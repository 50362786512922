.story-card {
	background-color: $color-white;
	border-bottom: 6px solid $color-microsite-yellow;
	margin: 0 0 1.25rem;
	padding: 0;
	width: 100%;

	&:before {
		display: none !important;
	}

	&.video-card {
		.story-image-inner {
			&:after {
				border-bottom: 35px solid transparent;
				border-left: 70px solid rgba($color-white, 0.5);
				border-top: 35px solid transparent;
				content: '';
				display: block;
				height: 0;
				left: 50%;
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);
				transition: border-color 0.2s ease-in-out;
				width: 0;
			}
		}
	}

	.story-link {
		color: $color-black;

		&:hover,
		&:focus {
			text-decoration: none;

			.story-image-container {
				filter: brightness(0.7);

				.story-image-inner {
					&:after {
						border-left: 70px solid rgba($color-white, 0.8);
					}
				}
			}

			.story-title {
				text-decoration: underline;
			}
		}
	}

	.story-image-container {
		height: 0;
		padding-top: 100%;
		position: relative;
		transition: filter 0.2s ease-in-out;
		width: 100%;
	}

	.story-image-inner {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		img {
			font-family: 'object-fit:cover; object-position: center;';
			height: 100%;
			object-fit: cover;
			object-position: center;
			width: 100%;
		}
	}

	.story-content {
		padding: 1.75rem 2.25rem;
	}

	.story-subject {
		@include text(zeta);
		color: $color-black;
		font-family: $moments-sans-serif;
		font-weight: $font-weight-normal;
		letter-spacing: 1px;
		margin-bottom: 0.75rem;
		text-transform: uppercase;
	}

	.story-title {
		@include text(alpha);
		border-bottom: 2px solid $color-healthplan-gray;
		color: $color-black;
		font-family: $moments-serif;
		font-weight: $font-weight-normal;
		letter-spacing: -0.5px;
		line-height: 1;
		margin-bottom: 1rem;
		padding-bottom: 1rem;
	}

	.story-description {
		@include text(zeta, 22);
		color: $color-black;
		font-family: $moments-serif;
		font-weight: $font-weight-normal;
	}
}
