/*
 *
 * TYPOGRAPHY MIXINS
 *
 */

/*
 * text($font-size, $line-height(optional))
 * calculates font-size and line-height corresponding to $font-size-scale and $line-height-scale maps in _variables.scss
 * $line-height argument is optional, and can be a different value in $line-height-scale or a number value
 */
@mixin text($font-size, $line-height: null) {
	@if map-has-key($font-size-scale, $font-size) {
		font-size: calculateRem(map-get($font-size-scale, $font-size));

		@if ($line-height != null) {
			line-height: calulateLineHeight($font-size, $line-height);
		} @else {
			line-height: calulateLineHeight($font-size, $font-size);
		}
	} @else {
		@warn 'Unfortunately, no value could be retrieved from `#{$font-size}`. ' + 'Please make sure it is defined in `$font-size-scale` map in _variables.scss.';
	}
}

/*
 * loader($color, $height(optional, default 26px), $stroke(optional, default 2px))
 * adds generic loader as :after psuedo element
 * $color must be passed. $height and $stroke have defaults, but can be passed in as needed.
 */
@mixin loader($color, $height: 26px, $stroke: 2px) {
	&:after {
		border: $stroke solid $color;
		border-bottom: $stroke solid transparent;
		border-left: $stroke solid transparent;
		content: ' ';
		display: block;
		pointer-events: none;
		width: $height;
		height: $height;
		border-radius: 50%;
		animation: spin 1.4s infinite linear;
		position: relative;
    	left: 10px;

		@keyframes spin {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	}
}

@mixin make-container-max-width(
	$max-widths: $container-max-widths,
	$breakpoints: $grid-breakpoints
) {
	@each $breakpoint, $container-max-width in $max-widths {
		@include media-breakpoint-up($breakpoint, $breakpoints) {
			max-width: $container-max-width !important; /* stylelint-disable-line declaration-no-important */
		}
	}
}
