.message {
	width: 100%;
	margin: 0;

	@at-root .modal-open {
		padding-right: 0 !important;
	}

	.modal-close-btn {
		background-color: transparent;
		border: 0;
		opacity: 1;
		outline: 0;
		padding: 0;
		position: absolute;
		right: 1.625rem;
		top: 1rem;
		transition: opacity 0.1s ease-in-out;

		.icon {
			height: 1.5rem;
			fill: $color-gray-lightest;
			transition: fill 0.2s ease-in-out;
			width: 1.5rem;
		}
	}

	.alert {
		background: $color-gray-darkest;
		border: 0;
		border-radius: 0;
		border-bottom: 0.25rem solid $color-white;
		margin: 0;
		padding: 1rem 0;

		.alert-content {
			@include make-container();
			@include make-container-max-width();
			display: flex;
			flex-direction: column;
			padding: 0 2rem;
			text-align: center;
		}

		.alert-icon {
			align-self: center;
			margin-bottom: 0.375rem;

			.icon {
				fill: $color-primary-yellow;
				height: 40px;
				width: 47px;
			}
		}

		.alert-title {
			@include text(zeta, delta);
			color: $color-primary-yellow;
			font-weight: $font-weight-bold;
			margin-bottom: 1rem;
			padding: 0 0.5rem;
		}

		.alert-cta {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
		}

		.alert-cta-link {
			@include text(zeta);
			color: $color-white;
			font-weight: $font-weight-semi-bold;
			margin: 0 1rem 1rem;
			position: relative;
			text-transform: uppercase;

			&:hover {
				text-decoration: none;
			}

			span {
				padding-bottom: 2px;
				position: relative;

				&:after {
					background-color: $color-primary-yellow;
					bottom: 0;
					content: '';
					display: block;
					height: 2px;
					position: absolute;
					transition: width 0.25s ease-in-out, opacity 0.25s ease-out;
					width: 100%;
				}
			}
		}
	}

	.modal {
		position: fixed;
		top: 0;
		z-index: 1050;
		padding-right: 0 !important;
	}

	.urgent .modal-content {
		background-color: $color-gray-darkest;
		border: 0.375rem solid $color-gray-darkest;
		border-radius: 5px;
		padding: 1.75rem 2rem;
		text-align: center;

		.icon-message {
			fill: $color-red;
		}

		.btn {
			@include button-variant(
				$color-primary-yellow,
				$color-primary-yellow,
				transparent,
				$color-primary-yellow
			);
			background-color: $color-primary-yellow;
			border-color: $color-primary-yellow;
			color: $color-gray-darkest;
			padding: 0.375rem 0.75rem;
			text-transform: uppercase;
		}

		.modal-header {
			border-bottom: none;
			color: $color-gray-lightest;
			display: block;
			align-items: center;
			justify-content: center;
			margin-bottom: 1.5rem;
			padding: 0;

			.icon-alert {
				fill: $color-primary-yellow;
				height: 100px;
				flex: 1 1 auto;
				margin-bottom: 1rem;
				max-width: 116px;
				width: 116px;
			}
		}

		.modal-close-btn {
			position: absolute;
			top: 0.625rem;
			right: 0.625rem;
			opacity: 1;
			transition: opacity 0.1s ease-in-out;
			outline: 0;

			svg {
				stroke: $color-gray-lightest;
			}
		}

		.modal-title {
			@include text(alpha);
			color: $color-primary-yellow;
			font-weight: $font-weight-bold;
			flex: 1;
			text-transform: uppercase;
		}

		.modal-body {
			@include text(delta, gamma);
			color: $color-gray-lightest;
			font-weight: $font-weight-normal;
			margin-bottom: 1.5rem;
			padding: 0;

			a {
				color: $color-white;
				text-decoration: underline;

				&:hover,
				&:focus {
					color: $color-white;
					text-decoration: none;
				}
			}
		}

		.modal-footer {
			border-top: none;
			justify-content: center;
			padding: 0;
		}
	}

	@include media-breakpoint-up(sm) {
		.modal-close-btn {
			&:hover {
				.icon {
					fill: $color-white;
				}
			}
		}

		.alert {
			.alert-content {
				padding: 0 5rem;
			}

			.alert-title {
				@include text(epsilon, delta);
			}

			.alert-cta {
				@include text(epsilon, delta);
			}

			.alert-cta-link {
				&:hover {
					span {
						&:after {
							opacity: 0;
							width: 0;
						}
					}
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		.alert {
			padding-top: 1.75rem;

			.alert-content {
				align-items: center;
				flex-direction: row;
				justify-content: flex-start;
				padding: 0 70px 0 15px;
				text-align: left;
			}

			.alert-icon {
				align-self: unset;
				margin-bottom: 0;
				margin-right: 1rem;

				.icon {
					height: 50px;
					width: 58px;
				}
			}

			.alert-title {
				margin-bottom: 0.25rem;
				max-width: 500px;
				padding-left: 0;
				padding-right: 2rem;
			}

			.alert-cta {
				flex-wrap: nowrap;
			}

			.alert-cta-link {
				@include text(epsilon);
				margin: 0;
				transition: color 0.2s, border-bottom-color 0.2s;

				&:hover {
					border-bottom-color: $color-primary-dark-blue;
					color: $color-white;
				}

				&:nth-child(2) {
					margin-left: 1.5rem;
				}
			}
		}

		.urgent {
			max-width: 750px;
		}

		.urgent .modal-content {
			margin: auto;
			padding: 3.25rem 4rem 2.25rem;
			text-align: left;

			.modal-header {
				display: flex;

				.icon-alert {
					height: 75px;
					margin-bottom: 0;
					margin-right: 2.5rem;
					max-width: 85px;
					width: 85px;
				}
			}

			.modal-title {
				margin-top: -7px;
			}

			.modal-body {
				@include text(gamma, omega);
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.alert {
			.alert-content {
				padding-right: 4rem;
			}

			.alert-title {
				padding-right: 2rem;
			}

			.alert-cta {
				display: flex;
				flex-grow: 1;
				justify-content: space-evenly;
			}

			.alert-cta-link {
				white-space: nowrap;

				&:nth-child(2) {
					margin-left: 3rem;
				}
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.alert {
			.alert-content {
				padding-left: 15px;
			}

			.alert-title {
				@include text(delta);
			}

			.alert-cta {
				justify-content: flex-end;
				justify-self: flex-end;
			}

			.alert-cta-link {
				@include text(delta);

				&:nth-child(2) {
					margin-left: 5rem;
				}
			}
		}
	}

	@media (min-width: 1440px) {
		.alert {
			.alert-content {
				padding-right: 15px;
			}
		}
	}
}
