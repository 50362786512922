.legend {
	@include text('eta');

	.legend-label {
		display: inline-block;
		padding-right: 0.75rem;
	}

	.legend-data {
		display: inline-block;
		margin-bottom: 0;

		li {
			display: inline-block;
			padding-right: 0.5rem;

			&:last-child {
				padding-right: 0;
			}
		}
	}

	@include media-breakpoint-up(md) {
		@include text('zeta');
	}
}
