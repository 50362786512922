.advance {
	/* filters */
	.list-filters {
		padding: $padding-advance-section-mobile 20px;
		.filter-group--contain {
			margin-top: 1px;
			border: 10px $color-advance-light-grey solid;
			.filter-group--toggle {
				padding: 23px 20px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				.heading {
					margin: 0;
				}
				.icon {
					color: $color-advance-dark-teal;
					fill: $color-advance-light-teal;
					margin-left: 15px;
					width: 30px;
					height: 30px;
					transform: rotate(90deg);
				}
			}
			&.active {
				.filter-group--toggle .icon {
					transform: rotate(-90deg);
				}
				form {
					display: flex;
					flex-direction: column;
				}
			}
			form {
				display: none;
				border: none;
				background: $color-advance-white;
				padding: 4px 20px 15px;
				input,
				select {
					background: $color-advance-off-white !important;
				}
				button[type="submit"] {
					display: none;
				}
			}
		}
	}
	/* listing */
	.article-list-paginated {
		padding-top: $padding-advance-section-mobile;
		padding-bottom: $padding-advance-section-mobile;
		padding-left: 20px;
		padding-right: 20px;
		& > .showing-vs-total {
			margin: -28px 0 24px;
			line-height: 22px;
		}
		.article-listing {
			margin-left: 0;
			margin-right: 0;
			&:not(:last-of-type) {
				margin-bottom: 3rem;
				min-height: 160px;
			}
			p {
				display: block;
			}
			.advance--article-card--date {
				display: none;
			}
		}
	}
	/* pagination */
	.pagination-controls {
		padding-top: 18px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 0;
		& > div {
			max-width: 45vw;
		}
		.pagination-quantity {
			order: 1;
		}
		.pagination-link {
			order: 4;
			text-align: right;
			display: none;
		}
		.pagination-counts {
			order: 2;
			justify-content: flex-end;
			margin: -3px 0 3px;
		}
		.pagination-nav {
			max-width: unset;
			flex-grow: 1;
			flex-flow: column-reverse;
			align-items: center;
			order: 5;
			width: 100%;
			margin: 0 0 3px;
			.showing-vs-total {
				padding: 0;
				font-size: 18px;
			}
			.pagination {
				padding-top: 25px;
				align-items: center;
				justify-content: space-between;
				width: 100%;
			}
			.page-link {
				border: 4px $color-advance-white solid;
				background: $color-advance-white;
				color: $color-advance-mid-bluegrey;
				border-radius: 5px;
				&:hover {
					color: $color-advance-dark-teal;
				}
			}
			.page-item {
				margin: 0;

				&.next-page,
				&.prev-page {
					.page-link {
						border: none;
						background: $color-advance-mid-bluegrey;
						border-radius: 50%;
						span {
							svg {
								fill: $color-advance-white;
							}
						}
						&:hover {
							background: $color-advance-dark-teal;
						}
					}
					&.prev-page {
						margin-right: 5px;
						span {
							margin: 2px 1px -2px -1px;
						}
					}
					&.next-page {
						margin-left: 5px;
						span {
							margin: 2px -1px -2px 1px;
						}
					}
					&.disabled .page-link {
						background: $color-advance-light-grey;
					}
				}
			}
			.page-number {
				.page-link {
					padding: 5px 0;
					width: 41px;
					height: 36px;
				}
				&:nth-child(2) {
					&.active .page-link {
						margin-left: 10px;
					}
				}
				&:nth-child(5) {
					&.active .page-link {
						margin-right: 10px;
					}
				}
				&.active .page-link {
					border-color: $color-advance-light-teal;
					color: $color-advance-dark-teal;
				}
			}
		}
	}
	@include media-breakpoint-up(sm) {
		.pagination-controls .pagination-nav .pagination {
			justify-content: center;
			gap: 18px;
		}
	}
	@include media-breakpoint-up(md) {
		.list-filters {
			padding-top: $padding-advance-section-desktop;
			padding-bottom: $padding-advance-section-desktop;
			margin-top: -18px;
			.filter-group--contain {
				border-width: 14px;
				padding: 15px 30px;
				margin-top: 2px;
				.filter-group--toggle {
					padding: 20px 15px;
					justify-content: flex-start;
					.icon {
						margin-top: -3px;
					}
				}
				&.active {
					form {
						flex-direction: row;
						padding: 15px 15px 40px;
						flex-wrap: wrap;
						gap: 20px;
						.filter-group {
							margin: 0;
							width: calc(50% - 10px);
							flex: auto;
						}
					}
				}
			}
		}
		.article-list-paginated {
			padding-top: $padding-advance-section-desktop;
			padding-bottom: $padding-advance-section-desktop;
			& > .showing-vs-total {
				margin: -45px 0 54px;
				font-size: 18px;
				line-height: 28px;
			}
			.article-listing {
				.advance--article-card {
					min-height: unset;
					.advance--article-card--content {
						display: flex;
						flex-direction: row;
						flex-flow: row-reverse;
						justify-content: space-between;
						gap: 50px;
						.advance--article-card--image {
							max-width: 24.4%;
							a,
							img {
								display: block;
								max-height: 160px;
							}
						}
						.advance--article-card--info {
							justify-content: center;
							display: flex;
							flex-direction: column;
							padding: 0 0 18px;
							.advance--article-card--date {
								display: block;
							}
						}
					}
				}
			}
		}
		.pagination-controls {
			align-items: flex-start;
			.pagination-counts {
				display: none;
			}
			.pagination-link {
				display: block;
			}
			.pagination-nav {
				padding-top: 40px;
				width: auto;
				.pagination {
					padding-bottom: 28px;
					padding-top: unset;
				}
				.page-link {
					border-width: 4px;
				}
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.list-filters {
			.filter-group--contain {
				&.active {
					form {
						padding: 18px 15px 50px;
						flex-wrap: nowrap;
					}
				}
			}
		}
		.pagination-controls {
			padding-top: 30px;
			margin-bottom: -20px;
			& > div {
				width: 285px;
			}
			.pagination-nav {
				padding-top: 0px;
				order: 3;
			}
		}
	}
}
