.media-summary {
	@include make-container();
	@include make-container-max-width();

	&.summary-top {
		.media-summary-content-container {
			flex-direction: column-reverse;
		}
	}

	.media-summary-inner {
		background-color: $color-neutral-lighter;
		margin-bottom: 1rem;
		max-width: 100%;
	}

	.heading-3 {
		@include text(delta);
		color: $color-primary-dark-blue;
		font-weight: $font-weight-extra-bold;

		&:after {
			background-color: $color-primary-yellow;
			bottom: -4px;
			content: "";
			display: block;
			height: 4px;
			left: 0;
			position: relative;
			width: 4rem;
		}
	}

	.heading-4 {
		@include text(zeta, epsilon);
		color: $color-neutral-darkest;
		font-weight: $font-weight-semi-bold;
	}

	p {
		@include text(zeta, delta);
		color: $color-primary-dark-blue;
		font-weight: $font-weight-normal;
	}

	.btn {
		margin-top: 1rem;
		padding-left: 3rem;
		padding-right: 3rem;

		&:not(:last-of-type) {
			margin-right: 1rem;
		}
	}

	.media-summary-content {
		background-color: $color-neutral-lighter;
		padding: 1rem 1.625rem;

		> :last-child {
			margin-bottom: 0;

			> :last-child {
				margin-bottom: 0;
			}
		}

		.read-more-body {
			max-height: 100px;
		}

		.description {
			padding-top: 1rem;

			.story-container {
				padding-right: 1rem;
				min-width: 12rem;
			}
		}
	}

	.media-summary-player {
		flex: auto;
		max-width: 100%;

		.media-item {
			width: 100%;
			margin-bottom: 0;
			padding: 0;

			.heading-4 {
				display: none;
			}

			.figure-text {
				display: none;
			}
		}
	}

	.media-summary-content-container {
		background-color: $color-neutral-lighter;
		border: 0;
		flex: auto;
		max-width: 100%;
	}

	.two-columns {
		column-gap: 40px;
		padding-left: 1.5rem;
	}

	.read-more, .read-more:not(.in-container) {
		padding-left: 0;
		padding-right: 0;
	}

	@include media-breakpoint-up(sm) {
		.media-summary-content {
			padding: 2rem;
		}
	}

	@include media-breakpoint-up(md) {
		.heading-3 {
			font-weight: $font-weight-extra-bold;
		}

		.heading-4 {
			display: none;
		}
	}

	@include media-breakpoint-up(lg) {
		&.always-stacked {
			.media-summary-content-container {
				flex-direction: column;
			}

			.media-summary-content {
				flex-basis: auto;
				max-width: 100%;
			}

			.media-summary-player {
				flex-basis: auto;
				max-width: 100%;
				width: 100%;
			}

			.figure-img {
				max-height: none;
			}
		}

		&.right {
			.media-summary-content-container {
				flex-direction: row-reverse;
			}
		}

		&.summary-top {
			.media-summary-content-container {
				flex-direction: row;
			}

			&.right {
				.media-summary-content-container {
					flex-direction: row-reverse;
				}
			}

			&.always-stacked {
				.media-summary-content-container {
					flex-direction: column-reverse;
				}
			}
		}

		.media-summary-content-container {
			flex-direction: row;
			padding: 0;
			margin-bottom: 2rem;
		}

		.media-summary-content {
			padding: 3rem;
			flex: 0 0 calc(41.666667%);
			max-width: calc(41.666667%);
			padding: 2rem;

			.description {
				padding-top: 1rem;

				.story-container {
					min-width: 10rem;
				}
			}
		}

		.media-summary-player {
			flex: 0 0 calc(58.333333%);
			max-width: calc(58.333333%);
			width: calc(58.333333%);
		}
	}

	@include media-breakpoint-up(xl) {
		.media-summary-content {
			padding: 4rem;
			padding-top: 3rem;
		}
	}
}
