.global-search-typeahead, .global-search-typeahead-mobile {
	position: relative;

	&.search-focus {
		.search-dropdown {
			display: block;
			border-radius: 0 0 5px 5px;
		}
	}

	.search-typeahead-input {
		&::-ms-clear {
			display: none;
		}
	}

	.search-dropdown {
		background-color: $color-white;
		border: 1px solid transparent;
		box-shadow: 0 10px 24px rgba($color-black, 0.12);
		display: none;
		margin-top: 0.5rem;
		position: absolute;
		top: 100%;
		left: 50%;
		text-align: left;
		transform: translateX(-50%);
		width: calc(100vw - 30px);
		z-index: 6;

		&.search-mobile {
			margin-top: 2.5rem;
		}

		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;

			li {
				@include text(zeta);
				text-align: left;

				&:before {
					display: none;
				}

				&:not(:last-of-type) {
					margin-bottom: 0.5rem;
				}

				a {
					@include text(zeta);
					color: $color-white;
					font-weight: $font-weight-normal;

					&:focus,
					&.active {
						outline: 0;
						text-decoration: underline;
					}
				}
			}
		}

		.heading-5 {
			color: $color-primary-yellow;
			margin-bottom: 0.625rem;
		}

		.view-all-link {
			@include text(zeta);
			color: $color-white;
			display: inline-block;
			font-weight: $font-weight-semi-bold;
			margin-top: 1.5rem;
			margin-bottom: 0;

			&:focus,
			&.active {
				outline: 0;
				text-decoration: underline;
			}

			&.looking-for {
				color: $color-gray-light;
				font-style: italic;
				margin-top: 0;
			}
		}

		.primary-view-all {
			@include text(zeta);
			align-items: center;
			background-color: $color-neutral-dark;
			color: $color-white;
			display: flex;
			font-weight: $font-weight-semi-bold;
			height: 50px;
			justify-content: center;
			text-align: center;
			width: 100%;

			&:focus,
			&.active {
				outline: 0;
				text-decoration: underline;
			}
		}

		.category-list {
			&:not(:first-of-type) {
				margin-top: 1.5rem;
			}
		}

		.doctor-name {
			@include text(epsilon);
			color: $color-white;
			font-weight: $font-weight-semi-bold;
		}

		.location-name {
			@include text(epsilon);
			color: $color-white;
			font-weight: $font-weight-semi-bold;
		}

		.dropdown-container {
			@include make-row();
			flex-direction: column;
			margin: 0;

			.dropdown-primary {
				background-color: $color-gray-darkest;
				order: 2;
				padding: 1.75rem 1.75rem 1.25rem;
			}

			.dropdown-secondary {
				display: flex;
				background-color: $color-neutral-dark;
				order: 1;

				.heading-5,
				ul {
					display: none;
				}

				.view-all-link {
					display: none;

					&.mobile-find-link {
						align-items: center;
						border-bottom: 1px solid $color-gray-darkest;
						color: $color-white;
						display: flex;
						justify-content: center;
						margin-top: 0;
						text-align: center;
						width: 100%;
						height: 100%;
						padding: 0.75rem 0.5rem;
					}
				}

				.category-list {
					align-items: center;
					display: flex;
					justify-content: center;
					flex: 0 0 50%;
					margin-top: 0;

					&:not(:first-of-type) {
						.mobile-find-link {
							border-left: 1px solid $color-gray-darkest;
						}
					}
				}
			}
		}

		.cta-container {
			.current-network {
				display: none;
			}
		}

		.search-doctors {
			.profile-image {
				height: 62px;
				min-width: 62px;
				width: 62px;
				flex: 0 0 62px;
				margin-right: 1.5rem;
				border: none;
				box-shadow: none;
			}

			li {
				align-items: center;
				display: flex;
			}

			svg {
				height: 100%;
				width: 100%;
			}
		}

		.search-locations {
			li {
				align-items: center;
				display: flex;
			}

			svg {
				flex: 0 0 25px;
				height: 25px;
				margin-right: 1.5rem;
				fill: $color-neutral-light;
				width: 25px;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.search-dropdown {
			width: 65%;

			.dropdown-container {
				@include make-row();
				flex-direction: row;
				margin: 0;
				// padding: 1.75rem 1.75rem 1.25rem;

				.dropdown-primary {
					@include make-col(5);
					border-right: 1px solid $color-gray-darkest;
					order: 1;
					padding: 1.75rem 2rem 1.25rem 1.75rem;
				}

				.dropdown-secondary {
					@include make-col(7);
					display: block;
					background-color: $color-blue-gray;
					font-weight: $font-weight-light;
					order: 2;
					padding: 1.75rem 2rem 1.25rem 2rem;

					.heading-5 {
						display: block;
					}

					ul {
						display: block;
						color: $color-white;
					}
					.view-all-link {
						display: inline-block;

						&.mobile-find-link {
							display: none;
						}
					}

					.category-list {
						display: block;

						&:not(:first-of-type) {
							margin-top: 1.5rem;

							.view-all-link {
								border-left: none;
							}
						}
					}
				}
			}

			.cta-container {
				@include make-row();
				background-color: $color-neutral-lightest;
				margin: 0;
				padding: 0 1.75rem;

				.primary-view-all {
					@include make-col(5);
					justify-content: flex-start;
				}

				.current-network {
					@include make-col(7);
					@include text(eta);
					color: $color-neutral-dark;
					display: flex;
					align-items: center;
					padding-left: 2rem;
				}
			}
		}


	}
}
