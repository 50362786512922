.meredith-recipes {
	@include make-container();
	@include make-container-max-width();
	min-height: calc(100vh - 90px);

	&.error {
		min-height: 300px;
	}

	.mcl {
		margin-left: -10px;
		margin-right: -10px;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			line-height: 1;
		}
	}

	@include media-breakpoint-up(lg) {
		min-height: calc(100vh - 141px + 70px);

		.mcl {
			&:before {
				content: '';
				display: block;
				height: 70px;
				margin-top: -70px;
				pointer-events: none;
				visibility: hidden;

				.healthplan-theme & {
					height: 60px;
					margin-top: -60px;
				}
			}
		}
	}
}
