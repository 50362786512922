.doctor-result {
	border: solid $profile-card-border-width $color-gray-lightest;
	border-radius: 5px;
	background-color: $color-gray-lightest;
	display: flex;
	flex-direction: column;
	margin-top: $profile-card-offset;

	.card-header {
		background-color: $color-white;
		border-bottom: none;
		border-radius: 5px;
		padding-top: 0;
		padding-bottom: 0.5rem;
		text-align: center;

		.location-address {
			padding-top: 0.25rem;
			margin-bottom: 0.25rem;
		}

		.open-status {
			float: right;
			margin-top: 1rem;
			margin-right: -1.25rem;
			margin-left: 0.5rem;
			position: relative;
			padding: 0.5rem 1rem 0.5rem 1.25rem;
			background-color: $color-gray-lightest;
			border-radius: 6rem 0 0 6rem;
			font-weight: 600;
		}
	}

	.profile-image {
		display: block;
		margin-top: $profile-image-offset;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 1rem;

		&[href] {
			&:hover,
			&:focus {
				box-shadow: 0 8px 24px 0 rgba($color-black, 0.24);
				text-decoration: none;

				+ .doctor-details {
					.doctor-name {
						color: $color-secondary-light-blue;
						text-decoration: underline;
					}
				}
			}
		}

		&.has-hours {
			display: flex;
			flex-direction: column;

			.location-image {
				flex: 1;
				height: 100%;
				overflow: hidden;
				position: relative;

				img {
					height: 100%;
					object-fit: cover;
					object-position: 50% 50%;
					font-family: 'object-fit: cover; object-position: 50% 50%;';
					width: 100%;
				}

				svg {
					height: 100%;
					width: 100%;
				}
			}

			.open-status {
				@include text(zeta);
				background-color: $color-white;
				bottom: 0;
				color: $color-neutral-dark;
				font-weight: $font-weight-bold;
				flex: 0 0 34px;
				padding: 3px 1.125rem 0;
				text-align: center;
				width: 100%;

				&.open {
					color: $color-open;
				}
			}
		}
	}

	.doctor-details {
		width: 100%;
	}

	.doctor-name {
		@include text(delta);
		color: $color-primary-light-blue;
		display: inline-block;
		font-weight: $font-weight-bold;
		margin-bottom: 0.5rem;

		&[href] {
			&:hover,
			&:focus {
				color: $color-secondary-light-blue;
			}
		}
	}

	.doctor-ratings {
		align-items: center;
		display: flex;
		justify-content: center;
		margin-bottom: 0.5rem;

		.rating-stars {
			margin-right: 0.75rem;
		}

		.rating-count {
			@include text(zeta);
			color: $color-neutral-dark;
		}
	}

	.specialty {
		@include text(eta);
		display: block;
		margin-bottom: 0.25rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.doctor-location {
		text-align: left;
		padding: 2rem 0 1rem;

		.appointment-type-label {
			padding: 0.125rem 0 0.25rem ;

			.appointment-type {
				padding-left: 0.125rem;
			}
		}
	}

	.affiliation-logo {
		float: right;
		max-width: 95px;
	}

	.distance-and-location,
	.phone-number,
	.appointment-type-label,
	.location-name,
	.address,
	.appointment-time,
	.modal-open-btn {
		@include text(eta);
	}

	.appointment-heading,
	.appointment-date {
		@include text(theta);
	}

	.location-name {
		color: $color-primary-blue;
		display: block;
		margin-bottom: 0.5rem;

		&:only-child {
			margin-bottom: 0;
		}
	}

	.distance,
	.phone-number {
		color: $color-secondary-light-blue;
		font-weight: $font-weight-semi-bold;
		padding-right: 0.5rem;
	}

	.distance-and-location {
		a {
			font-weight: $font-weight-semi-bold;
		}

		.get-directions::before {
			content: "|";
			padding-right: 0.75rem;
		}
	}

	.appointment-type-label {
		color: $color-primary-dark-blue;
	}

	.card-footer {
		background-color: $color-gray-lightest;
		border-top: none;
		padding-bottom: 1rem;
		padding-top: 1rem;

		.onlineSchDown {
			margin-bottom: 1rem;
			text-align: center;
		}

		.location-address {
			border-bottom: 1px solid $color-gray-light;
			margin-bottom: 1rem;
			padding-bottom: 1rem;

			&:empty {
				display: none;
			}
		}

		.address {
			color: $color-gray-darkest;
			font-weight: $font-weight-normal;
		}

		.appointment-type-label {
			display: none;
		}

		.appointment-button-container {
			display: flex;
			justify-content: center;
		}

		.appointment-heading {
			color: $color-primary-dark-blue;
			font-weight: $font-weight-bold;
			margin-bottom: 0.75rem;
		}

		.appointment-date {
			color: $color-primary-blue;
			font-weight: $font-weight-bold;
			margin-bottom: 0.5rem;
		}

		.appointment-time {
			margin-right: 1rem;
		}

		.appointment-request-container {
			padding: 0 1.5rem;
		}

		.appointment-request-btn {
			color: $color-white;
			padding: 7px;
			width: 100%;

			+ .appointment-request-phone {
				padding-top: 1rem;
			}
		}

		.appointment-request-phone {
			@include text(eta);
			align-items: center;
			color: $color-primary-dark-blue;
			display: flex;
			justify-content: center;
			padding: 0;
			font-weight: $font-weight-semi-bold;

			svg {
				flex: 0 0 2rem;
				height: 2rem;
				margin-right: 1rem;
				width: 2rem;
				stroke: none;
			}

			a {
				color: $color-primary-blue;
				display: block;
			}
		}

		.modal-open-btn {
			background: none;
			border: none;
			color: $color-primary-light-blue;
			font-weight: $font-weight-normal;
			padding: 0 0.625rem 0 0;
			position: relative;

			&:after {
				border-bottom: 6px solid transparent;
				border-left: 6px solid $color-quick-links;
				border-top: 6px solid transparent;
				clear: both;
				content: '';
				display: inline-block;
				height: 0;
				left: 100%;
				position: absolute;
				top: 1px;
				width: 0;
			}

			&:hover,
			&:focus {
				background: none;
				border: none;
				color: $color-primary-light-blue;
				outline: 0;
				text-decoration: underline;
			}
		}

		.legend {
			display: none;
			// padding-top: 1rem;
		}
	}

	.view-mode-map & {
		height: auto;
		justify-items: flex-start;
		flex-direction: column;
		background-color: $color-gray-light;
		border: none;
		margin-left: 0;
		margin-top: 0;

		.card-header {
			align-items: center;
			border-radius: 5px;
			display: flex;
			flex-direction: row;
			overflow: hidden;
			padding: 0;
			text-align: left;
			width: auto;
			background-color: $color-neutral-lighter;
			border: 0.25rem solid $color-gray-lightest;

			.appointment-type-label {
				display: none;
			}
		}

		.profile-image {
			margin: 0 1rem 0 0;
			border-radius: 0;
			border: none;
			min-width: 175px;

			&.has-hours {
				.location-image {
					svg {
						top: 50%;
					}
				}
			}
		}

		.doctor-details {
			padding: 1rem 0;
		}

		.doctor-location {
			padding: 1rem 0 0;
		}

		.card-footer {
			display: none;
			width: 0;
		}
	}

	.loc-provider-specialty {
		@include text(zeta);
		display: block;
		background-color: $color-neutral-lighter;
		border-top: none;

		.loc-provider-specialty-content {
			align-items: center;
			justify-content: center;
			text-align: center; //left
		}
		.loc-provider-specialty-list {
			@include text(zeta);
			align-items: center;
			color: $color-primary-blue;
			display: inline-block;
			justify-content: center;
			padding: 0 1rem;
			list-style: none; //or disc?

			a {
				display: block;
			}
		}
	}

	@include media-breakpoint-up(md) {
		height: calc(100% - #{$profile-card-offset});
		justify-content: space-between;

		.view-mode-list & {
			height: auto;
			justify-items: flex-start;
			flex-direction: row;
			margin-left: $profile-card-offset;
			margin-top: 0;

			.card-header,
			.card-footer {
				padding-top: 1rem;
				padding-bottom: 1rem;
				width: 50%;
			}

			.card-header {
				align-items: center;
				display: flex;
				flex-direction: row;
				padding-left: 0;
				text-align: left;

				.appointment-type-label {
					display: none;
				}

				.open-status {
					float: right;
					margin-top: 0;
					bottom: 0;
					position: relative;
				}
			}

			.doctor-ratings {
				justify-content: flex-start;
			}

			.profile-image {
				margin-top: 0;
				margin-left: $profile-image-offset;
				margin-right: 1rem;
				margin-bottom: 0;
			}

			.doctor-location {
				padding-bottom: 0;
			}

			.card-footer {
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding-left: 1.5rem;
				padding-right: 1.5rem;
				width: 50%;

				.appointment-type-label {
					@include text(zeta);
					display: block;
					padding-bottom: 0.5rem;

					.appointment-type {
						padding-left: 0.125rem;
					}

					&:not(:last-of-type) {
						padding-bottom: 0.25rem;
					}
				}

				.location-address {
					&:empty {
						display: block;
						padding-bottom: 0;
					}
				}

				.appointment-button-container,
				.appointment-request-phone {
					justify-content: left;
				}

				.appointment-request-container {
					padding: 0;
				}
			}
		}

		.view-mode-map & {
			.card-header {
				flex-direction: column;
				overflow: visible;

				.doctor-location {
					align-items: center;
				}

				.open-status {
					position: absolute;
					top: 1rem;
					right: 0;
					margin-right: 0;
				}
			}

			.profile-image {
				display: flex;
				flex: 0 0 175px;
				min-height: 175px;
				min-width: 175px;
				position: relative;
			}

			.doctor-details {
				padding: 1rem 1rem 1rem 0;

				.doctor-name {
					@include text(epsilon);
				}
			}
		}

		.specialty {
			@include text(zeta);
		}

		.affiliation-logo {
			max-width: 115px;
		}

		.distance-and-location,
		.phone-number,
		.appointment-type-label,
		.location-name,
		.address,
		.appointment-time,
		.modal-open-btn {
			@include text(zeta);
		}

		.appointment-heading,
		.appointment-date {
			@include text(eta);
		}

		.card-footer {
			.appointment-button-container{
				justify-content: center;
			}

			.appointment-time {
				margin-right: 1.4rem;
			}

			.modal-open-btn {
				&:after {
					top: 4px;
				}
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.card-footer {
			.appointment-request-phone {
				padding: 0 1.25rem;
			}

			.loc-provider-specialty-content {
				padding: 0 1.25rem;
			}
		}

		.view-mode-map & {
			// .profile-image {
			// 	flex: 0 0 175px;
			// 	margin-right: 2rem;
			// 	min-height: 205px;
			// 	min-width: 232px;
			// }

			.doctor-details {
				padding-right: 2rem;

				.doctor-name {
					@include text(epsilon);
				}
			}
		}
	}
}
