.list-filters {
	@include make-container();
	@include make-container-max-width();
	margin-bottom: 2rem;

	form {
		background-color: $color-neutral-lightest;
		border-radius: 5px;
		padding: 1.5rem 1rem;
		position: relative;

		button[type='submit'] {
			margin-top: 1rem;
		}
	}

	.filter-group {
		margin-bottom: 1rem;
	}

	label {
		@include text(delta);
		color: $color-primary-dark-blue;
		font-weight: $font-weight-semi-bold;
	}

	input[type='text'] {
		background-color: $color-white;
	}

	.input-icon-container {
		// box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
	}

	select {
		background-color: $color-white !important;
		box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
		padding-right: 2.5rem;
		position: relative;
		appearance: none;
		background-color: transparent;
		background-image: none;
		box-shadow: none;
		display: block;

		&::-ms-expand {
			display: none;
		}
	}

	.clear-btn {
		margin: 1.5rem 1rem 0.5rem;
		padding: 0;
	}

	@include media-breakpoint-up(md) {
		form {
			display: flex;
			flex-wrap: wrap;

			.filter-group {
				flex: 1 1 0;
				margin-bottom: 0;
				margin-right: 1rem;

				&:last-of-type {
					margin-right: 0;
				}
			}

			button[type='submit'] {
				align-self: flex-end;
				flex-basis: 100%;
				height: 42px;
			}
		}

		.clear-btn {
			margin: 0.5rem 1rem 0.5rem;
			position: absolute;
			top: 0;
			right: 2px;
		}
	}

	@include media-breakpoint-up(xl) {
		form {
			.filter-group {
				&:last-of-type {
					margin-right: 1rem;
				}
			}

			button[type='submit'] {
				flex-basis: auto;
			}
		}
	}
}
