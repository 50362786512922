.moments-hero {
	.hero-outer {
		border-radius: 0;
		margin-bottom: 0;
		padding-bottom: 0;
		padding-top: 0;
		position: relative;
	}

	.hero-img {
		display: block;
		height: 100vh;
		min-height: 400px;
		position: relative;
		text-align: center;
	}

	.hero-img img,
	.hero-img source {
		font-family: 'object-fit: cover; object-position: center bottom;';
		height: 100vh;
		min-height: 400px;
		object-fit: cover;
		object-position: center bottom;
		width: 100%;
	}

	.hero-content-container {
		background-color: transparent;
		color: $color-white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		text-align: center;
		top: 17.25vh;
	}

	.hero-content {
		margin: auto;
		max-width: 750px;
		padding: 0 10px;
		width: 100%;
	}

	.hero-eyebrow {
		@include text(gamma, 29);
		color: $color-white;
		font-family: $moments-sans-serif;
		font-weight: 900;
		letter-spacing: 3.36px;
		margin: 0;
		text-transform: uppercase;
	}

	.hero-subtitle {
		@include text(alpha, 39);
		color: $color-white;
		font-family: $moments-serif;
		font-weight: 400;
		margin: 0;
	}

	.hero-logo {
		margin: 0.625rem 0 1rem;

		img {
			width: 100%;
		}
	}

	.scroll-arrow {
		bottom: 2rem;
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		text-align: center;

		img {
			height: auto;
			width: 52px;
		}
	}

	.scroll-btn {
		background: transparent;
		border: none;
		margin: 0;
		outline: 0;
		padding: 0;
	}

	@include media-breakpoint-up(lg) {
		.hero-img {
			height: calc(100vh - 70px);
			min-height: 500px;
		}

		.hero-img img,
		.hero-img source {
			height: calc(100vh - 70px);
			min-height: 500px;
		}

		.hero-content-container {
			@include make-container();
			@include make-container-max-width();
			display: flex;
			top: 9vh;
		}

		.hero-content {
			max-width: 775px;
			padding: 0;
			width: 70%;
		}

		.hero-eyebrow {
			@include text(delta, 25);
			padding: 0 2rem;
		}

		.hero-logo {
			margin: 2.25% 0 3.1%;
		}

		.hero-subtitle {
			@include text(alpha, 44);
			padding: 0 2rem;
		}

		.scroll-arrow {
			display: none;
		}
	}
}
