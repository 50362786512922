.one-up {
	@include make-container();
	@include make-container-max-width();

	.one-up-inner {
		background-color: $color-white;
		margin-bottom: 1rem;
		max-width: 100%;
	}

	.one-up-title {
		@include text(beta);
		color: $color-primary-dark-blue;
		margin-top: 0;
	}

	p {
		@include text(epsilon, delta);
		color: $color-neutral-darkest;
		font-weight: $font-weight-semi-bold;
	}

	.btn {
		padding-left: 3rem;
		padding-right: 3rem;
	}

	.one-up-content {
		margin-bottom: 1rem;
	}

	.card .card-img-top {
		display: none;
	}

	.card .card-body .card-img-top {
		display: block;
	}

	.one-up-img {
		order: 2;
		border-radius: 5px;
		margin-bottom: 1rem;

		img,
		source {
			object-fit: cover;
			object-position: 50% 50%;
			font-family: 'object-fit: cover; object-position: 50% 50%;';
			height: 15rem;
			width: 100%;
		}
	}

	.one-up-content-container {
		flex: auto;
		max-width: 100%;
		padding: 2rem;
	}

	@include media-breakpoint-up(sm) {
		.one-up-img {
			height: 18rem;

			img,
			source {
				height: 18rem;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.two-column & {
			.one-up-inner {
				padding-top: 0;
				padding-left: 0;
			}

			.card .card-img-top {
				display: none;
			}

			.card .card-body .card-img-top {
				display: block;
			}

			.one-up-content-container {
				flex-direction: column;
				margin-bottom: 6rem;
			}

			.one-up-img {
				flex: 0 0 100%;
				max-width: 100%;
				width: 100%;
				margin-top: 0;
				margin-bottom: 1rem;
				margin-left: 0;
				height: 15rem;
				max-height: none;
				min-height: 0;

				img,
				source {
					height: 15rem;
					max-height: none;
					min-height: 0;
				}
			}

			.one-up-content {
				flex: 0 0 100%;
				max-width: 100%;
				margin-bottom: 0;
				padding: 2.5rem 2rem;
			}

			.one-up-title {
				@include text(beta);
			}

			.divider {
				margin: 1rem auto 1rem 0;
			}

			&.right {
				.one-up-content-container {
					flex-direction: column;
				}
			}
		}

		&.right {
			.one-up-inner {
				padding-left: 0;
				padding-right: 2.5rem;
			}

			.one-up-content-container {
				flex-direction: row;
			}

			.one-up-img {
				margin-left: auto;
			}
		}

		.one-up-content {
			margin-bottom: 1rem;
		}

		.card .card-img-top {
			display: block;
		}

		.card .card-body .card-img-top {
			display: none;
		}

		.one-up-inner {
			//padding-top: 1.5rem;
			padding-left: 1.5rem;
		}

		.one-up-title {
			@include text(alpha, beta);
			margin-bottom: 0;
		}

		.divider {
			margin: 1.75rem auto 1.75rem 0;
		}

		p {
			@include text(epsilon, delta);
			color: $color-neutral-darkest;
		}

		.one-up-content-container {
			flex-direction: row-reverse;
			padding: 0;
			margin-bottom: 2rem;
		}

		.one-up-img {
			height: auto;
			min-height: 19rem;
			margin-right: auto;
			order: 1;
			flex: 0 0 calc(50% + 1rem);
			max-width: calc(50% + 1rem);
			width: calc(50% + 1rem);

			img,
			source {
				height: 100%;
				max-height: 340px;
				min-height: 19rem;
			}
		}

		.one-up-content {
			align-self: center;
			flex: 0 0 calc(50%);
			max-width: calc(50%);
			margin-bottom: 0;
			padding: 2.5rem 2rem;
		}
	}

	@include media-breakpoint-up(lg) {
		.two-column & {
			.one-up-inner {
				padding-top: 1.5rem;
			}

			.one-up-content-container {
				flex-direction: row-reverse;
				margin-bottom: 3rem;
			}

			.one-up-content {
				margin-bottom: 0;
				padding: 2.5rem 2rem;
			}

			.one-up-img {
				height: auto;
				min-height: 16rem;
				margin-right: auto;
				margin-bottom: 2rem;
				order: 1;

				img,
				source {
					height: 100%;
					max-height: 20rem;
					min-height: 16rem;
				}
			}

			&.right {
				.one-up-inner {
					padding-left: 0;
					padding-right: 1.5rem;
				}

				.one-up-content-container {
					flex-direction: row;
				}

				.one-up-img {
					margin-left: auto;
					margin-right: 0;
				}
			}
		}

		&.right {
			.one-up-inner {
				padding-left: 0;
				padding-right: 4rem;
			}

			.one-up-img {
				margin-left: auto;
				margin-right: -2.5rem;
			}

			.one-up-content {
				padding: 3rem 0 3rem 3rem;
			}
		}

		.one-up-content-container {
			margin-bottom: 3rem;
		}

		.one-up-inner {
			padding-left: 4rem;
		}

		.one-up-img {
			min-height: 24rem;
			margin-left: -3.5rem;
			margin-right: auto;
			flex: 0 0 calc(50% + 3rem);
			max-width: calc(50% + 3rem);
			width: calc(50% + 3rem);

			img,
			source {
				max-height: 400px;
				min-height: 24rem;
			}
		}

		.one-up-content {
			flex: 0 0 calc(50% - 3rem);
			max-width: calc(50% - 3rem);
			padding: 3rem 3rem 3rem 0;
		}
	}

	@include media-breakpoint-up(xl) {
		.two-column & {
			.one-up-inner {
				padding-top: 3rem;
			}

			.card .card-img-top {
				display: block;
			}

			.card .card-body .card-img-top {
				display: none;
			}

			.one-up-img {
				margin-left: -1rem;
				margin-bottom: 0;
				min-height: 20rem;
				flex: 0 0 calc(50% + 2rem);
				max-width: calc(50% + 2rem);
				width: calc(50% + 2rem);

				img,
				source {
					max-height: 22rem;
					min-height: 20rem;
				}
			}

			.one-up-content {
				flex: 0 0 calc(50% - 3rem);
				max-width: calc(50% - 3rem);
				padding: 0.5rem 2.5rem 2.5rem 0;
			}

			&.right {
				.one-up-inner {
					padding-left: 0;
					padding-right: 3rem;
				}

				.one-up-img {
					margin-right: -2rem;
				}

				.one-up-content {
					padding: 2.5rem 0 2.5rem 2.5rem;
				}
			}
		}

		&.right {
			.one-up-content {
				padding-left: 5rem;
			}
		}

		.one-up-content {
			padding-right: 5rem;
		}
	}
}
