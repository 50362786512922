.bi-scheduling-success {
	display: flex;
	flex-direction: column;
	padding-top: 1.5rem;
	margin-left: auto !important;
	margin-right: auto !important;
	margin-bottom: 3rem;

	.bi-scheduling-inner {
		@include make-container();
		@include make-container-max-width();
		display: flex;
		flex-direction: column;
	}

	.bi-scheduling-card {
		border-top: 1px solid $color-gray-light;
		border-radius: 0 0 6px 6px;
		margin-top: 2rem;
		padding-top: 2rem;
	}

	.card-header,
	.card-footer {
		background-color: $color-white;
		border: none;
	}

	.card-header {
		padding: 1.5rem 1.5rem 0.5rem 1.5rem;
	}

	.card-footer {
		background-color: $color-gray-lightest;
		border-radius: 5px;
		padding: 0.5rem 1.5rem 1.5rem;
	}

	.print-details {
		display: flex;
		flex-direction: column;
		border-top-width: 1px;
		margin-top: 2rem;
		margin-bottom: 2rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
		align-items: center;

		a {
			// padding-top: 1.5rem;
			font-weight: $font-weight-bold;
		}
	}

	.profile-image {
		margin: 0 1rem 1rem 0;
		object-fit: cover;
		object-position: top;
		font-family: "object-fit: cover; object-position: top;";
		background-color: #fff;
		height: 128px;
		min-width: 128px;
		overflow: hidden;
		position: relative;
		width: 128px;
	}

	.appointment-info {
		@include text(beta);
		color: $color-primary-dark-blue;
		font-weight: $font-weight-bold;
		margin-bottom: 0.5rem;
	}

	.doctor-details {
		padding-right: 1rem;

		span {
			font-weight: $font-weight-semi-bold;
		}
	}

	.doctor-name {
		@include text(delta);
		display: inline-block;
		font-weight: $font-weight-bold;
		margin-bottom: 0.5rem;
	}

	.location-address {
		@include text(zeta);
		margin-bottom: 1rem;

		&:empty {
			display: none;
		}
	}

	.distance-and-location,
	.appointment-request-phone {
		@include text(zeta);
		color: $color-secondary-light-blue;
		font-weight: $font-weight-semi-bold;
	}

	.instructions {
		display: flex;
		flex-direction: column;
		background-color: $color-neutral-lighter;
		border-radius: 6px;
		margin-top: 1.75rem;
	}

	.instructions-title {
		@include text(gamma);
		color: $color-primary-dark-blue;
		font-weight: $font-weight-semi-bold;
	}

	.instructions-details {
		@include text(zeta, delta);
	}

	.appointment-details {
		#demographics {
			padding: 2rem 0;

			h2 {
				@include text(alpha);
				font-weight: $font-weight-bold;
				color: $color-primary-dark-blue;
			}

			label {
				@include text(delta);
				font-weight: $font-weight-bold;
				color: $color-primary-dark-blue;
			}
		}
	}

	.specialty {
		@include text(zeta,epsilon);
	}



	@include media-breakpoint-up(md) {
		height: calc(100% - #{$profile-card-offset});
		justify-content: space-between;

		.print-details {
			flex-direction: row;
    		justify-content: space-between;
		}
	}

	@include media-breakpoint-up(lg) {
		.bi-scheduling-inner {
			// flex-direction: row;
		}

		.heading-2 {
			padding-top: 3rem;
		}

		.appointment-details {
			// flex: 0 0 70%;
			// border-top: 1px solid $color-gray-light;
			// padding-right: 3.5rem;
		}

		.bi-scheduling-card {
			flex-direction: row;
		}

		.profile-image {
			margin: 0 1rem 0 0;
		}

		.card-header,
		.card-footer {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			flex: 1 1 0%;
		}

		.card-header {
			padding: 1rem 0.5rem 1rem 1rem;
		}

		.card-footer {
			align-items: flex-start;
			padding: 1.5rem;
			justify-content: space-around;
			flex-direction: column;

			.location-address {
				margin-bottom: 0;
			}
		}

		.instructions {
			// flex: 0 0 30%;
			// border: 1px solid $color-gray-light;
			// border-radius: 0;
		}
	}

	@include media-breakpoint-up(xl) {
	}
}
