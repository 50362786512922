.doctor-appointments {
	.doctor-result {
		align-items: center;
		background-color: $color-white;
		border: none;
		height: auto;
		justify-content: space-between;
		margin: 0;
		padding: 1rem 15px;
	}

	.profile-image {
		margin: 0 0 2rem 0;
	}

	.doctor-details {
		margin-bottom: 1rem;

		&.name-container {
			text-align: center;
		}
	}

	.distance-and-location {
		text-align: center;
	}

	.doctor-location {
		padding: 2rem 0 0;
	}

	.location-select {
		.select-wrapper {
			align-items: center;
			background-color: $color-neutral-lightest;
			color: $color-primary-dark-blue;
			display: flex;
			padding: 0.375rem;
			position: relative;
			margin: 0.25rem -0.375rem;

			&:focus {
				border-color: $color-secondary-dark-teal;
				box-shadow: 0 0 0 0.2rem rgba($color-secondary-dark-teal, 0.5);
			}

			select {
				@include text('zeta');
				background-color: transparent;
				border: 0;
				color: $color-neutral-dark;
				display: block;
				padding-right: 2rem;
				white-space: normal;
				width: 100%;

				/* for Firefox */
				-moz-appearance: none;
				/* for Chrome */
				-webkit-appearance: none;

				/* For IE10 */
				&::-ms-expand {
					display: none;
				}

				&:focus {
					outline: 0;
				}
			}

			.icon {
				pointer-events: none;
				position: absolute;
				right: 0.5rem;
				stroke: $color-primary-light-blue;
				top: 0.5rem;
				width: 0.75rem;
			}
		}
	}

	.location-address {
		@include text(zeta);
		margin-bottom: 1rem;
	}

	.phone-number {
		@include text(zeta);
	}

	@include media-breakpoint-up(lg) {
		.doctor-result {
			flex-direction: row;
			justify-content: flex-start;
			padding: 1rem 3rem 1rem 2rem;
		}

		.profile-image {
			height: 100px;
			margin: 0 1.5rem 0 0;
			min-width: 100px;
			width: 100px;
		}

		.doctor-details {
			flex-grow: 1;
			margin-bottom: 0;
			margin-right: 2rem;

			&.name-container {
				text-align: left;
			}

			&:last-child {
				max-width: 45%;
			}
		}

		.distance-and-location {
			text-align: left;
		}

		.doctor-location {
			padding: 1rem 0 0;
		}
	}
}
