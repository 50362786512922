.quizlet {
	@include make-container();
	@include make-container-max-width();
	background-color: $color-neutral-lightest;
	margin-bottom: 2rem;

	&.intro {
		.quizlet-progress,
		form {
			display: none;
		}

		.quizlet-intro {
			align-items: center;
			display: flex;
			flex-direction: column;


			.marketo-form input[type='checkbox'] {
				&:checked {
					+ label {
						&:after {
							border-bottom: 2px solid $color-white;
							border-right: 2px solid $color-white;
						}
					}
				}
			}

			form {
				display: block;
			}
		}
	}

	&.completed {
		.quizlet-heading,
		.quizlet-progress-container {
			display: none;
		}

		.retake-btn {
			display: block;
			margin-top: 1.5rem;
		}
	}

	.inner-container {
		padding: 2rem 0;
		background-color: $color-neutral-lightest;
	}

	.quizlet-heading,
	.outcome-heading {
		border: none;
		color: $color-primary-dark-blue;
		margin-bottom: 2rem;
		text-align: center;
	}

	.quizlet-intro {
		display: none;
	}

	.quizlet-progress {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 3rem;
		position: relative;

		&.center-sm {
			justify-content: center;
		}

		.start-over-btn {
			display: none;
			@include text(eta);
			font-weight: $font-weight-semi-bold;
			padding: 0 0 2rem;
			position: relative;
			left: 0;
			width: 100%;
			text-align: center;

			&.visible {
				display: block;
			}
		}
	}

	.question-indicator {
		display: flex;
		flex: 0 1 20%;
		justify-content: center;
		margin-bottom: 1.125rem;
		position: relative;

		&:not(:first-of-type) {
			&:before {
				content: '';
				display: block;
				width: 100%;
				background-color: $color-secondary-blue;
				height: 1px;
				left: -50%;
				position: absolute;
				top: 50%;
			}
		}

		&:nth-of-type(5n + 1) {
			&:before {
				display: none;
			}
		}

		&.completed {
			.question-number {
				background-color: $color-gray-darkest;
				border-color: $color-gray-darkest;
				color: $color-gray-light;
			}
		}

		&.active {
			.question-number {
				background-color: $color-secondary-dark-teal;
				border-color: $color-secondary-dark-teal;
				color: $color-white;
			}
		}
	}

	.question-number {
		@include text(eta);
		align-items: center;
		background-color: $color-white;
		border: 2px solid $color-secondary-dark-teal;
		border-radius: 15px;
		color: $color-secondary-dark-teal;
		display: flex;
		font-weight: $font-weight-bold;
		height: 30px;
		justify-content: center;
		position: relative;
		width: 30px;
		z-index: 1;
	}

	.question-list {
		padding: 0;
		list-style-type: none;
	}

	.question-panel {
		display: none;

		&.active {
			display: block;
		}
	}

	.question-desc {
		@include text(epsilon);
		color: $color-black;
		margin: 0 auto 2rem;
	}

	.answer-grid {
		display: flex;
		flex-flow: wrap;
		justify-content: space-between;

		&.true-false {
			&:after {
				display: none;
			}

			.answer-item {
				flex: 0 1 48.5%;
				max-width: 48.5%;

				&:first-of-type {
					label {
						margin-left: auto;
					}
				}
			}

			.answer-content {
				padding-bottom: 1rem;
			}

			input[type='radio'] {
				+ label {
					max-width: 190px;

					&:before {
						bottom: 50%;
						left: auto;
						right: 1rem;
						transform: translateY(50%);
					}

					&:after {
						bottom: 50%;
						left: auto;
						margin: 0;
						right: 1.125rem;
						transform: translateY(50%);
					}
				}
			}
		}

		&.agree-disagree {
			flex-direction: column;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: 1px;
				background-color: $color-secondary-blue;
				height: 80%;
				position: absolute;
				left: 12px;
				top: 11px;
				transform: translateX(-50%);
			}

			.answer-item {
				flex: 0 1 100%;
				max-width: 100%;
			}

			.answer-content {
				background-color: transparent;
				padding: 0.125rem 0 0 2rem;
			}

			input[type='radio'] {
				&:checked {
					+ label {
						border-color: transparent;
					}
				}

				+ label {
					&:before {
						background-color: $color-neutral-lightest;
						bottom: auto;
						left: 0;
						margin: 0;
					}

					&:after {
						bottom: auto;
						left: 2px;
						margin: 0;
						top: 2px;
					}
				}
			}
		}
	}

	.answer-item {
		flex: 0 1 48.5%;
		margin-bottom: 2rem;
		max-width: 48.5%;
		position: relative;

		label {
			@include text(zeta);
			border: 2px solid transparent;
			color: $color-primary-dark-blue;
			display: flex;
			flex-direction: column;
			height: 100%;
			margin: 0;
			position: relative;
		}
	}

	.answer-content {
		background-color: $color-white;
		display: block;
		height: 100%;
		padding: 1rem 1rem 3.5rem;
		border-radius: 5px;
	}

	input[type='checkbox'] {
		margin-top: 2px;
		opacity: 0;

		&:checked {
			+ label {
				border-color: $color-neutral-light;
				border-radius: 5px;

				.answer-content {
					background-color: transparent;
				}

				&:before {
					background-color: $color-primary-dark-blue;
				}

				&:after {
					border-bottom: 3px solid $color-white;
					border-right: 3px solid $color-white;
					content: '';
					display: block;
					height: 1.375rem;
					right: 1rem;
					margin: 0.375rem 0.625rem;
					position: absolute;
					bottom: 1rem;
					transform: rotate(45deg) translate(-2px, -3px);
					width: 0.875rem;
				}
			}
		}

		&:hover {
			cursor: pointer;

			+ label {
				&:before {
					border-color: $color-primary-light-blue;
				}
			}
		}

		&:focus {
			+ label {
				&:before {
					box-shadow: 0 0 0 0.2rem
						rgba($color-primary-light-blue, 0.25);
				}
			}
		}

		+ label {
			&:before {
				border: 2px solid $color-primary-dark-blue;
				border-radius: 3px;
				content: '';
				display: block;
				height: 2.125rem;
				right: 1rem;
				position: absolute;
				bottom: 1rem;
				width: 2.125rem;
			}

			&:hover {
				cursor: pointer;

				&:before {
					border-color: $color-primary-light-blue;
				}
			}
		}
	}

	input[type='radio'] {
		opacity: 0;
		position: absolute;

		&:checked {
			+ label {
				border-color: $color-neutral-light;
				border-radius: 5px;

				.answer-content {
					background-color: transparent;
				}

				&:before {
					border-color: $color-white;
				}

				&:after {
					background-color: $color-primary-dark-blue;
					border-radius: 50%;
					content: '';
					display: block;
					height: 1rem;
					bottom: 1.25rem;
					left: 0;
					right: 0;
					margin: 0.125rem auto;
					position: absolute;
					width: 1rem;
				}
			}

			&:hover {
				+ label {
					&:before {
						border-color: $color-white;
					}
				}
			}
		}

		&:hover {
			cursor: pointer;

			+ label {
				&:before {
					border-color: $color-primary-light-blue;
				}
			}
		}

		&:focus {
			+ label {
				&:before {
					box-shadow: 0 0 0 0.2rem
						rgba($color-primary-light-blue, 0.25);
				}
			}
		}

		+ label {
			&:before {
				border: 2px solid $color-primary-dark-blue;
				border-radius: 50%;
				content: '';
				display: block;
				height: 1.25rem;
				bottom: 1.25rem;
				left: 0;
				right: 0;
				margin: auto;
				position: absolute;
				width: 1.25rem;
			}

			&:hover {
				cursor: pointer;

				&:before {
					border-color: $color-primary-light-blue;
				}
			}
		}
	}

	.answer-image-container {
		display: block;
		height: 0;
		overflow: hidden;
		padding-bottom: 40%;
		position: relative;
		width: 100%;
	}

	.answer-image {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		img {
			font-family: 'object-fit: cover; object-position: center 25%;';
			height: 100%;
			object-fit: cover;
			object-position: center 25%;
			width: 100%;
		}
	}

	.validation-message,
	.contact-validation-message,
	.email-validation-message {
		color: $color-red;
		display: none;

		&.active {
			display: block;
		}
	}

	.form-control {
		margin-bottom: 1rem;

		&[type='email'] {
			margin-bottom: 0;

			&.invalid {
				+ .email-validation-message {
					display: block;
				}
			}
		}
	}

	.btn {
		display: block;
		margin-top: 2rem;
	}

	.prev-next-btns {
		display: flex;
    	flex-flow: column-reverse;

		.btn-prev {
			background-color: $color-gray-darkest;
			color: $color-white;

			&:hover {
				cursor: pointer;
				background-color: transparent;
				color: $color-gray-darkest;
				border-color: $color-gray-darkest;

				&:before {
					border-color: $color-gray-darkest;
				}
			}
		}
	}

	.btn-next,
	.btn-submit {
		margin-left: auto;
	}

	.reset-btn {
		@include text(epsilon);
		color: $color-primary-blue;
		font-weight: $font-weight-normal;
		padding: 0;
		margin: auto;
		text-align: left;
	}

	.retake-btn {
		display: none;
	}

	.outcome {
		display: none;

		&.active {
			align-items: center;
			display: flex;
			flex-direction: column;
		}
	}

	.media-item {
		padding: 0;
	}

	button[type='submit'].mktoButton {
		display: none !important;
	}

	.marketo-form {
		margin-top: 1.5rem;
		margin-bottom: 0;

		.mktoForm {
			margin-left: auto;
			margin-right: auto;


			.mktoFormRow {
				margin-bottom: 1.5rem !important;
				margin: auto;
			}

			.mktoField {
				background-color: $color-white !important;
			}
		}

		.g-recaptcha {
			height: 0;
			margin: 0;
		}
	}

	@include media-breakpoint-up(sm) {

		.prev-next-btns {
			display: flex;
			flex-flow: row;
			justify-content: space-between;

			[value='Next'] {
				margin-left: auto;
			}
		}

	}

	@include media-breakpoint-up(md) {
		background-color: transparent;

		.inner-container {
			padding: 2.5rem 2.25rem;
		}

		.question-desc {
			width: 80%;
		}

		.answer-grid {
			&.agree-disagree {
				flex-direction: row;
				margin: auto;
				position: relative;
				width: 80%;

				&:before {
					height: 1px;
					width: 80%;
					left: 0;
					right: 0;
					margin: auto;
					top: 28px;
					transform: translateY(-50%);
				}

				.answer-item {
					flex: 0 1 20%;
					max-width: 20%;
				}

				.answer-content {
					background-color: transparent;
					padding: 3.5rem 1rem 1rem;
				}

				input[type='radio'] {
					&:checked {
						+ label {
							border-color: transparent;
						}
					}

					+ label {
						text-align: center;

						&:before {
							background-color: $color-neutral-lightest;
							bottom: auto;
							left: 0;
							margin: auto;
							right: 0;
							top: 1rem;
						}

						&:after {
							bottom: auto;
							left: 0;
							margin: auto;
							right: 0;
							top: 1.125rem;
						}
					}
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.quizlet-progress-container {
			display: flex;
			justify-content: center;
		}

		.quizlet-progress {
			display: inline-flex;
			margin: 0 auto 2rem;

			&.center-lg {
				justify-content: center;
			}

			.start-over-btn {
				display: none;
				@include text(eta);
				font-weight: $font-weight-semi-bold;
				padding: 0;
				position: absolute;
				top: -25px;
				left: 0;
				width: auto;

				&.visible {
					display: block;
				}
			}

			.reset-btn {
				top: 5px;
				transform: translateX(-100%);
			}
		}

		.question-indicator {
			flex: 0 1 10%;
			min-width: 50px;

			&:nth-of-type(5n + 1) {
				&:before {
					display: block;
				}
			}

			&:nth-of-type(10n + 1) {
				&:before {
					display: none;
				}
			}
		}

		.answer-grid {
			// Holds space for row with two items
			&:after {
				content: ' ';
				flex: 0 1 31.5%;
				max-width: 31.5%;
			}
		}

		.answer-item {
			flex: 0 1 31.5%;
			margin-bottom: 2.5rem;
			max-width: 31.5%;
		}
	}

	@include media-breakpoint-up(xl) {
		.inner-container {
			padding: 3.5rem 10rem;
		}

		.media-item {
			&.full-width {
				margin-left: -40px;
				width: calc(100% + 80px);

				.figure-img {
					max-height: none;
				}
			}
		}
	}
}
