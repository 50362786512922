/*
 *
 * COLOR BACKGROUND CLASSES
 *
 */

/* 2024 Primary Color Backgrounds */
.bg-precision-gray {
	background-color: $color-precision-gray;
}
.bg-illumination-yellow {
	background-color: $color-illumination-yellow;
}
.bg-sky-blue {
	background-color: $color-sky-blue;
}

/* 2024 Secondary Color Backgrounds */
.bg-sky-blue-ish-tint {
	background-color: $color-sky-blue-ish-tint;
}
.bg-precision-gray-tint {
	background-color: $color-precision-gray-tint;
}
.bg-precision-gray-ish-tint {
	background-color: $color-precision-gray-ish-tint;
}
.bg-illumination-yellow-ish-tint {
	background-color: $color-illumination-yellow-ish-tint;
}

/* 2024 Tertiary Color Backgrounds */
.bg-deep-green {
	background-color: $color-deep-green;
}
.bg-overcast-blue {
	background-color: $color-overcast-blue;
}
.bg-glacier-blue {
	background-color: $color-glacier-blue;
}
.bg-graham-gray {
	background-color: $color-graham-gray;
}


/* Primary Color Backgrounds */
.bg-primary-yellow {
	background-color: $color-primary-yellow;
}
/* ~~IN 2024 Site Redesign - kbma 'precision grey' */
.bg-primary-dark-blue {
	background-color: $color-primary-dark-blue;
}

.bg-primary-blue {
	background-color: $color-primary-blue;
}

.bg-primary-blue-gray {
	background-color: $color-primary-blue-gray;
}

/* Secondary Color Backgrounds */
.bg-secondary-dark-teal {
	background-color: $color-secondary-dark-teal;
}

.bg-secondary-blue {
	background-color: $color-secondary-blue;
}

.bg-secondary-light-blue {
	background-color: $color-secondary-light-blue;
}

.bg-secondary-teal {
	background-color: $color-secondary-teal;
}

.bg-secondary-light-teal {
	background-color: $color-secondary-light-teal;
}

/* Neutral Color Backgrounds */
.bg-neutral-darkest {
	background-color: $color-neutral-darkest;
}

.bg-neutral-dark {
	background-color: $color-neutral-dark;
}

.bg-neutral {
	background-color: $color-neutral;
}

.bg-neutral-light {
	background-color: $color-neutral-light;
}

.bg-neutral-lighter {
	background-color: $color-neutral-lighter;
}

.bg-neutral-lightest {
	background-color: $color-neutral-lightest;
}

/* Gray Color Backgrounds */
.bg-gray-darkest {
	background-color: $color-gray-darkest;
}

.bg-gray-light {
	background-color: $color-gray-light;
}

.bg-gray-lightest {
	background-color: $color-gray-lightest;
}

.bg-gray-secondary {
	background-color: $color-gray-secondary;
}

/* Utility Color Backgrounds */
.bg-black {
	background-color: $color-black;
}

.bg-white {
	background-color: $color-white;
}

.bg-green {
	background-color: $color-green;
}

.bg-dark-red {
	background-color: $color-dark-red;
}

.bg-red {
	background-color: $color-red;
}

.bg-light-red {
	background-color: $color-light-red;
}
