.microsite-theme {
	.media-summary {
		.media-summary-inner {
			background-color: $color-neutral-lighter;
		}

		.media-summary-player {
			background-color: $color-neutral-lighter;
		}

		.media-summary-content {
			background-color: $color-neutral-lighter;
			padding: 1.5rem 1.5rem 2rem;

			.btn {
				width: 100%;
			}
		}

		.heading-4 {
			@include text(delta, 30);
			color: $color-black;
			font-weight: $font-weight-bold;
		}

		.heading-3 {
			color: $color-black;
			font-size: 30px;
			font-weight: $font-weight-bold;
		}

		p {
			@include text(delta, 30);
		}

		@include media-breakpoint-up(md) {
			.media-summary-content {
				.btn {
					width: auto;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			&:not(.always-stacked) {
				.media-summary-player {
					flex: 0 0 calc(50% - 0.5rem);
					max-width: calc(50% - 0.5rem);
					position: relative;

					.media-item:not(.is-video) {
						bottom: 0;
						position: absolute;
						top: 0;

						.figure-img {
							font-family: 'object-fit: cover; object-position: center;';
							height: 100%;
							object-fit: cover;
						}
					}
				}

				.media-summary-content {
					flex: 0 0 calc(50% + 0.5rem);
					max-width: calc(50% + 0.5rem);
				}
			}

			.media-summary-content {
				background-color: $color-neutral-lighter;
				padding: 2.5rem 2.5rem 2.75rem;
			}
		}
	}

	.three-column {
		.media-summary {
			.media-summary-content {
				padding: 2.25rem 2.5rem 2.5rem;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.two-column {
			.media-summary {
				.media-summary-content {
					padding: 2.25rem 2.5rem 2.5rem;
				}
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.two-column {
			.media-summary {
				&:not(.always-stacked) {
					.media-summary-player {
						flex: 0 0 calc(50% - 0.5rem - 3rem);
						max-width: calc(50% - 0.5rem - 3rem);
					}

					.media-summary-content {
						flex: 0 0 calc(50% + 0.5rem + 3rem);
						max-width: calc(50% + 0.5rem + 3rem);
						padding-left: calc((0.5rem + 3rem) * 2);
					}
				}

				&.right {
					&:not(.always-stacked) {
						.media-summary-content {
							padding-left: 2.5rem;
							padding-right: calc((0.5rem + 3rem) * 2);
						}
					}
				}
			}

			.two-column {
				.row {
					[class^='col'] {
						&:first-of-type {
							.media-summary {
								&.always-stacked {
									padding-right: 3rem;
								}
							}
						}

						&:last-of-type {
							.media-summary {
								&.always-stacked {
									padding-left: 3rem;
								}
							}
						}
					}
				}
			}
		}
	}
}
