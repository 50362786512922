.callout {
	background-color: $color-white;

	.callout-inner {
		@include make-container-max-width();
		margin-bottom: 1rem;
		padding-top: 1rem;
	}

	.callout-call-button {
		align-items: center;
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		padding: 0.75rem 3rem 2rem;

		.btn {
			@include text(gamma);
			@include button-variant(
				$color-white,
				$color-neutral-light,
				rgba($color-white, 0.6),
				$color-neutral-light
			);
			border-radius: 10px;
			border-width: 6px;
			color: $color-primary-dark-blue;
			font-weight: $font-weight-semi-bold;
			padding: 0.625rem 1.75rem;
		}

		.icon {
			height: 60px;
			margin-bottom: 1rem;
			min-width: 60px;
			width: 60px;
		}
	}

	.heading-4 {
		@include text(gamma, delta);
		color: $color-primary-dark-blue;
		font-weight: $font-weight-semi-bold;
		margin-bottom: 1.5rem;
	}

	.divider-header {
		margin-bottom: 1.5rem;
	}

	p {
		@include text(zeta, epsilon);
		color: $color-neutral-darkest;
		margin-bottom: 1.875rem;
	}

	.btn {
		padding-top: $input-btn-padding-y-sm;
		padding-bottom: $input-btn-padding-y-sm;
	}

	.card-deck {
		display: flex;
		flex-direction: column;
		margin-left: auto;
		margin-right: auto;
	}

	.callout-img {
		padding: 2.5em 2rem 1rem;

		&.health-wellness-img {
			img {
			}
		}

		img {
			height: 90px;
		}
	}

	.callout-content {
		padding: 2rem 2rem 0;
	}

	.callout-cta {
		background-color: transparent;
		border-top: none;
		padding: 0 2rem 2.5rem;
	}

	.callout-content-container {
		background-color: $color-neutral-lighter;
		border: 0;
		border-radius: 5px;
		// border-bottom: 1px solid $color-neutral-light;
		flex-basis: 100%;
		height: auto;
		max-width: 100%;
		margin-bottom: 1rem;
		margin-left: 15px;
		margin-right: 15px;
		padding: 0;
		&:last-child {
			border-bottom: 0;
		}
	}

	@include media-breakpoint-up(sm) {
		.card-deck {
			margin-top: 0;
		}
	}

	@include media-breakpoint-up(md) {
		.callout-call-button {
			flex-direction: row;
			padding: 0.75rem 0 0;
			margin-bottom: 2.25rem;

			.btn {
				padding: 0.625rem 2rem;
			}

			.icon {
				margin-bottom: 0;
				margin-left: 1rem;
			}
		}

		.heading-3 {
			@include text(beta, beta);
			padding-top: 2rem;
		}

		.callout-inner {
			@include make-container();
			@include make-container-max-width();
			padding-top: 1rem;
		}

		.card-deck {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			padding-top: 1rem;
			padding-left: 15px;
			padding-right: 15px;
		}

		.callout-content {
			padding: 1.75rem 2rem 1rem;
		}

		.callout-content-container {
			// background-color: $color-white;
			border: 0;
			// border-bottom: 0;
			padding: 0;
			margin-bottom: 2rem;
			// box-shadow: 0 3px 8px rgba(0, 0, 0, 0.18);
			margin-left: 0;
			margin-right: 0;
			&:first-child:nth-last-child(2),
			&:first-child:nth-last-child(2) ~ .callout-content-container,
			&:first-child:nth-last-child(4),
			&:first-child:nth-last-child(4) ~ .callout-content-container {
				flex-basis: calc((100% / 2) - 10px);
				max-width: calc((100% / 2) - 10px);
			}
			&:first-child:nth-last-child(3),
			&:first-child:nth-last-child(3) ~ .callout-content-container,
			&:first-child:nth-last-child(5),
			&:first-child:nth-last-child(5) ~ .callout-content-container,
			&:first-child:nth-last-child(6),
			&:first-child:nth-last-child(6) ~ .callout-content-container {
				flex-basis: calc((100% / 2) - 10px);
				max-width: calc((100% / 2) - 10px);
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.callout-call-button {
			.btn {
				padding: 0.625rem 3rem;
			}
		}

		.btn {
			padding-left: 1.875rem;
			padding-right: 1.875rem;
		}

		.callout-content-container {
			&:first-child:nth-last-child(2),
			&:first-child:nth-last-child(2) ~ .callout-content-container,
			&:first-child:nth-last-child(4),
			&:first-child:nth-last-child(4) ~ .callout-content-container {
				flex-basis: calc((100% / 2) - 10px);
				max-width: calc((100% / 2) - 10px);
			}
			&:first-child:nth-last-child(3),
			&:first-child:nth-last-child(3) ~ .callout-content-container,
			&:first-child:nth-last-child(5),
			&:first-child:nth-last-child(5) ~ .callout-content-container,
			&:first-child:nth-last-child(6),
			&:first-child:nth-last-child(6) ~ .callout-content-container {
				flex-basis: calc((100% / 3) - 10px);
				max-width: calc((100% / 3) - 10px);
			}
			&:first-child:nth-last-child(5),
			&:first-child:nth-last-child(5) ~ .callout-content-container {
				&:nth-last-child(2) {
					margin-left: auto;
					margin-right: 0.5rem;
				}
				&:nth-last-child(1) {
					margin-left: 0.5rem;
					margin-right: auto;
				}
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.callout-img {
			padding: 2.5rem 3rem 1rem;
		}

		.callout-content {
			padding: 1.75rem 3rem 1rem;
		}

		.callout-cta {
			padding: 0 3rem 2.5rem;
		}

		.callout-content-container {
			&:first-child:nth-last-child(4),
			&:first-child:nth-last-child(4) ~ .callout-content-container {
				flex-basis: calc((100% / 4) - 10px);
				max-width: calc((100% / 4) - 10px);
			}
		}
	}
}
