.custom-modal {
	height: 100%;
	left: 0;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	top: 0;
	transition: opacity 0.2s ease-in-out;
	transition-delay: 0.2s;
	width: 100%;
	z-index: 10;

	.custom-modal-open & {
		opacity: 1;
		overflow: auto;
		pointer-events: auto;
		transition-delay: 0s;
	}

	.modal-grid {
		margin: 0 auto;
		overflow: hidden;
	}

	.modal-content {
		background: $color-white;
		border-radius: 0;
		min-height: 100%;
		opacity: 0;
		position: relative;
		transition: all 0.2s ease-in-out;
		transform: translateY(3rem);
		width: 100%;

		.custom-modal-open & {
			opacity: 1;
			transform: translateY(0);
			transition-delay: 0.2s;
		}

		.modal-close-btn {
			@include text(epsilon, eta);
			background: none;
			border: none;
			color: $color-primary-light-blue;
			font-weight: $font-weight-extra-bold;
			right: 1.5rem;
			padding: 0;
			position: absolute;
			top: 1.5rem;

			.icon {
				fill: $color-primary-light-blue;
			}

			&:hover,
			&:focus {
				outline: 0;

				.icon {
					fill: $color-secondary-light-blue;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		align-items: center;
		background-color: rgba($color-white, 0.5);
		display: flex;
		justify-content: center;
		overflow: auto;

		.modal-scroll-container {
			max-height: 100vh;
			overflow: auto;
			width: 100%;
		}

		.modal-grid {
			@include make-container();
			@include make-container-max-width();
			align-items: flex-start;
			display: flex;
			justify-content: center;
			max-width: none;
		}

		.modal-content {
			@include make-col(8);
			border: solid $profile-card-border-width $color-neutral-light;
			box-shadow: 0 10px 24px rgba($color-black, 0.12);
			height: auto;
			margin: 3rem 0;
			min-height: auto;
		}
	}
}
