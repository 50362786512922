.advance--podcast-player {
    padding-top: $padding-advance-section-mobile;
    padding-bottom: $padding-advance-section-mobile;

    .advance--podcast-player--container {
        max-width: 845px;
        margin: 0 auto;
        padding-bottom: 17px;
    }

    .audio-player {
        padding: 10px 0 0;
        margin-bottom: 22px;
        .audio-player--controls {
            display: flex;
            justify-content: center;
            margin-top: 22px;
            position: relative;
            .navigation-controls {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                position: relative;
                .play-button {
                    background: $color-advance-yellow;
                    border-radius: 50%;
                    height: 60px;
                    width: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 30px;
                    border: none;
                    .play-button--svg {
                        border: 0;
                        background: transparent;
                        box-sizing: border-box;
                        width: 0;
                        height: 32px;
                        border-color: transparent transparent transparent $color-advance-dark-teal;
                        transition: 300ms opacity ease;
                        cursor: pointer;
                        border-style: solid;
                        border-width: 16px 0 16px 24px;
                        margin-left: 5px;
                    }
                    &.playing .play-button--svg {
                        border-width: 0px 7px 0px 7px;
                        margin-left: 0px;
                        width: 25px;
                        border-color: transparent $color-advance-dark-teal;
                        transition: 300ms all ease;
                    }
                    &:hover .play-button--svg {
                        opacity: 0.9;
                    }
                    &:focus .play-button--svg {
                        outline: none;
                    }
                }
                .skip-icon {
                    height: 26px;
                    width: 26px;
                    cursor: pointer;
                }
            }
            .volume-control {
                display: none;
                align-items: center;
                gap: 10px;
                #volume-slider input {
                    width: 87px;
                }
                #mute-button {
                    cursor: pointer;
                    svg {
                        width: 27px;
                        height: 23px;
                    }
                }
            }
            .advance-podcast--overflow {
                cursor: pointer;
                position: absolute;
                top: 10px;
                right: 0;
                .dropdown-toggle:after {
                    content: none;
                }
                .advance-podcast--overflow-button {
                    float: right;
                    width: 30px;
                    svg {
                        width: 26px;
                    }
                }
                .dropdown-menu {
                    top: 30px;
                    left: auto;
                    right: 0;
                    width: 170px;
                    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.1);
                    border-color: $color-advance-off-white;
                    .dropdown-menu {
                        top: -1px;
                        right: -1px;
                    }
                }
                .dropdown-item {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    justify-content: flex-start;
                    font-size: 12px;
                    line-height: initial;
                    font-weight: 700;
                    height: 40px;
                    padding: 0 0 0 22px;
                    background: $color-advance-white;
                    &.btn-group {
                        padding: 0;
                        position: initial;
                        .sub-menu svg {
                            width: 7px;
                            height: 14px;
                        }
                    }
                    svg,
                    .icon {
                        width: 20px;
                        height: 20px;
                    }
                    &:hover {
                        background: $color-advance-light-teal;
                        color: $color-advance-dark-teal;
                    }
                    &.playback-speed-option {
                        font-weight: 400;
                        font-size: 14px;
                    }
                    &.active {
                        font-weight: 700;
                        background: $color-advance-white;
                        color: $color-advance-dark-teal;
                        position: relative;
                        // &::after {
                        //     //content: url("../images/icons/check.svg");
                        //     display: inline-block;
                        //     width: 20px; //only one size is alright, IE scales uniformly to fit it
                        //     height: 14px;
                        //     position: absolute;
                        //     top: 13px;
                        //     right: 29px;
                        //     background: $color-advance-yellow;
                        //     content: "";
                        //     -webkit-mask: url("../images/icons/check.svg") no-repeat 50% 50%;
                        //     mask: url("../images/icons/check.svg") no-repeat 50% 50%;
                        //     -webkit-mask-size: contain;
                        //     mask-size: contain;
                        // }
                        &::after {
                            border-bottom: 3px solid #f7aa00;
                            border-right: 3px solid #f7aa00;
                            content: "";
                            display: block;
                            margin: 0.25rem 0.375rem;
                            position: absolute;
                            top: 7px;
                            right: 29px;
                            -webkit-transform: rotate(45deg) translate(-1px, -1px);
                            -ms-transform: rotate(45deg) translate(-1px, -1px);
                            transform: rotate(45deg) translate(-1px, -1px);
                            width: 0.5rem;
                            height: 1rem;
                        }
                    }
                }
            }
            input[type="range"] {
                &::-webkit-slider-runnable-track {
                    background: $color-advance-light-grey;
                }
                &::before {
                    background: $color-advance-dark-teal;
                }
                &::-webkit-slider-thumb {
                    border: 1px solid $color-advance-dark-teal;
                    background-color: $color-advance-dark-teal;
                }
                &:active::-webkit-slider-thumb {
                    background: $color-advance-dark-teal;
                }
                &::-moz-range-track {
                    background: $color-advance-light-grey;
                }
                &::-moz-range-progress {
                    background-color: $color-advance-dark-teal;
                    height: 7px;
                    border-radius: 4px 0 0 4px;
                }
                &::-moz-range-thumb {
                    border: 1px solid $color-advance-dark-teal;
                    background-color: $color-advance-dark-teal;
                }
                &:active::-moz-range-thumb {
                    background: $color-advance-dark-teal;
                }
                &::-ms-fill-lower {
                    background-color: $color-advance-dark-teal;
                }
                &::-ms-fill-upper {
                    background: linear-gradient(
                        to right,
                        $color-advance-dark-teal var(--buffered-width),
                        $color-advance-light-grey var(--buffered-width)
                    );
                }
                &::-ms-thumb {
                    border: 1px solid $color-advance-dark-teal;
                    background-color: $color-advance-dark-teal;
                }
                &:active::-ms-thumb {
                    background: $color-advance-dark-teal;
                }
            }
        }

        .audio-player--progress {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            gap: 19px;
            input[type="range"] {
                width: 100%;
                &::-webkit-slider-runnable-track {
                    background: $color-advance-light-grey;
                }
                &::before {
                    background-color: $color-advance-yellow;
                }
                &::-webkit-slider-thumb {
                    border: 1px solid $color-advance-yellow;
                    background-color: $color-advance-yellow;
                }
                &:active::-webkit-slider-thumb {
                    background: $color-advance-yellow;
                }
                &::-moz-range-track {
                    background: $color-advance-light-grey;
                }
                &::-moz-range-progress {
                    background-color: $color-advance-yellow;
                    height: 7px;
                    border-radius: 4px 0 0 4px;
                }
                &::-moz-range-thumb {
                    border: 1px solid $color-advance-yellow;
                    background-color: $color-advance-yellow;
                }
                &:active::-moz-range-thumb {
                    background: $color-advance-yellow;
                }
                &::-ms-fill-lower {
                    background-color: $color-advance-light-grey;
                }
                &::-ms-fill-upper {
                    background: linear-gradient(
                        to right,
                        $color-advance-light-grey var(--buffered-width),
                        $color-advance-light-teal var(--buffered-width)
                    );
                }
                &::-ms-thumb {
                    border: 1px solid $color-advance-light-grey;
                    background-color: $color-advance-light-grey;
                }
                &:active::-ms-thumb {
                    background: $color-advance-light-grey;
                }
            }
        }

        input[type="range"] {
            position: relative;
            -webkit-appearance: none;
            margin: 0;
            padding: 0;
            height: 17px;
            margin-left: 0;
            float: left;
            outline: none;

            &::-webkit-slider-runnable-track {
                width: 100%;
                height: 7px;
                cursor: pointer;
                border-radius: 4px;
            }
            &::before {
                position: absolute;
                content: "";
                top: 5px;
                left: 0;
                width: var(--range-before-width);
                height: 7px;
                cursor: pointer;
                border-radius: 4px;
            }
            &::-webkit-slider-thumb {
                position: relative;
                -webkit-appearance: none;
                box-sizing: content-box;
                height: 11px;
                width: 11px;
                border-radius: 50%;
                cursor: pointer;
                margin: -3px 0 0 0;
                transform: scale(1.4);
            }
            &:active::-webkit-slider-thumb {
                transform: scale(1.6);
            }
            &::-moz-range-track {
                width: 100%;
                height: 7px;
                cursor: pointer;
                border-radius: 4px;
            }
            &::-moz-focus-outer {
                border: 0;
                border-radius: 4px;
            }
            &::-moz-range-thumb {
                box-sizing: content-box;
                height: 10px;
                width: 10px;
                border-radius: 50%;
                cursor: pointer;
            }
            &:active::-moz-range-thumb {
                transform: scale(1.4);
            }
            &::-ms-track {
                width: 100%;
                height: 7px;
                cursor: pointer;
                background: transparent;
                border: solid transparent;
                color: transparent;
                border-radius: 4px;
            }
            &::-ms-thumb {
                position: relative;
                -webkit-appearance: none;
                box-sizing: content-box;
                height: 11px;
                width: 11px;
                cursor: pointer;
                margin: -3px 0 0 0;
                transform: scale(1.4);
                border-radius: 50%;
            }
            &:active::-ms-thumb {
                transform: scale(1.6);
            }
        }
    }

    .advance-podcast--transcript-drawer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        &.active #advance-podcast--transcript {
            display: block;
        }
        #advance-podcast--transcript {
            display: none;
            .advance-podcast--transcript-title-line {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-bottom: 26px;
                .advance-podcast--transcript-title {
                    margin-bottom: 10px;
                }
                .advance-podcast--transcript-popout {
                    span {
                        color: $color-advance-blue;
                        text-decoration: underline;
                    }
                    svg {
                        fill: $color-advance-blue;
                        margin-left: 3px;
                        height: 19px;
                        width: 19px;
                    }
                    &:hover {
                        span {
                            color: $color-advance-dark-teal;
                        }
                        svg {
                            fill: $color-advance-dark-teal;
                        }
                    }
                }
            }

            .advance-podcast--transcript-content {
                max-height: 540px;
                padding: 0 30px 0 0;
                overflow: scroll;
                overflow-x: hidden;
                margin-top: 34px;
                scrollbar-width: thin;
                scrollbar-color: $color-advance-dark-teal $color-advance-mid-grey;
            }
            ::-webkit-scrollbar {
                height: 7px;
                width: 7px;
                overflow: hidden;
            }
            ::-webkit-scrollbar-track {
                border-radius: 4px;
            }
            ::-webkit-scrollbar-thumb {
                border: 0;
                border-radius: 4px;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .advance--podcast-player {
        padding-top: $padding-advance-section-desktop;
        padding-bottom: $padding-advance-section-desktop;

        .audio-player {
            padding: 0;
            margin-bottom: 37px;
            .audio-player--progress {
                padding-top: 13px;
                div {
                    font-size: 16px;
                }
            }
            .audio-player--controls {
                justify-content: space-between;
                & > div {
                    min-width: 130px;
                }
                .volume-control {
                    display: flex;
                }
                .advance-podcast--overflow {
                    position: relative;
                }
            }
        }

        .advance-podcast--transcript-drawer {
            #advance-podcast--transcript {
                .advance-podcast--transcript-title-line {
                    margin-bottom: 7px;
                    flex-wrap: nowrap;
                    a {
                        white-space: nowrap;
                        padding-left: 30px;
                    }
                }
                .advance-podcast--transcript-content {
                    max-height: 480px;
                    margin-top: 37px;
                }
            }
        }
    }
}
