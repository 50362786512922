.mapbox-map {
	.mapbox-map-popup-content {
		display: none;
	}

	.mapboxgl-popup {
		// box-shadow: 0 10px 24px rgba($color-black, 0.12);
		max-width: calc(100% - 30px) !important;
		margin-top: -65px;

		&:before {
			display: block;
			content: ' ';
			width: 0;
			height: 0;
			border-left: 30px solid transparent;
			border-right: 30px solid transparent;
			border-top: 30px solid $color-gray-lightest;
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translate(-50%, -12px);
		}

		&:after {
			display: block;
			content: ' ';
			width: 0;
			height: 0;
			border-left: 25px solid transparent;
			border-right: 25px solid transparent;
			border-top: 25px solid $color-neutral-lighter;
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translate(-50%, -13px);
		}
	}

	.mapboxgl-popup-content {
		position: relative;
		background-color: $color-neutral-lighter;
		border: solid $profile-card-border-width $color-gray-lightest;
		border-radius: 5px;

		.card {
			background-color: $color-neutral-lighter;
		}
	}
}
