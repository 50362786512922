.advance {
	.advance--article-hero,
	.advance--podcast-hero {
		padding-top: $padding-advance-section-mobile;
		padding-bottom: 0;
		hr {
			display: none;
		}
		.breadcrumb {
			border-bottom: 6px $color-advance-teal solid;
			padding-bottom: 25px;
			margin: -4px 0 30px;
			font-weight: 600;
		}
		.article-page-header {
			display: flex;
			flex-direction: column;
			padding: 0 0 37px 0;
			position: relative;
			.page-header--info {
				order: 1;
				flex-grow: 1;
				min-width: 50%;
				.info--content-label {
					color: $color-advance-yellow;
				}
				.info--content-date {
					color: $color-advance-light-grey;
					&::before {
						content: '';
						display: inline-block;
						height: 4px;
						width: 4px;
						margin: 0 10px 2px 8px;
						background: $color-advance-green;
						border-radius: 50%;
						vertical-align: middle;
					}
				}
			}
			.page-header--social {
				display: flex;
				white-space: nowrap;
				align-items: center;
				order: 4;
				.social-share {
					border-radius: 10px;
					height: 36px;
					padding: 0 14px;
					display: flex;
					flex: 1;
					justify-content: space-between;
					align-items: center;
					color: $color-advance-dark-teal;
					.label {
						margin-right: 10px;
					}
					svg.icon {
						fill: $color-advance-dark-teal;
						color: $color-advance-dark-teal;
						height: 20px;
						width: 20px;
						display: block;
					}
					button:hover svg.icon {
						opacity: 0.8;
					}
				}
			}
			.page-header--heading {
				flex-basis: 50%;
				order: 3;
				padding: 25px 2rem 6px 0;
			}
			.page-header--image {
				flex-basis: 50%;
				flex-grow: 1;
				margin: 35px -20px 0;
				order: 5;
				.image-container {
					overflow: hidden;
					padding-top: 56.25%;
					position: relative;
				}
				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
	}
	@include media-breakpoint-up(sm) {
		.advance--article-hero,
		.advance--podcast-hero {
			.article-page-header .page-header--social {
				width: 233px;
				min-width: 233px;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.advance--article-hero,
		.advance--podcast-hero {
			padding-top: $padding-advance-section-mobile;
			padding-bottom: 0;
			.breadcrumb {
				padding-bottom: 24px;
				margin: 6px 0 38px;
			}
			.page-header--contain {
				max-width: 1064px;
				margin: 0 auto;
			}
			.article-page-header {
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
				.page-header--info {
					font-size: 14px;
				}
				.page-header--social {
					margin: 5px 0 -5px;
					order: 2;
					justify-content: flex-end;
				}
				.page-header--heading {
					padding: 52px 3rem 0 0;
				}
				.page-header--image {
					margin: 35px 0 0;
				}
			}
		}
		.advance--podcast-hero {
			.article-page-header {
				padding-bottom: 60px;
				.page-header--info {
					font-size: 14px;
					margin-bottom: 32px;
				}
				.page-header--social {
					margin: 4px 0 28px;
				}
				.page-header--heading {
					width: auto;
					flex-grow: 1;
					width: 50%;
					padding: 3px 3rem 0 0;
					align-self: flex-start;
				}
				.page-header--image {
					margin: 0;
				}
			}
		}
	}
}
