.share-schedule-buttons {
	.btn {
		align-items: center;
		background-color: transparent;
		border: 3px solid $color-microsite-yellow;
		border-radius: 0;
		color: $color-white;
		display: flex;
		flex-direction: column;
		font-family: $moments-serif;
		font-size: 26px;
		font-weight: 400;
		height: 110px;
		justify-content: center;
		line-height: 1;
		margin-bottom: 1.25rem;
		position: relative;
		width: 100%;

		svg {
			fill: $color-microsite-yellow;
			flex: 1 0 auto;
			height: 26px;
			margin-top: 0.5rem;
			max-height: 26px;
			max-width: 26px;
			transition: fill 0.15s ease-in-out;
			width: 26px;
		}

		&:hover,
		&:focus {
			background-color: $color-microsite-yellow;
			border-color: $color-microsite-yellow;

			svg {
				fill: $color-white;
			}
		}
	}

	.moments-story & {
		.btn {
			color: $color-black;
		}
	}

	@include media-breakpoint-up(md) {
		display: flex;
		justify-content: space-between;

		.btn {
			flex: 0 1 calc(50% - 10px);
			flex-direction: row;
			font-size: 30px;
			justify-content: space-between;
			max-width: calc(50% - 10px);
			padding: 1rem 3.5rem 1rem 2.5rem;
			text-align: left;

			svg {
				margin-top: 0;
			}
		}
	}
}
