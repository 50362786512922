.price-estimates {
	@include make-container();
	@include make-container-max-width();
	margin-bottom: 2rem;
	margin-top: 2rem;

	.insurance-btns-container {
		display: flex;
		margin: 0.5rem 0 1.5rem;
	}

	.insured-btn,
	.uninsured-btn {
		width: 50%;

		&:first-of-type {
			margin-right: 1rem;
		}

		&[aria-expanded='true'] {
			background-color: $color-primary-dark-blue;
			border-color: $color-primary-dark-blue;
		}
	}

	.insured-panel,
	.uninsured-panel {
		display: none;

		&[aria-hidden='false'] {
			display: block;
			margin-top: 2rem;
		}
	}

	.csv-table {
		padding-left: 0;
		padding-right: 0;
	}

	.options {
		.col-lg-4 {
			margin-bottom: 1rem;
		}

		.heading-5 {
			color: $color-primary-dark-blue;
		}

		.btn {
			width: 100%;

			&:not(:last-child) {
				margin-bottom: 1rem;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.insurance-btns-container {
			display: block;
		}

		.insured-btn,
		.uninsured-btn {
			width: auto;
		}
	}
}
