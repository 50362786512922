.more-stories {
	background-color: $color-moments-black;
	margin-top: 2rem;
	padding: 3rem 0 0;

	.more-stories-header {
		color: $color-white;
		font-family: $moments-serif;
		font-size: 50px;
		font-weight: $font-weight-light;
		line-height: 1;
		margin-bottom: 2.5rem;
		text-align: center;
	}

	.stories-container {
		@include make-container();
		@include make-container-max-width();
	}

	.story-listings {
		margin: 0;
		padding: 0;
	}

	.story-card {
		margin: 0;
		margin-bottom: 1.25rem;

		&:before {
			display: none;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-up(md) {
		.story-listings {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.story-card {
			flex: 0 1 calc(50% - 0.625rem);
			max-width: calc(50% - 0.625rem);

			&:last-of-type {
				margin-bottom: 1.25rem;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.more-stories-header {
			font-size: 60px;
			margin: 0 0 3rem;
			text-align: left;
		}

		.story-listings {
			&:after {
				content: ' ';
				flex: 0 1 calc(33.3333% - (40px / 3));
				max-width: calc(33.3333% - (40px / 3));
			}
		}

		.story-card {
			flex: 0 1 calc(33.3333% - (40px / 3));
			max-width: calc(33.3333% - (40px / 3));
		}
	}

	@include media-breakpoint-up(xl) {
		.story-listings {
			&:before {
				content: ' ';
				flex: 0 1 calc(25% - (60px / 4));
				max-width: calc(25% - (60px / 4));
				order: 1;
			}
			&:after {
				content: ' ';
				flex: 0 1 calc(25% - (60px / 4));
				max-width: calc(25% - (60px / 4));
			}
		}

		.story-card {
			flex: 0 1 calc(25% - (60px / 4));
			margin-bottom: 0;
			max-width: calc(25% - (60px / 4));

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}
