.article-list {
	@include make-container();
	@include make-container-max-width();

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	.article-listing {
		background-color: $color-neutral-lighter;
		border-radius: 5px;
		margin-left: 0;
		padding: 2rem;

		&:before {
			display: none;
		}

		&:not(:last-of-type) {
			margin-bottom: 2.5rem;
		}

		&.research {
			.heading-4 {
				color: $color-primary-blue;
			}

			.article-links {
				a:after {
					content: '\00BB';
					display: inline-block;
					padding-left: 0.25rem;
				}
			}
		}

		&.companion {
			.article-content {
				justify-content: left;

				.article-content-inner {
					display: flex;
				}
			}
		}
	}

	.article-date {
		@include text(epsilon);
		display: none;
		font-weight: $font-weight-light;
		margin-bottom: 0.25rem;
	}

	.article-inner {
		display: flex;
		flex-flow: column;

		.article-links {
			display: block;

			a:not(.btn-primary):after {
				content: '\00BB';
				display: inline-block;
				padding-left: 0.25rem;
			}
		}
	}

	.article-image {
		margin-bottom: 1.5rem;

		img {
			height: 205px;
			width: 205px;
			object-fit: cover;
			object-position: 50% 50%;
			font-family: 'object-fit: cover; object-position: 50% 50%;';
		}

		img.content-icon {
			height: 100px;
			width: 100px;
		}
	}

	.section-title {
		&:after{
			background-color: $color-primary-yellow;
		}
	}

	.heading-4 {
		@include text(delta);
		font-weight: $font-weight-bold;
		color: $color-primary-dark-blue;
	}

	p {
		display: block;
	}

	.article-content {
		.description-xs {
			display: block;

			p {
				@include text(epsilon);
				display: block;
			}
		}

		.description-lg {
			display: none;
		}
	}

	.article-links {
		font-size: 0;
		margin-top: 1.25rem;

		span {
			@include text(epsilon);
			font-weight: $font-weight-semi-bold;
			margin-right: 0.5rem;
		}

		a {
			@include text(epsilon);
			font-weight: $font-weight-semi-bold;
			display: inline-block;
			position: relative;

			&:not(:first-of-type) {
				margin-left: 1rem;

				&:before {
					color: $color-secondary-blue;
					content: '\2022';
					display: block;
					position: absolute;
					left: -0.5rem;
					transform: translateX(-50%);
				}
			}
		}
	}

	.article-date {
		@include text(eta);
		padding: .5rem 1rem;
		border-radius: 6rem;
    	border-width: 5px;
	}

	.btn {
		padding: .5rem 1rem;
	}

	@include media-breakpoint-up(sm) {
		.article-inner {
			flex-flow: row;
		}

		.article-image {
			flex: 0 0 175px;
			height: 175px;
			width: 175px;
			margin-right: 1.5rem;
			margin-bottom: 0;

			img {
				height: 100%;
				width: 100%;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.two-column & {
			.article-date {
				display: none;
			}

			.article-image {
				flex: 0 0 150px;
				height: 150px;
				width: 150px;
			}
		}

		.article-inner {
			flex-flow: row;

			.article-links {
				display: block;
			}
		}

		.article-date {
			font-weight: $font-weight-normal;
			display: flex;
			background-color: $color-white;
			border-color: $color-white;
			align-self: flex-start;
			order: 1;
			padding-left: 1rem;
			padding-right: 1rem;
			white-space: nowrap;
		}

		.companion {
			.article-date {
				background-color: transparent;
				border-color: transparent;
			}
		}

		.article-image {
			flex: 0 0 175px;
			height: 175px;
			margin-right: 2rem;
		}

		p {
			@include text(epsilon);
			margin-bottom: 0;
			margin-top: 0.75rem;
		}

		.article-content {
			margin-right: 2rem;
		}

		.article-links {
			display: none;
			margin-top: 0.75rem;
		}
	}

	@include media-breakpoint-up(lg) {
		.two-column & {
			.article-inner {
				flex-flow: row;
			}

			.article-date {
				display: flex;
			}
			.article-image {
				flex: 0 0 200px;
				height: 200px;
				width: 200px;
			}
		}

		.article-image {
			flex: 0 0 200px;
			height: 200px;
			width: 200px;
		}

		.companion {
			.article-image {
				flex: 0 0 200px;
				height: 100px;
				width: 100px;
			}
		}

		.article-content {
			.description-xs {
				display: none;
			}

			.description-lg {
				display: block;

				p {
					@include text(epsilon);
					display: block;
				}
			}
		}
	}
}
