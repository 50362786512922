.microsite-theme {
	.microsite-left-nav {
		.accordion-item {
			margin-top: 3px;
			margin-bottom: 3px;

			.accordion-item-label {
				width: 100%;
			}

			.accordion-item-toggle {

				&.collapsed {
					.icon {
						fill: $color-gray-darkest;
						transition: all 0.5s ease;
						transition-property: color, transform, margin;
					}
				}

				&:not(.collapsed) {
					.icon {
						transform: rotate(180deg);
						transition: all 0.5s ease;
					}
				}
			}
		}

		.heading-4 {
			border-radius: 0;
			border: none;
			color: $color-black;
			font-weight: $font-weight-normal;
			margin-bottom: 1px;

			a {
				@include text(delta, 30);
				background-color: $color-gray-light;
				color: $color-black;
				display: block;
				padding: 0.375rem 1.25rem;
				text-decoration: underline;
				border-radius: 5px;

				&:hover,
				&:focus {
					text-decoration: none;
				}
			}
		}

		.accordion-cta {
			border-radius: 5px;
			border: none;
			color: $color-black;
			font-weight: $font-weight-bold;
			margin-bottom: 1px;

			a:first-child {
				margin-top: .1rem;
			}

			a {
				@include text(epsilon, 30);
				background-color: $color-illumination-yellow;
				color: $color-precision-gray;
				display: flex;
				padding: 0.2rem 1.25rem;
				text-decoration: none;
				transition: all 0.15s ease-in-out;
				border-radius: 5px;
				border: 5px solid $color-illumination-yellow;
				align-items: center;
				margin-top: 1rem;

				span {
					width: 100%;
				}

				&:hover,
				&:focus {
					background-color: $color-white;
					color: $color-black;
				}
			}
		}

		.btn-expand {
			@include text(epsilon, 30);
			background-color: $color-gray-light;
			border-radius: 5px;
			border: none;
			border-left: 10px solid transparent;
			box-shadow: none;
			color: $color-black;
			display: flex;
			font-weight: $font-weight-bold;
			padding: 0.375rem 1.25rem 0.375rem 0.625rem;
			text-align: left;
			text-decoration: underline;
			transition: all 0.15s ease-in-out;
			width: 100%;
			align-items: center;

			&.active {
				border-left-color: $color-illumination-yellow;
			}

			&.active-parent {
				&.collapsed {
					border-left-color: $color-illumination-yellow;
				}
			}

			&:not(.collapsed) {
				text-decoration: none;
			}

			&:hover {
				background-color: $color-illumination-yellow;
			}
		}

		.icon-arrow-left,
		.icon-arrow-right {
			fill: $color-precision-gray;
			position: relative;
			left: 0.3rem;
		}

		.accordion-header {
			margin-bottom: 0;

			h5 {
				margin-bottom: 0;
			}
		}

		.accordion-body {
			background-color: $color-neutral-lighter;
			border: none;
			padding: 0.625rem 1.25rem 1rem 0 !important;
		}

		.inner-list-title {
			@include text(epsilon);
			color: $color-microsite-gray-light;
			font-style: italic;
			font-weight: $font-weight-bold;
			margin-bottom: 0.25rem;
			padding-left: 30px;
		}

		.overview-link {
			@include text(epsilon);
			border-left: 10px solid transparent;
			font-style: normal;
			font-weight: $font-weight-bold;
			padding: 0.375rem 0 0.375rem 1.25rem;
			margin-bottom: 1.375rem;

			a {
				color: $color-microsite-gray-light;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}

			&.active {
				border-left-color: $color-illumination-yellow;

				a {
					pointer-events: none;
					text-decoration: none;
				}
			}
		}

		ul {
			list-style: none;
			padding: 0;
			margin-bottom: 1.75rem;

			&:last-of-type {
				margin-bottom: 0;
			}

			li {
				margin: 0;
				padding: 0.25rem 0 0.25rem 1.25rem;
				border-left: 10px solid transparent;

				&:before {
					display: none;
				}

				&.active {
					border-left-color: $color-illumination-yellow;

					a {
						pointer-events: none;
						text-decoration: none;
					}
				}

				a {
					@include text(zeta, eta);
					color: $color-black;
					text-decoration: underline;

					&:hover,
					&:focus {
						text-decoration: none;
					}
				}
			}
		}
	}

	.leftnav.has-page-adjacent {
		border-radius: 5px;
		border: none;
		color: $color-black;
		font-weight: $font-weight-bold;
		margin-bottom: 1px;

		// The use of !important is needed to offset work done by Aha in _leftnav.scss line 21
		:active {
			background-color: $color-white !important;
			border-color: $color-primary-yellow !important;
			color: $color-black !important;
		}

		a:first-child {
			margin-top: .2rem;
		}

		a {
			@include text(epsilon, 30);
			background-color: $color-illumination-yellow;
			color: $color-precision-gray;
			display: flex;
			padding: 0.2rem 1.25rem;
			text-decoration: none;
			transition: all 0.15s ease-in-out;
			border-radius: 5px;
			border: 5px solid $color-illumination-yellow;
			align-items: center;
			margin-top: 1rem;

			span {
				width: 100%;
				font-weight: $font-weight-bold;
			}

			&:hover,
			&:focus {
				background-color: $color-white;
				color: $color-black;
			}

			.icon-arrow-left,
			.icon-arrow-right {
				fill: $color-precision-gray;
			}
		}
	}
}
