.childrens-theme {
	.related-areas {
		.related-areas-inner {
			background-color: $color-childrens-primary;
		}

		.btn {
			@include button-variant(
				$color-white,
				$color-white,
				$color-neutral-light,
				$color-neutral-light
			);

			color: $color-childrens-secondary;

			&:hover {
				color: $color-childrens-secondary;
			}
		}
	}
}
