.two-up {
	@include make-container();
	@include make-container-max-width();

	.two-up-inner {
		background-color: $color-white;
		margin-bottom: 2rem;
		max-width: 100%;
	}

	.heading-1:first-of-type,
	.heading-3:first-of-type,
	.heading-3:first-of-type,
	.heading-4:first-of-type,
	.heading-5:first-of-type,
	.heading-6:first-of-type {
		margin-top: 0;
	}

	.heading-3 {
		@include text(beta, gamma);
		color: $color-primary-dark-blue;
	}

	.divider {
		margin: 1rem auto 0 0;
	}

	.edw-title {
		text-transform: uppercase;
	}

	p {
		@include text(epsilon, delta);
		color: $color-neutral-darkest;
	}

	.btn {
		padding-left: 3rem;
		padding-right: 3rem;
	}

	.card-deck {
		&:first-of-type {
			display: none;
		}

		flex-direction: column;
	}

	.two-up-header,
	.two-up-content,
	.two-up-footer {
		background-color: transparent;
		border-top: none;
		border-bottom: none;
	}

	.two-up-header {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 1.25rem 3.25rem 0;
	}

	.two-up-content {
		padding: 1.25rem 3.25rem;
	}

	.two-up-footer {
		padding: 0 3.25rem 1.25rem;
		margin-bottom: 1rem;
	}

	.two-up-img {
		order: 2;
		display: block;
		border-radius: 5px;
		margin-bottom: 1rem;
	}

	.two-up-img img,
	.two-up-img source {
		//box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.18);
		object-fit: cover;
		object-position: 50% 50%;
		font-family: 'object-fit: cover; object-position: 50% 50%;';
		height: 15rem;
		width: 100%;
	}

	.two-up-content-container {
		flex: auto;
		max-width: 100%;
		padding: 0;
		margin-bottom: 1rem;
	}

	@include media-breakpoint-up(sm) {
		.card-deck {
			flex-flow: column;
		}
		.two-up-img {
			height: 18rem;

			img,
			source {
				height: 18rem;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.two-column & {
			.two-up-inner {
				margin-bottom: 0;
			}

			.card-deck {
				flex-flow: column;

				&:first-of-type {
					display: none;
				}

				&:not(:first-of-type) {
					.two-up-content-container .two-up-header {
						display: flex;
					}
				}
			}

			.two-up-content-container {
				flex: 1 1 0;
				max-width: 100%;
				padding: 0;
				margin-bottom: 1rem;
			}

			.two-up-content-container:first-child {
				margin-left: 0;
				margin-right: 15px;
			}

			.two-up-content-container:not(:first-child) {
				margin-left: 0;
			}

			.two-up-header {
				padding: 1.25rem 3.25rem 0;
			}

			.two-up-content {
				padding: 1.25rem 3.25rem;
			}

			.two-up-footer {
				padding: 0 3.25rem 1.25rem;
			}

			.two-up-img {
				height: 18rem;

				img,
				source {
					height: 18rem;
				}
			}

			&.center {
				.two-up-content-container {
					margin-left: 15px;
				}
			}

			&.right {
				.two-up-content-container {
					margin-left: 15px;
					margin-right: 0;
				}

				.two-up-img {
					margin-right: -2.5rem;
				}
			}
		}

		&.center {
			.two-up-inner {
				padding-left: 1rem;
				padding-right: 1rem;
			}

			.two-up-content-container:first-child {
				margin-left: 15px;
			}

			.two-up-content-container:not(:first-child) {
				margin-right: 15px;
			}

			.two-up-img {
				margin-left: auto;
				margin-right: auto;

				&:not(.left):not(.right) {
					width: calc(100% - 3rem);
				}
			}
		}

		&.right {
			.two-up-inner {
				padding-left: 0;
				padding-right: 2.5rem;
			}

			.two-up-content-container:first-child {
				margin-left: 15px;
			}

			.two-up-content-container:not(:first-child) {
				margin-right: 0;
			}

			.two-up-img {
				margin-left: auto;
				margin-right: -2rem;
			}
		}

		.card-deck {
			&:first-of-type {
				display: flex;
			}

			&:not(:first-of-type) {
				.two-up-content-container .two-up-header {
					display: none;
				}
			}

			flex-flow: row wrap;
		}

		.two-up-content-container {
			flex: 1 0 40%;
			max-width: 50%;
			padding: 0;
			margin-bottom: 0;
			justify-content: flex-end;
		}

		.two-up-content-container:first-child {
			margin-left: 0;
			margin-right: 1rem;
		}

		.two-up-content-container:not(:first-child) {
			margin-left: 1rem;
		}

		.two-up-header {
			padding: 0 1.5rem;
		}

		.two-up-content {
			padding: 2rem 1.5rem;
		}

		.two-up-footer {
			padding: 0 1.5rem 2rem;
		}

		.two-up-img {
			height: 10.75rem;
			width: 100%;

			img,
			source {
				height: 10.75rem;
			}

			&.left {
				margin-left: -2rem;
				margin-right: auto;
			}

			&.center {
				margin-left: auto;
				margin-right: auto;
				width: calc(100% - 3rem);
			}

			&.right {
				margin-left: auto;
				margin-right: -2rem;
			}
		}

		.two-up-content {
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-up(lg) {
		.two-column & {
			.two-up-inner {
				margin-bottom: 2rem;
			}

			.card-deck {
				flex-flow: row wrap;

				&:first-of-type {
					display: flex;
				}

				&:not(:first-of-type) {
					.two-up-content-container .two-up-header {
						display: none;
					}
				}
			}

			.two-up-content-container {
				flex: 1 0 40%;
				max-width: 50%;
				margin-bottom: 0;
			}

			.two-up-content-container:first-child {
				margin-right: 1rem;
			}

			.two-up-content-container:not(:first-child) {
				margin-left: 1rem;
			}

			.two-up-header {
				padding: 0 1.5rem;
			}

			.two-up-content {
				padding: 2rem 1.5rem;
			}

			.two-up-footer {
				padding: 0 1.5rem 2rem;
			}

			.two-up-img {
				height: 10.75rem;

				img,
				source {
					height: 10.75rem;
				}
			}

			&.center {
				.two-up-inner {
					padding-left: 1.5rem;
					padding-right: 1.5rem;
				}
			}

			&.right {
				.two-up-inner {
					padding-left: 0;
					padding-right: 2.5rem;
				}
			}
		}

		&.center {
			.two-up-inner {
				padding-left: 1.5rem;
				padding-right: 1.5rem;
			}
		}

		&.right {
			.two-up-inner {
				padding-left: 0;
				padding-right: 5rem;
			}

			.two-up-img {
				margin-left: auto;
				margin-right: -2.5rem;
			}
		}

		.two-up-inner {
		}

		.two-up-content-container:first-child {
			margin-right: 1.5rem;
		}

		.two-up-content-container:not(:first-child) {
			margin-left: 1.5rem;
		}

		.two-up-content {
		}

		.two-up-img {
			height: 14rem;

			img,
			source {
				height: 14rem;
			}

			&.left {
				margin-left: -2.5rem;
				margin-right: auto;
			}

			&.right {
				margin-left: auto;
				margin-right: -2.5rem;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.two-column & {
			.two-up-inner {
			}

			.two-up-content {
				padding: 2rem 1.5rem;
			}

			.two-up-img {
				height: 14rem;

				img,
				source {
					height: 14rem;
				}
			}

			&.center {
				.two-up-inner {
					padding-left: 1.5rem;
					padding-right: 1.5rem;
				}
			}

			&.right {
				.two-up-inner {
					padding-left: 0;
					padding-right: 4rem;
				}
			}
		}

		.two-up-content {
		}

		.two-up-img {
			height: 18rem;

			img,
			source {
				height: 18rem;
			}
		}
	}
}
