.services-callout {
	@include make-container();
	@include make-container-max-width();
	margin-bottom: 2rem;

	.services-row {
		@include make-row();
	}

	.description {
		@include make-col-ready();

		p {
			@include text(epsilon, delta);
			color: $color-neutral-dark;
			font-weight: $font-weight-semi-bold;
		}
	}

	.services-actions {
		@include make-col-ready();
	}

	.callout-links {
		background-color: $color-neutral-lightest;
		padding: 1.5rem 1.5rem 1rem;
		margin-bottom: 1rem;

		.callout-links-header {
			align-items: center;
			display: flex;
			justify-content: space-between;
			margin-bottom: 1rem;

			.heading-6 {
				margin-bottom: 0;
				margin-right: 0.75rem;
			}

			a {
				@include text(eta);
				font-weight: $font-weight-semi-bold;

				&:focus {
					outline: 0;
					text-decoration: underline;
				}
			}
		}

		.callout-links-container {
			display: flex;
			flex-direction: column;
			margin-bottom: 1rem;
		}

		.btn-icon {
			background-color: $color-white;
			border-color: $color-white;
			color: $color-primary-blue-gray;
			margin-top: 1.25rem;
			padding: 0.625rem 0.75rem;
			position: relative;

			&:not(:last-of-type) {
				margin-bottom: 0.5rem;
			}

			&:hover {
				background-color: $color-neutral-lighter;
				border-color: $color-neutral-lighter;
			}

			&:focus {
				box-shadow: 0 0 0 0.2rem rgba($color-neutral-light, 0.5);
			}

			picture,
			svg {
				align-items: center;
				display: flex;
				height: 2rem;
				justify-content: center;
				left: 50%;
				position: absolute;
				top: 0;
				transform: translate(-50%, -65%);
				width: 3rem;

				img {
					width: 100%;
				}
			}
		}

		.callout-links-note {
			@include text(zeta);
			color: rgba($color-neutral-dark, 0.8);
			font-style: italic;
			margin-bottom: 0;
			margin-left: 0.125rem;
		}
	}

	.services-links {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1;

		a {
			@include text(zeta);
			color: $color-white;
			background-color: $color-primary-blue-gray;
			display: block;
			font-weight: $font-weight-semi-bold;
			padding: 1rem 1.5rem;
			width: 100%;

			&:not(:last-of-type) {
				margin-bottom: 0.5rem;
			}

			&:focus {
				outline: 0;
				text-decoration: underline;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.services-actions-container {
			display: flex;
		}

		.callout-links {
			margin-bottom: 0;

			.callout-links-container {
				flex-direction: row;
			}

			.btn-icon {
				&:not(:last-of-type) {
					margin-bottom: 0;
					margin-right: 0.875rem;
				}
			}
		}

		.services-links {
			padding-left: 15px;
		}
	}

	@include media-breakpoint-up(lg) {
		.description {
			@include make-col(4);
			padding-right: 1rem;
		}

		.services-actions {
			@include make-col(8);
			padding-left: 0;
		}
	}

	@include media-breakpoint-up(xl) {
		.services-actions {
			@include make-col(8);
			padding-left: 15px;
		}

		.services-links {
			padding-left: 30px;
		}
	}
}
