.up-header {
	@include make-container();
	@include make-container-max-width();
	text-align: center;

	.heading-3 {
		@include text(beta, gamma);
		color: $color-primary-dark-blue;
		font-weight: $font-weight-semi-bold;
	}

	.one-up,
	.two-up,
	.three-up {
		padding-left: 0;
		padding-right: 0;
	}
}
