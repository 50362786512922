.appointments {
	margin: 32px calc(#{$grid-gutter-width} / -2);
	padding: 0 1rem;
	margin: 0;
	padding: 0;

	.icon {
		fill: $color-white;
	}

	.btn {
		line-height: 1;
	}

	.date-range-selection {
		.btn {
			padding: 0.5rem 1rem;
			/* override bootstrap -1px margin-left on .btn-group>.btn:not(:first-child) */
			margin-left: 0;
		}
	}

	.select-date[type='text'] {
		background-color: $color-white;
		border-color: $color-white;
		color: $color-primary-blue-gray;
		padding: 1px;
		text-align: center;
		width: 145px;

		&:hover {
			border-color: $color-primary-blue-gray;
		}

		&:focus {
			border-color: transparent;
		}
	}

	.day-label {
		@include text(zeta);
		color: $color-primary-dark-blue;
		font-weight: $font-weight-bold;
		margin-bottom: 1rem;
		text-align: center;
	}

	.appointments-inner {
		background-color: $color-neutral-lightest;
		height: 100%;
		display: block;
		text-align: center;
		padding: 1.5rem;
		border-radius: 5px;

		.form-control {
			width: 75%;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 2rem;
		}

		.appt-covid-message {
			@include text(epsilon, delta);
			font-weight: $font-weight-semi-bold;
			margin-bottom: 0.375rem;
			padding-top: 1.5rem;
		}

		.warning-message {
			@include text(epsilon, delta);
			color: $color-dark-red;
			font-weight: $font-weight-bold;
			margin-bottom: 0.375rem;
			padding-top: 1.5rem;
		}
	}

	.appointment-title {
		@include text(delta);
		margin-bottom: 2rem;
	}

	.appointment-request-btn {
		margin-bottom: 1.5rem; // added for R1
	}

	.disclaimer {
		// added for R1
		@include text(epsilon, gamma);
		width: 75%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 2rem;

		a {
			font-weight: $font-weight-bold;
		}
	}

	.r1-question {
		// added for R1
		margin-bottom: 1rem;
	}

	.form-group {
		// added for R1
		display: flex;
		justify-content: center;

		.radio-wrapper {
			label {
				padding: 0 0 0 0.5rem;
			}
		}
	}

	.appointment-selection {
		padding: 2rem 0 1rem;
		position: relative;
		width: 100%;

		&.is-loading {
			.day-list-buttons {
				@include loader($color-primary-blue);
				position: relative;

				&:after {
					left: 50%;
					margin-left: -13px;
					margin-top: -13px;
					position: absolute;
					top: 50%;
				}
			}
		}

		&.no-appointments {
			.no-appointments-message {
				opacity: 1;
			}
		}

		.no-appointments-message {
			color: $color-primary-blue-gray;
			margin-left: 10%;
			opacity: 0;
			position: absolute;
			top: 50%;
			transition: opacity, 0.4s;
			width: 80%;
		}

		.icon {
			stroke: $color-white;
		}
	}

	.day-list {
		display: flex;
		justify-content: space-around;
		margin-bottom: 0;

		> li {
			display: flex;
			flex-basis: 25%;
			flex-direction: column;
			opacity: 0.3;
			transition: opacity 0.4s;

			&.has-appointments {
				opacity: 1;
				pointer-events: auto;

				.btn-select-time {
					&:disabled {
						opacity: 0.3;
					}
				}
			}

			&:nth-child(n + 4) {
				display: none;
			}

			> .btn:last-child {
				margin-top: auto;
			}
		}
	}

	.btn-select-time {
		@include button-variant(
			$color-primary-blue-gray,
			$color-primary-blue-gray,
			$color-primary-dark-blue,
			$color-primary-dark-blue
		);

		padding: 0.75rem 2.625rem;

		svg {
			pointer-events: none;
		}
	}

	.appointment-list {
		min-height: 186px;
		padding: 0.375rem 0;

		li {
			padding: 0.375rem 0;

			&.is-hidden {
				display: none;
			}
		}

		.btn {
			@include text(zeta);
			font-weight: $font-weight-normal;
			padding-left: 0;
			padding-right: 0;
			width: 100%;

			&:hover {
			}
		}

		.appointment-type {
			text-transform: lowercase;

			&:before {
				left: 6px;
				position: absolute;
				top: 16px;
			}
		}
	}

	@include media-breakpoint-up(sm) {
		margin: 0;
		padding: 0;

		.btn-select-time {
			padding: 0.75rem 3.375rem;
		}

		.day-list {
			> li {
				flex-basis: 22%;

				&:nth-child(n + 4) {
					display: flex;
				}

				&:nth-child(n + 5) {
					display: none;
				}
			}
		}

		.appointment-list {
			min-height: 244px;

			.btn {
				@include text(epsilon);
			}

			.appointment-type {
				&:before {
					left: 12px;
					top: 1.1rem;
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		.appointments-inner {
			padding: 2rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.appointment-selection {
			order: 1;
			padding-bottom: 0;
		}

		.day-list {
			> li {
				flex-basis: 18%;

				&:nth-child(n + 3) {
					display: flex;
				}

				&:nth-child(n + 4) {
					display: none;
				}
			}
		}

		.day-label {
			@include text(epsilon);
			margin-bottom: 2rem;
		}
	}

	@include media-breakpoint-up(lg) {
		.appointments-inner {
			.appt-covid-message {
				@include text(epsilon, delta);
				font-weight: $font-weight-semi-bold;
				margin-bottom: 0.375rem;
				padding-top: 1.5rem;
			}
		}
		.btn-select-time {
			padding: 0.75rem 2.625rem;
		}

		.day-list {
			> li {
				flex-basis: 19%;

				&:nth-child(n + 4) {
					display: flex;
				}

				&:nth-child(n + 5) {
					display: none;
				}
			}
		}

		.appointment-list {
			min-height: 302px;

			.appointment-type {
				&:before {
					left: 6px;
				}
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.appointments-inner {
			padding: 2rem 3rem;
		}

		.btn-select-date {
			padding: 0.5rem 2rem;
		}

		.btn-select-time {
			padding: 0.75rem 3.375rem;
		}

		.day-list {
			> li {
				flex-basis: 18%;

				&:nth-child(n + 5) {
					display: flex;
				}
			}
		}

		.appointment-list {
			.appointment-type {
				&:before {
					left: 12px;
				}
			}
		}
	}
}
