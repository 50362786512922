.advance main {
	form,
	.marketo-form .mktoForm,
	.input-icon-container {
		font-family: $advance-sans-serif;
		row-gap: 1px;
		label {
			font-size: 16px;
			line-height: initial;
			font-family: $advance-sans-serif;
			font-weight: 600;
			color: $color-advance-mid-bluegrey;
			margin-bottom: 12px;
		}
		input,
		textarea,
		select {
			background: $color-advance-off-white;
			height: 50px;
			border-radius: 8px;
			appearance: none;
			font-size: 16px;
			font-family: $advance-sans-serif;
			line-height: 50px;
			padding: 0 20px 0 20px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			border: 1px $color-advance-mid-grey solid;
			&:focus {
				border: 1px $color-advance-mid-bluegrey solid;
			}
			&:focus + .icon,
			&:focus + button .icon,
			& + button:focus .icon {
				fill: $color-advance-dark-teal;
			}
		}
		textarea {
			line-height: 20px;
			padding: 15px 20px 15px 20px;
		}
		input[type="radio"],
		input[type="checkbox"] {
			height: 24px;
			width: 24px;
			margin-right: 16px;
			padding: 0;
			background: #fff;
			& + label {
				&:before {
					content: "";
					height: 24px;
					width: 24px;
					border: 2px $color-advance-mid-grey solid;
				}
			}
			&:checked + label:after {
				height: 1rem;
				left: 2px;
			}
			&:hover,
			&:focus {
				& + label:before {
					border-color: $color-advance-light-teal;
				}
			}
		}
		input[type="radio"] {
			border-radius: 12px;
			&:checked + label:after {
				height: 1rem;
				left: 0px;
				width: 1rem;
			}
		}
		.input-icon-container {
			background: $color-advance-off-white !important;
			border-radius: 8px;
			box-shadow: none;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			input,
			textarea,
			select {
				padding-right: 55px;
			}
			.icon-btn {
				top: 0;
				right: 0;
			}
			.icon {
				width: 30px;
				height: 30px;
				transform: rotate(90deg);
				transform-origin: center;
				position: absolute;
				top: 10px;
				right: 15px;
				color: $color-advance-white;
				fill: $color-advance-mid-bluegrey;
				&.icon-search-circle {
					transform: none;
				}
			}
			&--sm {
				display: inline-block;
				input,
				select {
					height: 36px;
					padding: 5px 46px 5px 18px;
					line-height: 26px;
					font-size: 12px;
					font-weight: bold;
				}
				.icon {
					width: 22px;
					height: 22px;
					top: 7px;
					right: 18px;
				}
			}
		}
	}
	@include media-breakpoint-up(lg) {
		form,
		.input-icon-container {
			row-gap: 2px;
		}
	}
}
