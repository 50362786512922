.blog-article {
	@include make-container();
	@include make-container-max-width();

	.article-top-image {
		height: 250px;
		margin-bottom: 2rem;

		img {
			height: 100%;
			object-fit: cover;
			object-position: center 40%;
			font-family: 'object-fit: cover; object-position: center 40%;';
			width: 100%;
		}
	}

	.article-content {
		@include make-row();
		justify-content: center;

		p, .alt-background {
			a {
				font-weight: $font-weight-bold;
			}
		}

		.podcast-headshot {
			float: right;
			width: 205px;
			margin-left: 2rem;
			margin-bottom: 1rem;
		}

		.podcastImageDescription {
			display: flow-root;
		}
	}

	.blog-subscription {
		height: auto;
		margin: 0 0 2rem 0;
		position: relative;
		align-items: center;
		display: flex;

		.blog-item-content {
			background-color: $color-white;
			position: relative;
			padding: 1.25rem;
			margin: auto;
			border-radius: 0;
			width: 100%;
		}

		.blog-title {
			@include text(beta);
			color: $color-primary-dark-blue;
			font-weight: $font-weight-bold;
		}

		.divider-blog {
			border-bottom: 2px solid $color-primary-yellow;
			margin: 0.75rem auto 0.5rem 0;
			width: 4rem;
		}

		.blog-description {
			@include text(epsilon);
			display: block;
			font-weight: $font-weight-normal;
			margin: 1rem 0;
		}

		.subscribe-form {
			padding: 0;

			form {
				padding-top: 1.5rem;
			}
		}
	}

	.back-btn {
		background-color: $color-primary-dark-blue;
		border-radius: 0;
		display: block;
		margin-bottom: 0.75rem;
		margin-top: 0.75rem;
		padding: 1rem 3rem;
		position: relative;
		text-align: left;
		width: 100%;

		&:hover,
		&:focus {
			background-color: $color-primary-blue-gray;
			border-color: $color-primary-blue-gray;

			span {
				text-decoration: underline;
			}
		}

		.icon-arrow-left,
		.icon-arrow-right {
			align-self: center;
			fill: $color-white;
			left: 1rem;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	@include media-breakpoint-up(md) {
		.article-top-image {
			height: 350px;
		}

		.two-column-sidebar {
			.blog-subscription {
				display: block;

				.blog-item-content {
					border: none;
					padding: 0;
				}

				.blog-title {
					@include text(delta);
					width: 100%;
				}
			}
		}

		.blog-subscription {
			.blog-title {
				@include text(beta);
				width: 50%;
			}
		}

		.back-btn {
			display: none;
		}
	}

	@include media-breakpoint-up(lg) {
		.article-top-image {
			height: 360px;
		}
	}
}
