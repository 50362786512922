.search-page-results {
	.results-controls {
		align-items: center;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		padding: 1rem 0;
		width: 100%;

		.legend,
		.results-view-mode {
			display: none;
		}

		> * {
			margin-top: 1rem;
			width: 40%;
		}

		.results-control {
			&.results-control-dropdown {
				select {
					width: 100%;
				}
			}

			&.results-view-mode {
				button {
					background: none;
					border: 0;
					margin: 0;
					outline: 0;
					padding: 0;

					&:first-child {
						padding-right: 1.25rem;
					}

					&[disabled] {
						.icon {
							fill: $color-secondary-light-blue;
						}
					}

					.icon {
						fill: $color-neutral;
					}
				}
			}
		}

		.results-view-mode,
		.results-control-dropdown {
			text-align: right;
		}

		.results-control-dropdown {
			select {
				text-align-last: right;
			}
		}

		.mobile-filters-toggle {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
			margin: 1rem auto;
		}
	}

	.results-count,
	.results-view-mode {
		@include text(zeta);
	}

	.results-count {
		color: $color-gray-darkest;
		font-weight: $font-weight-semi-bold;
	}

	.results-list-container {
		position: relative;
		min-height: 320px;

		&.loading {
			.results-pagination-info {
				opacity: 0;
			}

			.loading-overlay {
				opacity: 1;
			}
		}
	}

	.no-results-message {
		margin-top: 1rem;
		margin-bottom: 1rem;
		background-color: $color-precision-gray-ish-tint;

		.no-results-image {
			margin-left: auto;
			margin-right: auto;
			padding: 2.5rem;
		}

		h2 {
			@include text(beta);
			margin-bottom: 1.25rem;
		}

		p {
			@include text(epsilon);
			margin-bottom: 0.5rem;
		}

		ul {
			margin-bottom: 0;

			li {
				@include text(epsilon);
			}
		}
	}

	.results-list {
		display: flex;
		flex-direction: column;
		list-style: none;
		padding: 0;
		padding-top: 0.75rem;
	}

	.results-list-item {
		padding-bottom: 1rem;
	}

	@include media-breakpoint-up(md) {
		.results-controls {
			gap: 1rem;
			justify-content: space-evenly;

			> * {
				margin-top: 0;
				width: auto;
			}

			.results-control {
				text-align: center;
				// flex-grow: 2;

				// &.results-count,
				// &.results-view-mode {
				// 	flex-grow: 1;
				// }

				&.results-count {
					flex-grow: 1;
					text-align: left;
				}

				&.results-view-mode {
					text-align: right;
				}

				+ .results-control {
					flex: 0 1 auto;
					padding-left: 1rem;
					border-left: 1px solid $color-gray-light;

					// .find-a-location & {
					// 	border-left: none;
					// }
				}
			}

			.legend,
			.results-view-mode {
				display: block;
			}

			.mobile-filters-toggle {
				margin-left: 1.75rem;
				padding-left: 3.375rem;
				padding-right: 3.375rem;
			}
		}

		.no-results-message {
			display: flex;
			flex-direction: row;

			.no-results-image {
				padding: 2.5rem 0.5rem 2.5rem 3rem;
			}

			.card-body {
				padding: 2.5rem 2rem;
			}

			h2 {
				@include text(alpha);
			}

			p {
				@include text(delta);
			}

			ul {
				li {
					@include text(delta);
				}
			}
		}

		.results-list-container {
			min-height: 500px;
		}

		.results-list {
			display: flex;
			flex-flow: row wrap;
			list-style: none;
			padding-top: 1rem;

			&.view-mode-list {
				flex-direction: column;

				.results-list-item {
					flex: auto;
					max-width: none;
					padding-left: 0;
					padding-right: 0;
					width: 100%;

					&:last-child {
						padding-bottom: 0;
					}
				}
			}
		}

		.results-list-item {
			flex: 50%;
			max-width: 50%;

			&:nth-of-type(odd) {
				padding-right: 0.75rem;
			}

			&:nth-of-type(even) {
				padding-left: 0.75rem;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.results-controls {
			padding: 0.625rem 0 1rem;
		}

		.mobile-filters-toggle {
			display: none;
		}
	}
}
