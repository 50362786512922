.advance {
	.advance--form-bar {
		position: relative;
		margin-bottom: -10px;

		&.single-form {


		}

		.row > div {
			position: static !important;
		}

		.form-bar--form-trigger {
			position: relative;
			padding: 0 20px;
			margin: 0 -20px;
			cursor: pointer;
			display: flex;
			align-items: center;
			height: 60px;
			color: $color-advance-dark-teal;
			.icon {
				height: 27px;
				width: 27px;
				margin: 0 20px 0 0;
				&.icon-carat-circle {
					position: absolute;
					right: 0;
					top: 14px;
					transform: rotate(90deg);
					height: 33px;
					width: 33px;
				}
			}
		}

		.form-bar--form-contain {
			position: relative;
			display: none;
			left: 0px;
			right: 0px;
			z-index: 999;
			margin: 0 -20px;
			background: #fff;
			border: 10px $color-advance-light-teal solid;
			padding: 30px 3px 18px;
			.heading {
				margin-bottom: 10px;
			}
			p {
				color: $color-advance-mid-bluegrey;
			}
			form {
				padding-top: 16px;
				input {
					height: 56px;
					line-height: 56px;
				}
				& > .mktoFormRow {
					margin: 0 0 14px !important;
				}
				.mktoErrorMsg {
					padding: 11px 0 3px;
				}
			}
			.thank-you-message {
				background: $color-advance-light-teal;
				.thank-you-bubble {
					border-color: $color-advance-dark-teal;
					color: $color-advance-dark-teal;
					&:after {
						border-top-color: $color-advance-light-teal;
					}
					&:before {
						border-top-color: $color-advance-dark-teal;
					}
				}
				.thank-you-content,
				.thank-you-content p {
					color: $color-advance-dark-teal;
				}
			}
		}
		.form-contain--yellow {
			border-color: $color-advance-yellow;
			.thank-you-message {
				background: $color-advance-yellow;
				.thank-you-bubble {
					&:after {
						border-top-color: $color-advance-yellow;
					}
				}
			}
		}

		.active {
			.form-bar--form-trigger {
				.icon-carat-circle {
					transform: rotate(-90deg);
				}
			}
			.form-bar--form-contain {
				display: block;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.advance--form-bar {
			position: relative;
			margin: 0;

			.row > div {
				position: static !important;
			}
			.form-bar--form-trigger {
				font-size: 18px;
				line-height: 28px;
				height: 68px;
				.icon {
					height: 33px;
					width: 33px;
					&.icon-carat-circle {
						top: 18px;
					}
				}
			}
			.form-bar--form-trigger.advance--bk--teal {
				padding-right: 50px;
				.icon-carat-circle {
					right: 75px;
				}
			}
			.form-bar--form-trigger.advance--bk--yellow {
				padding-left: 75px;
			}
			.form-bar--form-trigger::after {
				position: absolute;
				width: 50vw;
				top: 0;
				content: "";
				height: 100%;
			}
			.form-bar--form-trigger.advance--bk--teal::after {
				background: $color-advance-light-teal;
				right: 100%;
			}
			.form-bar--form-trigger.advance--bk--yellow::after {
				background: $color-advance-yellow;
				left: 100%;
			}

			.form-bar--form-contain {
				position: absolute;
				top: 100%;
				left: 0;
				right: 0;
				margin: 0;
				padding: 55px 30px 50px;
				border-width: 14px;
				.row > div:first-child {
					padding-right: 3.5vw;
				}
				form {
					margin: -5px 0 0;
					padding: 0;
					& > .mktoFormRow {
						margin: 0 0 18px !important;
					}
				}
			}
		}
	}
	@include media-breakpoint-up(xl) {
		.advance--form-bar {
			.form-bar--form-contain {
				.row > div:first-child {
					padding-right: 7.4vw;
				}
			}
		}
	}
}
