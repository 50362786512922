html {
	overflow-x: hidden;
}

body {
	min-height: 100vh;
	overflow-x: hidden;

	&.body-fixed {
		overflow-y: scroll;
		position: fixed;
		top: 0;
		width: 100%;
	}
}

table {
	&.table-bordered {
		border-width: 1px;

		th,
		td {
			border-width: 1px;
			border-color: $color-gray-light;
		}
	}

	th,
	td {
		color: $color-gray-darkest;

		a {
			font-weight: $font-weight-semi-bold;
		}

		p {
			@include text(zeta);
			color: $color-gray-darkest;
		}
	}
}

form {
	margin-bottom: 0;
}

.form-control {
	background-clip: unset;
}

input[type='text'],
input[type='email'],
input[type='date'],
input[type='phone'],
input[type='tel'],
input[type='search'] {
	@include text('epsilon');
	background-color: $color-neutral-lightest;
	// border: 1px solid $color-gray-light;
	border-radius: 5px;
	color: $color-gray-darkest;

	&:focus {
		background-color: $color-neutral-lightest;
		border-color: $color-secondary-light-blue;
		box-shadow: none;
	}

	&[placeholder] {
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&:disabled {
		background-color: rgba($color-gray-lightest, 0.35);

		&::placeholder {
			color: $color-neutral;
		}
	}
}

textarea {
	@extend .form-control;
	@include text(epsilon);
	background-color: $color-neutral-lightest;
	border: 1px solid $color-neutral-lightest;
	border-radius: 0;
	color: $color-primary-blue;
	max-width: 100%;

	&:focus {
		background-color: $color-neutral-lightest;
		border-color: $color-secondary-light-blue;
		box-shadow: none;
	}
}

.input-icon-container {
	position: relative;

	input[type='text'],
	input[type='search'] {
		padding-right: 2.5rem;
	}

	> .icon-btn {
		@include text(delta);
		align-items: center;
		background: none;
		border: none;
		color: $color-primary-blue;
		display: flex;
		padding: 0;
		position: absolute;
		right: 1rem;
		top: 50%;
		transform: translateY(-50%);

		.icon {
			height: 1rem;
			stroke: $color-primary-light-blue;
			width: 1rem;

			&.icon-search {
				margin-top: -2px;
			}
		}

		&:hover,
		&:focus {
			outline: 0;

			.icon {
				stroke: $color-secondary-light-blue;
			}
		}
	}

	> .icon {
		stroke: $color-gray-darkest;
		height: 1rem;
		pointer-events: none;
		position: absolute;
		right: 1rem;
		top: 50%;
		transform: translateY(-50%);
		width: 1rem;

		&.icon-search {
			height: 1rem;
			margin-top: -2px;
			width: 1rem;
		}
	}
}

input[type='search'] {
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		-webkit-appearance: none;
	}
}

.field-validation-error {
	span {
		color: $color-red;
	}
}

.btn {
	font-weight: $font-weight-semi-bold;
	border-radius: 6rem;
	border-width: 5px;

	/* Default to button-primary */
	&.btn-primary,
	&:not([class*='btn-']) {
		@include button-variant(
			$color-primary-yellow,
			$color-primary-yellow,
			$color-white,
			$color-primary-yellow
		);
	}

	&.btn-secondary {
		@include button-variant(
			$color-gray-darkest,
			$color-gray-darkest,
			$color-white,
			$color-gray-darkest
		);
	}

	&.btn-tertiary {
		@include button-variant(
			$color-deep-green,
			$color-deep-green,
			$color-white,
			$color-deep-green
		);
	}

	&.btn-primary,
	&.btn-secondary,
	&.btn-tertiary {
		width: 100%;

		svg {
			margin: 0 -8px 0 10px;
		}

		@include media-breakpoint-up(sm) {
			width: auto;
		}
	}

	&.btn-secondary,
	&.btn-tertiary {
		svg {
			stroke: $color-white;
		}
		&:hover
		&:focus {
			svg {
				stroke: $color-gray-darkest;
			}
		}
	}

	&.btn-shadow {
		box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
	}

	&.submitting {
		@include loader($color-white, 20px);
		display: flex;
	}

	.rte & {
		margin: 0.375rem 0.25rem;
	}

	&.btn-disabled {
		pointer-events: none;
		background-color: $color-gray-secondary;
		border-color: $color-gray-secondary;
		color: $color-gray-darkest;
		box-shadow: none;
	}
}

.link-forward {
	color: $color-healthplan-blue;
	display: inline-block;
	padding-right: 1rem;

	&:after {
		display: inline-block;
		content: ' ';
		border-left: 6px solid $color-primary-yellow;
		border-top: 5px solid transparent;
		border-bottom: 5px solid transparent;
		margin-left: 0.5rem;
		margin-right: -1rem;
	}

	@at-root #{selector-unify(&, button)} {
		background-color: transparent;
		border: none;
		padding: 0 1rem 0 0;
		text-align: left;
	}

	> * {
		pointer-events: none;
	}

	@include media-breakpoint-up(lg) {
		padding-right: 1.125rem;

		&:after {
			margin-left: 0.75rem;
		}

		@at-root #{selector-unify(&, button)} {
			padding: 0 1.125rem 0 0;
		}
	}
}

.link-backward {
	color: $color-healthplan-blue;

	&:before {
		display: inline-block;
		content: ' ';
		border-right: 6px solid $color-primary-yellow;
		border-top: 5px solid transparent;
		border-bottom: 5px solid transparent;
		margin-right: 0.5rem;
	}

	@include media-breakpoint-up(lg) {
		&:before {
			margin-right: 0.75rem;
		}
	}
}

.range-container {
	position: relative;

	label {
		@include text(zeta);
	}

	input {
		display: block;
	}

	.range-label-list {
		@include text(eta);
		display: flex;
		justify-content: space-between;
		list-style-type: none;
		margin: 6px 0 0;
		padding: 0 0 0 $custom-range-thumb-width;

		li {
			flex: 1 1 0;
			text-align: left;
			transform: translateX(-(calc($custom-range-thumb-width / 2)));

			&.active {
				color: $color-secondary-teal;
				font-weight: $font-weight-bold;

				span {
					color: $color-secondary-teal;
					font-weight: $font-weight-bold;

					&:before {
						display: none;
					}
				}
			}

			&:first-of-type {
				span {
					transform: translateX(
						-(calc($custom-range-thumb-width / 2))
					);

					&:before {
						left: 0;
						transform: none;
					}
				}
			}

			&:last-of-type {
				position: absolute;
				right: 0;
				text-align: right;
				transform: none;

				span {
					transform: none;

					&:before {
						left: auto;
						right: 0;
						transform: none;
					}
				}
			}

			span {
				display: inline-block;
				position: relative;
				transform: translateX(-50%);

				&:before {
					background-color: $custom-range-track-bg;
					border-radius: 50%;
					content: ' ';
					display: block;
					height: 0.75rem;
					left: 50%;
					pointer-events: none;
					position: absolute;
					top: -27px;
					transform: translateX(-50%);
					width: 0.75rem;
				}
			}
		}
	}
}

.dropdown-menu {
	border-color: $color-gray-light;
	border-radius: 0;
	box-shadow: 0 10px 16px rgba($color-black, 0.12);
	margin: 0;
	max-height: none !important;
	padding: 0;
	width: 100%;

	.dropdown-item {
		@include text(zeta);
		color: $color-primary-blue;
		display: flex;
		justify-content: space-between;
		padding: 0.5rem 1rem;

		&:hover,
		&.active {
			background-color: $color-primary-dark-blue;
			color: $color-white;

			.icon {
				stroke: $color-white;
			}
		}

		.rbt-highlight-text {
			font-weight: $font-weight-semi-bold;
		}

		> span:first-of-type {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.checkbox-wrapper {
	align-items: flex-start;
	display: flex;
	justify-items: flex-start;
	margin-bottom: 0.75rem;
	position: relative;

	&.centered {
		input[type='checkbox'] {
			align-items: center;

			&:checked {
				+ label {
					&:after {
						top: 50%;
						margin-top: -6px;
					}
				}
			}

			+ label {
				&:before {
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}

	input[type='checkbox'] {
		margin-top: 2px;
		opacity: 0;

		&:checked {
			+ label {
				&:after {
					border-bottom: 3px solid $color-gray-darkest;
					border-right: 3px solid $color-gray-darkest;
					content: '';
					display: block;
					height: 0.75rem;
					left: 0;
					margin: 0.25rem 0.375rem;
					position: absolute;
					top: 0;
					transform: rotate(45deg) translate(-1px, -1px);
					width: 0.5rem;
				}
			}
		}

		&:hover {
			cursor: pointer;

			+ label {
				&:before {
					border-color: $color-primary-light-blue;
				}
			}
		}

		&:focus {
			+ label {
				&:before {
					box-shadow: 0 0 0 0.2rem
						rgba($color-primary-light-blue, 0.25);
				}
			}
		}

		+ label {
			&:before {
				border: 2px solid $color-gray-light;
				border-radius: 5px;
				content: '';
				display: block;
				height: 1.25rem;
				left: 0;
				position: absolute;
				top: 0;
				width: 1.25rem;
			}

			&:hover {
				cursor: pointer;

				&:before {
					border-color: $color-primary-light-blue;
				}
			}
		}
	}

	label {
		@include text(epsilon);
		display: flex;
		justify-content: space-between;
		margin: 0;
		padding: 0 0 0 1rem;
		width: 100%;

		.label-name {
			flex-grow: 1;
		}

		.label-number {
			padding-left: 1rem;
		}
	}
}

.radio-wrapper {
	align-items: flex-start;
	display: flex;
	justify-items: flex-start;
	margin-bottom: 0.75rem;
	position: relative;

	input[type='radio'] {
		margin-top: 2px;
		opacity: 0;

		&:checked {
			+ label {
				&:after {
					background-color: $color-gray-darkest;
					border-radius: 50%;
					content: '';
					display: block;
					height: 0.75rem;
					left: 0;
					margin: 0.25rem;
					position: absolute;
					top: 0;
					width: 0.75rem;
				}
			}
		}

		&:hover {
			cursor: pointer;

			+ label {
				&:before {
					border-color: $color-primary-light-blue;
				}
			}
		}

		&:focus {
			+ label {
				&:before {
					box-shadow: 0 0 0 0.2rem
						rgba($color-primary-light-blue, 0.25);
				}
			}
		}

		+ label {
			&:before {
				border: 2px solid $color-gray-light;
				border-radius: 50%;
				content: '';
				display: block;
				height: 1.25rem;
				left: 0;
				position: absolute;
				width: 1.25rem;
			}

			&:hover {
				cursor: pointer;

				&:before {
					border-color: $color-neutral-dark;
				}
			}
		}
	}

	label {
		@include text(epsilon);
		display: flex;
		justify-content: space-between;
		margin: 0;
		padding: 0 0 0 1rem;
		width: 100%;

		.label-name {
			flex-grow: 1;
		}

		.label-number {
			padding-left: 1rem;
		}
	}
}

.radio-btns {
	display: flex;

	.radio-wrapper {
		flex: 1;
		margin-right: 0.5rem;
		margin-bottom: 0;

		&:last-child {
			margin-right: 0;
		}
	}

	input[type='radio'] {
		height: 0;
		width: 0;

		&:checked {
			+ label {
				background-color: $color-primary-dark-blue;
				border-color: $color-primary-dark-blue;

				&:before,
				&:after {
					display: none;
				}
			}
		}

		&:focus {
			+ label {
				background-color: $color-primary-dark-blue;
				border-color: $color-primary-dark-blue;
				box-shadow: 0 0 0 0.2rem rgba($color-primary-dark-blue, 0.35);
			}
		}

		+ label {
			align-items: center;
			background-color: $color-primary-blue-gray;
			border: 1px solid $color-primary-blue-gray;
			border-radius: 0.625rem;
			color: $color-white;
			display: flex;
			font-size: 1rem;
			justify-content: center;
			line-height: 1.375;
			padding: 0.75rem 0.25rem;
			width: 100%;

			&:before,
			&:after {
				display: none;
			}

			&:hover {
				background-color: $color-primary-dark-blue;
				border-color: $color-primary-dark-blue;
			}

			span {
				text-align: center;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.radio-wrapper {
			margin-right: 1rem;
		}
	}
}

.rbt {
	position: relative;

	&.loading {
		@include loader($color-primary-blue, 20px);

		&:after {
			margin-top: -10px;
			position: absolute;
			right: 1rem;
			top: 50%;
		}

		> .icon-btn,
		> .icon {
			display: none;
		}
	}

	> .icon-btn {
		@include text(delta);
		align-items: center;
		background: none;
		border: none;
		color: $color-primary-blue;
		display: flex;
		padding: 0;
		position: absolute;
		right: 1rem;
		top: 50%;
		transform: translateY(-50%);

		.icon {
			fill: $color-primary-light-blue;
			height: 1rem;
			stroke: $color-primary-light-blue;
			width: 1rem;

			&.icon-search {
				margin-top: -2px;
			}

			&.icon-close {
				height: 0.8rem;
				width: 0.8rem;
			}
		}

		&:hover,
		&:focus {
			outline: 0;

			.icon {
				fill: $color-secondary-light-blue;
				stroke: $color-secondary-light-blue;
			}
		}
	}

	> .icon {
		stroke: $color-primary-blue;
		height: 1rem;
		pointer-events: none;
		position: absolute;
		right: 1rem;
		top: 50%;
		transform: translateY(-50%);
		width: 1rem;

		&.disabled {
			fill: $color-neutral;
			stroke: $color-neutral;
		}

		&.icon-search {
			height: 1rem;
			margin-top: -2px;
			width: 1rem;
		}
	}
}

// /* Preventing typeahead suggestion from extending beyond input width */
.rbt-input-main {
	padding-right: 2.5rem;

	+ div {
		max-width: 100%;
		overflow: hidden;
	}

	&.btn-shadow {
		box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
	}
}

.header-module {
	.heading-3 {
		@include text(beta, gamma);
		color: $color-primary-dark-blue;
	}
}

.divider {
	/* applied to the <hr> */
	border-bottom: 0.25rem solid $color-illumination-yellow !important;
	margin: 1rem auto 1rem 0;
	width: 4.375rem;
	border-top: none;
	&-header {
		border-bottom: 0.5rem solid $color-illumination-yellow !important;
		margin: 1rem auto 3.5rem;
		width: 4.375rem;
		border-top: none;
	}
}

.card {
	border: 1px solid transparent;
	border-radius: 0;

	&.transparent-border {
		border-color: transparent;
	}
}

.profile-image {
	background-color: $color-white;
	border: solid $profile-image-border-width $color-white;
	border-radius: 50%;
	//box-shadow: $box-shadow-primary;
	height: $profile-image-size;
	min-width: $profile-image-size;
	overflow: hidden;
	position: relative;
	width: $profile-image-size;

	img {
		height: 100%;
		object-fit: cover;
		object-position: top;
		font-family: 'object-fit: cover; object-position: top;';
		width: 100%;
	}

	svg {
		height: 100%;
		width: 100%;
	}
}

/* accomodate classes exposed in the Sitecore Rich Text Editor */
img {
	&.image-left,
	&.image-center,
	&.image-right {
		display: block;
		margin: 0 auto 1rem;
		max-width: 100%;
		height: auto !important;
	}

	@include media-breakpoint-up(md) {
		&.image-left {
			float: left;
			margin-right: 1.5rem;
			max-width: 50%;
		}

		&.image-right {
			float: right;
			margin-left: 1.5rem;
			max-width: 50%;
		}

		&.image-center {
			margin-left: auto;
			margin-right: auto;
		}
	}

	@include media-breakpoint-up(lg) {
		&.image-left,
		&.image-right {
			max-width: 60%;
		}
	}
}

img,
source {
	border-radius: 5px;
	&.img-zero-radius {
		border-radius: 0;
	}
}

.appointment-type {
	position: relative;

	&:before {
		border: 2px solid $color-neutral-darkest;
		border-radius: 50%;
		content: '';
		display: inline-block;
		height: 0.625rem;
		margin-right: 0.25rem;
		width: 0.625rem;
	}

	&.patients-new {
		&:before {
			background-color: $color-new-patients;
			border-color: $color-new-patients;
		}
	}

	&.patients-existing {
		&:before {
			background-color: $color-existing-patients;
			border-color: $color-existing-patients;
		}
	}
}

.appointment-type-label {
	@include text(zeta);
	color: $color-neutral-dark;
	display: block;
	padding-bottom: 1rem;

	.appointment-type {
		margin-left: 0.125rem;
	}

	@include media-breakpoint-up(md) {
		font-weight: $font-weight-semi-bold;
	}
}

.section-title {
	@include text(delta);
	color: $color-primary-dark-blue;
	font-weight: $font-weight-bold;
	margin-bottom: 1.75rem;
	padding-bottom: 1.25rem;
	position: relative;
	text-align: center;

	&:after {
		background-color: $color-primary-yellow;
		bottom: 0;
		content: '';
		display: block;
		height: 4px;
		left: calc(50% - 29px);
		position: absolute;
		width: 58px;
	}

	@include media-breakpoint-up(md) {
		@include text(gamma);
		text-align: left;

		&:after {
			left: 0;
		}
	}
}

.section-subtitle {
	color: $color-primary-blue;
	font-weight: $font-weight-bold;
	margin-bottom: 1rem;
}

.results-control-dropdown {
	.select-wrapper {
		position: relative;

		select {
			@include text('epsilon');
			background-color: $color-white;
			border: 0;
			color: $color-gray-darkest;
			padding: 0.25rem 2rem 0.25rem 0.25rem;

			appearance: none;
			/* for Firefox */
			-moz-appearance: none;
			/* for Chrome */
			-webkit-appearance: none;

			/* For IE10 */
			&::-ms-expand {
				display: none;
			}

			&:focus-visible {
				outline: $color-gray-lightest auto 1px;
			}

			.dropdown-item {
				appearance: none;
				/* for Firefox */
				-moz-appearance: none;
				/* for Chrome */
				-webkit-appearance: none;
				background-color: $color-white;
			}
		}
		.icon {
			pointer-events: none;
			position: absolute;
			right: 0.25rem;
			stroke: $color-primary-light-blue;
			top: 0.5rem;
		}
	}
}

.loading-overlay {
	@include loader($color-primary-blue, 100px, 5px);
	align-items: flex-start;
	background-color: $color-white;
	display: flex;
	height: 100%;
	justify-content: center;
	left: -15px;
	opacity: 0;
	padding-top: 150px;
	pointer-events: none;
	position: absolute;
	top: 0;
	transition: opacity 0.4s ease-in-out;
	width: calc(100% + 30px);
	z-index: 2;
}

.chat-icon {
	z-index: 1000;
	bottom: 3rem;
	right: 1rem;
	position: fixed;

	img {
		display: none;
	}

	img.show {
		display: block;
		height: 5rem;
		width: 5rem;
	}
}

.img-legacy {
	display: table;
	width: auto;
	max-width: 100%;
	padding-left: 15px;
	padding-right: 15px;

	.figure-text {
		display: table-caption;
		caption-side: bottom;
		padding-left: 15px;
		padding-right: 15px;
		text-align: center;
	}

	.figure-caption {
		@include text(zeta);
		color: $color-neutral-darkest;
		~ .figure-credits {
			padding-top: 0.5rem;
		}
	}

	.figure-credits {
		@include text(eta);
		color: $color-neutral;
		font-style: italic;
	}
}

#hp_private_label {
	margin-top: 1rem;
	min-height: calc(100vh - 200px);
}

.healthplan-theme {
	.modal-backdrop {
		// background-color: $color-healthplan-blue;

		&.show {
			// opacity: 0.91;
		}
	}
}

// Overriding Blackbaud form submit button styling to align with MH standard button treatment
.BBFormSubmitbutton {
	background-color: $color-primary-yellow !important;
	border: 5px solid $color-primary-yellow !important;
	border-radius: 6rem !important;
	box-shadow: none !important;
	color: $color-gray-darkest !important;
	font-family: $font-family-sans-serif !important;
	font-size: 1.125rem !important;
	font-weight: $font-weight-semi-bold !important;
	line-height: 1.375rem !important;
	padding: 0.75rem 3.375rem !important;

	&:hover {
		background-color: $color-white !important;
		border-color: $color-primary-yellow !important;
		opacity: 1 !important;
	}

	&:focus {
		box-shadow: 0 0 0 0.2rem rgba($color-secondary-yellow, 0.5) !important;
		outline: 0 !important;
	}
}

// Allowing Wistia to load inside non-visible bootstrap modal in IE
.video-modal:not(.show) {
	display: block;
	opacity: 0 !important;
	pointer-events: none !important;

	.modal-content {
		pointer-events: none !important;

		* {
			pointer-events: none !important;
		}
	}
}

// Sticky nav offset (when present, lg up) for :target anchor element (referenced via hash in URL)
@include media-breakpoint-up(lg) {
	:target[id]:before,
	:target[name]:before {
		content: '';
		display: block;
		height: 70px;
		margin-top: -70px;
		pointer-events: none;
		visibility: hidden;

		.healthplan-theme & {
			height: 60px;
			margin-top: -60px;
		}
	}
	.table-lg-50 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.table-lg-75 {
		flex: 0 0 75%;
		max-width: 75%;
	}
}

// BlockIt elements when there isn't a specified SCSS file starting with "bi-". Body tag has a new class added by JS called ".mhBlockit"
.mhBlockit {
    #screening_container {
        .text-center {
            text-align: left !important;
        }
        h3 {
            color: #212529;
            font-weight: 600;
            padding-top: 1rem;

            &:after {
                background-color: #ffcd55;
                display: block;
                position: relative;
                content: "";
                height: 4px;
                margin: 1rem auto 0 0;
                width: 4.375rem;
             }
        }

        .form-group {
            margin-bottom: 0;
        }

        label {
            font-weight: 600;

            &.border-gray-300 {
                border: 1px solid #ced4da !important;
            }
        }

        input[type=date],
        input[type=email],
        input[type=phone],
        input[type=search],
        input[type=tel],
        input[type=text] {
            background-color: #fff;
        }

        .flex-row-reverse {
            flex-direction: row !important;
        }

        #screening-submit,
        #screening-cancel {
            border-radius: 6rem;
            border-width: 5px !important;
            font-weight: 600;
        }

        #screening-submit {
            color: #212529 !important;
            background-color: #ffcd55;
            border-color: #ffcd55 !important;
            margin-left: 0;
            margin-bottom: 0.5rem;

            &:hover {
                color: #212529 !important;
                background-color: #fff !important;
                border-color: #ffcd55 !important;
            }
        }

        #screening-cancel {
            color: $color-sky-blue !important;
            background: none!important;
            border-color: $color-white !important;
			box-shadow: none !important;
			// Uncomment to remove the underline. The 3rd party blockit styling has underline for the cancel button
			/*text-decoration:none !important;*/
            &:hover {
                color: $color-sky-blue !important;
				background-color: $color-white;
				border-color: none !important;
            }
        }

        .phx-no-feedback,
        .has-success {
            .help-block {
                margin-bottom: 0;
            }
        }
    }

    .bi-search-results {
        .show-past-dates,
        .show-future-dates {
            background-color: #212529;
            border-color: #212529;
            border-radius: 6rem !important;

            &:hover,
            &:focus,
            &:active {
                background-color: #fff !important;
                border-color: #212529 !important;

                .icon {
                    fill: #212529;
                }
            }
        }
    }

	.questionnaire-radio {
		label {
			&:not(:first-child) {
				margin-top: 0;
			}
		}
	}

    @media (min-width: 640px) {
        .questionnaire-radio {
            label {
                &:first-child {
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                }
                &:last-child {
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                    margin-left: 0;
                }
            }
        }

		// Tashika wanted to have cancel button behave as a link and to be oriented to the right. SIT-2046
		.questionnaire-actions {
			justify-content: space-between;
		}
    }
}
