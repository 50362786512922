.advance {
	.video {
		.wistia_embed {
			.w-bottom-bar-left,
			.w-bottom-bar-middle,
			.w-bottom-bar-right {
				// background-color: $color-advance-light-teal !important;
				background-color:rgba(71,210,215,1) !important;
				.w-slider-wrapper{
					background-color:rgba(71,210,215,.4) !important;
				}
			}
			.w-bpb-wrapper {
				border-width: 0 !important;
				button {
					div {
						background-color: $color-advance-light-teal !important;
						mix-blend-mode: normal !important;

						&:hover {
							div {
								background-color: $color-advance-dark-teal !important;
								color:#fff;
							}
						}
					}
					svg{
						height: 85px !important;
						width: 125px !important;
						left: -22px !important;
						@include media-breakpoint-up(sm) {
							height: 115px !important;
							width: 180px !important;
							left: -33px !important;
						}
						polygon{
							fill: $color-advance-dark-teal;
						}
					}
				}
			}
			div[data-handle="playbarlessProgressIndicator"]{
				div div div{
					background-color: $color-advance-light-teal !important;
				}
			}
		}
	}
}
