.microsite-theme,
.moments-theme {
	.gallery {
		.carousel-control-next,
		.carousel-control-prev {
			background-color: transparent;
			height: 55px;
			margin: 0 0.625rem;
			opacity: 1;
			padding: 0;
			width: 40px;

			&:before {
				content: '';
				display: block;
				height: 40px;
				border: 3px solid $color-white;
				width: 40px;
			}
		}

		.carousel-control-prev {
			&:before {
				border-right: none;
				border-top: none;
				transform: translateX(25%) rotate(45deg);
			}
		}

		.carousel-control-next {
			&:before {
				border-left: none;
				border-top: none;
				transform: translateX(-25%) rotate(-45deg);
			}
		}

		.icon-arrow-left,
		.icon-arrow-right {
			display: none;
			fill: $color-white;
		}

		.carousel-indicators {
			justify-content: center;
			margin: 0;
			padding-top: 1rem;
			width: 100%;

			li {
				background-color: $color-black;
				height: 9px;
				margin-left: 0.375rem;
				margin-right: 0.375rem;
				opacity: 0.4;
				width: 9px;

				&.active {
					opacity: 1;
				}
			}
		}

		.carousel-caption {
			margin-top: 0;
			padding-top: 0.5rem;
			width: 100%;
		}

		.figure-caption {
			color: $color-black;
		}

		.figure-credit {
			color: $color-microsite-gray;
		}

		.carousel {
			&.onethird,
			&.onehalf,
			&.twothird {
				.carousel-control-next,
				.carousel-control-prev {
					height: 55px;
					width: 40px;
				}

				.carousel-indicators {
					margin: 0;
				}
			}

			.carousel-inner {
				width: 100%;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.two-column {
			.two-column {
				.row {
					[class^='col'] {
						&:first-of-type {
							.gallery {
								padding-right: 3rem;
							}
						}

						&:last-of-type {
							.gallery {
								padding-left: 3rem;
							}
						}
					}
				}
			}
		}

		.gallery {
			.carousel {
				&.onehalf {
					flex: 0 0 calc(50% - 0.5rem - 3rem);
					max-width: calc(50% - 0.5rem - 3rem);
				}
			}
		}
	}
}

.moments-theme {
	.gallery {
		.carousel-control-next,
		.carousel-control-prev {
			height: 30px;
			width: 20px;

			&:before {
				border-width: 2px;
				content: '';
				display: block;
				height: 20px;
				width: 20px;
			}
		}

		.carousel-indicators {
			li {
				background-color: #bbbbbb;
				opacity: 1;

				&.active {
					background-color: $color-black;
					opacity: 1;
				}
			}
		}
	}
}
