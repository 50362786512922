.childrens-theme {
	.video {
		.wistia_embed {
			.w-bpb-wrapper {
				button {
					div {
						background-color: $color-childrens-primary !important;
						mix-blend-mode: normal !important;

						&:hover {
							div {
								background-color: $color-childrens-secondary !important;
							}
						}
					}
				}
			}
		}
	}
}
