.related-specialties {
	@include make-container();
	@include make-container-max-width();

	.section-title {
		&:after {
			background-color: $color-primary-yellow;
		}
	}

	.related-inner {
		margin-bottom: 4rem;
		max-width: 100%;
		padding: 1.5rem;

		&.is-expanded {
			.toggle-expand {
				.icon {
					transform: rotate(180deg);
				}
			}
		}

		.toggle-expand {
			bottom: -30px;
			position: relative;

			.icon {
				height: 14px;
				fill: $color-white;
				width: 28px;
			}
		}

		a {
			text-decoration: none;
		}
	}

	.related-specialties-content {
		display: flex;
		flex-direction: column;
		margin-bottom: 1rem;
		overflow: hidden;
		padding: 0;

		&.read-more-body {
			max-height: 100%;
		}
	}

	.offering-list {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			@include text(zeta);
			display: inline-block;
			margin: 0;
			padding-bottom: 0.375rem;
			width: 100%;

			padding-bottom: 1rem;
			border-bottom: 1px solid $color-neutral;
			margin-bottom: 1rem;
		}
	}

	@include media-breakpoint-up(sm) {
		.offering-list {
			columns: 2;
			column-gap: $grid-gutter-width;

			li {
				@include text(zeta);
				padding-bottom: 1rem;
			}
		}

		&.is-two-col {
			.offering-list {
				columns: 1;
			}
		}

		.two-column & {
			.offering-list {
				columns: 1;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.section-title {
			padding-bottom: 0;
			margin-bottom: 2rem;
			&::after {
				display: none;
			}
		}

		.related-inner {
			border: none;
			padding: 1.75rem 0 1.5rem;
			margin-bottom: 1.5rem;
		}

		.related-specialties-content {
			max-height: none;
			overflow: auto;
			padding: 0;
			button {
				display: none;
			}
		}

		.toggle-expand {
			display: none;
		}

		.offering-list {
			columns: 3;

			li {
				@include text(zeta);
			}
		}

		&.is-two-col {
			.offering-list {
				columns: 2;
			}
		}

		.two-column & {
			.offering-list {
				columns: 2;
			}

			&.is-two-col {
				.offering-list {
					columns: 2;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.offering-list {
			columns: 3;
		}

		.two-column & {
			.offering-list {
				columns: 3;
			}
		}
	}
}
