.three-column {
	@include make-container();
	@include make-container-max-width();
	margin-bottom: 2rem;

	&.alt-background {
		background-color: $color-highlight;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.row > * {
		margin-bottom: 1.25rem;

		> :last-child {
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}

	@include media-breakpoint-up(md) {
		.row > * {
			margin-bottom: 0;
		}
	}
}
