.location-edw-profile-detail {
	@include make-container();
	@include make-container-max-width();
	margin-bottom: 3rem;
	padding-top: 1rem;

	.profile-detail-navigation {
		margin-bottom: 1.5rem;
	}

	.back-to-results {
		display: none;
	}

	.profile-detail-card {
		border: 0;
		text-align: center;
	}

	.card-header,
	.card-body,
	.card-footer {
		background-color: transparent;
		border: 0;
	}

	.profile-image {
		display: inline-block;
		height: 128px;
		width: 120px;
	}

	.profile-name {
		@include text(delta);
		color: $color-primary-dark-blue;
		display: inline-block;
		font-weight: $font-weight-bold;
		margin-bottom: 1.5rem;
	}

	.ratings {
		align-items: center;
		display: flex;
		flex-direction: column;
		height: 44px;
		margin-top: 1.25rem;

		.rating-count {
			@include text(zeta);
			margin-top: 0.5rem;
		}
	}

	.profile-specialties {
		margin-bottom: 1.5rem;
	}

	.specialty {
		@include text(zeta);
		color: $color-neutral-darkest;
		display: block;
		font-weight: $font-weight-bold;
		margin-bottom: 0;

		+ .specialty {
			padding-top: 0.5rem;
		}
	}

	.profile-biography {
		color: $color-neutral-darkest;
		margin-bottom: 3rem;
		position: relative;
		text-align: left;

		p,
		li {
			@include text(epsilon, delta);
			text-align: left;
		}
	}

	.affiliation-logo {
		display: inline-block;
		max-width: 175px;
	}

	.expanded-content,
	.phone-number {
		display: none;
	}

	.quick-links {
		list-style: none;
		padding: 1rem 0 0 0;

		li + li {
			padding-top: 1rem;
		}

		a {
			background-color: $color-neutral-lightest;
			color: $color-quick-links;
			display: block;
			font-weight: $font-weight-bold;
			padding: 1rem;
		}
	}

	@include media-breakpoint-up(sm) {
		.profile-detail-navigation {
			align-items: center;
			display: flex;
			justify-content: space-between;
			margin-bottom: 1.375rem;
		}

		.back-to-results {
			@include text(zeta);
			display: inline-block;
			font-weight: $font-weight-semi-bold;

			svg {
				fill: $color-secondary-light-blue;
				margin-right: 0.75rem;
			}
		}

		.profile-detail-card {
			border: 0;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			padding-top: 1rem;
			text-align: left;
		}

		.card-header {
			text-align: center;
		}

		.card-header,
		.card-footer {
			width: 235px;
		}

		.card-body {
			order: 1;
			width: calc(100% - 235px);
		}

		.quick-links {
			li + li {
				padding-top: 0.5rem;
			}
			a {
				@include text(zeta);
				padding: 0.375rem 0 0.375rem 1rem;
			}
		}

		.phone-number {
			@include text(zeta);
		}

		.phone-number,
		.affiliation-logo {
			display: inline-block;
		}

		.affiliation-logo {
			display: inline-block;
			max-width: 115px;

			&:not(:last-of-type) {
				margin-bottom: 1rem;
			}
		}
	}

	@include media-breakpoint-up(md) {
		padding-top: 1.75rem;

		.profile-detail-card {
			padding-left: 2rem;
			padding-right: 2rem;
		}

		.card-footer {
			width: 232px;
		}

		.card-body {
			width: calc(100% - 232px);
		}

		.quick-links {
			li + li {
				padding-top: 0.5rem;
			}
			a {
				@include text(zeta);
				padding: 0.375rem 0 0.375rem 1rem;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.profile-detail-card {
			padding-bottom: 1.5rem;
			padding-top: 2rem;
			text-align: left;
		}

		.card-header {
			padding: 0 1.25rem 0.5rem 1.25rem;
			width: 225px;
		}

		.card-footer {
			padding: 0.75rem 1.25rem 0 2rem;
			width: 243px;
		}

		.card-body {
			order: 0;
			padding: 0.5rem 1.25rem 0 1.25rem;
			width: calc(100% - 568px);
		}

		.quick-links {
			a {
				margin-left: -2rem;
				width: calc(100% + 2rem);
				padding-left: 2rem;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.profile-detail-card {
			padding-left: 1.75rem;
			padding-right: 1.75rem;
		}
	}
}
