.healthplan-theme {
	@include media-breakpoint-up(md) {
		.view-mode-list {
			.doctor-result {
				.card-header {
					flex-grow: 1;
				}
			}
		}
	}
}
