.microsite-theme {
	.quizlet {
		.inner-container {
			background-color: $color-neutral-lighter;
		}

		.quizlet-heading {
			color: $color-gray-darkest;
			font-weight: $font-weight-normal;
		}

		.question-indicator {
			&:not(:first-of-type) {
				&:before {
					background-color: $color-microsite-gray-quizlet;
					height: 2px;
				}
			}

			&.completed {
				.question-number {
					background-color: $color-microsite-gray-quizlet;
					border-color: $color-microsite-gray-quizlet;
					color: $color-primary-blue-gray;
				}
			}

			&.active {
				.question-number {
					background-color: $color-primary-blue-gray;
					border-color: $color-primary-blue-gray;
					color: $color-white;
				}
			}
		}

		.question-number {
			border: 2px solid $color-microsite-gray-quizlet;
			color: $color-primary-blue-gray;
		}

		.question-desc {
			@include text(epsilon);
		}

		.answer-grid {
			&.agree-disagree {
				&:before {
					background-color: $color-primary-blue-gray;
				}

				input[type='radio'] {
					&:checked {
						+ label {
							.answer-content {
								background-color: transparent;
								color: $color-gray-darkest;
							}
						}
					}

					+ label {
						&:before {
							background-color: $color-white;
						}
					}
				}
			}
		}

		input[type='checkbox'] {
			&:checked {
				+ label {
					border-color: transparent;

					.answer-content {
						background-color: $color-primary-blue-gray;
						color: $color-white;
					}

					&:before {
						background-color: transparent;
						border-color: $color-microsite-yellow;
					}

					&:after {
						border-bottom: 3px solid $color-primary-blue-gray;
						border-right: 3px solid $color-primary-blue-gray;
						content: '';
						display: block;
						height: 1.375rem;
						right: 1rem;
						margin: 0.375rem 0.625rem;
						position: absolute;
						bottom: 1rem;
						transform: rotate(45deg) translate(-2px, -3px);
						width: 0.875rem;
					}
				}

				&:hover {
					+ label {
						&:before {
							border-color: $color-microsite-yellow;
						}
					}
				}

				&:focus {
					+ label {
						&:before {
							box-shadow: 0 0 0 0.2rem
								rgba($color-microsite-yellow, 0.25);
						}
					}
				}
			}

			&:hover {
				+ label {
					&:before {
						border-color: $color-primary-blue-gray;
					}
				}
			}

			&:focus {
				+ label {
					&:before {
						box-shadow: 0 0 0 0.2rem
							rgba($color-primary-blue-gray, 0.25);
					}
				}
			}

			+ label {
				.answer-content {
					color: $color-gray-darkest;
				}

				&:before {
					border: 2px solid $color-primary-blue-gray;
				}

				&:hover {
					&:before {
						border-color: $color-primary-blue-gray;
					}
				}
			}
		}

		input[type='radio'] {
			&:checked {
				+ label {
					border-color: transparent;

					.answer-content {
						background-color: $color-primary-blue-gray;
						color: $color-white;
					}

					&:before {
						border-color: $color-microsite-yellow;
					}

					&:after {
						background-color: $color-microsite-yellow;
					}
				}

				&:hover {
					+ label {
						&:before {
							border-color: $color-microsite-yellow;
						}
					}
				}
			}

			&:hover {
				+ label {
					&:before {
						border-color: $color-primary-blue-gray;
					}
				}
			}

			&:focus {
				+ label {
					&:before {
						box-shadow: 0 0 0 0.2rem
							rgba($color-microsite-yellow, 0.25);
					}
				}
			}

			+ label {
				.answer-content {
					color: $color-gray-darkest;
				}

				&:before {
					border: 2px solid $color-primary-blue-gray;
				}

				&:hover {
					cursor: pointer;

					&:before {
						border-color: $color-primary-blue-gray;
					}
				}
			}
		}

		@include media-breakpoint-up(lg) {
			.quizlet-progress {
				margin-bottom: 2rem;
			}
		}
	}
}
