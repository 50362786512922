.filter-list {
	background-color: $color-white;
	pointer-events: none;
	overflow: auto;
	visibility: hidden;
	opacity: 0;
	top: 20vh;
	padding-bottom: 80px;
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	transition: visibility 0s ease-in-out 400ms, opacity 0.2s ease-in-out,
		top 0.2s ease-in-out;
	z-index: 9;

	&.filters-open {
		display: block;
		padding: 1rem;
		opacity: 1;
		pointer-events: auto;
		visibility: visible;
		top: 0;
		transition-delay: 0s;
	}

	&.view-all-open {
		#view-all-root {
			display: block;
		}

		> .accordion-container {
			display: none;
		}
	}

	.accordion-container {
		padding: 0;
	}

	.mobile-filter-header {
		align-items: center;
		background-color: $color-white;
		color: $color-primary-dark-blue;
		display: flex;
		height: 60px;
		justify-content: space-between;
		padding: 0 1rem;
		position: relative;

		.mobile-close-btn {
			@include text(delta);
			background: none;
			border: none;
			color: $color-primary-light-blue;
			font-weight: $font-weight-extra-bold;
			margin-left: -1rem;
			padding: 1rem;

			.icon {
				fill: $color-primary-light-blue;
				height: 1rem;
			}

			&:hover,
			&:focus {
				outline: 0;

				.icon {
					fill: $color-secondary-light-blue;
				}
			}
		}

		.icon-arrow-right {
			transform: rotate(180deg);
		}

		.filter-heading {
			@include text('beta');
			font-weight: $font-weight-extra-bold;
			left: 50%;
			margin: 0;
			position: absolute;
			text-align: center;
			top: 50%;
			transform: translate(-50%, -50%);
			width: calc(100% - 100px);
			pointer-events: none;
		}

		.filter-link {
			color: $color-primary-blue;
		}
	}

	.nav-accordion {
		background-color: $color-neutral-lightest;

		.accordion-item {
			border-color: $color-precision-gray-tint;

			&[aria-expanded='true'] {
				.accordion-item-title {
					.accordion-item-toggle {
						color: $color-primary-blue;
						font-weight: $font-weight-semi-bold;

						.icon {
							fill: $color-primary-blue;
						}
					}
				}

				&.closing {
					> .accordion-item-title {
						.accordion-item-toggle {
							color: $color-gray-darkest;

							&:hover,
							&:focus {
								color: $color-secondary-teal;
							}
						}
					}
				}
			}

			.accordion-item-title {
				.accordion-item-toggle {
					color: $color-gray-darkest;
					font-weight: $font-weight-normal;

					&:hover,
					&:focus {
						color: $color-deep-green;

						.icon {
							fill: $color-deep-green;
						}
					}

					.label-secondary {
						font-weight: $font-weight-normal;
					}

					.icon {
						fill: $color-gray-darkest;
					}
				}

				.accordion-item-tags {
					background-color: $color-neutral-lightest;
				}
			}

			.accordion-item-body {
				color: $color-gray-darkest;
				font-weight: $font-weight-semi-bold;

				input[type=text], input[type=email], input[type=search] {
					background-color: $color-white;
					color: $color-gray-light;
				}

				input[type=text][placeholder], input[type=email][placeholder], input[type=search][placeholder] {
					color: $color-blue-gray;
				}

				.range-container .range-label-list li.active span {
					color: $color-gray-darkest;
				}
			}
		}
	}

	.checkbox-filter {
		align-items: center;
		display: flex;
		padding: 1rem;

		.checkbox-wrapper {
			margin: 0 1rem 0 0;

			input[type='checkbox'] {
				+ label {
					color: $color-gray-darkest;
					font-weight: $font-weight-semi-bold;
				}
			}
		}

		img {
			display: none;
		}

		&.isMHMG-container {
			display: none;
		}
	}

	.mobile-filter-show-btn {
		align-items: center;
		bottom: 0;
		display: flex;
		height: 80px;
		padding: 0 15px 1rem;
		position: fixed;
		width: calc(100% - 2rem);

		.filter-show-btn {
			@include text(epsilon);
			width: 100%;

			&:hover {
			}

			&:focus {
			}
		}

		&.loading {
			.filter-show-btn {
				@include loader($color-white);
				color: transparent;
				position: relative;

				&:after {
					left: 50%;
					margin-left: -13px;
					margin-top: -13px;
					position: absolute;
					top: 50%;
				}
			}
		}
	}

	.tag-list {
		.tag {
			color: $color-deep-green;
		}
	}

	.filter-header {
		display: none;
	}

	.filter-link,
	.show-more-trigger {
		@include text('zeta');
		background: none;
		border: 0;
		color: $color-primary-blue;
		font-weight: $font-weight-semi-bold;
		margin: 0;
		outline: 0;
		padding: 0;

		&:hover,
		&:focus {
			text-decoration: underline;
		}

		&:disabled {
			color: $color-neutral-light;
			pointer-events: none;
		}
	}

	.range-container {
		padding-top: 1.5rem;
		margin: 0 1rem;
	}

	#view-all-root {
		display: none;
	}

	.view-all-trigger {
		padding-top: 1rem;
	}

	.view-all-panel {
		background-color: $color-neutral-lightest;
		color: $color-white;
		display: block;

		.view-all-header {
			display: none;
		}

		.view-all-dismiss-container {
			.dismiss-btn {
				@include text(delta);
				background: none;
				border: none;
				color: $color-primary-light-blue;
				font-weight: $font-weight-extra-bold;
				margin-left: -1rem;
				padding: 1rem;

				.icon {
					fill: $color-primary-light-blue;
				}

				&:hover,
				&:focus {
					outline: 0;

					.icon {
						fill: $color-secondary-light-blue;
					}
				}
			}
		}

		input[type=text], input[type=email], input[type=search] {
			background-color: $color-white;
			color: $color-gray-darkest;
		}

		input[type=text][placeholder], input[type=email][placeholder], input[type=search][placeholder] {
			color: $color-neutral-darkest;
		}

		.rbt {
			margin: 1rem 1rem 0;
		}

		.tag-list {
			padding: 0 1rem;
		}

		.range-container {
			padding: 1.5rem 0 0;
			margin: 0 2rem;
		}

		.accordion-label {
			@include text(epsilon);
			color: $color-primary-blue;
			margin: 2rem 1rem 0;
		}

		.nav-accordion {
			border-top: 1px solid $color-primary-blue;
			margin-top: 1rem;

			.object-section {
				display: flex;
				padding: 0.5rem 1rem 0.5rem 0;

				.object-section-label {
					@include text(zeta);
					margin-right: 2rem;
					min-width: 16px;
				}

				.object-section-content {
					border-bottom: 1px solid $color-neutral-light;
					padding-bottom: 0.5rem;
					width: 100%;
				}

				&:last-of-type {
					.object-section-content {
						border-bottom: 0;
					}
				}
			}
		}

		.view-all-no-accordion {
			border-top: 1px solid $color-primary-blue;
			margin-top: 1rem;
			padding: 1.625rem 1.5rem 0.25rem;
		}
	}

	.filter-availability {
		padding: 0.25rem 0;

		hr {
			border-color: $color-primary-blue;
			margin: 1.5rem 0;
		}

		.secondary-filter-title {
			@include text(epsilon);
			color: $color-white;
			font-weight: $font-weight-semi-bold;
		}

		p {
			@include text(zeta);
		}

		.radio-btns {
			padding-top: 0.25rem;

			input[type='radio'] {
				&:checked {
					+ label {
						background-color: $color-secondary-dark-teal;
						border-color: $color-secondary-dark-teal;
					}
				}

				&:focus {
					+ label {
						background-color: $color-secondary-dark-teal;
						border-color: $color-secondary-dark-teal;
						box-shadow: 0 0 0 0.2rem
							rgba($color-secondary-dark-teal, 0.5);
					}
				}

				+ label {
					background-color: $color-secondary-teal;
					border-color: $color-secondary-teal;
				}

				&:disabled {
					+ label {
						background-color: $color-gray-light;
						border-color: $color-gray-light;
						pointer-events: none;
					}

					&:checked {
						+ label {
							background-color: $color-gray-light;
							border-color: $color-gray-light;
						}
					}
				}
			}
		}
	}

	.filter-gender {
		padding: 0.25rem 0;

		.radio-wrapper:last-of-type {
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-up(lg) {
		background-color: transparent;
		display: block;
		height: auto;
		opacity: 1;
		overflow: visible;
		pointer-events: auto;
		visibility: visible;
		position: relative;
		top: auto;
		left: auto;
		width: auto;
		z-index: 7;

		&.view-all-open {
			overflow: visible;
		}

		.accordion-container {
			margin-bottom: 0;
		}

		.nav-accordion {
			background-color: $color-white;

			.accordion-item {
				border-color: $color-precision-gray-tint;

				&[aria-expanded='true'] {
					.accordion-item-title {
						.accordion-item-toggle {
							color: $color-primary-dark-blue;
						}
					}

					&.closing {
						> .accordion-item-title {
							.accordion-item-toggle {
								color: $color-neutral-dark;

								&:hover,
								&:focus {
									color: $color-primary-light-blue;
								}
							}
						}
					}
				}

				.accordion-item-title {
					.accordion-item-toggle {
						color: $color-neutral-dark;

						&:hover,
						&:focus {
							color: $color-secondary-light-blue;
						}

						.label-secondary {
							color: $color-secondary-light-blue;
						}
					}

					.accordion-item-tags {
						background-color: $color-white;
					}
				}

				.accordion-item-body {
					color: $color-neutral-darkest;

					input[type=text], input[type=email], input[type=search] {
						background-color: $color-neutral-lightest;
						color: $color-gray-darkest;
					}

					input[type=text][placeholder], input[type=email][placeholder], input[type=search][placeholder] {
						color: $color-neutral-darkest;
					}

					.rbt .icon {
						stroke: $color-gray-darkest;

						&.disabled {
							stroke: $color-gray-light;
						}
					}
				}
			}
		}

		.checkbox-filter {
			.checkbox-wrapper {
				input[type='checkbox'] {
					+ label {
						color: $color-neutral-dark;
					}
				}
			}

			img {
				display: block;
				height: auto;
				width: 80px;
			}
		}

		.mobile-filter-show-btn {
			display: none;
		}

		.tag-list {
			.tag {
				color: $color-primary-light-blue;
			}
		}

		.mobile-filter-header {
			display: none;
		}

		.filter-header {
			align-items: center;
			display: flex;
			justify-content: space-between;
			margin-top: 1rem;
			margin-bottom: 0.5rem;

			.filter-heading {
				@include text('zeta');
				font-weight: $font-weight-normal;
				color: $color-neutral;
				margin: 0;
				padding-left: 1rem;
			}

			.clear-btn-container {
				margin-right: 2px;
			}
		}

		.filter-link,
		.show-more-trigger {
			color: $color-secondary-light-blue;
		}

		.range-container {
			margin: 0;
		}

		.view-all-panel {
			background-color: $color-white;
			border: 1px solid $color-neutral-light;
			box-shadow: 0 10px 18px rgba($color-black, 0.12);
			color: $color-neutral-darkest;
			display: block;
			margin-bottom: 2rem;
			margin-top: 1rem;
			padding: 1rem;

			.view-all-header {
				align-items: center;
				display: flex;
				justify-content: space-between;
				margin-bottom: 1.25rem;

				.search-title {
					margin: 0;
				}
			}

			.view-all-dismiss-container {
				margin-left: 1rem;

				.dismiss-btn {
					margin-left: 0;
					padding: 0;
				}
			}

			.rbt {
				margin: 0;
			}

			.range-container {
				padding: 1.5rem 0 0;
				margin: 0;
			}

			.tag-list {
				padding: 0 0.1rem;
			}

			.accordion-label {
				@include text(epsilon);
				color: $color-secondary-blue;
				margin: 2rem 0 0;
			}

			.nav-accordion {
				border-top: 1px solid $color-neutral-light;

				.object-section {
					padding-right: 1.5rem;
				}
			}

			.view-all-no-accordion {
				border-color: $color-neutral-light;
			}
		}

		.filter-availability {
			hr {
				border-color: rgba($color-black, 0.1);
			}

			.secondary-filter-title {
				color: $color-primary-dark-blue;
			}

			.radio-btns {
				input[type='radio'] {
					&:checked {
						+ label {
							background-color: $color-primary-dark-blue;
							border-color: $color-primary-dark-blue;
						}
					}

					&:focus {
						+ label {
							background-color: $color-primary-dark-blue;
							border-color: $color-primary-dark-blue;
							box-shadow: 0 0 0 0.2rem
								rgba($color-primary-dark-blue, 0.35);
						}
					}

					+ label {
						background-color: $color-primary-blue-gray;
						border-color: $color-primary-blue-gray;
					}
				}
			}
		}

		.filter-gender {
			padding: 0.25rem 0;

			.radio-wrapper:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}
