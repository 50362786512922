.locations-and-appointments-edw {
	@include make-container();
	@include text(zeta, gamma);
	margin-bottom: 2rem;
	padding-top: 1rem;
	color: $color-edw-gray;

	&.no-online-scheduling {
		.locations {
			.carousel-item {
				display: block;
				float: none;
			}
		}

		.read-more {
			&.is-expanded {
				.read-more-expanded {
					display: block;
				}
			}
		}

		&.single-location,
		&.two-locations,
		&.three-locations {
			.appointments {
				display: none;
			}
		}
	}

	&.practice-members {
		.location-details {
			.subsection-content {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				padding-bottom: 1rem;

				.appointment-type-label {
					padding-bottom: 0;
				}
			}
		}
	}

	.locations-and-appointments-inner {
		@include make-container();
		padding: 2rem 1rem 1rem;

		&.border {
			border: 1px solid rgba($color: #000000, $alpha: 0.13);
			border-radius: 0.25rem;
			box-shadow: 0 0.25rem 0.25rem 0 rgba($color: #000000, $alpha: 0.13);
		}
	}

	.locations {
		//margin-bottom: 2rem;
	}

	.distance-and-directions,
	.location-details {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.distance-and-directions {
		display: flex;
		align-items: center;
		margin-top: 1rem;
		margin-bottom: 1.5rem;
	}

	.distance {
		@include text(zeta);
		color: $color-secondary-light-blue;
		display: inline-block;
		font-weight: $font-weight-semi-bold;
		padding-right: 1rem;
	}

	.get-directions {
		@include text(zeta, 14);
		border-radius: 5px;
		padding: 0.375rem 1rem;
	}

	p {
		@include text(zeta, gamma);

	}

	.section-title {
		@include text(delta, delta);
		color: $color-edw-gray;
		font-weight: $font-weight-bold;
		margin-bottom: 0.25rem;
		text-transform: uppercase;
		text-align: left;

		&:after {
			display: none;
		}
	}

	.section-subtitle {
		@include text(delta, gamma);
		color: $color-edw-gray;
		font-weight: $font-weight-bold;
		margin-bottom: 0.25rem;
		padding-bottom: 1rem;

		&:after {
			display: none;
		}
	}

	.subsection {
		width: 100%;
		min-width: 200px;
	}

	.subsection-title {
		@include text(zeta, delta);
		color: $color-edw-blue;
		font-weight: $font-weight-bold;
		margin-bottom: 0.25rem;
	}

	.subsection-content {
		@include text(zeta, delta);
		margin-bottom: 1rem;

		&:not(:last-child) {
			margin-bottom: 1.5rem;
		}
	}

	.ratings {
		align-items: center;
		display: flex;
		height: 18px;
		justify-content: center;
		margin: 0.5rem 0;

		.rating-stars {
			margin-right: 0.75rem;
		}

		.rating-count {
			@include text(zeta);
			color: $color-neutral-dark;
		}
	}

	address {
		margin-bottom: 0.25rem;
	}

	.phone-and-fax {
		font-weight: $font-weight-semi-bold;
	}

	.fax-number {
		margin-left: 2rem;
		overflow: visible;
		position: relative;

		&:before {
			content: '|';
			display: inline-block;
			pointer-events: none;
			position: absolute;
			left: -1.5rem;
		}
	}

	.fax-label {
		color: $color-edw-blue;
		padding-left: 0.5rem;
	}

	.office-hours-list {
		list-style: none;
		margin-bottom: 1rem;
		padding-left: 0;

		li {
			@include text(zeta, delta);
		}
	}

	.physician-info {
		text-align: center;
		padding: 1rem 0;
		width: 100%;

		.profile-image {
			height: 160px;
			margin: 0 auto 1rem;
			min-width: 160px;
			width: 160px;
		}
	}

	.carousel-controls,
	.carousel-indicators {
		// display: none; // removed for R1
		display: flex; // added for R1
		z-index: 5;
		padding-right: 15px; // added for R1
		padding-left: 15px; // added for R1
	}

	.carousel-indicators {
		// added for R1
		top: 100%;
		align-items: center;

		li {
			// moved to XS for R1
			background-color: $color-neutral-dark;
			border-radius: 50%;
			height: 12px;
			margin: 0 4px;
			width: 12px;

			&.active {
				pointer-events: none;
			}
		}
	}

	.carousel-control-prev,
	.carousel-control-next {
		background-color: $color-edw-blue;
		opacity: 1;
		padding: 1.5rem 2.5rem;
		position: relative;
		width: auto;

		.icon {
			fill: $color-white;
		}
	}

	.view-other-locations {
		@include text(delta);
		background: transparent;
		border: 0;
		flex-grow: 1;
		outline: none;
		text-align: center;

		.icon-arrow-down {
			margin-left: 0.5rem;
		}
	}

	.carousel-item {
		&.no-appointments,
		&.no-online-scheduling {
			.appointment-request-container {
				display: block;
			}

			.appointments-summary {
				display: none;
			}
		}
	}

	.carousel-item.active,
	.carousel-item-next,
	.carousel-item-prev {
		display: block;
	}

	.doctors-carousel {
		.phone-and-fax {
			margin-bottom: 1rem;
		}

		.affiliation-logo {
			float: right;
			width: 115px;
		}
	}

	.appointments {
		@include make-col-ready(); // added for R1
		@include make-col(12); // added for R1
		display: block; // added for R1
		// display: none; // removed for R1
	}

	.legend {
		text-align: center;
	}

	.appointments-modal {
		.appointments {
			display: block;
		}
	}

	.appointment-request-container {
		@include text(zeta);
		display: none;
		border-top: none;
		padding: 1rem 2rem;

		.appointment-request-btn {
			color: $color-white;
			padding: 7px;
			width: 100%;

			+ .appointment-request-phone {
				padding-top: 1rem;
			}
		}

		.appointment-request-phone {
			@include text(zeta);
			align-items: center;
			color: $color-primary-dark-blue;
			display: flex;
			justify-content: center;
			padding: 0 1rem;

			svg {
				flex: 0 0 2rem;
				height: 2rem;
				margin-right: 1rem;
				width: 2rem;
			}

			a {
				display: block;

				span {
					display: inline-block;
				}
			}

			.btn {
				border-radius: 50rem;
				letter-spacing: 0.125rem;
				text-transform: uppercase;
			}
		}
	}

	.appointments-summary {
		@include text(zeta);
		border-top: none;
		padding: 1rem;
		display: flex;
		flex-flow: column;

		.location-address {
			//border-bottom: 1px solid $color-neutral;
			//margin-bottom: 1rem;
			padding-bottom: 1rem;

			&:empty {
				display: none;
			}
		}

		.address {
			color: $color-edw-blue;
		}

		.appointment-type-label {
			display: none;
		}

		.appointment-heading {
			color: $color-primary-dark-blue;
			font-weight: $font-weight-bold;
			margin-bottom: 0.75rem;
			text-transform: uppercase;
		}

		.appointment-date {
			color: $color-edw-blue;
			font-weight: $font-weight-bold;
			margin-bottom: 0.5rem;
		}

		.appointment-time {
			margin-right: 0.875rem;
		}

		.modal-open-btn {
			background: none;
			border: none;
			color: $color-primary-light-blue;
			font-weight: $font-weight-normal;
			padding: 0 0.625rem 0 0;
			position: relative;

			&:after {
				border-bottom: 6px solid transparent;
				border-left: 6px solid $color-quick-links;
				border-top: 6px solid transparent;
				clear: both;
				content: '';
				display: inline-block;
				height: 0;
				left: 100%;
				position: absolute;
				top: 3px;
				width: 0;
			}

			&:hover,
			&:focus {
				background: none;
				border: none;
				color: $color-primary-light-blue;
				outline: 0;
				text-decoration: underline;
			}
		}

		.legend {
			padding-top: 1rem;
			text-align: left;
		}

		.btn {
			border-radius: 50rem;
			letter-spacing: 0.125rem;
			text-transform: uppercase;
			max-width: 350px;
			align-self: center;
		}

		.select-custom {
			overflow: hidden;
			position: relative;
			margin-bottom: 0.5rem;
			width: 100%;

			svg {
				pointer-events: none;
			}
		}

		.select-custom select {
			position: relative;
			appearance: none;
			background-color: transparent;
			background-image: none;
			box-shadow: none;
			display: block;
		}

		.select-custom-inner::-ms-expand {
			display: none;
		}

		.select-custom-inner {
			border-bottom: 1px solid #454545;
			border-left: none;
			border-top: none;
			border-right: none;
			border-radius: 0;
			overflow: hidden;
			position: relative;
			width: 100%;
			appearance: none;
		}

		.icon-arrow-down {
			position: absolute;
			right: 0.75rem;
			bottom: 1rem;
			display: block;
			padding: 0;
		}
	}

	.available-providers {
		display: block;
		padding-top: 15px;
		padding-left: 15px;
		padding-right: 15px;

		.heading-5 {
			margin-bottom: 0.825rem;
		}
	}

	.provider-list {
		display: flex;
		flex-wrap: wrap;
		padding-left: 0;
		margin-bottom: 1rem;
		list-style: none;

		.provider-list-item {
			display: block;
			width: 100%;

			&.active {
				.provider-item {
					background-color: $color-highlight;
					pointer-events: none;
				}

				.cta-link {
					display: none;
				}
			}
		}

		.provider-item {
			background: transparent;
			border: none;
			display: flex;
			padding: 1rem 0.5rem;
			width: 100%;

			&:hover,
			&:focus {
				background-color: $color-neutral-lightest;
				outline: none;
				text-decoration: none;

				.cta-link {
					text-decoration: underline;
				}
			}

			svg {
				pointer-events: none;
			}
		}

		.profile-image {
			height: 90px;
			width: 90px;
			min-width: 90px;
		}

		.profile-content {
			@include text(zeta);
			color: $color-neutral-darkest;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding-left: 1rem;
			text-align: left;
			height: 85px;
		}

		.profile-content-main {
			flex-grow: 1;
		}

		.profile-name {
			@include text(zeta);
			color: $color-edw-blue;
			font-weight: 700;
			margin-bottom: 0.5rem;
		}

		.cta-link {
			color: $color-edw-blue;
			font-weight: 700;
		}
	}

	.locations-modal {
		.modal-dialog {
			max-width: 800px;
		}

		.modal-content {
			border: 0;
		}

		.modal-body {
			padding: 2rem;

			&:only-child {
				padding: 2rem;
			}
		}

		.modal-close-btn {
			background: transparent;
			border: 0;
			position: absolute;
			right: 1rem;
			top: 1rem;

			svg {
				height: 1.25rem;
				width: 1.25rem;
			}
		}

		.location-select-list {
			columns: 1;
			list-style: none;
			margin-bottom: 0;
			padding-left: 0;

			li {
				display: block;
				padding: 0.5rem 0;
				break-inside: avoid-column;
			}
		}

		.location-name {
			font-weight: $font-weight-bold;
		}
	}

	.button-container {
		margin-bottom: 1.5rem;
		width: 100%;
		text-align: center;
	}

	.read-more-expanded {
		width: 100%;
	}

	@include media-breakpoint-down(sm) {
		.carousel-item {
			float: left;

			margin-bottom: 3rem;
		}

		.locations-modal {
			.location-select-list {
				columns: 2;

				li {
					display: inline-block;
				}
			}
		}
	}

	@include media-breakpoint-up(sm) {
	}

	@include media-breakpoint-up(md) {

		&.no-online-scheduling {
			&.single-location {
				.locations {
					//@include make-col(8);

					.carousel-item {
						.location-details {
							padding-left: $grid-gutter-width;
						}
					}
				}

				.appointments {
					@include make-col(4);
					display: block;

					.appointment-request-container {
						display: block;
					}
				}
			}

			&.two-locations {
				.locations {
					@include make-col(8);

					.carousel-inner {
						@include make-row();
					}

					.carousel-item {
						@include make-col-ready();
						@include make-col(6);

						display: block;
						margin-right: 0;
					}
				}

				.appointments {
					@include make-col(4);
					display: block;

					.appointment-request-container {
						display: block;
					}
				}
			}

			&.three-locations {
				.locations {
					@include make-col(12);

					.carousel-inner {
						@include make-row();
						width: auto;
					}

					.carousel-item {
						@include make-col-ready();
						@include make-col(4);

						display: block;
						margin-right: 0;
						margin-bottom: 1.5rem;
					}
				}

				.appointments {
					@include make-col(12);
					display: block;

					.appointment-request-container {
						display: block;
					}
				}
			}

			.locations {
				.distance-and-directions {
					margin-bottom: 0;
				}
			}

			.appointments {
				.appointments-inner {
					align-content: center;
					height: auto;
				}

				.date-range-selection,
				.appointment-selection,
				.legend {
					display: none;
				}

				.appointment-request-container {
					padding: 0;
					width: 100%;

					svg {
						flex: 0 0 2.25rem;
						height: 2.25rem;
						width: 2.25rem;
					}

					.appointment-request-phone {
						@include text(zeta);
						padding: 0;
					}

					a {
						display: block;
					}
				}
			}

			.read-more {
				&.is-expanded {
					.read-more-expanded {
						display: flex;
						flex-wrap: wrap;
					}
				}
			}
		}

		&.no-online-scheduling,
		&.practice-members {
			.carousel-controls,
			.carousel-indicators {
				display: none;
			}
		}

		.carousel-control-prev,
		.carousel-control-next {
			background-color: $color-edw-blue;
			opacity: 1;
			padding: 1.5rem 0.5rem;
			position: relative;
			width: auto;

			.icon {
				fill: $color-white;
			}
		}

		.carousel-controls {
			align-items: center;
			margin-top: 1rem;
			padding-left: 0;
			padding-right: 0;
		}

		.carousel-indicators {
			display: flex;
			// top: 100%; // moved to XS for R1

			li {
				// moved to XS for R1
				background-color: $color-neutral-dark;
				border-radius: 50%;
				height: 12px;
				margin: 0 4px;
				width: 12px;

				&.active {
					pointer-events: none;
				}
			}
		}

		.view-other-locations {
			@include text(zeta, delta);
		}

		.locations-and-appointments-inner {
		}

		.locations {
			//@include make-col-ready();
			//@include make-col(5);
			margin-bottom: 0;
		}

		.location-details {
			display: flex;
			flex-flow: row;
			flex-wrap: wrap;

			.subsection {
				width: 50%;

				&.directions {
					order: 1;
				}

				&.contact-info {
					order: 3;
				}

				&.office-hours {
					order: 2;
				}
			}
		}

		.office-hours-list {
			margin-bottom: 0;
		}

		.carousel-item {
			&.no-appointments,
			&.no-online-scheduling {
				.appointment-request-container {
					display: block;
				}
			}

			&.is-telemedicine {
				position: relative;

				.location-details {
					background-color: $color-neutral-lighter;
					height: 400px;

					.subsection-title {
						align-items: center;
						background: url('/assets/images/telemedicine.png');
						background-position-y: 0;
						background-repeat: no-repeat;
						background-size: 100%;
						display: flex;
						flex-direction: column;
						height: 400px;
						justify-content: flex-end;
						padding-bottom: 4rem;

						svg {
							height: 3rem;
							width: 3rem;
							margin-bottom: 1rem;
						}
					}
				}
			}
		}

		.legend {
			margin: 1.5rem auto 0;
			order: 2;
		}

		.appointments {
			// @include make-col-ready(); // moved to XS for R1
			@include make-col(7); // moved to XS for R1
			display: block;
		}

		.appointment-selection {
			margin-left: -1rem;
			margin-right: -1rem;
			width: calc(100% + 2rem);
		}

		.available-providers {
			display: block;
			padding-top: initial;
			padding-left: initial;
			padding-right: initial;
		}

		.provider-list {
			display: flex;
			flex-wrap: wrap;
			padding-left: 0;
			margin-bottom: 1rem;
			list-style: none;

			.provider-list-item {
				display: block;
				width: 50%;

				&.active {
					.provider-item {
						background-color: $color-highlight;
						pointer-events: none;
					}

					.cta-link {
						display: none;
					}
				}
			}

			.provider-item {
				background: transparent;
				border: none;
				display: flex;
				padding: 1rem 0.5rem;
				width: 100%;

				&:hover,
				&:focus {
					background-color: $color-neutral-lightest;
					outline: none;
					text-decoration: none;

					.cta-link {
						text-decoration: underline;
					}
				}

				svg {
					pointer-events: none;
				}
			}

			.profile-image {
				height: 90px;
				width: 90px;
				min-width: 90px;
			}

			.profile-content {
				@include text(zeta);
				color: $color-neutral-darkest;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding-left: 1rem;
				text-align: left;
				height: 85px;
			}

			.profile-content-main {
				flex-grow: 1;
			}

			.profile-name {
				@include text(zeta);
				color: $color-edw-blue;
				font-weight: 700;
				margin-bottom: 0.5rem;
			}

			.cta-link {
				color: $color-edw-blue;
				font-weight: 700;
			}
		}

		.locations-modal {
			.modal-dialog {
				max-width: 800px;
			}

			.modal-content {
				border: 0;
			}

			.modal-body {
				padding: 2rem;

				&:only-child {
					padding: 2rem;
				}
			}

			.modal-close-btn {
				background: transparent;
				border: 0;
				position: absolute;
				right: 1rem;
				top: 1rem;

				svg {
					height: 1.25rem;
					width: 1.25rem;
				}
			}

			.location-select-list {
				columns: 2;
				list-style: none;
				margin-bottom: 0;
				padding-left: 0;

				li {
					display: inline-block;
					padding: 0.5rem 0;
					break-inside: avoid-column;
				}
			}

			.location-name {
				font-weight: $font-weight-bold;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.locations {
			//@include make-col(4);
		}

		.appointments {
			@include make-col(8);
		}

		.radio-wrapper {
			display: inline-flex;
			margin-right: 1rem;
		}
	}

	@include media-breakpoint-up(xl) {
		.location-details {
			.subsection {
				width: auto;

				&.directions {
					order: 1;
					flex-grow: 1;
				}

				&.contact-info {
					order: 2;
					flex-grow: 2;
				}

				&.office-hours {
					order: 3;
					flex-grow: 2;
				}
			}
		}

		.legend {
			margin: 0;
			order: 0;
		}

		.provider-list {
			.provider-list-item {
				width: 33.33%;
			}
		}

		.appointments-summary {
			flex-flow: row;

			.location-address {
				margin-right: 2rem;
				flex: 2 2 0;
			}

			.btn {
				align-self: flex-start;
				margin-top: 1.25rem;
			}
		}
	}
}
