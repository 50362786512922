.global-navigation {
	font-family: $font-family-sans-serif;
	overflow: visible;
	position: relative;

	&:before {
		background-color: rgba($color-black, 0.6);
		content: '';
		display: block;
		height: 100%;
		left: 0;
		opacity: 0;
		pointer-events: none;
		position: fixed;
		top: 0;
		transition: opacity 0.4s ease-in-out;
		width: 100%;
		z-index: 5;
	}

	&.hamburger-nav-open {
		&:before {
			opacity: 1;
			pointer-events: auto;
		}

		.hamburger-nav {
			border-top: 4px solid #f5f5f6;
			padding: 3rem 1rem 1rem;
			transform: translateX(-100%);
		}

		.hamburger-nav > {
			.form-group {
				display: block;
				margin-bottom: 0;

				.icon {
					fill: $color-secondary-light-blue;
					height: 1.25rem;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-ms-flex-item-align: center;
					align-self: center;
					right: 0;
					width: 1.5rem;
					z-index: 1;
				}

				label {
					background-color: $color-white;
					box-shadow: none;
					margin-bottom: 0;

					border: 2px solid #cbced1;
					border-radius: 6rem;
					display: block;
					padding: 1rem 2rem;
					position: relative;
				}

				.form-control {
					background-color: transparent;
					color: $color-primary-dark-blue;
					display: block;
					&::placeholder {
						color: $color-primary-dark-blue;
					}
				}

				.search-form {
					border-color: $color-secondary-blue;
				}

				.global-search-typeahead-mobile {


					.search-form { //these values will need to override the current styles for the global search
						position: relative;
						background-color: $color-white;
						display: flex;

						input {
							font-weight: $font-weight-semi-bold;

							&::placeholder {
								color: $color-precision-gray;
							}

							&:focus {
								border-color: $color-secondary-teal;
							}
						}

						.submit-search {
							background: none;
							border: none;
							outline: 0;
							padding: 0;
							position: absolute;
							right: 2rem;
							top: 50%;
							transform: translateY(-50%);

							&:hover,
							&:focus {
								.icon-search {
									stroke: $color-secondary-light-blue;
								}
							}
						}
					}
				}
			}
		}

		label {
			display: block;
			//padding: 1rem 2rem;
			position: relative;
		}

		.icon-menu-close {
			display: block;
		}

		.icon-menu-open {
			display: none;
		}
	}

	.global-search-typeahead-mobile {
		.search-form { //these values will need to override the current styles for the global search
			position: relative;
			background-color: $color-white;
			display: flex;

			input {
				width: 100%;
				//background-color: $color-white;
				border: none;
				//border-radius: 6rem;
				//display: block;
				padding: 0;
				position: relative;

				&::placeholder {
					color: $color-precision-gray;
				}

				&:focus {
					border-color: $color-secondary-teal;
				}
			}

			.submit-search {
				background: none;
				border: none;
				outline: 0;
				padding: 0;
				position: absolute;
				right: 2rem;
				top: 50%;
				transform: translateY(-50%);

				&:hover,
				&:focus {
					.icon-search {
						stroke: $color-secondary-light-blue;
					}
				}
			}
		}
	}

	&.navbar-fixed {
		height: 90px;

		.main-nav {
			height: 90px;
			left: 0;
			position: fixed;
			top: 0;
			width: 100%;
			transform: translateY(-100%);
			pointer-events: none;
		}

		&.scroll-up {
			.main-nav {
				pointer-events: auto;
				transform: translateY(0);
				transition: transform 0.2s ease-in-out;
			}

			&.scroll-down {
				.main-nav {
					pointer-events: none;
					transform: translateY(-100%);
				}
			}
		}
	}

	.moments-theme & {
		&.navbar-fixed {
			height: 0;
		}

		.main-nav {
			background-color: transparent;
			border-bottom: 1px solid transparent;
			box-shadow: none;
			height: 70px;
			left: 0;
			position: absolute;
			top: 0;
			transition: background-color, border-bottom, box-shadow;
			transition-timing: 0.4s ease-in-out;
			width: 100%;
		}

		.moments-back {
			align-items: center;
			display: flex;
			height: 33px;
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.4s ease-in-out;
			width: 46px;

			svg {
				height: 22px;
				stroke: $color-white;
				width: 22px;
			}
		}

		.moments-brand {
			opacity: 0;
			pointer-events: none;
			transform: translateY(-3px);
			transition: opacity 0.4s ease-in-out;
		}

		.navbar-brand {
			display: none;
		}

		.icon-menu-open,
		.icon-menu-close {
			stroke: $color-white;
		}

		.hamburger-nav {
			height: calc(100% - 70px);
			opacity: 0;
			top: 70px;
			transition: transform 0.4s ease-in-out, opacity 0.35s ease-in-out;

			.primary-nav {
				.nav-links {
					.sub-nav-header {
						button {
							color: $color-moments-black;
						}
					}
				}
			}

			.secondary-nav {
				.nav-links {
					li {
						a {
							color: $color-moments-black;
						}
					}
				}
			}

			.icon-arrow-right,
			.icon-arrow-left {
				fill: $color-moments-black;
			}

			.image-links {
				.image-cta {
					.text-overlay {
						background-color: $color-moments-black;

						.icon-arrow-right {
							fill: $color-moments-black;
						}
					}
				}
			}
		}

		.global-search-trigger {
			background-color: $color-moments-black;
			border: none;
			border-top: 6px solid $color-microsite-yellow;
			padding: 1rem 2rem 1.25rem;

			svg {
				stroke: $color-white;
				top: calc(50% - 4px);
			}
		}

		&.hamburger-nav-open {
			.main-nav {
				background-color: $color-moments-black;
				border-bottom: 1px solid $color-moments-black;
				box-shadow: 0 4px 6px rgba($color-black, 0.06);
				transition-delay: 0;
			}

			.moments-back {
				opacity: 0;
				pointer-events: none;
			}

			.moments-brand {
				opacity: 1;
				pointer-events: auto;
			}

			.hamburger-nav {
				opacity: 1;
			}
		}
	}

	.moments-theme.moments-story & {
		&.navbar-fixed {
			height: 70px;
		}

		&.hamburger-nav-open {
			.moments-back {
				opacity: 0;
				pointer-events: none;
			}
		}

		.main-nav {
			background-color: $color-moments-black;
			position: relative;
		}

		.moments-back,
		.moments-brand {
			opacity: 1;
			pointer-events: auto;
		}

		.hamburger-nav {
			opacity: 1;
			transition: transform 0.4s ease-in-out;
		}
	}

	.icon-menu-close {
		display: none;
	}

	.utility-nav {
		display: none;
	}

	.global-search-typeahead {
		display: none;
		background-color: $color-neutral;
		position: fixed;
		width: 100vw;
		left: 0;
		top: 0;
		opacity: 0;
		pointer-events: none;
		height: 49px;
		transition: opacity 0.4s ease-in-out;
		z-index: 9;

		&.search-open {
			animation: global-search-typeahead-fade-in 0.4s;
			display: block;
			opacity: 1;
			pointer-events: auto;

			@keyframes global-search-typeahead-fade-in {
				from {
					opacity: 0;
				}
				to {
					opacity: 1;
				}
			}
		}

		&.search-closing {
			opacity: 0;
			pointer-events: none;
		}

		.search-grid {
			@include make-container();
			@include make-container-max-width();
			background-color: $color-neutral-light;
			position: relative;
		}

		.search-input-container {
			position: relative;
			width: calc(100vw - 70px);
			margin-left: -15px;
		}

		.search-overlay {
			display: block;
			position: fixed;
			top: 49px;
			left: 0;
			bottom: 0;
			right: 0;
			background-color: rgba($color-white, 0.8);
			transition: top 0.2s ease-in-out;
		}

		.search-overlay-close {
			@include text(zeta);
			background: none;
			border: 0;
			color: $color-gray-darkest;
			font-weight: $font-weight-semi-bold;
			margin: 0;
			outline: 0;
			padding: 0;
			position: absolute;
			right: 1rem;
			top: 50%;
			transform: translateY(-50%);

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}

		.search-form {
			input {
				@include text(epsilon);
				background-color: $color-neutral-lightest;
				border: 1px solid $color-primary-blue;
				color: $color-gray-darkest;
				font-weight: $font-weight-semi-bold;
				padding: 1rem 24px;
				height: 49px;
				transition: height 0.2s ease-in-out;

				&::placeholder {
					color: $color-gray-darkest;
				}

				&:focus {
					border-color: $color-primary-blue;
				}
			}

			.submit-search {
				min-height: 20px;
				right: 1rem;
			}
		}

		.search-dropdown {
			margin-top: 0;
			max-height: calc(100vh - 49px);
			overflow: auto;
			width: 100vw;
		}
	}

	.global-search-typeahead-mobile {
		.search-dropdown {
			margin-top: 2.5rem;
		}
	}

	.main-nav {
		align-items: center;
		background-color: $color-white;
		border-bottom: 1px solid $color-neutral-lightest;
		box-shadow: 0 4px 6px rgba($color-black, 0.06);
		display: flex;
		height: 90px;
		position: relative;
		z-index: 8;

		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.navbar-brand {
			height: 50px;
			padding-top: 0;
			padding-bottom: 0;

			img {
				height: 100%;
			}
		}

		.main-nav-links {
			display: none;
		}

		.hamburger-toggle {
			border: 0;
			outline: 0;

			.icon {
				height: 1.375rem;
				width: 1.375rem;
			}
		}
	}

	.fixed-search-btn {
		display: none;
	}

	.search-form {
		position: relative;
		background-color: $color-neutral-light;

		input {
			background-color: $color-primary-dark-blue;
			border: 1px solid $color-primary-dark-blue;
			color: $color-white;
			display: block;
			margin: 0;
			outline: 0;
			padding: 1rem 2rem;
			width: 100%;

			&::placeholder {
				color: $color-white;
			}

			&:focus {
				border-color: $color-secondary-teal;
			}
		}

		.submit-search {
			background: none;
			border: none;
			outline: 0;
			padding: 0;
			position: absolute;
			right: 2rem;
			top: 50%;
			transform: translateY(-50%);

			&:hover,
			&:focus {
				.icon-search {
					stroke: $color-secondary-light-blue;
				}
			}
		}
	}

	.global-search-trigger {
		@include text(epsilon);
		background-color: $color-neutral-lighter;
		border: 1px solid $color-gray-light;
		border-radius: 6rem;
		border-bottom-width: 2px;
		border-top-width: 2px;
		color: $color-gray-darkest;
		display: block;
		margin: 0;
		outline: 0;
		padding: 1rem 2rem;
		position: relative;
		text-align: left;
		width: 100%;

		&:hover,
		&:focus {
			border-color: $color-secondary-teal;
			cursor: text;
		}

		.icon-search {
			margin-top: -1px;
			position: absolute;
			right: 2rem;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.image-links {
		.image-cta {
			@include text(zeta);
			background-color: $color-gray-lightest;
			border-radius: 5px;
			display: block;
			height: 0;
			outline: 0;
			padding-bottom: 56.25%;
			position: relative;
			width: 100%;

			&:hover,
			&:focus {
				picture {
					&:after {
						opacity: 1;
					}
				}
				.text-overlay {

					.svg-container {
						background-color: transparent;
						transform: translateX(4px);
					}
				}
			}

			&:not(:last-of-type) {
				margin-bottom: 0.75rem;
			}

			picture {
				height: calc(100% - 33px);
				position: absolute;
				width: 100%;

				&:after {
					background-color: rgba($color-black, 0.2);
					content: '';
					display: block;
					height: 100%;
					left: 0;
					opacity: 0;
					position: absolute;
					top: 0;
					width: 100%;
					transition: opacity 0.2s ease-in-out;
				}

				img {
					height: 100%;
					object-fit: cover;
					object-position: 50% 50%;
					font-family: 'object-fit: cover; object-position: 50% 50%;';
					width: 100%;
				}
			}

			.text-overlay {
				align-items: center;
				background-color: $color-primary-yellow;
				border-radius: 0 0 5px 5px;
				color: $color-gray-darkest;
				display: flex;
				font-weight: $font-weight-bold;
				justify-content: space-between;
				padding: 0.5rem 1rem;
				position: absolute;
				bottom: 0;
				transition: background-color 0.2s ease-in-out;
				width: 100%;

				.svg-container {
					background-color: transparent;
					border-radius: 50%;
					display: flex;
					// padding: 0.25rem;
					transition: transform 0.2s ease-in-out;
				}

				svg {
					height: 1rem;
					fill: $color-gray-darkest;
					width: 1rem;
					transform: translateX(1px);
				}
			}
		}
	}

	.hamburger-nav {
		background-color: $color-white;
		padding: 3rem 1rem 1rem;
		position: fixed;
		top: 90px;
		left: 100%;
		height: calc(100% - 90px);
		overflow-y: auto;
		transition: transform 0.4s ease-in-out;
		width: 100vw;
		z-index: 7;

		.icon {
			fill: $color-secondary-light-blue;
			height: 1.25rem;
			display: -webkit-box;
			display: -ms-flexbox;
			display: inline-flex;
			-ms-flex-item-align: center;
			align-self: center;
			right: 0;
			width: 1.5rem;
			z-index: 1;
		}

		label {
			background-color: $color-white;
			box-shadow: none;
			margin-bottom: 0;

			border: 2px solid #cbced1;
			border-radius: 6rem;
			display: block;
			padding: 1rem 2rem;
			position: relative;
		}


		.global-search-typeahead-mobile {
			.search-form { //these values will need to override the current styles for the global search
				position: relative;
				background-color: $color-white;
				display: flex;

				input {
					font-weight: $font-weight-semi-bold;
					background-color: $color-white;

					&::placeholder {
						color: $color-precision-gray;
					}

					&:focus {
						border-color: $color-secondary-teal;
					}
				}

				.submit-search {
					background: none;
					border: none;
					outline: 0;
					padding: 0;
					position: absolute;
					right: 2rem;
					top: 50%;
					transform: translateY(-50%);

					&:hover,
					&:focus {
						.icon-search {
							stroke: $color-secondary-light-blue;
						}
					}
				}
			}
		}


		.icon-search {
			stroke: $color-gray-darkest;
		}

		.nav-links {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				@include text(epsilon);
				display: block;
				font-weight: $font-weight-semi-bold;

				a {
					// border-bottom: 2px solid $color-white;
					color: $color-gray-darkest;
					display: block;
					padding: 1rem;
					outline: 0;

					&:hover,
					&:focus {
						text-decoration: underline;
					}
				}

				.icon-arrow-right {
					pointer-events: none;
					position: absolute;
					top: calc(50% - 7px);
				}
			}
		}

		.primary-nav {
			height: auto;
			overflow: hidden;
			padding-top: 2rem;
			position: relative;
			transition: height 0.4s ease-in-out;

			.nav-links {
				li {
					&.has-sub-nav {
						&:before {
							background-color: $color-white;
							content: '';
							display: block;
							height: 100%;
							opacity: 0;
							pointer-events: none;
							position: absolute;
							top: 0;
							transition: opacity 0.4s ease-in-out;
							width: 100%;
							z-index: 1;
						}

						&.sub-nav-active {
							pointer-events: none;

							&:before {
								opacity: 1;
							}

							> .sub-nav {
								pointer-events: auto;
								transform: translateX(0);
							}
						}

						> a {
							position: relative;

							.icon-arrow-right {
								right: 2rem;
							}
						}
					}

					.sub-nav {
						background: $color-white;
						position: absolute;
						top: 0;
						left: 0;
						transform: translateX(100%);
						transition: transform 0.4s ease-in-out;
						width: 100%;
						z-index: 1;
					}
				}

				.sub-nav-header {
					button {
						@include text(epsilon);
						background: none;
						border: none;
						border-bottom: 2px solid #f8f8f8;
						color: $color-secondary-blue;
						display: flex;
						font-weight: $font-weight-semi-bold;
						justify-content: center;
						outline: 0;
						padding: 1rem 2rem;
						position: relative;
						width: 100%;

						&:hover,
						&:focus {
							cursor: pointer;
							text-decoration: underline;
						}

						.icon-arrow-right {
							left: 2rem;
							// TODO: Consider adding arrow-left and removing this transform rotate
							transform: rotate(180deg);
						}
					}
				}
			}
		}

		.secondary-nav {
			// background-color: #f8f8f8;

			.nav-links {
				padding: 1rem 0;

				li {
					font-weight: $font-weight-bold;
					padding-bottom: 1rem;

					a {
						// border-bottom: 0;
						@include text(zeta);
						@include button-variant(
							$color-primary-yellow,
							$color-primary-yellow,
							$color-white,
							$color-primary-yellow
						);
						font-weight: $font-weight-semi-bold;
						border-radius: 6rem;
						text-decoration: none;

						&:hover {
							text-decoration: none;
						}

						.icon {
							top: -0.125rem;
							position: relative;
							height: 1rem;
						}
					}
				}
			}
		}

		.tertiary-nav {
			.contact-phone {
				@include text(delta);
				color: $color-neutral-darkest;
				display: block;
				outline: 0;
				padding: 2rem 2rem 1rem;

				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}

			.nav-section-heading {
				@include text(zeta);
				color: $color-secondary-dark-teal;
				font-weight: $font-weight-normal;
				padding: 1rem 2rem 0;
			}

			.nav-links {
				column-count: 2;
				column-gap: 0.375rem;
				li {
					font-weight: $font-weight-bold;

					a {
						// border-bottom: 0;
						@include text(zeta);
						@include button-variant(
							$color-deep-green,
							$color-deep-green,
							$color-white,
							$color-deep-green
						);
						font-weight: $font-weight-semi-bold;
						border-radius: 6rem;
						padding: 0.75rem .5rem;
						text-decoration: none;

						&:hover,
						&:focus {
							color: $color-gray-darkest;
							text-decoration: none;

							svg {
								fill: $color-gray-darkest;
							}
						}

						svg {
							fill: $color-white;
							top: -0.125rem;
							position: relative;
							height: 1rem;
							width: 1rem;
							margin: 0 -8px 0 4px;
						}
					}
				}
			}

			.image-links {
				padding: 3rem 2rem 1.75rem;
			}

			.image-cta {
				.text-overlay {
					background-color: $color-gray-darkest;
					color: $color-white;

					.svg-container {
						background-color: transparent;
					}

					svg {
						fill: $color-white;
					}
				}

				&:hover,
				&:focus {
					.text-overlay {
						background-color: $color-neutral-dark;
						color: $color-white;

						.svg-container {
							background-color: $color-neutral-dark;
						}

						svg {
							fill: $color-white;
						}
					}
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		.hamburger-nav {
			max-width: 25rem;

			.tertiary-nav {
				.image-links {
					padding: 3rem 1rem 1.75rem;
				}
			}

			.global-search-typeahead-mobile {
				.search-dropdown {
					left: -33px;
					right: -30px;
					transform: none;
					width: auto;
					top: calc(100% + 1.5rem);
					margin-top: .5rem;
				}
			}
		}


	}

	@include media-breakpoint-up(lg) {
		&.navbar-fixed,
		.moments-theme &,
		.moments-theme.moments-story & {
			height: 141px;

			&.no-transition {
				.main-nav {
					.navbar-brand {
						transition: none;
					}

					.main-nav-links {
						> li {
							> a {
								transition: none;
							}
						}
					}

					.fixed-search-btn {
						animation: none;
					}
				}
			}

			.utility-nav {
				display: none;
			}

			.main-nav {
				height: 70px;
				left: 0;
				position: fixed;
				top: 0;
				width: 100%;
				transform: translateY(0);
				pointer-events: auto;
				transition: none;

				.container {
					height: 100%;
				}

				.navbar-brand {
					align-self: center;
					height: 50px;
					margin-bottom: 0;
					transition: height 0.2s ease-in-out,
						margin-bottom 0.2s ease-in-out;
					margin-right: auto;
				}

				.main-nav-links {
					padding-right: 40px;

					> li {
						> a {
							@include text(zeta);
							padding-bottom: 1.5rem;
							transition: padding-bottom 0.2s ease-in-out;

							&.btn-tertiary {
								top: -0.875rem;
							}
						}
					}
				}

				.fixed-search-btn {
					background-color: $color-gray-lightest;
					border: none;
					display: block;
					height: 100%;
					outline: 0;
					transition: background-color 0.2s ease-in-out;
					width: 50px;
					animation: fixed-search-btn-fade-in 0.2s;

					svg {
						transform: translateX(-1px);
						transition: stroke 0.2s ease-in-out;
					}

					&:hover,
					&:focus {
						background-color: rgba($color-gray-lightest, 0.85);

						svg {
							stroke: $color-gray-darkest;
						}
					}

					@keyframes fixed-search-btn-fade-in {
						from {
							opacity: 0;
						}
						to {
							opacity: 1;
						}
					}
				}
			}
		}

		.moments-theme &,
		.moments-theme.moments-story & {
			height: 70px;

			.main-nav {
				border-bottom: none;
				box-shadow: none;
			}

			.moments-back,
			.moments-brand {
				display: none;
			}

			.navbar-brand {
				display: block;
			}
		}

		.utility-nav {
			display: block;

			.container {
				position: relative;
			}

			.utility-nav-container {
				display: flex;
				position: absolute;
				top: 0;
				right: 15px;
				z-index: 9;
			}

			.global-search-trigger {
				@include text(eta);
				background-color: $color-gray-lightest;
				border-bottom-width: 1px;
				border-color: $color-gray-lightest;
				border-radius: 0;
				border-top-width: 1px;
				// color: $color-primary-blue;
				font-weight: $font-weight-semi-bold;
				height: 49px;
				padding: 1rem 24px;
				width: 150px;

				&:hover,
				&:focus {
					border-color: $color-secondary-light-blue;
				}

				.icon-search {
					margin-top: -2px;
					right: 1rem;
				}
			}
		}

		.utility-nav-links {
			background-color: $color-neutral-lighter;
			display: flex;
			list-style: none;
			margin: 0;
			padding: 1rem;

			li {
				@include text(eta);
				font-weight: $font-weight-semi-bold;

				&:not(:last-of-type) {
					padding-right: 1rem;
					margin-right: 1rem;
					// border-right: 1px solid $color-primary-blue;
				}

				a {
					color: $color-gray-darkest;
					outline: 0;

					&:hover,
					&:focus {
						text-decoration: underline;
					}
				}
			}
		}

		.global-search-typeahead, .global-search-typeahead-mobile {
			.search-input-container {
				position: relative;
				width: 55%;
				margin: auto;
			}

			.search-dropdown {
				border-top: none;
				margin-top: 0;
				width: 80%;
			}

			.search-overlay-close {
				right: 1.5rem;
			}

			.search-form {
				input {
					@include text(eta);
				}
			}
		}

		.main-nav {
			align-items: flex-end;
			border-bottom: none;
			box-shadow: none;
			height: 141px;

			&:before {
				content: '';
				background-color: $color-white;
				border-bottom: 1px solid $color-neutral-lightest;
				box-shadow: 0 4px 6px rgba($color-black, 0.06);
				display: block;
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}

			.container {
				position: relative;
			}

			.navbar-brand {
				height: 77px;
				width: 232px;
				margin-bottom: 24px;
				margin-right: 3rem;
				padding-top: 1rem
			}

			.main-nav-links {
				align-items: center;
				align-self: flex-end;
				display: flex;
				flex: 1;
				justify-content: space-between;
				list-style: none;
				margin: 0;
				padding: 0;

				> li {
					text-align: center;

					> a {
						@include text(epsilon);
						align-items: center;
						border-bottom: 7px solid transparent;
						color: $color-gray-darkest;
						display: flex;
						font-weight: $font-weight-semi-bold;
						padding: 0 0.5rem 34px;
						outline: 0;
						text-decoration: none;

						&:hover {
							border-bottom-color: $color-primary-yellow;
							color: $color-gray-darkest;
						}

						&:focus {
							color: $color-gray-darkest;
						}

						.icon-arrow-down {
							height: 0.75rem;
							margin-left: 0.75rem;
							pointer-events: none;
							width: 0.75rem;
						}

						&.btn-tertiary {
							@include button-variant(
								$color-deep-green,
								$color-deep-green,
								$color-white,
								$color-deep-green
							);
							border: 4px solid $color-deep-green;
							border-radius: 6rem;
							padding: 0.5rem 1rem;
							position: relative;
							top: -1.125rem;

							&:hover,
							&:focus {
								background-color: $color-white;
								border-color: $color-deep-green;
								color: $color-gray-darkest;

								& svg {
									stroke: $color-gray-darkest;
								}
							}

							& svg {
								margin-bottom: 1px;
							}
						}
					}
				}

				> li.has-sub-nav {
					> .sub-nav {
						display: none;
					}

					&.sub-nav-active {
						&.sub-nav-opening {
							> .sub-nav {
								animation: sub-nav-slide-down 0.4s;
								animation-timing-function: ease-in-out;
								z-index: -1;

								@keyframes sub-nav-slide-down {
									from {
										transform: translate(
											-50%,
											calc(-100% - 8px)
										);
									}
									to {
										transform: translate(-50%, 0);
									}
								}

								.sub-nav-container {
									box-shadow: none;
								}
							}
						}

						&.sub-nav-closing {
							> a {
								.icon-arrow-down {
									transform: rotate(0deg);
								}
							}

							> .sub-nav {
								animation: sub-nav-slide-up 0.4s;
								animation-timing-function: ease-in-out;
								transform: translate(-50%, calc(-100% - 8px));
								z-index: -1;

								@keyframes sub-nav-slide-up {
									from {
										transform: translate(-50%, 0);
									}
									to {
										transform: translate(
											-50%,
											calc(-100% - 24px)
										);
									}
								}

								.sub-nav-container {
									box-shadow: none;
								}
							}
						}

						> a {
							border-bottom-color: $color-primary-yellow;

							.icon-arrow-down {
								transform: rotate(180deg);
							}
						}

						> .sub-nav {
							background: $color-white;
							border: 1px solid $color-gray-darkest;
							border-top: 0;
							border-radius: 0 0 5px 5px;
							box-shadow: 0 10px 24px rgba($color-black, 0.12);
							display: block;
							padding: 0;
							position: absolute;
							top: 100%;
							transform: translateX(-50%);
							left: 50%;
							width: 100%;

							.sub-nav {
								margin-left: 2rem;
							}
						}

						.nav-links {
							list-style: none;
							margin: 0;
							padding: 0;

							li {
								padding: 6px 0;
								text-align: left;
							}
						}
					}
				}

				.sub-nav {
					text-align: left;

					ul {
						list-style-type: none;
						padding: 0;

						li {
							@include text(zeta);
							margin-bottom: 0.75rem;
							text-align: left;
							display: flex;

							a {
								@include text(zeta);
								color: $color-white;

								&:focus {
									outline: 0;
									text-decoration: underline;
								}
							}
						}
					}

					.heading-6 {
						color: $color-primary-yellow;
						margin-bottom: 0.75rem;
					}

					.sub-nav-container {
						@include make-row();
						background-color: $color-gray-darkest;
						box-shadow: inset 0 4px 6px rgba($color-black, 0.06);
						margin: 0;
						// padding: 1.75rem 1.75rem 1.25rem;
					}

					.sub-nav-primary {
						@include make-col(8);
						border-right: 1px solid $color-gray-darkest;
						padding: 1.75rem 2rem 1.75rem 2rem;
						// padding-right: 3rem;

						&.no-border {
							border-right: none;
							padding-right: 0;
						}
					}

					.sub-nav-secondary {
						@include make-col(4);
						background-color: $color-blue-gray;
						padding: 1.75rem 2.75rem 1.75rem 3rem;
					}

					.featured-items {
						margin-bottom: 2rem;

						&:first-child {
							margin-bottom: 2.5rem;
						}
					}

					.featured-links {
						columns: 3;
						column-gap: 3rem;
						line-height: 0;
					}

					.quick-links {
						.quick-links-header {
							align-items: center;
							display: flex;
							margin-bottom: 0.75rem;

							.heading-6 {
								margin-bottom: 0;
								margin-right: 0.75rem;
							}

							a {
								@include text(eta);
								color: $color-secondary-yellow;

								&:focus {
									outline: 0;
									text-decoration: underline;
								}
							}
						}

						.quick-links-container {
							display: flex;
							margin-bottom: 1rem;
						}

						.btn-icon {
							background-color: $color-neutral-dark;
							border-color: $color-neutral-dark;
							color: $color-white;
							margin-left: 0;
							padding-left: 2.25rem;
							padding-right: 1rem;
							position: relative;

							&:not(:last-of-type) {
								margin-right: 1rem;
							}

							&:hover {
								background-color: $color-neutral-lighter;
								border-color: $color-neutral-lighter;
								color: $color-gray-darkest;
							}

							&:focus {
								box-shadow: 0 0 0 0.2rem
									rgba($color-neutral-light, 0.5);
							}

							picture,
							svg {
								align-items: center;
								display: flex;
								height: 45px;
								justify-content: center;
								left: 0;
								position: absolute;
								top: 50%;
								transform: translate(-50%, -50%);
								width: 45px;
								stroke-width: 0;

								img {
									width: 100%;
								}
							}
						}

						.quick-links-note {
							@include text(theta);
							color: $color-white;
							font-style: italic;
							margin-bottom: 0;
						}
					}

					.section-list {
						li {
							margin-bottom: 0.5rem;
						}

						a {
							@include text(zeta);
							color: $color-white;
							background-color: $color-neutral-dark;
							border-radius: 5px;
							display: block;
							font-weight: $font-weight-semi-bold;
							padding: 1rem;
							width: 100%;
							text-align: center;
						}
					}

					.sub-nav-categories {
						display: flex;

						.sub-nav-category {
							flex: 1;
							margin-right: 3rem;
						}
					}

					.browse-all-link {
						@include text(zeta);
						align-items: center;
						background-color: $color-neutral-dark;
						color: $color-white;
						display: flex;
						font-weight: $font-weight-semi-bold;
						height: 50px;
						justify-content: center;
						text-align: center;
						width: 100%;

						&:focus {
							outline: 0;
							text-decoration: underline;
						}
					}
				}
			}

			.hamburger-menu {
				display: none;
			}
		}

		.hamburger-nav {
			opacity: 0;
			pointer-events: none;
		}
	}

	@include media-breakpoint-up(xl) {
		.main-nav {
			.main-nav-links {
				padding: 0 10px 0 200px;

				> li.has-sub-nav {
					&.sub-nav-active {
						> .sub-nav {
							width: 90%;
						}
					}
				}

				.sub-nav {
					.quick-links {
						.btn-icon {
							margin-left: 0.5rem;
							padding-left: 3rem;
						}
					}
				}
			}

			.navbar-brand {
				height: 65px;
				width: 200px;
				margin-bottom: 38px;
				padding-top: 0;
			}
		}
	}
}
