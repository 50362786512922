.classes-and-events {
	background-color: $color-neutral-lightest;
	margin-bottom: 2rem;
	padding: 2rem !important;

	.classes-and-events-subtitle {
		@include text(zeta);
		color: $color-neutral-darkest;
		font-weight: $font-weight-semi-bold;
		margin-bottom: 0.5rem;
	}

	.classes-and-events-title {
		@include text(delta);
		color: $color-primary-dark-blue;
		font-weight: $font-weight-bold;
		margin-bottom: 1.25rem;

		+ .classes-and-events-description {
			margin-top: -0.5rem;
		}
	}

	.classes-and-events-description {
		@include text(epsilon);
		color: $color-primary-dark-blue;
		font-weight: $font-weight-normal;
		margin-bottom: 1.25rem;
	}

	.classes-and-events-item {
		margin-bottom: 1.5rem;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.item-header {
		display: flex;
	}

	.svg-container {
		width: 35px;
		flex-basis: 35px;
		margin-right: 0.75rem;

		svg {
			fill: $color-neutral;
			height: 35px;
			width: 35px;
		}
	}

	.item-date-time {
		display: flex;
		flex-wrap: wrap;
	}

	.date,
	.time {
		@include text(epsilon);
		color: $color-primary-blue;
		font-weight: $font-weight-normal;
		position: relative;

		&:not(:last-of-type) {
			margin-right: 0.625rem;
			padding-right: 0.625rem;

			&:after {
				content: '|';
				display: inline-block;
				right: 0;
				padding-right: 0;
				position: absolute;
				transform: translate(50%, 0px);
			}
		}
	}

	.location {
		@include text(epsilon);
		color: $color-primary-blue;
		font-weight: $font-weight-bold;
	}

	.item-description {
		@include text(epsilon);
		color: $color-primary-dark-blue;
		font-weight: $font-weight-normal;
		margin: 0.5rem 0 0;
	}

	.view-all-link {
		@include text(epsilon);
		color: $color-primary-light-blue;
		display: inline-block;
		font-weight: $font-weight-bold;
		padding: 0;
	}

	@include media-breakpoint-up(md) {
		max-width: 410px;

		&.left {
			float: left;
			margin-right: 2rem;
		}

		&.right {
			float: right;
			margin-left: 2rem;
		}
	}
}
