/*
 *
 * TYPOGRAPHY VARIABLES AND MAPS
 *
 */

$font-family-sans-serif: "proxima-nova", sans-serif;

/* Moments Fonts */
$moments-sans-serif: 'Lato', sans-serif;
$moments-serif: 'IBM Plex Serif', serif;

/* Advance Fonts */
$advance-serif: 'Merriweather', serif;
$advance-sans-serif: 'Open Sans', sans-serif;

/* Resolution Fonts */
$resolution-sans-serif: 'proxima-nova', sans-serif;

/*
 * Utlizing Bootstrap font-weight variables. Adding custom semi-bold and extra-bold.
 * $font-weight-light:           300 !default;
 * $font-weight-normal:          400 !default;
 * $font-weight-bold:            700 !default;

 proxima-nova: Regular 400, Medium 500, Semibold 600, Bold 700, Extrabold 800
*/

$font-weight-semi-bold: 	600 !default; //600
$font-weight-extra-bold: 	800 !default; //800

/*
 * Font Size and Line Height Maps
 * Matching keys gets used together by default
 * Used in text() mixin and calulateLineHeight() function
 * FONT SIZE SCALE = Latin (+36px) and Greek (36-) alphabet keys
 * LINE HEIGHT SCALE = Latin (+40px) and Greek (40-) alphabet keys
 * Latin (majus) https://en.wikipedia.org/wiki/Latin_alphabet
 * Greek https://en.wikipedia.org/wiki/Greek_alphabet
 */
$font-size-scale: (
	ve: 48,
	ix: 44,
	igraeca: 40,
	omega: 36,
	alpha: 32,
	beta: 28,
	gamma: 24,
	delta: 20,
	epsilon: 18,
	zeta: 16,
	eta: 14,
	theta: 12,
	iota: 11,
	kappa: 10,
);

$line-height-scale: (
	ve: 52,
	ix: 48,
	igraeca: 44,
	omega: 40,
	alpha: 38,
	beta: 34,
	gamma: 30,
	delta: 24,
	epsilon: 20,
	zeta: 18,
	eta: 16,
	theta: 14,
	iota: 12,
	kappa: 10,
);

$box-shadow-primary: 0 8px 24px 0 rgba(0, 0, 0, 0.18);

$default-icon-color: $color-gray-darkest;

/*
 * Profile variables
 */
$profile-image-size: 130px;
$profile-image-border-width: 0;
$profile-card-border-width: 0.25rem;
$profile-image-offset: calc((#{$profile-image-size}) / -2);
$profile-card-offset: calc(
	(#{$profile-image-size} / 2) - #{$profile-card-border-width}
);

$service-categories: 'specialty', 'condition', 'treatment', 'other';

$alphabet: a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w,
	x, y, z;
