.microsite-theme,
.moments-theme {
	html & {
		.marketo-form {
			 background-color: $color-neutral-lighter;
			 padding: 1.5rem;

			.mktoForm {
				label,
				.mktoLabel {
					color: $color-microsite-gray;
				}

				.mktoEmailField,
				.mktoTelField,
				.mktoTextField,
				select,
				textarea {
					@extend .form-control;
					@include text(epsilon);
					background-color: $color-white;
					border: 1px solid $color-white;
					border-bottom-color: $color-gray-light;
					border-radius: 0;
					color: $color-microsite-gray;

					&:focus {
						background-color: $color-white;
						border-color: $color-gray-light;
						box-shadow: none;
					}

					&::placeholder {
						color: $color-microsite-gray-light;
					}
				}

				.mktoCheckboxList {
					@extend .checkbox-wrapper;

					label {
						@include text(zeta);
					}

					input[type='checkbox'] {
						&:focus {
							+ label {
								&:before {
									box-shadow: 0 0 0 0.2rem
										rgba($color-microsite-gray-light, 0.25);
								}
							}
						}

						+ label {
							padding: 0 0 0 2rem;
							position: relative;

							&:before {
								border-color: $color-microsite-gray-light;
								border-width: 1px;
							}

							&:after {
								border-color: $color-microsite-yellow;
							}
						}
					}
				}
			}
		}

		.two-column,
		.three-column {
			[class^='col'] {
				.marketo-form {
					padding: 1.5rem;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			.two-column {
				[class^='col'] {
					.marketo-form {
						padding-left: 3rem;
						padding-right: 3rem;
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.two-column {
				[class^='col'] {
					.marketo-form {
						padding-left: 5rem;
						padding-right: 5rem;
					}
				}
			}
		}
	}

	.field-validation-error {
		display: block;
		margin: -0.75rem 0 1rem;
	}
}

.moments-theme {
	html & {
		.marketo-form {
			 background-color: transparent;
			 padding: 0;
		}
	}
}
