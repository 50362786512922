.read-more {
	margin-bottom: 3rem;
	position: relative;

	&:not(.in-container) {
		@include make-container();
		@include make-container-max-width();
	}

	&.has-border {
		border: 0.75rem solid $color-neutral-light;
		padding: 1.5rem;

		.read-more-body {
			margin-bottom: 0;
		}

		.button-container {
			margin-top: 1.5rem;
		}

		.toggle-expand {
			bottom: -30px;
			/* this needs to change in accordance with button width */
			left: calc(50% - 69px);
			position: absolute;
		}
	}

	&.is-expanded {
		> .read-more-body {
			max-height: none;
		}

		> .read-more-condensed {
			display: none;
		}

		> .read-more-expanded {
			display: block;
		}
	}

	.read-more-body {
		margin-bottom: 2rem;
		max-height: 280px;
		overflow: hidden;
	}

	p {
		@include text(delta);
	}

	.read-more-expanded {
		display: none;
	}

	.read-more-link {
		@include text(zeta);
		bottom: -2rem;
		display: inline-block;
		font-weight: $font-weight-bold;
		left: 0;
		position: absolute;
	}

	.button-container {
		display: flex;
		justify-content: center;
	}

	.btn {
		&.toggle-expand {
			background-color: $color-primary-yellow;

			&:hover {
				background-color: $color-white;
			}

			.icon {
				height: 14px;
				fill: $color-white;
				width: 28px;
			}
		}
	}

	.read-more-link,
	.button-container .btn {
		&.is-expanded {
			> .expandToggleLabel {
				display: none;
			}

			> .collapseToggleLabel {
				display: inline;
			}

			> .icon {
				transform: rotate(180deg);
			}
		}

		> .collapseToggleLabel {
			display: none;
		}
	}

	@include media-breakpoint-up(md) {
		margin-bottom: 4rem;
	}
}
