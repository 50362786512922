.language-dropdown {
	.dropdown-toggle {
		@include text(zeta);
		align-items: center;
		background: none;
		border: none;
		color: $color-secondary-light-blue;
		display: flex;
		margin: 0 0.25rem 0 auto;
		outline: 0;
		padding: 0;

		&:hover,
		&:focus {
			text-decoration: underline;
		}

		&:after {
			display: none;
		}

		svg {
			fill: $color-secondary-light-blue;
			height: 1.25rem;
			margin-right: 0.375rem;
			width: 1.25rem;
		}
	}

	.dropdown-menu {
		border-color: $color-gray-lightest;
		margin-top: 0.25rem;
		max-width: calc(100vw - 30px);
		width: calc(100vw - 30px);
	}

	.dropdown-header {
		@include text(zeta);
		border-bottom: 1px solid $color-gray-lightest;
		color: $color-secondary-light-blue;
		font-weight: $font-weight-semi-bold;
		padding: 1rem;
		text-align: center;
	}

	.dropdown-item {
		@include text(epsilon);
		color: $color-neutral-darkest;
		padding: 0.625rem 1rem;

		&:hover {
			background-color: $color-neutral-lightest;
			color: $color-neutral-darkest;
		}

		&:first-of-type {
			margin-top: 0.75rem;
		}

		&:last-of-type {
			margin-bottom: 0.5rem;
		}

		.language-name {
			pointer-events: none;
		}
	}

	.in-language-example {
		color: $color-secondary-light-blue;
		pointer-events: none;
	}

	@include media-breakpoint-up(sm) {
		.dropdown-menu {
			width: 350px;
		}
	}

	@include media-breakpoint-up(md) {
		.dropdown-toggle {
			@include text(epsilon);
		}
	}
}
