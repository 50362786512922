.moments-footer {
	@include text(epsilon);
	background-color: $color-moments-black;
	color: $color-white;
	font-family: $moments-sans-serif;
	font-weight: $font-weight-bold;

	.footer-container {
		@include make-container();
		@include make-container-max-width();
		display: flex;
		flex-direction: column;
		padding: 3.5rem 4rem;
	}

	ul {
		color: $color-white;
		padding: 0;

		li {
			list-style-type: none;
		}
	}

	.footer-top {
		margin-bottom: 90px;

		li {
			margin-bottom: 1.375rem;
		}

		a {
			color: $color-white;
		}
	}

	.follow-label {
		margin-bottom: 1.25rem;
	}

	.follow-icons {
		display: flex;
		justify-content: space-between;
		margin-bottom: 0;
		width: 100%;

		li {
			margin-right: 0.5rem;

			&:last-of-type {
				margin-right: 0;
			}
		}

		a {
			display: block;
			max-width: 46px;

			&:hover,
			&:focus {
				.icon {
					fill: $color-microsite-yellow;
				}
			}
		}

		.icon {
			fill: $color-white;
			height: 46px;
			width: 100%;
			transition: fill 0.15s ease-in-out;
		}
	}

	@include media-breakpoint-up(sm) {
		.follow-icons {
			justify-content: flex-start;

			li {
				margin-right: 1.25rem;

				&:last-of-type {
					margin-right: 0;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		font-size: 15px;
		line-height: 2;

		.footer-container {
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
			padding: 4rem 15px;
		}

		.footer-top {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 0;
			padding-right: 3rem;

			li {
				margin-bottom: 0;
				position: relative;

				&:not(:last-of-type) {
					margin-right: 0.75rem;
					padding-right: 0.75rem;

					&:after {
						bottom: 0;
						content: '|';
						display: block;
						position: absolute;
						right: 0;
						transform: translateX(50%);
					}
				}
			}
		}

		.footer-bottom {
			align-items: center;
			display: flex;
		}

		.follow-label {
			margin-bottom: 0;
			margin-right: 1.25rem;
		}

		.follow-icons {
			.icon {
				height: 40px;
				width: 40px;
			}
		}
	}
}
