.gallery {
	@include make-container();
	@include make-container-max-width();

	.modal {
		padding-right: 0 !important;
	}

	.carousel {
		margin-bottom: 1rem;
		width: 100%;
	}

	.carousel-inner {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		width: calc(100% - 4rem);
	}

	.carousel-item {
		img,
		.video {
			margin-bottom: 2rem;
		}
	}

	.carousel-caption {
		color: $color-primary-dark-blue;
		position: relative;
		right: auto;
		bottom: auto;
		left: auto;
		margin-top: -2rem;
		padding-top: 0.5rem;
		padding-bottom: 0;
		text-align: left;
		height: auto;
		width: 75%;
		float: left;
		z-index: 1;

		.figure-caption {
			@include text(zeta);
			color: $color-gray-darkest;
			font-weight: $font-weight-semi-bold;
			max-height: 2.25rem;
			margin-bottom: 0.75rem;
			overflow: hidden;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.figure-credit {
			@include text(eta);
			color: $color-deep-green;
			height: 1.125rem;
			margin-bottom: 0;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.carousel-indicators {
		background-color: transparent;
		left: auto;
		flex-wrap: wrap;
		align-content: flex-start;
		justify-content: flex-end;
		height: auto;
		margin-right: 2rem;
		margin-bottom: 0;
		padding-top: 0.625rem;
		opacity: 1;
		width: 23%;
		z-index: 1;
		top: 100%;

		li {
			background-color: transparent;
			border: 2px solid $color-gray-darkest;
			border-top: 2px solid $color-gray-darkest;
			border-bottom: 2px solid $color-gray-darkest;
			border-radius: 1rem;
			height: 0.625rem;
			margin-bottom: 0.5rem;
			width: 0.625rem;
			opacity: 1;

			&.active {
				background-color: $color-gray-darkest;
			}
		}
	}

	.carousel-control-next,
	.carousel-control-prev {
		position: absolute;
		top: calc(50%);
		bottom: 0;
		z-index: 1;
		background-color: $color-gray-darkest;
		border-radius: 6rem;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 2rem;
		opacity: 1;
		padding: 0;
		text-align: center;
		transform: translateY(-50%);
		transition: opacity 0.15s ease;
		width: 2rem;
		&:hover,
		&:focus {
			background-color: $color-neutral-dark;
			color: $color-white;
			text-decoration: none;
			outline: 0;
			opacity: 1;
		}
		&:focus:not(:hover) {
			background-color: $color-gray-darkest;
		}
	}

	.carousel-control-next {
		right: -10px;
	}

	.carousel-control-prev {
		left: -10px;
	}

	.icon {
		fill: $color-white;
		stroke: $color-white;
	}

	.icon-arrow-left,
	.icon-arrow-right {
		display: inline-block;
		width: 10px;
		height: 20px;
		background: no-repeat 50%/100% 100%;
	}

	.icon-arrow-left {
		margin-right: 0.125rem;
	}

	.icon-arrow-right {
		margin-left: 0.125rem;
	}

	@include media-breakpoint-up(md) {
		.two-column & {
			.carousel {
				&.onethird,
				&.onehalf,
				&.twothird {
					max-width: 100%;
				}

				&.onethird,
				&.onehalf,
				&.twothird {
					&.left,
					&.right {
						float: none;
						margin-left: auto;
						margin-right: auto;
					}
				}
			}
		}

		.carousel {
			&.onethird {
				@include make-col(4);
			}
			&.onehalf {
				@include make-col(6);
			}
			&.twothird {
				@include make-col(8);
			}

			&.onethird,
			&.onehalf,
			&.twothird {
				&.left {
					float: left;
					margin-left: 0;
					margin-right: 2.5rem;
				}

				&.right {
					float: right;
					margin-left: 2.5rem;
					margin-right: 0;
				}

				&.center {
					margin-left: auto;
					margin-right: auto;
				}

				.carousel-indicators {
					margin-right: 2rem;
				}

				.carousel-inner {
					width: calc(100% - 4.5rem);
				}

				.carousel-control-next,
				.carousel-control-prev {
					top: calc(50% - 4.25rem);
					height: 2.5rem;
					width: 2.5rem;
				}

				.icon-arrow-left,
				.icon-arrow-right {
					width: 10px;
					height: 20px;
				}
			}

			.carousel-indicators {
				margin-right: 3rem;
			}

			.carousel-inner {
				width: calc(100% - 6rem);
			}

			.carousel-control-next,
			.carousel-control-prev {
				height: 3rem;
				width: 3rem;
			}

			.icon-arrow-left,
			.icon-arrow-right {
				width: 14px;
				height: 28px;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.two-column & {
			.carousel {
				&.onethird {
					@include make-col(6);
				}
				&.onehalf {
					@include make-col(6);
				}
				&.twothird {
					@include make-col(8);
				}

				&.onethird,
				&.onehalf,
				&.twothird {
					&.left {
						float: left;
						margin-left: 0;
						margin-right: 2.5rem;
					}

					&.right {
						float: right;
						margin-left: 2.5rem;
						margin-right: 0;
					}
				}
			}
		}
	}
	@include media-breakpoint-up(xl) {
		.two-column & {
			.carousel {
				&.onethird {
					@include make-col(4);
				}
			}
		}
	}
}
