.csv-table {
	@include make-container();
	@include make-container-max-width();

	.table-container {
		margin-bottom: 2rem;
	}

	.table {
		thead {
			tr {
				height: 72px;
			}
		}

		.csv-data {
			&.is-hidden {
				display: none;
			}
		}

		th {
			&:first-child {
				min-width: 50%;
				max-width: 75%;
				vertical-align: bottom;
			}

			&:nth-child(n + 2) {
				width: 25%;
				vertical-align: bottom;
			}
		}

		td {
			vertical-align: top;
			word-break: break-word;
		}
	}
}
