.childrens-theme {
	.btn {
		&.btn-primary,
		&:not([class*='btn-']) {
			@include button-variant(
				$color-childrens-primary,
				$color-childrens-primary,
				$color-childrens-secondary,
				$color-childrens-secondary
			);
		}

		&.btn-secondary {
			@include button-variant(
				$color-childrens-secondary,
				$color-childrens-secondary,
				$color-childrens-primary,
				$color-childrens-primary
			);
		}

		&.btn-tertiary {
			@include button-variant(
				$color-childrens-tertiary,
				$color-childrens-tertiary,
				$color-childrens-secondary,
				$color-childrens-secondary
			);

			color: $color-white;
		}
	}
}
