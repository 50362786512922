.three-up {
	@include make-container();
	@include make-container-max-width();

	.two-column-sidebar & {
		margin-bottom: 2rem;
		padding: 0;

		.card {
			border: 0;

			&:not(:last-of-type) {
				margin-bottom: 2rem;
			}
		}
	}

	.three-up-inner {
		background-color: $color-white;
		margin-bottom: 1.5rem;
		max-width: 100%;
		text-align: center;
		&.right {
			.three-up-content {
				margin-left: 1rem;
				text-align: right;
				right: 0;
			}

			.divider-syndicated {
				border-top: 0;
				margin-left: auto;
				margin-right: 0;
			}
		}
	}

	.heading-4 {
		@include text(epsilon);
		color: $color-neutral-dark;
		font-weight: $font-weight-bold;
		margin-bottom: 1rem;
	}

	p {
		@include text(zeta);
		color: $color-neutral-darkest;
		font-weight: $font-weight-semi-bold;
		margin-bottom: 0.75rem;
		& span {
			padding-right: 0.5rem;
			&:after {
				content: '/';
				padding-left: 0.5rem;
			}
			&:last-child:after {
				content: '';
				padding-left: 0.5rem;
			}
		}
	}

	.btn-primary {
		padding-left: 3rem;
		padding-right: 3rem;
		margin-left: auto;
		margin-right: auto;
	}

	.btn-link {
		@include text(eta);
		padding: 0;
		color: $color-secondary-dark-teal;
		font-weight: $font-weight-bold;
		text-transform: uppercase;

		&.video {
			position: relative;
			padding-right: 1.5rem;

			&:after {
				@include text(eta);
				content: '\25BA';
				padding-left: 0.5rem;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	.divider-syndicated {
		border-bottom: 2px solid #c5d6df;
		margin-bottom: 0.25rem;
		margin-left: 0;
		margin-right: auto;
		margin-top: 1rem;
		width: 4rem;
	}

	.card-deck {
		flex-direction: column;
	}

	.three-up-content {
		margin-bottom: 0;
		padding: 1.5rem 1rem 1.5rem 0;
		text-align: left;
		width: calc(100% - 1rem);
	}

	.three-up-footer {
		background-color: transparent;
		border-top: 0;
		margin-bottom: 1.5rem;
		padding: 0 1.25rem 0.75rem 0;
		text-align: left;
	}

	.three-up-img {
		position: relative;
		border-radius: 0;
		height: 300px;
		overflow: hidden;
	}

	.three-up-img img,
	.three-up-img source {
		object-fit: cover;
		object-position: 50% 50%;
		font-family: 'object-fit: cover; object-position: 50% 50%;';
		min-height: 300px;
		height: 100%;
		max-height: 400px;
		width: 100%;
	}

	.three-up-content-container {
		border: 0;
		flex: auto;
		min-height: 300px;
		height: auto;
		max-width: 100%;
		padding: 0;
		margin-bottom: 2rem;
	}

	.modal-content {
		background: transparent;
		border: none;

		.modal-body {
			padding: 0;
		}

		.media-item {
			width: 100%;
			padding: 0;

			.heading-4 {
				display: none;
			}

			.figure-text {
				background-color: $color-neutral-lightest;
				padding: 1rem 1.25rem;
			}
		}
	}

	@include media-breakpoint-up(sm) {
		.three-up-content {
			max-width: 425px;
			margin-right: auto;
			width: 100%;
		}
		.three-up-img {
			img,
			source {
				max-height: none;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.card-deck {
			flex-flow: wrap;
			justify-content: space-between;
			padding-left: 1rem;
			padding-right: 1rem;
		}

		.three-up-content-container {
			flex: 0 1 48.5%;
			max-width: 48.5%;
			padding: 0;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 1.5rem;
			&:nth-child(odd) {
				margin-left: 0;
			}
			&:nth-child(even) {
				margin-right: 0;
			}
			&:nth-child(odd):last-child {
				margin-left: auto;
				margin-right: auto;
			}
		}

		.three-up-content {
			margin-right: auto;
		}

		.three-up-img {
			img,
			source {
				min-height: 300px;
				max-height: 400px;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.three-up-content-container {
			flex: 0 1 32.333333%;
			max-width: 32.333333%;
			&:nth-child(3n + 1) {
				margin-left: 0;
				margin-right: auto;
			}
			&:nth-child(3n + 1) {
				margin-left: 0;
				margin-right: auto;
				&:last-child {
					margin-left: auto;
				}
				&:nth-last-child(2) {
					margin-left: auto;
					margin-right: 0.5rem;
				}
			}
			&:nth-child(3n + 2) {
				margin-left: auto;
				margin-right: auto;
				&:last-child,
				&:nth-child(odd):last-child {
					margin-left: 0.5rem;
					margin-right: auto;
				}
			}
			&:nth-child(3n + 3),
			&:nth-child(odd):last-child {
				margin-left: auto;
				margin-right: 0;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.three-up-img {
			img,
			source {
				min-height: 360px;
				max-height: 400px;
			}
		}
	}
}
