.bi-scheduling-detail {
	display: flex;
	flex-direction: column-reverse;
	padding-top: 2rem;
	margin-top: 0.5rem;
	margin-left: auto !important;
	margin-right: auto !important;
	margin-bottom: 3rem;

	&.success {
		.bi-scheduling-card {
			display: none;
		}
		.three-step-tracker {
			border-radius: 6px;
		}
	}

	.bi-scheduling-inner {
		@include make-container();
		@include make-container-max-width();
	}

	.bi-scheduling-card {
		// border: 1px solid $color-gray-light;
		border-radius: 0 0 6px 6px;
		// border-top: none;
	}

	.card-header,
	.card-footer {
		background-color: $color-white;
		border: none;
	}

	.card-header {
		padding: 1.5rem 1.5rem 0.5rem 1.5rem;
	}

	.card-footer {
		background-color: $color-gray-lightest;
		border-radius: 5px;
		padding: 0.5rem 1.5rem 1.5rem;
	}

	.profile-image {
		margin: 0 1rem 1rem 0;
		object-fit: cover;
		object-position: top;
		font-family: "object-fit: cover; object-position: top;";
		background-color: #fff;
		height: 128px;
		min-width: 128px;
		overflow: hidden;
		position: relative;
		width: 128px;
	}

	.doctor-details {
		padding-right: 1rem;

		span {
			font-weight: $font-weight-semi-bold;
		}
	}

	.doctor-name {
		@include text(delta);
		display: inline-block;
		font-weight: $font-weight-bold;
		margin-bottom: 0.5rem;
	}

	.location-address {
		@include text(zeta);
		margin-bottom: 1rem;

		&:empty {
			display: none;
		}
	}

	.distance-and-location,
	.appointment-request-phone {
		@include text(zeta);
		color: $color-secondary-light-blue;
		font-weight: $font-weight-semi-bold;
	}

	.three-step-tracker {
		margin-bottom: 0;
		border: 1px solid $color-gray-light;
		border-radius: 6px 6px 0 0;

		.step-title,
		.step-detail {
			margin-left: 1.5rem;
		}

		.step-title {
			@include text(delta);
			font-weight: $font-weight-bold;
		}

		.step-detail {
			@include text(zeta);
			font-weight: $font-weight-semi-bold;
		}

		.step-one,
		.step-two,
		.step-three {
			color: $color-primary-dark-blue;
			display: flex;
			align-items: center;
			padding: 1.25rem;

			.icon {
				display: flex;
				flex-shrink: 0;
				justify-content: center;
				align-items: center;
				width: 2.5rem;
				height: 2.5rem;
				background-color: $color-primary-dark-blue;
				border-radius: 9999px;
				stroke: $white;
			}

			&.active {
				color: $color-primary-light-blue;

				.icon {
					background-color: $color-primary-light-blue;
				}
			}

			&.next {
				color: $color-neutral-darkest;

				.icon {
					background-color: $white;
					border-width: 2px;
					border-color: $color-neutral-darkest;
				}
			}
		}

		.arrow {
			display: none;
		}
	}

	.specialty {
		@include text(zeta,epsilon);
	}



	@include media-breakpoint-up(md) {
		height: calc(100% - #{$profile-card-offset});
		justify-content: space-between;
	}

	@include media-breakpoint-up(lg) {
		.bi-scheduling-card {
			flex-direction: row;
		}

		.profile-image {
			margin: 0 1rem 0 0;
		}

		.card-header,
		.card-footer {
			display: flex;
			flex-direction: row;
			align-items: center;
			flex: 1 1 0%;
		}

		.card-header {
			padding: 1rem 0.5rem 1rem 1rem;
		}

		.card-footer {
			align-items: flex-start;
			padding: 1.5rem;
			justify-content: space-around;
			flex-direction: column;

			.location-address {
				margin-bottom: 0;
			}
		}

		.three-step-tracker {
			display: flex;
			margin-bottom: 0;

			li {
				flex: 1 1 0%;
			}

			.arrow {
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				height: 100%;
				width: 1.25rem;

				svg {
					color: $color-gray-light;
				}
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.bi-search-filters {
			.filters-form {
				.filter {
					flex: 0 0 calc(33.33% - 30px);
					margin-right: 40px;
				}
			}
		}
	}
}
