.audio-with-image {
	@include make-container();
	@include make-container-max-width();
	margin-bottom: 2rem;

	.audio-with-image-inner {
		.audio-image {
			margin-bottom: 1rem;
			text-align: center;

			img {
				max-width: 100%;
			}
		}

		.audio-player {
			display: block;
			padding-left: 0;
			padding-right: 0;

			.audio-text,
			.audio-element,
			audio {
				max-width: none;
				width: 100%;
			}

			.audio-element {
				padding-top: 0;
			}
		}

		audio::-webkit-media-controls-play-button {
			transform: scale(1.5, 1.5);
			left: -2px;
		}
	}

	@include media-breakpoint-up(lg) {
		margin-bottom: 2.5rem;

		.audio-with-image-inner {
			display: flex;
			justify-content: space-between;

			.audio-image {
				flex-basis: 205px;
				margin-bottom: 1rem;
				max-width: 205px;
				max-height: 100%;
				margin-bottom: 0;
				position: relative;
				width: 205px;

				img,
				source {
					height: auto;
					object-fit: cover;
					object-position: 50% 50%;
					font-family: 'object-fit: cover; object-position: 50% 50%;';
					width: 100%;
				}
			}

			.audio-player {
				flex-basis: calc(100% - 2rem - 205px);
				max-width: calc(100% - 2rem - 205px);
				margin-left: unset;
				margin-right: unset;

				.audio-element {
					padding-top: 1rem;
				}
			}
		}
	}
}
