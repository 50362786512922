.app-download {
	margin-bottom: 2rem;

	&.has-feature-list {
		.icon-list-image-container {
			display: flex;
			flex-direction: column-reverse;
			margin-top: 2rem;

			.image-container {
				text-align: center;

				img {
					max-width: 350px;
					width: 100%;
				}
			}
		}

		.list-download-container {
			display: flex;
			flex-direction: column-reverse;
		}

		.icon-list {
			list-style-type: none;
			margin: 3rem 0 2rem;
			padding: 0;
		}

		.icon-list-item {
			display: flex;

			&:not(:last-of-type) {
				margin-bottom: 2rem;
			}
		}

		.list-item-title {
			@include text(epsilon);
			color: $color-primary-dark-blue;
			font-weight: $font-weight-semi-bold;
			margin-bottom: 0.25rem;
		}

		.list-icon-container {
			flex-basis: 50px;
			min-width: 50px;
			margin-right: 1rem;

			.list-icon {
				width: 100%;
			}
		}

		.app-image {
			display: none;
		}
	}

	.app-description {
		width: 100%;
	}

	.list-download-container {
		width: 100%;
	}

	.app-download-inner {
		background-color: $color-neutral-lightest;
		padding: 2.5rem 2rem 0;
		border-radius: 5px;
	}

	.app-logo {
		display: none;
	}

	.learn-more {
		color: $color-secondary-light-blue;
		font-weight: $font-weight-semi-bold;
	}

	.browser-experience {
		margin: 1rem 0;
		text-align: center;

		a {
			color: $color-neutral-darkest;
			font-weight: $font-weight-semi-bold;
		}
	}

	.download-btns {
		display: flex;
		margin-top: 2rem;

		a {
			img {
				height: auto;
				max-height: 110px;
				max-width: 100%;
			}

			&:not(:last-of-type) {
				margin-right: 1rem;
			}
		}
	}

	.disclaimer {
		@include text(theta);
		font-style: italic;
		margin-top: 0.5rem;
	}

	.app-image {
		text-align: center;

		img,
		source {
			max-width: 350px;
			width: 100%;
		}
	}

	@include media-breakpoint-up(md) {
		@include make-container();
		@include make-container-max-width();

		&.has-feature-list {
			.app-logo {
				margin: 0 0 2rem;
				width: 215px;

				img {
					width: 100%;
					height: auto;
				}
			}

			.app-description {
				padding: 2.5rem 3rem;
			}

			.icon-list-image-container {
				display: flex;
				flex-direction: row;
				margin-top: 2rem;
			}

			.list-download-container {
				display: block;
			}

			.icon-list {
				margin-top: 0;
				margin-right: 2rem;
			}

			.download-btns {
				flex-direction: row;

				a {
					max-height: 64px;
					width: auto;

					img {
						max-height: 64px;
						width: auto;
					}

					&:not(:last-of-type) {
						margin-bottom: 0;
						margin-right: 2rem;
					}
				}
			}

			.image-container {
				align-items: center;
				flex-basis: 315px;
				margin-left: 2rem;
				margin-right: 1rem;
				min-width: 315px;
			}
		}

		.app-download-inner {
			display: flex;
			padding: 0;
		}

		.app-logo {
			display: block;
			flex-basis: 130px;
			min-width: 130px;
			margin: 3.25rem 3rem 1.5rem 3rem;

			img,
			source {
				width: 130px;
			}
		}

		.app-description {
			padding: 3.25rem 0 1.5rem;
		}

		.download-btns {
			flex-direction: column;

			a {
				height: auto;
				max-width: 215px;

				img {
					height: auto;
					width: 100%;
				}

				&:not(:last-of-type) {
					margin-bottom: 1.5rem;
					margin-right: 0;
				}
			}
		}

		.browser-experience {
			margin-bottom: 0;
			text-align: left;
		}

		.app-image {
			align-items: flex-end;
			display: flex;
			flex-basis: 300px;
			margin-left: 2rem;
			margin-right: 1rem;
			min-width: 300px;

			&.center {
				align-items: center;
			}

			img,
			source {
				width: 315px;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		&.has-feature-list {
			.icon-list-image-container {
				.image-container {
					display: none;
				}
			}

			.app-image {
				display: flex;
			}
		}

		.app-logo {
			display: block;
			flex-basis: 160px;
			min-width: 160px;
			margin-right: 4rem;

			img,
			source {
				width: 160px;
			}
		}

		.download-btns {
			flex-direction: row;

			a {
				max-height: 64px;

				img {
					max-height: 64px;
					width: auto;
				}

				&:not(:last-of-type) {
					margin-bottom: 0;
					margin-right: 2rem;
				}
			}
		}

		.app-image {
			flex-basis: 315px;
			min-width: 315px;
			margin-left: 3rem;
		}
	}
}
