.advance {
	main {
		color: $color-advance-dark-bluegrey;
		font-family: $advance-sans-serif;
		font-weight: 400;
	}

	.blue {
		color: $color-advance-mid-blue;
	}

	/*TYPOGRAPHY */
	h1,
	.heading-1,
	.heading-1-sans {
		font-size: 24px;
		line-height: 34px;
		font-family: $advance-serif;
		font-weight: 500;
		color: $color-advance-dark-teal;
	}
	h2,
	.heading-2,
	.heading-2-sans {
		font-size: 20px;
		line-height: 27px;
		font-family: $advance-serif;
		font-weight: 600;
		color: $color-advance-dark-teal;
	}
	h3,
	.heading-3,
	.heading-3-sans {
		font-size: 18px;
		line-height: 28px;
		font-family: $advance-serif;
		font-weight: 600;
		color: $color-advance-dark-teal;
	}
	h4,
	.heading-4,
	.heading-4-sans {
		font-size: 16px;
		line-height: 26px;
		font-family: $advance-serif;
		font-weight: 600;
		color: $color-advance-dark-teal;
	}
	.heading-4-sans {
		line-height: 22px;
	}
	h5,
	.heading-5,
	.heading-5-sans {
		font-size: 14px;
		line-height: initial;
		font-family: $advance-serif;
		font-weight: 600;
		color: $color-advance-dark-teal;
	}
	.heading-1-sans,
	.heading-2-sans,
	.heading-3-sans,
	.heading-4-sans,
	.heading-5-sans {
		font-family: $advance-sans-serif;
		font-weight: 600;
	}

	.body-lg,
	.body-lg p {
		font-size: 18px;
		line-height: 28px;
		font-family: $advance-sans-serif;
		ul > li:before {
			margin-left: -1.4rem;
		}
	}
	.body-md,
	.body-md p {
		font-size: 16px;
		line-height: 26px;
		font-family: $advance-sans-serif;
	}
	.body-sm,
	.body-sm p {
		font-size: 14px;
		line-height: initial;
		font-family: $advance-sans-serif;
	}

	.body-md,
	.body-lg {
		h1,
		h2,
		h3,
		h4,
		h5 {
			margin-bottom: 0.55em;
		}
	}

	label,
	.label {
		font-size: 12px;
		line-height: initial;
		font-family: $advance-sans-serif;
		font-weight: 600;
	}
	p {
		font-size: 1.2rem;
		font-family: $advance-sans-serif;
	}

	.marketing p {
		@include text(delta);
	}

	.copyright p {
		@include text(theta);
	}

	@include media-breakpoint-up(lg) {
		h1,
		.heading-1,
		.heading-1-sans {
			font-size: 36px;
			line-height: 48px;
		}
		h2,
		.heading-2,
		.heading-2-sans {
			font-size: 24px;
			line-height: 34px;
		}
		.heading-2-sans {
			line-height: auto;
		}
	}
}
