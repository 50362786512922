.cta-cards {
	@include make-container();
	@include make-container-max-width();

	.cta-cards-inner {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 2rem;
	}

	.col {
		flex-basis: 100%;
		padding: 0;
		margin-bottom: 1.5rem;
	}

	.card {
		// border-width: 0.75rem;
		background-color: $color-neutral-lighter;
		border: 0;
		border-radius: 5px;
	}

	.card-footer {
		background-color: transparent;
		border-top: none;
	}

	.card-text {
		@include text(epsilon);
		color: $color-gray-darkest;
	}

	.two-column & {
		padding: 0;
	}

	@include media-breakpoint-up(md) {
		.cta-cards-inner {
			justify-content: space-between;
			flex-wrap: wrap;

			.col {
				flex-basis: calc((100% / 2) - 15px);
				max-width: calc((100% / 2) - 15px);
				display: flex;
				flex-direction: column;

				.card {
					height: 100%;
				}

				.card-text {
					display: block;
				}
			}
		}

		.two-column & {
			.col {
				flex-basis: 100%;
				max-width: 100%;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.cta-cards-inner {
			&:after {
				content: ' ';
				flex: calc((100% / 3) - 20px);
				max-width: calc((100% / 3) - 20px);
				width: 100%;
			}

			.col {
				flex: calc((100% / 3) - 20px);
				max-width: calc((100% / 3) - 20px);

				&:first-child:nth-last-child(2),
				&:first-child:nth-last-child(2) ~ .col {
					flex-basis: calc((100% / 2) - 15px);
					max-width: calc((100% / 2) - 15px);
				}
			}
		}

		.two-column & {
			.col {
				flex-basis: calc((100% / 2) - 15px);
				max-width: calc((100% / 2) - 15px);
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.two-column & {
			.col {
				flex: calc((100% / 3) - 20px);
				max-width: calc((100% / 3) - 20px);
			}
		}
	}
}
