.hero-article {
	@include make-container();
	@include make-container-max-width();
	margin-bottom: 2rem;

	.divider {
		width: 15.375rem;
	}

	.hero-outer {
		overflow: hidden;
		position: relative;
		background-color: $color-gray-darkest;
	}

	.hero-img {
		display: block;
		background-color: $color-gray-darkest;

		img,
		source {
			object-fit: cover;
			object-position: 50% 50%;
			width: 100%;
			border-radius: 0;
			height: 25rem;
			clip-path: circle(700px at 50% -19rem);
		}
	}

	.hero-content-container {
		@include make-container();
		@include make-container-max-width();
		position: relative;
		padding: 0 2rem;
		background-color: $color-gray-darkest;
	}

	.hero-content {
		flex: auto;
		max-width: 100%;
		display: block;
		padding: 1rem 0 2rem;
		text-align: left;

		a.btn-primary {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			margin-right: auto;
			padding: 0.75rem 1.375rem;

			svg {
				margin: 0 5px 0 0px;
			}

			&:hover,
			&:focus {
				color: $color-primary-yellow;
				background-color: $color-gray-darkest;
				text-decoration: none;

				.icon.icon-arrow-long-left, .icon.icon-arrow-left {
					fill: $color-primary-yellow;
				}
			}
		}
	}

	.hero-category {
		@include text(beta);
		color: $color-white;
		font-weight: $font-weight-semi-bold;
	}

	.hero-title {
		@include text(beta);
		color: $color-white;
		font-weight: $font-weight-semi-bold;
	}

	.hero-description {
		@include text(zeta);
		display: block;
		color: $color-white;
		font-weight: $font-weight-normal;
		margin: 0;
		padding: .5rem 0 1.5rem;
		line-height: 1.5rem;
	}

	.divider-hero {
		border-bottom: 2px solid $color-neutral-light;
		margin: 0.75rem auto 0.5rem 0;
		width: 4rem;
	}

	.read-more-link {
		@include text(eta);
		padding: 0;
		color: $color-secondary-dark-teal;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
	}

	@include media-breakpoint-up(sm) {
		.hero-img {
			img,
			source {
				height: 30rem;
				clip-path: circle(800px at 50% -21rem);
			}
		}
	}

	@include media-breakpoint-up(md) {
		padding-top: 1rem;

		.hero-outer {
			height: 475px;
		}

		.hero-img {
			height: 100%;
			position: absolute;

			img,
			source {
				height: 100%;
				object-fit: cover;
				object-position: 50% 50%;
				font-family: 'object-fit: cover; object-position: 50% 50%;';
				width: 100%;
				clip-path: none;
			}
		}

		.hero-content-container {
			display: flex;
			justify-content: flex-start;
			bottom: 0;
			height: 100%;
			padding-left: 1rem;
			padding-right: 1rem;
			margin-bottom: 0;
			position: absolute;
			clip-path: circle(66% at center right);
		}

		.hero-content {
			flex: 0 0 45%;
			max-width: 45%;
			flex-direction: column;
			align-self: center;
			text-align: right;
			margin-left: auto;
			padding: 5rem 0 2rem;

			object-position: 50% 50%;
			height: 25rem;
			clip-path: circle(800px at 50% -19rem);
		}

		.hero-title {
			@include text(ve);
			color: $color-white;
			font-weight: $font-weight-semi-bold;
		}

		.divider {
			margin: 1rem 0 1rem auto;
		}

		.divider-hero {
			border-bottom: 2px solid $color-neutral-light;
			margin: 1.25rem auto 0.5rem 0;
			width: 4rem;
		}
	}

	@include media-breakpoint-up(lg) {
		.hero-content-container {
			@include make-col(6);
		}
	}

	@include media-breakpoint-up(xl) {
		.hero-content-container {
			@include make-col(6);
			background-position: 35rem 50%;
		}
	}
}
