.global-search {
	@include make-container();
	@include make-container-max-width();
	margin-top: 1.5rem;
	min-height: 720px;

	.healthplan-theme & {
		.search-category-nav,
		.search-secondary {
			display: none;
		}
	}

	.global-search-wrapper {
		&.loading {
			.results-count,
			.pagination-controls {
				opacity: 0;
			}

			.loading-overlay {
				opacity: 1;
			}
		}

		&.has-category {
			.featured-results-mobile {
				display: none;
			}
		}
	}

	.no-results-message {
		margin-top: 2rem;

		.no-results-image {
			margin-left: auto;
			margin-right: auto;
			padding: 2.5rem;
		}

		.card-body {
			padding: 1.25rem;
			margin-left: auto;
			margin-right: auto;
		}

		h2 {
			@include text(beta);
			margin-bottom: 1.25rem;
		}

		p {
			@include text(epsilon);
			margin-bottom: 0.5rem;
		}

		ul {
			margin-bottom: 0;

			li {
				@include text(epsilon);
			}
		}
	}

	.search-input-wrapper {
		margin-bottom: 1.5rem;

		.icon-close {
			fill: $color-gray-darkest;
			stroke: $color-gray-darkest;
		}
	}

	.form-control {
		border-bottom-color: $color-gray-light;
		background-clip: unset;
	}

	.search-nav-links {
		align-items: center;
		align-self: flex-end;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		list-style: none;
		padding: 0;

		> li {
			text-align: center;
			margin-bottom: 0.625rem;
		}

		a,
		button {
			@include text(epsilon, delta);
			align-items: center;
			background-color: transparent;
			border: none;
			border-bottom: 4px solid transparent;
			color: $color-gray-darkest;
			display: flex;
			font-weight: $font-weight-semi-bold;
			padding: 0 0.5rem 0.25rem;
			outline: 0;
			text-decoration: none;

			&.active {
				border-bottom-color: $color-primary-yellow;
				color: $color-gray-darkest;
				margin-bottom: 0.5rem;

				&:hover {
					color: $color-gray-darkest;
				}
			}

			&:focus {
				color: $color-gray-darkest;
			}

			&:hover {
				color: $color-primary-blue;
			}

			&.looking-for {
				color: $color-gray-light;
				font-style: italic;
			}
		}
	}

	.featured-results {
		display: none;
	}

	.featured-results-mobile {
		// border: 1px solid $color-gray-light;
		display: flex;
		margin-bottom: 1.5rem;
		flex-flow: column;

		.featured-category {
			padding: 0;
			margin-bottom: 1rem;
		}

		.view-all-link {
			@include text(zeta);
			font-weight: $font-weight-semi-bold;
		}
	}

	.featured-category {
		padding: 0.75rem 1rem;
		text-align: center;
		flex-basis: 50%;
		min-width: 50%;
	}

	.search-results-wrapper {
		min-height: 500px;
		position: relative;
	}

	.results-count {
		@include text(epsilon);
		color: $color-secondary-blue;
		margin-bottom: 1rem;
		transition: opacity 0.4s ease-in-out;

		.current-query {
			color: $color-primary-blue;
			font-weight: $font-weight-bold;
		}
	}

	.results-sort {
		display: none;
	}

	.results-list {
		list-style: none;
		margin: 1rem 0;
		padding: 0;

		.results-list-item {
			padding-bottom: 1rem;

			.result-link {
				@include text(delta);
				color: $color-primary-dark-blue;
				display: inline-block;
				font-weight: $font-weight-bold;
				margin-bottom: 0.625rem;

				.query-keyword {
					font-weight: $font-weight-bold;
					color: #3579ad;
				}
			}

			.icon-pdf {
				padding-left: 5px;
				margin-bottom: 5px;
			}

			.result-summary {
				@include text(zeta, epsilon);
				color: $color-neutral-darkest;

				.query-keyword {
					/*background-color: $color-highlight;*/
					font-weight: $font-weight-bold;
					/*padding: 0 0.25rem;*/
				}
			}
		}
	}

	.search-secondary {
		display: none;
	}

	@include media-breakpoint-up(sm) {

		.featured-results-mobile {
			flex-flow: row;

			.featured-category {
				margin-bottom: auto;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.no-results-message {
			display: flex;
			flex-direction: row;

			.card-body {
				padding: 3rem 2.5rem;
			}

			h2 {
				@include text(alpha);
			}

			p {
				@include text(delta);
			}

			ul {
				li {
					@include text(delta);
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		margin-top: 3rem;

		.global-search-wrapper {
			&.has-category {
				.featured-results,
				.search-secondary {
					display: none;
				}

				.search-primary {
					padding-right: 0;
				}
			}
		}

		.search-field {
			margin: 2rem auto 1rem;
			width: 50%;
		}

		.search-input-wrapper {
			margin-bottom: 3rem;
		}

		.search-category-nav {
			text-align: center;
		}

		.search-nav-links {
			display: inline-block;
			flex-direction: row;
			margin: 0 auto 2rem auto;

			> li {
				display: inline-block;
				margin-bottom: 0;
				padding-right: 1rem;

				&:last-child {
					padding-right: 0;
				}
			}

			a,
			button {
				padding-bottom: 0.375rem;
				font-weight: $font-weight-bold;

				&:hover,
				&.active {
					margin-bottom: 0;
				}

				&:hover {
					border-bottom-color: $color-primary-dark-blue;
					color: $color-primary-dark-blue;
					cursor: pointer;
				}
			}
		}

		.featured-results {
			display: flex;
			background-color: $color-white;
			justify-content: space-evenly;
			margin-bottom: 2rem;
			min-height: 195px;
			// padding: 2.25rem;
			position: relative;

			.current-network {
				@include text(eta);
				color: $color-neutral-dark;
				font-style: italic;
				position: absolute;
				bottom: 0.625rem;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		.featured-results-mobile {
			display: none;
		}

		.featured-category {
			display: flex;
			background-color: $color-neutral-lightest;
			flex-direction: column;
			padding: 2.25rem;
			text-align: left;

			&:first-child {
				// border-right: 1px solid $color-neutral-light;
				margin-right: 0.375rem;

				.looking-for {
					display: block;
					text-align: right;
				}

				+ .featured-category {
					margin-left: 0.375rem;
				}
			}

			.looking-for {
				color: $color-neutral-dark;
				display: flex;
				font-style: italic;
				font-weight: $font-weight-semi-bold;
				margin-top: auto;
				margin-bottom: auto;
			}
		}

		.category-label {
			@include text(epsilon);
			color: $color-gray-darkest;
			font-weight: $font-weight-bold;
			margin-bottom: 1rem;

			&::after {
				background-color: $color-primary-yellow;
				display: block;
				position: relative;
				content: "";
				height: 4px;
				margin: 0.75rem auto 0.75rem 0;
				width: 2.5rem;
			}
		}

		.featured-list {
			display: flex;
			list-style: none;
			margin: 0 0 1rem 0;
			padding: 0;
		}

		.featured-item {
			display: none;
			width: 50%;

			&:not(:last-of-type) {
				margin-right: 1rem;
			}
			/* remove this once we get the correct number of items from the back end */
			&:nth-child(-n + 2) {
				display: flex;
			}
		}

		.featured-image {
			border-radius: 50%;
			display: block;
			height: 62px;
			min-width: 62px;
			overflow: hidden;
			width: 62px;

			&[href] {
				&:hover,
				&:focus {
					box-shadow: 0 8px 24px 0 rgba($color-black, 0.075);
					text-decoration: none;

					+ .featured-content {
						.name {
							text-decoration: underline;
						}
					}
				}
			}

			img {
				height: 100%;
				object-fit: cover;
				object-position: top;
				width: 100%;
			}

			svg {
				height: 100%;
				width: 100%;
			}
		}

		.featured-content {
			padding-left: 1rem;

			.name {
				@include text(zeta);
				display: inline-block;
				color: $color-gray-darkest;
				font-weight: $font-weight-bold;
				margin-bottom: 0.25rem;
			}

			.description {
				@include text(zeta);

				address {
					margin-top: 0.25rem;
				}
			}
		}

		.view-all-link {
			@include text(zeta);
			display: inline-block;
			font-weight: $font-weight-semi-bold;
			margin-top: auto;
		}

		.search-results-wrapper {
			display: flex;
			justify-content: center;
			margin-bottom: 4rem;
		}

		.search-primary {
			flex-grow: 1;
			max-width: 765px;
			padding-right: 4rem;
		}

		.search-secondary {
			margin-left: auto;
			margin-top: 72px;
			width: 30%;

			.heading-5 {
				font-weight: $font-weight-bold;
				margin-bottom: 0.625rem;
			}

			.category-lists {
				margin-bottom: 3rem;
			}

			.category-list {
				list-style: none;
				margin-bottom: 0.75rem;
				padding: 0;

				li {
					padding-bottom: 0.25rem;

					&:last-child {
						padding-bottom: 0;
					}
				}

				a {
					@include text(zeta);
					display: inline-block;
					color: $color-gray-darkest;
					font-weight: $font-weight-normal;

					&:hover {
						color: $color-primary-blue;
						font-weight: $font-weight-semi-bold;
					}
				}
			}
		}

		.results-controls {
			display: flex;
			justify-content: space-between;
		}

		.results-list {
			margin-bottom: 1rem;
		}

		.search-secondary {
			display: block;
		}
	}

	@include media-breakpoint-up(xl) {
		.featured-results {
			// padding: 2.5rem 3.5rem;

			.current-network {
				bottom: 0.75rem;
			}
		}

		.featured-category {
			&:first-child {
				padding-right: 3rem;

				+ .featured-category {
					padding-left: 3rem;
				}
			}
		}

		.featured-item {
			&:not(:last-of-type) {
				margin-right: 2rem;
			}
		}

		.featured-content {
			.name {
				@include text(epsilon);
			}
		}
	}
}
