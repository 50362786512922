.advance {
	.advance--featured-specialties {
		padding-top: $padding-advance-section-mobile;
		padding-bottom: $padding-advance-section-mobile;
		a {
			&:hover {
				color: $color-advance-dark-teal;
				text-decoration: none;
			}
		}
		.card {
			padding: 33px 20px 34px;
			border: 10px solid #c5d6df;
			margin: 0 -30px;
			.featured-specialties--header {
				padding-bottom: 12px;
				justify-content: flex-start;
				flex-direction: column;
				.col:nth-child(2) {
					display: flex;
					flex-grow: unset;
					a {
						white-space: nowrap;
						text-decoration: underline;
						display: block;
						padding-top: 5px;
						color: $color-advance-mid-bluegrey;
						&:hover {
							color: $color-advance-dark-teal;
						}
					}
				}
			}
			.featured-specialties--content {
				& > div {
					padding-top: 27px;
					a {
						display: block;
						position: relative;
						padding-bottom: 16px;
						&::before {
							content: "";
							background: $color-advance-light-teal;
							height: 4px;
							width: 39px;
							position: absolute;
							bottom: 0;
							left: 0;
						}
					}
				}
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.advance--featured-specialties {
			padding-top: $padding-advance-section-desktop;
			padding-bottom: $padding-advance-section-desktop;
			.card {
				padding: 38px 85px 76px;
				border-width: 14px;
				margin: 0;
				.featured-specialties--header {
					padding-bottom: 10px;
					justify-content: space-between;
					flex-direction: row;
					.col:nth-child(2) {
						justify-content: flex-end;
						a {
							padding-top: 3px;
						}
					}
				}
				.featured-specialties--content {
					& > div {
						padding-top: 30px;
					}
				}
			}
		}
	}
}
