/*
 * Bootstrap variable overrides only
 * https://github.com/twbs/bootstrap/blob/master/scss/_variables.scss
 */

$border-radius: 0.3125rem;
$border-radius-lg: 0.3125rem;
$border-radius-sm: 0.3125rem;

$input-btn-padding-y: 0.75rem;
$input-btn-padding-x: 3.375rem;
$input-btn-font-size: 1rem;
$input-btn-line-height: 1.375;

$input-btn-padding-y-sm: 0.4375rem;
$input-btn-padding-x-sm: 3.375rem;
$input-btn-font-size-sm: 1rem;
$input-btn-line-height-sm: 1.375;

$input-btn-padding-y-lg: 0.875rem;
$input-btn-padding-x-lg: 3.375rem;
$input-btn-font-size-lg: 1rem;
$input-btn-line-height-lg: 1.375;

$btn-block-spacing-y: 0.8125rem;

/* Revert back to sensible padding-x for inputs */
$input-padding-x: 1rem;
$input-padding-x-sm: 1rem;
$input-padding-x-lg: 1rem;
$input-padding-y: 0.625rem;
$input-padding-y-sm: 0.625rem;
$input-padding-y-lg: 0.625rem;
$input-height: auto;

/*
 * Custom Range
 */
$custom-range-thumb-width: 1.5rem;
$custom-range-thumb-bg: $color-gray-darkest;
$custom-range-thumb-focus-box-shadow: 0 3px 6px rgba($color-black, 0.36);
$custom-range-thumb-active-bg: $color-gray-darkest;
$custom-range-track-height: 0.25rem;
$custom-range-track-bg: $color-gray-light;

/*
 * Pagination
 */
$pagination-line-height: 1;
$pagination-color: $color-primary-dark-blue;
$pagination-bg: $color-neutral-light;
$pagination-border-width: 0;
$pagination-active-bg: $color-secondary-light-blue;

/*
 * Overriding Bootstrap defaults of:
 * $container-max-widths: (
 *   sm: 540px,
 *   md: 720px,
 *   lg: 960px,
 *   xl: 1140px
 * ) !default;
 */
$container-max-widths: (
	sm: 1317px,
	md: 1318px,
	lg: 1319px,
	xl: 1320px,
) !default;

/*
 * Tables
 * Customizes the `.table` component with basic values, each used across all table variations.
 */

$table-accent-bg: $color-neutral-lightest;
$table-border-width: 0;
$table-color: $color-primary-blue;
$table-striped-order: even;
