.thank-you-message {
	align-items: center;
	background-color: $color-neutral-dark;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 6rem 2rem;
	position: relative;
	text-align: center;

	.thank-you-bubble {
		@include text(gamma, delta);
		border: 3px solid $color-primary-yellow;
		border-radius: 15px;
		color: $color-primary-yellow;
		font-weight: $font-weight-bold;
		margin-bottom: 2rem;
		padding: 0.625rem;
		position: relative;
		text-align: center;
		text-transform: uppercase;

		&:before {
			display: block;
			content: ' ';
			width: 0;
			height: 0;
			border-left: 13px solid transparent;
			border-right: 13px solid transparent;
			border-top: 13px solid $color-primary-yellow;
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
		}

		&:after {
			display: block;
			content: ' ';
			width: 0;
			height: 0;
			border-left: 9px solid transparent;
			border-right: 9px solid transparent;
			border-top: 9px solid $color-neutral-dark;
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	.thank-you-content {
		color: $color-white;

		.heading-3,
		.heading-4,
		.heading-5,
		h3,
		h4,
		h5 {
			color: $color-gray-lightest;
			font-weight: $font-weight-semi-bold;
		}

		.heading-3 {
			@include text(gamma);
		}

		.heading-4 {
			@include text(delta);
		}

		p {
			@include text(epsilon);
			font-weight: $font-weight-semi-bold;
			a {
				color: $color-primary-yellow;
			}
		}
	}

	@include media-breakpoint-up(md) {
		padding: 6rem 3rem;
	}
}
