.leftnav {
	margin-bottom: 2rem;

	&.callout {
		.link-parent,
		.link-adjacent {
			display: flex;
		}
	}

	.link-parent,
	.link-adjacent {
		border-radius: 5px;
		margin-bottom: 0.75rem;
		padding: 1rem;
		position: relative;
		text-align: left;
		width: 100%;

		&:active {
			background-color: $color-primary-blue !important;
			border-color: $color-primary-blue !important;
			color: $color-white !important;
			box-shadow: none !important;
		}
	}

	.link-parent {
		background-color: $color-gray-darkest;
		border-color: $color-gray-darkest;
		color: $color-white;
		padding-left: 2.5rem;

		&:hover,
		&:focus {
			background-color: $color-neutral-darkest;
			border-color: $color-neutral-darkest;
			color: $color-white;
			box-shadow: none;
		}
	}

	.link-adjacent {
		background-color: $color-neutral-darkest;
		border-color: $color-neutral-darkest;
		color: $color-white;
		padding-right: 2.5rem;

		&:hover,
		&:focus {
			background-color: $color-gray-darkest;
			border-color: $color-gray-darkest;
			color: $color-white;
			box-shadow: none;
		}
	}

	.accordion-link {
		@include text(epsilon);

		background-color: transparent;
		border: transparent;
		color: $color-neutral-darkest;
		display: inline-block;
		font-weight: $font-weight-normal;
		padding: 1rem 2.5rem 1rem 1rem;
		text-align: left;
		width: 100%;

		&:hover,
		&:focus {
			color: $color-sky-blue;
			outline: 0;
		}

		&.current {
			color: $color-deep-green;
			font-weight: $font-weight-semi-bold;
			pointer-events: none;
		}
	}

	.accordion-item-body {
		.accordion-link {
			@include text(zeta);
			padding: 0.5rem 2.5rem 0.5rem 1.5rem;
		}
	}

	.icon-arrow-left,
	.icon-arrow-right {
		align-self: center;
		fill: $color-white;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	.icon-arrow-left {
		left: 1rem;
	}

	.icon-arrow-right {
		right: 1rem;
	}

	.icon-arrow-down {
		width: 0.75rem;
	}

	.nav-accordion {
		background-color: $color-white;

		> .accordion-item {
			> .accordion-item-body {
				> .accordion-item-body-inner {
					> .accordion-item {
						margin: 0.5rem 0;
					}

					> .accordion-item-body-inner {
						padding: 0.375rem 0;
					}
				}
			}
		}

		.accordion-item {
			font-weight: $font-weight-normal;

			.link-adjacent {
				display: flex;
				margin-top: 0.75rem;
			}

			.accordion-item-title {
				.accordion-item-toggle {
					color: $color-gray-darkest;
					font-weight: $font-weight-normal;

					&:hover,
					&:focus {
						color: $color-primary-blue;

						.icon {
							fill: $color-primary-blue;
						}
					}

					.accordion-item-label {
						white-space: normal;
					}

					.icon {
						fill: $color-gray-darkest;
						transition: all 0.5s ease;
						transition-property: color, transform, margin;
					}
				}

				&.active {
					background-color: $color-white;

					.accordion-item-toggle {
						font-weight: $font-weight-normal;

						.icon {
							margin-top: -0.5rem;
							transform: rotate(180deg);
						}
					}

					~ .accordion-item-body {
						@include text(zeta);
						height: auto;
						margin-bottom: 1rem;

						.accordion-item-body-inner {
							@include text(zeta);
							height: auto;
							padding: 0;

							.accordion-item {
								border: 0;
							}

							.accordion-item > .accordion-item-title {
								.accordion-item-toggle {
									@include text(zeta);
									background-color: transparent;
									color: $color-neutral-darkest;
									font-weight: $font-weight-normal;
									padding: 0.5rem 2.5rem 0.5rem 1.5rem;

									&:hover,
									&:focus {
										.accordion-item-label {
											color: $color-primary-blue;
										}

										.icon {
											fill: $color-primary-blue;
										}
									}
								}

								&.active {
									.accordion-item-toggle {
										background-color: $color-primary-dark-blue;
										font-weight: $font-weight-semi-bold;

										&:hover,
										&:focus {
											.accordion-item-label {
												color: $color-white;
											}

											.icon {
												fill: $color-white;
											}
										}

										.accordion-item-label {
											color: $color-neutral-lighter;
										}

										.icon {
											fill: $color-neutral-lighter;
											margin-top: -0.5rem;
											transform: rotate(180deg);
										}
									}

									~ .accordion-item-body {
										background-color: $color-neutral-lighter;
										margin-bottom: auto;

										.accordion-item-body-inner {
											background-color: transparent;
											padding: 0.5rem 0;

											.accordion-link {
												padding: 0.5rem 2.5rem 0.5rem
													2.5rem;
											}

											.accordion-item
												> .accordion-item-title {
												.accordion-item-toggle {
													background-color: transparent;
													color: $color-neutral-darkest;
													padding: 0.5rem 2.5rem;

													&:hover,
													&:focus {
														.accordion-item-label {
															color: $color-primary-blue;
														}

														.icon {
															color: $color-primary-blue;
														}
													}

													.accordion-item-label {
														color: $color-primary-blue;
													}
												}

												&.active {
													.accordion-item-toggle {
														background-color: $color-neutral-lighter;
														font-weight: $font-weight-semi-bold;

														&:hover,
														&:focus {
															.icon {
																fill: $color-primary-blue;
															}
														}

														.icon {
															fill: $color-primary-blue;
															margin-top: -0.5rem;
															transform: rotate(
																180deg
															);
														}
													}

													~ .accordion-item-body {
														background-color: $color-neutral-lighter;

														.accordion-item-body-inner {
															background-color: transparent;
															padding: 0;

															.accordion-link {
																padding: 0.5rem
																	2.5rem
																	0.5rem
																	3.5rem;
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		.link-parent,
		.link-adjacent {
			display: flex;
		}

		.accordion-container {
			padding: 0;

			.nav-accordion {
				background-color: $color-white;

				.link-adjacent {
					display: none;
				}

				.accordion-item {
					display: block;
				}
			}
		}
	}
}
