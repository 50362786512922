.footer-healthplan {
	a[target='_blank'] {
		padding-right: 1.5rem;
		position: relative;

		&:after {
			background-image: url(/assets/images/external-white.png);
			background-size: cover;
			bottom: -3px;
			content: '';
			height: 28px;
			position: absolute;
			width: 28px;
		}
	}

	.footer-wrapper {
		background-color: $color-gray-darkest;
		padding: 2.5rem 0 2.5rem;
	}

	.footer-content {
		@include make-container();
		@include make-container-max-width();
	}

	.marketing {
		text-align: left;
	}

	.mh-logo-full {
		display: flex;
		flex-flow: row;
		margin-bottom: 2.5rem;
		justify-content: center;

		.logo-wrapper,
		img {
			width: 11.75rem;
		}

		img {
			border-radius: 0;
			border-right: solid 1px $color-primary-yellow;
			padding-right: 1rem;
		}

		.title-list {
			display: flex;
			flex-direction: column;
			list-style: none;
			padding: 0 0 0 1rem;

			li {
				@include text(theta,eta);
				color: $color-white;
				display: inline-block;
			}
		}
	}

	.link-list {
		list-style: none;
		margin: 0;
		padding: 0.625rem 0 0;
		text-align: center;

		ul {
			list-style: none;
			margin: 0.75rem 0;
			padding: 0;
		}

		li {
			padding-bottom: 0.75rem;
		}

		a {
			@include text(delta);
			color: $color-primary-yellow;
			display: inline-block;
			max-width: 245px;
		}

		> li {
			padding-bottom: 1.25rem;

			> a {
				font-weight: $font-weight-semi-bold;
			}

			> ul li a {
				color: $color-white;
				font-weight: $font-weight-normal;
			}
		}
	}

	.visit-cta {
		margin-bottom: 3rem;

		a {
			@include text(delta);
			color: $color-white;
			font-weight: $font-weight-bold;
		}

		.logo-wrapper {
			margin: 2rem auto 1rem;
			width: 155px;
		}

		.mh-logo {
			width: 155px;
		}
	}

	.footer-primary,
	.footer-secondary {
		.disclaimer {
			color: $color-white;
			margin-bottom: 2rem;

			p {
				@include text(zeta);
			}
		}
	}

	.footer-primary {
		.disclaimer {
			display: none;
		}
	}

	.footer-secondary {
		text-align: center;
	}

	.copyright {
		@include text(zeta);
		color: $color-white;

		span {
			&:first-child {
				padding-right: 1rem;
			}
		}
	}

	.legal-links {
		display: flex;
		list-style: none;
		padding-left: 0;
		flex-flow: wrap;
		margin: auto;
		max-width: 245px;
		justify-content: center;

		li {
			&:after {
				content: '';
				border-right: 1px solid $color-white;
				padding-left: 0.825rem;
				margin-right: 0.825rem;
			}

			&:nth-of-type(even),
			&:last-child {
				&:after {
					display: none;
				}
			}

			a {
				@include text(zeta);
				color: $color-white;
			}
		}
	}

	@include media-breakpoint-up(sm) {
		.link-list {
			a {
				max-width: none;
			}
		}

		.legal-links {
			max-width: none;

			li {
				&:nth-of-type(even) {
					&:after {
						display: inline;
					}
				}
				&:last-child {
					&:after {
						display: none;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		.footer-wrapper {
			padding: 2.75rem 0;
		}

		.footer-primary {
			display: flex;
			justify-content: flex-start;
			margin-bottom: 1.75rem;

			.disclaimer {
				display: block;
				flex: 0 0 100%;
				padding-top: 2rem;
				margin-bottom: 0.5rem;

				p {
					@include text(eta, zeta);
					max-width: 500px;
				}
			}
		}

		.mh-logo-full {
			flex-flow: row wrap;
			padding-right: 2rem;
			width: 51%;
			justify-content: flex-start;

			.logo-wrapper,
			img {
				width: 8.5rem;
			}

			.title-list {
				li {
					@include text(kappa, iota);
				}
			}
		}

		.link-list {
			margin-top: 0;
			padding-top: 0;
			text-align: left;

			&:not(:last-child) {
				padding-right: 1.5rem;
				max-width: 35%;
			}

			ul {
				margin: 1.25rem 0 0;

				a {
					@include text(epsilon);
				}
			}

			li {
				padding-bottom: 0.625rem;
			}

			> li {
				padding-bottom: 1.25rem;
			}
		}

		.footer-secondary {
			align-items: flex-end;
			display: flex;
			flex-flow: wrap;
			justify-content: space-between;
			text-align: initial;

			> * {
				flex-basis: 50%;
			}

			.disclaimer {
				display: none;
			}
		}

		.copyright {
			@include text(eta);
			margin-bottom: 0;
		}

		.visit-cta {
			flex-basis: 100%;
			margin-bottom: 1.75rem;
			text-align: right;

			a {
				@include text(epsilon);
			}

			.logo-wrapper {
				margin: 0 0.25rem 1rem auto;
				width: 115px;
			}

			.mh-logo {
				width: 115px;
			}
		}

		.legal-links {
			justify-content: flex-end;
		}
	}

	@include media-breakpoint-up(lg) {
		.mh-logo-full {
			&:after {
				height: 50px;
				left: 215px;
				top: 11px;
			}

			.title-list {
				top: 9px;
				left: 232px;

				li {
					@include text(theta,eta);
				}
			}

			.logo-wrapper,
			img {
				width: 10rem;
			}
		}

		.link-list {
			&:not(:last-child) {
				padding-right: 2.5rem;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.link-list {
			&:not(:last-child) {
				padding-right: 2.5rem;
			}

			&:last-child {
				display: flex;
				flex-flow: row wrap;
				align-content: flex-start;

				li {
					flex-basis: 50%;
				}
			}
		}

		.footer-primary {
			.disclaimer {
				max-width: 25.625rem;
			}
		}
	}
}
