.microsite-theme,
.moments-theme {
	.video {
		.wistia_embed {
			.w-bpb-wrapper {
				background-color: transparent !important;
				border: none !important;
				border-radius: 0 !important;
				height: 200px !important;
				width: 200px !important;
				top: calc(50% - 100px) !important;
				left: calc(50% - 100px) !important;

				button {
					div {
						background-color: transparent !important;
					}

					svg {
						opacity: 0.8;
					}

					&:hover {
						svg {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}
