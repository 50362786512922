.advance {
	.advance--article-cta {
		padding-top: $padding-advance-section-mobile;
		padding-bottom: $padding-advance-section-mobile;
		.card {
			border-left-width: 0;
			border-right-width: 0;
			border-width: 0px;
			margin: 0 (-$padding-advance-mobile);
			max-width: none;
			.heading {
				padding-bottom: 28px;
				padding-top: 16px;
			}
			.card--content {
				padding: 0 20px;
				margin-bottom: -10px;
			}
			.advance--article-card--date {
				display: none;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.advance--article-cta {
			padding-top: $padding-advance-section-desktop;
			padding-bottom: $padding-advance-section-desktop;
			.card {
				border-width: 14px;
				margin: 0 0 15px;
				padding-top: 16px;
				padding-bottom: 68px;
				.heading {
					padding-bottom: 7px;
					padding-top: 5px;
				}
				.card--content {
					max-width: $advance-content-width;
					padding: 20px;
				}
			}
			/* article card variation */
			.advance--article-card {
				margin: 20px 0;
				min-height: unset;
				&::before {
					left: 0px;
					right: 0;
					display: block;
					margin-top: 28px;
				}
				.advance--article-card--content {
					display: flex;
					min-height: 0;
				}
				.advance--article-card--image {
					width: 47%;
					padding-left: 3px;
					overflow: hidden;
				}
				.advance--article-card--info {
					flex: 1;
					padding: 5px 0 0 30px;
					display: flex;
					justify-content: center;
					flex-direction: column;
				}
			}
		}
	}
}
