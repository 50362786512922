.story-hero {
	.moments-brand {
		display: none;
	}

	.hero-media {
		align-items: center;
		display: flex;
		height: auto;
		min-height: calc(80vh - 70px);
		margin-bottom: 0;
		position: relative;

		.hero-img,
		.video-img-btn {
			height: calc(80vh - 70px);
			min-height: 400px;
			width: 100%;

			img,
			source {
				border: none;
				font-family: 'object-fit: cover; object-position: center center;';
				height: 100%;
				min-height: 400px;
				object-fit: cover;
				object-position: center center;
				width: 100%;
			}
		}

		.video-img-btn {
			border: 0;
			height: 100%;
			left: 0;
			padding: 0;
			position: absolute;
			top: 0;

			&:after {
				border-bottom: 35px solid transparent;
				border-left: 70px solid rgba($color-white, 0.5);
				border-top: 35px solid transparent;
				content: '';
				display: block;
				height: 0;
				left: 50%;
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);
				transition: border-color 0.2s ease-in-out;
				width: 0;
			}

			&.playing {
				display: none;
			}
		}

		.video {
			min-height: 400px;
			width: 100%;

			.wistia_embed {
				align-items: center;
				display: flex;
				min-height: 400px;
			}
		}
	}

	.media-item {
		margin: 0;
		padding: 0;
		width: 100%;
	}

	@include media-breakpoint-up(lg) {
		.hero-outer {
			@include make-container();
			@include make-container-max-width();
			align-items: center;
			display: flex;
			flex-direction: column;
			min-height: calc(100vh - 70px);
			justify-content: space-between;
			padding: 0;
			padding-bottom: 7.5vh;
			padding-top: 7.5vh;
		}

		.moments-brand {
			display: block;

			img {
				height: 7vh;
				margin-bottom: 1.5rem;
				max-width: 500px;
				min-height: 50px;
			}
		}

		.hero-media {
			@include make-row();
			height: auto;
			min-height: 65vh;
			width: calc(100% + 30px);

			.hero-img,
			.video {
				@include make-col-ready();
				@include make-col(10);
				height: 100%;
				margin: auto;
				text-align: center;
				width: auto;

				img,
				source {
					font-family: 'object-fit: contain; object-position: center center;';
					height: auto;
					margin: auto;
					max-width: 100%;
					object-fit: contain;
					object-position: center center;
					width: auto;
				}

				.wistia_embed {
					display: block;
					min-height: none;
				}
			}

			.video-img-btn {
				display: none;
			}
		}
	}
}
