.pagination-controls {
	margin-bottom: 1.5rem;
	transition: opacity 0.4s ease-in-out;

	.pagination-counts {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1.5rem;
	}

	.showing-vs-total {
		@include text('epsilon');
		color: $color-neutral-dark;
	}

	.pagination-nav {
		display: flex;
		justify-content: center;
		margin-bottom: 1rem;

		.showing-vs-total {
			display: none;
		}
	}

	.pagination {
		.page-item {
			margin-right: 0.5rem;

			&.active {
				.page-link {
					cursor: default;
					background-color: $color-gray-darkest;
					border-color: $color-gray-darkest;
				}
			}

			&.disabled {
				&:first-child,
				&:last-child {
					.page-link {
						background-color: $color-gray-lightest;
					}
				}
				.page-link {
					.icon {
						stroke: $color-gray-light;
					}
				}
			}

			.page-link {
				@include text(zeta);
				border-radius: 5px;
				font-weight: $font-weight-bold;

				&:hover {
					.icon {
						stroke: $link-hover-color;
					}
				}

				span {
					align-items: center;
					display: flex;
					height: 17px;
				}

				.icon {
					height: 0.75rem;
					margin-top: -2px;
					stroke: $color-primary-dark-blue;
					width: 0.5rem;
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	@include media-breakpoint-up(md) {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin: 2rem 0;

		.pagination-nav {
			align-items: center;
			display: flex;
			margin-bottom: 0;
			width: auto;

			.showing-vs-total {
				display: inline-block;
				padding-right: 1rem;
			}

			.pagination {
				margin-bottom: 0;
			}
		}

		.pagination-counts {
			@include text('epsilon');
			margin-bottom: 0;

			.showing-vs-total {
				display: none;
			}
		}
	}
}
