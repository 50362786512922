/*
 *
 * COLOR VARIABLES
 * kbma - Known By Marketing As
 */

/* 2024 Primary Colors */
/* ~~IN 2024 Site Redesign - kbma 'Precision Grey' #2c373f START */
$color-precision-gray: #2c373f;
$color-primary-dark-blue: #2c373f;
$color-gray-darkest: #2c373f;
/* ~~IN 2024 Site Redesign - kbma 'Precision Grey' #2c373f END */
/* ~~IN 2024 Site Redesign - kbma 'Illumination Yellow' #ffcd55 START */
$color-illumination-yellow: #ffcd55;
$color-primary-yellow: #ffcd55;
/* ~~IN 2024 Site Redesign - kbma 'Illumination Yellow' #ffcd55 END */
/* ~~IN 2024 Site Redesign - kbma 'Sky Blue' #006D9D START */
$color-sky-blue: #006D9D;
$color-primary-blue: #006D9D;
$color-primary-light-blue: #006D9D;
$color-secondary-blue: #006D9D;
$color-secondary-light-blue: #006D9D;
/* ~~IN 2024 Site Redesign - kbma 'Sky Blue' #006D9D END */

/* 2024 Secondary Colors */
/* ~~IN 2024 Site Redesign - kbma 'Sky Blue-ish Tint' #f2f9ff START */
$color-sky-blue-ish-tint: #f2f9ff;
$color-neutral-lightest: #f2f9ff;
/* ~~IN 2024 Site Redesign - kbma 'Sky Blue-ish Tint' #f2f9ff END */
/* ~~IN 2024 Site Redesign - kbma 'Precision Grey Tint' #cbced1 START */
$color-precision-gray-tint: #cbced1;
$color-gray-light: #cbced1;
$color-gray-secondary: #cbced1;
/* ~~IN 2024 Site Redesign - kbma 'Precision Grey Tint' #cbced1 END */
/* ~~IN 2024 Site Redesign - kbma 'Precision Grey-ish Tint' #f5f5f6 START */
$color-precision-gray-ish-tint: #f5f5f6;
$color-neutral-lighter: #f5f5f6;
/* ~~IN 2024 Site Redesign - kbma 'Precision Grey-ish Tint' #f5f5f6 END */
/* ~~IN 2024 Site Redesign - kbma 'Illumination Yellow-ish Tint' #ffebbb START */
$color-illumination-yellow-ish-tint: #ffebbb;
$color-secondary-yellow: #ffebbb;
/* ~~IN 2024 Site Redesign - kbma 'Illumination Yellow-ish Tint' #ffebbb END */

/* 2024 Tertiary Colors */
/* ~~IN 2024 Site Redesign - kbma 'Deep Green' #005557 START */
$color-deep-green: #005557;
$color-secondary-dark-teal: #005557;
/* ~~IN 2024 Site Redesign - kbma 'Deep Green' #cbced1 END */
/* ~~IN 2024 Site Redesign - kbma 'Overcast Blue' #3d5d6b START */
$color-overcast-blue: #3d5d6b;
$color-neutral-darkest: #3d5d6b;
$color-neutral-dark: #3d5d6b;
/* ~~IN 2024 Site Redesign - kbma 'Overcast Blue' #3d5d6b END */
/* ~~IN 2024 Site Redesign - kbma 'Glacier Blue' #a2c3d6 START */
$color-glacier-blue: #a2c3d6;
$color-primary-blue-gray: #a2c3d6;
$color-neutral: #a2c3d6;
$color-neutral-light: #a2c3d6;
$color-secondary-teal: #a2c3d6;
$color-secondary-light-teal: #a2c3d6;
/* ~~IN 2024 Site Redesign - kbma 'Glacier Blue' #a2c3d6 END */
/* ~~IN 2024 Site Redesign - kbma 'Graham Gray' #e4e5e6 START */
$color-graham-gray: #e4e5e6;
$color-gray-lightest: #e4e5e6;
/* ~~IN 2024 Site Redesign - kbma 'Graham Gray' #e4e5e6 END */
$color-blue-gray: #333f48;

/* Utility Colors */
$color-black: #000;
$color-white: #fff;
$color-green: #378957;
$color-dark-red: #8b0000;
$color-red: #b23838;
$color-light-red: #ffe5e5;

/* Patient Type Indicators */
$color-new-patients: #00a084;
$color-existing-patients: #0090ff;

/* Location Status */
$color-open: #128b40;

$color-quick-links: #00487c;
$color-highlight: #f2f9ff;
//$color-highlight: #cfe9f7;

/* Childrens Theme */
$color-childrens-primary: #6fb068;
$color-childrens-secondary: #509536;
$color-childrens-tertiary: #a0bf9b;

/* Health Plan Theme */
$color-healthplan-blue: #006D9D;
$color-healthplan-gray: #707070;
$color-healthplan-subtle-green: #dbedd3;

/* Microsite Theme */
$color-microsite-yellow: #fcb930;
$color-microsite-blue: #5799cb;
$color-microsite-dark-blue: #092a3e;
$color-microsite-neutral-light: #faf9f4;
$color-microsite-gray: #404040;
$color-microsite-gray-light: #666666;
$color-microsite-gray-lightest: #cccccc;
$color-microsite-gray-quizlet: #ccd5d7;

/* Moments Theme */
$color-moments-black: #1f1c17;
$color-moments-gray: #333;

/* Advance */
$color-advance-yellow: #f7aa00;
$color-advance-green: #006366;
$color-advance-teal: #24a0a5;
$color-advance-light-teal: #47d2d7;
$color-advance-dark-teal: #00364a;
$color-advance-blue: #3579ad;
$color-advance-mid-blue: #19647e;
$color-advance-light-grey: #c5d6df;
$color-advance-mid-bluegrey: #4d7489;
$color-advance-dark-bluegrey: #3f596c;
$color-advance-darker-bluegrey: #626a7b;
$color-advance-mid-grey: #99b4c3;
$color-advance-off-white: #eff3f7;
$color-advance-off-black: #5a5a5a;
$color-advance-white: #fff;
$color-advance-black: #000;

/* EDW BlockIt */
$color-edw-blue: #146B9A;
$color-edw-gray: #333;


// Deprecated by 2024 Site Redesign
// --- Primary Colors ---
// $color-primary-yellow: #f0b323;
// $color-primary-dark-blue: #00364a;
// $color-primary-blue: #3f596c;
// $color-primary-light-blue: #12598d;
// $color-primary-blue-gray: #4d7489;

// --- Secondary Colors ---
// $color-secondary-blue: #19647e;
// $color-secondary-light-blue: #3579ad;
// $color-secondary-dark-teal: #005e64;
// $color-secondary-teal: #24a0a5;
// $color-secondary-light-teal: #47d2d7;

// --- Neutral Colors ---
// $color-neutral-darkest: #2c3e4d;
// $color-neutral-dark: #2c3e4d;
// $color-neutral: #99b4c3;
// $color-neutral-light: #c5d6df;
// $color-neutral-lighter: #f5f9fc;
// $color-neutral-lightest: #eff3f7;

// --- Gray Colors ---
// $color-gray-darkest: #212529;
// $color-gray-light: #aaa;
// $color-gray-lightest: #ddd;
// $color-gray-secondary: #6c757d;
