.media-item {
	@include make-container();
	@include make-container-max-width();
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 3rem;
	min-width: 150px;
	padding-top: 0;
	width: 100%;

	&.summary-top {
		display: flex;
		flex-direction: column-reverse;
	}

	.generic &,
	.two-column-main &,
	.two-column &,
	.container & {
		padding: 0;
	}

	&.center {
		display: block;
		margin-left: auto;
		margin-right: auto;

		.figure-caption,
		.figure-credits {
			margin-left: auto;
			margin-right: auto;
			max-width: 100%;
			text-align: center;
		}
	}

	.figure-img {
		width: 100%;
		max-height: 500px;
		margin-bottom: 0;
		object-fit: contain;
		object-position: center;
		font-family: 'object-fit: contain; object-position: center;';
		border-radius: 5px;
	}

	&.has-background {
		.figure-text {
			padding: 1rem 1.25rem;
			background-color: $color-neutral-lightest;
		}
	}

	.figure-text {
		padding: 1rem 0;
		text-align: center;
	}

	.figure-caption,
	.figure-credits {
		&.center {
			text-align: center;
		}
	}

	.figure-caption {
		@include text(zeta);
		color: $color-gray-darkest;
		~ .figure-credits {
			padding-top: 0.5rem;
		}
	}

	.figure-credits {
		@include text(eta);
		color: $color-gray-darkest;
		font-style: italic;
	}

	img {
		border-radius: 5px;
	}

	@include media-breakpoint-up(md) {
		display: block;
		width: 40%;

		&.center {
			width: 70%;
		}

		&.left {
			float: left;
			margin-left: 0;
			margin-right: 1.5rem;
		}

		&.right {
			float: right;
			margin-left: 1.5rem;
			margin-right: 0;
		}

		&.left,
		&.right {
			margin-bottom: 1.5rem;

			&:not(.has-background) {
				.figure-text {
					padding-bottom: 0;
				}
			}

			.wistia_embed {
				.w-bpb-wrapper {
					//border: 5px solid $color-white !important;
					height: 80px !important;
					width: 80px !important;
					top: calc(50% - 40px) !important;
					left: calc(50% - 40px) !important;
				}
			}
		}

		&.full-width {
			width: 100%;
		}
	}

	@include media-breakpoint-up(xl) {
		&.center {
			width: calc(1290px * 0.6 + 30px);
		}

		&.left,
		&.right {
			.wistia_embed {
				.w-bpb-wrapper {
					//border-width: 8px !important;
					height: 112px !important;
					width: 112px !important;
					top: calc(50% - 56px) !important;
					left: calc(50% - 56px) !important;
				}
			}
		}

		.generic &,
		.two-column-main &,
		.container & {
			&.center {
				width: 60%;
			}
		}
	}
}
