.quiz {
	@include make-container();
	@include make-container-max-width();
	margin-bottom: 2rem;

	.question-panel {
		display: none;

		&.active {
			display: block;
		}
	}

	.quiz-card {
		background-color: $color-neutral-lighter;
		// border-width: 0.75rem;
	}

	.heading-4 {
		color: $color-gray-darkest;
		// border-bottom: 1px solid $color-gray-lightest;
		margin-bottom: 1.75rem;
		// padding-bottom: 1rem;
	}

	.validation-message,
	.contact-validation-message,
	.email-validation-message {
		color: $color-red;
		display: none;

		&.active {
			display: block;
		}
	}

	.form-control {
		margin-bottom: 1rem;

		&[type='email'] {
			margin-bottom: 0;

			&.invalid {
				+ .email-validation-message {
					display: block;
				}
			}
		}
	}

	input[type=text], input[type=email], input[type=search] {
		background-color: $color-white;

		&:hover {
			border-color: $color-primary-blue;
		}
	}

	.success-message,
	.failure-message {
		display: none;

		&.active {
			display: block;
		}
	}

	.btn {
		display: block;
		margin: 2rem auto 0;
	}

	.progress-bar-container {
		background-color: $color-gray-light;
		position: relative;
		width: 100%;
		height: 30px;
		border-radius: 5px;
		overflow: hidden;
		margin-top: 2rem;
	}

	.progress-bar {
		width: 0%;
		height: 100%;
		background-color: $color-deep-green;
		text-align: center;
		line-height: 30px;
		transition: width 0.6s ease-in-out;
	}

	.progress-percent {
		@include text(delta);
		color: $color-white;
		display: flex;
		justify-content: center;
		font-weight: $font-weight-semi-bold;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}

	@include media-breakpoint-up(md) {
		.quiz-card {
			padding: 2.5rem 2.25rem;
		}
	}
}
