.tag-list {
	.tag {
		@include text(epsilon);
		color: $color-primary-light-blue;
		padding-top: 0.5rem;

		span {
			display: inline-block;
		}

		.tag-remove-btn {
			@include text(zeta);
			background: none;
			border: none;
			font-weight: $font-weight-bold;
			margin-left: 0.25rem;
			padding: 0;

			.icon {
				align-self: center;
				display: flex;
				fill: $color-gray-light;
				height: 0.75rem;
				width: 0.75rem;
			}

			&:hover,
			&:focus {
				outline: 0;

				.icon {
					fill: $color-neutral;
				}
			}
		}
	}
}
