.error-page {
	margin-top: 4rem;

	.error-page-container {
		@include make-container();
		@include make-container-max-width();
		margin-bottom: 4rem;
		text-align: center;
	}

	.error-title {
		background: $color-primary-blue;
		border-radius: 0.75rem;
		color: $color-white;
		display: inline-block;
		font-weight: $font-weight-bold;
		margin: 0 auto 2.75rem;
		padding: 1.25rem;
		position: relative;
		width: 100%;

		.heading-3 {
			margin: 0;
		}

		&:after {
			display: block;
			content: ' ';
			width: 0;
			height: 0;
			border-left: 12px solid transparent;
			border-right: 12px solid transparent;
			border-top: 12px solid $color-primary-blue;
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	.error-content {
		.heading-3 {
			@include text(delta, gamma);
			color: $color-primary-blue;
			margin-bottom: 2.5rem;
		}

		p {
			@include text(delta, gamma);
			color: $color-primary-blue;
			margin-bottom: 2.5rem;

			&.error-instructions {
				@include text(epsilon);
				margin-bottom: 1.5rem;
				margin-top: 4rem;
			}

			a {
				font-weight: $font-weight-semi-bold;
				text-decoration: underline;
			}
		}
	}

	.error-links {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			margin-bottom: 0.75rem;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		a {
			font-weight: $font-weight-semi-bold;
			text-decoration: underline;
		}
	}

	.global-search-typeahead {
		.search-form {
			position: relative;

			input {
				&::placeholder {
					color: $color-primary-blue;
				}
			}

			.submit-search {
				background: none;
				border: none;
				outline: 0;
				padding: 0;
				position: absolute;
				right: 1rem;
				top: 50%;
				transform: translateY(-50%);

				&:hover,
				&:focus {
					.icon-search {
						stroke: $color-secondary-light-blue;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		margin-top: 7rem;

		.error-title {
			padding: 1.25rem 2.5rem;
			width: auto;
		}

		.error-content {
			.heading-3 {
				@include text(beta, alpha);
			}

			p,
			.global-search-typeahead {
				margin-left: 6rem;
				margin-right: 6rem;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.error-content {
			@include make-col(7);
			margin: auto;
		}

		.error-links {
			list-style-type: none;

			li {
				display: inline-block;
				margin-left: 1.75rem;
			}
		}

		.global-search-typeahead {
			.search-grid {
				@include make-container();
				@include make-container-max-width();
				left: 50%;
				margin: 0;
				position: relative;
				width: 100vw;

				.search-dropdown {
					left: 0;
				}
			}
		}
	}
}
