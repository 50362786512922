.two-column {
	@include make-container();
	@include make-container-max-width();
	margin-bottom: 2rem;

	&.alt-background {
		background-color: $color-highlight;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.row > * {
		margin-bottom: 1.25rem;

		> :last-child {
			margin-bottom: 0;
		}
	}

	.landing-page {
		.heading-2 {
			@include text(beta);
			font-weight: $font-weight-bold;
		}
		.heading-3 {
			@include text(gamma, beta);
			&:nth-child(n + 3) {
				font-weight: $font-weight-normal;
			}
		}
		&.two-column-sidebar {
			h2 {
				@include text(gamma, beta);
			}
		}
	}

	@include media-breakpoint-up(md) {
		.row > * {
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-up(lg) {
		.landing-page {
			&.two-column-main {
				flex: 0 0 45%;
				max-width: 45%;
			}
			&.two-column-sidebar {
				h2 {
					@include text(beta, beta);
				}
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.landing-page {
			&.two-column-main {
				flex: 0 0 43%;
				max-width: 43%;
			}
		}
	}
}
