.event-list {
	@include make-container();
	@include make-container-max-width();
	margin-bottom: 2rem;

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	.event-list-header {
		display: none;
	}

	.event-listing {
		display: none;
		padding: 2rem 0;

		&:before {
			display: none;
		}

		&.active {
			display: block;
		}

		&:not(:last-of-type) {
			margin-bottom: 0;
		}
	}

	.event-date-time {
		@include text(zeta);
		color: $color-gray-darkest;
		display: none;
		margin-bottom: 0.75rem;

		> :not(:last-child) {
			margin-right: 1rem;
			position: relative;

			&:after {
				background-color: $color-gray-darkest;
				content: '';
				height: 100%;
				position: absolute;
				right: -0.5rem;
				width: 1px;
			}
		}
	}

	.event-location {
		@include text(zeta);
		margin-bottom: 1.25rem;

		.name {
			margin-bottom: 0.125rem;
			font-weight: $font-weight-bold;
		}
	}

	.event-description {
		margin-bottom: 1.75rem;

		.event-title {
			@include text(epsilon);
			color: $color-primary-blue;
			margin-bottom: 0.5rem;

			a {
				color: $color-primary-blue;
			}
		}

		.event-date-time {
			display: flex;
		}

		p {
			@include text(zeta);

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.event-space {
		.btn {
			padding: 0.75rem 1.5rem;
		}
	}

	.pagination-controls {
		.pagination-counts {
			justify-content: center;
		}
	}

	@include media-breakpoint-up(md) {
		.event-list-header {
			@include text(epsilon);
			border-bottom: 1px solid $color-gray-light;
			display: flex;
			justify-content: space-between;
			padding: 1.25rem 0;

			span {
				color: $color-neutral-dark;
			}
		}

		.event-listing {
			border-bottom: 1px solid $color-gray-light;
			justify-content: space-between;
			padding: 1.25rem 0;

			&.active {
				display: flex;
			}
		}

		.event-date-time {
			@include text(epsilon);
			display: block;
			flex-basis: 100px;
			margin: 0 1rem 0 0;
			max-width: 100px;
			order: 1;

			> :not(:last-child) {
				margin: 0 0 0.125rem;

				&:after {
					display: none;
				}
			}
		}

		.event-space {
			flex-basis: 100px;
			margin-left: 1rem;
			max-width: 100px;
			order: 4;

			.btn {
				width: 100%;
			}
		}

		.event-location {
			@include text(epsilon);
			flex: 1 1 0;
			margin-bottom: 0;
			order: 3;
			padding: 0 1rem;
		}

		.event-description {
			flex: 1.4 1 0;
			margin-bottom: 0;
			order: 2;
			padding: 0 1rem;

			.event-title {
				@include text(delta);
			}

			.event-date-time {
				display: none;
			}

			p {
				@include text(epsilon);
			}
		}

		.space-label {
			display: none;
		}
	}
}
