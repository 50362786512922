.related-list-container {
	@include make-container();
	@include make-container-max-width();
	margin-bottom: 2rem;

	.section-title {
		margin-bottom: 1.25rem;
		padding-top: 2rem;

		&:after {
			background-color: $color-primary-yellow;
		}
	}

	.description {
		@include text(epsilon);
	}

	.btn-container {
		padding-bottom: 2rem;
		text-align: center;
		width: 100%;
	}

	.view-all-bottom {
		display: inline-block;
	}

	.related-list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-around;
		list-style: none;
		padding: 0;
		gap: 1rem;

		&.doctor {
			.related-list-item {
				.related-DoL {
					margin-top: calc(64px - 12px);

					.profile-image {
						// height: 108px;
						height: 128px;
						// min-width: 108px;
						min-width: 128px;
						margin-top: $profile-image-offset;
						margin-left: auto;
						margin-right: auto;
						margin-bottom: 1rem;
						// width: 108px;
						width: 128px;
					}
				}
			}
		}
	}

	.related-list-item {
		display: flex;
		flex: 100%;
		max-width: 100%;
		flex-direction: column;
		padding-top: 1rem;
		padding-bottom: 0;
		margin-bottom: 1rem;
	}

	.related-DoL {
		// border: 0.75rem solid $color-neutral-light;
		// border-radius: 0;
		border-radius: 5px;
		display: flex; //revisit this - was block
		margin-top: calc(77px - 4px);
		width: 100%;
		background-color: $color-neutral-lighter;

		.card-header {
			background-color: transparent;
			border-bottom: none;
			flex: 1;
			// padding-top: 0;
			// padding-bottom: 0.5rem;
			padding-bottom: 1.5rem;
			text-align: center;
		}

		.profile-image {
			display: block;
			height: 144px;
			min-width: 144px;
			margin-top: calc(-77px - 8px);
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 1rem;
			width: 144px;
			border: none;
			box-shadow: none;

			&[href] {
				&:hover,
				&:focus {
					// box-shadow: 0 8px 24px 0 rgba($color-black, 0.24);
					text-decoration: none;

					+ .doctor-details {
						.doctor-name {
							color: $color-secondary-light-blue;
							text-decoration: underline;
						}
					}
				}
			}
		}

		.doctor-details, .location-details {
			display: flex;
			flex-direction: column;

			.doctor-location {
				margin-top: auto;
				display: flex;
				justify-content: space-evenly;
			}

			.location-address {
				display: flex;
    			flex-wrap: wrap;
			}
		}

		.doctor-name,
		.location-name {
			// @include text(epsizlon);
			@include text(zeta);
			// color: $color-primary-light-blue;
			color: $color-gray-darkest;
			// display: block;
			display: flex;
			font-weight: $font-weight-bold;
			margin-bottom: 0.5rem;
			justify-content: center;

			&:last-child {
				margin-bottom: 0;
			}

			&[href] {
				&:hover,
				&:focus {
					color: $color-secondary-light-blue;
				}
			}
		}

		.ratings {
			align-items: center;
			display: flex;
			flex-wrap: wrap;
			min-height: 18px;
			justify-content: center;
			margin-bottom: 0.5rem;

			.rating-stars {
				margin-right: 0.75rem;
			}

			.rating-count {
				@include text(eta, theta);
				color: $color-neutral-dark;
				margin: 3px 0 1px 2px;
			}
		}

		.ratings-fill {
			background-color: $color-primary-yellow;
			height: 100%;
			width: 100%;
		}

		.icon-view-tile {
			fill: $color-primary-yellow;
		}

		.distance-and-location {
			margin-bottom: 0.5rem;

			.subsection-content {
				margin-top: 0.375rem;
				padding-right: .5rem;
				display: flex;
				flex-basis: 50%;
				align-items: center;
				flex-direction: row-reverse;
				height: .625rem;

				&:after {
					background-color: #000;
					right: 0;
					content: "";
					display: block;
					height: 11px;
					left: calc(50% - 1px);
					position: absolute;
					width: 1px;
				}

				&:last-child {
					flex-basis: 100%;
					padding-right: 0;
					justify-content: space-evenly;

					&:after {
						display: none;
					}
				}
			}

			.distance {
				display: flex;
				font-weight: $font-weight-bold;
				margin-top: 0.375rem;
				flex-basis: 100%;
				flex-direction: row;
				height: .625rem;
				align-items: center;
				justify-content: space-evenly;

				span {
					font-weight: $font-weight-normal;
				}
			}

			.location-address .subsection-content + .distance {
					display: flex;
					font-weight: $font-weight-bold;
					margin-top: 0.375rem;
					flex-basis: 50%;
					flex-direction: row;
					padding-left: .5rem;
					height: .625rem;
					align-items: center;
					justify-content: start;
			}
		}

		.specialties {
			margin-bottom: 0;
		}

		.doctor-location,
		.specialty,
		.address {
			@include text(zeta);
			// display: block;
			display: flex;
			font-weight: $font-weight-normal;
			// margin-bottom: 0.25rem;
			justify-content: space-evenly;
			flex-basis: 100%;
			padding-bottom: .5rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.address {
			// padding-bottom: .125rem;
			padding-bottom: .5rem;
		}





		.appointment-type-label {
			// @include text(eta);
			@include text(zeta);
			border-top: 1px solid $color-neutral;
			font-weight: $font-weight-semi-bold;
			padding: 0.75rem 0;
			margin-top: 1rem;

			.appointment-type.patients-new:before {
				position: relative;
    			top: 1px;
			}
		}
	}

	@include media-breakpoint-up(sm) {
		.related-list {
			.related-list-item {
				.related-DoL {
					.card-header {
						display: flex;
						flex-direction: column;
						padding-top: 1rem;
						padding-bottom: 0.5rem;
					}

					.profile-image {
						height: 144px;
						min-width: 144px;
						margin-top: calc(144px / -2 - 28px);
						width: 144px;
					}

					.doctor-details {
						width: 100%;
					}

					.appointment-type-label {
						//@include text(zeta);
						@include text(eta);
						padding: 0.75rem 0 0;
					}
				}
			}
			&.doctor {
				padding-right: 1.5rem;

				.related-list-item {
					.related-DoL {
						margin-left: calc(64px - 20px);
						margin-top: 0;
						width: calc(100% - 44px);

						.card-header {
							display: flex;
							flex-direction: row;
							// padding: 1.5rem 4rem 1.5rem 0.5rem;
							padding: 1rem 1rem 1rem 0.5rem;
						}

						.profile-image {
							margin: auto 1rem auto -64px;
							// margin: -1.5rem 1rem -1rem -64px;
						}

						.doctor-details {
							text-align: left;
							width: 100%;
							padding: 0.5rem 0 0 1rem;

							.doctor-name, .specialty, .doctor-location {
								justify-content: start;
							}
						}

						.ratings {
							justify-content: flex-start;
							margin-bottom: 1rem;
						}

						// .doctor-location {
						// 	text-align: left;
						// }
					}
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		justify-content: flex-start;

		.section-title {
			text-align: center;

			&::after {
				left: calc(50% - 29px);
			}
		}

		.related-list-item {
			flex: calc((100% / 2) - 15px);
			max-width: calc((100% / 2) - 15px);
			padding-top: 0.5rem;
			padding-right: 1rem;
		}

		.related-list {
			justify-content: flex-start;

			&.doctor {
				padding-right: 0;

				.related-list-item {
					flex: calc((100% / 2) - 8px);
					max-width: calc((100% / 2) - 8px);

					.related-DoL {
						height: 100%;

						// .card-header {
						// 	padding-right: 3rem;
						// }
					}
				}
			}

			.related-DoL {
				height: calc(100% - (77px - 4px));
			}
		}

		.related-list.two-col-layout,
		.two-column & .related-list {
			.related-list-item {
				flex: 100%;
				max-width: 100%;

				.related-DoL {
					.card-header {
						display: flex;
						flex-direction: column;
						padding-top: 1rem;
						padding-bottom: 0.5rem;
					}

					.doctor-details {
						width: 100%;
					}
				}
			}
			&.doctor {
				.related-list-item {
					flex: 100%;
					max-width: 100%;

					.related-DoL {
						margin-left: calc(64px - 20px);
						margin-top: 0;

						.card-header {
							display: flex;
							flex-direction: row;
							padding: 1.5rem 4rem 1.5rem 0.5rem;
						}

						.doctor-details {
							text-align: left;
							width: 100%;
						}

						.appointment-type-label {
							@include text(eta);
						}

						.doctor-location {
							text-align: left;
						}
					}
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.section-title {
			display: inline-block;
			margin-bottom: 1rem;
			text-align: left;

			&::after {
				left: 0;
			}
		}

		.related-DoL {
			.card-header {
				display: flex;
				flex-direction: column;
			}

			.doctor-name,
			.location-name {
				@include text(epsilon);
			}
		}

		.related-list {
			gap: 1rem;

			&.doctor {
				.related-list-item {
					// flex: calc((100% / 3) - 12px);
					// max-width: calc((100% / 3) - 12px);
					flex: calc((100% / 2) - 12px);
					max-width: calc((100% / 2) - 12px);
				}

				&:after {
					content: ' ';
					// flex: calc((100% / 3) - 12px);
					// max-width: calc((100% / 3) - 12px);
					flex: calc((100% / 2) - 12px);
					max-width: calc((100% / 2) - 12px);
				}


			}

			&:after {
				content: ' ';
				flex: calc((100% / 3) - 20px);
				max-width: calc((100% / 3) - 20px);
				width: 100%;
			}
		}

		// .related-list-item {
		// 	flex: calc((100% / 3) - 20px);
		// 	max-width: calc((100% / 3) - 20px);
		// }

		.related-list.two-col-layout,
		.two-column & .related-list {
			.related-list-item {
				flex: calc((100% / 2) - 15px);
				max-width: calc((100% / 2) - 15px);

				.related-DoL {
					.card-header {
						display: flex;
						flex-direction: column;
						padding: 1rem 0.3rem 0.5rem;
					}

					.doctor-details {
						width: 100%;
					}

					.doctor-location {
						text-align: center;
					}
				}
			}
			&.doctor {
				.related-list-item {
					flex: calc((100% / 2) - 8px);
					max-width: calc((100% / 2) - 8px);

					.related-DoL {
						display: block;
						// margin-left: calc(64px - 12px);
						margin-top: 0;

						.card-header {
							display: flex;
							flex-direction: row;
							padding: 1.5rem 4rem 1.5rem 0.5rem;
						}

						.doctor-details {
							text-align: left;
							width: 100%;
						}

						.doctor-location {
							text-align: left;
						}
					}
				}
			}

			&:after {
				display: none;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.related-list {
			&.two-col-layout,
			.two-column & {
				.related-list-item {
					flex: calc((100% / 3) - 20px);
					max-width: calc((100% / 3) - 20px);
					flex-direction: row;
				}

				.doctor-location {
					text-align: left;
				}

				&:after {
					content: ' ';
					display: block;
					flex: calc((100% / 3) - 20px);
					max-width: calc((100% / 3) - 20px);
				}
			}

			&.doctor {
				.related-list-item {
					flex: calc((100% / 3) - 20px);
					max-width: calc((100% / 3) - 20px);

					.related-DoL {
						.card-header {
							// padding-right: 4rem;
							padding-right: 2rem;
						}
					}
				}
			}
		}
	}
}
