.listing-overview {
	@include make-container();
	@include make-container-max-width();

	.listing-overview-inner {
		background-color: $color-white;
		margin-bottom: 1rem;
		max-width: 100%;
	}

	.card-deck {
		flex-direction: column;

		.listing-overview-container {
			display: flex;
			flex-basis: auto;
			flex-flow: column;
			margin-bottom: 4rem;
			max-width: 100%;
			padding: 1.5rem;
			background-color: $color-neutral-lighter;
			border-radius: 5px;

			&.is-expanded {
				.listing-overview-content {
					max-height: none;
				}

				.btn-link {
					display: block;
				}
			}

			.listing-overview-content {
				flex: 1 1 auto;
				margin-bottom: 2rem;
				overflow: hidden;
				padding: 0;

				button {
					display: none;
				}
			}
		}
	}

	.section-title {

		&::after {
			background-color: $color-illumination-yellow;
		}
	}

	.btn-link {
		color: #212529;
		background-color: #ffcd55;
		border-color: #ffcd55;

		&:hover,
		&:focus {
			color: $color-precision-gray;
			background-color: $color-white;
			text-decoration: none;
		}
	}

	.section-subtitle {
		@include text(epsilon);
		font-weight: $font-weight-normal;

		color: $color-precision-gray;
		text-align: left;
	}

	.offering-list {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			@include text(zeta);
			width: 100%;
			min-height: 3rem;
			border-bottom: 2px solid $color-graham-gray;

			line-height: 1.1428571429;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			height: 3rem;
			padding: 2rem 0;

					a {
						min-width: -webkit-fill-available;

						svg {
    						float: right;
							margin-right: -1rem;
						}
					}
		}
	}

	@include media-breakpoint-up(sm) {
		.card-deck {
			flex-flow: column;
			padding-left: 15px;
			padding-right: 15px;
		}

		.offering-list {
			columns: 2;
			column-gap: $grid-gutter-width;
			column-fill: balance;
		}
	}

	@include media-breakpoint-up(md) {
		.two-column & {
			.card-deck {
				flex-flow: column;
			}

			.listing-overview-container {
				&:only-child {
					.offering-list {
						columns: 2;
					}
				}
			}
		}

		.listing-overview-inner {
			margin-bottom: 2rem;
		}

		.card-deck {
			flex-flow: row wrap;

			.listing-overview-container {
				flex-basis: calc(50% - 1rem);
				max-width: calc(50% - 1rem);
				overflow: auto;
				padding: 1.5rem 2rem 1.5rem;
				margin-bottom: 1.5rem;

				&:nth-child(odd) {
					margin-left: 0;
				}

				&:nth-child(even) {
					margin-right: 0;
				}

				&:last-child:nth-child(odd) {
					margin-left: auto;
					margin-right: auto;
				}

				&:only-child {
					flex-basis: 100%;
					max-width: 100%;
					margin-left: 0;
					margin-right: 0;
				}

				.listing-overview-content {
					max-height: none;
				}

				.toggle-expand {
					display: none;
				}
			}
		}

		.section-subtitle {
			@include text(epsilon);
		}

		.offering-list {
			li {
				margin-bottom: 0.5rem;
			}
		}

		.btn-primary {
			display: block;
			max-width: 12rem;
		}
	}

	@include media-breakpoint-up(lg) {
		.two-column & {
			.card-deck {
				flex-flow: row wrap;

				.listing-overview-container {
					&:only-child {
						.offering-list {
							columns: 2;
						}
					}
				}
			}

			.offering-list {
				columns: 1;
			}
		}

		.section-subtitle {
			@include text(delta);
			flex-basis: 50%;
			padding-right: 4rem;
		}

		.listing-overview-container {
			padding: 3rem 3rem 1.5rem;

				&:only-child {

					.listing-overview-content {
						margin-bottom: 2rem;
						overflow: hidden;
						padding: 0;
						flex-direction: row;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
					}

					.offering-list {
						columns: 4;
						max-width: 100%;
						min-width: 100%;
						margin-top: 0rem;
					}

					.section-title {
						flex-basis: 100%;
					}

					.section-subtitle {
						@include text(delta);
						flex-basis: 50%;
						padding-right: 3rem;
						padding-bottom: 1rem;
					}

					.section-subtitle + .offering-list {
						columns: 2;
						max-width: 50%;
						min-width: 50%;
						margin-top: -2rem;
					}

			}
		}
	}

	@include media-breakpoint-up(xl) {

		.two-column & {
			.offering-list {
				columns: 2;
			}

			.card-deck {
				.listing-overview-container {
					&:only-child {
						.offering-list {
							columns: 4;
						}

						.section-subtitle + .offering-list {
							columns: 2;
						}
					}
				}
			}
		}

		.card-deck {
			.listing-overview-container {

				&:only-child {
					padding: 3rem;

					h4.section-subtitle {
						max-width: 30rem;
					}
				}
			}
		}
	}
}
