@charset "UTF-8";
/* Font Families */
/* @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i'); */
@import url("https://use.typekit.net/fqx2phx.css");
/* Colors */
/*
 *
 * COLOR VARIABLES
 * kbma - Known By Marketing As
 */
/* 2024 Primary Colors */
/* ~~IN 2024 Site Redesign - kbma 'Precision Grey' #2c373f START */
/* ~~IN 2024 Site Redesign - kbma 'Precision Grey' #2c373f END */
/* ~~IN 2024 Site Redesign - kbma 'Illumination Yellow' #ffcd55 START */
/* ~~IN 2024 Site Redesign - kbma 'Illumination Yellow' #ffcd55 END */
/* ~~IN 2024 Site Redesign - kbma 'Sky Blue' #006D9D START */
/* ~~IN 2024 Site Redesign - kbma 'Sky Blue' #006D9D END */
/* 2024 Secondary Colors */
/* ~~IN 2024 Site Redesign - kbma 'Sky Blue-ish Tint' #f2f9ff START */
/* ~~IN 2024 Site Redesign - kbma 'Sky Blue-ish Tint' #f2f9ff END */
/* ~~IN 2024 Site Redesign - kbma 'Precision Grey Tint' #cbced1 START */
/* ~~IN 2024 Site Redesign - kbma 'Precision Grey Tint' #cbced1 END */
/* ~~IN 2024 Site Redesign - kbma 'Precision Grey-ish Tint' #f5f5f6 START */
/* ~~IN 2024 Site Redesign - kbma 'Precision Grey-ish Tint' #f5f5f6 END */
/* ~~IN 2024 Site Redesign - kbma 'Illumination Yellow-ish Tint' #ffebbb START */
/* ~~IN 2024 Site Redesign - kbma 'Illumination Yellow-ish Tint' #ffebbb END */
/* 2024 Tertiary Colors */
/* ~~IN 2024 Site Redesign - kbma 'Deep Green' #005557 START */
/* ~~IN 2024 Site Redesign - kbma 'Deep Green' #cbced1 END */
/* ~~IN 2024 Site Redesign - kbma 'Overcast Blue' #3d5d6b START */
/* ~~IN 2024 Site Redesign - kbma 'Overcast Blue' #3d5d6b END */
/* ~~IN 2024 Site Redesign - kbma 'Glacier Blue' #a2c3d6 START */
/* ~~IN 2024 Site Redesign - kbma 'Glacier Blue' #a2c3d6 END */
/* ~~IN 2024 Site Redesign - kbma 'Graham Gray' #e4e5e6 START */
/* ~~IN 2024 Site Redesign - kbma 'Graham Gray' #e4e5e6 END */
/* Utility Colors */
/* Patient Type Indicators */
/* Location Status */
/* Childrens Theme */
/* Health Plan Theme */
/* Microsite Theme */
/* Moments Theme */
/* Advance */
/* EDW BlockIt */
/* Bootstrap and Bootstrap variable overrides */
/*
 * Bootstrap variable overrides only
 * https://github.com/twbs/bootstrap/blob/master/scss/_variables.scss
 */
/* Revert back to sensible padding-x for inputs */
/*
 * Custom Range
 */
/*
 * Pagination
 */
/*
 * Overriding Bootstrap defaults of:
 * $container-max-widths: (
 *   sm: 540px,
 *   md: 720px,
 *   lg: 960px,
 *   xl: 1140px
 * ) !default;
 */
/*
 * Tables
 * Customizes the `.table` component with basic values, each used across all table variations.
 */
/*!
 * Bootstrap v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.3125rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.3125rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 1317px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 1318px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 1319px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #006D9D;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0 solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 0 solid #dee2e6;
}
.table tbody + tbody {
  border-top: 0 solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 0 solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 0 solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 0;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #f2f9ff;
}

.table-hover tbody tr:hover {
  color: #006D9D;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control, .share-story-modal form input[type=text],
.share-story-modal form input[type=email],
.share-story-modal form input[type=tel],
.share-story-modal form select,
.share-story-modal form textarea, html .microsite-theme .marketo-form .mktoForm .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .mktoTextField,
html .microsite-theme .marketo-form .mktoForm select,
html .microsite-theme .marketo-form .mktoForm textarea,
html .moments-theme .marketo-form .mktoForm .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .mktoTelField,
html .moments-theme .marketo-form .mktoForm .mktoTextField,
html .moments-theme .marketo-form .mktoForm select,
html .moments-theme .marketo-form .mktoForm textarea, .marketo-form .mktoForm select,
.marketo-form .mktoForm textarea, .marketo-form .mktoForm .mktoEmailField,
.marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .mktoTextField, .bmi-calculator input[type=text],
.bmi-calculator select, textarea {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.625rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control, .share-story-modal form input[type=text],
.share-story-modal form input[type=email],
.share-story-modal form input[type=tel],
.share-story-modal form select,
.share-story-modal form textarea, html .microsite-theme .marketo-form .mktoForm .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .mktoTextField,
html .microsite-theme .marketo-form .mktoForm select,
html .microsite-theme .marketo-form .mktoForm textarea,
html .moments-theme .marketo-form .mktoForm .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .mktoTelField,
html .moments-theme .marketo-form .mktoForm .mktoTextField,
html .moments-theme .marketo-form .mktoForm select,
html .moments-theme .marketo-form .mktoForm textarea, .marketo-form .mktoForm select,
.marketo-form .mktoForm textarea, .marketo-form .mktoForm .mktoEmailField,
.marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .mktoTextField, .bmi-calculator input[type=text],
.bmi-calculator select, textarea {
    transition: none;
  }
}
.form-control::-ms-expand, .share-story-modal form input[type=text]::-ms-expand,
.share-story-modal form input[type=email]::-ms-expand,
.share-story-modal form input[type=tel]::-ms-expand,
.share-story-modal form select::-ms-expand,
.share-story-modal form textarea::-ms-expand, html .microsite-theme .marketo-form .mktoForm .mktoEmailField::-ms-expand,
html .microsite-theme .marketo-form .mktoForm .mktoTelField::-ms-expand,
html .microsite-theme .marketo-form .mktoForm .mktoTextField::-ms-expand,
html .microsite-theme .marketo-form .mktoForm select::-ms-expand,
html .microsite-theme .marketo-form .mktoForm textarea::-ms-expand,
html .moments-theme .marketo-form .mktoForm .mktoEmailField::-ms-expand,
html .moments-theme .marketo-form .mktoForm .mktoTelField::-ms-expand,
html .moments-theme .marketo-form .mktoForm .mktoTextField::-ms-expand,
html .moments-theme .marketo-form .mktoForm select::-ms-expand,
html .moments-theme .marketo-form .mktoForm textarea::-ms-expand, .marketo-form .mktoForm select::-ms-expand,
.marketo-form .mktoForm textarea::-ms-expand, .marketo-form .mktoForm .mktoEmailField::-ms-expand,
.marketo-form .mktoForm .mktoTelField::-ms-expand,
.marketo-form .mktoForm .mktoTextField::-ms-expand, .bmi-calculator input[type=text]::-ms-expand,
.bmi-calculator select::-ms-expand, textarea::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus, .share-story-modal form input[type=text]:focus,
.share-story-modal form input[type=email]:focus,
.share-story-modal form input[type=tel]:focus,
.share-story-modal form select:focus,
.share-story-modal form textarea:focus, html .microsite-theme .marketo-form .mktoForm .mktoEmailField:focus,
html .microsite-theme .marketo-form .mktoForm .mktoTelField:focus,
html .microsite-theme .marketo-form .mktoForm .mktoTextField:focus,
html .microsite-theme .marketo-form .mktoForm select:focus,
html .microsite-theme .marketo-form .mktoForm textarea:focus,
html .moments-theme .marketo-form .mktoForm .mktoEmailField:focus,
html .moments-theme .marketo-form .mktoForm .mktoTelField:focus,
html .moments-theme .marketo-form .mktoForm .mktoTextField:focus,
html .moments-theme .marketo-form .mktoForm select:focus,
html .moments-theme .marketo-form .mktoForm textarea:focus, .marketo-form .mktoForm select:focus,
.marketo-form .mktoForm textarea:focus, .marketo-form .mktoForm .mktoEmailField:focus,
.marketo-form .mktoForm .mktoTelField:focus,
.marketo-form .mktoForm .mktoTextField:focus, .bmi-calculator input[type=text]:focus,
.bmi-calculator select:focus, textarea:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder, .share-story-modal form input[type=text]::placeholder,
.share-story-modal form input[type=email]::placeholder,
.share-story-modal form input[type=tel]::placeholder,
.share-story-modal form select::placeholder,
.share-story-modal form textarea::placeholder, html .microsite-theme .marketo-form .mktoForm .mktoEmailField::placeholder,
html .microsite-theme .marketo-form .mktoForm .mktoTelField::placeholder,
html .microsite-theme .marketo-form .mktoForm .mktoTextField::placeholder,
html .microsite-theme .marketo-form .mktoForm select::placeholder,
html .microsite-theme .marketo-form .mktoForm textarea::placeholder,
html .moments-theme .marketo-form .mktoForm .mktoEmailField::placeholder,
html .moments-theme .marketo-form .mktoForm .mktoTelField::placeholder,
html .moments-theme .marketo-form .mktoForm .mktoTextField::placeholder,
html .moments-theme .marketo-form .mktoForm select::placeholder,
html .moments-theme .marketo-form .mktoForm textarea::placeholder, .marketo-form .mktoForm select::placeholder,
.marketo-form .mktoForm textarea::placeholder, .marketo-form .mktoForm .mktoEmailField::placeholder,
.marketo-form .mktoForm .mktoTelField::placeholder,
.marketo-form .mktoForm .mktoTextField::placeholder, .bmi-calculator input[type=text]::placeholder,
.bmi-calculator select::placeholder, textarea::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .share-story-modal form input[type=text]:disabled,
.share-story-modal form input[type=email]:disabled,
.share-story-modal form input[type=tel]:disabled,
.share-story-modal form select:disabled,
.share-story-modal form textarea:disabled, html .microsite-theme .marketo-form .mktoForm .mktoEmailField:disabled,
html .microsite-theme .marketo-form .mktoForm .mktoTelField:disabled,
html .microsite-theme .marketo-form .mktoForm .mktoTextField:disabled,
html .microsite-theme .marketo-form .mktoForm select:disabled,
html .microsite-theme .marketo-form .mktoForm textarea:disabled,
html .moments-theme .marketo-form .mktoForm .mktoEmailField:disabled,
html .moments-theme .marketo-form .mktoForm .mktoTelField:disabled,
html .moments-theme .marketo-form .mktoForm .mktoTextField:disabled,
html .moments-theme .marketo-form .mktoForm select:disabled,
html .moments-theme .marketo-form .mktoForm textarea:disabled, .marketo-form .mktoForm select:disabled,
.marketo-form .mktoForm textarea:disabled, .marketo-form .mktoForm .mktoEmailField:disabled,
.marketo-form .mktoForm .mktoTelField:disabled,
.marketo-form .mktoForm .mktoTextField:disabled, .bmi-calculator input[type=text]:disabled,
.bmi-calculator select:disabled, textarea:disabled, .form-control[readonly], .share-story-modal form input[readonly][type=text],
.share-story-modal form input[readonly][type=email],
.share-story-modal form input[readonly][type=tel],
.share-story-modal form select[readonly],
.share-story-modal form textarea[readonly], html .microsite-theme .marketo-form .mktoForm [readonly].mktoEmailField,
html .microsite-theme .marketo-form .mktoForm [readonly].mktoTelField,
html .microsite-theme .marketo-form .mktoForm [readonly].mktoTextField,
html .microsite-theme .marketo-form .mktoForm select[readonly],
html .microsite-theme .marketo-form .mktoForm textarea[readonly],
html .moments-theme .marketo-form .mktoForm [readonly].mktoEmailField,
html .moments-theme .marketo-form .mktoForm [readonly].mktoTelField,
html .moments-theme .marketo-form .mktoForm [readonly].mktoTextField,
html .moments-theme .marketo-form .mktoForm select[readonly],
html .moments-theme .marketo-form .mktoForm textarea[readonly], .marketo-form .mktoForm select[readonly],
.marketo-form .mktoForm textarea[readonly], .marketo-form .mktoForm [readonly].mktoEmailField,
.marketo-form .mktoForm [readonly].mktoTelField,
.marketo-form .mktoForm [readonly].mktoTextField, .bmi-calculator input[readonly][type=text],
.bmi-calculator select[readonly], textarea[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control, .share-story-modal form input[type=date][type=text],
.share-story-modal form input[type=date][type=email],
.share-story-modal form input[type=date][type=tel], .marketo-form .mktoForm input[type=date].mktoEmailField,
.marketo-form .mktoForm input[type=date].mktoTelField,
.marketo-form .mktoForm input[type=date].mktoTextField, .bmi-calculator input[type=date][type=text],
input[type=time].form-control,
.share-story-modal form input[type=time][type=text],
.share-story-modal form input[type=time][type=email],
.share-story-modal form input[type=time][type=tel],
.marketo-form .mktoForm input[type=time].mktoEmailField,
.marketo-form .mktoForm input[type=time].mktoTelField,
.marketo-form .mktoForm input[type=time].mktoTextField,
.bmi-calculator input[type=time][type=text],
input[type=datetime-local].form-control,
.share-story-modal form input[type=datetime-local][type=text],
.share-story-modal form input[type=datetime-local][type=email],
.share-story-modal form input[type=datetime-local][type=tel],
.marketo-form .mktoForm input[type=datetime-local].mktoEmailField,
.marketo-form .mktoForm input[type=datetime-local].mktoTelField,
.marketo-form .mktoForm input[type=datetime-local].mktoTextField,
.bmi-calculator input[type=datetime-local][type=text],
input[type=month].form-control,
.share-story-modal form input[type=month][type=text],
.share-story-modal form input[type=month][type=email],
.share-story-modal form input[type=month][type=tel],
.marketo-form .mktoForm input[type=month].mktoEmailField,
.marketo-form .mktoForm input[type=month].mktoTelField,
.marketo-form .mktoForm input[type=month].mktoTextField,
.bmi-calculator input[type=month][type=text] {
  appearance: none;
}

select.form-control:-moz-focusring,
.share-story-modal form select:-moz-focusring,
html .microsite-theme .marketo-form .mktoForm select:-moz-focusring,
html .moments-theme .marketo-form .mktoForm select:-moz-focusring, .marketo-form .mktoForm select:-moz-focusring,
.bmi-calculator select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value,
.share-story-modal form select:focus::-ms-value,
html .microsite-theme .marketo-form .mktoForm select:focus::-ms-value,
html .moments-theme .marketo-form .mktoForm select:focus::-ms-value, .marketo-form .mktoForm select:focus::-ms-value,
.bmi-calculator select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.625rem + 1px);
  padding-bottom: calc(0.625rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.375;
}

.col-form-label-lg {
  padding-top: calc(0.625rem + 1px);
  padding-bottom: calc(0.625rem + 1px);
  font-size: 1rem;
  line-height: 1.375;
}

.col-form-label-sm {
  padding-top: calc(0.625rem + 1px);
  padding-bottom: calc(0.625rem + 1px);
  font-size: 1rem;
  line-height: 1.375;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.625rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.375;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.375em + 1.25rem + 2px);
  padding: 0.625rem 1rem;
  font-size: 1rem;
  line-height: 1.375;
  border-radius: 0.3125rem;
}

.form-control-lg {
  height: calc(1.375em + 1.25rem + 2px);
  padding: 0.625rem 1rem;
  font-size: 1rem;
  line-height: 1.375;
  border-radius: 0.3125rem;
}

select.form-control[size],
.share-story-modal form select[size],
html .microsite-theme .marketo-form .mktoForm select[size],
html .moments-theme .marketo-form .mktoForm select[size], .marketo-form .mktoForm select[size],
.bmi-calculator select[size], select.form-control[multiple],
.share-story-modal form select[multiple],
html .microsite-theme .marketo-form .mktoForm select[multiple],
html .moments-theme .marketo-form .mktoForm select[multiple], .marketo-form .mktoForm select[multiple],
.bmi-calculator select[multiple] {
  height: auto;
}

textarea.form-control,
.share-story-modal form textarea, html .microsite-theme .marketo-form .mktoForm textarea.mktoEmailField,
html .microsite-theme .marketo-form .mktoForm textarea.mktoTelField,
html .microsite-theme .marketo-form .mktoForm textarea.mktoTextField,
html .microsite-theme .marketo-form .mktoForm textarea,
html .moments-theme .marketo-form .mktoForm textarea.mktoEmailField,
html .moments-theme .marketo-form .mktoForm textarea.mktoTelField,
html .moments-theme .marketo-form .mktoForm textarea.mktoTextField,
html .moments-theme .marketo-form .mktoForm textarea,
.marketo-form .mktoForm textarea, .marketo-form .mktoForm textarea.mktoEmailField,
.marketo-form .mktoForm textarea.mktoTelField,
.marketo-form .mktoForm textarea.mktoTextField, textarea {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.3125rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .was-validated .share-story-modal form input[type=text]:valid, .share-story-modal form .was-validated input[type=text]:valid,
.was-validated .share-story-modal form input[type=email]:valid,
.share-story-modal form .was-validated input[type=email]:valid,
.was-validated .share-story-modal form input[type=tel]:valid,
.share-story-modal form .was-validated input[type=tel]:valid,
.was-validated .share-story-modal form select:valid,
.share-story-modal form .was-validated select:valid,
.was-validated .share-story-modal form textarea:valid,
.share-story-modal form .was-validated textarea:valid, .was-validated .marketo-form .mktoForm select:valid, .marketo-form .mktoForm .was-validated select:valid,
.was-validated .marketo-form .mktoForm textarea:valid,
.marketo-form .mktoForm .was-validated textarea:valid, .was-validated .marketo-form .mktoForm .mktoEmailField:valid, .marketo-form .mktoForm .was-validated .mktoEmailField:valid,
.was-validated .marketo-form .mktoForm .mktoTelField:valid,
.marketo-form .mktoForm .was-validated .mktoTelField:valid,
.was-validated .marketo-form .mktoForm .mktoTextField:valid,
.marketo-form .mktoForm .was-validated .mktoTextField:valid, .was-validated .bmi-calculator input[type=text]:valid, .bmi-calculator .was-validated input[type=text]:valid,
.was-validated .bmi-calculator select:valid,
.bmi-calculator .was-validated select:valid, .was-validated textarea:valid, .form-control.is-valid, .share-story-modal form input.is-valid[type=text],
.share-story-modal form input.is-valid[type=email],
.share-story-modal form input.is-valid[type=tel],
.share-story-modal form select.is-valid,
.share-story-modal form textarea.is-valid, html .microsite-theme .marketo-form .mktoForm .is-valid.mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .is-valid.mktoTelField,
html .microsite-theme .marketo-form .mktoForm .is-valid.mktoTextField,
html .microsite-theme .marketo-form .mktoForm select.is-valid,
html .microsite-theme .marketo-form .mktoForm textarea.is-valid,
html .moments-theme .marketo-form .mktoForm .is-valid.mktoEmailField,
html .moments-theme .marketo-form .mktoForm .is-valid.mktoTelField,
html .moments-theme .marketo-form .mktoForm .is-valid.mktoTextField,
html .moments-theme .marketo-form .mktoForm select.is-valid,
html .moments-theme .marketo-form .mktoForm textarea.is-valid, .marketo-form .mktoForm select.is-valid,
.marketo-form .mktoForm textarea.is-valid, .marketo-form .mktoForm .is-valid.mktoEmailField,
.marketo-form .mktoForm .is-valid.mktoTelField,
.marketo-form .mktoForm .is-valid.mktoTextField, .bmi-calculator input.is-valid[type=text],
.bmi-calculator select.is-valid, textarea.is-valid {
  border-color: #28a745;
  padding-right: calc(1.375em + 1.25rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.34375em + 0.3125rem) center;
  background-size: calc(0.6875em + 0.625rem) calc(0.6875em + 0.625rem);
}
.was-validated .form-control:valid:focus, .was-validated .share-story-modal form input[type=text]:valid:focus, .share-story-modal form .was-validated input[type=text]:valid:focus,
.was-validated .share-story-modal form input[type=email]:valid:focus,
.share-story-modal form .was-validated input[type=email]:valid:focus,
.was-validated .share-story-modal form input[type=tel]:valid:focus,
.share-story-modal form .was-validated input[type=tel]:valid:focus,
.was-validated .share-story-modal form select:valid:focus,
.share-story-modal form .was-validated select:valid:focus,
.was-validated html .microsite-theme .marketo-form .mktoForm textarea:valid:focus,
html .microsite-theme .marketo-form .mktoForm .was-validated textarea:valid:focus,
.was-validated html .moments-theme .marketo-form .mktoForm textarea:valid:focus,
html .moments-theme .marketo-form .mktoForm .was-validated textarea:valid:focus, .was-validated .marketo-form .mktoForm select:valid:focus, .marketo-form .mktoForm .was-validated select:valid:focus, .was-validated .marketo-form .mktoForm .mktoEmailField:valid:focus, .marketo-form .mktoForm .was-validated .mktoEmailField:valid:focus,
.was-validated .marketo-form .mktoForm .mktoTelField:valid:focus,
.marketo-form .mktoForm .was-validated .mktoTelField:valid:focus,
.was-validated .marketo-form .mktoForm .mktoTextField:valid:focus,
.marketo-form .mktoForm .was-validated .mktoTextField:valid:focus, .was-validated .bmi-calculator input[type=text]:valid:focus, .bmi-calculator .was-validated input[type=text]:valid:focus,
.was-validated .bmi-calculator select:valid:focus,
.bmi-calculator .was-validated select:valid:focus, .was-validated textarea:valid:focus, .form-control.is-valid:focus, .share-story-modal form input.is-valid[type=text]:focus,
.share-story-modal form input.is-valid[type=email]:focus,
.share-story-modal form input.is-valid[type=tel]:focus,
.share-story-modal form select.is-valid:focus,
.share-story-modal form textarea.is-valid:focus, .marketo-form .mktoForm select.is-valid:focus,
.marketo-form .mktoForm textarea.is-valid:focus, .marketo-form .mktoForm .is-valid.mktoEmailField:focus,
.marketo-form .mktoForm .is-valid.mktoTelField:focus,
.marketo-form .mktoForm .is-valid.mktoTextField:focus, .bmi-calculator input.is-valid[type=text]:focus,
.bmi-calculator select.is-valid:focus, textarea.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid,
.was-validated .share-story-modal form select:valid,
.share-story-modal form .was-validated select:valid, .was-validated .marketo-form .mktoForm select:valid, .marketo-form .mktoForm .was-validated select:valid,
.was-validated .bmi-calculator select:valid,
.bmi-calculator .was-validated select:valid, select.form-control.is-valid,
.share-story-modal form select.is-valid,
html .microsite-theme .marketo-form .mktoForm select.is-valid,
html .moments-theme .marketo-form .mktoForm select.is-valid, .marketo-form .mktoForm select.is-valid,
.bmi-calculator select.is-valid {
  padding-right: 4rem !important;
  background-position: right 2rem center;
}

.was-validated textarea.form-control:valid,
.was-validated .share-story-modal form textarea:valid,
.share-story-modal form .was-validated textarea:valid,
.was-validated .marketo-form .mktoForm textarea:valid,
.marketo-form .mktoForm .was-validated textarea:valid, .was-validated textarea:valid, textarea.form-control.is-valid,
.share-story-modal form textarea.is-valid,
html .microsite-theme .marketo-form .mktoForm textarea.is-valid,
html .moments-theme .marketo-form .mktoForm textarea.is-valid,
.marketo-form .mktoForm textarea.is-valid, textarea.is-valid {
  padding-right: calc(1.375em + 1.25rem);
  background-position: top calc(0.34375em + 0.3125rem) right calc(0.34375em + 0.3125rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.9375rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 2rem/calc(0.6875em + 0.625rem) calc(0.6875em + 0.625rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.3125rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .was-validated .share-story-modal form input[type=text]:invalid, .share-story-modal form .was-validated input[type=text]:invalid,
.was-validated .share-story-modal form input[type=email]:invalid,
.share-story-modal form .was-validated input[type=email]:invalid,
.was-validated .share-story-modal form input[type=tel]:invalid,
.share-story-modal form .was-validated input[type=tel]:invalid,
.was-validated .share-story-modal form select:invalid,
.share-story-modal form .was-validated select:invalid,
.was-validated .share-story-modal form textarea:invalid,
.share-story-modal form .was-validated textarea:invalid, .was-validated .marketo-form .mktoForm select:invalid, .marketo-form .mktoForm .was-validated select:invalid,
.was-validated .marketo-form .mktoForm textarea:invalid,
.marketo-form .mktoForm .was-validated textarea:invalid, .was-validated .marketo-form .mktoForm .mktoEmailField:invalid, .marketo-form .mktoForm .was-validated .mktoEmailField:invalid,
.was-validated .marketo-form .mktoForm .mktoTelField:invalid,
.marketo-form .mktoForm .was-validated .mktoTelField:invalid,
.was-validated .marketo-form .mktoForm .mktoTextField:invalid,
.marketo-form .mktoForm .was-validated .mktoTextField:invalid, .was-validated .bmi-calculator input[type=text]:invalid, .bmi-calculator .was-validated input[type=text]:invalid,
.was-validated .bmi-calculator select:invalid,
.bmi-calculator .was-validated select:invalid, .was-validated textarea:invalid, .form-control.is-invalid, .share-story-modal form input.is-invalid[type=text],
.share-story-modal form input.is-invalid[type=email],
.share-story-modal form input.is-invalid[type=tel],
.share-story-modal form select.is-invalid,
.share-story-modal form textarea.is-invalid, html .microsite-theme .marketo-form .mktoForm .is-invalid.mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .is-invalid.mktoTelField,
html .microsite-theme .marketo-form .mktoForm .is-invalid.mktoTextField,
html .microsite-theme .marketo-form .mktoForm select.is-invalid,
html .microsite-theme .marketo-form .mktoForm textarea.is-invalid,
html .moments-theme .marketo-form .mktoForm .is-invalid.mktoEmailField,
html .moments-theme .marketo-form .mktoForm .is-invalid.mktoTelField,
html .moments-theme .marketo-form .mktoForm .is-invalid.mktoTextField,
html .moments-theme .marketo-form .mktoForm select.is-invalid,
html .moments-theme .marketo-form .mktoForm textarea.is-invalid, .marketo-form .mktoForm select.is-invalid,
.marketo-form .mktoForm textarea.is-invalid, .marketo-form .mktoForm .is-invalid.mktoEmailField,
.marketo-form .mktoForm .is-invalid.mktoTelField,
.marketo-form .mktoForm .is-invalid.mktoTextField, .bmi-calculator input.is-invalid[type=text],
.bmi-calculator select.is-invalid, textarea.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.375em + 1.25rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.34375em + 0.3125rem) center;
  background-size: calc(0.6875em + 0.625rem) calc(0.6875em + 0.625rem);
}
.was-validated .form-control:invalid:focus, .was-validated .share-story-modal form input[type=text]:invalid:focus, .share-story-modal form .was-validated input[type=text]:invalid:focus,
.was-validated .share-story-modal form input[type=email]:invalid:focus,
.share-story-modal form .was-validated input[type=email]:invalid:focus,
.was-validated .share-story-modal form input[type=tel]:invalid:focus,
.share-story-modal form .was-validated input[type=tel]:invalid:focus,
.was-validated .share-story-modal form select:invalid:focus,
.share-story-modal form .was-validated select:invalid:focus,
.was-validated html .microsite-theme .marketo-form .mktoForm textarea:invalid:focus,
html .microsite-theme .marketo-form .mktoForm .was-validated textarea:invalid:focus,
.was-validated html .moments-theme .marketo-form .mktoForm textarea:invalid:focus,
html .moments-theme .marketo-form .mktoForm .was-validated textarea:invalid:focus, .was-validated .marketo-form .mktoForm select:invalid:focus, .marketo-form .mktoForm .was-validated select:invalid:focus, .was-validated .marketo-form .mktoForm .mktoEmailField:invalid:focus, .marketo-form .mktoForm .was-validated .mktoEmailField:invalid:focus,
.was-validated .marketo-form .mktoForm .mktoTelField:invalid:focus,
.marketo-form .mktoForm .was-validated .mktoTelField:invalid:focus,
.was-validated .marketo-form .mktoForm .mktoTextField:invalid:focus,
.marketo-form .mktoForm .was-validated .mktoTextField:invalid:focus, .was-validated .bmi-calculator input[type=text]:invalid:focus, .bmi-calculator .was-validated input[type=text]:invalid:focus,
.was-validated .bmi-calculator select:invalid:focus,
.bmi-calculator .was-validated select:invalid:focus, .was-validated textarea:invalid:focus, .form-control.is-invalid:focus, .share-story-modal form input.is-invalid[type=text]:focus,
.share-story-modal form input.is-invalid[type=email]:focus,
.share-story-modal form input.is-invalid[type=tel]:focus,
.share-story-modal form select.is-invalid:focus,
.share-story-modal form textarea.is-invalid:focus, .marketo-form .mktoForm select.is-invalid:focus,
.marketo-form .mktoForm textarea.is-invalid:focus, .marketo-form .mktoForm .is-invalid.mktoEmailField:focus,
.marketo-form .mktoForm .is-invalid.mktoTelField:focus,
.marketo-form .mktoForm .is-invalid.mktoTextField:focus, .bmi-calculator input.is-invalid[type=text]:focus,
.bmi-calculator select.is-invalid:focus, textarea.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid,
.was-validated .share-story-modal form select:invalid,
.share-story-modal form .was-validated select:invalid, .was-validated .marketo-form .mktoForm select:invalid, .marketo-form .mktoForm .was-validated select:invalid,
.was-validated .bmi-calculator select:invalid,
.bmi-calculator .was-validated select:invalid, select.form-control.is-invalid,
.share-story-modal form select.is-invalid,
html .microsite-theme .marketo-form .mktoForm select.is-invalid,
html .moments-theme .marketo-form .mktoForm select.is-invalid, .marketo-form .mktoForm select.is-invalid,
.bmi-calculator select.is-invalid {
  padding-right: 4rem !important;
  background-position: right 2rem center;
}

.was-validated textarea.form-control:invalid,
.was-validated .share-story-modal form textarea:invalid,
.share-story-modal form .was-validated textarea:invalid,
.was-validated .marketo-form .mktoForm textarea:invalid,
.marketo-form .mktoForm .was-validated textarea:invalid, .was-validated textarea:invalid, textarea.form-control.is-invalid,
.share-story-modal form textarea.is-invalid,
html .microsite-theme .marketo-form .mktoForm textarea.is-invalid,
html .moments-theme .marketo-form .mktoForm textarea.is-invalid,
.marketo-form .mktoForm textarea.is-invalid, textarea.is-invalid {
  padding-right: calc(1.375em + 1.25rem);
  background-position: top calc(0.34375em + 0.3125rem) right calc(0.34375em + 0.3125rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.9375rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 2rem/calc(0.6875em + 0.625rem) calc(0.6875em + 0.625rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control, .form-inline .share-story-modal form input[type=text], .share-story-modal form .form-inline input[type=text],
.form-inline .share-story-modal form input[type=email],
.share-story-modal form .form-inline input[type=email],
.form-inline .share-story-modal form input[type=tel],
.share-story-modal form .form-inline input[type=tel],
.form-inline .share-story-modal form select,
.share-story-modal form .form-inline select,
.form-inline .share-story-modal form textarea,
.share-story-modal form .form-inline textarea, .form-inline html .microsite-theme .marketo-form .mktoForm .mktoEmailField, html .microsite-theme .marketo-form .mktoForm .form-inline .mktoEmailField,
.form-inline html .microsite-theme .marketo-form .mktoForm .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .form-inline .mktoTelField,
.form-inline html .microsite-theme .marketo-form .mktoForm .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .form-inline .mktoTextField,
.form-inline html .microsite-theme .marketo-form .mktoForm select,
html .microsite-theme .marketo-form .mktoForm .form-inline select,
.form-inline html .microsite-theme .marketo-form .mktoForm textarea,
html .microsite-theme .marketo-form .mktoForm .form-inline textarea,
.form-inline html .moments-theme .marketo-form .mktoForm .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .form-inline .mktoEmailField,
.form-inline html .moments-theme .marketo-form .mktoForm .mktoTelField,
html .moments-theme .marketo-form .mktoForm .form-inline .mktoTelField,
.form-inline html .moments-theme .marketo-form .mktoForm .mktoTextField,
html .moments-theme .marketo-form .mktoForm .form-inline .mktoTextField,
.form-inline html .moments-theme .marketo-form .mktoForm select,
html .moments-theme .marketo-form .mktoForm .form-inline select,
.form-inline html .moments-theme .marketo-form .mktoForm textarea,
html .moments-theme .marketo-form .mktoForm .form-inline textarea, .form-inline .marketo-form .mktoForm select, .marketo-form .mktoForm .form-inline select,
.form-inline .marketo-form .mktoForm textarea,
.marketo-form .mktoForm .form-inline textarea, .form-inline .marketo-form .mktoForm .mktoEmailField, .marketo-form .mktoForm .form-inline .mktoEmailField,
.form-inline .marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .form-inline .mktoTelField,
.form-inline .marketo-form .mktoForm .mktoTextField,
.marketo-form .mktoForm .form-inline .mktoTextField, .form-inline .bmi-calculator input[type=text], .bmi-calculator .form-inline input[type=text],
.form-inline .bmi-calculator select,
.bmi-calculator .form-inline select, .form-inline textarea {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn, .marketo-form .mktoForm .mktoButton {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.75rem 3.375rem;
  font-size: 1rem;
  line-height: 1.375;
  border-radius: 0.3125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn, .marketo-form .mktoForm .mktoButton {
    transition: none;
  }
}
.btn:hover, .marketo-form .mktoForm .mktoButton:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .marketo-form .mktoForm .mktoButton:focus, .btn.focus, .marketo-form .mktoForm .focus.mktoButton {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .marketo-form .mktoForm .disabled.mktoButton, .btn:disabled, .marketo-form .mktoForm .mktoButton:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled), .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled, .marketo-form .mktoForm a.disabled.mktoButton,
fieldset:disabled a.btn,
fieldset:disabled .marketo-form .mktoForm a.mktoButton,
.marketo-form .mktoForm fieldset:disabled a.mktoButton {
  pointer-events: none;
}

.btn-primary, .marketo-form .mktoForm .mktoButton {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover, .marketo-form .mktoForm .mktoButton:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .marketo-form .mktoForm .mktoButton:focus, .btn-primary.focus, .marketo-form .mktoForm .focus.mktoButton {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .marketo-form .mktoForm .disabled.mktoButton, .btn-primary:disabled, .marketo-form .mktoForm .mktoButton:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle, .marketo-form .mktoForm .show > .dropdown-toggle.mktoButton {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus, .marketo-form .mktoForm .show > .dropdown-toggle.mktoButton:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn, .marketo-form .mktoForm .btn-group-lg > .mktoButton {
  padding: 0.875rem 3.375rem;
  font-size: 1rem;
  line-height: 1.375;
  border-radius: 0.3125rem;
}

.btn-sm, .btn-group-sm > .btn, .marketo-form .mktoForm .btn-group-sm > .mktoButton {
  padding: 0.4375rem 3.375rem;
  font-size: 1rem;
  line-height: 1.375;
  border-radius: 0.3125rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.8125rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn, .marketo-form .mktoForm .btn-group > .mktoButton,
.btn-group-vertical > .btn,
.marketo-form .mktoForm .btn-group-vertical > .mktoButton {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover, .marketo-form .mktoForm .btn-group > .mktoButton:hover,
.btn-group-vertical > .btn:hover,
.marketo-form .mktoForm .btn-group-vertical > .mktoButton:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .marketo-form .mktoForm .btn-group > .mktoButton:focus, .btn-group > .btn:active, .marketo-form .mktoForm .btn-group > .mktoButton:active, .btn-group > .btn.active, .marketo-form .mktoForm .btn-group > .active.mktoButton,
.btn-group-vertical > .btn:focus,
.marketo-form .mktoForm .btn-group-vertical > .mktoButton:focus,
.btn-group-vertical > .btn:active,
.marketo-form .mktoForm .btn-group-vertical > .mktoButton:active,
.btn-group-vertical > .btn.active,
.marketo-form .mktoForm .btn-group-vertical > .active.mktoButton {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child), .marketo-form .mktoForm .btn-group > .mktoButton:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .marketo-form .mktoForm .btn-group > .mktoButton:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.marketo-form .mktoForm .btn-group > .btn-group:not(:last-child) > .mktoButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child), .marketo-form .mktoForm .btn-group > .mktoButton:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.marketo-form .mktoForm .btn-group > .btn-group:not(:first-child) > .mktoButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 2.53125rem;
  padding-left: 2.53125rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .marketo-form .mktoForm .btn-group-sm > .mktoButton + .dropdown-toggle-split {
  padding-right: 2.53125rem;
  padding-left: 2.53125rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .marketo-form .mktoForm .btn-group-lg > .mktoButton + .dropdown-toggle-split {
  padding-right: 2.53125rem;
  padding-left: 2.53125rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn, .marketo-form .mktoForm .btn-group-vertical > .mktoButton,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child), .marketo-form .mktoForm .btn-group-vertical > .mktoButton:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .marketo-form .mktoForm .btn-group-vertical > .mktoButton:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.marketo-form .mktoForm .btn-group-vertical > .btn-group:not(:last-child) > .mktoButton {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child), .marketo-form .mktoForm .btn-group-vertical > .mktoButton:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.marketo-form .mktoForm .btn-group-vertical > .btn-group:not(:first-child) > .mktoButton {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .marketo-form .mktoForm .btn-group-toggle > .mktoButton,
.btn-group-toggle > .btn-group > .btn,
.marketo-form .mktoForm .btn-group-toggle > .btn-group > .mktoButton {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio], .marketo-form .mktoForm .btn-group-toggle > .mktoButton input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.marketo-form .mktoForm .btn-group-toggle > .mktoButton input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control, .share-story-modal form .input-group > input[type=text],
.share-story-modal form .input-group > input[type=email],
.share-story-modal form .input-group > input[type=tel],
.share-story-modal form .input-group > select,
.share-story-modal form .input-group > textarea, html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > select,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > select,
html .moments-theme .marketo-form .mktoForm .input-group > textarea, .marketo-form .mktoForm .input-group > select,
.marketo-form .mktoForm .input-group > textarea, .marketo-form .mktoForm .input-group > .mktoEmailField,
.marketo-form .mktoForm .input-group > .mktoTelField,
.marketo-form .mktoForm .input-group > .mktoTextField, .bmi-calculator .input-group > input[type=text],
.bmi-calculator .input-group > select, .input-group > textarea,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control, .share-story-modal form .input-group > input[type=text] + .form-control,
.share-story-modal form .input-group > input[type=email] + .form-control,
.share-story-modal form .input-group > input[type=tel] + .form-control,
.share-story-modal form .input-group > select + .form-control,
.share-story-modal form .input-group > textarea + .form-control, .share-story-modal form .input-group > .form-control + input[type=text], .share-story-modal form .input-group > input[type=text] + input[type=text],
.share-story-modal form .input-group > input[type=email] + input[type=text],
.share-story-modal form .input-group > input[type=tel] + input[type=text],
.share-story-modal form .input-group > select + input[type=text],
.share-story-modal form .input-group > textarea + input[type=text],
.share-story-modal form .input-group > .form-control + input[type=email],
.share-story-modal form .input-group > input[type=text] + input[type=email],
.share-story-modal form .input-group > input[type=email] + input[type=email],
.share-story-modal form .input-group > input[type=tel] + input[type=email],
.share-story-modal form .input-group > select + input[type=email],
.share-story-modal form .input-group > textarea + input[type=email],
.share-story-modal form .input-group > .form-control + input[type=tel],
.share-story-modal form .input-group > input[type=text] + input[type=tel],
.share-story-modal form .input-group > input[type=email] + input[type=tel],
.share-story-modal form .input-group > input[type=tel] + input[type=tel],
.share-story-modal form .input-group > select + input[type=tel],
.share-story-modal form .input-group > textarea + input[type=tel],
.share-story-modal form .input-group > .form-control + select,
.share-story-modal form .input-group > input[type=text] + select,
.share-story-modal form .input-group > input[type=email] + select,
.share-story-modal form .input-group > input[type=tel] + select,
.share-story-modal form .input-group > select + select,
.share-story-modal form .input-group > textarea + select,
.share-story-modal form .input-group > .form-control + textarea,
.share-story-modal form .input-group > input[type=text] + textarea,
.share-story-modal form .input-group > input[type=email] + textarea,
.share-story-modal form .input-group > input[type=tel] + textarea,
.share-story-modal form .input-group > select + textarea,
.share-story-modal form .input-group > textarea + textarea, html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .form-control, html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoEmailField + input[type=text], .share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + input[type=text],
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoEmailField + input[type=email],
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + input[type=email],
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoEmailField + input[type=tel],
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + input[type=tel],
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoEmailField + select,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + select,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoEmailField + textarea,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + .form-control,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTelField + input[type=text],
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + input[type=text],
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTelField + input[type=email],
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + input[type=email],
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTelField + input[type=tel],
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + input[type=tel],
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTelField + select,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + select,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTelField + textarea,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + .form-control,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTextField + input[type=text],
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + input[type=text],
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTextField + input[type=email],
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + input[type=email],
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTextField + input[type=tel],
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + input[type=tel],
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTextField + select,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + select,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTextField + textarea,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > select + .form-control,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + input[type=text],
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > select + input[type=text],
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + input[type=email],
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > select + input[type=email],
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + input[type=tel],
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > select + input[type=tel],
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + select,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > select + select,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + textarea,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > select + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + .form-control,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + input[type=text],
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > textarea + input[type=text],
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + input[type=email],
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > textarea + input[type=email],
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + input[type=tel],
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > textarea + input[type=tel],
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + select,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > textarea + select,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + textarea,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > textarea + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .form-control,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoEmailField + input[type=text],
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + input[type=text],
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoEmailField + input[type=email],
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + input[type=email],
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoEmailField + input[type=tel],
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + input[type=tel],
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoEmailField + select,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + select,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoEmailField + textarea,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + .form-control,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTelField + input[type=text],
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + input[type=text],
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTelField + input[type=email],
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + input[type=email],
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTelField + input[type=tel],
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + input[type=tel],
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTelField + select,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + select,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTelField + textarea,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + .form-control,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTextField + input[type=text],
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + input[type=text],
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTextField + input[type=email],
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + input[type=email],
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTextField + input[type=tel],
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + input[type=tel],
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTextField + select,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + select,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > .mktoTextField + textarea,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > select + .form-control,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + input[type=text],
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > select + input[type=text],
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + input[type=email],
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > select + input[type=email],
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + input[type=tel],
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > select + input[type=tel],
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + select,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > select + select,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + textarea,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > select + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + .form-control,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + input[type=text],
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > textarea + input[type=text],
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + input[type=email],
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > textarea + input[type=email],
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + input[type=tel],
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > textarea + input[type=tel],
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + select,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > textarea + select,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + textarea,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > textarea + textarea, html .microsite-theme .marketo-form .mktoForm .input-group > .form-control + .mktoEmailField, html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=text] + .mktoEmailField, .share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > input[type=text] + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=email] + .mktoEmailField,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > input[type=email] + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=tel] + .mktoEmailField,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > input[type=tel] + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + .mktoEmailField,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + .mktoEmailField,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoEmailField, html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoEmailField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoEmailField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoEmailField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoEmailField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoEmailField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoEmailField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoEmailField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoEmailField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > select + .mktoEmailField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoEmailField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group > .form-control + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=text] + .mktoTelField,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > input[type=text] + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=email] + .mktoTelField,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > input[type=email] + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=tel] + .mktoTelField,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > input[type=tel] + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + .mktoTelField,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + .mktoTelField,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoTelField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTelField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTelField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTelField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTelField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTelField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTelField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoTelField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > select + .mktoTelField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoTelField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > .form-control + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=text] + .mktoTextField,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > input[type=text] + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=email] + .mktoTextField,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > input[type=email] + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=tel] + .mktoTextField,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > input[type=tel] + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + .mktoTextField,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + .mktoTextField,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoTextField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTextField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTextField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTextField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTextField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTextField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTextField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoTextField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > select + .mktoTextField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoTextField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > .form-control + select,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=text] + select,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > input[type=text] + select,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=email] + select,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > input[type=email] + select,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=tel] + select,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > input[type=tel] + select,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + select,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > select + select,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + select,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > textarea + select,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + select,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + select,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + select,
html .microsite-theme .marketo-form .mktoForm .input-group > select + select,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + select,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + select,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + select,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + select,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + select,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + select,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + select,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > select + select,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > select + select,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > textarea + select,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > textarea + select,
html .microsite-theme .marketo-form .mktoForm .input-group > .form-control + textarea,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=text] + textarea,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > input[type=text] + textarea,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=email] + textarea,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > input[type=email] + textarea,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=tel] + textarea,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > input[type=tel] + textarea,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + textarea,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > select + textarea,
html .microsite-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + textarea,
.share-story-modal form html .microsite-theme .marketo-form .mktoForm .input-group > textarea + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > select + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + textarea,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + textarea,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + textarea,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + textarea,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + textarea,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + textarea,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + textarea,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > select + textarea,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > select + textarea,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > textarea + textarea,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > textarea + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .form-control + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=text] + .mktoEmailField,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > input[type=text] + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=email] + .mktoEmailField,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > input[type=email] + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=tel] + .mktoEmailField,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > input[type=tel] + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + .mktoEmailField,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > select + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + .mktoEmailField,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoEmailField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoEmailField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoEmailField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoEmailField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoEmailField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoEmailField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoEmailField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > select + .mktoEmailField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoEmailField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoEmailField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > select + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > .form-control + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=text] + .mktoTelField,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > input[type=text] + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=email] + .mktoTelField,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > input[type=email] + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=tel] + .mktoTelField,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > input[type=tel] + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + .mktoTelField,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > select + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + .mktoTelField,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoTelField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTelField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTelField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTelField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTelField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTelField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTelField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > select + .mktoTelField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoTelField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoTelField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > select + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > .form-control + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=text] + .mktoTextField,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > input[type=text] + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=email] + .mktoTextField,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > input[type=email] + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=tel] + .mktoTextField,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > input[type=tel] + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + .mktoTextField,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > select + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + .mktoTextField,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoTextField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTextField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTextField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTextField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTextField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTextField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTextField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > select + .mktoTextField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoTextField,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoTextField,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > select + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > .form-control + select,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=text] + select,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > input[type=text] + select,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=email] + select,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > input[type=email] + select,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=tel] + select,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > input[type=tel] + select,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + select,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > select + select,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + select,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > textarea + select,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + select,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + select,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + select,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + select,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + select,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + select,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > select + select,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > select + select,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > textarea + select,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > textarea + select,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + select,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + select,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + select,
html .moments-theme .marketo-form .mktoForm .input-group > select + select,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + select,
html .moments-theme .marketo-form .mktoForm .input-group > .form-control + textarea,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=text] + textarea,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > input[type=text] + textarea,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=email] + textarea,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > input[type=email] + textarea,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > input[type=tel] + textarea,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > input[type=tel] + textarea,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > select + textarea,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > select + textarea,
html .moments-theme .marketo-form .mktoForm .share-story-modal form .input-group > textarea + textarea,
.share-story-modal form html .moments-theme .marketo-form .mktoForm .input-group > textarea + textarea,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + textarea,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + textarea,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + textarea,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + textarea,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + textarea,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + textarea,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > select + textarea,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > select + textarea,
html .microsite-theme .moments-theme .marketo-form .mktoForm .input-group > textarea + textarea,
html .moments-theme .microsite-theme .marketo-form .mktoForm .input-group > textarea + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > select + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + textarea, .marketo-form .mktoForm .input-group > select + .form-control, .marketo-form .mktoForm .share-story-modal form .input-group > select + input[type=text], .share-story-modal form .marketo-form .mktoForm .input-group > select + input[type=text],
.marketo-form .mktoForm .share-story-modal form .input-group > select + input[type=email],
.share-story-modal form .marketo-form .mktoForm .input-group > select + input[type=email],
.marketo-form .mktoForm .share-story-modal form .input-group > select + input[type=tel],
.share-story-modal form .marketo-form .mktoForm .input-group > select + input[type=tel],
.marketo-form .mktoForm .share-story-modal form .input-group > select + select,
.share-story-modal form .marketo-form .mktoForm .input-group > select + select,
.marketo-form .mktoForm .share-story-modal form .input-group > select + textarea,
.share-story-modal form .marketo-form .mktoForm .input-group > select + textarea, html .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > select + select,
html .microsite-theme .marketo-form .mktoForm .input-group > select + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > select + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > select + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > select + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > select + select,
html .moments-theme .marketo-form .mktoForm .input-group > select + textarea,
.marketo-form .mktoForm .input-group > textarea + .form-control,
.marketo-form .mktoForm .share-story-modal form .input-group > textarea + input[type=text],
.share-story-modal form .marketo-form .mktoForm .input-group > textarea + input[type=text],
.marketo-form .mktoForm .share-story-modal form .input-group > textarea + input[type=email],
.share-story-modal form .marketo-form .mktoForm .input-group > textarea + input[type=email],
.marketo-form .mktoForm .share-story-modal form .input-group > textarea + input[type=tel],
.share-story-modal form .marketo-form .mktoForm .input-group > textarea + input[type=tel],
.marketo-form .mktoForm .share-story-modal form .input-group > textarea + select,
.share-story-modal form .marketo-form .mktoForm .input-group > textarea + select,
.marketo-form .mktoForm .share-story-modal form .input-group > textarea + textarea,
.share-story-modal form .marketo-form .mktoForm .input-group > textarea + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + select,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + select,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + textarea, .marketo-form .mktoForm .input-group > .form-control + select, .marketo-form .mktoForm .share-story-modal form .input-group > input[type=text] + select, .share-story-modal form .marketo-form .mktoForm .input-group > input[type=text] + select,
.marketo-form .mktoForm .share-story-modal form .input-group > input[type=email] + select,
.share-story-modal form .marketo-form .mktoForm .input-group > input[type=email] + select,
.marketo-form .mktoForm .share-story-modal form .input-group > input[type=tel] + select,
.share-story-modal form .marketo-form .mktoForm .input-group > input[type=tel] + select,
.marketo-form .mktoForm .share-story-modal form .input-group > select + select,
.share-story-modal form .marketo-form .mktoForm .input-group > select + select,
.marketo-form .mktoForm .share-story-modal form .input-group > textarea + select,
.share-story-modal form .marketo-form .mktoForm .input-group > textarea + select, html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + select,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + select,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + select,
html .microsite-theme .marketo-form .mktoForm .input-group > select + select,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + select,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + select,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + select,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + select,
html .moments-theme .marketo-form .mktoForm .input-group > select + select,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + select, .marketo-form .mktoForm .input-group > select + select,
.marketo-form .mktoForm .input-group > textarea + select,
.marketo-form .mktoForm .input-group > .form-control + textarea,
.marketo-form .mktoForm .share-story-modal form .input-group > input[type=text] + textarea,
.share-story-modal form .marketo-form .mktoForm .input-group > input[type=text] + textarea,
.marketo-form .mktoForm .share-story-modal form .input-group > input[type=email] + textarea,
.share-story-modal form .marketo-form .mktoForm .input-group > input[type=email] + textarea,
.marketo-form .mktoForm .share-story-modal form .input-group > input[type=tel] + textarea,
.share-story-modal form .marketo-form .mktoForm .input-group > input[type=tel] + textarea,
.marketo-form .mktoForm .share-story-modal form .input-group > select + textarea,
.share-story-modal form .marketo-form .mktoForm .input-group > select + textarea,
.marketo-form .mktoForm .share-story-modal form .input-group > textarea + textarea,
.share-story-modal form .marketo-form .mktoForm .input-group > textarea + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > select + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > select + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + textarea,
.marketo-form .mktoForm .input-group > select + textarea,
.marketo-form .mktoForm .input-group > textarea + textarea, .marketo-form .mktoForm .input-group > .mktoEmailField + .form-control, .marketo-form .mktoForm .share-story-modal form .input-group > .mktoEmailField + input[type=text], .share-story-modal form .marketo-form .mktoForm .input-group > .mktoEmailField + input[type=text],
.marketo-form .mktoForm .share-story-modal form .input-group > .mktoEmailField + input[type=email],
.share-story-modal form .marketo-form .mktoForm .input-group > .mktoEmailField + input[type=email],
.marketo-form .mktoForm .share-story-modal form .input-group > .mktoEmailField + input[type=tel],
.share-story-modal form .marketo-form .mktoForm .input-group > .mktoEmailField + input[type=tel],
.marketo-form .mktoForm .share-story-modal form .input-group > .mktoEmailField + select,
.share-story-modal form .marketo-form .mktoForm .input-group > .mktoEmailField + select,
.marketo-form .mktoForm .share-story-modal form .input-group > .mktoEmailField + textarea,
.share-story-modal form .marketo-form .mktoForm .input-group > .mktoEmailField + textarea, html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + select,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + select,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + textarea, .marketo-form .mktoForm .input-group > .mktoEmailField + select,
.marketo-form .mktoForm .input-group > .mktoEmailField + textarea,
.marketo-form .mktoForm .input-group > .mktoTelField + .form-control,
.marketo-form .mktoForm .share-story-modal form .input-group > .mktoTelField + input[type=text],
.share-story-modal form .marketo-form .mktoForm .input-group > .mktoTelField + input[type=text],
.marketo-form .mktoForm .share-story-modal form .input-group > .mktoTelField + input[type=email],
.share-story-modal form .marketo-form .mktoForm .input-group > .mktoTelField + input[type=email],
.marketo-form .mktoForm .share-story-modal form .input-group > .mktoTelField + input[type=tel],
.share-story-modal form .marketo-form .mktoForm .input-group > .mktoTelField + input[type=tel],
.marketo-form .mktoForm .share-story-modal form .input-group > .mktoTelField + select,
.share-story-modal form .marketo-form .mktoForm .input-group > .mktoTelField + select,
.marketo-form .mktoForm .share-story-modal form .input-group > .mktoTelField + textarea,
.share-story-modal form .marketo-form .mktoForm .input-group > .mktoTelField + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + select,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + select,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + textarea,
.marketo-form .mktoForm .input-group > .mktoTelField + select,
.marketo-form .mktoForm .input-group > .mktoTelField + textarea,
.marketo-form .mktoForm .input-group > .mktoTextField + .form-control,
.marketo-form .mktoForm .share-story-modal form .input-group > .mktoTextField + input[type=text],
.share-story-modal form .marketo-form .mktoForm .input-group > .mktoTextField + input[type=text],
.marketo-form .mktoForm .share-story-modal form .input-group > .mktoTextField + input[type=email],
.share-story-modal form .marketo-form .mktoForm .input-group > .mktoTextField + input[type=email],
.marketo-form .mktoForm .share-story-modal form .input-group > .mktoTextField + input[type=tel],
.share-story-modal form .marketo-form .mktoForm .input-group > .mktoTextField + input[type=tel],
.marketo-form .mktoForm .share-story-modal form .input-group > .mktoTextField + select,
.share-story-modal form .marketo-form .mktoForm .input-group > .mktoTextField + select,
.marketo-form .mktoForm .share-story-modal form .input-group > .mktoTextField + textarea,
.share-story-modal form .marketo-form .mktoForm .input-group > .mktoTextField + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + select,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + select,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + textarea,
.marketo-form .mktoForm .input-group > .mktoTextField + select,
.marketo-form .mktoForm .input-group > .mktoTextField + textarea, .marketo-form .mktoForm .input-group > .form-control + .mktoEmailField, .marketo-form .mktoForm .share-story-modal form .input-group > input[type=text] + .mktoEmailField, .share-story-modal form .marketo-form .mktoForm .input-group > input[type=text] + .mktoEmailField,
.marketo-form .mktoForm .share-story-modal form .input-group > input[type=email] + .mktoEmailField,
.share-story-modal form .marketo-form .mktoForm .input-group > input[type=email] + .mktoEmailField,
.marketo-form .mktoForm .share-story-modal form .input-group > input[type=tel] + .mktoEmailField,
.share-story-modal form .marketo-form .mktoForm .input-group > input[type=tel] + .mktoEmailField,
.marketo-form .mktoForm .share-story-modal form .input-group > select + .mktoEmailField,
.share-story-modal form .marketo-form .mktoForm .input-group > select + .mktoEmailField,
.marketo-form .mktoForm .share-story-modal form .input-group > textarea + .mktoEmailField,
.share-story-modal form .marketo-form .mktoForm .input-group > textarea + .mktoEmailField, html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > select + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoEmailField, .marketo-form .mktoForm .input-group > select + .mktoEmailField,
.marketo-form .mktoForm .input-group > textarea + .mktoEmailField, .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoEmailField,
.marketo-form .mktoForm .input-group > .mktoTelField + .mktoEmailField,
.marketo-form .mktoForm .input-group > .mktoTextField + .mktoEmailField,
.marketo-form .mktoForm .input-group > .form-control + .mktoTelField,
.marketo-form .mktoForm .share-story-modal form .input-group > input[type=text] + .mktoTelField,
.share-story-modal form .marketo-form .mktoForm .input-group > input[type=text] + .mktoTelField,
.marketo-form .mktoForm .share-story-modal form .input-group > input[type=email] + .mktoTelField,
.share-story-modal form .marketo-form .mktoForm .input-group > input[type=email] + .mktoTelField,
.marketo-form .mktoForm .share-story-modal form .input-group > input[type=tel] + .mktoTelField,
.share-story-modal form .marketo-form .mktoForm .input-group > input[type=tel] + .mktoTelField,
.marketo-form .mktoForm .share-story-modal form .input-group > select + .mktoTelField,
.share-story-modal form .marketo-form .mktoForm .input-group > select + .mktoTelField,
.marketo-form .mktoForm .share-story-modal form .input-group > textarea + .mktoTelField,
.share-story-modal form .marketo-form .mktoForm .input-group > textarea + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > select + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoTelField,
.marketo-form .mktoForm .input-group > select + .mktoTelField,
.marketo-form .mktoForm .input-group > textarea + .mktoTelField,
.marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTelField,
.marketo-form .mktoForm .input-group > .mktoTelField + .mktoTelField,
.marketo-form .mktoForm .input-group > .mktoTextField + .mktoTelField,
.marketo-form .mktoForm .input-group > .form-control + .mktoTextField,
.marketo-form .mktoForm .share-story-modal form .input-group > input[type=text] + .mktoTextField,
.share-story-modal form .marketo-form .mktoForm .input-group > input[type=text] + .mktoTextField,
.marketo-form .mktoForm .share-story-modal form .input-group > input[type=email] + .mktoTextField,
.share-story-modal form .marketo-form .mktoForm .input-group > input[type=email] + .mktoTextField,
.marketo-form .mktoForm .share-story-modal form .input-group > input[type=tel] + .mktoTextField,
.share-story-modal form .marketo-form .mktoForm .input-group > input[type=tel] + .mktoTextField,
.marketo-form .mktoForm .share-story-modal form .input-group > select + .mktoTextField,
.share-story-modal form .marketo-form .mktoForm .input-group > select + .mktoTextField,
.marketo-form .mktoForm .share-story-modal form .input-group > textarea + .mktoTextField,
.share-story-modal form .marketo-form .mktoForm .input-group > textarea + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > select + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoTextField,
.marketo-form .mktoForm .input-group > select + .mktoTextField,
.marketo-form .mktoForm .input-group > textarea + .mktoTextField,
.marketo-form .mktoForm .input-group > .mktoEmailField + .mktoTextField,
.marketo-form .mktoForm .input-group > .mktoTelField + .mktoTextField,
.marketo-form .mktoForm .input-group > .mktoTextField + .mktoTextField, .bmi-calculator .input-group > input[type=text] + .form-control, .bmi-calculator .share-story-modal form .input-group > input[type=text] + input[type=text], .share-story-modal form .bmi-calculator .input-group > input[type=text] + input[type=text],
.bmi-calculator .share-story-modal form .input-group > input[type=text] + input[type=email],
.share-story-modal form .bmi-calculator .input-group > input[type=text] + input[type=email],
.bmi-calculator .share-story-modal form .input-group > input[type=text] + input[type=tel],
.share-story-modal form .bmi-calculator .input-group > input[type=text] + input[type=tel],
.bmi-calculator .share-story-modal form .input-group > input[type=text] + select,
.share-story-modal form .bmi-calculator .input-group > input[type=text] + select,
.bmi-calculator .share-story-modal form .input-group > input[type=text] + textarea,
.share-story-modal form .bmi-calculator .input-group > input[type=text] + textarea, .bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > input[type=text] + .mktoEmailField, html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > input[type=text] + .mktoEmailField,
.bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > input[type=text] + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > input[type=text] + .mktoTelField,
.bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > input[type=text] + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > input[type=text] + .mktoTextField,
.bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > input[type=text] + select,
html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > input[type=text] + select,
.bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > input[type=text] + textarea,
html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > input[type=text] + textarea,
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > input[type=text] + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > input[type=text] + .mktoEmailField,
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > input[type=text] + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > input[type=text] + .mktoTelField,
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > input[type=text] + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > input[type=text] + .mktoTextField,
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > input[type=text] + select,
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > input[type=text] + select,
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > input[type=text] + textarea,
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > input[type=text] + textarea, .bmi-calculator .marketo-form .mktoForm .input-group > input[type=text] + select, .marketo-form .mktoForm .bmi-calculator .input-group > input[type=text] + select,
.bmi-calculator .marketo-form .mktoForm .input-group > input[type=text] + textarea,
.marketo-form .mktoForm .bmi-calculator .input-group > input[type=text] + textarea, .bmi-calculator .marketo-form .mktoForm .input-group > input[type=text] + .mktoEmailField, .marketo-form .mktoForm .bmi-calculator .input-group > input[type=text] + .mktoEmailField,
.bmi-calculator .marketo-form .mktoForm .input-group > input[type=text] + .mktoTelField,
.marketo-form .mktoForm .bmi-calculator .input-group > input[type=text] + .mktoTelField,
.bmi-calculator .marketo-form .mktoForm .input-group > input[type=text] + .mktoTextField,
.marketo-form .mktoForm .bmi-calculator .input-group > input[type=text] + .mktoTextField,
.bmi-calculator .input-group > select + .form-control,
.bmi-calculator .share-story-modal form .input-group > select + input[type=text],
.share-story-modal form .bmi-calculator .input-group > select + input[type=text],
.bmi-calculator .share-story-modal form .input-group > select + input[type=email],
.share-story-modal form .bmi-calculator .input-group > select + input[type=email],
.bmi-calculator .share-story-modal form .input-group > select + input[type=tel],
.share-story-modal form .bmi-calculator .input-group > select + input[type=tel],
.bmi-calculator .share-story-modal form .input-group > select + select,
.share-story-modal form .bmi-calculator .input-group > select + select,
.bmi-calculator .share-story-modal form .input-group > select + textarea,
.share-story-modal form .bmi-calculator .input-group > select + textarea,
.bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > select + .mktoEmailField,
.bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > select + .mktoTelField,
.bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > select + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > select + .mktoTextField,
.bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > select + select,
html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > select + select,
.bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > select + textarea,
html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > select + textarea,
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > select + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > select + .mktoEmailField,
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > select + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > select + .mktoTelField,
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > select + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > select + .mktoTextField,
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > select + select,
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > select + select,
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > select + textarea,
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > select + textarea,
.bmi-calculator .marketo-form .mktoForm .input-group > select + select,
.marketo-form .mktoForm .bmi-calculator .input-group > select + select,
.bmi-calculator .marketo-form .mktoForm .input-group > select + textarea,
.marketo-form .mktoForm .bmi-calculator .input-group > select + textarea,
.bmi-calculator .marketo-form .mktoForm .input-group > select + .mktoEmailField,
.marketo-form .mktoForm .bmi-calculator .input-group > select + .mktoEmailField,
.bmi-calculator .marketo-form .mktoForm .input-group > select + .mktoTelField,
.marketo-form .mktoForm .bmi-calculator .input-group > select + .mktoTelField,
.bmi-calculator .marketo-form .mktoForm .input-group > select + .mktoTextField,
.marketo-form .mktoForm .bmi-calculator .input-group > select + .mktoTextField, .bmi-calculator .input-group > .form-control + input[type=text], .bmi-calculator .share-story-modal form .input-group > input[type=text] + input[type=text], .share-story-modal form .bmi-calculator .input-group > input[type=text] + input[type=text],
.bmi-calculator .share-story-modal form .input-group > input[type=email] + input[type=text],
.share-story-modal form .bmi-calculator .input-group > input[type=email] + input[type=text],
.bmi-calculator .share-story-modal form .input-group > input[type=tel] + input[type=text],
.share-story-modal form .bmi-calculator .input-group > input[type=tel] + input[type=text],
.bmi-calculator .share-story-modal form .input-group > select + input[type=text],
.share-story-modal form .bmi-calculator .input-group > select + input[type=text],
.bmi-calculator .share-story-modal form .input-group > textarea + input[type=text],
.share-story-modal form .bmi-calculator .input-group > textarea + input[type=text], .bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + input[type=text], html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > .mktoEmailField + input[type=text],
.bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + input[type=text],
html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > .mktoTelField + input[type=text],
.bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + input[type=text],
html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > .mktoTextField + input[type=text],
.bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > select + input[type=text],
html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > select + input[type=text],
.bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > textarea + input[type=text],
html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > textarea + input[type=text],
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + input[type=text],
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > .mktoEmailField + input[type=text],
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + input[type=text],
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > .mktoTelField + input[type=text],
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + input[type=text],
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > .mktoTextField + input[type=text],
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > select + input[type=text],
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > select + input[type=text],
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > textarea + input[type=text],
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > textarea + input[type=text], .bmi-calculator .marketo-form .mktoForm .input-group > select + input[type=text], .marketo-form .mktoForm .bmi-calculator .input-group > select + input[type=text],
.bmi-calculator .marketo-form .mktoForm .input-group > textarea + input[type=text],
.marketo-form .mktoForm .bmi-calculator .input-group > textarea + input[type=text], .bmi-calculator .marketo-form .mktoForm .input-group > .mktoEmailField + input[type=text], .marketo-form .mktoForm .bmi-calculator .input-group > .mktoEmailField + input[type=text],
.bmi-calculator .marketo-form .mktoForm .input-group > .mktoTelField + input[type=text],
.marketo-form .mktoForm .bmi-calculator .input-group > .mktoTelField + input[type=text],
.bmi-calculator .marketo-form .mktoForm .input-group > .mktoTextField + input[type=text],
.marketo-form .mktoForm .bmi-calculator .input-group > .mktoTextField + input[type=text], .bmi-calculator .input-group > input[type=text] + input[type=text],
.bmi-calculator .input-group > select + input[type=text],
.bmi-calculator .input-group > .form-control + select,
.bmi-calculator .share-story-modal form .input-group > input[type=text] + select,
.share-story-modal form .bmi-calculator .input-group > input[type=text] + select,
.bmi-calculator .share-story-modal form .input-group > input[type=email] + select,
.share-story-modal form .bmi-calculator .input-group > input[type=email] + select,
.bmi-calculator .share-story-modal form .input-group > input[type=tel] + select,
.share-story-modal form .bmi-calculator .input-group > input[type=tel] + select,
.bmi-calculator .share-story-modal form .input-group > select + select,
.share-story-modal form .bmi-calculator .input-group > select + select,
.bmi-calculator .share-story-modal form .input-group > textarea + select,
.share-story-modal form .bmi-calculator .input-group > textarea + select,
.bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + select,
html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > .mktoEmailField + select,
.bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + select,
html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > .mktoTelField + select,
.bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + select,
html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > .mktoTextField + select,
.bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > select + select,
html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > select + select,
.bmi-calculator html .microsite-theme .marketo-form .mktoForm .input-group > textarea + select,
html .microsite-theme .marketo-form .mktoForm .bmi-calculator .input-group > textarea + select,
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + select,
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > .mktoEmailField + select,
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + select,
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > .mktoTelField + select,
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + select,
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > .mktoTextField + select,
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > select + select,
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > select + select,
.bmi-calculator html .moments-theme .marketo-form .mktoForm .input-group > textarea + select,
html .moments-theme .marketo-form .mktoForm .bmi-calculator .input-group > textarea + select,
.bmi-calculator .marketo-form .mktoForm .input-group > select + select,
.marketo-form .mktoForm .bmi-calculator .input-group > select + select,
.bmi-calculator .marketo-form .mktoForm .input-group > textarea + select,
.marketo-form .mktoForm .bmi-calculator .input-group > textarea + select,
.bmi-calculator .marketo-form .mktoForm .input-group > .mktoEmailField + select,
.marketo-form .mktoForm .bmi-calculator .input-group > .mktoEmailField + select,
.bmi-calculator .marketo-form .mktoForm .input-group > .mktoTelField + select,
.marketo-form .mktoForm .bmi-calculator .input-group > .mktoTelField + select,
.bmi-calculator .marketo-form .mktoForm .input-group > .mktoTextField + select,
.marketo-form .mktoForm .bmi-calculator .input-group > .mktoTextField + select,
.bmi-calculator .input-group > input[type=text] + select,
.bmi-calculator .input-group > select + select, .input-group > textarea + .form-control, .share-story-modal form .input-group > textarea + input[type=text],
.share-story-modal form .input-group > textarea + input[type=email],
.share-story-modal form .input-group > textarea + input[type=tel],
.share-story-modal form .input-group > textarea + select,
.share-story-modal form .input-group > textarea + textarea, html .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + select,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + select,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + textarea, .marketo-form .mktoForm .input-group > textarea + select,
.marketo-form .mktoForm .input-group > textarea + textarea, .marketo-form .mktoForm .input-group > textarea + .mktoEmailField,
.marketo-form .mktoForm .input-group > textarea + .mktoTelField,
.marketo-form .mktoForm .input-group > textarea + .mktoTextField, .bmi-calculator .input-group > textarea + input[type=text],
.bmi-calculator .input-group > textarea + select, .input-group > .form-control + textarea, .share-story-modal form .input-group > input[type=text] + textarea,
.share-story-modal form .input-group > input[type=email] + textarea,
.share-story-modal form .input-group > input[type=tel] + textarea,
.share-story-modal form .input-group > select + textarea,
.share-story-modal form .input-group > textarea + textarea, html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > select + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > select + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + textarea, .marketo-form .mktoForm .input-group > select + textarea,
.marketo-form .mktoForm .input-group > textarea + textarea, .marketo-form .mktoForm .input-group > .mktoEmailField + textarea,
.marketo-form .mktoForm .input-group > .mktoTelField + textarea,
.marketo-form .mktoForm .input-group > .mktoTextField + textarea, .bmi-calculator .input-group > input[type=text] + textarea,
.bmi-calculator .input-group > select + textarea, .input-group > textarea + textarea,
.input-group > .form-control + .custom-select,
.share-story-modal form .input-group > input[type=text] + .custom-select,
.share-story-modal form .input-group > input[type=email] + .custom-select,
.share-story-modal form .input-group > input[type=tel] + .custom-select,
.share-story-modal form .input-group > select + .custom-select,
.share-story-modal form .input-group > textarea + .custom-select,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .custom-select,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + .custom-select,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + .custom-select,
html .microsite-theme .marketo-form .mktoForm .input-group > select + .custom-select,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + .custom-select,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .custom-select,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + .custom-select,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + .custom-select,
html .moments-theme .marketo-form .mktoForm .input-group > select + .custom-select,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + .custom-select,
.marketo-form .mktoForm .input-group > select + .custom-select,
.marketo-form .mktoForm .input-group > textarea + .custom-select,
.marketo-form .mktoForm .input-group > .mktoEmailField + .custom-select,
.marketo-form .mktoForm .input-group > .mktoTelField + .custom-select,
.marketo-form .mktoForm .input-group > .mktoTextField + .custom-select,
.bmi-calculator .input-group > input[type=text] + .custom-select,
.bmi-calculator .input-group > select + .custom-select,
.input-group > textarea + .custom-select,
.input-group > .form-control + .custom-file,
.share-story-modal form .input-group > input[type=text] + .custom-file,
.share-story-modal form .input-group > input[type=email] + .custom-file,
.share-story-modal form .input-group > input[type=tel] + .custom-file,
.share-story-modal form .input-group > select + .custom-file,
.share-story-modal form .input-group > textarea + .custom-file,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .custom-file,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTelField + .custom-file,
html .microsite-theme .marketo-form .mktoForm .input-group > .mktoTextField + .custom-file,
html .microsite-theme .marketo-form .mktoForm .input-group > select + .custom-file,
html .microsite-theme .marketo-form .mktoForm .input-group > textarea + .custom-file,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoEmailField + .custom-file,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTelField + .custom-file,
html .moments-theme .marketo-form .mktoForm .input-group > .mktoTextField + .custom-file,
html .moments-theme .marketo-form .mktoForm .input-group > select + .custom-file,
html .moments-theme .marketo-form .mktoForm .input-group > textarea + .custom-file,
.marketo-form .mktoForm .input-group > select + .custom-file,
.marketo-form .mktoForm .input-group > textarea + .custom-file,
.marketo-form .mktoForm .input-group > .mktoEmailField + .custom-file,
.marketo-form .mktoForm .input-group > .mktoTelField + .custom-file,
.marketo-form .mktoForm .input-group > .mktoTextField + .custom-file,
.bmi-calculator .input-group > input[type=text] + .custom-file,
.bmi-calculator .input-group > select + .custom-file,
.input-group > textarea + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.share-story-modal form .input-group > .form-control-plaintext + input[type=text],
.share-story-modal form .input-group > .form-control-plaintext + input[type=email],
.share-story-modal form .input-group > .form-control-plaintext + input[type=tel],
.share-story-modal form .input-group > .form-control-plaintext + select,
.share-story-modal form .input-group > .form-control-plaintext + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > .form-control-plaintext + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group > .form-control-plaintext + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > .form-control-plaintext + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > .form-control-plaintext + select,
html .microsite-theme .marketo-form .mktoForm .input-group > .form-control-plaintext + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .form-control-plaintext + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > .form-control-plaintext + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > .form-control-plaintext + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > .form-control-plaintext + select,
html .moments-theme .marketo-form .mktoForm .input-group > .form-control-plaintext + textarea,
.marketo-form .mktoForm .input-group > .form-control-plaintext + select,
.marketo-form .mktoForm .input-group > .form-control-plaintext + textarea,
.marketo-form .mktoForm .input-group > .form-control-plaintext + .mktoEmailField,
.marketo-form .mktoForm .input-group > .form-control-plaintext + .mktoTelField,
.marketo-form .mktoForm .input-group > .form-control-plaintext + .mktoTextField,
.bmi-calculator .input-group > .form-control-plaintext + input[type=text],
.bmi-calculator .input-group > .form-control-plaintext + select,
.input-group > .form-control-plaintext + textarea,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.share-story-modal form .input-group > .custom-select + input[type=text],
.share-story-modal form .input-group > .custom-select + input[type=email],
.share-story-modal form .input-group > .custom-select + input[type=tel],
.share-story-modal form .input-group > .custom-select + select,
.share-story-modal form .input-group > .custom-select + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > .custom-select + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group > .custom-select + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > .custom-select + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > .custom-select + select,
html .microsite-theme .marketo-form .mktoForm .input-group > .custom-select + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .custom-select + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > .custom-select + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > .custom-select + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > .custom-select + select,
html .moments-theme .marketo-form .mktoForm .input-group > .custom-select + textarea,
.marketo-form .mktoForm .input-group > .custom-select + select,
.marketo-form .mktoForm .input-group > .custom-select + textarea,
.marketo-form .mktoForm .input-group > .custom-select + .mktoEmailField,
.marketo-form .mktoForm .input-group > .custom-select + .mktoTelField,
.marketo-form .mktoForm .input-group > .custom-select + .mktoTextField,
.bmi-calculator .input-group > .custom-select + input[type=text],
.bmi-calculator .input-group > .custom-select + select,
.input-group > .custom-select + textarea,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.share-story-modal form .input-group > .custom-file + input[type=text],
.share-story-modal form .input-group > .custom-file + input[type=email],
.share-story-modal form .input-group > .custom-file + input[type=tel],
.share-story-modal form .input-group > .custom-file + select,
.share-story-modal form .input-group > .custom-file + textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > .custom-file + .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group > .custom-file + .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group > .custom-file + .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group > .custom-file + select,
html .microsite-theme .marketo-form .mktoForm .input-group > .custom-file + textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .custom-file + .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group > .custom-file + .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group > .custom-file + .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group > .custom-file + select,
html .moments-theme .marketo-form .mktoForm .input-group > .custom-file + textarea,
.marketo-form .mktoForm .input-group > .custom-file + select,
.marketo-form .mktoForm .input-group > .custom-file + textarea,
.marketo-form .mktoForm .input-group > .custom-file + .mktoEmailField,
.marketo-form .mktoForm .input-group > .custom-file + .mktoTelField,
.marketo-form .mktoForm .input-group > .custom-file + .mktoTextField,
.bmi-calculator .input-group > .custom-file + input[type=text],
.bmi-calculator .input-group > .custom-file + select,
.input-group > .custom-file + textarea,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus, .share-story-modal form .input-group > input[type=text]:focus,
.share-story-modal form .input-group > input[type=email]:focus,
.share-story-modal form .input-group > input[type=tel]:focus,
.share-story-modal form .input-group > select:focus,
.share-story-modal form .input-group > textarea:focus, .marketo-form .mktoForm .input-group > select:focus,
.marketo-form .mktoForm .input-group > textarea:focus, .marketo-form .mktoForm .input-group > .mktoEmailField:focus,
.marketo-form .mktoForm .input-group > .mktoTelField:focus,
.marketo-form .mktoForm .input-group > .mktoTextField:focus, .bmi-calculator .input-group > input[type=text]:focus,
.bmi-calculator .input-group > select:focus, .input-group > textarea:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child), .share-story-modal form .input-group > input[type=text]:not(:first-child),
.share-story-modal form .input-group > input[type=email]:not(:first-child),
.share-story-modal form .input-group > input[type=tel]:not(:first-child),
.share-story-modal form .input-group > select:not(:first-child),
.share-story-modal form .input-group > textarea:not(:first-child), .marketo-form .mktoForm .input-group > select:not(:first-child),
.marketo-form .mktoForm .input-group > textarea:not(:first-child), .marketo-form .mktoForm .input-group > .mktoEmailField:not(:first-child),
.marketo-form .mktoForm .input-group > .mktoTelField:not(:first-child),
.marketo-form .mktoForm .input-group > .mktoTextField:not(:first-child), .bmi-calculator .input-group > input[type=text]:not(:first-child),
.bmi-calculator .input-group > select:not(:first-child), .input-group > textarea:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child), .share-story-modal form .input-group:not(.has-validation) > input[type=text]:not(:last-child),
.share-story-modal form .input-group:not(.has-validation) > input[type=email]:not(:last-child),
.share-story-modal form .input-group:not(.has-validation) > input[type=tel]:not(:last-child),
.share-story-modal form .input-group:not(.has-validation) > select:not(:last-child),
.share-story-modal form .input-group:not(.has-validation) > textarea:not(:last-child), .marketo-form .mktoForm .input-group:not(.has-validation) > select:not(:last-child),
.marketo-form .mktoForm .input-group:not(.has-validation) > textarea:not(:last-child), .marketo-form .mktoForm .input-group:not(.has-validation) > .mktoEmailField:not(:last-child),
.marketo-form .mktoForm .input-group:not(.has-validation) > .mktoTelField:not(:last-child),
.marketo-form .mktoForm .input-group:not(.has-validation) > .mktoTextField:not(:last-child), .bmi-calculator .input-group:not(.has-validation) > input[type=text]:not(:last-child),
.bmi-calculator .input-group:not(.has-validation) > select:not(:last-child), .input-group:not(.has-validation) > textarea:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3), .share-story-modal form .input-group.has-validation > input[type=text]:nth-last-child(n+3),
.share-story-modal form .input-group.has-validation > input[type=email]:nth-last-child(n+3),
.share-story-modal form .input-group.has-validation > input[type=tel]:nth-last-child(n+3),
.share-story-modal form .input-group.has-validation > select:nth-last-child(n+3),
.share-story-modal form .input-group.has-validation > textarea:nth-last-child(n+3), .marketo-form .mktoForm .input-group.has-validation > select:nth-last-child(n+3),
.marketo-form .mktoForm .input-group.has-validation > textarea:nth-last-child(n+3), .marketo-form .mktoForm .input-group.has-validation > .mktoEmailField:nth-last-child(n+3),
.marketo-form .mktoForm .input-group.has-validation > .mktoTelField:nth-last-child(n+3),
.marketo-form .mktoForm .input-group.has-validation > .mktoTextField:nth-last-child(n+3), .bmi-calculator .input-group.has-validation > input[type=text]:nth-last-child(n+3),
.bmi-calculator .input-group.has-validation > select:nth-last-child(n+3), .input-group.has-validation > textarea:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn, .input-group-prepend .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .input-group-prepend .mktoButton,
.input-group-append .btn,
.input-group-append .marketo-form .mktoForm .mktoButton,
.marketo-form .mktoForm .input-group-append .mktoButton {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus, .input-group-prepend .marketo-form .mktoForm .mktoButton:focus, .marketo-form .mktoForm .input-group-prepend .mktoButton:focus,
.input-group-append .btn:focus,
.input-group-append .marketo-form .mktoForm .mktoButton:focus,
.marketo-form .mktoForm .input-group-append .mktoButton:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn, .input-group-prepend .marketo-form .mktoForm .mktoButton + .btn, .marketo-form .mktoForm .input-group-prepend .mktoButton + .btn, .input-group-prepend .marketo-form .mktoForm .btn + .mktoButton, .marketo-form .mktoForm .input-group-prepend .btn + .mktoButton, .input-group-prepend .marketo-form .mktoForm .mktoButton + .mktoButton, .marketo-form .mktoForm .input-group-prepend .mktoButton + .mktoButton,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .marketo-form .mktoForm .mktoButton + .input-group-text,
.marketo-form .mktoForm .input-group-prepend .mktoButton + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .marketo-form .mktoForm .input-group-text + .mktoButton,
.marketo-form .mktoForm .input-group-prepend .input-group-text + .mktoButton,
.input-group-append .btn + .btn,
.input-group-append .marketo-form .mktoForm .mktoButton + .btn,
.marketo-form .mktoForm .input-group-append .mktoButton + .btn,
.input-group-append .marketo-form .mktoForm .btn + .mktoButton,
.marketo-form .mktoForm .input-group-append .btn + .mktoButton,
.input-group-append .marketo-form .mktoForm .mktoButton + .mktoButton,
.marketo-form .mktoForm .input-group-append .mktoButton + .mktoButton,
.input-group-append .btn + .input-group-text,
.input-group-append .marketo-form .mktoForm .mktoButton + .input-group-text,
.marketo-form .mktoForm .input-group-append .mktoButton + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .marketo-form .mktoForm .input-group-text + .mktoButton,
.marketo-form .mktoForm .input-group-append .input-group-text + .mktoButton {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.625rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.3125rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea), .share-story-modal form .input-group-lg > input[type=text]:not(textarea),
.share-story-modal form .input-group-lg > input[type=email]:not(textarea),
.share-story-modal form .input-group-lg > input[type=tel]:not(textarea),
.share-story-modal form .input-group-lg > select:not(textarea),
.share-story-modal form .input-group-lg > textarea:not(textarea),
html .microsite-theme .marketo-form .mktoForm .input-group-lg > select:not(textarea),
html .microsite-theme .marketo-form .mktoForm .input-group-lg > textarea:not(textarea),
html .moments-theme .marketo-form .mktoForm .input-group-lg > select:not(textarea),
html .moments-theme .marketo-form .mktoForm .input-group-lg > textarea:not(textarea), .marketo-form .mktoForm .input-group-lg > select:not(textarea),
.marketo-form .mktoForm .input-group-lg > textarea:not(textarea), .marketo-form .mktoForm .input-group-lg > .mktoEmailField:not(textarea),
.marketo-form .mktoForm .input-group-lg > .mktoTelField:not(textarea),
.marketo-form .mktoForm .input-group-lg > .mktoTextField:not(textarea), .bmi-calculator .input-group-lg > input[type=text]:not(textarea),
.bmi-calculator .input-group-lg > select:not(textarea), .input-group-lg > textarea:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.375em + 1.25rem + 2px);
}

.input-group-lg > .form-control, .share-story-modal form .input-group-lg > input[type=text],
.share-story-modal form .input-group-lg > input[type=email],
.share-story-modal form .input-group-lg > input[type=tel],
.share-story-modal form .input-group-lg > select,
.share-story-modal form .input-group-lg > textarea, html .microsite-theme .marketo-form .mktoForm .input-group-lg > .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group-lg > .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group-lg > .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group-lg > select,
html .microsite-theme .marketo-form .mktoForm .input-group-lg > textarea,
html .moments-theme .marketo-form .mktoForm .input-group-lg > .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group-lg > .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group-lg > .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group-lg > select,
html .moments-theme .marketo-form .mktoForm .input-group-lg > textarea, .marketo-form .mktoForm .input-group-lg > select,
.marketo-form .mktoForm .input-group-lg > textarea, .marketo-form .mktoForm .input-group-lg > .mktoEmailField,
.marketo-form .mktoForm .input-group-lg > .mktoTelField,
.marketo-form .mktoForm .input-group-lg > .mktoTextField, .bmi-calculator .input-group-lg > input[type=text],
.bmi-calculator .input-group-lg > select, .input-group-lg > textarea,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.marketo-form .mktoForm .input-group-lg > .input-group-prepend > .mktoButton,
.input-group-lg > .input-group-append > .btn,
.marketo-form .mktoForm .input-group-lg > .input-group-append > .mktoButton {
  padding: 0.625rem 1rem;
  font-size: 1rem;
  line-height: 1.375;
  border-radius: 0.3125rem;
}

.input-group-sm > .form-control:not(textarea), .share-story-modal form .input-group-sm > input[type=text]:not(textarea),
.share-story-modal form .input-group-sm > input[type=email]:not(textarea),
.share-story-modal form .input-group-sm > input[type=tel]:not(textarea),
.share-story-modal form .input-group-sm > select:not(textarea),
.share-story-modal form .input-group-sm > textarea:not(textarea),
html .microsite-theme .marketo-form .mktoForm .input-group-sm > select:not(textarea),
html .microsite-theme .marketo-form .mktoForm .input-group-sm > textarea:not(textarea),
html .moments-theme .marketo-form .mktoForm .input-group-sm > select:not(textarea),
html .moments-theme .marketo-form .mktoForm .input-group-sm > textarea:not(textarea), .marketo-form .mktoForm .input-group-sm > select:not(textarea),
.marketo-form .mktoForm .input-group-sm > textarea:not(textarea), .marketo-form .mktoForm .input-group-sm > .mktoEmailField:not(textarea),
.marketo-form .mktoForm .input-group-sm > .mktoTelField:not(textarea),
.marketo-form .mktoForm .input-group-sm > .mktoTextField:not(textarea), .bmi-calculator .input-group-sm > input[type=text]:not(textarea),
.bmi-calculator .input-group-sm > select:not(textarea), .input-group-sm > textarea:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.375em + 1.25rem + 2px);
}

.input-group-sm > .form-control, .share-story-modal form .input-group-sm > input[type=text],
.share-story-modal form .input-group-sm > input[type=email],
.share-story-modal form .input-group-sm > input[type=tel],
.share-story-modal form .input-group-sm > select,
.share-story-modal form .input-group-sm > textarea, html .microsite-theme .marketo-form .mktoForm .input-group-sm > .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .input-group-sm > .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .input-group-sm > .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .input-group-sm > select,
html .microsite-theme .marketo-form .mktoForm .input-group-sm > textarea,
html .moments-theme .marketo-form .mktoForm .input-group-sm > .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .input-group-sm > .mktoTelField,
html .moments-theme .marketo-form .mktoForm .input-group-sm > .mktoTextField,
html .moments-theme .marketo-form .mktoForm .input-group-sm > select,
html .moments-theme .marketo-form .mktoForm .input-group-sm > textarea, .marketo-form .mktoForm .input-group-sm > select,
.marketo-form .mktoForm .input-group-sm > textarea, .marketo-form .mktoForm .input-group-sm > .mktoEmailField,
.marketo-form .mktoForm .input-group-sm > .mktoTelField,
.marketo-form .mktoForm .input-group-sm > .mktoTextField, .bmi-calculator .input-group-sm > input[type=text],
.bmi-calculator .input-group-sm > select, .input-group-sm > textarea,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.marketo-form .mktoForm .input-group-sm > .input-group-prepend > .mktoButton,
.input-group-sm > .input-group-append > .btn,
.marketo-form .mktoForm .input-group-sm > .input-group-append > .mktoButton {
  padding: 0.625rem 1rem;
  font-size: 1rem;
  line-height: 1.375;
  border-radius: 0.3125rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem;
}

.input-group > .input-group-prepend > .btn, .marketo-form .mktoForm .input-group > .input-group-prepend > .mktoButton,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.marketo-form .mktoForm .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .mktoButton,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.marketo-form .mktoForm .input-group.has-validation > .input-group-append:nth-last-child(n+3) > .mktoButton,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.marketo-form .mktoForm .input-group > .input-group-append:last-child > .mktoButton:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .marketo-form .mktoForm .input-group > .input-group-append > .mktoButton,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.marketo-form .mktoForm .input-group > .input-group-prepend:not(:first-child) > .mktoButton,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.marketo-form .mktoForm .input-group > .input-group-prepend:first-child > .mktoButton:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.3125rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: auto;
  padding: 0.625rem 2rem 0.625rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.3125rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.375em + 1.25rem + 2px);
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 1rem;
  font-size: 1rem;
}

.custom-select-lg {
  height: calc(1.375em + 1.25rem + 2px);
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 1rem;
  font-size: 1rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: auto;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: auto;
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: auto;
  padding: 0.625rem 1rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.375;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.3125rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.375em + 1.25rem);
  padding: 0.625rem 1rem;
  line-height: 1.375;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.3125rem 0.3125rem 0;
}

.custom-range {
  width: 100%;
  height: 1.9rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.36);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.36);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.36);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: -0.625rem;
  background-color: #2c373f;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #2c373f;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.25rem;
  color: transparent;
  cursor: pointer;
  background-color: #cbced1;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #2c373f;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #2c373f;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.25rem;
  color: transparent;
  cursor: pointer;
  background-color: #cbced1;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #2c373f;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #2c373f;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.25rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.75rem;
}
.custom-range::-ms-fill-lower {
  background-color: #cbced1;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #cbced1;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.3125rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.3125rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.3125rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.3125rem - 1px);
  border-top-right-radius: calc(0.3125rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.3125rem - 1px);
  border-bottom-left-radius: calc(0.3125rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.3125rem - 1px) calc(0.3125rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.3125rem - 1px) calc(0.3125rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.3125rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.3125rem - 1px);
  border-top-right-radius: calc(0.3125rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.3125rem - 1px);
  border-bottom-left-radius: calc(0.3125rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.3125rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.3125rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1;
  color: #2c373f;
  background-color: #a2c3d6;
  border: 0 solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #006D9D;
  border-color: #006D9D;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.3125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge, .marketo-form .mktoForm .mktoButton .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3125rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.3125rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.3125rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.3125rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.3125rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.3125rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3125rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3125rem - 1px);
  border-top-right-radius: calc(0.3125rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3125rem - 1px);
  border-bottom-left-radius: calc(0.3125rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.3125rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3125rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3125rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3125rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3125rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3125rem - 1px);
  border-top-right-radius: calc(0.3125rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.3125rem !important;
}

.rounded {
  border-radius: 0.3125rem !important;
}

.rounded-top {
  border-top-left-radius: 0.3125rem !important;
  border-top-right-radius: 0.3125rem !important;
}

.rounded-right {
  border-top-right-radius: 0.3125rem !important;
  border-bottom-right-radius: 0.3125rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important;
}

.rounded-left {
  border-top-left-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important;
}

.rounded-lg {
  border-radius: 0.3125rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after, .bi-omnibar::after, .tool::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
img {
    page-break-inside: avoid;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }
  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/* Helpers */
/*
 *
 * TYPOGRAPHY VARIABLES AND MAPS
 *
 */
/* Moments Fonts */
/* Advance Fonts */
/* Resolution Fonts */
/*
 * Utlizing Bootstrap font-weight variables. Adding custom semi-bold and extra-bold.
 * $font-weight-light:           300 !default;
 * $font-weight-normal:          400 !default;
 * $font-weight-bold:            700 !default;

 proxima-nova: Regular 400, Medium 500, Semibold 600, Bold 700, Extrabold 800
*/
/*
 * Font Size and Line Height Maps
 * Matching keys gets used together by default
 * Used in text() mixin and calulateLineHeight() function
 * FONT SIZE SCALE = Latin (+36px) and Greek (36-) alphabet keys
 * LINE HEIGHT SCALE = Latin (+40px) and Greek (40-) alphabet keys
 * Latin (majus) https://en.wikipedia.org/wiki/Latin_alphabet
 * Greek https://en.wikipedia.org/wiki/Greek_alphabet
 */
/*
 * Profile variables
 */
/*
 *
 * TYPOGRAPHY FUNCTIONS
 *
 */
/*
 * calculateRem($font-size, $base)
 * convert absolute (px) into relative em (rem) values
 * assumes a 16px baseline; override if in a different context
 * -----
 * calculateRem(32)      ==> 0.5rem
 * calculateRem(48, 12)  ==> 4rem
 */
/*
 * calulateLineHeight($font-size, $line-height)
 * convert absolute (px) from corresponding line-heights map into unitless line-height values
 * $font-size argument must correlate to $font-size-scale in _variables.scss to avoid errant font-sizes
 * $line-height argument can be correlated to $line-height-scale (ie alpha, beta) in _variables.scss or a specific number value (ie 27, 32)
 */
/*
 *
 * TYPOGRAPHY MIXINS
 *
 */
/*
 * text($font-size, $line-height(optional))
 * calculates font-size and line-height corresponding to $font-size-scale and $line-height-scale maps in _variables.scss
 * $line-height argument is optional, and can be a different value in $line-height-scale or a number value
 */
/*
 * loader($color, $height(optional, default 26px), $stroke(optional, default 2px))
 * adds generic loader as :after psuedo element
 * $color must be passed. $height and $stroke have defaults, but can be passed in as needed.
 */
/*
 *
 * COLOR BACKGROUND CLASSES
 *
 */
/* 2024 Primary Color Backgrounds */
.bg-precision-gray {
  background-color: #2c373f;
}

.bg-illumination-yellow {
  background-color: #ffcd55;
}

.bg-sky-blue {
  background-color: #006D9D;
}

/* 2024 Secondary Color Backgrounds */
.bg-sky-blue-ish-tint {
  background-color: #f2f9ff;
}

.bg-precision-gray-tint {
  background-color: #cbced1;
}

.bg-precision-gray-ish-tint {
  background-color: #f5f5f6;
}

.bg-illumination-yellow-ish-tint {
  background-color: #ffebbb;
}

/* 2024 Tertiary Color Backgrounds */
.bg-deep-green {
  background-color: #005557;
}

.bg-overcast-blue {
  background-color: #3d5d6b;
}

.bg-glacier-blue {
  background-color: #a2c3d6;
}

.bg-graham-gray {
  background-color: #e4e5e6;
}

/* Primary Color Backgrounds */
.bg-primary-yellow {
  background-color: #ffcd55;
}

/* ~~IN 2024 Site Redesign - kbma 'precision grey' */
.bg-primary-dark-blue {
  background-color: #2c373f;
}

.bg-primary-blue {
  background-color: #006D9D;
}

.bg-primary-blue-gray {
  background-color: #a2c3d6;
}

/* Secondary Color Backgrounds */
.bg-secondary-dark-teal {
  background-color: #005557;
}

.bg-secondary-blue {
  background-color: #006D9D;
}

.bg-secondary-light-blue {
  background-color: #006D9D;
}

.bg-secondary-teal {
  background-color: #a2c3d6;
}

.bg-secondary-light-teal {
  background-color: #a2c3d6;
}

/* Neutral Color Backgrounds */
.bg-neutral-darkest {
  background-color: #3d5d6b;
}

.bg-neutral-dark {
  background-color: #3d5d6b;
}

.bg-neutral {
  background-color: #a2c3d6;
}

.bg-neutral-light {
  background-color: #a2c3d6;
}

.bg-neutral-lighter {
  background-color: #f5f5f6;
}

.bg-neutral-lightest {
  background-color: #f2f9ff;
}

/* Gray Color Backgrounds */
.bg-gray-darkest {
  background-color: #2c373f;
}

.bg-gray-light {
  background-color: #cbced1;
}

.bg-gray-lightest {
  background-color: #e4e5e6;
}

.bg-gray-secondary {
  background-color: #cbced1;
}

/* Utility Color Backgrounds */
.bg-black {
  background-color: #000;
}

.bg-white {
  background-color: #fff;
}

.bg-green {
  background-color: #378957;
}

.bg-dark-red {
  background-color: #8b0000;
}

.bg-red {
  background-color: #b23838;
}

.bg-light-red {
  background-color: #ffe5e5;
}

/* Global Styles */
html {
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  overflow-x: hidden;
}
body.body-fixed {
  overflow-y: scroll;
  position: fixed;
  top: 0;
  width: 100%;
}

table.table-bordered {
  border-width: 1px;
}
table.table-bordered th,
table.table-bordered td {
  border-width: 1px;
  border-color: #cbced1;
}
table th,
table td {
  color: #2c373f;
}
table th a,
table td a {
  font-weight: 600;
}
table th p,
table td p {
  font-size: 1rem;
  line-height: 1.125;
  color: #2c373f;
}

form {
  margin-bottom: 0;
}

.form-control, .share-story-modal form input[type=text],
.share-story-modal form input[type=email],
.share-story-modal form input[type=tel],
.share-story-modal form select,
.share-story-modal form textarea, html .microsite-theme .marketo-form .mktoForm .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .mktoTextField,
html .microsite-theme .marketo-form .mktoForm select,
html .microsite-theme .marketo-form .mktoForm textarea,
html .moments-theme .marketo-form .mktoForm .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .mktoTelField,
html .moments-theme .marketo-form .mktoForm .mktoTextField,
html .moments-theme .marketo-form .mktoForm select,
html .moments-theme .marketo-form .mktoForm textarea, .marketo-form .mktoForm select,
.marketo-form .mktoForm textarea, .marketo-form .mktoForm .mktoEmailField,
.marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .mktoTextField, .bmi-calculator input[type=text],
.bmi-calculator select, textarea {
  background-clip: unset;
}

input[type=text],
input[type=email],
input[type=date],
input[type=phone],
input[type=tel],
input[type=search] {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  background-color: #f2f9ff;
  border-radius: 5px;
  color: #2c373f;
}
input[type=text]:focus,
input[type=email]:focus,
input[type=date]:focus,
input[type=phone]:focus,
input[type=tel]:focus,
input[type=search]:focus {
  background-color: #f2f9ff;
  border-color: #006D9D;
  box-shadow: none;
}
input[type=text][placeholder],
input[type=email][placeholder],
input[type=date][placeholder],
input[type=phone][placeholder],
input[type=tel][placeholder],
input[type=search][placeholder] {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
input[type=text]:disabled,
input[type=email]:disabled,
input[type=date]:disabled,
input[type=phone]:disabled,
input[type=tel]:disabled,
input[type=search]:disabled {
  background-color: rgba(228, 229, 230, 0.35);
}
input[type=text]:disabled::placeholder,
input[type=email]:disabled::placeholder,
input[type=date]:disabled::placeholder,
input[type=phone]:disabled::placeholder,
input[type=tel]:disabled::placeholder,
input[type=search]:disabled::placeholder {
  color: #a2c3d6;
}

textarea {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  background-color: #f2f9ff;
  border: 1px solid #f2f9ff;
  border-radius: 0;
  color: #006D9D;
  max-width: 100%;
}
textarea:focus {
  background-color: #f2f9ff;
  border-color: #006D9D;
  box-shadow: none;
}

.input-icon-container {
  position: relative;
}
.input-icon-container input[type=text],
.input-icon-container input[type=search] {
  padding-right: 2.5rem;
}
.input-icon-container > .icon-btn {
  font-size: 1.25rem;
  line-height: 1.2;
  align-items: center;
  background: none;
  border: none;
  color: #006D9D;
  display: flex;
  padding: 0;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.input-icon-container > .icon-btn .icon {
  height: 1rem;
  stroke: #006D9D;
  width: 1rem;
}
.input-icon-container > .icon-btn .icon.icon-search {
  margin-top: -2px;
}
.input-icon-container > .icon-btn:hover, .input-icon-container > .icon-btn:focus {
  outline: 0;
}
.input-icon-container > .icon-btn:hover .icon, .input-icon-container > .icon-btn:focus .icon {
  stroke: #006D9D;
}
.input-icon-container > .icon {
  stroke: #2c373f;
  height: 1rem;
  pointer-events: none;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
}
.input-icon-container > .icon.icon-search {
  height: 1rem;
  margin-top: -2px;
  width: 1rem;
}

input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-results-button, input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.field-validation-error span {
  color: #b23838;
}

.btn, .marketo-form .mktoForm .mktoButton {
  font-weight: 600;
  border-radius: 6rem;
  border-width: 5px;
  /* Default to button-primary */
}
.btn.btn-primary, .marketo-form .mktoForm .mktoButton, .btn:not([class*=btn-]) {
  color: #212529;
  background-color: #ffcd55;
  border-color: #ffcd55;
}
.btn.btn-primary:hover, .marketo-form .mktoForm .mktoButton:hover, .btn:not([class*=btn-]):hover {
  color: #212529;
  background-color: #fff;
  border-color: #ffcd55;
}
.btn.btn-primary:focus, .marketo-form .mktoForm .mktoButton:focus, .btn.btn-primary.focus, .marketo-form .mktoForm .focus.mktoButton, .btn:not([class*=btn-]):focus, .btn:not([class*=btn-]).focus {
  color: #212529;
  background-color: #fff;
  border-color: #ffcd55;
  box-shadow: 0 0 0 0.2rem rgba(222, 180, 78, 0.5);
}
.btn.btn-primary.disabled, .marketo-form .mktoForm .disabled.mktoButton, .btn.btn-primary:disabled, .marketo-form .mktoForm .mktoButton:disabled, .btn:not([class*=btn-]).disabled, .btn:not([class*=btn-]):disabled {
  color: #212529;
  background-color: #ffcd55;
  border-color: #ffcd55;
}
.btn.btn-primary:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active, .btn.btn-primary:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active, .show > .btn.btn-primary.dropdown-toggle, .marketo-form .mktoForm .show > .dropdown-toggle.mktoButton, .btn:not([class*=btn-]):not(:disabled):not(.disabled):active, .btn:not([class*=btn-]):not(:disabled):not(.disabled).active, .show > .btn:not([class*=btn-]).dropdown-toggle {
  color: #212529;
  background-color: #ffbe22;
  border-color: #ffba15;
}
.btn.btn-primary:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active:focus, .btn.btn-primary:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active:focus, .show > .btn.btn-primary.dropdown-toggle:focus, .marketo-form .mktoForm .show > .dropdown-toggle.mktoButton:focus, .btn:not([class*=btn-]):not(:disabled):not(.disabled):active:focus, .btn:not([class*=btn-]):not(:disabled):not(.disabled).active:focus, .show > .btn:not([class*=btn-]).dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 180, 78, 0.5);
}
.btn.btn-secondary, .marketo-form .mktoForm .btn-secondary.mktoButton {
  color: #fff;
  background-color: #2c373f;
  border-color: #2c373f;
}
.btn.btn-secondary:hover, .marketo-form .mktoForm .btn-secondary.mktoButton:hover {
  color: #212529;
  background-color: #fff;
  border-color: #2c373f;
}
.btn.btn-secondary:focus, .marketo-form .mktoForm .btn-secondary.mktoButton:focus, .btn.btn-secondary.focus, .marketo-form .mktoForm .btn-secondary.focus.mktoButton {
  color: #212529;
  background-color: #fff;
  border-color: #2c373f;
  box-shadow: 0 0 0 0.2rem rgba(76, 85, 92, 0.5);
}
.btn.btn-secondary.disabled, .marketo-form .mktoForm .btn-secondary.disabled.mktoButton, .btn.btn-secondary:disabled, .marketo-form .mktoForm .btn-secondary.mktoButton:disabled {
  color: #fff;
  background-color: #2c373f;
  border-color: #2c373f;
}
.btn.btn-secondary:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled):active, .btn.btn-secondary:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled).active, .show > .btn.btn-secondary.dropdown-toggle, .marketo-form .mktoForm .show > .btn-secondary.dropdown-toggle.mktoButton {
  color: #fff;
  background-color: #171d21;
  border-color: #121619;
}
.btn.btn-secondary:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled):active:focus, .btn.btn-secondary:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled).active:focus, .show > .btn.btn-secondary.dropdown-toggle:focus, .marketo-form .mktoForm .show > .btn-secondary.dropdown-toggle.mktoButton:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 85, 92, 0.5);
}
.btn.btn-tertiary, .marketo-form .mktoForm .btn-tertiary.mktoButton {
  color: #fff;
  background-color: #005557;
  border-color: #005557;
}
.btn.btn-tertiary:hover, .marketo-form .mktoForm .btn-tertiary.mktoButton:hover {
  color: #212529;
  background-color: #fff;
  border-color: #005557;
}
.btn.btn-tertiary:focus, .marketo-form .mktoForm .btn-tertiary.mktoButton:focus, .btn.btn-tertiary.focus, .marketo-form .mktoForm .btn-tertiary.focus.mktoButton {
  color: #212529;
  background-color: #fff;
  border-color: #005557;
  box-shadow: 0 0 0 0.2rem rgba(38, 111, 112, 0.5);
}
.btn.btn-tertiary.disabled, .marketo-form .mktoForm .btn-tertiary.disabled.mktoButton, .btn.btn-tertiary:disabled, .marketo-form .mktoForm .btn-tertiary.mktoButton:disabled {
  color: #fff;
  background-color: #005557;
  border-color: #005557;
}
.btn.btn-tertiary:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .btn-tertiary.mktoButton:not(:disabled):not(.disabled):active, .btn.btn-tertiary:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .btn-tertiary.mktoButton:not(:disabled):not(.disabled).active, .show > .btn.btn-tertiary.dropdown-toggle, .marketo-form .mktoForm .show > .btn-tertiary.dropdown-toggle.mktoButton {
  color: #fff;
  background-color: #002324;
  border-color: #001717;
}
.btn.btn-tertiary:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .btn-tertiary.mktoButton:not(:disabled):not(.disabled):active:focus, .btn.btn-tertiary:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .btn-tertiary.mktoButton:not(:disabled):not(.disabled).active:focus, .show > .btn.btn-tertiary.dropdown-toggle:focus, .marketo-form .mktoForm .show > .btn-tertiary.dropdown-toggle.mktoButton:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 111, 112, 0.5);
}
.btn.btn-primary, .marketo-form .mktoForm .mktoButton, .btn.btn-secondary, .btn.btn-tertiary {
  width: 100%;
}
.btn.btn-primary svg, .marketo-form .mktoForm .mktoButton svg, .btn.btn-secondary svg, .btn.btn-tertiary svg {
  margin: 0 -8px 0 10px;
}
@media (min-width: 576px) {
  .btn.btn-primary, .marketo-form .mktoForm .mktoButton, .btn.btn-secondary, .btn.btn-tertiary {
    width: auto;
  }
}
.btn.btn-secondary svg, .marketo-form .mktoForm .btn-secondary.mktoButton svg, .btn.btn-tertiary svg, .marketo-form .mktoForm .btn-tertiary.mktoButton svg {
  stroke: #fff;
}
.btn.btn-secondary:hover .btn.btn-secondary:focus svg, .marketo-form .mktoForm .btn-secondary.mktoButton:hover .btn.btn-secondary:focus svg, .btn.btn-secondary:hover .marketo-form .mktoForm .btn-secondary.mktoButton:focus svg, .marketo-form .mktoForm .btn.btn-secondary:hover .btn-secondary.mktoButton:focus svg, .marketo-form .mktoForm .btn-secondary.mktoButton:hover .btn-secondary.mktoButton:focus svg, .btn.btn-secondary:hover .btn.btn-tertiary:focus svg, .marketo-form .mktoForm .btn-secondary.mktoButton:hover .btn.btn-tertiary:focus svg, .btn.btn-secondary:hover .marketo-form .mktoForm .btn-tertiary.mktoButton:focus svg, .marketo-form .mktoForm .btn.btn-secondary:hover .btn-tertiary.mktoButton:focus svg, .marketo-form .mktoForm .btn-secondary.mktoButton:hover .btn-tertiary.mktoButton:focus svg, .btn.btn-tertiary:hover .btn.btn-secondary:focus svg, .marketo-form .mktoForm .btn-tertiary.mktoButton:hover .btn.btn-secondary:focus svg, .btn.btn-tertiary:hover .marketo-form .mktoForm .btn-secondary.mktoButton:focus svg, .marketo-form .mktoForm .btn.btn-tertiary:hover .btn-secondary.mktoButton:focus svg, .marketo-form .mktoForm .btn-tertiary.mktoButton:hover .btn-secondary.mktoButton:focus svg, .btn.btn-tertiary:hover .btn.btn-tertiary:focus svg, .marketo-form .mktoForm .btn-tertiary.mktoButton:hover .btn.btn-tertiary:focus svg, .btn.btn-tertiary:hover .marketo-form .mktoForm .btn-tertiary.mktoButton:focus svg, .marketo-form .mktoForm .btn.btn-tertiary:hover .btn-tertiary.mktoButton:focus svg, .marketo-form .mktoForm .btn-tertiary.mktoButton:hover .btn-tertiary.mktoButton:focus svg {
  stroke: #2c373f;
}
.btn.btn-shadow, .marketo-form .mktoForm .btn-shadow.mktoButton {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}
.btn.submitting, .marketo-form .mktoForm .submitting.mktoButton {
  display: flex;
}
.btn.submitting:after, .marketo-form .mktoForm .submitting.mktoButton:after {
  border: 2px solid #fff;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  content: " ";
  display: block;
  pointer-events: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: spin 1.4s infinite linear;
  position: relative;
  left: 10px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.rte .btn, .rte .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .rte .mktoButton {
  margin: 0.375rem 0.25rem;
}
.btn.btn-disabled, .marketo-form .mktoForm .btn-disabled.mktoButton {
  pointer-events: none;
  background-color: #cbced1;
  border-color: #cbced1;
  color: #2c373f;
  box-shadow: none;
}

.link-forward {
  color: #006D9D;
  display: inline-block;
  padding-right: 1rem;
}
.link-forward:after {
  display: inline-block;
  content: " ";
  border-left: 6px solid #ffcd55;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  margin-left: 0.5rem;
  margin-right: -1rem;
}
button.link-forward {
  background-color: transparent;
  border: none;
  padding: 0 1rem 0 0;
  text-align: left;
}

.link-forward > * {
  pointer-events: none;
}
@media (min-width: 992px) {
  .link-forward {
    padding-right: 1.125rem;
  }
  .link-forward:after {
    margin-left: 0.75rem;
  }
  button.link-forward {
    padding: 0 1.125rem 0 0;
  }
}

.link-backward {
  color: #006D9D;
}
.link-backward:before {
  display: inline-block;
  content: " ";
  border-right: 6px solid #ffcd55;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  margin-right: 0.5rem;
}
@media (min-width: 992px) {
  .link-backward:before {
    margin-right: 0.75rem;
  }
}

.range-container {
  position: relative;
}
.range-container label {
  font-size: 1rem;
  line-height: 1.125;
}
.range-container input {
  display: block;
}
.range-container .range-label-list {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 6px 0 0;
  padding: 0 0 0 1.5rem;
}
.range-container .range-label-list li {
  flex: 1 1 0;
  text-align: left;
  transform: translateX(-0.75rem);
}
.range-container .range-label-list li.active {
  color: #a2c3d6;
  font-weight: 700;
}
.range-container .range-label-list li.active span {
  color: #a2c3d6;
  font-weight: 700;
}
.range-container .range-label-list li.active span:before {
  display: none;
}
.range-container .range-label-list li:first-of-type span {
  transform: translateX(-0.75rem);
}
.range-container .range-label-list li:first-of-type span:before {
  left: 0;
  transform: none;
}
.range-container .range-label-list li:last-of-type {
  position: absolute;
  right: 0;
  text-align: right;
  transform: none;
}
.range-container .range-label-list li:last-of-type span {
  transform: none;
}
.range-container .range-label-list li:last-of-type span:before {
  left: auto;
  right: 0;
  transform: none;
}
.range-container .range-label-list li span {
  display: inline-block;
  position: relative;
  transform: translateX(-50%);
}
.range-container .range-label-list li span:before {
  background-color: #cbced1;
  border-radius: 50%;
  content: " ";
  display: block;
  height: 0.75rem;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: -27px;
  transform: translateX(-50%);
  width: 0.75rem;
}

.dropdown-menu {
  border-color: #cbced1;
  border-radius: 0;
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.12);
  margin: 0;
  max-height: none !important;
  padding: 0;
  width: 100%;
}
.dropdown-menu .dropdown-item {
  font-size: 1rem;
  line-height: 1.125;
  color: #006D9D;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item.active {
  background-color: #2c373f;
  color: #fff;
}
.dropdown-menu .dropdown-item:hover .icon, .dropdown-menu .dropdown-item.active .icon {
  stroke: #fff;
}
.dropdown-menu .dropdown-item .rbt-highlight-text {
  font-weight: 600;
}
.dropdown-menu .dropdown-item > span:first-of-type {
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkbox-wrapper, html .microsite-theme .marketo-form .mktoForm .mktoCheckboxList,
html .moments-theme .marketo-form .mktoForm .mktoCheckboxList, .marketo-form .mktoForm .mktoCheckboxList {
  align-items: flex-start;
  display: flex;
  justify-items: flex-start;
  margin-bottom: 0.75rem;
  position: relative;
}
.checkbox-wrapper.centered input[type=checkbox], .marketo-form .mktoForm .centered.mktoCheckboxList input[type=checkbox] {
  align-items: center;
}
.checkbox-wrapper.centered input[type=checkbox]:checked + label:after, .marketo-form .mktoForm .centered.mktoCheckboxList input[type=checkbox]:checked + label:after {
  top: 50%;
  margin-top: -6px;
}
.checkbox-wrapper.centered input[type=checkbox] + label:before, .marketo-form .mktoForm .centered.mktoCheckboxList input[type=checkbox] + label:before {
  top: 50%;
  transform: translateY(-50%);
}
.checkbox-wrapper input[type=checkbox], .marketo-form .mktoForm .mktoCheckboxList input[type=checkbox] {
  margin-top: 2px;
  opacity: 0;
}
.checkbox-wrapper input[type=checkbox]:checked + label:after, .marketo-form .mktoForm .mktoCheckboxList input[type=checkbox]:checked + label:after {
  border-bottom: 3px solid #2c373f;
  border-right: 3px solid #2c373f;
  content: "";
  display: block;
  height: 0.75rem;
  left: 0;
  margin: 0.25rem 0.375rem;
  position: absolute;
  top: 0;
  transform: rotate(45deg) translate(-1px, -1px);
  width: 0.5rem;
}
.checkbox-wrapper input[type=checkbox]:hover, .marketo-form .mktoForm .mktoCheckboxList input[type=checkbox]:hover {
  cursor: pointer;
}
.checkbox-wrapper input[type=checkbox]:hover + label:before, .marketo-form .mktoForm .mktoCheckboxList input[type=checkbox]:hover + label:before {
  border-color: #006D9D;
}
.checkbox-wrapper input[type=checkbox]:focus + label:before, .marketo-form .mktoForm .mktoCheckboxList input[type=checkbox]:focus + label:before {
  box-shadow: 0 0 0 0.2rem rgba(0, 109, 157, 0.25);
}
.checkbox-wrapper input[type=checkbox] + label:before, .marketo-form .mktoForm .mktoCheckboxList input[type=checkbox] + label:before {
  border: 2px solid #cbced1;
  border-radius: 5px;
  content: "";
  display: block;
  height: 1.25rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 1.25rem;
}
.checkbox-wrapper input[type=checkbox] + label:hover, .marketo-form .mktoForm .mktoCheckboxList input[type=checkbox] + label:hover {
  cursor: pointer;
}
.checkbox-wrapper input[type=checkbox] + label:hover:before, .marketo-form .mktoForm .mktoCheckboxList input[type=checkbox] + label:hover:before {
  border-color: #006D9D;
}
.checkbox-wrapper label, html .microsite-theme .marketo-form .mktoForm .mktoCheckboxList label,
html .moments-theme .marketo-form .mktoForm .mktoCheckboxList label, .marketo-form .mktoForm .mktoCheckboxList label {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0 0 0 1rem;
  width: 100%;
}
.checkbox-wrapper label .label-name, .marketo-form .mktoForm .mktoCheckboxList label .label-name {
  flex-grow: 1;
}
.checkbox-wrapper label .label-number, .marketo-form .mktoForm .mktoCheckboxList label .label-number {
  padding-left: 1rem;
}

.radio-wrapper, .marketo-form .mktoForm .mktoRadioList {
  align-items: flex-start;
  display: flex;
  justify-items: flex-start;
  margin-bottom: 0.75rem;
  position: relative;
}
.radio-wrapper input[type=radio], .marketo-form .mktoForm .mktoRadioList input[type=radio] {
  margin-top: 2px;
  opacity: 0;
}
.radio-wrapper input[type=radio]:checked + label:after, .marketo-form .mktoForm .mktoRadioList input[type=radio]:checked + label:after {
  background-color: #2c373f;
  border-radius: 50%;
  content: "";
  display: block;
  height: 0.75rem;
  left: 0;
  margin: 0.25rem;
  position: absolute;
  top: 0;
  width: 0.75rem;
}
.radio-wrapper input[type=radio]:hover, .marketo-form .mktoForm .mktoRadioList input[type=radio]:hover {
  cursor: pointer;
}
.radio-wrapper input[type=radio]:hover + label:before, .marketo-form .mktoForm .mktoRadioList input[type=radio]:hover + label:before {
  border-color: #006D9D;
}
.radio-wrapper input[type=radio]:focus + label:before, .marketo-form .mktoForm .mktoRadioList input[type=radio]:focus + label:before {
  box-shadow: 0 0 0 0.2rem rgba(0, 109, 157, 0.25);
}
.radio-wrapper input[type=radio] + label:before, .marketo-form .mktoForm .mktoRadioList input[type=radio] + label:before {
  border: 2px solid #cbced1;
  border-radius: 50%;
  content: "";
  display: block;
  height: 1.25rem;
  left: 0;
  position: absolute;
  width: 1.25rem;
}
.radio-wrapper input[type=radio] + label:hover, .marketo-form .mktoForm .mktoRadioList input[type=radio] + label:hover {
  cursor: pointer;
}
.radio-wrapper input[type=radio] + label:hover:before, .marketo-form .mktoForm .mktoRadioList input[type=radio] + label:hover:before {
  border-color: #3d5d6b;
}
.radio-wrapper label, .marketo-form .mktoForm .mktoRadioList label {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0 0 0 1rem;
  width: 100%;
}
.radio-wrapper label .label-name, .marketo-form .mktoForm .mktoRadioList label .label-name {
  flex-grow: 1;
}
.radio-wrapper label .label-number, .marketo-form .mktoForm .mktoRadioList label .label-number {
  padding-left: 1rem;
}

.radio-btns {
  display: flex;
}
.radio-btns .radio-wrapper, .radio-btns .marketo-form .mktoForm .mktoRadioList, .marketo-form .mktoForm .radio-btns .mktoRadioList {
  flex: 1;
  margin-right: 0.5rem;
  margin-bottom: 0;
}
.radio-btns .radio-wrapper:last-child, .radio-btns .marketo-form .mktoForm .mktoRadioList:last-child, .marketo-form .mktoForm .radio-btns .mktoRadioList:last-child {
  margin-right: 0;
}
.radio-btns input[type=radio] {
  height: 0;
  width: 0;
}
.radio-btns input[type=radio]:checked + label {
  background-color: #2c373f;
  border-color: #2c373f;
}
.radio-btns input[type=radio]:checked + label:before, .radio-btns input[type=radio]:checked + label:after {
  display: none;
}
.radio-btns input[type=radio]:focus + label {
  background-color: #2c373f;
  border-color: #2c373f;
  box-shadow: 0 0 0 0.2rem rgba(44, 55, 63, 0.35);
}
.radio-btns input[type=radio] + label {
  align-items: center;
  background-color: #a2c3d6;
  border: 1px solid #a2c3d6;
  border-radius: 0.625rem;
  color: #fff;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  line-height: 1.375;
  padding: 0.75rem 0.25rem;
  width: 100%;
}
.radio-btns input[type=radio] + label:before, .radio-btns input[type=radio] + label:after {
  display: none;
}
.radio-btns input[type=radio] + label:hover {
  background-color: #2c373f;
  border-color: #2c373f;
}
.radio-btns input[type=radio] + label span {
  text-align: center;
}
@media (min-width: 1200px) {
  .radio-btns .radio-wrapper, .radio-btns .marketo-form .mktoForm .mktoRadioList, .marketo-form .mktoForm .radio-btns .mktoRadioList {
    margin-right: 1rem;
  }
}

.rbt {
  position: relative;
}
.rbt.loading:after {
  border: 2px solid #006D9D;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  content: " ";
  display: block;
  pointer-events: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: spin 1.4s infinite linear;
  position: relative;
  left: 10px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.rbt.loading:after {
  margin-top: -10px;
  position: absolute;
  right: 1rem;
  top: 50%;
}
.rbt.loading > .icon-btn,
.rbt.loading > .icon {
  display: none;
}
.rbt > .icon-btn {
  font-size: 1.25rem;
  line-height: 1.2;
  align-items: center;
  background: none;
  border: none;
  color: #006D9D;
  display: flex;
  padding: 0;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.rbt > .icon-btn .icon {
  fill: #006D9D;
  height: 1rem;
  stroke: #006D9D;
  width: 1rem;
}
.rbt > .icon-btn .icon.icon-search {
  margin-top: -2px;
}
.rbt > .icon-btn .icon.icon-close {
  height: 0.8rem;
  width: 0.8rem;
}
.rbt > .icon-btn:hover, .rbt > .icon-btn:focus {
  outline: 0;
}
.rbt > .icon-btn:hover .icon, .rbt > .icon-btn:focus .icon {
  fill: #006D9D;
  stroke: #006D9D;
}
.rbt > .icon {
  stroke: #006D9D;
  height: 1rem;
  pointer-events: none;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
}
.rbt > .icon.disabled {
  fill: #a2c3d6;
  stroke: #a2c3d6;
}
.rbt > .icon.icon-search {
  height: 1rem;
  margin-top: -2px;
  width: 1rem;
}

.rbt-input-main {
  padding-right: 2.5rem;
}
.rbt-input-main + div {
  max-width: 100%;
  overflow: hidden;
}
.rbt-input-main.btn-shadow {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.header-module .heading-3 {
  font-size: 1.75rem;
  line-height: 1.0714285714;
  color: #2c373f;
}

.divider {
  /* applied to the <hr> */
  border-bottom: 0.25rem solid #ffcd55 !important;
  margin: 1rem auto 1rem 0;
  width: 4.375rem;
  border-top: none;
}
.divider-header {
  border-bottom: 0.5rem solid #ffcd55 !important;
  margin: 1rem auto 3.5rem;
  width: 4.375rem;
  border-top: none;
}

.card {
  border: 1px solid transparent;
  border-radius: 0;
}
.card.transparent-border {
  border-color: transparent;
}

.profile-image {
  background-color: #fff;
  border: solid 0 #fff;
  border-radius: 50%;
  height: 130px;
  min-width: 130px;
  overflow: hidden;
  position: relative;
  width: 130px;
}
.profile-image img {
  height: 100%;
  object-fit: cover;
  object-position: top;
  font-family: "object-fit: cover; object-position: top;";
  width: 100%;
}
.profile-image svg {
  height: 100%;
  width: 100%;
}

/* accomodate classes exposed in the Sitecore Rich Text Editor */
img.image-left, img.image-center, img.image-right {
  display: block;
  margin: 0 auto 1rem;
  max-width: 100%;
  height: auto !important;
}
@media (min-width: 768px) {
  img.image-left {
    float: left;
    margin-right: 1.5rem;
    max-width: 50%;
  }
  img.image-right {
    float: right;
    margin-left: 1.5rem;
    max-width: 50%;
  }
  img.image-center {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
  img.image-left, img.image-right {
    max-width: 60%;
  }
}

img,
source {
  border-radius: 5px;
}
img.img-zero-radius,
source.img-zero-radius {
  border-radius: 0;
}

.appointment-type {
  position: relative;
}
.appointment-type:before {
  border: 2px solid #3d5d6b;
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 0.625rem;
  margin-right: 0.25rem;
  width: 0.625rem;
}
.appointment-type.patients-new:before {
  background-color: #00a084;
  border-color: #00a084;
}
.appointment-type.patients-existing:before {
  background-color: #0090ff;
  border-color: #0090ff;
}

.appointment-type-label {
  font-size: 1rem;
  line-height: 1.125;
  color: #3d5d6b;
  display: block;
  padding-bottom: 1rem;
}
.appointment-type-label .appointment-type {
  margin-left: 0.125rem;
}
@media (min-width: 768px) {
  .appointment-type-label {
    font-weight: 600;
  }
}

.section-title {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #2c373f;
  font-weight: 700;
  margin-bottom: 1.75rem;
  padding-bottom: 1.25rem;
  position: relative;
  text-align: center;
}
.section-title:after {
  background-color: #ffcd55;
  bottom: 0;
  content: "";
  display: block;
  height: 4px;
  left: calc(50% - 29px);
  position: absolute;
  width: 58px;
}
@media (min-width: 768px) {
  .section-title {
    font-size: 1.5rem;
    line-height: 1.25;
    text-align: left;
  }
  .section-title:after {
    left: 0;
  }
}

.section-subtitle {
  color: #006D9D;
  font-weight: 700;
  margin-bottom: 1rem;
}

.results-control-dropdown .select-wrapper {
  position: relative;
}
.results-control-dropdown .select-wrapper select {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  background-color: #fff;
  border: 0;
  color: #2c373f;
  padding: 0.25rem 2rem 0.25rem 0.25rem;
  appearance: none;
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
  /* For IE10 */
}
.results-control-dropdown .select-wrapper select::-ms-expand {
  display: none;
}
.results-control-dropdown .select-wrapper select:focus-visible {
  outline: #e4e5e6 auto 1px;
}
.results-control-dropdown .select-wrapper select .dropdown-item {
  appearance: none;
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
  background-color: #fff;
}
.results-control-dropdown .select-wrapper .icon {
  pointer-events: none;
  position: absolute;
  right: 0.25rem;
  stroke: #006D9D;
  top: 0.5rem;
}

.loading-overlay {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  left: -15px;
  opacity: 0;
  padding-top: 150px;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.4s ease-in-out;
  width: calc(100% + 30px);
  z-index: 2;
}
.loading-overlay:after {
  border: 5px solid #006D9D;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  display: block;
  pointer-events: none;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  animation: spin 1.4s infinite linear;
  position: relative;
  left: 10px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.chat-icon {
  z-index: 1000;
  bottom: 3rem;
  right: 1rem;
  position: fixed;
}
.chat-icon img {
  display: none;
}
.chat-icon img.show {
  display: block;
  height: 5rem;
  width: 5rem;
}

.img-legacy {
  display: table;
  width: auto;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.img-legacy .figure-text {
  display: table-caption;
  caption-side: bottom;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}
.img-legacy .figure-caption {
  font-size: 1rem;
  line-height: 1.125;
  color: #3d5d6b;
}
.img-legacy .figure-caption ~ .figure-credits {
  padding-top: 0.5rem;
}
.img-legacy .figure-credits {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  color: #a2c3d6;
  font-style: italic;
}

#hp_private_label {
  margin-top: 1rem;
  min-height: calc(100vh - 200px);
}

.BBFormSubmitbutton {
  background-color: #ffcd55 !important;
  border: 5px solid #ffcd55 !important;
  border-radius: 6rem !important;
  box-shadow: none !important;
  color: #2c373f !important;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 1.125rem !important;
  font-weight: 600 !important;
  line-height: 1.375rem !important;
  padding: 0.75rem 3.375rem !important;
}
.BBFormSubmitbutton:hover {
  background-color: #fff !important;
  border-color: #ffcd55 !important;
  opacity: 1 !important;
}
.BBFormSubmitbutton:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 235, 187, 0.5) !important;
  outline: 0 !important;
}

.video-modal:not(.show) {
  display: block;
  opacity: 0 !important;
  pointer-events: none !important;
}
.video-modal:not(.show) .modal-content {
  pointer-events: none !important;
}
.video-modal:not(.show) .modal-content * {
  pointer-events: none !important;
}

@media (min-width: 992px) {
  :target[id]:before,
:target[name]:before {
    content: "";
    display: block;
    height: 70px;
    margin-top: -70px;
    pointer-events: none;
    visibility: hidden;
  }
  .healthplan-theme :target[id]:before,
.healthplan-theme :target[name]:before {
    height: 60px;
    margin-top: -60px;
  }
  .table-lg-50 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .table-lg-75 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
.mhBlockit #screening_container .text-center {
  text-align: left !important;
}
.mhBlockit #screening_container h3 {
  color: #212529;
  font-weight: 600;
  padding-top: 1rem;
}
.mhBlockit #screening_container h3:after {
  background-color: #ffcd55;
  display: block;
  position: relative;
  content: "";
  height: 4px;
  margin: 1rem auto 0 0;
  width: 4.375rem;
}
.mhBlockit #screening_container .form-group {
  margin-bottom: 0;
}
.mhBlockit #screening_container label {
  font-weight: 600;
}
.mhBlockit #screening_container label.border-gray-300 {
  border: 1px solid #ced4da !important;
}
.mhBlockit #screening_container input[type=date],
.mhBlockit #screening_container input[type=email],
.mhBlockit #screening_container input[type=phone],
.mhBlockit #screening_container input[type=search],
.mhBlockit #screening_container input[type=tel],
.mhBlockit #screening_container input[type=text] {
  background-color: #fff;
}
.mhBlockit #screening_container .flex-row-reverse {
  flex-direction: row !important;
}
.mhBlockit #screening_container #screening-submit,
.mhBlockit #screening_container #screening-cancel {
  border-radius: 6rem;
  border-width: 5px !important;
  font-weight: 600;
}
.mhBlockit #screening_container #screening-submit {
  color: #212529 !important;
  background-color: #ffcd55;
  border-color: #ffcd55 !important;
  margin-left: 0;
  margin-bottom: 0.5rem;
}
.mhBlockit #screening_container #screening-submit:hover {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #ffcd55 !important;
}
.mhBlockit #screening_container #screening-cancel {
  color: #006D9D !important;
  background: none !important;
  border-color: #fff !important;
  box-shadow: none !important;
  /*text-decoration:none !important;*/
}
.mhBlockit #screening_container #screening-cancel:hover {
  color: #006D9D !important;
  background-color: #fff;
  border-color: none !important;
}
.mhBlockit #screening_container .phx-no-feedback .help-block,
.mhBlockit #screening_container .has-success .help-block {
  margin-bottom: 0;
}
.mhBlockit .bi-search-results .show-past-dates,
.mhBlockit .bi-search-results .show-future-dates {
  background-color: #212529;
  border-color: #212529;
  border-radius: 6rem !important;
}
.mhBlockit .bi-search-results .show-past-dates:hover, .mhBlockit .bi-search-results .show-past-dates:focus, .mhBlockit .bi-search-results .show-past-dates:active,
.mhBlockit .bi-search-results .show-future-dates:hover,
.mhBlockit .bi-search-results .show-future-dates:focus,
.mhBlockit .bi-search-results .show-future-dates:active {
  background-color: #fff !important;
  border-color: #212529 !important;
}
.mhBlockit .bi-search-results .show-past-dates:hover .icon, .mhBlockit .bi-search-results .show-past-dates:focus .icon, .mhBlockit .bi-search-results .show-past-dates:active .icon,
.mhBlockit .bi-search-results .show-future-dates:hover .icon,
.mhBlockit .bi-search-results .show-future-dates:focus .icon,
.mhBlockit .bi-search-results .show-future-dates:active .icon {
  fill: #212529;
}
.mhBlockit .questionnaire-radio label:not(:first-child) {
  margin-top: 0;
}
@media (min-width: 640px) {
  .mhBlockit .questionnaire-radio label:first-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .mhBlockit .questionnaire-radio label:last-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    margin-left: 0;
  }
  .mhBlockit .questionnaire-actions {
    justify-content: space-between;
  }
}

.icon {
  height: 0.75rem;
  width: 1rem;
  stroke: #2c373f;
  stroke-linecap: round;
  stroke-width: 2px;
}
.icon use path {
  all: inherit;
}
.icon.icon-search {
  height: 1.15rem;
  width: 1.15rem;
  margin-top: -6px;
}
.icon.icon-search-circle, .icon.icon-arrow-right-circle {
  height: 1.6875rem;
  width: 1.6875rem;
}
.icon.icon-callout-asterisk, .icon.icon-callout-bowl, .icon.icon-callout-doctor, .icon.icon-callout-location, .icon.icon-callout-services {
  height: 4.5rem;
  width: 4.5rem;
}
.icon.icon-callout-asterisk {
  fill: #c22032;
  stroke: transparent;
}
.icon.icon-callout-bowl {
  fill: #00aa8f;
  stroke: transparent;
}
.icon.icon-callout-doctor {
  stroke: #0776ab;
}
.icon.icon-arrow-left-thin, .icon.icon-menu-open, .icon.icon-menu-close {
  stroke: #5a5a5a;
}
.icon.icon-alert, .icon.icon-arrow-down, .icon.icon-arrow-left, .icon.icon-arrow-right, .icon.icon-arrow-right-circle, .icon.icon-arrow-up, .icon.icon-arrow-long-down, .icon.icon-arrow-long-left, .icon.icon-arrow-long-right, .icon.icon-arrow-long-up, .icon.icon-building, .icon.icon-calendar, .icon.icon-camera, .icon.icon-clock, .icon.icon-close, .icon.icon-cta-arrow, .icon.icon-doctor, .icon.icon-follow-facebook, .icon.icon-follow-instagram, .icon.icon-follow-linkedin, .icon.icon-follow-twitter, .icon.icon-follow-youtube, .icon.icon-form-calendar, .icon.icon-globe, .icon.icon-home, .icon.icon-map-cluser, .icon.icon-map-marker, .icon.icon-map-pin, .icon.icon-message, .icon.icon-phone, .icon.icon-plus-circle, .icon.icon-profile, .icon.icon-search-circle, .icon.icon-share-chat-filled, .icon.icon-share-print-filled, .icon.icon-star, .icon.icon-video, .icon.icon-view-list, .icon.icon-view-tile {
  stroke-width: 0;
  fill: #2c373f;
}
.icon.icon-doctor {
  stroke: transparent;
}
.icon.icon-phone, .icon.icon-share-facebook, .icon.icon-share-facebook-filled, .icon.icon-share-instagram, .icon.icon-share-linkedin, .icon.icon-share-print, .icon.icon-share-twitter, .icon.icon-share-twitter-filled, .icon.icon-share-youtube, .icon.icon-map-cluser, .icon.icon-map-marker {
  fill: #2c373f;
  height: 2rem;
  width: 2rem;
}

.two-column [class^=col] > div:not(.alt-background),
.three-column [class^=col] > div:not(.alt-background) {
  padding-left: 0;
  padding-right: 0;
}

noscript {
  display: block;
  padding: 2rem;
  text-align: center;
  width: 100%;
}

@media print {
  .hamburger-nav {
    display: none;
  }
}
html[dir=rtl] {
  text-align: right;
}
html[dir=rtl] ul:not([class]),
html[dir=rtl] ul.two-columns,
html[dir=rtl] ul.three-columns,
html[dir=rtl] ul.four-columns,
html[dir=rtl] .bulleted-list {
  padding-left: 0;
  padding-right: 1.5rem;
}
html[dir=rtl] ul:not([class]) > li,
html[dir=rtl] ul.two-columns > li,
html[dir=rtl] ul.three-columns > li,
html[dir=rtl] ul.four-columns > li,
html[dir=rtl] .bulleted-list > li {
  margin-left: 0;
  margin-right: 1rem;
}
html[dir=rtl] ul:not([class]) > li:before,
html[dir=rtl] ul.two-columns > li:before,
html[dir=rtl] ul.three-columns > li:before,
html[dir=rtl] ul.four-columns > li:before,
html[dir=rtl] .bulleted-list > li:before {
  margin-left: 0;
  margin-right: -1rem;
}
html[dir=rtl] .global-navigation .image-cta picture {
  left: 0;
}
html[dir=rtl] .global-navigation .image-cta .text-overlay svg {
  transform: rotate(180deg);
}
html[dir=rtl] .global-navigation .image-cta:hover .text-overlay .svg-container, html[dir=rtl] .global-navigation .image-cta:focus .text-overlay .svg-container {
  transform: translate(-4px);
}
html[dir=rtl] .global-navigation .quick-links-header .heading-6 {
  direction: ltr;
}
html[dir=rtl] .global-navigation .quick-links-note {
  direction: ltr;
}
html[dir=rtl] .global-navigation .hamburger-nav .global-search-trigger {
  text-align: right;
}
html[dir=rtl] .global-navigation .hamburger-nav .global-search-trigger .icon-search {
  right: auto;
  left: 1rem;
}
html[dir=rtl] .global-navigation .hamburger-nav .primary-nav .nav-links li.has-sub-nav > a .icon-arrow-right {
  right: 0.5rem;
}
html[dir=rtl] .global-search-typeahead .search-form .submit-search {
  right: auto;
  left: 2rem;
}
html[dir=rtl] .global-search-typeahead .search-input-container {
  margin-right: -15px;
  margin-left: auto;
}
html[dir=rtl] .global-search-typeahead .search-overlay-close {
  right: auto;
  left: 1rem;
}
html[dir=rtl] .global-search-typeahead .search-dropdown {
  text-align: right;
}
html[dir=rtl] .global-search-typeahead .search-dropdown ul li {
  text-align: right;
}
html[dir=rtl] .global-search-typeahead .search-dropdown .search-doctors li {
  text-align: right;
}
html[dir=rtl] .global-search-typeahead .search-dropdown .search-doctors .profile-image {
  margin-left: 1.5rem;
  margin-right: 0;
}
html[dir=rtl] .global-search-typeahead .search-dropdown .search-locations li {
  text-align: right;
}
html[dir=rtl] .global-search-typeahead .search-dropdown .search-locations svg {
  margin-left: 1.5rem;
  margin-right: 0;
}
html[dir=rtl] .global-search-typeahead .view-all-link {
  direction: ltr;
}
html[dir=rtl] .message .alert .icon-message {
  margin-left: 2rem;
  margin-right: 0;
}
html[dir=rtl] .leftnav .accordion-link {
  text-align: right;
}
html[dir=rtl] .leftnav .accordion-item-label {
  text-align: right;
}
html[dir=rtl] .rte {
  text-align: right;
}
html[dir=rtl] .hero-primary .search-dropdown {
  text-align: right;
}
html[dir=rtl] .hero-article .divider-hero {
  margin: 0.75rem 0 0.5rem auto;
}
html[dir=rtl] .tool .tool-content {
  text-align: right;
}
html[dir=rtl] .tool .select-custom .icon-arrow-down {
  left: 0.75rem;
  right: auto;
}
html[dir=rtl] .tool .form-group {
  text-align: right;
}
html[dir=rtl] .tool .btn-minified-submit svg {
  transform: rotate(180deg);
}
html[dir=rtl] .page-header .breadcrumb-item > a > .icon {
  transform: rotate(180deg);
}
html[dir=rtl] .page-header .language-dropdown {
  left: 0;
  right: auto;
}
html[dir=rtl] .page-header .language-dropdown .dropdown-menu {
  left: 0;
  right: auto;
}
html[dir=rtl] .page-header .language-dropdown + .heading-1 {
  text-align: right;
}
html[dir=rtl] .page-header .divider {
  margin-left: auto;
  margin-right: 0;
}
html[dir=rtl] .social-share a,
html[dir=rtl] .social-share button {
  margin-left: 0;
  margin-right: 0.75rem;
}
html[dir=rtl] .social-share a:first-of-type,
html[dir=rtl] .social-share button:first-of-type {
  margin-right: 0;
}
html[dir=rtl] .one-up .divider {
  margin: 1.75rem 0 1.75rem auto;
}
html[dir=rtl] .two-up .divider {
  margin: 1rem 0 1rem auto;
}
html[dir=rtl] .three-up .three-up-content {
  text-align: right;
}
html[dir=rtl] .three-up .divider-syndicated {
  margin-right: 0;
  margin-left: auto;
}
html[dir=rtl] .three-up p span {
  padding-right: 0;
  padding-left: 0.5rem;
}
html[dir=rtl] .three-up p span:after {
  padding-right: 0.5rem;
  padding-left: 0;
}
html[dir=rtl] .footer .marketing p {
  direction: ltr;
}
html[dir=rtl] .footer .social-icons a {
  margin-left: 0;
  margin-right: 0.75rem;
}
html[dir=rtl] .footer .social-icons a:first-of-type {
  margin-right: 0;
}
html[dir=rtl] .footer .link-lists,
html[dir=rtl] .footer .link-lists-constant {
  text-align: right;
}
html[dir=rtl] .footer .link-lists ol,
html[dir=rtl] .footer .link-lists-constant ol {
  padding-right: 0;
}
html[dir=rtl] .footer .link-lists ol li ol,
html[dir=rtl] .footer .link-lists-constant ol li ol {
  padding-right: 0;
}
html[dir=rtl] .footer .policy-terms ol {
  padding-right: 0;
}
html[dir=rtl] .footer .copyright {
  direction: ltr;
}
@media (min-width: 576px) {
  html[dir=rtl] .related-list-container .related-list.doctor .related-list-item .related-DoL {
    margin-right: 44px;
    margin-left: 0;
  }
  html[dir=rtl] .related-list-container .related-list.doctor .related-list-item .related-DoL .card-header {
    padding: 1.5rem 0.5rem 1.5rem 4rem;
  }
  html[dir=rtl] .related-list-container .related-list.doctor .related-list-item .related-DoL .doctor-details {
    text-align: right;
  }
  html[dir=rtl] .related-list-container .related-list.doctor .related-list-item .related-DoL .profile-image {
    margin: auto -64px auto 1rem;
  }
}
@media (min-width: 768px) {
  html[dir=rtl] .hero-primary .hero-content-container {
    justify-content: flex-end;
  }
  html[dir=rtl] .hero-primary .hero-content-container:not(.center):not(.right) .global-search-typeahead .search-dropdown {
    left: calc(50% + 4rem);
  }
  html[dir=rtl] .hero-primary .hero-content-container.center {
    justify-content: center;
  }
  html[dir=rtl] .hero-primary .hero-content-container.center .global-search-typeahead .search-dropdown {
    left: 50%;
  }
  html[dir=rtl] .hero-primary .hero-content-container.right {
    justify-content: flex-start;
  }
  html[dir=rtl] .hero-secondary .hero-content-container.right {
    justify-content: flex-start;
  }
  html[dir=rtl] .hero-article .divider-hero {
    margin: 1.25rem 0 0.5rem auto;
  }
  html[dir=rtl] .tool.minified .form-group {
    padding-left: 2rem;
    padding-right: 0;
  }
  html[dir=rtl] .tool.minified .btn-minified-submit {
    right: auto;
    left: 0;
  }
  html[dir=rtl] .page-header .social {
    float: left;
    padding-left: 0;
    padding-right: 3rem;
  }
  html[dir=rtl] .page-header .language-dropdown + .heading-1 {
    padding-right: 0;
    padding-left: 100px;
  }
  html[dir=rtl] .one-up .one-up-inner {
    padding-left: 0;
    padding-right: 1.5rem;
  }
  html[dir=rtl] .one-up .one-up-img {
    margin-left: auto;
    margin-right: -2.5rem;
  }
  html[dir=rtl] .one-up.right .one-up-inner {
    padding-left: 1.5rem;
    padding-right: 0;
  }
  html[dir=rtl] .one-up.right .one-up-img {
    margin-left: -2.5rem;
    margin-right: auto;
  }
  html[dir=rtl] .two-up .two-up-content-container:first-child {
    margin-right: 15px;
    margin-left: 1rem;
  }
  html[dir=rtl] .two-up .two-up-content-container:not(:first-child) {
    margin-right: 1rem;
    margin-left: 0;
  }
  html[dir=rtl] .two-up.center .two-up-inner {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  html[dir=rtl] .two-up.center .two-up-content-container:first-child {
    margin-right: 15px;
  }
  html[dir=rtl] .two-up.center .two-up-content-container:not(:first-child) {
    margin-left: 15px;
  }
  html[dir=rtl] .two-up.right .two-up-content-container:first-child {
    margin-right: 0;
  }
  html[dir=rtl] .two-up.right .two-up-content-container:not(:first-child) {
    margin-left: 15px;
  }
  html[dir=rtl] .doctor-result .card-header {
    text-align: right;
  }
  html[dir=rtl] .doctor-result .doctor-location {
    text-align: right;
  }
  html[dir=rtl] .view-mode-list .doctor-result {
    margin-left: 0;
    margin-right: calc(
	(130px / 2) - 0.25rem
);
  }
  html[dir=rtl] .view-mode-list .doctor-result .card-header {
    text-align: right;
  }
  html[dir=rtl] .view-mode-list .doctor-result .profile-image {
    margin-left: auto;
    margin-right: calc((130px) / -2);
  }
  html[dir=rtl] .search-page.find-a-location .search-page-wrapper-outer.view-mode-map {
    margin-right: 0;
  }
  html[dir=rtl] .footer .footer-small .policy-terms {
    margin-left: 0;
    margin-right: 0.5rem;
    text-align: left;
  }
  html[dir=rtl] .footer .footer-small .copyright {
    margin-left: 0.5rem;
    margin-right: 0;
    text-align: right;
  }
}
@media (min-width: 992px) {
  html[dir=rtl] .global-navigation.navbar-fixed .main-nav .navbar-brand {
    text-align: right;
  }
  html[dir=rtl] .global-navigation .main-nav .navbar-brand {
    margin-left: 3rem;
    margin-right: 0;
  }
  html[dir=rtl] .global-navigation .main-nav .main-nav-links > li > a .icon-arrow-down {
    margin-right: 0.75rem;
    margin-left: 0;
  }
  html[dir=rtl] .global-navigation .main-nav .main-nav-links .sub-nav .heading-6 {
    text-align: right;
  }
  html[dir=rtl] .global-navigation .main-nav .main-nav-links .sub-nav ul li {
    text-align: right;
  }
  html[dir=rtl] .global-navigation .main-nav .main-nav-links .sub-nav .sub-nav-primary {
    padding-right: 0;
    padding-left: 3rem;
    border-right: none;
    border-left: 1px solid #cbced1;
  }
  html[dir=rtl] .global-navigation .main-nav .main-nav-links .sub-nav .sub-nav-secondary {
    padding: 0 3rem 0 1rem;
  }
  html[dir=rtl] .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .quick-links-header .heading-6 {
    margin-left: 0.75rem;
    margin-right: 0;
  }
  html[dir=rtl] .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .btn-icon:first-of-type {
    margin-right: 0;
  }
  html[dir=rtl] .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .btn-icon:not(:first-of-type) {
    margin-right: 1rem;
  }
  html[dir=rtl] .global-navigation .utility-nav .utility-nav-container {
    left: 15px;
    right: auto;
  }
  html[dir=rtl] .global-navigation .utility-nav .global-search-trigger {
    text-align: right;
  }
  html[dir=rtl] .global-navigation .utility-nav .global-search-trigger .icon-search {
    right: auto;
    left: 1rem;
  }
  html[dir=rtl] .global-navigation .utility-nav-links li:not(:last-of-type) {
    padding: 0 0 0 1rem;
    margin: 0 0 0 1rem;
    border-right: none;
    border-left: 1px solid #006D9D;
  }
  html[dir=rtl] .global-search-typeahead .search-form .submit-search {
    left: 1rem;
  }
  html[dir=rtl] .global-search-typeahead .search-input-container {
    margin: auto;
  }
  html[dir=rtl] .global-search-typeahead .search-dropdown .dropdown-container .dropdown-primary {
    padding: 0 0 0 2rem;
    border-right: 0;
    border-left: 1px solid #3d5d6b;
  }
  html[dir=rtl] .global-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary {
    padding: 0 2rem 0 0;
    border-left: 0;
    border-right: 1px solid #3d5d6b;
  }
  html[dir=rtl] .one-up .one-up-inner {
    padding-right: 4rem;
  }
  html[dir=rtl] .one-up .one-up-content {
    padding: 3rem 0 3rem 3rem;
  }
  html[dir=rtl] .one-up .one-up-img {
    margin-right: -5rem;
  }
  html[dir=rtl] .one-up.right .one-up-inner {
    padding-left: 4rem;
  }
  html[dir=rtl] .one-up.right .one-up-content {
    padding: 3rem 3rem 3rem 0;
  }
  html[dir=rtl] .one-up.right .one-up-img {
    margin-left: -5rem;
    margin-right: auto;
  }
  html[dir=rtl] .two-up .two-up-content-container:first-child {
    margin-left: 1.5rem;
  }
  html[dir=rtl] .two-up .two-up-content-container:not(:first-child) {
    margin-right: 1.5rem;
    margin-left: 0;
  }
  html[dir=rtl] .two-up.center .two-up-inner {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  html[dir=rtl] .related-list-container .section-title:after {
    right: 0;
    left: auto;
  }
  html[dir=rtl] .footer .marketing {
    float: right;
    margin-left: 1rem;
    margin-right: 0;
    text-align: right;
  }
  html[dir=rtl] .footer .link-lists {
    float: right;
    margin-left: 0;
    margin-right: 2rem;
  }
  html[dir=rtl] .footer .link-lists-constant {
    float: left;
    margin-left: 0;
    margin-right: 1rem;
  }
  html[dir=rtl] .footer .social-icons {
    text-align: right;
  }
}
@media (min-width: 1200px) {
  html[dir=rtl] .one-up .one-up-content {
    padding-left: 5rem;
  }
  html[dir=rtl] .one-up.right .one-up-content {
    padding-right: 5rem;
  }
}

html,
body {
  font-family: "proxima-nova", sans-serif;
  color: #2c373f;
}

h1,
.heading-1 {
  font-size: 1.75rem;
  line-height: 1.2142857143;
  color: #2c373f;
  font-weight: 700;
  margin-bottom: 1.5rem;
}
h1.heading-w-border,
.heading-1.heading-w-border {
  padding-bottom: 0.5rem;
  border-bottom: 0.25rem solid #ffcd55 !important;
  border-image: linear-gradient(to right, #ffcd55 0, #ffcd55 4.375rem, transparent 4.375rem, transparent 100%) 1;
}

h2,
.heading-2 {
  font-size: 1.75rem;
  line-height: 1.2142857143;
  color: #2c373f;
  font-weight: 700;
  margin-bottom: 0.25rem;
}

h3,
.heading-3 {
  font-size: 1.5rem;
  line-height: 1.25;
  color: #2c373f;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

h4,
.heading-4 {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #2c373f;
  font-weight: 700;
  margin-bottom: 0.25rem;
}

h5,
.heading-5 {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #2c373f;
  font-weight: 700;
  margin-bottom: 0.25rem;
}

h6,
.heading-6 {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #2c373f;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

@media (min-width: 992px) {
  h1,
.heading-1 {
    font-size: 2.25rem;
    line-height: 1.1111111111;
    margin-bottom: 2rem;
  }
  h1.heading-w-border,
.heading-1.heading-w-border {
    padding-bottom: 0.5rem;
    border-bottom: 0.25rem solid #ffcd55 !important;
    border-image: linear-gradient(to right, #ffcd55 0, #ffcd55 4.375rem, transparent 4.375rem, transparent 100%) 1;
  }
  h2,
.heading-2 {
    font-size: 2rem;
    line-height: 1.1875;
    margin-bottom: 0.375rem;
  }
  h3,
.heading-3 {
    font-size: 1.75rem;
    line-height: 1.2142857143;
    margin-bottom: 0.375rem;
  }
  h4,
.heading-4 {
    font-size: 1.5rem;
    line-height: 1.25;
    margin-bottom: 0.375rem;
  }
  h5,
.heading-5 {
    font-size: 1.25rem;
    line-height: 1.2;
    margin-bottom: 0.375rem;
  }
  h6,
.heading-6 {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    margin-bottom: 0.375rem;
  }
}
/* TODO: Add rte class to generic RTE containers in cshtml views */
.rte h1,
.rte .heading-1 {
  color: #2c373f;
  padding-top: 2rem;
}
.rte h1:first-child,
.rte .heading-1:first-child {
  margin-bottom: 0.375rem;
  padding-top: 0rem;
}
.rte h2,
.rte .heading-2 {
  color: #2c373f;
  padding-top: 1.5rem;
}
.rte h2:first-child,
.rte .heading-2:first-child {
  padding-top: 0rem;
}
.rte h3,
.rte .heading-3 {
  color: #2c373f;
  padding-top: 1.25rem;
}
.rte h3:first-child,
.rte .heading-3:first-child {
  padding-top: 0rem;
}
.rte h4,
.rte .heading-4 {
  color: #2c373f;
  padding-top: 1.125rem;
}
.rte h4:first-child,
.rte .heading-4:first-child {
  padding-top: 0rem;
}
.rte h5,
.rte .heading-5 {
  color: #2c373f;
  padding-top: 1rem;
}
.rte h6,
.rte .heading-6 {
  color: #2c373f;
  padding-top: 0.875rem;
}

p,
.body-copy {
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 400;
}
p.body-copy-secondary,
.body-copy.body-copy-secondary {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}

.notation {
  font-size: 1rem;
  line-height: 1.25;
  font-weight: 400;
  font-style: italic;
}

ol li {
  font-size: 1.25rem;
  line-height: 1.2;
}

a {
  font-weight: 600;
  color: #006D9D;
}
a:focus, a:hover {
  color: #006D9D;
}

/*
 * Default ul without any classes to not step on toes of Bootstrap's numerous custom ul classes.
 * .bulleted-list class to apply elsewhere as needed
 */
ul:not([class]),
ul.two-columns,
ul.three-columns,
ul.four-columns,
.bulleted-list {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #2c373f;
  list-style: none;
  margin-bottom: 2rem;
  padding-left: 1.5rem;
}
ul:not([class]) > li,
ul.two-columns > li,
ul.three-columns > li,
ul.four-columns > li,
.bulleted-list > li {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  margin-left: 1rem;
}
ul:not([class]) > li:before,
ul.two-columns > li:before,
ul.three-columns > li:before,
ul.four-columns > li:before,
.bulleted-list > li:before {
  color: #2c373f;
  content: "•";
  display: inline-block;
  margin-left: -1rem;
  width: 1rem;
}
ul:not([class]) > li:not(:last-of-type),
ul.two-columns > li:not(:last-of-type),
ul.three-columns > li:not(:last-of-type),
ul.four-columns > li:not(:last-of-type),
.bulleted-list > li:not(:last-of-type) {
  margin-bottom: 0.375rem;
}
ul:not([class]) > li p,
ul.two-columns > li p,
ul.three-columns > li p,
ul.four-columns > li p,
.bulleted-list > li p {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
ul:not([class]) ol,
ul:not([class]) ul,
ul.two-columns ol,
ul.two-columns ul,
ul.three-columns ol,
ul.three-columns ul,
ul.four-columns ol,
ul.four-columns ul,
.bulleted-list ol,
.bulleted-list ul {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}

ol:not([class]),
ol.two-columns,
ol.three-columns,
ol.four-columns {
  font-size: 1.125rem;
  line-height: 1.1111111111;
}
ol:not([class]) > li,
ol.two-columns > li,
ol.three-columns > li,
ol.four-columns > li {
  font-size: 1.125rem;
  line-height: 1.1111111111;
}
ol:not([class]) > li:not(:last-of-type),
ol.two-columns > li:not(:last-of-type),
ol.three-columns > li:not(:last-of-type),
ol.four-columns > li:not(:last-of-type) {
  margin-bottom: 0.25rem;
}
ol:not([class]) > li p,
ol.two-columns > li p,
ol.three-columns > li p,
ol.four-columns > li p {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
ol:not([class]) ol,
ol:not([class]) ul,
ol.two-columns ol,
ol.two-columns ul,
ol.three-columns ol,
ol.three-columns ul,
ol.four-columns ol,
ol.four-columns ul {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}

@media (min-width: 768px) {
  ol.two-columns, ol.three-columns, ol.four-columns,
ul.two-columns,
ul.three-columns,
ul.four-columns {
    columns: 2;
    column-gap: 1rem;
  }
}
@media (min-width: 992px) {
  ol.three-columns, ol.four-columns,
ul.three-columns,
ul.four-columns {
    columns: 3;
    column-gap: 1rem;
  }
}
@media (min-width: 1200px) {
  ol.four-columns,
ul.four-columns {
    columns: 4;
    column-gap: 1rem;
  }
}

/* Module Styles */
.bmi-calculator {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
  background-color: #f2f9ff;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
@media (min-width: 576px) {
  .bmi-calculator {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .bmi-calculator {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .bmi-calculator {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .bmi-calculator {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.bmi-calculator .title {
  margin-bottom: 1.25rem;
}
.bmi-calculator label {
  font-size: 1rem;
  line-height: 1.125;
  font-weight: 600;
}
.bmi-calculator input[type=text],
.bmi-calculator select {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 0;
  color: #006D9D;
  width: 100% !important;
}
.bmi-calculator input[type=text]:focus,
.bmi-calculator select:focus {
  background-color: #fff;
  border-color: #006D9D;
  box-shadow: none;
}
.bmi-calculator select {
  appearance: none;
  background-image: none;
}
.bmi-calculator select::-ms-expand {
  display: none;
}
.bmi-calculator .height {
  display: flex;
  margin-bottom: 1rem;
}
.bmi-calculator .height .height-item {
  flex: 1;
  margin-right: 1rem;
}
.bmi-calculator .height .height-item:last-of-type {
  margin-right: 0;
}
.bmi-calculator .weight {
  margin-bottom: 1.5rem;
}
.bmi-calculator .conditions-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.bmi-calculator .conditions-wrapper .checkbox-wrapper, .bmi-calculator .conditions-wrapper .marketo-form .mktoForm .mktoCheckboxList, .marketo-form .mktoForm .bmi-calculator .conditions-wrapper .mktoCheckboxList {
  margin-bottom: 1rem;
  flex: 0 0 calc(50% - 1rem);
  max-width: calc(50% - 1rem);
}
.bmi-calculator .error {
  display: none;
}
.bmi-calculator .error.show {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #b23838;
  display: block;
  margin-top: 1rem;
}
.bmi-calculator .bmi-results-container {
  display: none;
}
.bmi-calculator .bmi-results-container.show {
  background-color: rgba(255, 255, 255, 0.8);
  display: block;
  margin-top: 2rem;
  padding: 2rem;
  position: relative;
}
.bmi-calculator .calculated-bmi {
  font-weight: 600;
}
.bmi-calculator .health-rating > div {
  display: none;
}
.bmi-calculator .health-rating > div.show {
  display: block;
}
.bmi-calculator .weight-loss {
  display: none;
}
.bmi-calculator .weight-loss.show {
  display: block;
}
.bmi-calculator .results-close-btn {
  font-size: 1.125rem;
  line-height: 0.8888888889;
  background: none;
  border: none;
  color: #006D9D;
  font-weight: 800;
  right: 1rem;
  padding: 0;
  position: absolute;
  top: 1rem;
}
.bmi-calculator .results-close-btn .icon {
  fill: #006D9D;
}
.bmi-calculator .results-close-btn:hover, .bmi-calculator .results-close-btn:focus {
  outline: 0;
}
.bmi-calculator .results-close-btn:hover .icon, .bmi-calculator .results-close-btn:focus .icon {
  fill: #006D9D;
}
@media (min-width: 768px) {
  .bmi-calculator {
    background-color: transparent;
    padding-bottom: 0;
    padding-top: 0;
  }
  .bmi-calculator .bmi-calculator-inner {
    background-color: #f2f9ff;
    padding: 2rem;
  }
  .bmi-calculator .conditions-wrapper:after {
    content: " ";
    flex: 0 0 calc(33.3333333333% - 1rem);
    max-width: calc(33.3333333333% - 1rem);
  }
  .bmi-calculator .conditions-wrapper .checkbox-wrapper, .bmi-calculator .conditions-wrapper .marketo-form .mktoForm .mktoCheckboxList, .marketo-form .mktoForm .bmi-calculator .conditions-wrapper .mktoCheckboxList {
    flex: 0 0 calc(33.3333333333% - 1rem);
    max-width: calc(33.3333333333% - 1rem);
  }
  .two-column .bmi-calculator .conditions-wrapper .checkbox-wrapper, .two-column .bmi-calculator .conditions-wrapper .marketo-form .mktoForm .mktoCheckboxList, .marketo-form .mktoForm .two-column .bmi-calculator .conditions-wrapper .mktoCheckboxList {
    flex: 0 0 calc(50% - 1rem);
    max-width: calc(50% - 1rem);
  }
}
@media (min-width: 992px) {
  .bmi-calculator .conditions-wrapper:before {
    content: " ";
    flex: 0 0 calc(25% - 1rem);
    max-width: calc(25% - 1rem);
    order: 1;
  }
  .bmi-calculator .conditions-wrapper:after {
    flex: 0 0 calc(25% - 1rem);
    max-width: calc(25% - 1rem);
  }
  .two-column .bmi-calculator .conditions-wrapper:before {
    display: none;
  }
  .two-column .bmi-calculator .conditions-wrapper:after {
    flex: 0 0 calc(33.3333333333% - 1rem);
    max-width: calc(33.3333333333% - 1rem);
  }
  .bmi-calculator .conditions-wrapper .checkbox-wrapper, .bmi-calculator .conditions-wrapper .marketo-form .mktoForm .mktoCheckboxList, .marketo-form .mktoForm .bmi-calculator .conditions-wrapper .mktoCheckboxList {
    flex: 0 0 calc(25% - 1rem);
    max-width: calc(25% - 1rem);
  }
  .two-column .bmi-calculator .conditions-wrapper .checkbox-wrapper, .two-column .bmi-calculator .conditions-wrapper .marketo-form .mktoForm .mktoCheckboxList, .marketo-form .mktoForm .two-column .bmi-calculator .conditions-wrapper .mktoCheckboxList {
    flex: 0 0 calc(33.3333333333% - 1rem);
    max-width: calc(33.3333333333% - 1rem);
  }
}

.example-module {
  color: #fff;
}
.example-module h4 {
  margin-bottom: 1rem;
}
.example-module h4:after {
  font-size: 1rem;
  line-height: 1.125;
  content: "XS Breakpoint - <576px";
  display: block;
  margin-top: 2px;
}
.example-module .jumbotron {
  background-color: #006D9D;
}
@media (min-width: 576px) {
  .example-module h4:after {
    content: "SM Breakpoint - ≥576px";
  }
  .example-module .jumbotron {
    background-color: #a2c3d6;
  }
}
@media (min-width: 768px) {
  .example-module h4:after {
    content: "MD Breakpoint - ≥768px";
  }
  .example-module .jumbotron {
    background-color: #006D9D;
  }
}
@media (min-width: 992px) {
  .example-module h4:after {
    content: "LG Breakpoint - ≥992px";
  }
  .example-module .jumbotron {
    background-color: #2c373f;
  }
}
@media (min-width: 1200px) {
  .example-module h4:after {
    content: "XL Breakpoint -  ≥1200px";
  }
  .example-module .jumbotron {
    background-color: #005557;
  }
}

.filtered-content {
  margin-top: 2rem;
}
.filtered-content.has-query .selected-filters, .filtered-content.has-category .selected-filters, .filtered-content.has-letter .selected-filters {
  display: block;
}
.filtered-content.has-query .filters, .filtered-content.has-category .filters, .filtered-content.has-letter .filters {
  min-height: 0;
}
.filtered-content.has-query .btn-search-clear {
  display: inline-block;
}
.filtered-content.has-query .btn-search-submit {
  display: none;
}
.filtered-content.has-query .selected-filters .selected-filter-query {
  display: block;
}
.filtered-content.has-category .selected-filters .selected-filter-category {
  display: block;
}
.filtered-content.has-letter .selected-filters .selected-filter-letter {
  display: block;
}
.two-column .filtered-content {
  margin: 0;
}
.two-column .filtered-content .content {
  padding: 0;
}
.filtered-content .btn-search-clear {
  display: none;
  top: 45%;
}
.filtered-content svg {
  pointer-events: none;
}
.filtered-content .mobile-filters-toggle {
  width: 80%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.filtered-content .filter-list .mobile-filter-header {
  margin-bottom: 1rem;
}
.filtered-content .filter-list .mobile-filter-header .filter-heading {
  width: auto;
}
.filtered-content .filter-list .mobile-filter-header .mobile-close-btn .icon {
  fill: #2c373f;
}
.filtered-content .filtered-content-heading {
  font-size: 1.5rem;
  line-height: 1.25;
  display: none;
  color: #2c373f;
  margin-bottom: 2rem;
  padding-left: 15px;
}
.filtered-content .filter-by-search {
  margin-bottom: 1rem;
}
.filtered-content .filter-by-search .label {
  padding-left: 1rem;
}
.filtered-content .filter-by-search .services-typeahead {
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
}
.filtered-content .filter-by-search .services-typeahead.search-focus .search-dropdown {
  display: block;
}
.filtered-content .filter-by-search .services-typeahead-input {
  padding: 0.5rem 2.25rem 0.5rem 1.5rem;
  text-align: left;
  outline: none;
  width: 100%;
}
.filtered-content .filter-by-search .search-dropdown {
  display: none;
}
.filtered-content .filter-by-search .dropdown-menu {
  overflow: hidden;
}
.filtered-content .filters {
  margin-bottom: 2rem;
  min-height: 38px;
  text-align: center;
}
.filtered-content .filters .filters-inner {
  background-color: #f2f9ff;
  padding: 1rem;
  text-align: center;
}
.filtered-content .filters .label {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-weight: 600;
  color: #2c373f;
  margin-bottom: 1rem;
  text-align: center;
}
.filtered-content .filters .filter-by-category {
  margin-bottom: 2rem;
}
.filtered-content .filters .filter-by-category .btn, .filtered-content .filters .filter-by-category .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .filtered-content .filters .filter-by-category .mktoButton {
  font-size: 1rem;
  line-height: 1.125;
  border-radius: 5px;
  padding: 0.375rem 0;
  width: 100%;
}
.filtered-content .filters .filter-by-category .btn.active, .filtered-content .filters .filter-by-category .marketo-form .mktoForm .active.mktoButton, .marketo-form .mktoForm .filtered-content .filters .filter-by-category .active.mktoButton, .filtered-content .filters .filter-by-category .btn.active:focus {
  background-color: #3d5d6b;
  border-color: #3d5d6b;
  color: #fff;
  box-shadow: none;
}
.filtered-content .filters .filter-by-category .btn:not(.active):focus, .filtered-content .filters .filter-by-category .marketo-form .mktoForm .mktoButton:not(.active):focus, .marketo-form .mktoForm .filtered-content .filters .filter-by-category .mktoButton:not(.active):focus {
  background-color: #2c373f;
  color: #fff;
  box-shadow: none;
}
.childrens-theme .filtered-content .filters .filter-by-category .btn, .childrens-theme .filtered-content .filters .filter-by-category .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .childrens-theme .filtered-content .filters .filter-by-category .mktoButton {
  color: #fff;
  background-color: #6fb068;
  border-color: #6fb068;
}
.childrens-theme .filtered-content .filters .filter-by-category .btn:hover, .childrens-theme .filtered-content .filters .filter-by-category .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .childrens-theme .filtered-content .filters .filter-by-category .mktoButton:hover {
  color: #fff;
  background-color: #509536;
  border-color: #509536;
}
.childrens-theme .filtered-content .filters .filter-by-category .btn:focus, .childrens-theme .filtered-content .filters .filter-by-category .marketo-form .mktoForm .mktoButton:focus, .marketo-form .mktoForm .childrens-theme .filtered-content .filters .filter-by-category .mktoButton:focus, .childrens-theme .filtered-content .filters .filter-by-category .btn.focus, .childrens-theme .filtered-content .filters .filter-by-category .marketo-form .mktoForm .focus.mktoButton, .marketo-form .mktoForm .childrens-theme .filtered-content .filters .filter-by-category .focus.mktoButton {
  color: #fff;
  background-color: #509536;
  border-color: #509536;
  box-shadow: 0 0 0 0.2rem rgba(133, 188, 127, 0.5);
}
.childrens-theme .filtered-content .filters .filter-by-category .btn.disabled, .childrens-theme .filtered-content .filters .filter-by-category .marketo-form .mktoForm .disabled.mktoButton, .marketo-form .mktoForm .childrens-theme .filtered-content .filters .filter-by-category .disabled.mktoButton, .childrens-theme .filtered-content .filters .filter-by-category .btn:disabled, .childrens-theme .filtered-content .filters .filter-by-category .marketo-form .mktoForm .mktoButton:disabled, .marketo-form .mktoForm .childrens-theme .filtered-content .filters .filter-by-category .mktoButton:disabled {
  color: #fff;
  background-color: #6fb068;
  border-color: #6fb068;
}
.childrens-theme .filtered-content .filters .filter-by-category .btn:not(:disabled):not(.disabled):active, .childrens-theme .filtered-content .filters .filter-by-category .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .childrens-theme .filtered-content .filters .filter-by-category .mktoButton:not(:disabled):not(.disabled):active, .childrens-theme .filtered-content .filters .filter-by-category .btn:not(:disabled):not(.disabled).active, .childrens-theme .filtered-content .filters .filter-by-category .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .childrens-theme .filtered-content .filters .filter-by-category .mktoButton:not(:disabled):not(.disabled).active, .show > .childrens-theme .filtered-content .filters .filter-by-category .btn.dropdown-toggle, .show > .childrens-theme .filtered-content .filters .filter-by-category .marketo-form .mktoForm .dropdown-toggle.mktoButton, .marketo-form .mktoForm .show > .childrens-theme .filtered-content .filters .filter-by-category .dropdown-toggle.mktoButton {
  color: #fff;
  background-color: #56964f;
  border-color: #518e4a;
}
.childrens-theme .filtered-content .filters .filter-by-category .btn:not(:disabled):not(.disabled):active:focus, .childrens-theme .filtered-content .filters .filter-by-category .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .childrens-theme .filtered-content .filters .filter-by-category .mktoButton:not(:disabled):not(.disabled):active:focus, .childrens-theme .filtered-content .filters .filter-by-category .btn:not(:disabled):not(.disabled).active:focus, .childrens-theme .filtered-content .filters .filter-by-category .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .childrens-theme .filtered-content .filters .filter-by-category .mktoButton:not(:disabled):not(.disabled).active:focus, .show > .childrens-theme .filtered-content .filters .filter-by-category .btn.dropdown-toggle:focus, .show > .childrens-theme .filtered-content .filters .filter-by-category .marketo-form .mktoForm .dropdown-toggle.mktoButton:focus, .marketo-form .mktoForm .show > .childrens-theme .filtered-content .filters .filter-by-category .dropdown-toggle.mktoButton:focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 188, 127, 0.5);
}
.filtered-content .filters .filter-by-letter .letter-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding-left: 0;
}
.filtered-content .filters .filter-by-letter li {
  width: 16.6666666667%;
}
.filtered-content .filters .filter-by-letter button {
  background-color: transparent;
  border: 0;
  color: #2c373f;
  font-weight: 700;
  outline: 0;
  text-decoration: underline;
  text-transform: uppercase;
  padding: 0.5rem 0;
  width: 100%;
}
.filtered-content .filters .filter-by-letter button:disabled {
  background-color: transparent;
  color: #a2c3d6;
  text-decoration: none;
}
.filtered-content .filters .filter-by-letter button.active {
  background-color: #ffebbb;
  border-radius: 6rem;
  color: #2c373f;
  text-decoration: none;
}
.childrens-theme .filtered-content .filters .filter-by-letter button.active {
  background-color: #509536;
}
.filtered-content .filters .view-all {
  font-size: 1rem;
  line-height: 1.125;
  background-color: transparent;
  border: 0;
  color: #006D9D;
  font-weight: 700;
  outline: 0;
}
.filtered-content .selected-filters {
  display: none;
  margin-left: 15px;
  margin-right: 15px;
  border-bottom: 1px solid #cbced1;
  padding-bottom: 2rem;
}
.filtered-content .selected-filters .selected-filters-label {
  color: #3d5d6b;
  font-style: italic;
  padding-bottom: 0.375rem;
}
.filtered-content .selected-filters .selected-filter {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #006D9D;
  display: none;
  font-weight: 600;
  padding-bottom: 0.375rem;
}
.filtered-content .selected-filters .selected-filter-category span,
.filtered-content .selected-filters .selected-filter-letter span {
  text-transform: capitalize;
}
.filtered-content .selected-filters .remove-filter-btn {
  background-color: transparent;
  border: none;
}
.filtered-content .selected-filters .icon-close {
  fill: #cbced1;
  height: 0.75rem;
  width: 0.75rem;
}
.filtered-content .content {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
}
.filtered-content .content .description {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 1rem;
}
.filtered-content .content .filterable-item-list {
  list-style: none;
  padding: 0;
  margin-bottom: 1.5rem;
}
.filtered-content .content .filterable-item-list .filterable-item {
  padding: 0.5rem 0;
  display: inline-block;
  width: 100%;
}
.filtered-content .content .filterable-item-list .filterable-item.is-hidden {
  display: none;
}
.filtered-content .letter-group.is-hidden {
  display: none;
}
.filtered-content .letter-group-heading {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 0.5rem;
  width: 100%;
}
@media (min-width: 768px) {
  .filtered-content {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    /* override main filter list to switch at md instead of lg */
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .filtered-content {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .filtered-content {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .filtered-content {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .filtered-content {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .filtered-content .mobile-filters-toggle {
    display: none;
  }
}
@media (min-width: 768px) {
  .filtered-content .filtered-content-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media (min-width: 768px) {
  .filtered-content .filter-list {
    background-color: transparent;
    opacity: 1;
    pointer-events: auto;
    position: initial;
    visibility: visible;
  }
  .filtered-content .filter-list .mobile-filter-header,
.filtered-content .filter-list .mobile-filter-show-btn {
    display: none;
  }
}
@media (min-width: 768px) {
  .filtered-content .filtered-content-heading {
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .filtered-content .filter-by-search {
    margin-bottom: 2rem;
  }
  .filtered-content .filter-by-search .label {
    padding-left: 1.5rem;
    text-align: left;
  }
  .filtered-content .filter-by-search .services-typeahead {
    margin-left: 0;
    margin-right: 0;
  }
  .filtered-content .filter-by-search .services-typeahead-input {
    background-color: #f2f9ff;
  }
}
@media (min-width: 768px) {
  .filtered-content .filters {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    margin-bottom: 4rem;
  }
  .filtered-content .filters .filters-inner {
    padding: 1.5rem;
  }
  .filtered-content .filters .label {
    font-size: 1.25rem;
    line-height: 1.2;
    font-weight: 700;
    text-align: left;
  }
  .filtered-content .filters .filter-by-letter button:not([disabled]):hover {
    background-color: #fff;
    text-decoration: none;
    border-radius: 6rem;
  }
  .filtered-content .filters .filter-by-letter button:not([disabled]).active {
    text-decoration: none;
  }
  .childrens-theme .filtered-content .filters .filter-by-letter button:not([disabled]).active {
    background-color: #509536;
  }
}
@media (min-width: 768px) {
  .filtered-content .selected-filters {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .filtered-content .content {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
    margin-bottom: 4rem;
  }
  .filtered-content .content .filterable-item-list {
    columns: 2;
  }
  .filtered-content .content:only-child {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .filtered-content .letter-group-heading {
    font-size: 1.75rem;
    line-height: 1.2142857143;
  }
}
@media (min-width: 1200px) {
  .filtered-content .filters {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .filtered-content .content {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .filtered-content .content .filterable-item-list {
    column-count: 3;
  }
}

.global-navigation {
  font-family: "proxima-nova", sans-serif;
  overflow: visible;
  position: relative;
}
.global-navigation:before {
  background-color: rgba(0, 0, 0, 0.6);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity 0.4s ease-in-out;
  width: 100%;
  z-index: 5;
}
.global-navigation.hamburger-nav-open:before {
  opacity: 1;
  pointer-events: auto;
}
.global-navigation.hamburger-nav-open .hamburger-nav {
  border-top: 4px solid #f5f5f6;
  padding: 3rem 1rem 1rem;
  transform: translateX(-100%);
}
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group {
  display: block;
  margin-bottom: 0;
}
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .icon {
  fill: #006D9D;
  height: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  right: 0;
  width: 1.5rem;
  z-index: 1;
}
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group label {
  background-color: #fff;
  box-shadow: none;
  margin-bottom: 0;
  border: 2px solid #cbced1;
  border-radius: 6rem;
  display: block;
  padding: 1rem 2rem;
  position: relative;
}
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .form-control, .global-navigation.hamburger-nav-open .hamburger-nav > .form-group .share-story-modal form input[type=text], .share-story-modal form .global-navigation.hamburger-nav-open .hamburger-nav > .form-group input[type=text],
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .share-story-modal form input[type=email],
.share-story-modal form .global-navigation.hamburger-nav-open .hamburger-nav > .form-group input[type=email],
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .share-story-modal form input[type=tel],
.share-story-modal form .global-navigation.hamburger-nav-open .hamburger-nav > .form-group input[type=tel],
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .share-story-modal form select,
.share-story-modal form .global-navigation.hamburger-nav-open .hamburger-nav > .form-group select, .global-navigation.hamburger-nav-open .hamburger-nav > .form-group .marketo-form .mktoForm select, .marketo-form .mktoForm .global-navigation.hamburger-nav-open .hamburger-nav > .form-group select, .global-navigation.hamburger-nav-open .hamburger-nav > .form-group .marketo-form .mktoForm .mktoEmailField, .marketo-form .mktoForm .global-navigation.hamburger-nav-open .hamburger-nav > .form-group .mktoEmailField,
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .global-navigation.hamburger-nav-open .hamburger-nav > .form-group .mktoTelField,
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .marketo-form .mktoForm .mktoTextField,
.marketo-form .mktoForm .global-navigation.hamburger-nav-open .hamburger-nav > .form-group .mktoTextField, .global-navigation.hamburger-nav-open .hamburger-nav > .form-group textarea, .global-navigation.hamburger-nav-open .hamburger-nav > .form-group .bmi-calculator input[type=text], .bmi-calculator .global-navigation.hamburger-nav-open .hamburger-nav > .form-group input[type=text],
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .bmi-calculator select,
.bmi-calculator .global-navigation.hamburger-nav-open .hamburger-nav > .form-group select {
  background-color: transparent;
  color: #2c373f;
  display: block;
}
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .form-control::placeholder, .global-navigation.hamburger-nav-open .hamburger-nav > .form-group .share-story-modal form input[type=text]::placeholder, .share-story-modal form .global-navigation.hamburger-nav-open .hamburger-nav > .form-group input[type=text]::placeholder,
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .share-story-modal form input[type=email]::placeholder,
.share-story-modal form .global-navigation.hamburger-nav-open .hamburger-nav > .form-group input[type=email]::placeholder,
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .share-story-modal form input[type=tel]::placeholder,
.share-story-modal form .global-navigation.hamburger-nav-open .hamburger-nav > .form-group input[type=tel]::placeholder,
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .share-story-modal form select::placeholder,
.share-story-modal form .global-navigation.hamburger-nav-open .hamburger-nav > .form-group select::placeholder, .global-navigation.hamburger-nav-open .hamburger-nav > .form-group .marketo-form .mktoForm select::placeholder, .marketo-form .mktoForm .global-navigation.hamburger-nav-open .hamburger-nav > .form-group select::placeholder, .global-navigation.hamburger-nav-open .hamburger-nav > .form-group .marketo-form .mktoForm .mktoEmailField::placeholder, .marketo-form .mktoForm .global-navigation.hamburger-nav-open .hamburger-nav > .form-group .mktoEmailField::placeholder,
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .marketo-form .mktoForm .mktoTelField::placeholder,
.marketo-form .mktoForm .global-navigation.hamburger-nav-open .hamburger-nav > .form-group .mktoTelField::placeholder,
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .marketo-form .mktoForm .mktoTextField::placeholder,
.marketo-form .mktoForm .global-navigation.hamburger-nav-open .hamburger-nav > .form-group .mktoTextField::placeholder, .global-navigation.hamburger-nav-open .hamburger-nav > .form-group textarea::placeholder, .global-navigation.hamburger-nav-open .hamburger-nav > .form-group .bmi-calculator input[type=text]::placeholder, .bmi-calculator .global-navigation.hamburger-nav-open .hamburger-nav > .form-group input[type=text]::placeholder,
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .bmi-calculator select::placeholder,
.bmi-calculator .global-navigation.hamburger-nav-open .hamburger-nav > .form-group select::placeholder {
  color: #2c373f;
}
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .search-form {
  border-color: #006D9D;
}
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .global-search-typeahead-mobile .search-form {
  position: relative;
  background-color: #fff;
  display: flex;
}
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .global-search-typeahead-mobile .search-form input {
  font-weight: 600;
}
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .global-search-typeahead-mobile .search-form input::placeholder {
  color: #2c373f;
}
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .global-search-typeahead-mobile .search-form input:focus {
  border-color: #a2c3d6;
}
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .global-search-typeahead-mobile .search-form .submit-search {
  background: none;
  border: none;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
}
.global-navigation.hamburger-nav-open .hamburger-nav > .form-group .global-search-typeahead-mobile .search-form .submit-search:hover .icon-search, .global-navigation.hamburger-nav-open .hamburger-nav > .form-group .global-search-typeahead-mobile .search-form .submit-search:focus .icon-search {
  stroke: #006D9D;
}
.global-navigation.hamburger-nav-open label {
  display: block;
  position: relative;
}
.global-navigation.hamburger-nav-open .icon-menu-close {
  display: block;
}
.global-navigation.hamburger-nav-open .icon-menu-open {
  display: none;
}
.global-navigation .global-search-typeahead-mobile .search-form {
  position: relative;
  background-color: #fff;
  display: flex;
}
.global-navigation .global-search-typeahead-mobile .search-form input {
  width: 100%;
  border: none;
  padding: 0;
  position: relative;
}
.global-navigation .global-search-typeahead-mobile .search-form input::placeholder {
  color: #2c373f;
}
.global-navigation .global-search-typeahead-mobile .search-form input:focus {
  border-color: #a2c3d6;
}
.global-navigation .global-search-typeahead-mobile .search-form .submit-search {
  background: none;
  border: none;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
}
.global-navigation .global-search-typeahead-mobile .search-form .submit-search:hover .icon-search, .global-navigation .global-search-typeahead-mobile .search-form .submit-search:focus .icon-search {
  stroke: #006D9D;
}
.global-navigation.navbar-fixed {
  height: 90px;
}
.global-navigation.navbar-fixed .main-nav {
  height: 90px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  transform: translateY(-100%);
  pointer-events: none;
}
.global-navigation.navbar-fixed.scroll-up .main-nav {
  pointer-events: auto;
  transform: translateY(0);
  transition: transform 0.2s ease-in-out;
}
.global-navigation.navbar-fixed.scroll-up.scroll-down .main-nav {
  pointer-events: none;
  transform: translateY(-100%);
}
.moments-theme .global-navigation.navbar-fixed {
  height: 0;
}
.moments-theme .global-navigation .main-nav {
  background-color: transparent;
  border-bottom: 1px solid transparent;
  box-shadow: none;
  height: 70px;
  left: 0;
  position: absolute;
  top: 0;
  transition: background-color, border-bottom, box-shadow;
  transition-timing: 0.4s ease-in-out;
  width: 100%;
}
.moments-theme .global-navigation .moments-back {
  align-items: center;
  display: flex;
  height: 33px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-in-out;
  width: 46px;
}
.moments-theme .global-navigation .moments-back svg {
  height: 22px;
  stroke: #fff;
  width: 22px;
}
.moments-theme .global-navigation .moments-brand {
  opacity: 0;
  pointer-events: none;
  transform: translateY(-3px);
  transition: opacity 0.4s ease-in-out;
}
.moments-theme .global-navigation .navbar-brand {
  display: none;
}
.moments-theme .global-navigation .icon-menu-open,
.moments-theme .global-navigation .icon-menu-close {
  stroke: #fff;
}
.moments-theme .global-navigation .hamburger-nav {
  height: calc(100% - 70px);
  opacity: 0;
  top: 70px;
  transition: transform 0.4s ease-in-out, opacity 0.35s ease-in-out;
}
.moments-theme .global-navigation .hamburger-nav .primary-nav .nav-links .sub-nav-header button {
  color: #1f1c17;
}
.moments-theme .global-navigation .hamburger-nav .secondary-nav .nav-links li a {
  color: #1f1c17;
}
.moments-theme .global-navigation .hamburger-nav .icon-arrow-right,
.moments-theme .global-navigation .hamburger-nav .icon-arrow-left {
  fill: #1f1c17;
}
.moments-theme .global-navigation .hamburger-nav .image-links .image-cta .text-overlay {
  background-color: #1f1c17;
}
.moments-theme .global-navigation .hamburger-nav .image-links .image-cta .text-overlay .icon-arrow-right {
  fill: #1f1c17;
}
.moments-theme .global-navigation .global-search-trigger {
  background-color: #1f1c17;
  border: none;
  border-top: 6px solid #fcb930;
  padding: 1rem 2rem 1.25rem;
}
.moments-theme .global-navigation .global-search-trigger svg {
  stroke: #fff;
  top: calc(50% - 4px);
}
.moments-theme .global-navigation.hamburger-nav-open .main-nav {
  background-color: #1f1c17;
  border-bottom: 1px solid #1f1c17;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
  transition-delay: 0;
}
.moments-theme .global-navigation.hamburger-nav-open .moments-back {
  opacity: 0;
  pointer-events: none;
}
.moments-theme .global-navigation.hamburger-nav-open .moments-brand {
  opacity: 1;
  pointer-events: auto;
}
.moments-theme .global-navigation.hamburger-nav-open .hamburger-nav {
  opacity: 1;
}
.moments-theme.moments-story .global-navigation.navbar-fixed {
  height: 70px;
}
.moments-theme.moments-story .global-navigation.hamburger-nav-open .moments-back {
  opacity: 0;
  pointer-events: none;
}
.moments-theme.moments-story .global-navigation .main-nav {
  background-color: #1f1c17;
  position: relative;
}
.moments-theme.moments-story .global-navigation .moments-back,
.moments-theme.moments-story .global-navigation .moments-brand {
  opacity: 1;
  pointer-events: auto;
}
.moments-theme.moments-story .global-navigation .hamburger-nav {
  opacity: 1;
  transition: transform 0.4s ease-in-out;
}
.global-navigation .icon-menu-close {
  display: none;
}
.global-navigation .utility-nav {
  display: none;
}
.global-navigation .global-search-typeahead {
  display: none;
  background-color: #a2c3d6;
  position: fixed;
  width: 100vw;
  left: 0;
  top: 0;
  opacity: 0;
  pointer-events: none;
  height: 49px;
  transition: opacity 0.4s ease-in-out;
  z-index: 9;
}
.global-navigation .global-search-typeahead.search-open {
  animation: global-search-typeahead-fade-in 0.4s;
  display: block;
  opacity: 1;
  pointer-events: auto;
}
@keyframes global-search-typeahead-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.global-navigation .global-search-typeahead.search-closing {
  opacity: 0;
  pointer-events: none;
}
.global-navigation .global-search-typeahead .search-grid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background-color: #a2c3d6;
  position: relative;
}
@media (min-width: 576px) {
  .global-navigation .global-search-typeahead .search-grid {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .global-navigation .global-search-typeahead .search-grid {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .global-navigation .global-search-typeahead .search-grid {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .global-navigation .global-search-typeahead .search-grid {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.global-navigation .global-search-typeahead .search-input-container {
  position: relative;
  width: calc(100vw - 70px);
  margin-left: -15px;
}
.global-navigation .global-search-typeahead .search-overlay {
  display: block;
  position: fixed;
  top: 49px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  transition: top 0.2s ease-in-out;
}
.global-navigation .global-search-typeahead .search-overlay-close {
  font-size: 1rem;
  line-height: 1.125;
  background: none;
  border: 0;
  color: #2c373f;
  font-weight: 600;
  margin: 0;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.global-navigation .global-search-typeahead .search-overlay-close:hover, .global-navigation .global-search-typeahead .search-overlay-close:focus {
  text-decoration: underline;
}
.global-navigation .global-search-typeahead .search-form input {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  background-color: #f2f9ff;
  border: 1px solid #006D9D;
  color: #2c373f;
  font-weight: 600;
  padding: 1rem 24px;
  height: 49px;
  transition: height 0.2s ease-in-out;
}
.global-navigation .global-search-typeahead .search-form input::placeholder {
  color: #2c373f;
}
.global-navigation .global-search-typeahead .search-form input:focus {
  border-color: #006D9D;
}
.global-navigation .global-search-typeahead .search-form .submit-search {
  min-height: 20px;
  right: 1rem;
}
.global-navigation .global-search-typeahead .search-dropdown {
  margin-top: 0;
  max-height: calc(100vh - 49px);
  overflow: auto;
  width: 100vw;
}
.global-navigation .global-search-typeahead-mobile .search-dropdown {
  margin-top: 2.5rem;
}
.global-navigation .main-nav {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #f2f9ff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
  display: flex;
  height: 90px;
  position: relative;
  z-index: 8;
}
.global-navigation .main-nav .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.global-navigation .main-nav .navbar-brand {
  height: 50px;
  padding-top: 0;
  padding-bottom: 0;
}
.global-navigation .main-nav .navbar-brand img {
  height: 100%;
}
.global-navigation .main-nav .main-nav-links {
  display: none;
}
.global-navigation .main-nav .hamburger-toggle {
  border: 0;
  outline: 0;
}
.global-navigation .main-nav .hamburger-toggle .icon {
  height: 1.375rem;
  width: 1.375rem;
}
.global-navigation .fixed-search-btn {
  display: none;
}
.global-navigation .search-form {
  position: relative;
  background-color: #a2c3d6;
}
.global-navigation .search-form input {
  background-color: #2c373f;
  border: 1px solid #2c373f;
  color: #fff;
  display: block;
  margin: 0;
  outline: 0;
  padding: 1rem 2rem;
  width: 100%;
}
.global-navigation .search-form input::placeholder {
  color: #fff;
}
.global-navigation .search-form input:focus {
  border-color: #a2c3d6;
}
.global-navigation .search-form .submit-search {
  background: none;
  border: none;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
}
.global-navigation .search-form .submit-search:hover .icon-search, .global-navigation .search-form .submit-search:focus .icon-search {
  stroke: #006D9D;
}
.global-navigation .global-search-trigger {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  background-color: #f5f5f6;
  border: 1px solid #cbced1;
  border-radius: 6rem;
  border-bottom-width: 2px;
  border-top-width: 2px;
  color: #2c373f;
  display: block;
  margin: 0;
  outline: 0;
  padding: 1rem 2rem;
  position: relative;
  text-align: left;
  width: 100%;
}
.global-navigation .global-search-trigger:hover, .global-navigation .global-search-trigger:focus {
  border-color: #a2c3d6;
  cursor: text;
}
.global-navigation .global-search-trigger .icon-search {
  margin-top: -1px;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
}
.global-navigation .image-links .image-cta {
  font-size: 1rem;
  line-height: 1.125;
  background-color: #e4e5e6;
  border-radius: 5px;
  display: block;
  height: 0;
  outline: 0;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
}
.global-navigation .image-links .image-cta:hover picture:after, .global-navigation .image-links .image-cta:focus picture:after {
  opacity: 1;
}
.global-navigation .image-links .image-cta:hover .text-overlay .svg-container, .global-navigation .image-links .image-cta:focus .text-overlay .svg-container {
  background-color: transparent;
  transform: translateX(4px);
}
.global-navigation .image-links .image-cta:not(:last-of-type) {
  margin-bottom: 0.75rem;
}
.global-navigation .image-links .image-cta picture {
  height: calc(100% - 33px);
  position: absolute;
  width: 100%;
}
.global-navigation .image-links .image-cta picture:after {
  background-color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  transition: opacity 0.2s ease-in-out;
}
.global-navigation .image-links .image-cta picture img {
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%;";
  width: 100%;
}
.global-navigation .image-links .image-cta .text-overlay {
  align-items: center;
  background-color: #ffcd55;
  border-radius: 0 0 5px 5px;
  color: #2c373f;
  display: flex;
  font-weight: 700;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  position: absolute;
  bottom: 0;
  transition: background-color 0.2s ease-in-out;
  width: 100%;
}
.global-navigation .image-links .image-cta .text-overlay .svg-container {
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  transition: transform 0.2s ease-in-out;
}
.global-navigation .image-links .image-cta .text-overlay svg {
  height: 1rem;
  fill: #2c373f;
  width: 1rem;
  transform: translateX(1px);
}
.global-navigation .hamburger-nav {
  background-color: #fff;
  padding: 3rem 1rem 1rem;
  position: fixed;
  top: 90px;
  left: 100%;
  height: calc(100% - 90px);
  overflow-y: auto;
  transition: transform 0.4s ease-in-out;
  width: 100vw;
  z-index: 7;
}
.global-navigation .hamburger-nav .icon {
  fill: #006D9D;
  height: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: inline-flex;
  -ms-flex-item-align: center;
  align-self: center;
  right: 0;
  width: 1.5rem;
  z-index: 1;
}
.global-navigation .hamburger-nav label {
  background-color: #fff;
  box-shadow: none;
  margin-bottom: 0;
  border: 2px solid #cbced1;
  border-radius: 6rem;
  display: block;
  padding: 1rem 2rem;
  position: relative;
}
.global-navigation .hamburger-nav .global-search-typeahead-mobile .search-form {
  position: relative;
  background-color: #fff;
  display: flex;
}
.global-navigation .hamburger-nav .global-search-typeahead-mobile .search-form input {
  font-weight: 600;
  background-color: #fff;
}
.global-navigation .hamburger-nav .global-search-typeahead-mobile .search-form input::placeholder {
  color: #2c373f;
}
.global-navigation .hamburger-nav .global-search-typeahead-mobile .search-form input:focus {
  border-color: #a2c3d6;
}
.global-navigation .hamburger-nav .global-search-typeahead-mobile .search-form .submit-search {
  background: none;
  border: none;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
}
.global-navigation .hamburger-nav .global-search-typeahead-mobile .search-form .submit-search:hover .icon-search, .global-navigation .hamburger-nav .global-search-typeahead-mobile .search-form .submit-search:focus .icon-search {
  stroke: #006D9D;
}
.global-navigation .hamburger-nav .icon-search {
  stroke: #2c373f;
}
.global-navigation .hamburger-nav .nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
}
.global-navigation .hamburger-nav .nav-links li {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  display: block;
  font-weight: 600;
}
.global-navigation .hamburger-nav .nav-links li a {
  color: #2c373f;
  display: block;
  padding: 1rem;
  outline: 0;
}
.global-navigation .hamburger-nav .nav-links li a:hover, .global-navigation .hamburger-nav .nav-links li a:focus {
  text-decoration: underline;
}
.global-navigation .hamburger-nav .nav-links li .icon-arrow-right {
  pointer-events: none;
  position: absolute;
  top: calc(50% - 7px);
}
.global-navigation .hamburger-nav .primary-nav {
  height: auto;
  overflow: hidden;
  padding-top: 2rem;
  position: relative;
  transition: height 0.4s ease-in-out;
}
.global-navigation .hamburger-nav .primary-nav .nav-links li.has-sub-nav:before {
  background-color: #fff;
  content: "";
  display: block;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.4s ease-in-out;
  width: 100%;
  z-index: 1;
}
.global-navigation .hamburger-nav .primary-nav .nav-links li.has-sub-nav.sub-nav-active {
  pointer-events: none;
}
.global-navigation .hamburger-nav .primary-nav .nav-links li.has-sub-nav.sub-nav-active:before {
  opacity: 1;
}
.global-navigation .hamburger-nav .primary-nav .nav-links li.has-sub-nav.sub-nav-active > .sub-nav {
  pointer-events: auto;
  transform: translateX(0);
}
.global-navigation .hamburger-nav .primary-nav .nav-links li.has-sub-nav > a {
  position: relative;
}
.global-navigation .hamburger-nav .primary-nav .nav-links li.has-sub-nav > a .icon-arrow-right {
  right: 2rem;
}
.global-navigation .hamburger-nav .primary-nav .nav-links li .sub-nav {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(100%);
  transition: transform 0.4s ease-in-out;
  width: 100%;
  z-index: 1;
}
.global-navigation .hamburger-nav .primary-nav .nav-links .sub-nav-header button {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  background: none;
  border: none;
  border-bottom: 2px solid #f8f8f8;
  color: #006D9D;
  display: flex;
  font-weight: 600;
  justify-content: center;
  outline: 0;
  padding: 1rem 2rem;
  position: relative;
  width: 100%;
}
.global-navigation .hamburger-nav .primary-nav .nav-links .sub-nav-header button:hover, .global-navigation .hamburger-nav .primary-nav .nav-links .sub-nav-header button:focus {
  cursor: pointer;
  text-decoration: underline;
}
.global-navigation .hamburger-nav .primary-nav .nav-links .sub-nav-header button .icon-arrow-right {
  left: 2rem;
  transform: rotate(180deg);
}
.global-navigation .hamburger-nav .secondary-nav .nav-links {
  padding: 1rem 0;
}
.global-navigation .hamburger-nav .secondary-nav .nav-links li {
  font-weight: 700;
  padding-bottom: 1rem;
}
.global-navigation .hamburger-nav .secondary-nav .nav-links li a {
  font-size: 1rem;
  line-height: 1.125;
  color: #212529;
  background-color: #ffcd55;
  border-color: #ffcd55;
  font-weight: 600;
  border-radius: 6rem;
  text-decoration: none;
}
.global-navigation .hamburger-nav .secondary-nav .nav-links li a:hover {
  color: #212529;
  background-color: #fff;
  border-color: #ffcd55;
}
.global-navigation .hamburger-nav .secondary-nav .nav-links li a:focus, .global-navigation .hamburger-nav .secondary-nav .nav-links li a.focus {
  color: #212529;
  background-color: #fff;
  border-color: #ffcd55;
  box-shadow: 0 0 0 0.2rem rgba(222, 180, 78, 0.5);
}
.global-navigation .hamburger-nav .secondary-nav .nav-links li a.disabled, .global-navigation .hamburger-nav .secondary-nav .nav-links li a:disabled {
  color: #212529;
  background-color: #ffcd55;
  border-color: #ffcd55;
}
.global-navigation .hamburger-nav .secondary-nav .nav-links li a:not(:disabled):not(.disabled):active, .global-navigation .hamburger-nav .secondary-nav .nav-links li a:not(:disabled):not(.disabled).active, .show > .global-navigation .hamburger-nav .secondary-nav .nav-links li a.dropdown-toggle {
  color: #212529;
  background-color: #ffbe22;
  border-color: #ffba15;
}
.global-navigation .hamburger-nav .secondary-nav .nav-links li a:not(:disabled):not(.disabled):active:focus, .global-navigation .hamburger-nav .secondary-nav .nav-links li a:not(:disabled):not(.disabled).active:focus, .show > .global-navigation .hamburger-nav .secondary-nav .nav-links li a.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 180, 78, 0.5);
}
.global-navigation .hamburger-nav .secondary-nav .nav-links li a:hover {
  text-decoration: none;
}
.global-navigation .hamburger-nav .secondary-nav .nav-links li a .icon {
  top: -0.125rem;
  position: relative;
  height: 1rem;
}
.global-navigation .hamburger-nav .tertiary-nav .contact-phone {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #3d5d6b;
  display: block;
  outline: 0;
  padding: 2rem 2rem 1rem;
}
.global-navigation .hamburger-nav .tertiary-nav .contact-phone:hover, .global-navigation .hamburger-nav .tertiary-nav .contact-phone:focus {
  text-decoration: underline;
}
.global-navigation .hamburger-nav .tertiary-nav .nav-section-heading {
  font-size: 1rem;
  line-height: 1.125;
  color: #005557;
  font-weight: 400;
  padding: 1rem 2rem 0;
}
.global-navigation .hamburger-nav .tertiary-nav .nav-links {
  column-count: 2;
  column-gap: 0.375rem;
}
.global-navigation .hamburger-nav .tertiary-nav .nav-links li {
  font-weight: 700;
}
.global-navigation .hamburger-nav .tertiary-nav .nav-links li a {
  font-size: 1rem;
  line-height: 1.125;
  color: #fff;
  background-color: #005557;
  border-color: #005557;
  font-weight: 600;
  border-radius: 6rem;
  padding: 0.75rem 0.5rem;
  text-decoration: none;
}
.global-navigation .hamburger-nav .tertiary-nav .nav-links li a:hover {
  color: #212529;
  background-color: #fff;
  border-color: #005557;
}
.global-navigation .hamburger-nav .tertiary-nav .nav-links li a:focus, .global-navigation .hamburger-nav .tertiary-nav .nav-links li a.focus {
  color: #212529;
  background-color: #fff;
  border-color: #005557;
  box-shadow: 0 0 0 0.2rem rgba(38, 111, 112, 0.5);
}
.global-navigation .hamburger-nav .tertiary-nav .nav-links li a.disabled, .global-navigation .hamburger-nav .tertiary-nav .nav-links li a:disabled {
  color: #fff;
  background-color: #005557;
  border-color: #005557;
}
.global-navigation .hamburger-nav .tertiary-nav .nav-links li a:not(:disabled):not(.disabled):active, .global-navigation .hamburger-nav .tertiary-nav .nav-links li a:not(:disabled):not(.disabled).active, .show > .global-navigation .hamburger-nav .tertiary-nav .nav-links li a.dropdown-toggle {
  color: #fff;
  background-color: #002324;
  border-color: #001717;
}
.global-navigation .hamburger-nav .tertiary-nav .nav-links li a:not(:disabled):not(.disabled):active:focus, .global-navigation .hamburger-nav .tertiary-nav .nav-links li a:not(:disabled):not(.disabled).active:focus, .show > .global-navigation .hamburger-nav .tertiary-nav .nav-links li a.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 111, 112, 0.5);
}
.global-navigation .hamburger-nav .tertiary-nav .nav-links li a:hover, .global-navigation .hamburger-nav .tertiary-nav .nav-links li a:focus {
  color: #2c373f;
  text-decoration: none;
}
.global-navigation .hamburger-nav .tertiary-nav .nav-links li a:hover svg, .global-navigation .hamburger-nav .tertiary-nav .nav-links li a:focus svg {
  fill: #2c373f;
}
.global-navigation .hamburger-nav .tertiary-nav .nav-links li a svg {
  fill: #fff;
  top: -0.125rem;
  position: relative;
  height: 1rem;
  width: 1rem;
  margin: 0 -8px 0 4px;
}
.global-navigation .hamburger-nav .tertiary-nav .image-links {
  padding: 3rem 2rem 1.75rem;
}
.global-navigation .hamburger-nav .tertiary-nav .image-cta .text-overlay {
  background-color: #2c373f;
  color: #fff;
}
.global-navigation .hamburger-nav .tertiary-nav .image-cta .text-overlay .svg-container {
  background-color: transparent;
}
.global-navigation .hamburger-nav .tertiary-nav .image-cta .text-overlay svg {
  fill: #fff;
}
.global-navigation .hamburger-nav .tertiary-nav .image-cta:hover .text-overlay, .global-navigation .hamburger-nav .tertiary-nav .image-cta:focus .text-overlay {
  background-color: #3d5d6b;
  color: #fff;
}
.global-navigation .hamburger-nav .tertiary-nav .image-cta:hover .text-overlay .svg-container, .global-navigation .hamburger-nav .tertiary-nav .image-cta:focus .text-overlay .svg-container {
  background-color: #3d5d6b;
}
.global-navigation .hamburger-nav .tertiary-nav .image-cta:hover .text-overlay svg, .global-navigation .hamburger-nav .tertiary-nav .image-cta:focus .text-overlay svg {
  fill: #fff;
}
@media (min-width: 768px) {
  .global-navigation .hamburger-nav {
    max-width: 25rem;
  }
  .global-navigation .hamburger-nav .tertiary-nav .image-links {
    padding: 3rem 1rem 1.75rem;
  }
  .global-navigation .hamburger-nav .global-search-typeahead-mobile .search-dropdown {
    left: -33px;
    right: -30px;
    transform: none;
    width: auto;
    top: calc(100% + 1.5rem);
    margin-top: 0.5rem;
  }
}
@media (min-width: 992px) {
  .global-navigation.navbar-fixed, .moments-theme .global-navigation, .moments-theme.moments-story .global-navigation {
    height: 141px;
  }
  .global-navigation.navbar-fixed.no-transition .main-nav .navbar-brand, .moments-theme .global-navigation.no-transition .main-nav .navbar-brand, .moments-theme.moments-story .global-navigation.no-transition .main-nav .navbar-brand {
    transition: none;
  }
  .global-navigation.navbar-fixed.no-transition .main-nav .main-nav-links > li > a, .moments-theme .global-navigation.no-transition .main-nav .main-nav-links > li > a, .moments-theme.moments-story .global-navigation.no-transition .main-nav .main-nav-links > li > a {
    transition: none;
  }
  .global-navigation.navbar-fixed.no-transition .main-nav .fixed-search-btn, .moments-theme .global-navigation.no-transition .main-nav .fixed-search-btn, .moments-theme.moments-story .global-navigation.no-transition .main-nav .fixed-search-btn {
    animation: none;
  }
  .global-navigation.navbar-fixed .utility-nav, .moments-theme .global-navigation .utility-nav, .moments-theme.moments-story .global-navigation .utility-nav {
    display: none;
  }
  .global-navigation.navbar-fixed .main-nav, .moments-theme .global-navigation .main-nav, .moments-theme.moments-story .global-navigation .main-nav {
    height: 70px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    transform: translateY(0);
    pointer-events: auto;
    transition: none;
  }
  .global-navigation.navbar-fixed .main-nav .container, .moments-theme .global-navigation .main-nav .container, .moments-theme.moments-story .global-navigation .main-nav .container {
    height: 100%;
  }
  .global-navigation.navbar-fixed .main-nav .navbar-brand, .moments-theme .global-navigation .main-nav .navbar-brand, .moments-theme.moments-story .global-navigation .main-nav .navbar-brand {
    align-self: center;
    height: 50px;
    margin-bottom: 0;
    transition: height 0.2s ease-in-out, margin-bottom 0.2s ease-in-out;
    margin-right: auto;
  }
  .global-navigation.navbar-fixed .main-nav .main-nav-links, .moments-theme .global-navigation .main-nav .main-nav-links, .moments-theme.moments-story .global-navigation .main-nav .main-nav-links {
    padding-right: 40px;
  }
  .global-navigation.navbar-fixed .main-nav .main-nav-links > li > a, .moments-theme .global-navigation .main-nav .main-nav-links > li > a, .moments-theme.moments-story .global-navigation .main-nav .main-nav-links > li > a {
    font-size: 1rem;
    line-height: 1.125;
    padding-bottom: 1.5rem;
    transition: padding-bottom 0.2s ease-in-out;
  }
  .global-navigation.navbar-fixed .main-nav .main-nav-links > li > a.btn-tertiary, .moments-theme .global-navigation .main-nav .main-nav-links > li > a.btn-tertiary, .moments-theme.moments-story .global-navigation .main-nav .main-nav-links > li > a.btn-tertiary {
    top: -0.875rem;
  }
  .global-navigation.navbar-fixed .main-nav .fixed-search-btn, .moments-theme .global-navigation .main-nav .fixed-search-btn, .moments-theme.moments-story .global-navigation .main-nav .fixed-search-btn {
    background-color: #e4e5e6;
    border: none;
    display: block;
    height: 100%;
    outline: 0;
    transition: background-color 0.2s ease-in-out;
    width: 50px;
    animation: fixed-search-btn-fade-in 0.2s;
  }
  .global-navigation.navbar-fixed .main-nav .fixed-search-btn svg, .moments-theme .global-navigation .main-nav .fixed-search-btn svg, .moments-theme.moments-story .global-navigation .main-nav .fixed-search-btn svg {
    transform: translateX(-1px);
    transition: stroke 0.2s ease-in-out;
  }
  .global-navigation.navbar-fixed .main-nav .fixed-search-btn:hover, .global-navigation.navbar-fixed .main-nav .fixed-search-btn:focus, .moments-theme .global-navigation .main-nav .fixed-search-btn:hover, .moments-theme .global-navigation .main-nav .fixed-search-btn:focus, .moments-theme.moments-story .global-navigation .main-nav .fixed-search-btn:hover, .moments-theme.moments-story .global-navigation .main-nav .fixed-search-btn:focus {
    background-color: rgba(228, 229, 230, 0.85);
  }
  .global-navigation.navbar-fixed .main-nav .fixed-search-btn:hover svg, .global-navigation.navbar-fixed .main-nav .fixed-search-btn:focus svg, .moments-theme .global-navigation .main-nav .fixed-search-btn:hover svg, .moments-theme .global-navigation .main-nav .fixed-search-btn:focus svg, .moments-theme.moments-story .global-navigation .main-nav .fixed-search-btn:hover svg, .moments-theme.moments-story .global-navigation .main-nav .fixed-search-btn:focus svg {
    stroke: #2c373f;
  }
  @keyframes fixed-search-btn-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .moments-theme .global-navigation, .moments-theme.moments-story .global-navigation {
    height: 70px;
  }
  .moments-theme .global-navigation .main-nav, .moments-theme.moments-story .global-navigation .main-nav {
    border-bottom: none;
    box-shadow: none;
  }
  .moments-theme .global-navigation .moments-back,
.moments-theme .global-navigation .moments-brand, .moments-theme.moments-story .global-navigation .moments-back,
.moments-theme.moments-story .global-navigation .moments-brand {
    display: none;
  }
  .moments-theme .global-navigation .navbar-brand, .moments-theme.moments-story .global-navigation .navbar-brand {
    display: block;
  }
  .global-navigation .utility-nav {
    display: block;
  }
  .global-navigation .utility-nav .container {
    position: relative;
  }
  .global-navigation .utility-nav .utility-nav-container {
    display: flex;
    position: absolute;
    top: 0;
    right: 15px;
    z-index: 9;
  }
  .global-navigation .utility-nav .global-search-trigger {
    font-size: 0.875rem;
    line-height: 1.1428571429;
    background-color: #e4e5e6;
    border-bottom-width: 1px;
    border-color: #e4e5e6;
    border-radius: 0;
    border-top-width: 1px;
    font-weight: 600;
    height: 49px;
    padding: 1rem 24px;
    width: 150px;
  }
  .global-navigation .utility-nav .global-search-trigger:hover, .global-navigation .utility-nav .global-search-trigger:focus {
    border-color: #006D9D;
  }
  .global-navigation .utility-nav .global-search-trigger .icon-search {
    margin-top: -2px;
    right: 1rem;
  }
  .global-navigation .utility-nav-links {
    background-color: #f5f5f6;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 1rem;
  }
  .global-navigation .utility-nav-links li {
    font-size: 0.875rem;
    line-height: 1.1428571429;
    font-weight: 600;
  }
  .global-navigation .utility-nav-links li:not(:last-of-type) {
    padding-right: 1rem;
    margin-right: 1rem;
  }
  .global-navigation .utility-nav-links li a {
    color: #2c373f;
    outline: 0;
  }
  .global-navigation .utility-nav-links li a:hover, .global-navigation .utility-nav-links li a:focus {
    text-decoration: underline;
  }
  .global-navigation .global-search-typeahead .search-input-container, .global-navigation .global-search-typeahead-mobile .search-input-container {
    position: relative;
    width: 55%;
    margin: auto;
  }
  .global-navigation .global-search-typeahead .search-dropdown, .global-navigation .global-search-typeahead-mobile .search-dropdown {
    border-top: none;
    margin-top: 0;
    width: 80%;
  }
  .global-navigation .global-search-typeahead .search-overlay-close, .global-navigation .global-search-typeahead-mobile .search-overlay-close {
    right: 1.5rem;
  }
  .global-navigation .global-search-typeahead .search-form input, .global-navigation .global-search-typeahead-mobile .search-form input {
    font-size: 0.875rem;
    line-height: 1.1428571429;
  }
  .global-navigation .main-nav {
    align-items: flex-end;
    border-bottom: none;
    box-shadow: none;
    height: 141px;
  }
  .global-navigation .main-nav:before {
    content: "";
    background-color: #fff;
    border-bottom: 1px solid #f2f9ff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .global-navigation .main-nav .container {
    position: relative;
  }
  .global-navigation .main-nav .navbar-brand {
    height: 77px;
    width: 232px;
    margin-bottom: 24px;
    margin-right: 3rem;
    padding-top: 1rem;
  }
  .global-navigation .main-nav .main-nav-links {
    align-items: center;
    align-self: flex-end;
    display: flex;
    flex: 1;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .global-navigation .main-nav .main-nav-links > li {
    text-align: center;
  }
  .global-navigation .main-nav .main-nav-links > li > a {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    align-items: center;
    border-bottom: 7px solid transparent;
    color: #2c373f;
    display: flex;
    font-weight: 600;
    padding: 0 0.5rem 34px;
    outline: 0;
    text-decoration: none;
  }
  .global-navigation .main-nav .main-nav-links > li > a:hover {
    border-bottom-color: #ffcd55;
    color: #2c373f;
  }
  .global-navigation .main-nav .main-nav-links > li > a:focus {
    color: #2c373f;
  }
  .global-navigation .main-nav .main-nav-links > li > a .icon-arrow-down {
    height: 0.75rem;
    margin-left: 0.75rem;
    pointer-events: none;
    width: 0.75rem;
  }
  .global-navigation .main-nav .main-nav-links > li > a.btn-tertiary {
    color: #fff;
    background-color: #005557;
    border-color: #005557;
    border: 4px solid #005557;
    border-radius: 6rem;
    padding: 0.5rem 1rem;
    position: relative;
    top: -1.125rem;
  }
  .global-navigation .main-nav .main-nav-links > li > a.btn-tertiary:hover {
    color: #212529;
    background-color: #fff;
    border-color: #005557;
  }
  .global-navigation .main-nav .main-nav-links > li > a.btn-tertiary:focus, .global-navigation .main-nav .main-nav-links > li > a.btn-tertiary.focus {
    color: #212529;
    background-color: #fff;
    border-color: #005557;
    box-shadow: 0 0 0 0.2rem rgba(38, 111, 112, 0.5);
  }
  .global-navigation .main-nav .main-nav-links > li > a.btn-tertiary.disabled, .global-navigation .main-nav .main-nav-links > li > a.btn-tertiary:disabled {
    color: #fff;
    background-color: #005557;
    border-color: #005557;
  }
  .global-navigation .main-nav .main-nav-links > li > a.btn-tertiary:not(:disabled):not(.disabled):active, .global-navigation .main-nav .main-nav-links > li > a.btn-tertiary:not(:disabled):not(.disabled).active, .show > .global-navigation .main-nav .main-nav-links > li > a.btn-tertiary.dropdown-toggle {
    color: #fff;
    background-color: #002324;
    border-color: #001717;
  }
  .global-navigation .main-nav .main-nav-links > li > a.btn-tertiary:not(:disabled):not(.disabled):active:focus, .global-navigation .main-nav .main-nav-links > li > a.btn-tertiary:not(:disabled):not(.disabled).active:focus, .show > .global-navigation .main-nav .main-nav-links > li > a.btn-tertiary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 111, 112, 0.5);
  }
  .global-navigation .main-nav .main-nav-links > li > a.btn-tertiary:hover, .global-navigation .main-nav .main-nav-links > li > a.btn-tertiary:focus {
    background-color: #fff;
    border-color: #005557;
    color: #2c373f;
  }
  .global-navigation .main-nav .main-nav-links > li > a.btn-tertiary:hover svg, .global-navigation .main-nav .main-nav-links > li > a.btn-tertiary:focus svg {
    stroke: #2c373f;
  }
  .global-navigation .main-nav .main-nav-links > li > a.btn-tertiary svg {
    margin-bottom: 1px;
  }
  .global-navigation .main-nav .main-nav-links > li.has-sub-nav > .sub-nav {
    display: none;
  }
  .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active.sub-nav-opening > .sub-nav {
    animation: sub-nav-slide-down 0.4s;
    animation-timing-function: ease-in-out;
    z-index: -1;
  }
  @keyframes sub-nav-slide-down {
    from {
      transform: translate(-50%, calc(-100% - 8px));
    }
    to {
      transform: translate(-50%, 0);
    }
  }
  .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active.sub-nav-opening > .sub-nav .sub-nav-container {
    box-shadow: none;
  }
  .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active.sub-nav-closing > a .icon-arrow-down {
    transform: rotate(0deg);
  }
  .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active.sub-nav-closing > .sub-nav {
    animation: sub-nav-slide-up 0.4s;
    animation-timing-function: ease-in-out;
    transform: translate(-50%, calc(-100% - 8px));
    z-index: -1;
  }
  @keyframes sub-nav-slide-up {
    from {
      transform: translate(-50%, 0);
    }
    to {
      transform: translate(-50%, calc(-100% - 24px));
    }
  }
  .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active.sub-nav-closing > .sub-nav .sub-nav-container {
    box-shadow: none;
  }
  .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active > a {
    border-bottom-color: #ffcd55;
  }
  .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active > a .icon-arrow-down {
    transform: rotate(180deg);
  }
  .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active > .sub-nav {
    background: #fff;
    border: 1px solid #2c373f;
    border-top: 0;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.12);
    display: block;
    padding: 0;
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    left: 50%;
    width: 100%;
  }
  .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active > .sub-nav .sub-nav {
    margin-left: 2rem;
  }
  .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active .nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active .nav-links li {
    padding: 6px 0;
    text-align: left;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav {
    text-align: left;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav ul {
    list-style-type: none;
    padding: 0;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav ul li {
    font-size: 1rem;
    line-height: 1.125;
    margin-bottom: 0.75rem;
    text-align: left;
    display: flex;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav ul li a {
    font-size: 1rem;
    line-height: 1.125;
    color: #fff;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav ul li a:focus {
    outline: 0;
    text-decoration: underline;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .heading-6 {
    color: #ffcd55;
    margin-bottom: 0.75rem;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .sub-nav-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    background-color: #2c373f;
    box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.06);
    margin: 0;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .sub-nav-primary {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
    border-right: 1px solid #2c373f;
    padding: 1.75rem 2rem 1.75rem 2rem;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .sub-nav-primary.no-border {
    border-right: none;
    padding-right: 0;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .sub-nav-secondary {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    background-color: #333f48;
    padding: 1.75rem 2.75rem 1.75rem 3rem;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .featured-items {
    margin-bottom: 2rem;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .featured-items:first-child {
    margin-bottom: 2.5rem;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .featured-links {
    columns: 3;
    column-gap: 3rem;
    line-height: 0;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .quick-links-header {
    align-items: center;
    display: flex;
    margin-bottom: 0.75rem;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .quick-links-header .heading-6 {
    margin-bottom: 0;
    margin-right: 0.75rem;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .quick-links-header a {
    font-size: 0.875rem;
    line-height: 1.1428571429;
    color: #ffebbb;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .quick-links-header a:focus {
    outline: 0;
    text-decoration: underline;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .quick-links-container {
    display: flex;
    margin-bottom: 1rem;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .btn-icon {
    background-color: #3d5d6b;
    border-color: #3d5d6b;
    color: #fff;
    margin-left: 0;
    padding-left: 2.25rem;
    padding-right: 1rem;
    position: relative;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .btn-icon:not(:last-of-type) {
    margin-right: 1rem;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .btn-icon:hover {
    background-color: #f5f5f6;
    border-color: #f5f5f6;
    color: #2c373f;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .btn-icon:focus {
    box-shadow: 0 0 0 0.2rem rgba(162, 195, 214, 0.5);
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .btn-icon picture,
.global-navigation .main-nav .main-nav-links .sub-nav .quick-links .btn-icon svg {
    align-items: center;
    display: flex;
    height: 45px;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 45px;
    stroke-width: 0;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .btn-icon picture img,
.global-navigation .main-nav .main-nav-links .sub-nav .quick-links .btn-icon svg img {
    width: 100%;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .quick-links-note {
    font-size: 0.75rem;
    line-height: 1.1666666667;
    color: #fff;
    font-style: italic;
    margin-bottom: 0;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .section-list li {
    margin-bottom: 0.5rem;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .section-list a {
    font-size: 1rem;
    line-height: 1.125;
    color: #fff;
    background-color: #3d5d6b;
    border-radius: 5px;
    display: block;
    font-weight: 600;
    padding: 1rem;
    width: 100%;
    text-align: center;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .sub-nav-categories {
    display: flex;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .sub-nav-categories .sub-nav-category {
    flex: 1;
    margin-right: 3rem;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .browse-all-link {
    font-size: 1rem;
    line-height: 1.125;
    align-items: center;
    background-color: #3d5d6b;
    color: #fff;
    display: flex;
    font-weight: 600;
    height: 50px;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .browse-all-link:focus {
    outline: 0;
    text-decoration: underline;
  }
  .global-navigation .main-nav .hamburger-menu {
    display: none;
  }
  .global-navigation .hamburger-nav {
    opacity: 0;
    pointer-events: none;
  }
}
@media (min-width: 1200px) {
  .global-navigation .main-nav .main-nav-links {
    padding: 0 10px 0 200px;
  }
  .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active > .sub-nav {
    width: 90%;
  }
  .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .btn-icon {
    margin-left: 0.5rem;
    padding-left: 3rem;
  }
  .global-navigation .main-nav .navbar-brand {
    height: 65px;
    width: 200px;
    margin-bottom: 38px;
    padding-top: 0;
  }
}

.global-search {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 1.5rem;
  min-height: 720px;
}
@media (min-width: 576px) {
  .global-search {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .global-search {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .global-search {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .global-search {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.healthplan-theme .global-search .search-category-nav,
.healthplan-theme .global-search .search-secondary {
  display: none;
}
.global-search .global-search-wrapper.loading .results-count,
.global-search .global-search-wrapper.loading .pagination-controls {
  opacity: 0;
}
.global-search .global-search-wrapper.loading .loading-overlay {
  opacity: 1;
}
.global-search .global-search-wrapper.has-category .featured-results-mobile {
  display: none;
}
.global-search .no-results-message {
  margin-top: 2rem;
}
.global-search .no-results-message .no-results-image {
  margin-left: auto;
  margin-right: auto;
  padding: 2.5rem;
}
.global-search .no-results-message .card-body {
  padding: 1.25rem;
  margin-left: auto;
  margin-right: auto;
}
.global-search .no-results-message h2 {
  font-size: 1.75rem;
  line-height: 1.2142857143;
  margin-bottom: 1.25rem;
}
.global-search .no-results-message p {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  margin-bottom: 0.5rem;
}
.global-search .no-results-message ul {
  margin-bottom: 0;
}
.global-search .no-results-message ul li {
  font-size: 1.125rem;
  line-height: 1.1111111111;
}
.global-search .search-input-wrapper {
  margin-bottom: 1.5rem;
}
.global-search .search-input-wrapper .icon-close {
  fill: #2c373f;
  stroke: #2c373f;
}
.global-search .form-control, .global-search .share-story-modal form input[type=text], .share-story-modal form .global-search input[type=text],
.global-search .share-story-modal form input[type=email],
.share-story-modal form .global-search input[type=email],
.global-search .share-story-modal form input[type=tel],
.share-story-modal form .global-search input[type=tel],
.global-search .share-story-modal form select,
.share-story-modal form .global-search select,
.global-search .share-story-modal form textarea,
.share-story-modal form .global-search textarea, .global-search html .microsite-theme .marketo-form .mktoForm .mktoEmailField, html .microsite-theme .marketo-form .mktoForm .global-search .mktoEmailField,
.global-search html .microsite-theme .marketo-form .mktoForm .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .global-search .mktoTelField,
.global-search html .microsite-theme .marketo-form .mktoForm .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .global-search .mktoTextField,
.global-search html .microsite-theme .marketo-form .mktoForm select,
html .microsite-theme .marketo-form .mktoForm .global-search select,
.global-search html .microsite-theme .marketo-form .mktoForm textarea,
html .microsite-theme .marketo-form .mktoForm .global-search textarea,
.global-search html .moments-theme .marketo-form .mktoForm .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .global-search .mktoEmailField,
.global-search html .moments-theme .marketo-form .mktoForm .mktoTelField,
html .moments-theme .marketo-form .mktoForm .global-search .mktoTelField,
.global-search html .moments-theme .marketo-form .mktoForm .mktoTextField,
html .moments-theme .marketo-form .mktoForm .global-search .mktoTextField,
.global-search html .moments-theme .marketo-form .mktoForm select,
html .moments-theme .marketo-form .mktoForm .global-search select,
.global-search html .moments-theme .marketo-form .mktoForm textarea,
html .moments-theme .marketo-form .mktoForm .global-search textarea, .global-search .marketo-form .mktoForm select, .marketo-form .mktoForm .global-search select,
.global-search .marketo-form .mktoForm textarea,
.marketo-form .mktoForm .global-search textarea, .global-search .marketo-form .mktoForm .mktoEmailField, .marketo-form .mktoForm .global-search .mktoEmailField,
.global-search .marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .global-search .mktoTelField,
.global-search .marketo-form .mktoForm .mktoTextField,
.marketo-form .mktoForm .global-search .mktoTextField, .global-search textarea, .global-search .bmi-calculator input[type=text], .bmi-calculator .global-search input[type=text],
.global-search .bmi-calculator select,
.bmi-calculator .global-search select {
  border-bottom-color: #cbced1;
  background-clip: unset;
}
.global-search .search-nav-links {
  align-items: center;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  padding: 0;
}
.global-search .search-nav-links > li {
  text-align: center;
  margin-bottom: 0.625rem;
}
.global-search .search-nav-links a,
.global-search .search-nav-links button {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  align-items: center;
  background-color: transparent;
  border: none;
  border-bottom: 4px solid transparent;
  color: #2c373f;
  display: flex;
  font-weight: 600;
  padding: 0 0.5rem 0.25rem;
  outline: 0;
  text-decoration: none;
}
.global-search .search-nav-links a.active,
.global-search .search-nav-links button.active {
  border-bottom-color: #ffcd55;
  color: #2c373f;
  margin-bottom: 0.5rem;
}
.global-search .search-nav-links a.active:hover,
.global-search .search-nav-links button.active:hover {
  color: #2c373f;
}
.global-search .search-nav-links a:focus,
.global-search .search-nav-links button:focus {
  color: #2c373f;
}
.global-search .search-nav-links a:hover,
.global-search .search-nav-links button:hover {
  color: #006D9D;
}
.global-search .search-nav-links a.looking-for,
.global-search .search-nav-links button.looking-for {
  color: #cbced1;
  font-style: italic;
}
.global-search .featured-results {
  display: none;
}
.global-search .featured-results-mobile {
  display: flex;
  margin-bottom: 1.5rem;
  flex-flow: column;
}
.global-search .featured-results-mobile .featured-category {
  padding: 0;
  margin-bottom: 1rem;
}
.global-search .featured-results-mobile .view-all-link {
  font-size: 1rem;
  line-height: 1.125;
  font-weight: 600;
}
.global-search .featured-category {
  padding: 0.75rem 1rem;
  text-align: center;
  flex-basis: 50%;
  min-width: 50%;
}
.global-search .search-results-wrapper {
  min-height: 500px;
  position: relative;
}
.global-search .results-count {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #006D9D;
  margin-bottom: 1rem;
  transition: opacity 0.4s ease-in-out;
}
.global-search .results-count .current-query {
  color: #006D9D;
  font-weight: 700;
}
.global-search .results-sort {
  display: none;
}
.global-search .results-list {
  list-style: none;
  margin: 1rem 0;
  padding: 0;
}
.global-search .results-list .results-list-item {
  padding-bottom: 1rem;
}
.global-search .results-list .results-list-item .result-link {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #2c373f;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 0.625rem;
}
.global-search .results-list .results-list-item .result-link .query-keyword {
  font-weight: 700;
  color: #3579ad;
}
.global-search .results-list .results-list-item .icon-pdf {
  padding-left: 5px;
  margin-bottom: 5px;
}
.global-search .results-list .results-list-item .result-summary {
  font-size: 1rem;
  line-height: 1.25;
  color: #3d5d6b;
}
.global-search .results-list .results-list-item .result-summary .query-keyword {
  /*background-color: $color-highlight;*/
  font-weight: 700;
  /*padding: 0 0.25rem;*/
}
.global-search .search-secondary {
  display: none;
}
@media (min-width: 576px) {
  .global-search .featured-results-mobile {
    flex-flow: row;
  }
  .global-search .featured-results-mobile .featured-category {
    margin-bottom: auto;
  }
}
@media (min-width: 768px) {
  .global-search .no-results-message {
    display: flex;
    flex-direction: row;
  }
  .global-search .no-results-message .card-body {
    padding: 3rem 2.5rem;
  }
  .global-search .no-results-message h2 {
    font-size: 2rem;
    line-height: 1.1875;
  }
  .global-search .no-results-message p {
    font-size: 1.25rem;
    line-height: 1.2;
  }
  .global-search .no-results-message ul li {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}
@media (min-width: 992px) {
  .global-search {
    margin-top: 3rem;
  }
  .global-search .global-search-wrapper.has-category .featured-results,
.global-search .global-search-wrapper.has-category .search-secondary {
    display: none;
  }
  .global-search .global-search-wrapper.has-category .search-primary {
    padding-right: 0;
  }
  .global-search .search-field {
    margin: 2rem auto 1rem;
    width: 50%;
  }
  .global-search .search-input-wrapper {
    margin-bottom: 3rem;
  }
  .global-search .search-category-nav {
    text-align: center;
  }
  .global-search .search-nav-links {
    display: inline-block;
    flex-direction: row;
    margin: 0 auto 2rem auto;
  }
  .global-search .search-nav-links > li {
    display: inline-block;
    margin-bottom: 0;
    padding-right: 1rem;
  }
  .global-search .search-nav-links > li:last-child {
    padding-right: 0;
  }
  .global-search .search-nav-links a,
.global-search .search-nav-links button {
    padding-bottom: 0.375rem;
    font-weight: 700;
  }
  .global-search .search-nav-links a:hover, .global-search .search-nav-links a.active,
.global-search .search-nav-links button:hover,
.global-search .search-nav-links button.active {
    margin-bottom: 0;
  }
  .global-search .search-nav-links a:hover,
.global-search .search-nav-links button:hover {
    border-bottom-color: #2c373f;
    color: #2c373f;
    cursor: pointer;
  }
  .global-search .featured-results {
    display: flex;
    background-color: #fff;
    justify-content: space-evenly;
    margin-bottom: 2rem;
    min-height: 195px;
    position: relative;
  }
  .global-search .featured-results .current-network {
    font-size: 0.875rem;
    line-height: 1.1428571429;
    color: #3d5d6b;
    font-style: italic;
    position: absolute;
    bottom: 0.625rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .global-search .featured-results-mobile {
    display: none;
  }
  .global-search .featured-category {
    display: flex;
    background-color: #f2f9ff;
    flex-direction: column;
    padding: 2.25rem;
    text-align: left;
  }
  .global-search .featured-category:first-child {
    margin-right: 0.375rem;
  }
  .global-search .featured-category:first-child .looking-for {
    display: block;
    text-align: right;
  }
  .global-search .featured-category:first-child + .featured-category {
    margin-left: 0.375rem;
  }
  .global-search .featured-category .looking-for {
    color: #3d5d6b;
    display: flex;
    font-style: italic;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
  }
  .global-search .category-label {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    color: #2c373f;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  .global-search .category-label::after {
    background-color: #ffcd55;
    display: block;
    position: relative;
    content: "";
    height: 4px;
    margin: 0.75rem auto 0.75rem 0;
    width: 2.5rem;
  }
  .global-search .featured-list {
    display: flex;
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;
  }
  .global-search .featured-item {
    display: none;
    width: 50%;
    /* remove this once we get the correct number of items from the back end */
  }
  .global-search .featured-item:not(:last-of-type) {
    margin-right: 1rem;
  }
  .global-search .featured-item:nth-child(-n+2) {
    display: flex;
  }
  .global-search .featured-image {
    border-radius: 50%;
    display: block;
    height: 62px;
    min-width: 62px;
    overflow: hidden;
    width: 62px;
  }
  .global-search .featured-image[href]:hover, .global-search .featured-image[href]:focus {
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.075);
    text-decoration: none;
  }
  .global-search .featured-image[href]:hover + .featured-content .name, .global-search .featured-image[href]:focus + .featured-content .name {
    text-decoration: underline;
  }
  .global-search .featured-image img {
    height: 100%;
    object-fit: cover;
    object-position: top;
    width: 100%;
  }
  .global-search .featured-image svg {
    height: 100%;
    width: 100%;
  }
  .global-search .featured-content {
    padding-left: 1rem;
  }
  .global-search .featured-content .name {
    font-size: 1rem;
    line-height: 1.125;
    display: inline-block;
    color: #2c373f;
    font-weight: 700;
    margin-bottom: 0.25rem;
  }
  .global-search .featured-content .description {
    font-size: 1rem;
    line-height: 1.125;
  }
  .global-search .featured-content .description address {
    margin-top: 0.25rem;
  }
  .global-search .view-all-link {
    font-size: 1rem;
    line-height: 1.125;
    display: inline-block;
    font-weight: 600;
    margin-top: auto;
  }
  .global-search .search-results-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
  }
  .global-search .search-primary {
    flex-grow: 1;
    max-width: 765px;
    padding-right: 4rem;
  }
  .global-search .search-secondary {
    margin-left: auto;
    margin-top: 72px;
    width: 30%;
  }
  .global-search .search-secondary .heading-5 {
    font-weight: 700;
    margin-bottom: 0.625rem;
  }
  .global-search .search-secondary .category-lists {
    margin-bottom: 3rem;
  }
  .global-search .search-secondary .category-list {
    list-style: none;
    margin-bottom: 0.75rem;
    padding: 0;
  }
  .global-search .search-secondary .category-list li {
    padding-bottom: 0.25rem;
  }
  .global-search .search-secondary .category-list li:last-child {
    padding-bottom: 0;
  }
  .global-search .search-secondary .category-list a {
    font-size: 1rem;
    line-height: 1.125;
    display: inline-block;
    color: #2c373f;
    font-weight: 400;
  }
  .global-search .search-secondary .category-list a:hover {
    color: #006D9D;
    font-weight: 600;
  }
  .global-search .results-controls {
    display: flex;
    justify-content: space-between;
  }
  .global-search .results-list {
    margin-bottom: 1rem;
  }
  .global-search .search-secondary {
    display: block;
  }
}
@media (min-width: 1200px) {
  .global-search .featured-results .current-network {
    bottom: 0.75rem;
  }
  .global-search .featured-category:first-child {
    padding-right: 3rem;
  }
  .global-search .featured-category:first-child + .featured-category {
    padding-left: 3rem;
  }
  .global-search .featured-item:not(:last-of-type) {
    margin-right: 2rem;
  }
  .global-search .featured-content .name {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}

.accordion-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .accordion-container {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .accordion-container {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .accordion-container {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .accordion-container {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.accordion-container .heading-4 {
  margin-bottom: 1rem;
}
.accordion-container .accordion > .card {
  border: 0;
  margin-bottom: 1.5rem;
}
.accordion-container .accordion > .card .card-header {
  background-color: transparent;
  border-bottom: 0;
  padding: 0;
}
.accordion-container .accordion > .card .card-header.active {
  background: linear-gradient(to bottom, transparent 50%, #f2f9ff 50%);
}
.accordion-container .btn-link {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #2c373f;
  font-weight: 600;
  padding: 0.875rem 3.5rem 0.875rem 1.5rem;
  position: relative;
  text-align: left;
  width: 100%;
  box-shadow: none;
  border-radius: 4rem;
}
.accordion-container .btn-link.collapsed {
  background-color: #f5f5f6;
}
.accordion-container .btn-link.collapsed .icon-arrow-down {
  transform: translateY(-50%);
}
.accordion-container .btn-link[aria-expanded=true] {
  background-color: #cbced1;
}
.accordion-container .btn-link .icon-arrow-down {
  fill: #2c373f;
  position: absolute;
  top: 50%;
  transform: rotate(180deg) translateY(50%);
  right: 1.5rem;
}
.accordion-container .card-body {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  padding: 2rem 2.5rem 2rem;
  background-color: #f2f9ff;
  border-radius: 5px;
}
.accordion-container .card-body p,
.accordion-container .card-body li {
  font-size: 1.125rem;
  line-height: 1.1111111111;
}
.accordion-container .card-body p a {
  font-weight: 700;
}
.accordion-container .card-body :last-child:not(.btn) {
  margin-bottom: 0;
  padding-bottom: 0;
}
.two-column .accordion-container .accordion .card-body {
  padding: 2rem 2.5rem 2rem;
}
@media (min-width: 768px) {
  .accordion-container .btn-link:hover {
    color: #006D9D;
  }
  .accordion-container .btn-link:hover .icon-arrow-down {
    fill: #006D9D;
  }
}

.app-download {
  margin-bottom: 2rem;
}
.app-download.has-feature-list .icon-list-image-container {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 2rem;
}
.app-download.has-feature-list .icon-list-image-container .image-container {
  text-align: center;
}
.app-download.has-feature-list .icon-list-image-container .image-container img {
  max-width: 350px;
  width: 100%;
}
.app-download.has-feature-list .list-download-container {
  display: flex;
  flex-direction: column-reverse;
}
.app-download.has-feature-list .icon-list {
  list-style-type: none;
  margin: 3rem 0 2rem;
  padding: 0;
}
.app-download.has-feature-list .icon-list-item {
  display: flex;
}
.app-download.has-feature-list .icon-list-item:not(:last-of-type) {
  margin-bottom: 2rem;
}
.app-download.has-feature-list .list-item-title {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #2c373f;
  font-weight: 600;
  margin-bottom: 0.25rem;
}
.app-download.has-feature-list .list-icon-container {
  flex-basis: 50px;
  min-width: 50px;
  margin-right: 1rem;
}
.app-download.has-feature-list .list-icon-container .list-icon {
  width: 100%;
}
.app-download.has-feature-list .app-image {
  display: none;
}
.app-download .app-description {
  width: 100%;
}
.app-download .list-download-container {
  width: 100%;
}
.app-download .app-download-inner {
  background-color: #f2f9ff;
  padding: 2.5rem 2rem 0;
  border-radius: 5px;
}
.app-download .app-logo {
  display: none;
}
.app-download .learn-more {
  color: #006D9D;
  font-weight: 600;
}
.app-download .browser-experience {
  margin: 1rem 0;
  text-align: center;
}
.app-download .browser-experience a {
  color: #3d5d6b;
  font-weight: 600;
}
.app-download .download-btns {
  display: flex;
  margin-top: 2rem;
}
.app-download .download-btns a img {
  height: auto;
  max-height: 110px;
  max-width: 100%;
}
.app-download .download-btns a:not(:last-of-type) {
  margin-right: 1rem;
}
.app-download .disclaimer {
  font-size: 0.75rem;
  line-height: 1.1666666667;
  font-style: italic;
  margin-top: 0.5rem;
}
.app-download .app-image {
  text-align: center;
}
.app-download .app-image img,
.app-download .app-image source {
  max-width: 350px;
  width: 100%;
}
@media (min-width: 768px) {
  .app-download {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .app-download {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .app-download {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .app-download {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .app-download {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .app-download.has-feature-list .app-logo {
    margin: 0 0 2rem;
    width: 215px;
  }
  .app-download.has-feature-list .app-logo img {
    width: 100%;
    height: auto;
  }
  .app-download.has-feature-list .app-description {
    padding: 2.5rem 3rem;
  }
  .app-download.has-feature-list .icon-list-image-container {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
  }
  .app-download.has-feature-list .list-download-container {
    display: block;
  }
  .app-download.has-feature-list .icon-list {
    margin-top: 0;
    margin-right: 2rem;
  }
  .app-download.has-feature-list .download-btns {
    flex-direction: row;
  }
  .app-download.has-feature-list .download-btns a {
    max-height: 64px;
    width: auto;
  }
  .app-download.has-feature-list .download-btns a img {
    max-height: 64px;
    width: auto;
  }
  .app-download.has-feature-list .download-btns a:not(:last-of-type) {
    margin-bottom: 0;
    margin-right: 2rem;
  }
  .app-download.has-feature-list .image-container {
    align-items: center;
    flex-basis: 315px;
    margin-left: 2rem;
    margin-right: 1rem;
    min-width: 315px;
  }
}
@media (min-width: 768px) {
  .app-download .app-download-inner {
    display: flex;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .app-download .app-logo {
    display: block;
    flex-basis: 130px;
    min-width: 130px;
    margin: 3.25rem 3rem 1.5rem 3rem;
  }
  .app-download .app-logo img,
.app-download .app-logo source {
    width: 130px;
  }
}
@media (min-width: 768px) {
  .app-download .app-description {
    padding: 3.25rem 0 1.5rem;
  }
}
@media (min-width: 768px) {
  .app-download .download-btns {
    flex-direction: column;
  }
  .app-download .download-btns a {
    height: auto;
    max-width: 215px;
  }
  .app-download .download-btns a img {
    height: auto;
    width: 100%;
  }
  .app-download .download-btns a:not(:last-of-type) {
    margin-bottom: 1.5rem;
    margin-right: 0;
  }
}
@media (min-width: 768px) {
  .app-download .browser-experience {
    margin-bottom: 0;
    text-align: left;
  }
}
@media (min-width: 768px) {
  .app-download .app-image {
    align-items: flex-end;
    display: flex;
    flex-basis: 300px;
    margin-left: 2rem;
    margin-right: 1rem;
    min-width: 300px;
  }
  .app-download .app-image.center {
    align-items: center;
  }
  .app-download .app-image img,
.app-download .app-image source {
    width: 315px;
  }
}
@media (min-width: 992px) {
  .app-download.has-feature-list .icon-list-image-container .image-container {
    display: none;
  }
  .app-download.has-feature-list .app-image {
    display: flex;
  }
  .app-download .app-logo {
    display: block;
    flex-basis: 160px;
    min-width: 160px;
    margin-right: 4rem;
  }
  .app-download .app-logo img,
.app-download .app-logo source {
    width: 160px;
  }
  .app-download .download-btns {
    flex-direction: row;
  }
  .app-download .download-btns a {
    max-height: 64px;
  }
  .app-download .download-btns a img {
    max-height: 64px;
    width: auto;
  }
  .app-download .download-btns a:not(:last-of-type) {
    margin-bottom: 0;
    margin-right: 2rem;
  }
  .app-download .app-image {
    flex-basis: 315px;
    min-width: 315px;
    margin-left: 3rem;
  }
}

.appointments-modal .modal-dialog {
  margin: 1rem 0.75rem;
  max-width: none;
}
.appointments-modal .modal-content {
  border: 0;
}
.appointments-modal .modal-body {
  padding: 0;
}
.appointments-modal .modal-body:only-child {
  padding: 0;
}
.appointments-modal .modal-close-btn {
  background: transparent;
  border: 0;
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
}
.appointments-modal .modal-close-btn svg {
  height: 1.25rem;
  width: 1.25rem;
}
.appointments-modal .appointments .appointments-inner {
  padding: 2.5rem 1rem 2rem;
}
.appointments-modal .appointments .appointment-selection {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  width: calc(100% + 1rem);
}
.appointments-modal .appointments .appointment-selection .no-appointments-message {
  top: 40%;
}
.appointments-modal .appointments .day-list {
  justify-content: space-around;
}

.article-list {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .article-list {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .article-list {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .article-list {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .article-list {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.article-list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.article-list .article-listing {
  background-color: #f5f5f6;
  border-radius: 5px;
  margin-left: 0;
  padding: 2rem;
}
.article-list .article-listing:before {
  display: none;
}
.article-list .article-listing:not(:last-of-type) {
  margin-bottom: 2.5rem;
}
.article-list .article-listing.research .heading-4 {
  color: #006D9D;
}
.article-list .article-listing.research .article-links a:after {
  content: "»";
  display: inline-block;
  padding-left: 0.25rem;
}
.article-list .article-listing.companion .article-content {
  justify-content: left;
}
.article-list .article-listing.companion .article-content .article-content-inner {
  display: flex;
}
.article-list .article-date {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  display: none;
  font-weight: 300;
  margin-bottom: 0.25rem;
}
.article-list .article-inner {
  display: flex;
  flex-flow: column;
}
.article-list .article-inner .article-links {
  display: block;
}
.article-list .article-inner .article-links a:not(.btn-primary):after {
  content: "»";
  display: inline-block;
  padding-left: 0.25rem;
}
.article-list .article-image {
  margin-bottom: 1.5rem;
}
.article-list .article-image img {
  height: 205px;
  width: 205px;
  object-fit: cover;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%;";
}
.article-list .article-image img.content-icon {
  height: 100px;
  width: 100px;
}
.article-list .section-title:after {
  background-color: #ffcd55;
}
.article-list .heading-4 {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
  color: #2c373f;
}
.article-list p {
  display: block;
}
.article-list .article-content .description-xs {
  display: block;
}
.article-list .article-content .description-xs p {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  display: block;
}
.article-list .article-content .description-lg {
  display: none;
}
.article-list .article-links {
  font-size: 0;
  margin-top: 1.25rem;
}
.article-list .article-links span {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-weight: 600;
  margin-right: 0.5rem;
}
.article-list .article-links a {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-weight: 600;
  display: inline-block;
  position: relative;
}
.article-list .article-links a:not(:first-of-type) {
  margin-left: 1rem;
}
.article-list .article-links a:not(:first-of-type):before {
  color: #006D9D;
  content: "•";
  display: block;
  position: absolute;
  left: -0.5rem;
  transform: translateX(-50%);
}
.article-list .article-date {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  padding: 0.5rem 1rem;
  border-radius: 6rem;
  border-width: 5px;
}
.article-list .btn, .article-list .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .article-list .mktoButton {
  padding: 0.5rem 1rem;
}
@media (min-width: 576px) {
  .article-list .article-inner {
    flex-flow: row;
  }
  .article-list .article-image {
    flex: 0 0 175px;
    height: 175px;
    width: 175px;
    margin-right: 1.5rem;
    margin-bottom: 0;
  }
  .article-list .article-image img {
    height: 100%;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .two-column .article-list .article-date {
    display: none;
  }
  .two-column .article-list .article-image {
    flex: 0 0 150px;
    height: 150px;
    width: 150px;
  }
  .article-list .article-inner {
    flex-flow: row;
  }
  .article-list .article-inner .article-links {
    display: block;
  }
  .article-list .article-date {
    font-weight: 400;
    display: flex;
    background-color: #fff;
    border-color: #fff;
    align-self: flex-start;
    order: 1;
    padding-left: 1rem;
    padding-right: 1rem;
    white-space: nowrap;
  }
  .article-list .companion .article-date {
    background-color: transparent;
    border-color: transparent;
  }
  .article-list .article-image {
    flex: 0 0 175px;
    height: 175px;
    margin-right: 2rem;
  }
  .article-list p {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    margin-bottom: 0;
    margin-top: 0.75rem;
  }
  .article-list .article-content {
    margin-right: 2rem;
  }
  .article-list .article-links {
    display: none;
    margin-top: 0.75rem;
  }
}
@media (min-width: 992px) {
  .two-column .article-list .article-inner {
    flex-flow: row;
  }
  .two-column .article-list .article-date {
    display: flex;
  }
  .two-column .article-list .article-image {
    flex: 0 0 200px;
    height: 200px;
    width: 200px;
  }
  .article-list .article-image {
    flex: 0 0 200px;
    height: 200px;
    width: 200px;
  }
  .article-list .companion .article-image {
    flex: 0 0 200px;
    height: 100px;
    width: 100px;
  }
  .article-list .article-content .description-xs {
    display: none;
  }
  .article-list .article-content .description-lg {
    display: block;
  }
  .article-list .article-content .description-lg p {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    display: block;
  }
}

.article-list-paginated {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .article-list-paginated {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .article-list-paginated {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .article-list-paginated {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .article-list-paginated {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.article-list-paginated .article-list {
  padding-left: 0;
  padding-right: 0;
}
.article-list-paginated .article-listing {
  display: none;
}
.article-list-paginated .article-listing.active {
  display: block;
}
.article-list-paginated .article-listing:last-of-type {
  margin-bottom: 2.5rem;
}
.article-list-paginated .pagination-controls .pagination-counts {
  justify-content: center;
}

.audio-player {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .audio-player {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .audio-player {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .audio-player {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .audio-player {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.audio-player audio {
  width: 100%;
}
.generic .audio-player, .two-column .audio-player {
  padding: 0;
}

.audio-with-image {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .audio-with-image {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .audio-with-image {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .audio-with-image {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .audio-with-image {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.audio-with-image .audio-with-image-inner .audio-image {
  margin-bottom: 1rem;
  text-align: center;
}
.audio-with-image .audio-with-image-inner .audio-image img {
  max-width: 100%;
}
.audio-with-image .audio-with-image-inner .audio-player {
  display: block;
  padding-left: 0;
  padding-right: 0;
}
.audio-with-image .audio-with-image-inner .audio-player .audio-text,
.audio-with-image .audio-with-image-inner .audio-player .audio-element,
.audio-with-image .audio-with-image-inner .audio-player audio {
  max-width: none;
  width: 100%;
}
.audio-with-image .audio-with-image-inner .audio-player .audio-element {
  padding-top: 0;
}
.audio-with-image .audio-with-image-inner audio::-webkit-media-controls-play-button {
  transform: scale(1.5, 1.5);
  left: -2px;
}
@media (min-width: 992px) {
  .audio-with-image {
    margin-bottom: 2.5rem;
  }
  .audio-with-image .audio-with-image-inner {
    display: flex;
    justify-content: space-between;
  }
  .audio-with-image .audio-with-image-inner .audio-image {
    flex-basis: 205px;
    margin-bottom: 1rem;
    max-width: 205px;
    max-height: 100%;
    margin-bottom: 0;
    position: relative;
    width: 205px;
  }
  .audio-with-image .audio-with-image-inner .audio-image img,
.audio-with-image .audio-with-image-inner .audio-image source {
    height: auto;
    object-fit: cover;
    object-position: 50% 50%;
    font-family: "object-fit: cover; object-position: 50% 50%;";
    width: 100%;
  }
  .audio-with-image .audio-with-image-inner .audio-player {
    flex-basis: calc(100% - 2rem - 205px);
    max-width: calc(100% - 2rem - 205px);
    margin-left: unset;
    margin-right: unset;
  }
  .audio-with-image .audio-with-image-inner .audio-player .audio-element {
    padding-top: 1rem;
  }
}

.tag-list .tag {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #006D9D;
  padding-top: 0.5rem;
}
.tag-list .tag span {
  display: inline-block;
}
.tag-list .tag .tag-remove-btn {
  font-size: 1rem;
  line-height: 1.125;
  background: none;
  border: none;
  font-weight: 700;
  margin-left: 0.25rem;
  padding: 0;
}
.tag-list .tag .tag-remove-btn .icon {
  align-self: center;
  display: flex;
  fill: #cbced1;
  height: 0.75rem;
  width: 0.75rem;
}
.tag-list .tag .tag-remove-btn:hover, .tag-list .tag .tag-remove-btn:focus {
  outline: 0;
}
.tag-list .tag .tag-remove-btn:hover .icon, .tag-list .tag .tag-remove-btn:focus .icon {
  fill: #a2c3d6;
}

.hero-article {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .hero-article {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .hero-article {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .hero-article {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .hero-article {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.hero-article .divider {
  width: 15.375rem;
}
.hero-article .hero-outer {
  overflow: hidden;
  position: relative;
  background-color: #2c373f;
}
.hero-article .hero-img {
  display: block;
  background-color: #2c373f;
}
.hero-article .hero-img img,
.hero-article .hero-img source {
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
  border-radius: 0;
  height: 25rem;
  clip-path: circle(700px at 50% -19rem);
}
.hero-article .hero-content-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  padding: 0 2rem;
  background-color: #2c373f;
}
@media (min-width: 576px) {
  .hero-article .hero-content-container {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .hero-article .hero-content-container {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .hero-article .hero-content-container {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .hero-article .hero-content-container {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.hero-article .hero-content {
  flex: auto;
  max-width: 100%;
  display: block;
  padding: 1rem 0 2rem;
  text-align: left;
}
.hero-article .hero-content a.btn-primary, .hero-article .hero-content .marketo-form .mktoForm a.mktoButton, .marketo-form .mktoForm .hero-article .hero-content a.mktoButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  padding: 0.75rem 1.375rem;
}
.hero-article .hero-content a.btn-primary svg, .hero-article .hero-content .marketo-form .mktoForm a.mktoButton svg, .marketo-form .mktoForm .hero-article .hero-content a.mktoButton svg {
  margin: 0 5px 0 0px;
}
.hero-article .hero-content a.btn-primary:hover, .hero-article .hero-content .marketo-form .mktoForm a.mktoButton:hover, .marketo-form .mktoForm .hero-article .hero-content a.mktoButton:hover, .hero-article .hero-content a.btn-primary:focus, .hero-article .hero-content .marketo-form .mktoForm a.mktoButton:focus, .marketo-form .mktoForm .hero-article .hero-content a.mktoButton:focus {
  color: #ffcd55;
  background-color: #2c373f;
  text-decoration: none;
}
.hero-article .hero-content a.btn-primary:hover .icon.icon-arrow-long-left, .hero-article .hero-content .marketo-form .mktoForm a.mktoButton:hover .icon.icon-arrow-long-left, .marketo-form .mktoForm .hero-article .hero-content a.mktoButton:hover .icon.icon-arrow-long-left, .hero-article .hero-content a.btn-primary:hover .icon.icon-arrow-left, .hero-article .hero-content .marketo-form .mktoForm a.mktoButton:hover .icon.icon-arrow-left, .marketo-form .mktoForm .hero-article .hero-content a.mktoButton:hover .icon.icon-arrow-left, .hero-article .hero-content a.btn-primary:focus .icon.icon-arrow-long-left, .hero-article .hero-content .marketo-form .mktoForm a.mktoButton:focus .icon.icon-arrow-long-left, .marketo-form .mktoForm .hero-article .hero-content a.mktoButton:focus .icon.icon-arrow-long-left, .hero-article .hero-content a.btn-primary:focus .icon.icon-arrow-left, .hero-article .hero-content .marketo-form .mktoForm a.mktoButton:focus .icon.icon-arrow-left, .marketo-form .mktoForm .hero-article .hero-content a.mktoButton:focus .icon.icon-arrow-left {
  fill: #ffcd55;
}
.hero-article .hero-category {
  font-size: 1.75rem;
  line-height: 1.2142857143;
  color: #fff;
  font-weight: 600;
}
.hero-article .hero-title {
  font-size: 1.75rem;
  line-height: 1.2142857143;
  color: #fff;
  font-weight: 600;
}
.hero-article .hero-description {
  font-size: 1rem;
  line-height: 1.125;
  display: block;
  color: #fff;
  font-weight: 400;
  margin: 0;
  padding: 0.5rem 0 1.5rem;
  line-height: 1.5rem;
}
.hero-article .divider-hero {
  border-bottom: 2px solid #a2c3d6;
  margin: 0.75rem auto 0.5rem 0;
  width: 4rem;
}
.hero-article .read-more-link {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  padding: 0;
  color: #005557;
  font-weight: 700;
  text-transform: uppercase;
}
@media (min-width: 576px) {
  .hero-article .hero-img img,
.hero-article .hero-img source {
    height: 30rem;
    clip-path: circle(800px at 50% -21rem);
  }
}
@media (min-width: 768px) {
  .hero-article {
    padding-top: 1rem;
  }
  .hero-article .hero-outer {
    height: 475px;
  }
  .hero-article .hero-img {
    height: 100%;
    position: absolute;
  }
  .hero-article .hero-img img,
.hero-article .hero-img source {
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    font-family: "object-fit: cover; object-position: 50% 50%;";
    width: 100%;
    clip-path: none;
  }
  .hero-article .hero-content-container {
    display: flex;
    justify-content: flex-start;
    bottom: 0;
    height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 0;
    position: absolute;
    clip-path: circle(66% at center right);
  }
  .hero-article .hero-content {
    flex: 0 0 45%;
    max-width: 45%;
    flex-direction: column;
    align-self: center;
    text-align: right;
    margin-left: auto;
    padding: 5rem 0 2rem;
    object-position: 50% 50%;
    height: 25rem;
    clip-path: circle(800px at 50% -19rem);
  }
  .hero-article .hero-title {
    font-size: 3rem;
    line-height: 1.0833333333;
    color: #fff;
    font-weight: 600;
  }
  .hero-article .divider {
    margin: 1rem 0 1rem auto;
  }
  .hero-article .divider-hero {
    border-bottom: 2px solid #a2c3d6;
    margin: 1.25rem auto 0.5rem 0;
    width: 4rem;
  }
}
@media (min-width: 992px) {
  .hero-article .hero-content-container {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 1200px) {
  .hero-article .hero-content-container {
    flex: 0 0 50%;
    max-width: 50%;
    background-position: 35rem 50%;
  }
}

.hero-primary {
  color: #a2c3d6;
  margin-bottom: 0;
}
.hero-primary.is-takeover + .tool .card-deck {
  margin-top: 0;
}
.hero-primary.is-takeover .btn, .hero-primary.is-takeover .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .hero-primary.is-takeover .mktoButton {
  display: inline-block;
  margin-left: 1rem;
}
.hero-primary.is-takeover .hero-outer .form-group {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: block;
}
@media (min-width: 576px) {
  .hero-primary.is-takeover .hero-outer .form-group {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .hero-primary.is-takeover .hero-outer .form-group {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .hero-primary.is-takeover .hero-outer .form-group {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .hero-primary.is-takeover .hero-outer .form-group {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.hero-primary.is-takeover .hero-content .form-group {
  display: none;
}
.hero-primary:not(.is-takeover) .hero-outer .form-group {
  display: none;
}
.hero-primary:not(.is-takeover) .hero-content .form-group {
  display: block;
  padding-top: 1rem;
}
.hero-primary .btn, .hero-primary .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .hero-primary .mktoButton {
  display: none;
}
.hero-primary .hero-title {
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 600;
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0;
  color: #006D9D;
}
.hero-primary h3.hero-title {
  margin-bottom: 1rem;
}
.hero-primary .hero-outer {
  background-color: #f5f5f6;
  border-radius: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
}
.hero-primary .hero-content-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background: linear-gradient(to top, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 40%, rgba(255, 255, 255, 0.5) 85%, rgba(255, 255, 255, 0) 100%);
  padding-top: 2rem;
  position: relative;
  z-index: 1;
}
@media (min-width: 576px) {
  .hero-primary .hero-content-container {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .hero-primary .hero-content-container {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .hero-primary .hero-content-container {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .hero-primary .hero-content-container {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.hero-primary .hero-content-container .global-search-typeahead .search-dropdown {
  background-color: #2c373f;
  border-radius: 5px;
  top: calc(100% + 1.5rem);
}
.hero-primary .hero-content-container .global-search-typeahead .primary-view-all {
  border-radius: 0 0 5px 5px;
}
.hero-primary .hero-img {
  display: block;
  position: relative;
  text-align: center;
  height: 25rem;
}
.hero-primary .hero-img img,
.hero-primary .hero-img source {
  object-fit: none;
  object-position: 40% 45%;
  height: 35rem;
  width: 100%;
  border-radius: 0;
}
.hero-primary.dark-bg .hero-content-container {
  background: linear-gradient(to top, rgb(44, 55, 63) 0%, rgb(44, 55, 63) 40%, rgba(44, 55, 63, 0.75) 85%, rgba(44, 55, 63, 0) 100%);
  position: relative;
  z-index: 1;
}
.hero-primary.dark-bg .hero-content-container .hero-content {
  color: #fff;
}
.hero-primary.dark-bg .hero-content-container .hero-title {
  color: #fff;
}
.hero-primary .hero-content {
  flex: auto;
  max-width: 100%;
  display: block;
  padding: 1.5rem 0 1rem;
}
.hero-primary .form-control, .hero-primary .share-story-modal form input[type=text], .share-story-modal form .hero-primary input[type=text],
.hero-primary .share-story-modal form input[type=email],
.share-story-modal form .hero-primary input[type=email],
.hero-primary .share-story-modal form input[type=tel],
.share-story-modal form .hero-primary input[type=tel],
.hero-primary .share-story-modal form select,
.share-story-modal form .hero-primary select,
.hero-primary .share-story-modal form textarea,
.share-story-modal form .hero-primary textarea, .hero-primary html .microsite-theme .marketo-form .mktoForm .mktoEmailField, html .microsite-theme .marketo-form .mktoForm .hero-primary .mktoEmailField,
.hero-primary html .microsite-theme .marketo-form .mktoForm .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .hero-primary .mktoTelField,
.hero-primary html .microsite-theme .marketo-form .mktoForm .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .hero-primary .mktoTextField,
.hero-primary html .microsite-theme .marketo-form .mktoForm select,
html .microsite-theme .marketo-form .mktoForm .hero-primary select,
.hero-primary html .microsite-theme .marketo-form .mktoForm textarea,
html .microsite-theme .marketo-form .mktoForm .hero-primary textarea,
.hero-primary html .moments-theme .marketo-form .mktoForm .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .hero-primary .mktoEmailField,
.hero-primary html .moments-theme .marketo-form .mktoForm .mktoTelField,
html .moments-theme .marketo-form .mktoForm .hero-primary .mktoTelField,
.hero-primary html .moments-theme .marketo-form .mktoForm .mktoTextField,
html .moments-theme .marketo-form .mktoForm .hero-primary .mktoTextField,
.hero-primary html .moments-theme .marketo-form .mktoForm select,
html .moments-theme .marketo-form .mktoForm .hero-primary select,
.hero-primary html .moments-theme .marketo-form .mktoForm textarea,
html .moments-theme .marketo-form .mktoForm .hero-primary textarea, .hero-primary .marketo-form .mktoForm select, .marketo-form .mktoForm .hero-primary select,
.hero-primary .marketo-form .mktoForm textarea,
.marketo-form .mktoForm .hero-primary textarea, .hero-primary .marketo-form .mktoForm .mktoEmailField, .marketo-form .mktoForm .hero-primary .mktoEmailField,
.hero-primary .marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .hero-primary .mktoTelField,
.hero-primary .marketo-form .mktoForm .mktoTextField,
.marketo-form .mktoForm .hero-primary .mktoTextField, .hero-primary textarea, .hero-primary .bmi-calculator input[type=text], .bmi-calculator .hero-primary input[type=text],
.hero-primary .bmi-calculator select,
.bmi-calculator .hero-primary select {
  font-size: 1rem;
  line-height: 1;
  background-color: #fff;
  border: none;
  color: #3d5d6b;
  font-weight: 600;
  padding-left: 0;
  padding-right: 0;
}
.hero-primary .form-control:focus, .hero-primary .share-story-modal form input[type=text]:focus, .share-story-modal form .hero-primary input[type=text]:focus,
.hero-primary .share-story-modal form input[type=email]:focus,
.share-story-modal form .hero-primary input[type=email]:focus,
.hero-primary .share-story-modal form input[type=tel]:focus,
.share-story-modal form .hero-primary input[type=tel]:focus,
.hero-primary .share-story-modal form select:focus,
.share-story-modal form .hero-primary select:focus,
.hero-primary .share-story-modal form textarea:focus,
.share-story-modal form .hero-primary textarea:focus, .hero-primary .marketo-form .mktoForm select:focus, .marketo-form .mktoForm .hero-primary select:focus,
.hero-primary .marketo-form .mktoForm textarea:focus,
.marketo-form .mktoForm .hero-primary textarea:focus, .hero-primary .marketo-form .mktoForm .mktoEmailField:focus, .marketo-form .mktoForm .hero-primary .mktoEmailField:focus,
.hero-primary .marketo-form .mktoForm .mktoTelField:focus,
.marketo-form .mktoForm .hero-primary .mktoTelField:focus,
.hero-primary .marketo-form .mktoForm .mktoTextField:focus,
.marketo-form .mktoForm .hero-primary .mktoTextField:focus, .hero-primary textarea:focus, .hero-primary .bmi-calculator input[type=text]:focus, .bmi-calculator .hero-primary input[type=text]:focus,
.hero-primary .bmi-calculator select:focus,
.bmi-calculator .hero-primary select:focus {
  background-color: #fff;
}
.hero-primary .hero-outer > .form-group {
  display: block;
  margin-bottom: 0;
  padding: 0 1rem;
}
.hero-primary .hero-outer > .form-group .icon {
  fill: #006D9D;
}
.hero-primary .hero-outer > .form-group label {
  background-color: transparent;
  box-shadow: none;
  margin-bottom: 0;
}
.hero-primary .hero-outer > .form-group .form-control, .hero-primary .hero-outer > .form-group .share-story-modal form input[type=text], .share-story-modal form .hero-primary .hero-outer > .form-group input[type=text],
.hero-primary .hero-outer > .form-group .share-story-modal form input[type=email],
.share-story-modal form .hero-primary .hero-outer > .form-group input[type=email],
.hero-primary .hero-outer > .form-group .share-story-modal form input[type=tel],
.share-story-modal form .hero-primary .hero-outer > .form-group input[type=tel],
.hero-primary .hero-outer > .form-group .share-story-modal form select,
.share-story-modal form .hero-primary .hero-outer > .form-group select,
.hero-primary .hero-outer > .form-group html .microsite-theme .marketo-form .mktoForm textarea,
html .microsite-theme .marketo-form .mktoForm .hero-primary .hero-outer > .form-group textarea,
.hero-primary .hero-outer > .form-group html .moments-theme .marketo-form .mktoForm textarea,
html .moments-theme .marketo-form .mktoForm .hero-primary .hero-outer > .form-group textarea, .hero-primary .hero-outer > .form-group .marketo-form .mktoForm select, .marketo-form .mktoForm .hero-primary .hero-outer > .form-group select, .hero-primary .hero-outer > .form-group .marketo-form .mktoForm .mktoEmailField, .marketo-form .mktoForm .hero-primary .hero-outer > .form-group .mktoEmailField,
.hero-primary .hero-outer > .form-group .marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .hero-primary .hero-outer > .form-group .mktoTelField,
.hero-primary .hero-outer > .form-group .marketo-form .mktoForm .mktoTextField,
.marketo-form .mktoForm .hero-primary .hero-outer > .form-group .mktoTextField, .hero-primary .hero-outer > .form-group textarea, .hero-primary .hero-outer > .form-group .bmi-calculator input[type=text], .bmi-calculator .hero-primary .hero-outer > .form-group input[type=text],
.hero-primary .hero-outer > .form-group .bmi-calculator select,
.bmi-calculator .hero-primary .hero-outer > .form-group select {
  background-color: transparent;
  color: #2c373f;
  display: block;
}
.hero-primary .hero-outer > .form-group .form-control::placeholder, .hero-primary .hero-outer > .form-group .share-story-modal form input[type=text]::placeholder, .share-story-modal form .hero-primary .hero-outer > .form-group input[type=text]::placeholder,
.hero-primary .hero-outer > .form-group .share-story-modal form input[type=email]::placeholder,
.share-story-modal form .hero-primary .hero-outer > .form-group input[type=email]::placeholder,
.hero-primary .hero-outer > .form-group .share-story-modal form input[type=tel]::placeholder,
.share-story-modal form .hero-primary .hero-outer > .form-group input[type=tel]::placeholder,
.hero-primary .hero-outer > .form-group .share-story-modal form select::placeholder,
.share-story-modal form .hero-primary .hero-outer > .form-group select::placeholder,
.hero-primary .hero-outer > .form-group html .microsite-theme .marketo-form .mktoForm textarea::placeholder,
html .microsite-theme .marketo-form .mktoForm .hero-primary .hero-outer > .form-group textarea::placeholder,
.hero-primary .hero-outer > .form-group html .moments-theme .marketo-form .mktoForm textarea::placeholder,
html .moments-theme .marketo-form .mktoForm .hero-primary .hero-outer > .form-group textarea::placeholder, .hero-primary .hero-outer > .form-group .marketo-form .mktoForm select::placeholder, .marketo-form .mktoForm .hero-primary .hero-outer > .form-group select::placeholder, .hero-primary .hero-outer > .form-group .marketo-form .mktoForm .mktoEmailField::placeholder, .marketo-form .mktoForm .hero-primary .hero-outer > .form-group .mktoEmailField::placeholder,
.hero-primary .hero-outer > .form-group .marketo-form .mktoForm .mktoTelField::placeholder,
.marketo-form .mktoForm .hero-primary .hero-outer > .form-group .mktoTelField::placeholder,
.hero-primary .hero-outer > .form-group .marketo-form .mktoForm .mktoTextField::placeholder,
.marketo-form .mktoForm .hero-primary .hero-outer > .form-group .mktoTextField::placeholder, .hero-primary .hero-outer > .form-group textarea::placeholder, .hero-primary .hero-outer > .form-group .bmi-calculator input[type=text]::placeholder, .bmi-calculator .hero-primary .hero-outer > .form-group input[type=text]::placeholder,
.hero-primary .hero-outer > .form-group .bmi-calculator select::placeholder,
.bmi-calculator .hero-primary .hero-outer > .form-group select::placeholder {
  color: #2c373f;
}
.hero-primary .hero-outer > .form-group .search-form {
  border-color: #006D9D;
}
.hero-primary .search-form {
  display: flex;
}
.hero-primary .form-group {
  padding-top: 1rem;
}
.hero-primary label {
  background-color: #fff;
  border: 1px solid #cbced1;
  border-radius: 6rem;
  display: block;
  padding: 1rem 2rem;
  position: relative;
}
.hero-primary .search-typeahead-input {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #2c373f;
  padding: 0;
}
.hero-primary .search-typeahead-input::placeholder {
  color: #2c373f;
}
.hero-primary .icon {
  height: 1.25rem;
  fill: #a2c3d6;
  display: flex;
  align-self: center;
  right: 0;
  width: 1.5rem;
  z-index: 1;
}
.hero-primary .secondary-nav .nav-links {
  list-style: none;
  padding: 1rem 0;
}
.hero-primary .secondary-nav .nav-links li {
  font-weight: 700;
}
.hero-primary .secondary-nav .nav-links li a {
  color: #2c373f;
  text-decoration: none;
}
.hero-primary .secondary-nav .nav-links li a.btn, .hero-primary .secondary-nav .nav-links li .marketo-form .mktoForm a.mktoButton, .marketo-form .mktoForm .hero-primary .secondary-nav .nav-links li a.mktoButton {
  display: flex;
  justify-content: center;
}
.hero-primary .secondary-nav .nav-links li a:hover {
  text-decoration: none;
}
.hero-primary .secondary-nav .nav-links li a svg {
  fill: #2c373f;
  top: -0.125rem;
  position: relative;
}
.hero-primary .tertiary-nav .contact-phone {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #3d5d6b;
  display: block;
  outline: 0;
  padding: 2rem 2rem 1rem;
}
.hero-primary .tertiary-nav .contact-phone:hover, .hero-primary .tertiary-nav .contact-phone:focus {
  text-decoration: underline;
}
.hero-primary .tertiary-nav .nav-section-heading {
  font-size: 1rem;
  line-height: 1.125;
  color: #005557;
  font-weight: 400;
  padding: 1rem 2rem 0;
}
.hero-primary .tertiary-nav .nav-links {
  list-style: none;
  column-count: 2;
  column-gap: 0.375rem;
  padding: 0;
}
.hero-primary .tertiary-nav .nav-links li {
  font-weight: 700;
}
.hero-primary .tertiary-nav .nav-links li.nurse-help-line-md, .hero-primary .tertiary-nav .nav-links li.schedule-online-md {
  display: none;
}
.hero-primary .tertiary-nav .nav-links li.nurse-help-line-md span, .hero-primary .tertiary-nav .nav-links li.schedule-online-md span {
  font-weight: 400;
}
.hero-primary .tertiary-nav .nav-links li.nurse-help-line-xs {
  display: block;
}
.hero-primary .tertiary-nav .nav-links li a {
  color: #fff;
  padding: 0.75rem 0.5rem;
  text-decoration: none;
}
.hero-primary .tertiary-nav .nav-links li a.btn, .hero-primary .tertiary-nav .nav-links li .marketo-form .mktoForm a.mktoButton, .marketo-form .mktoForm .hero-primary .tertiary-nav .nav-links li a.mktoButton {
  display: flex;
  justify-content: center;
}
.hero-primary .tertiary-nav .nav-links li a:hover, .hero-primary .tertiary-nav .nav-links li a:focus {
  color: #2c373f;
  text-decoration: none;
}
.hero-primary .tertiary-nav .nav-links li a:hover svg, .hero-primary .tertiary-nav .nav-links li a:focus svg {
  fill: #2c373f;
}
.hero-primary .tertiary-nav .nav-links li a svg {
  fill: #fff;
  top: -0.125rem;
  position: relative;
  height: 1rem;
  width: 1rem;
  margin: 0 -8px 0 12px;
}
.hero-primary .tertiary-nav .image-links {
  padding: 1rem 2rem 1.75rem;
}
@media (min-width: 576px) {
  .hero-primary .hero-img.right {
    object-position: 90% 40%;
  }
  .hero-primary .hero-img img,
.hero-primary .hero-img source {
    object-position: 10% 40%;
  }
  .hero-primary .form-group {
    padding-top: 1rem;
  }
  .hero-primary .form-control, .hero-primary .share-story-modal form input[type=text], .share-story-modal form .hero-primary input[type=text],
.hero-primary .share-story-modal form input[type=email],
.share-story-modal form .hero-primary input[type=email],
.hero-primary .share-story-modal form input[type=tel],
.share-story-modal form .hero-primary input[type=tel],
.hero-primary .share-story-modal form select,
.share-story-modal form .hero-primary select,
.hero-primary .share-story-modal form textarea,
.share-story-modal form .hero-primary textarea, .hero-primary html .microsite-theme .marketo-form .mktoForm .mktoEmailField, html .microsite-theme .marketo-form .mktoForm .hero-primary .mktoEmailField,
.hero-primary html .microsite-theme .marketo-form .mktoForm .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .hero-primary .mktoTelField,
.hero-primary html .microsite-theme .marketo-form .mktoForm .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .hero-primary .mktoTextField,
.hero-primary html .microsite-theme .marketo-form .mktoForm select,
html .microsite-theme .marketo-form .mktoForm .hero-primary select,
.hero-primary html .microsite-theme .marketo-form .mktoForm textarea,
html .microsite-theme .marketo-form .mktoForm .hero-primary textarea,
.hero-primary html .moments-theme .marketo-form .mktoForm .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .hero-primary .mktoEmailField,
.hero-primary html .moments-theme .marketo-form .mktoForm .mktoTelField,
html .moments-theme .marketo-form .mktoForm .hero-primary .mktoTelField,
.hero-primary html .moments-theme .marketo-form .mktoForm .mktoTextField,
html .moments-theme .marketo-form .mktoForm .hero-primary .mktoTextField,
.hero-primary html .moments-theme .marketo-form .mktoForm select,
html .moments-theme .marketo-form .mktoForm .hero-primary select,
.hero-primary html .moments-theme .marketo-form .mktoForm textarea,
html .moments-theme .marketo-form .mktoForm .hero-primary textarea, .hero-primary .marketo-form .mktoForm select, .marketo-form .mktoForm .hero-primary select,
.hero-primary .marketo-form .mktoForm textarea,
.marketo-form .mktoForm .hero-primary textarea, .hero-primary .marketo-form .mktoForm .mktoEmailField, .marketo-form .mktoForm .hero-primary .mktoEmailField,
.hero-primary .marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .hero-primary .mktoTelField,
.hero-primary .marketo-form .mktoForm .mktoTextField,
.marketo-form .mktoForm .hero-primary .mktoTextField, .hero-primary textarea, .hero-primary .bmi-calculator input[type=text], .bmi-calculator .hero-primary input[type=text],
.hero-primary .bmi-calculator select,
.bmi-calculator .hero-primary select {
    font-size: 1.125rem;
    line-height: 0.8888888889;
  }
}
@media (min-width: 768px) {
  .hero-primary.is-takeover .btn, .hero-primary.is-takeover .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .hero-primary.is-takeover .mktoButton {
    margin-left: 0;
  }
  .hero-primary .hero-title {
    font-size: 2.5rem;
    line-height: 1.2;
    padding: 0;
  }
  .hero-primary .hero-img.right {
    object-position: 70% 40%;
  }
  .hero-primary .hero-img img,
.hero-primary .hero-img source {
    object-position: 30% 40%;
  }
  .hero-primary .hero-img img.right,
.hero-primary .hero-img source.right {
    object-position: 70% 40%;
  }
  .hero-primary .secondary-nav .nav-links li.schedule-online-xs {
    display: none;
  }
  .hero-primary.dark-bg .hero-content-container {
    background: linear-gradient(to right, rgba(44, 55, 63, 0.8) 0%, rgba(44, 55, 63, 0.6) 40%, rgba(44, 55, 63, 0) 85%, rgba(44, 55, 63, 0) 100%);
    position: absolute;
    z-index: 1;
  }
  .hero-primary.dark-bg .hero-content-container .hero-content {
    color: #fff;
  }
  .hero-primary.dark-bg .hero-content-container .hero-title {
    color: #fff;
  }
  .hero-primary.dark-bg .hero-content-container.right {
    background: linear-gradient(to left, rgba(44, 55, 63, 0.8) 0%, rgba(44, 55, 63, 0.6) 40%, rgba(44, 55, 63, 0) 85%, rgba(44, 55, 63, 0) 100%);
  }
  .hero-primary.dark-bg .hero-img img,
.hero-primary.dark-bg .hero-img source {
    object-position: 30% 40%;
  }
  .hero-primary .hero-content-container {
    background: transparent;
    display: flex;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .hero-primary .hero-content-container.center {
    justify-content: center;
    text-align: center;
  }
  .hero-primary .hero-content-container.center .global-search-typeahead .search-grid {
    left: 50%;
  }
  .hero-primary .hero-content-container.center .global-search-typeahead .search-dropdown {
    left: 0;
  }
  .hero-primary .hero-content-container.right {
    justify-content: flex-end;
    text-align: right;
  }
  .hero-primary .hero-content-container.right .global-search-typeahead .search-grid {
    position: absolute;
    left: 100%;
  }
  .hero-primary .hero-content-container.right .global-search-typeahead .search-dropdown {
    left: 3rem;
    transform: translateX(-100%);
    background-color: #2c373f;
  }
  .hero-primary .hero-content-container.right .secondary-nav {
    margin-left: auto;
  }
  .hero-primary .hero-content-container.right .form-group {
    margin-left: auto;
  }
  .hero-primary .hero-content-container.right .tertiary-nav .nav-links li.nurse-help-line-md {
    border: 5px solid transparent;
    display: inline-flex;
    margin-bottom: 1rem;
  }
  .hero-primary .hero-content-container.right .tertiary-nav .nav-links li.nurse-help-line-md a {
    margin-left: 0.25rem;
    margin-right: 0;
    order: 2;
  }
  .hero-primary .hero-content-container.right .tertiary-nav .nav-links li.nurse-help-line-md svg {
    margin: 0 4px;
    top: 0;
  }
  .hero-primary .hero-content-container.right .tertiary-nav .nav-links li.nurse-help-line-md:first-child {
    order: 2;
  }
  .hero-primary .hero-content-container.right .tertiary-nav .nav-links li.nurse-help-line-md:last-child {
    order: 1;
  }
  .hero-primary .hero-content-container.right .tertiary-nav .nav-links li.nurse-help-line-xs {
    display: none;
  }
  .hero-primary .hero-content-container.right .tertiary-nav .nav-links li.schedule-online-md {
    display: block;
  }
  .hero-primary .hero-content-container.right .tertiary-nav .nav-links li:last-child {
    display: none;
  }
  .hero-primary .hero-content-container:not(.center):not(.right) .global-search-typeahead .search-dropdown {
    left: -3rem;
    transform: none;
    width: calc(100vw - 100px);
  }
  .hero-primary .hero-img {
    height: 35rem;
  }
  .hero-primary .hero-content {
    flex: 0 0 55%;
    max-width: 55%;
    padding-bottom: 0;
    align-self: center;
  }
  .hero-primary .tertiary-nav {
    padding-right: 0.5rem;
    right: 0;
    bottom: 0;
    position: absolute;
  }
  .hero-primary .tertiary-nav .nav-links {
    columns: auto 2;
    column-gap: 1rem;
  }
  .hero-primary .tertiary-nav .nav-links li.nurse-help-line-md {
    display: inline-flex;
    margin-bottom: 1rem;
  }
  .hero-primary .tertiary-nav .nav-links li.nurse-help-line-md a {
    margin-right: 0.5rem;
  }
  .hero-primary .tertiary-nav .nav-links li.nurse-help-line-md svg {
    margin: 0 4px;
    top: 0;
  }
  .hero-primary .tertiary-nav .nav-links li.nurse-help-line-xs {
    display: none;
  }
  .hero-primary .tertiary-nav .nav-links li.schedule-online-md a {
    color: #2c373f;
  }
  .hero-primary .tertiary-nav .nav-links li.schedule-online-md svg {
    fill: #2c373f;
  }
  .hero-primary .tertiary-nav .nav-links li:last-child {
    display: none;
  }
  .hero-primary .form-group {
    padding-top: 2rem;
  }
  .hero-primary .hero-outer > .form-group label {
    padding-left: 0;
    width: 40%;
  }
  .hero-primary .hero-outer > .form-group .search-dropdown {
    left: 0;
    transform: none;
    width: calc(100vw - 40px);
  }
  .hero-primary .global-search-typeahead .search-grid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    margin: 0;
    width: 100vw;
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .hero-primary .global-search-typeahead .search-grid {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .hero-primary .global-search-typeahead .search-grid {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .hero-primary .global-search-typeahead .search-grid {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .hero-primary .global-search-typeahead .search-grid {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .hero-primary .hero-title {
    font-size: 2.5rem;
    line-height: 1.2;
  }
  .hero-primary .form-control, .hero-primary .share-story-modal form input[type=text], .share-story-modal form .hero-primary input[type=text],
.hero-primary .share-story-modal form input[type=email],
.share-story-modal form .hero-primary input[type=email],
.hero-primary .share-story-modal form input[type=tel],
.share-story-modal form .hero-primary input[type=tel],
.hero-primary .share-story-modal form select,
.share-story-modal form .hero-primary select,
.hero-primary .share-story-modal form textarea,
.share-story-modal form .hero-primary textarea, .hero-primary html .microsite-theme .marketo-form .mktoForm .mktoEmailField, html .microsite-theme .marketo-form .mktoForm .hero-primary .mktoEmailField,
.hero-primary html .microsite-theme .marketo-form .mktoForm .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .hero-primary .mktoTelField,
.hero-primary html .microsite-theme .marketo-form .mktoForm .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .hero-primary .mktoTextField,
.hero-primary html .microsite-theme .marketo-form .mktoForm select,
html .microsite-theme .marketo-form .mktoForm .hero-primary select,
.hero-primary html .microsite-theme .marketo-form .mktoForm textarea,
html .microsite-theme .marketo-form .mktoForm .hero-primary textarea,
.hero-primary html .moments-theme .marketo-form .mktoForm .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .hero-primary .mktoEmailField,
.hero-primary html .moments-theme .marketo-form .mktoForm .mktoTelField,
html .moments-theme .marketo-form .mktoForm .hero-primary .mktoTelField,
.hero-primary html .moments-theme .marketo-form .mktoForm .mktoTextField,
html .moments-theme .marketo-form .mktoForm .hero-primary .mktoTextField,
.hero-primary html .moments-theme .marketo-form .mktoForm select,
html .moments-theme .marketo-form .mktoForm .hero-primary select,
.hero-primary html .moments-theme .marketo-form .mktoForm textarea,
html .moments-theme .marketo-form .mktoForm .hero-primary textarea, .hero-primary .marketo-form .mktoForm select, .marketo-form .mktoForm .hero-primary select,
.hero-primary .marketo-form .mktoForm textarea,
.marketo-form .mktoForm .hero-primary textarea, .hero-primary .marketo-form .mktoForm .mktoEmailField, .marketo-form .mktoForm .hero-primary .mktoEmailField,
.hero-primary .marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .hero-primary .mktoTelField,
.hero-primary .marketo-form .mktoForm .mktoTextField,
.marketo-form .mktoForm .hero-primary .mktoTextField, .hero-primary textarea, .hero-primary .bmi-calculator input[type=text], .bmi-calculator .hero-primary input[type=text],
.hero-primary .bmi-calculator select,
.bmi-calculator .hero-primary select {
    font-size: 1.25rem;
    line-height: 1.2;
  }
  .hero-primary .hero-content-container:not(.center):not(.right) .global-search-typeahead .search-dropdown {
    width: 65%;
  }
  .hero-primary .hero-content-container .global-search-typeahead .dropdown-primary {
    border-radius: 5px 0 0;
  }
  .hero-primary .hero-content-container .global-search-typeahead .dropdown-secondary {
    background-color: #333f48 !important;
    border-radius: 0 5px 0 0;
  }
  .hero-primary .hero-outer > .form-group .search-dropdown {
    width: 65%;
  }
  .hero-primary .hero-content {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .hero-primary .hero-content .form-group {
    max-width: 450px;
  }
  .hero-primary .secondary-nav {
    max-width: 300px;
  }
  .hero-primary .secondary-nav .nav-links {
    list-style: none;
    padding: 1rem 0;
  }
  .hero-primary .secondary-nav .nav-links li {
    font-weight: 700;
  }
  .hero-primary .secondary-nav .nav-links li a.btn, .hero-primary .secondary-nav .nav-links li .marketo-form .mktoForm a.mktoButton, .marketo-form .mktoForm .hero-primary .secondary-nav .nav-links li a.mktoButton {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1200px) {
  .hero-primary .form-control, .hero-primary .share-story-modal form input[type=text], .share-story-modal form .hero-primary input[type=text],
.hero-primary .share-story-modal form input[type=email],
.share-story-modal form .hero-primary input[type=email],
.hero-primary .share-story-modal form input[type=tel],
.share-story-modal form .hero-primary input[type=tel],
.hero-primary .share-story-modal form select,
.share-story-modal form .hero-primary select,
.hero-primary .share-story-modal form textarea,
.share-story-modal form .hero-primary textarea, .hero-primary html .microsite-theme .marketo-form .mktoForm .mktoEmailField, html .microsite-theme .marketo-form .mktoForm .hero-primary .mktoEmailField,
.hero-primary html .microsite-theme .marketo-form .mktoForm .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .hero-primary .mktoTelField,
.hero-primary html .microsite-theme .marketo-form .mktoForm .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .hero-primary .mktoTextField,
.hero-primary html .microsite-theme .marketo-form .mktoForm select,
html .microsite-theme .marketo-form .mktoForm .hero-primary select,
.hero-primary html .microsite-theme .marketo-form .mktoForm textarea,
html .microsite-theme .marketo-form .mktoForm .hero-primary textarea,
.hero-primary html .moments-theme .marketo-form .mktoForm .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .hero-primary .mktoEmailField,
.hero-primary html .moments-theme .marketo-form .mktoForm .mktoTelField,
html .moments-theme .marketo-form .mktoForm .hero-primary .mktoTelField,
.hero-primary html .moments-theme .marketo-form .mktoForm .mktoTextField,
html .moments-theme .marketo-form .mktoForm .hero-primary .mktoTextField,
.hero-primary html .moments-theme .marketo-form .mktoForm select,
html .moments-theme .marketo-form .mktoForm .hero-primary select,
.hero-primary html .moments-theme .marketo-form .mktoForm textarea,
html .moments-theme .marketo-form .mktoForm .hero-primary textarea, .hero-primary .marketo-form .mktoForm select, .marketo-form .mktoForm .hero-primary select,
.hero-primary .marketo-form .mktoForm textarea,
.marketo-form .mktoForm .hero-primary textarea, .hero-primary .marketo-form .mktoForm .mktoEmailField, .marketo-form .mktoForm .hero-primary .mktoEmailField,
.hero-primary .marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .hero-primary .mktoTelField,
.hero-primary .marketo-form .mktoForm .mktoTextField,
.marketo-form .mktoForm .hero-primary .mktoTextField, .hero-primary textarea, .hero-primary .bmi-calculator input[type=text], .bmi-calculator .hero-primary input[type=text],
.hero-primary .bmi-calculator select,
.bmi-calculator .hero-primary select {
    font-size: 1.5rem;
    line-height: 1.25;
  }
  .hero-primary .hero-outer {
    background: linear-gradient(to left, rgb(245, 245, 246) 0%, rgb(245, 245, 246) 50%, rgb(44, 55, 63) 50%, rgb(44, 55, 63) 100%);
  }
  .hero-primary .hero-outer.right {
    background: linear-gradient(to right, rgb(245, 245, 246) 0%, rgb(245, 245, 246) 50%, rgb(44, 55, 63) 50%, rgb(44, 55, 63) 100%);
  }
  .hero-primary .hero-img {
    height: 40rem;
    order: 2;
  }
  .hero-primary .hero-img img,
.hero-primary .hero-img source {
    object-fit: cover;
    object-position: 50% 25%;
    height: inherit;
    max-width: 1920px;
  }
  .hero-primary.dark-bg .hero-img img,
.hero-primary.dark-bg .hero-img source {
    object-position: 10% 25%;
  }
  .hero-primary.dark-bg .hero-content-container {
    background: linear-gradient(to left, rgba(44, 55, 63, 0) 10%, rgba(44, 55, 63, 0) 40%, rgba(44, 55, 63, 0.5) 60%, rgba(44, 55, 63, 0.8) 80%, rgb(44, 55, 63) 100%);
    min-width: 1200px;
    max-width: 1920px !important;
  }
  .hero-primary.dark-bg .hero-content-container .hero-content {
    height: 75%;
    padding-right: 1rem;
    width: 50%;
    max-width: 660px;
    bottom: 0;
    left: auto;
    right: 50%;
    position: absolute;
  }
  .hero-primary.dark-bg .hero-content-container.right {
    background: linear-gradient(to right, rgba(44, 55, 63, 0) 10%, rgba(44, 55, 63, 0) 40%, rgba(44, 55, 63, 0.5) 60%, rgba(44, 55, 63, 0.8) 80%, rgb(44, 55, 63) 100%);
    min-width: 1200px;
    max-width: 1920px !important;
  }
  .hero-primary.dark-bg .hero-content-container.right .hero-content {
    left: 50%;
    right: auto;
  }
  .hero-primary.dark-bg .hero-content-container.right .tertiary-nav {
    padding-right: 0.5rem;
    right: 0;
    bottom: 0;
    position: absolute;
  }
}

.tool {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  position: relative;
}
@media (min-width: 576px) {
  .tool {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .tool {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .tool {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .tool {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.tool.minified {
  background-color: transparent;
}
.tool.minified .tool-inner {
  margin-bottom: 1rem;
  padding-top: 0;
}
.tool.minified .select-custom {
  display: none;
}
.tool.minified .card:not(.contact) .btn-primary, .tool.minified .card:not(.contact) .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .tool.minified .card:not(.contact) .mktoButton {
  display: none;
}
.tool.minified .contact {
  display: block;
}
.tool .tool-inner {
  padding-top: 0;
  text-align: center;
}
@media (min-width: 576px) {
  .tool .tool-inner {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .tool .tool-inner {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .tool .tool-inner {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .tool .tool-inner {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.tool .search-dropdown {
  display: none;
}
.tool .heading-3 {
  font-size: 1rem;
  line-height: 1.25;
  font-weight: 700;
  margin-bottom: 0.75rem;
  text-transform: capitalize;
}
.tool .form-group {
  margin-bottom: 0;
}
.tool .form-group.search-focus .search-dropdown {
  display: block;
}
.tool .form-group,
.tool .form-control,
.tool .share-story-modal form input[type=text],
.share-story-modal form .tool input[type=text],
.tool .share-story-modal form input[type=email],
.share-story-modal form .tool input[type=email],
.tool .share-story-modal form input[type=tel],
.share-story-modal form .tool input[type=tel],
.tool .share-story-modal form select,
.share-story-modal form .tool select,
.tool .share-story-modal form textarea,
.share-story-modal form .tool textarea,
.tool html .microsite-theme .marketo-form .mktoForm .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .tool .mktoEmailField,
.tool html .microsite-theme .marketo-form .mktoForm .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .tool .mktoTelField,
.tool html .microsite-theme .marketo-form .mktoForm .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .tool .mktoTextField,
.tool html .microsite-theme .marketo-form .mktoForm select,
html .microsite-theme .marketo-form .mktoForm .tool select,
.tool html .microsite-theme .marketo-form .mktoForm textarea,
html .microsite-theme .marketo-form .mktoForm .tool textarea,
.tool html .moments-theme .marketo-form .mktoForm .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .tool .mktoEmailField,
.tool html .moments-theme .marketo-form .mktoForm .mktoTelField,
html .moments-theme .marketo-form .mktoForm .tool .mktoTelField,
.tool html .moments-theme .marketo-form .mktoForm .mktoTextField,
html .moments-theme .marketo-form .mktoForm .tool .mktoTextField,
.tool html .moments-theme .marketo-form .mktoForm select,
html .moments-theme .marketo-form .mktoForm .tool select,
.tool html .moments-theme .marketo-form .mktoForm textarea,
html .moments-theme .marketo-form .mktoForm .tool textarea,
.tool .marketo-form .mktoForm select,
.marketo-form .mktoForm .tool select,
.tool .marketo-form .mktoForm textarea,
.marketo-form .mktoForm .tool textarea,
.tool .marketo-form .mktoForm .mktoEmailField,
.marketo-form .mktoForm .tool .mktoEmailField,
.tool .marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .tool .mktoTelField,
.tool .marketo-form .mktoForm .mktoTextField,
.marketo-form .mktoForm .tool .mktoTextField,
.tool textarea,
.tool .bmi-calculator input[type=text],
.bmi-calculator .tool input[type=text],
.tool .bmi-calculator select,
.bmi-calculator .tool select {
  font-size: 1.125rem;
  line-height: 1;
  color: #2c373f;
}
.tool .form-group,
.tool .form-label-group {
  position: relative;
}
.tool .form-group > input,
.tool .form-label-group > input {
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem 1rem 1rem 2.5rem;
}
.tool .form-group > label,
.tool .form-label-group > label {
  background-color: transparent;
  border: none;
  display: block;
  font-weight: 600;
  width: 100%;
  margin-bottom: 0;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  pointer-events: none;
  cursor: text;
  transition: all 0.1s ease-in-out;
}
.tool .form-group input:not(.placeholder-shown) ~ label,
.tool .form-label-group input:not(.placeholder-shown) ~ label {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  padding-top: 0;
  padding-bottom: 0.25rem;
}
.tool .select-custom {
  overflow: hidden;
  position: relative;
  margin-bottom: 0.5rem;
  width: 100%;
}
.tool .select-custom svg {
  pointer-events: none;
}
.tool .select-custom select {
  position: relative;
  appearance: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  display: block;
}
.tool .select-custom-inner::-ms-expand {
  display: none;
}
.tool .select-custom-inner {
  border: none;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  appearance: none;
}
.tool .icon-arrow-down {
  position: absolute;
  right: 0.75rem;
  bottom: 1rem;
  display: block;
  padding: 0;
}
.tool .icon-btn {
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 2.75rem;
  left: 1rem;
  padding: 0;
}
.tool .icon-btn svg {
  height: 1rem;
  width: 1rem;
}
.tool .card-deck {
  background-color: #f5f5f6;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
  padding: 1.5rem;
}
.tool .tool-content {
  padding: 0;
  margin-bottom: 0;
  text-align: left;
}
.tool .tool-content-container {
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: none;
  flex: 0 0 auto;
  height: auto;
  max-width: 100%;
  padding: 0;
  margin: 0 0 1.5rem 0;
}
.tool .tool-content-container:last-child {
  margin-bottom: 0;
}
.tool .tool-content-container.contact .tool-content {
  padding: 0;
  display: block;
}
.tool .tool-content-container.contact .tool-content .btn, .tool .tool-content-container.contact .tool-content .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .tool .tool-content-container.contact .tool-content .mktoButton {
  width: 100%;
  padding: 0.75rem;
}
.tool .tool-content-container.contact .tool-content .btn.btn-tertiary .icon, .tool .tool-content-container.contact .tool-content .marketo-form .mktoForm .btn-tertiary.mktoButton .icon, .marketo-form .mktoForm .tool .tool-content-container.contact .tool-content .btn-tertiary.mktoButton .icon {
  fill: #fff;
  height: 1.25rem;
  width: 1.25rem;
}
.tool .tool-content-container.contact .tool-content .btn.btn-tertiary:hover .icon, .tool .tool-content-container.contact .tool-content .marketo-form .mktoForm .btn-tertiary.mktoButton:hover .icon, .marketo-form .mktoForm .tool .tool-content-container.contact .tool-content .btn-tertiary.mktoButton:hover .icon, .tool .tool-content-container.contact .tool-content .btn.btn-tertiary:focus .icon, .tool .tool-content-container.contact .tool-content .marketo-form .mktoForm .btn-tertiary.mktoButton:focus .icon, .marketo-form .mktoForm .tool .tool-content-container.contact .tool-content .btn-tertiary.mktoButton:focus .icon {
  fill: #2c373f;
}
.tool .tool-content-container.contact .tool-content .btn + .btn, .tool .tool-content-container.contact .tool-content .marketo-form .mktoForm .mktoButton + .btn, .marketo-form .mktoForm .tool .tool-content-container.contact .tool-content .mktoButton + .btn, .tool .tool-content-container.contact .tool-content .marketo-form .mktoForm .btn + .mktoButton, .marketo-form .mktoForm .tool .tool-content-container.contact .tool-content .btn + .mktoButton, .tool .tool-content-container.contact .tool-content .marketo-form .mktoForm .mktoButton + .mktoButton, .marketo-form .mktoForm .tool .tool-content-container.contact .tool-content .mktoButton + .mktoButton {
  margin-top: 1.25rem;
}
@media (min-width: 576px) {
  .tool .card-deck {
    margin-top: 0;
  }
  .tool .tool-content-container {
    background-color: transparent;
    flex: 0 0 auto;
    height: auto;
    max-width: 100%;
    padding: 0;
  }
  .tool .tool-content-container.contact .tool-content {
    display: flex;
    justify-content: space-between;
  }
  .tool .tool-content-container.contact .tool-content .btn, .tool .tool-content-container.contact .tool-content .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .tool .tool-content-container.contact .tool-content .mktoButton {
    width: calc(50% - 0.5rem);
  }
  .tool .tool-content-container.contact .tool-content .btn + .btn, .tool .tool-content-container.contact .tool-content .marketo-form .mktoForm .mktoButton + .btn, .marketo-form .mktoForm .tool .tool-content-container.contact .tool-content .mktoButton + .btn, .tool .tool-content-container.contact .tool-content .marketo-form .mktoForm .btn + .mktoButton, .marketo-form .mktoForm .tool .tool-content-container.contact .tool-content .btn + .mktoButton, .tool .tool-content-container.contact .tool-content .marketo-form .mktoForm .mktoButton + .mktoButton, .marketo-form .mktoForm .tool .tool-content-container.contact .tool-content .mktoButton + .mktoButton {
    margin-top: auto;
  }
}
@media (min-width: 768px) {
  .tool {
    background-color: #f2f9ff;
  }
  .hero-primary + .tool.minified {
    margin-top: -5rem;
  }
  .hero-primary + .tool.minified .tool-inner {
    padding-top: 0;
  }
  .hero-secondary + .tool.minified .tool-content-container:first-child:nth-last-child(4), .hero-secondary + .tool.minified .tool-content-container:first-child:nth-last-child(4) ~ .tool-content-container {
    margin-top: -1rem;
    margin-bottom: 1rem;
    padding: 0;
  }
  .tool:not(.minified) .card-deck {
    margin-top: -7rem;
  }
  .tool.minified .heading-3 {
    margin-bottom: 0.5rem;
  }
  .tool.minified .form-group > input,
.tool.minified .form-group > label {
    display: block;
  }
  .tool .tool-inner {
    padding-top: 1rem;
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .tool .tool-inner {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .tool .tool-inner {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .tool .tool-inner {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .tool .tool-inner {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .two-column .tool .tool-inner {
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  .tool .card-deck {
    flex-flow: row wrap;
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  .tool .tool-content-container {
    justify-content: space-between;
  }
  .tool .tool-content-container:first-child:nth-last-child(3) {
    flex: 0 0 calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
  }
  .tool .tool-content-container:first-child:nth-last-child(1) ~ .contact, .tool .tool-content-container:first-child:nth-last-child(2) ~ .contact {
    flex: 0 0 calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
  }
  .tool .tool-content-container:first-child:nth-last-child(2) ~ .contact {
    margin-bottom: 0;
  }
  .tool .tool-content-container:not(:only-child) {
    flex: 0 0 calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
    margin-bottom: 0;
  }
  .tool .tool-content-container.contact:not(:only-child) {
    padding-top: 1.5rem;
  }
  .tool .tool-content-container:only-child, .tool .tool-content-container.contact {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .two-column .tool.minified .tool-content-container {
    margin: 0 0 1.5rem 0;
  }
}
@media (min-width: 768px) {
  .tool .btn-minified-submit {
    bottom: 0.375rem;
    width: auto;
  }
}
@media (min-width: 768px) {
  .col-md-4 .tool.minified {
    background-color: transparent;
  }
  .col-md-4 .tool.minified .tool-inner {
    margin-bottom: 1rem;
  }
  .col-md-4 .tool.minified .tool-inner .card-deck .tool-content-container:before {
    display: none;
  }
  .col-md-4 .tool.minified .select-custom {
    display: none;
  }
  .col-md-4 .tool.minified .btn-primary, .col-md-4 .tool.minified .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .col-md-4 .tool.minified .mktoButton {
    display: none;
  }
  .col-md-4 .tool.minified .btn-minified-submit {
    display: block;
  }
  .col-md-4 .tool.minified .card-deck {
    margin-top: 0;
  }
  .col-md-4 .tool.minified .contact {
    display: block;
  }
  .col-md-4 .tool.minified .card-deck {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }
  .col-md-4 .tool.minified .tool-content {
    background-color: #fff;
    padding: 1.25rem 1rem;
    text-align: left;
  }
  .col-md-4 .tool.minified .tool-content-container {
    background-color: transparent;
    flex: 0 0 auto !important;
    height: auto;
    max-width: 100% !important;
    padding: 0 !important;
    margin-bottom: 0;
  }
  .col-md-4 .tool.minified input,
.col-md-4 .tool.minified label {
    padding: 1rem 0.5rem 0.5rem;
  }
}
@media (min-width: 992px) {
  .tool .card-deck {
    justify-content: space-between;
  }
  .tool.minified .tool-inner {
    margin-top: -1rem;
  }
  .two-column .tool.minified .tool-content-container {
    justify-content: space-between;
  }
  .two-column .tool.minified .tool-content-container:first-child:nth-last-child(3) {
    flex: 0 0 calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
  }
  .two-column .tool.minified .tool-content-container:first-child:nth-last-child(1) ~ .contact, .two-column .tool.minified .tool-content-container:first-child:nth-last-child(2) ~ .contact {
    flex: 0 0 calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
  }
  .two-column .tool.minified .tool-content-container:first-child:nth-last-child(2) ~ .contact {
    margin-bottom: 0;
  }
  .two-column .tool.minified .tool-content-container:not(:only-child) {
    flex: 0 0 calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
    margin-bottom: 0;
  }
  .two-column .tool.minified .tool-content-container.contact:not(:only-child) {
    padding-top: 1.5rem;
  }
  .two-column .tool.minified .tool-content-container:only-child, .two-column .tool.minified .tool-content-container.contact {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .hero-secondary + .tool.minified .tool-content-container:first-child:nth-last-child(4), .hero-secondary + .tool.minified .tool-content-container:first-child:nth-last-child(4) ~ .tool-content-container {
    margin-top: 0rem;
    margin-bottom: 1rem;
    padding: 0;
  }
}
@media (min-width: 1200px) {
  .tool:not(.minified) .card-deck {
    margin-top: -10rem;
  }
  .two-column .tool.minified .tool-inner .tool-content-container {
    justify-content: space-between;
  }
  .two-column .tool.minified .tool-inner .tool-content-container:first-child:nth-last-child(3) {
    flex: 0 0 calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
  }
  .two-column .tool.minified .tool-inner .tool-content-container:first-child:nth-last-child(1) ~ .contact, .two-column .tool.minified .tool-inner .tool-content-container:first-child:nth-last-child(2) ~ .contact {
    flex: 0 0 calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
  }
  .two-column .tool.minified .tool-inner .tool-content-container:first-child:nth-last-child(2) ~ .contact {
    margin-bottom: 0;
  }
  .two-column .tool.minified .tool-inner .tool-content-container:not(:only-child) {
    flex: 0 0 calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
    margin-bottom: 0;
  }
  .two-column .tool.minified .tool-inner .tool-content-container.contact:not(:only-child) {
    padding-top: 1.5rem;
  }
  .two-column .tool.minified .tool-inner .tool-content-container:only-child, .two-column .tool.minified .tool-inner .tool-content-container.contact {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
  }
  .tool .tool-content-container {
    margin-bottom: 0;
  }
  .tool .tool-content-container:first-child:nth-last-child(3), .tool .tool-content-container:first-child:nth-last-child(3) ~ :not(.contact) {
    flex: 0 0 calc(29.1666666667% - 1rem);
    padding-right: 0;
  }
  .tool .tool-content-container.contact, .tool .tool-content-container:first-child:nth-last-child(2) ~ .contact, .tool .tool-content-container:first-child:nth-last-child(3) ~ .contact {
    flex: 0 0 calc(41.6666666667% - 1rem);
    max-width: calc(41.6666666667% - 1rem);
    padding-top: 1.5rem;
    margin-bottom: 0;
    padding-right: 0;
  }
  .tool .tool-content-container:not(:only-child) {
    flex: 1 0 calc(50% - 0.5rem);
    max-width: 58.3333333333%;
    padding-right: 1rem;
  }
  .tool .tool-content-container:not(:only-child) ~ :not(.contact) {
    flex: 0 0 calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
    padding-right: 0;
  }
  .tool .tool-content-container.contact:only-child {
    padding-top: 0;
  }
  .tool .tool-content-container:only-child {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.blog-article {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .blog-article {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .blog-article {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .blog-article {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .blog-article {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.blog-article .article-top-image {
  height: 250px;
  margin-bottom: 2rem;
}
.blog-article .article-top-image img {
  height: 100%;
  object-fit: cover;
  object-position: center 40%;
  font-family: "object-fit: cover; object-position: center 40%;";
  width: 100%;
}
.blog-article .article-content {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
}
.blog-article .article-content p a, .blog-article .article-content .alt-background a {
  font-weight: 700;
}
.blog-article .article-content .podcast-headshot {
  float: right;
  width: 205px;
  margin-left: 2rem;
  margin-bottom: 1rem;
}
.blog-article .article-content .podcastImageDescription {
  display: flow-root;
}
.blog-article .blog-subscription {
  height: auto;
  margin: 0 0 2rem 0;
  position: relative;
  align-items: center;
  display: flex;
}
.blog-article .blog-subscription .blog-item-content {
  background-color: #fff;
  position: relative;
  padding: 1.25rem;
  margin: auto;
  border-radius: 0;
  width: 100%;
}
.blog-article .blog-subscription .blog-title {
  font-size: 1.75rem;
  line-height: 1.2142857143;
  color: #2c373f;
  font-weight: 700;
}
.blog-article .blog-subscription .divider-blog {
  border-bottom: 2px solid #ffcd55;
  margin: 0.75rem auto 0.5rem 0;
  width: 4rem;
}
.blog-article .blog-subscription .blog-description {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  display: block;
  font-weight: 400;
  margin: 1rem 0;
}
.blog-article .blog-subscription .subscribe-form {
  padding: 0;
}
.blog-article .blog-subscription .subscribe-form form {
  padding-top: 1.5rem;
}
.blog-article .back-btn {
  background-color: #2c373f;
  border-radius: 0;
  display: block;
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
  padding: 1rem 3rem;
  position: relative;
  text-align: left;
  width: 100%;
}
.blog-article .back-btn:hover, .blog-article .back-btn:focus {
  background-color: #a2c3d6;
  border-color: #a2c3d6;
}
.blog-article .back-btn:hover span, .blog-article .back-btn:focus span {
  text-decoration: underline;
}
.blog-article .back-btn .icon-arrow-left,
.blog-article .back-btn .icon-arrow-right {
  align-self: center;
  fill: #fff;
  left: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  .blog-article .article-top-image {
    height: 350px;
  }
  .blog-article .two-column-sidebar .blog-subscription {
    display: block;
  }
  .blog-article .two-column-sidebar .blog-subscription .blog-item-content {
    border: none;
    padding: 0;
  }
  .blog-article .two-column-sidebar .blog-subscription .blog-title {
    font-size: 1.25rem;
    line-height: 1.2;
    width: 100%;
  }
  .blog-article .blog-subscription .blog-title {
    font-size: 1.75rem;
    line-height: 1.2142857143;
    width: 50%;
  }
  .blog-article .back-btn {
    display: none;
  }
}
@media (min-width: 992px) {
  .blog-article .article-top-image {
    height: 360px;
  }
}

.blog-categories {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .blog-categories {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .blog-categories {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .blog-categories {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .blog-categories {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.blog-categories .accordion-container {
  padding: 0;
}
.blog-categories .accordion-container .accordion .card-header {
  background-color: transparent;
  border-radius: 0;
}
.blog-categories .accordion-container .accordion .card-header.active {
  background: linear-gradient(to bottom, transparent 50%, #f2f9ff 50%);
}
.blog-categories .accordion-container .accordion .card-header button {
  color: #fff;
}
.blog-categories .accordion-container .accordion .button-header {
  margin-bottom: 0;
}
.blog-categories .accordion-container .accordion .btn-link {
  font-size: 1rem;
  line-height: 1.125;
  padding: 0.875rem 1rem;
  text-decoration: none;
  background-color: #2c373f;
}
.blog-categories .accordion-container .accordion .btn-link .icon {
  fill: #fff;
  right: 1rem;
}
.blog-categories .category-header {
  display: none;
}
.blog-categories .category-list {
  background-color: #f2f9ff;
  list-style-type: none;
  margin: 0;
  padding: 1rem 1rem 1.5rem;
}
.blog-categories .category-list li {
  text-align: center;
  font-weight: 600;
}
.blog-categories .category-list li:before {
  display: none;
}
.blog-categories .category-list li:not(:last-of-type) {
  margin-bottom: 0.5rem;
}
.blog-categories .category-list li a.active {
  font-weight: 800;
}
.blog-categories .category-list li a:hover {
  text-decoration: none;
}
.blog-categories .category-link {
  font-size: 1rem;
  line-height: 1.125;
  color: #2c373f;
}
@media (min-width: 992px) {
  .hero-article + .blog-categories, .blog-list + .blog-categories {
    margin-top: -1rem;
  }
  .blog-categories .accordion-container .accordion .button-header {
    display: none;
  }
  .blog-categories .accordion-container .accordion .collapse {
    display: block;
  }
  .blog-categories .accordion-container .accordion .card {
    background-color: #2c373f;
    border-radius: 5px;
  }
  .blog-categories .accordion-container .accordion .card-header {
    background-color: #2c373f;
  }
  .blog-categories .accordion-container .accordion .card-header.active {
    background: #2c373f;
  }
  .blog-categories .category-header {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    color: #fff;
    display: block;
    font-weight: 600;
    margin-bottom: 0.25rem;
    padding: 1.25rem 2.5rem 0;
  }
  .blog-categories .category-list {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0.5rem 2.5rem 1.25rem;
    background-color: #2c373f;
  }
  .blog-categories .category-list li {
    position: relative;
    margin-bottom: 0;
  }
  .blog-categories .category-list li:before {
    display: none;
  }
  .blog-categories .category-list li .category-link {
    position: relative;
    color: #fff;
    font-weight: 400;
  }
  .blog-categories .category-list li:not(:last-of-type) {
    margin-right: 1rem;
    margin-bottom: 0;
  }
  .blog-categories .category-list li:not(:last-of-type):after {
    background-color: #ffcd55;
    content: "";
    display: block;
    height: 75%;
    pointer-events: none;
    position: absolute;
    right: -1rem;
    top: 50%;
    transform: translate(50%, -50%);
    width: 1px;
  }
  .blog-categories .category-list li:not(:first-of-type) {
    margin-left: 1rem;
  }
  .blog-categories .category-list li a.active {
    font-weight: 800;
    border-bottom: 2px solid #ffcd55;
    padding-bottom: 2px;
  }
  .blog-categories .category-list li a:hover {
    text-decoration: none;
    border-bottom: 2px solid #ffcd55;
    padding-bottom: 2px;
  }
  .blog-categories .category-link {
    font-size: 1rem;
    line-height: 1.125;
  }
}

.blog-list {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .blog-list {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .blog-list {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .blog-list {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .blog-list {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.blog-list .blog-list-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 1.5rem;
}
.blog-list .blog-list-title {
  font-size: 2rem;
  line-height: 1.1875;
  color: #2c373f;
  font-weight: 700;
  margin: 0;
}
.blog-list .blog-list-title:after {
  background-color: #ffcd55;
  bottom: -10px;
  content: "";
  display: block;
  height: 4px;
  left: 0;
  position: relative;
  width: 4rem;
}
.blog-list .view-all-link {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-weight: 600;
}
.blog-list .view-all-link:hover, .blog-list .view-all-link:focus {
  color: #006D9D;
}
.blog-list .view-all-link .icon {
  top: -1px;
  position: relative;
}
.blog-list .blog-item {
  height: auto;
  margin-bottom: 2rem;
  min-height: 325px;
  position: relative;
}
.blog-list .blog-item.subscription {
  align-items: center;
  display: flex;
}
.blog-list .blog-item.subscription .blog-item-content {
  background-color: #fff;
  position: relative;
  padding: 0;
}
.blog-list .blog-item.subscription .blog-title {
  font-size: 1.75rem;
  line-height: 1.2142857143;
  font-weight: 700;
}
.blog-list .blog-item.subscription .blog-description {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  display: block;
  font-weight: 400;
  margin: 1rem 0 0;
  padding-bottom: 0;
}
.blog-list .blog-item.subscription .blog-description p {
  margin-bottom: 0;
}
.blog-list .blog-item.subscription .subscribe-form {
  padding: 0;
}
.blog-list .blog-item.subscription .subscribe-form form {
  padding-top: 1.5rem;
}
.blog-list .blog-item-content {
  bottom: 0;
  padding: 1.5rem 0 1rem;
  width: 100%;
}
.blog-list .blog-img {
  width: 100%;
}
.blog-list .blog-img img,
.blog-list .blog-img source {
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%;";
  width: 100%;
  max-height: 400px;
}
.blog-list .blog-category {
  font-size: 1rem;
  line-height: 1.125;
  background-color: rgba(242, 249, 255, 0.95);
  color: #006D9D;
  font-weight: 400;
  margin: 0;
  padding: 0.5rem 0.75rem 0;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
}
.blog-list .blog-title {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #2c373f;
  font-weight: 600;
  margin-bottom: 1rem;
}
.blog-list .divider-blog {
  border-bottom: 4px solid #ffcd55;
  margin: 0.75rem auto 0.5rem 0;
  width: 4rem;
  border-top: none;
}
.blog-list .read-more-link {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  padding: 0;
  color: #005557;
  font-weight: 700;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .blog-list .blog-list-header {
    margin: 1rem 0 1.5rem;
  }
  .blog-list .blog-list-title {
    font-size: 1.5rem;
    line-height: 1.25;
  }
  .blog-list .blog-listings {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
  }
  .blog-list .blog-item {
    flex: 0 1 48.5%;
    margin-bottom: 2rem;
    max-width: 48.5%;
    min-height: 425px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
  }
  .blog-list .blog-item.subscription .blog-title {
    font-size: 1.75rem;
    line-height: 1.2142857143;
    width: 65%;
  }
  .blog-list .blog-item.subscription .blog-description {
    width: 65%;
  }
  .blog-list .blog-item-content {
    padding: 1.5rem 0 1.25rem;
  }
  .blog-list .blog-category {
    margin-left: -1rem;
    padding: 0.5rem 1rem 0;
  }
  .blog-list .blog-title {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}
@media (min-width: 992px) {
  .blog-list .blog-listings:after {
    content: " ";
    flex: 0 1 31.5%;
    max-width: 31.5%;
  }
  .blog-list .blog-item {
    flex: 0 1 31.5%;
    max-width: 31.5%;
  }
  .blog-list .blog-item .blog-description {
    font-size: 1rem;
    line-height: 1.125;
    display: block;
    font-weight: 400;
    margin: 0;
    padding-bottom: 1rem;
  }
  .blog-list .blog-item.half {
    flex: 0 1 48.5%;
    max-width: 48.5%;
  }
  .blog-list .blog-item.subscription .blog-title,
.blog-list .blog-item.subscription .blog-description {
    width: 75%;
  }
}
@media (min-width: 1200px) {
  .blog-list .blog-item.subscription .blog-title,
.blog-list .blog-item.subscription .blog-description {
    width: 65%;
  }
}

.callout {
  background-color: #fff;
}
.callout .callout-inner {
  margin-bottom: 1rem;
  padding-top: 1rem;
}
@media (min-width: 576px) {
  .callout .callout-inner {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .callout .callout-inner {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .callout .callout-inner {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .callout .callout-inner {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.callout .callout-call-button {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  padding: 0.75rem 3rem 2rem;
}
.callout .callout-call-button .btn, .callout .callout-call-button .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .callout .callout-call-button .mktoButton {
  font-size: 1.5rem;
  line-height: 1.25;
  color: #212529;
  background-color: #fff;
  border-color: #a2c3d6;
  border-radius: 10px;
  border-width: 6px;
  color: #2c373f;
  font-weight: 600;
  padding: 0.625rem 1.75rem;
}
.callout .callout-call-button .btn:hover, .callout .callout-call-button .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .callout .callout-call-button .mktoButton:hover {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.6);
  border-color: #a2c3d6;
}
.callout .callout-call-button .btn:focus, .callout .callout-call-button .marketo-form .mktoForm .mktoButton:focus, .marketo-form .mktoForm .callout .callout-call-button .mktoButton:focus, .callout .callout-call-button .btn.focus, .callout .callout-call-button .marketo-form .mktoForm .focus.mktoButton, .marketo-form .mktoForm .callout .callout-call-button .focus.mktoButton {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.6);
  border-color: #a2c3d6;
  box-shadow: 0 0 0 0.2rem rgba(143, 171, 188, 0.5);
}
.callout .callout-call-button .btn.disabled, .callout .callout-call-button .marketo-form .mktoForm .disabled.mktoButton, .marketo-form .mktoForm .callout .callout-call-button .disabled.mktoButton, .callout .callout-call-button .btn:disabled, .callout .callout-call-button .marketo-form .mktoForm .mktoButton:disabled, .marketo-form .mktoForm .callout .callout-call-button .mktoButton:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #a2c3d6;
}
.callout .callout-call-button .btn:not(:disabled):not(.disabled):active, .callout .callout-call-button .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .callout .callout-call-button .mktoButton:not(:disabled):not(.disabled):active, .callout .callout-call-button .btn:not(:disabled):not(.disabled).active, .callout .callout-call-button .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .callout .callout-call-button .mktoButton:not(:disabled):not(.disabled).active, .show > .callout .callout-call-button .btn.dropdown-toggle, .show > .callout .callout-call-button .marketo-form .mktoForm .dropdown-toggle.mktoButton, .marketo-form .mktoForm .show > .callout .callout-call-button .dropdown-toggle.mktoButton {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #76a6c2;
}
.callout .callout-call-button .btn:not(:disabled):not(.disabled):active:focus, .callout .callout-call-button .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .callout .callout-call-button .mktoButton:not(:disabled):not(.disabled):active:focus, .callout .callout-call-button .btn:not(:disabled):not(.disabled).active:focus, .callout .callout-call-button .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .callout .callout-call-button .mktoButton:not(:disabled):not(.disabled).active:focus, .show > .callout .callout-call-button .btn.dropdown-toggle:focus, .show > .callout .callout-call-button .marketo-form .mktoForm .dropdown-toggle.mktoButton:focus, .marketo-form .mktoForm .show > .callout .callout-call-button .dropdown-toggle.mktoButton:focus {
  box-shadow: 0 0 0 0.2rem rgba(143, 171, 188, 0.5);
}
.callout .callout-call-button .icon {
  height: 60px;
  margin-bottom: 1rem;
  min-width: 60px;
  width: 60px;
}
.callout .heading-4 {
  font-size: 1.5rem;
  line-height: 1;
  color: #2c373f;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.callout .divider-header {
  margin-bottom: 1.5rem;
}
.callout p {
  font-size: 1rem;
  line-height: 1.25;
  color: #3d5d6b;
  margin-bottom: 1.875rem;
}
.callout .btn, .callout .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .callout .mktoButton {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
}
.callout .card-deck {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}
.callout .callout-img {
  padding: 2.5em 2rem 1rem;
}
.callout .callout-img img {
  height: 90px;
}
.callout .callout-content {
  padding: 2rem 2rem 0;
}
.callout .callout-cta {
  background-color: transparent;
  border-top: none;
  padding: 0 2rem 2.5rem;
}
.callout .callout-content-container {
  background-color: #f5f5f6;
  border: 0;
  border-radius: 5px;
  flex-basis: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 1rem;
  margin-left: 15px;
  margin-right: 15px;
  padding: 0;
}
.callout .callout-content-container:last-child {
  border-bottom: 0;
}
@media (min-width: 576px) {
  .callout .card-deck {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .callout .callout-call-button {
    flex-direction: row;
    padding: 0.75rem 0 0;
    margin-bottom: 2.25rem;
  }
  .callout .callout-call-button .btn, .callout .callout-call-button .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .callout .callout-call-button .mktoButton {
    padding: 0.625rem 2rem;
  }
  .callout .callout-call-button .icon {
    margin-bottom: 0;
    margin-left: 1rem;
  }
  .callout .heading-3 {
    font-size: 1.75rem;
    line-height: 1.2142857143;
    padding-top: 2rem;
  }
  .callout .callout-inner {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 1rem;
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .callout .callout-inner {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .callout .callout-inner {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .callout .callout-inner {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .callout .callout-inner {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .callout .card-deck {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 1rem;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 768px) {
  .callout .callout-content {
    padding: 1.75rem 2rem 1rem;
  }
}
@media (min-width: 768px) {
  .callout .callout-content-container {
    border: 0;
    padding: 0;
    margin-bottom: 2rem;
    margin-left: 0;
    margin-right: 0;
  }
  .callout .callout-content-container:first-child:nth-last-child(2), .callout .callout-content-container:first-child:nth-last-child(2) ~ .callout-content-container, .callout .callout-content-container:first-child:nth-last-child(4), .callout .callout-content-container:first-child:nth-last-child(4) ~ .callout-content-container {
    flex-basis: calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
  .callout .callout-content-container:first-child:nth-last-child(3), .callout .callout-content-container:first-child:nth-last-child(3) ~ .callout-content-container, .callout .callout-content-container:first-child:nth-last-child(5), .callout .callout-content-container:first-child:nth-last-child(5) ~ .callout-content-container, .callout .callout-content-container:first-child:nth-last-child(6), .callout .callout-content-container:first-child:nth-last-child(6) ~ .callout-content-container {
    flex-basis: calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
}
@media (min-width: 992px) {
  .callout .callout-call-button .btn, .callout .callout-call-button .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .callout .callout-call-button .mktoButton {
    padding: 0.625rem 3rem;
  }
  .callout .btn, .callout .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .callout .mktoButton {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  .callout .callout-content-container:first-child:nth-last-child(2), .callout .callout-content-container:first-child:nth-last-child(2) ~ .callout-content-container, .callout .callout-content-container:first-child:nth-last-child(4), .callout .callout-content-container:first-child:nth-last-child(4) ~ .callout-content-container {
    flex-basis: calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
  .callout .callout-content-container:first-child:nth-last-child(3), .callout .callout-content-container:first-child:nth-last-child(3) ~ .callout-content-container, .callout .callout-content-container:first-child:nth-last-child(5), .callout .callout-content-container:first-child:nth-last-child(5) ~ .callout-content-container, .callout .callout-content-container:first-child:nth-last-child(6), .callout .callout-content-container:first-child:nth-last-child(6) ~ .callout-content-container {
    flex-basis: calc(33.3333333333% - 10px);
    max-width: calc(33.3333333333% - 10px);
  }
  .callout .callout-content-container:first-child:nth-last-child(5):nth-last-child(2), .callout .callout-content-container:first-child:nth-last-child(5) ~ .callout-content-container:nth-last-child(2) {
    margin-left: auto;
    margin-right: 0.5rem;
  }
  .callout .callout-content-container:first-child:nth-last-child(5):nth-last-child(1), .callout .callout-content-container:first-child:nth-last-child(5) ~ .callout-content-container:nth-last-child(1) {
    margin-left: 0.5rem;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .callout .callout-img {
    padding: 2.5rem 3rem 1rem;
  }
  .callout .callout-content {
    padding: 1.75rem 3rem 1rem;
  }
  .callout .callout-cta {
    padding: 0 3rem 2.5rem;
  }
  .callout .callout-content-container:first-child:nth-last-child(4), .callout .callout-content-container:first-child:nth-last-child(4) ~ .callout-content-container {
    flex-basis: calc(25% - 10px);
    max-width: calc(25% - 10px);
  }
}

.classes-and-events {
  background-color: #f2f9ff;
  margin-bottom: 2rem;
  padding: 2rem !important;
}
.classes-and-events .classes-and-events-subtitle {
  font-size: 1rem;
  line-height: 1.125;
  color: #3d5d6b;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.classes-and-events .classes-and-events-title {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #2c373f;
  font-weight: 700;
  margin-bottom: 1.25rem;
}
.classes-and-events .classes-and-events-title + .classes-and-events-description {
  margin-top: -0.5rem;
}
.classes-and-events .classes-and-events-description {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #2c373f;
  font-weight: 400;
  margin-bottom: 1.25rem;
}
.classes-and-events .classes-and-events-item {
  margin-bottom: 1.5rem;
}
.classes-and-events .classes-and-events-item:last-of-type {
  margin-bottom: 0;
}
.classes-and-events .item-header {
  display: flex;
}
.classes-and-events .svg-container {
  width: 35px;
  flex-basis: 35px;
  margin-right: 0.75rem;
}
.classes-and-events .svg-container svg {
  fill: #a2c3d6;
  height: 35px;
  width: 35px;
}
.classes-and-events .item-date-time {
  display: flex;
  flex-wrap: wrap;
}
.classes-and-events .date,
.classes-and-events .time {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #006D9D;
  font-weight: 400;
  position: relative;
}
.classes-and-events .date:not(:last-of-type),
.classes-and-events .time:not(:last-of-type) {
  margin-right: 0.625rem;
  padding-right: 0.625rem;
}
.classes-and-events .date:not(:last-of-type):after,
.classes-and-events .time:not(:last-of-type):after {
  content: "|";
  display: inline-block;
  right: 0;
  padding-right: 0;
  position: absolute;
  transform: translate(50%, 0px);
}
.classes-and-events .location {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #006D9D;
  font-weight: 700;
}
.classes-and-events .item-description {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #2c373f;
  font-weight: 400;
  margin: 0.5rem 0 0;
}
.classes-and-events .view-all-link {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #006D9D;
  display: inline-block;
  font-weight: 700;
  padding: 0;
}
@media (min-width: 768px) {
  .classes-and-events {
    max-width: 410px;
  }
  .classes-and-events.left {
    float: left;
    margin-right: 2rem;
  }
  .classes-and-events.right {
    float: right;
    margin-left: 2rem;
  }
}

.content-grid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .content-grid {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .content-grid {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .content-grid {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .content-grid {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.content-grid .col {
  flex-basis: 100%;
  margin-bottom: 1.25rem;
}
@media (min-width: 768px) {
  .content-grid .row .col:nth-child(-n+2):not(:nth-last-child(3)) {
    flex-basis: 0;
  }
}
@media (min-width: 992px) {
  .content-grid .row .col:nth-child(-n+3):not(:nth-last-child(2)) {
    flex-basis: 0;
  }
}

.csv-table {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .csv-table {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .csv-table {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .csv-table {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .csv-table {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.csv-table .table-container {
  margin-bottom: 2rem;
}
.csv-table .table thead tr {
  height: 72px;
}
.csv-table .table .csv-data.is-hidden {
  display: none;
}
.csv-table .table th:first-child {
  min-width: 50%;
  max-width: 75%;
  vertical-align: bottom;
}
.csv-table .table th:nth-child(n+2) {
  width: 25%;
  vertical-align: bottom;
}
.csv-table .table td {
  vertical-align: top;
  word-break: break-word;
}

.cta-cards {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .cta-cards {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .cta-cards {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .cta-cards {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .cta-cards {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.cta-cards .cta-cards-inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;
}
.cta-cards .col {
  flex-basis: 100%;
  padding: 0;
  margin-bottom: 1.5rem;
}
.cta-cards .card {
  background-color: #f5f5f6;
  border: 0;
  border-radius: 5px;
}
.cta-cards .card-footer {
  background-color: transparent;
  border-top: none;
}
.cta-cards .card-text {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #2c373f;
}
.two-column .cta-cards {
  padding: 0;
}
@media (min-width: 768px) {
  .cta-cards .cta-cards-inner {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .cta-cards .cta-cards-inner .col {
    flex-basis: calc(50% - 15px);
    max-width: calc(50% - 15px);
    display: flex;
    flex-direction: column;
  }
  .cta-cards .cta-cards-inner .col .card {
    height: 100%;
  }
  .cta-cards .cta-cards-inner .col .card-text {
    display: block;
  }
  .two-column .cta-cards .col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .cta-cards .cta-cards-inner:after {
    content: " ";
    flex: calc(33.3333333333% - 20px);
    max-width: calc(33.3333333333% - 20px);
    width: 100%;
  }
  .cta-cards .cta-cards-inner .col {
    flex: calc(33.3333333333% - 20px);
    max-width: calc(33.3333333333% - 20px);
  }
  .cta-cards .cta-cards-inner .col:first-child:nth-last-child(2), .cta-cards .cta-cards-inner .col:first-child:nth-last-child(2) ~ .col {
    flex-basis: calc(50% - 15px);
    max-width: calc(50% - 15px);
  }
  .two-column .cta-cards .col {
    flex-basis: calc(50% - 15px);
    max-width: calc(50% - 15px);
  }
}
@media (min-width: 1200px) {
  .two-column .cta-cards .col {
    flex: calc(33.3333333333% - 20px);
    max-width: calc(33.3333333333% - 20px);
  }
}

.cta-image-card {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-top: 2rem;
  padding-right: 0;
}
@media (min-width: 576px) {
  .cta-image-card {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .cta-image-card {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .cta-image-card {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .cta-image-card {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.two-column-sidebar .cta-image-card {
  display: block;
}
.two-column-main .cta-image-card {
  display: none;
}
.cta-image-card .card {
  margin-bottom: 2rem;
}
.cta-image-card .cta-card-img img,
.cta-image-card .cta-card-img source {
  border-radius: 5px 5px 0 0;
  object-fit: cover;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%;";
  width: 100%;
}
.cta-image-card .section-title {
  text-align: center;
  margin-bottom: 1.25rem;
}
.cta-image-card .section-title:after {
  background-color: #ffcd55;
}
.cta-image-card .card-body {
  text-align: center;
  border: 0.5rem solid #cbced1;
  border-top: 0;
}
.cta-image-card .divider {
  margin: 1rem auto;
}
.cta-image-card .card-text {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
.cta-image-card .btn, .cta-image-card .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .cta-image-card .mktoButton {
  padding: 0.4375rem 2.25rem;
  width: 100%;
}
@media (min-width: 576px) {
  .cta-image-card .btn, .cta-image-card .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .cta-image-card .mktoButton {
    width: auto;
  }
}
@media (min-width: 768px) {
  .two-column-sidebar .cta-image-card {
    display: block;
  }
  .two-column-main .cta-image-card {
    display: none;
  }
  .cta-image-card .card {
    margin-bottom: 2rem;
  }
  .cta-image-card .section-title {
    text-align: center;
  }
  .cta-image-card .section-title:after {
    left: calc(50% - 29px);
  }
  .cta-image-card .card-body {
    text-align: center;
    order: 2;
  }
  .cta-image-card .cta-card-img {
    order: 1;
    margin-bottom: auto;
    padding: 0;
  }
  .cta-image-card .btn, .cta-image-card .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .cta-image-card .mktoButton {
    width: 100%;
  }
}

.error-page {
  margin-top: 4rem;
}
.error-page .error-page-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 4rem;
  text-align: center;
}
@media (min-width: 576px) {
  .error-page .error-page-container {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .error-page .error-page-container {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .error-page .error-page-container {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .error-page .error-page-container {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.error-page .error-title {
  background: #006D9D;
  border-radius: 0.75rem;
  color: #fff;
  display: inline-block;
  font-weight: 700;
  margin: 0 auto 2.75rem;
  padding: 1.25rem;
  position: relative;
  width: 100%;
}
.error-page .error-title .heading-3 {
  margin: 0;
}
.error-page .error-title:after {
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #006D9D;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.error-page .error-content .heading-3 {
  font-size: 1.25rem;
  line-height: 1.5;
  color: #006D9D;
  margin-bottom: 2.5rem;
}
.error-page .error-content p {
  font-size: 1.25rem;
  line-height: 1.5;
  color: #006D9D;
  margin-bottom: 2.5rem;
}
.error-page .error-content p.error-instructions {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  margin-bottom: 1.5rem;
  margin-top: 4rem;
}
.error-page .error-content p a {
  font-weight: 600;
  text-decoration: underline;
}
.error-page .error-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.error-page .error-links li {
  margin-bottom: 0.75rem;
}
.error-page .error-links li:last-of-type {
  margin-bottom: 0;
}
.error-page .error-links a {
  font-weight: 600;
  text-decoration: underline;
}
.error-page .global-search-typeahead .search-form {
  position: relative;
}
.error-page .global-search-typeahead .search-form input::placeholder {
  color: #006D9D;
}
.error-page .global-search-typeahead .search-form .submit-search {
  background: none;
  border: none;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.error-page .global-search-typeahead .search-form .submit-search:hover .icon-search, .error-page .global-search-typeahead .search-form .submit-search:focus .icon-search {
  stroke: #006D9D;
}
@media (min-width: 768px) {
  .error-page {
    margin-top: 7rem;
  }
  .error-page .error-title {
    padding: 1.25rem 2.5rem;
    width: auto;
  }
  .error-page .error-content .heading-3 {
    font-size: 1.75rem;
    line-height: 1.3571428571;
  }
  .error-page .error-content p,
.error-page .error-content .global-search-typeahead {
    margin-left: 6rem;
    margin-right: 6rem;
  }
}
@media (min-width: 992px) {
  .error-page .error-content {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
    margin: auto;
  }
  .error-page .error-links {
    list-style-type: none;
  }
  .error-page .error-links li {
    display: inline-block;
    margin-left: 1.75rem;
  }
  .error-page .global-search-typeahead .search-grid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    left: 50%;
    margin: 0;
    position: relative;
    width: 100vw;
  }
}
@media (min-width: 992px) and (min-width: 576px) {
  .error-page .global-search-typeahead .search-grid {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) and (min-width: 768px) {
  .error-page .global-search-typeahead .search-grid {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .error-page .global-search-typeahead .search-grid {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  .error-page .global-search-typeahead .search-grid {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .error-page .global-search-typeahead .search-grid .search-dropdown {
    left: 0;
  }
}

.event-detail {
  margin-bottom: 2rem;
}
.event-detail .event-card {
  border-width: 0.5rem;
  border-radius: 5px;
  background-color: #f5f5f6;
}
.event-detail .event-card a {
  font-weight: 600;
}
.event-detail .card-header,
.event-detail .card-body,
.event-detail .card-footer {
  background-color: transparent;
  border: 0;
}
.event-detail .card-header {
  height: 175px;
  padding: 0;
  position: relative;
}
.event-detail .card-body .body-half {
  margin-bottom: 1.375rem;
}
.event-detail .card-body .body-half:last-of-type {
  margin-bottom: 0;
}
.event-detail .mapboxgl-popup {
  display: none;
}
.event-detail .event-location-map {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.event-detail .event-location-map .mapbox-map {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.event-detail .event-location-map .mapboxgl-canvas-container {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.event-detail .event-location-map .mapboxgl-marker {
  display: flex;
  justify-content: center;
  width: 32px;
}
.event-detail .event-info {
  font-size: 1rem;
  line-height: 1.25;
  margin-bottom: 1.375rem;
}
.event-detail .event-info p {
  font-size: 1rem;
  line-height: 1.25;
}
.event-detail .event-info .btn, .event-detail .event-info .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .event-detail .event-info .mktoButton {
  padding: 0.75rem 1.5rem;
}
.event-detail .event-info .btn.disabled, .event-detail .event-info .marketo-form .mktoForm .disabled.mktoButton, .marketo-form .mktoForm .event-detail .event-info .disabled.mktoButton {
  background-color: #cbced1;
  border-color: #cbced1;
  color: #2c373f;
  opacity: 1;
  width: auto;
}
.event-detail .event-info .location-name {
  color: #2c373f;
}
.event-detail .event-info .distance-and-directions {
  display: none;
}
.event-detail .event-info .phone-number {
  color: #006D9D;
}
.event-detail .event-info:last-of-type {
  margin-bottom: 0;
}
.event-detail .event-heading {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #2c373f;
}
.event-detail .date,
.event-detail .time {
  color: #2c373f;
}
.event-detail .location-address {
  margin-bottom: 1rem;
}
.event-detail .distance-and-directions {
  color: #006D9D;
  margin-bottom: 2rem;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;
}
.event-detail .distance-and-directions a {
  display: inline-block;
}
.event-detail .distance-and-directions .distance {
  color: #006D9D;
  font-weight: 700;
  padding-right: 0.5rem;
}
@media (min-width: 768px) {
  .event-detail {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .event-detail {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .event-detail {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .event-detail {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .event-detail {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .event-detail .event-card {
    border-width: 0.75rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    min-height: 325px;
  }
}
@media (min-width: 768px) {
  .event-detail .card-header {
    flex-basis: 45%;
    height: auto;
    max-width: 45%;
  }
}
@media (min-width: 768px) {
  .event-detail .card-body {
    display: flex;
    flex-basis: 55%;
    max-width: 55%;
    padding: 2.5rem 2.25rem;
  }
  .event-detail .card-body .body-half {
    flex: 1 1 0;
    margin: 0 1rem;
  }
  .event-detail .card-body .body-half:first-of-type {
    margin-left: 0;
  }
  .event-detail .card-body .body-half:last-of-type {
    margin-bottom: 0;
    margin-right: 0;
  }
}
@media (min-width: 768px) {
  .event-detail .distance-and-directions {
    display: none;
  }
}
@media (min-width: 768px) {
  .event-detail .mapboxgl-popup {
    display: block;
    top: 50px;
  }
  .event-detail .mapboxgl-popup .mapboxgl-popup-tip {
    border: none;
  }
}
@media (min-width: 768px) {
  .event-detail .location-popup {
    padding: 2rem 2.5rem;
  }
  .event-detail .location-popup .distance-and-directions {
    display: block;
  }
  .event-detail .location-popup .location-type {
    font-size: 0.875rem;
    line-height: 1.1428571429;
  }
  .event-detail .location-popup .event-heading {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    margin-bottom: 0;
  }
  .event-detail .location-popup .location-type-name {
    padding-bottom: 0.75rem;
    margin-bottom: 0.75rem;
    text-align: center;
  }
  .event-detail .location-popup .location-distance-parking {
    font-size: 1rem;
    line-height: 1.125;
    display: flex;
    justify-content: center;
  }
  .event-detail .location-popup .location-distance-parking a {
    color: #006D9D;
  }
  .event-detail .location-popup .location-distance-parking a:focus-visible {
    outline: none;
  }
  .event-detail .location-popup .location-distance-parking > :not(:last-child) {
    margin-right: 1.5rem;
    position: relative;
  }
  .event-detail .location-popup .location-distance-parking > :not(:last-child):after {
    background-color: #3d5d6b;
    content: "";
    height: 80%;
    position: absolute;
    right: -0.75rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
  }
}
@media (min-width: 768px) {
  .event-detail .event-location-map .mapboxgl-marker:hover svg, .event-detail .event-location-map .mapboxgl-marker.active svg {
    cursor: pointer;
    fill: #006D9D;
  }
}
@media (min-width: 768px) {
  .event-detail .event-info {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    margin-bottom: 1.5rem;
  }
  .event-detail .event-info p {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
  .event-detail .event-info .distance-and-directions {
    display: block;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .event-detail .event-heading {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}

.event-list {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .event-list {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .event-list {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .event-list {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .event-list {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.event-list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.event-list .event-list-header {
  display: none;
}
.event-list .event-listing {
  display: none;
  padding: 2rem 0;
}
.event-list .event-listing:before {
  display: none;
}
.event-list .event-listing.active {
  display: block;
}
.event-list .event-listing:not(:last-of-type) {
  margin-bottom: 0;
}
.event-list .event-date-time {
  font-size: 1rem;
  line-height: 1.125;
  color: #2c373f;
  display: none;
  margin-bottom: 0.75rem;
}
.event-list .event-date-time > :not(:last-child) {
  margin-right: 1rem;
  position: relative;
}
.event-list .event-date-time > :not(:last-child):after {
  background-color: #2c373f;
  content: "";
  height: 100%;
  position: absolute;
  right: -0.5rem;
  width: 1px;
}
.event-list .event-location {
  font-size: 1rem;
  line-height: 1.125;
  margin-bottom: 1.25rem;
}
.event-list .event-location .name {
  margin-bottom: 0.125rem;
  font-weight: 700;
}
.event-list .event-description {
  margin-bottom: 1.75rem;
}
.event-list .event-description .event-title {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #006D9D;
  margin-bottom: 0.5rem;
}
.event-list .event-description .event-title a {
  color: #006D9D;
}
.event-list .event-description .event-date-time {
  display: flex;
}
.event-list .event-description p {
  font-size: 1rem;
  line-height: 1.125;
}
.event-list .event-description p:last-of-type {
  margin-bottom: 0;
}
.event-list .event-space .btn, .event-list .event-space .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .event-list .event-space .mktoButton {
  padding: 0.75rem 1.5rem;
}
.event-list .pagination-controls .pagination-counts {
  justify-content: center;
}
@media (min-width: 768px) {
  .event-list .event-list-header {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    border-bottom: 1px solid #cbced1;
    display: flex;
    justify-content: space-between;
    padding: 1.25rem 0;
  }
  .event-list .event-list-header span {
    color: #3d5d6b;
  }
  .event-list .event-listing {
    border-bottom: 1px solid #cbced1;
    justify-content: space-between;
    padding: 1.25rem 0;
  }
  .event-list .event-listing.active {
    display: flex;
  }
  .event-list .event-date-time {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    display: block;
    flex-basis: 100px;
    margin: 0 1rem 0 0;
    max-width: 100px;
    order: 1;
  }
  .event-list .event-date-time > :not(:last-child) {
    margin: 0 0 0.125rem;
  }
  .event-list .event-date-time > :not(:last-child):after {
    display: none;
  }
  .event-list .event-space {
    flex-basis: 100px;
    margin-left: 1rem;
    max-width: 100px;
    order: 4;
  }
  .event-list .event-space .btn, .event-list .event-space .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .event-list .event-space .mktoButton {
    width: 100%;
  }
  .event-list .event-location {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    flex: 1 1 0;
    margin-bottom: 0;
    order: 3;
    padding: 0 1rem;
  }
  .event-list .event-description {
    flex: 1.4 1 0;
    margin-bottom: 0;
    order: 2;
    padding: 0 1rem;
  }
  .event-list .event-description .event-title {
    font-size: 1.25rem;
    line-height: 1.2;
  }
  .event-list .event-description .event-date-time {
    display: none;
  }
  .event-list .event-description p {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
  .event-list .space-label {
    display: none;
  }
}

.featured-list-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background-color: #f2f9ff;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .featured-list-container {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .featured-list-container {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .featured-list-container {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .featured-list-container {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.two-column .featured-list-container {
  margin-left: -15px;
  width: calc(100% + 30px);
}
.featured-list-container .section-title {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-weight: 800;
  color: #2c373f;
  margin-bottom: 1.25rem;
  padding-top: 2rem;
}
.featured-list-container .view-all-top-left {
  font-size: 1rem;
  line-height: 1.125;
  color: #2c373f;
  font-weight: 400;
}
.featured-list-container .view-all-top-left {
  display: none;
  float: right;
}
.featured-list-container .btn-container {
  padding-bottom: 2rem;
  text-align: center;
  width: 100%;
}
.featured-list-container .view-all-bottom {
  display: inline-block;
}
.featured-list-container .featured-list {
  color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}
.featured-list-container .featured-list-item {
  flex: 100%;
  max-width: 100%;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 0;
  margin-bottom: 1rem;
}
.featured-list-container .featured-DoL {
  border: none;
  border-radius: 0;
  display: flex;
  flex-direction: column;
}
.featured-list-container .featured-DoL .card-header {
  background-color: transparent;
  border-bottom: none;
  padding-top: 0;
  padding-bottom: 0.5rem;
  text-align: left;
  display: flex;
  align-items: center;
  width: 100%;
  flex-flow: row;
  gap: 1rem;
}
.featured-list-container .featured-DoL .profile-image {
  height: 130px;
  min-width: 130px;
  width: 130px;
}
.featured-list-container .featured-DoL .doctor-name,
.featured-list-container .featured-DoL .location-name {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #2c373f;
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.featured-list-container .featured-DoL .doctor-name:last-child,
.featured-list-container .featured-DoL .location-name:last-child {
  margin-bottom: 0;
}
.featured-list-container .featured-DoL .specialty,
.featured-list-container .featured-DoL .address {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  color: #2c373f;
  display: block;
  font-weight: 400;
  margin-bottom: 0.25rem;
}
.featured-list-container .featured-DoL .specialty:last-child,
.featured-list-container .featured-DoL .address:last-child {
  margin-bottom: 0;
}
@media (min-width: 576px) {
  .featured-list-container .featured-list-item {
    flex: 50%;
    max-width: 50%;
  }
}
@media (min-width: 768px) {
  .featured-list-container {
    background-color: transparent;
  }
  .two-column .featured-list-container {
    margin-left: 0;
    width: 100%;
  }
  .two-column .featured-list-container .featured-list {
    flex-direction: row;
  }
  .two-column .featured-list-container .featured-list .featured-list-item {
    flex: 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    width: 50%;
  }
  .two-column .featured-list-container .featured-list .featured-list-item:last-child {
    padding-bottom: 0;
  }
  .featured-list-container .featured-list-inner {
    background-color: #f2f9ff;
    border-radius: 5px;
  }
  .featured-list-container .section-title {
    text-align: center;
  }
  .featured-list-container .section-title::after {
    left: calc(50% - 29px);
  }
  .featured-list-container .featured-list.two-col-layout {
    flex-direction: row;
  }
  .featured-list-container .featured-list.two-col-layout .featured-list-item {
    flex: 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    width: 50%;
  }
  .featured-list-container .featured-list.two-col-layout .featured-list-item:last-child {
    padding-bottom: 0;
  }
  .featured-list-container .featured-list .featured-list-item {
    flex: 33%;
    max-width: 33%;
    flex-direction: row;
  }
  .two-col-layout .featured-list-container {
    height: auto;
    justify-items: flex-start;
    flex-direction: row;
    margin-left: calc(
	(130px / 2) - 0.25rem
);
    margin-top: 0;
  }
  .two-col-layout .featured-list-container .card-header,
.two-col-layout .featured-list-container .card-footer {
    padding-top: 2rem;
    padding-bottom: 1.5rem;
  }
  .two-col-layout .featured-list-container .card-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-left: 0;
    text-align: left;
  }
  .two-col-layout .featured-list-container .card-header .appointment-type-label {
    display: none;
  }
  .two-col-layout .featured-list-container .profile-image {
    margin-top: 0;
    margin-left: calc((130px) / -2);
    margin-right: 1rem;
  }
}
@media (min-width: 992px) {
  .featured-list-container .section-title {
    display: inline-block;
    padding-left: 1.25rem;
    margin-bottom: 1rem;
    text-align: left;
  }
  .featured-list-container .section-title::after {
    left: 1.325rem;
  }
  .featured-list-container .view-all-top-left {
    display: block;
    float: right;
    margin-top: 1.25rem;
  }
  .featured-list-container .view-all-bottom {
    display: none;
  }
  .featured-list-container .featured-DoL {
    margin-top: 0;
  }
  .featured-list-container .featured-DoL .card-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    text-align: left;
  }
  .featured-list-container .featured-DoL .profile-image {
    margin: 0 1rem 1rem 0;
  }
  .featured-list-container .featured-DoL .doctor-name,
.featured-list-container .featured-DoL .location-name {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
  .featured-list-container .featured-DoL .specialty,
.featured-list-container .featured-DoL .address {
    font-size: 1rem;
    line-height: 1.25;
  }
}
@media (min-width: 1200px) {
  .two-column .featured-list-container .featured-list .featured-list-item {
    flex: 33%;
    max-width: 33%;
    flex-direction: row;
    padding-bottom: 1rem;
  }
  .featured-list-container .featured-list.two-col-layout .featured-list-item {
    flex: 33%;
    max-width: 33%;
    flex-direction: row;
    padding-bottom: 1rem;
  }
  .featured-list-container .featured-list .featured-list-item {
    flex: 0 0 33.3333333333;
    max-width: 33.3333333333;
  }
}

.gallery {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .gallery {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .gallery {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .gallery {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .gallery {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.gallery .modal {
  padding-right: 0 !important;
}
.gallery .carousel {
  margin-bottom: 1rem;
  width: 100%;
}
.gallery .carousel-inner {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: calc(100% - 4rem);
}
.gallery .carousel-item img,
.gallery .carousel-item .video {
  margin-bottom: 2rem;
}
.gallery .carousel-caption {
  color: #2c373f;
  position: relative;
  right: auto;
  bottom: auto;
  left: auto;
  margin-top: -2rem;
  padding-top: 0.5rem;
  padding-bottom: 0;
  text-align: left;
  height: auto;
  width: 75%;
  float: left;
  z-index: 1;
}
.gallery .carousel-caption .figure-caption {
  font-size: 1rem;
  line-height: 1.125;
  color: #2c373f;
  font-weight: 600;
  max-height: 2.25rem;
  margin-bottom: 0.75rem;
  overflow: hidden;
}
.gallery .carousel-caption .figure-caption:last-of-type {
  margin-bottom: 0;
}
.gallery .carousel-caption .figure-credit {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  color: #005557;
  height: 1.125rem;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gallery .carousel-indicators {
  background-color: transparent;
  left: auto;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-end;
  height: auto;
  margin-right: 2rem;
  margin-bottom: 0;
  padding-top: 0.625rem;
  opacity: 1;
  width: 23%;
  z-index: 1;
  top: 100%;
}
.gallery .carousel-indicators li {
  background-color: transparent;
  border: 2px solid #2c373f;
  border-top: 2px solid #2c373f;
  border-bottom: 2px solid #2c373f;
  border-radius: 1rem;
  height: 0.625rem;
  margin-bottom: 0.5rem;
  width: 0.625rem;
  opacity: 1;
}
.gallery .carousel-indicators li.active {
  background-color: #2c373f;
}
.gallery .carousel-control-next,
.gallery .carousel-control-prev {
  position: absolute;
  top: 50%;
  bottom: 0;
  z-index: 1;
  background-color: #2c373f;
  border-radius: 6rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  opacity: 1;
  padding: 0;
  text-align: center;
  transform: translateY(-50%);
  transition: opacity 0.15s ease;
  width: 2rem;
}
.gallery .carousel-control-next:hover, .gallery .carousel-control-next:focus,
.gallery .carousel-control-prev:hover,
.gallery .carousel-control-prev:focus {
  background-color: #3d5d6b;
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 1;
}
.gallery .carousel-control-next:focus:not(:hover),
.gallery .carousel-control-prev:focus:not(:hover) {
  background-color: #2c373f;
}
.gallery .carousel-control-next {
  right: -10px;
}
.gallery .carousel-control-prev {
  left: -10px;
}
.gallery .icon {
  fill: #fff;
  stroke: #fff;
}
.gallery .icon-arrow-left,
.gallery .icon-arrow-right {
  display: inline-block;
  width: 10px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}
.gallery .icon-arrow-left {
  margin-right: 0.125rem;
}
.gallery .icon-arrow-right {
  margin-left: 0.125rem;
}
@media (min-width: 768px) {
  .two-column .gallery .carousel.onethird, .two-column .gallery .carousel.onehalf, .two-column .gallery .carousel.twothird {
    max-width: 100%;
  }
  .two-column .gallery .carousel.onethird.left, .two-column .gallery .carousel.onethird.right, .two-column .gallery .carousel.onehalf.left, .two-column .gallery .carousel.onehalf.right, .two-column .gallery .carousel.twothird.left, .two-column .gallery .carousel.twothird.right {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  .gallery .carousel.onethird {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .gallery .carousel.onehalf {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .gallery .carousel.twothird {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .gallery .carousel.onethird.left, .gallery .carousel.onehalf.left, .gallery .carousel.twothird.left {
    float: left;
    margin-left: 0;
    margin-right: 2.5rem;
  }
  .gallery .carousel.onethird.right, .gallery .carousel.onehalf.right, .gallery .carousel.twothird.right {
    float: right;
    margin-left: 2.5rem;
    margin-right: 0;
  }
  .gallery .carousel.onethird.center, .gallery .carousel.onehalf.center, .gallery .carousel.twothird.center {
    margin-left: auto;
    margin-right: auto;
  }
  .gallery .carousel.onethird .carousel-indicators, .gallery .carousel.onehalf .carousel-indicators, .gallery .carousel.twothird .carousel-indicators {
    margin-right: 2rem;
  }
  .gallery .carousel.onethird .carousel-inner, .gallery .carousel.onehalf .carousel-inner, .gallery .carousel.twothird .carousel-inner {
    width: calc(100% - 4.5rem);
  }
  .gallery .carousel.onethird .carousel-control-next,
.gallery .carousel.onethird .carousel-control-prev, .gallery .carousel.onehalf .carousel-control-next,
.gallery .carousel.onehalf .carousel-control-prev, .gallery .carousel.twothird .carousel-control-next,
.gallery .carousel.twothird .carousel-control-prev {
    top: calc(50% - 4.25rem);
    height: 2.5rem;
    width: 2.5rem;
  }
  .gallery .carousel.onethird .icon-arrow-left,
.gallery .carousel.onethird .icon-arrow-right, .gallery .carousel.onehalf .icon-arrow-left,
.gallery .carousel.onehalf .icon-arrow-right, .gallery .carousel.twothird .icon-arrow-left,
.gallery .carousel.twothird .icon-arrow-right {
    width: 10px;
    height: 20px;
  }
  .gallery .carousel .carousel-indicators {
    margin-right: 3rem;
  }
  .gallery .carousel .carousel-inner {
    width: calc(100% - 6rem);
  }
  .gallery .carousel .carousel-control-next,
.gallery .carousel .carousel-control-prev {
    height: 3rem;
    width: 3rem;
  }
  .gallery .carousel .icon-arrow-left,
.gallery .carousel .icon-arrow-right {
    width: 14px;
    height: 28px;
  }
}
@media (min-width: 992px) {
  .two-column .gallery .carousel.onethird {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .two-column .gallery .carousel.onehalf {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .two-column .gallery .carousel.twothird {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .two-column .gallery .carousel.onethird.left, .two-column .gallery .carousel.onehalf.left, .two-column .gallery .carousel.twothird.left {
    float: left;
    margin-left: 0;
    margin-right: 2.5rem;
  }
  .two-column .gallery .carousel.onethird.right, .two-column .gallery .carousel.onehalf.right, .two-column .gallery .carousel.twothird.right {
    float: right;
    margin-left: 2.5rem;
    margin-right: 0;
  }
}
@media (min-width: 1200px) {
  .two-column .gallery .carousel.onethird {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
}

.generic {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .generic {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .generic {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .generic {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .generic {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.generic .alt-background {
  background-color: #f2f9ff;
  padding: 1.5rem;
  border-radius: 5px;
}
.generic .alt-background .rte p:first-of-type {
  padding-top: 0;
}
.generic .rte p:first-of-type {
  padding-top: 0;
}
.generic p a, .generic .alt-background a {
  font-weight: 700;
}
.generic li > a {
  font-weight: 700;
}
.generic td:first-child a {
  font-weight: inherit;
}
.generic li > a {
  font-weight: 700;
}
.generic td:first-child a {
  font-weight: inherit;
}
.generic .btn, .generic .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .generic .mktoButton {
  margin: 0.5rem 0;
}

.global-search-typeahead, .global-search-typeahead-mobile {
  position: relative;
}
.global-search-typeahead.search-focus .search-dropdown, .global-search-typeahead-mobile.search-focus .search-dropdown {
  display: block;
  border-radius: 0 0 5px 5px;
}
.global-search-typeahead .search-typeahead-input::-ms-clear, .global-search-typeahead-mobile .search-typeahead-input::-ms-clear {
  display: none;
}
.global-search-typeahead .search-dropdown, .global-search-typeahead-mobile .search-dropdown {
  background-color: #fff;
  border: 1px solid transparent;
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.12);
  display: none;
  margin-top: 0.5rem;
  position: absolute;
  top: 100%;
  left: 50%;
  text-align: left;
  transform: translateX(-50%);
  width: calc(100vw - 30px);
  z-index: 6;
}
.global-search-typeahead .search-dropdown.search-mobile, .global-search-typeahead-mobile .search-dropdown.search-mobile {
  margin-top: 2.5rem;
}
.global-search-typeahead .search-dropdown ul, .global-search-typeahead-mobile .search-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.global-search-typeahead .search-dropdown ul li, .global-search-typeahead-mobile .search-dropdown ul li {
  font-size: 1rem;
  line-height: 1.125;
  text-align: left;
}
.global-search-typeahead .search-dropdown ul li:before, .global-search-typeahead-mobile .search-dropdown ul li:before {
  display: none;
}
.global-search-typeahead .search-dropdown ul li:not(:last-of-type), .global-search-typeahead-mobile .search-dropdown ul li:not(:last-of-type) {
  margin-bottom: 0.5rem;
}
.global-search-typeahead .search-dropdown ul li a, .global-search-typeahead-mobile .search-dropdown ul li a {
  font-size: 1rem;
  line-height: 1.125;
  color: #fff;
  font-weight: 400;
}
.global-search-typeahead .search-dropdown ul li a:focus, .global-search-typeahead .search-dropdown ul li a.active, .global-search-typeahead-mobile .search-dropdown ul li a:focus, .global-search-typeahead-mobile .search-dropdown ul li a.active {
  outline: 0;
  text-decoration: underline;
}
.global-search-typeahead .search-dropdown .heading-5, .global-search-typeahead-mobile .search-dropdown .heading-5 {
  color: #ffcd55;
  margin-bottom: 0.625rem;
}
.global-search-typeahead .search-dropdown .view-all-link, .global-search-typeahead-mobile .search-dropdown .view-all-link {
  font-size: 1rem;
  line-height: 1.125;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 0;
}
.global-search-typeahead .search-dropdown .view-all-link:focus, .global-search-typeahead .search-dropdown .view-all-link.active, .global-search-typeahead-mobile .search-dropdown .view-all-link:focus, .global-search-typeahead-mobile .search-dropdown .view-all-link.active {
  outline: 0;
  text-decoration: underline;
}
.global-search-typeahead .search-dropdown .view-all-link.looking-for, .global-search-typeahead-mobile .search-dropdown .view-all-link.looking-for {
  color: #cbced1;
  font-style: italic;
  margin-top: 0;
}
.global-search-typeahead .search-dropdown .primary-view-all, .global-search-typeahead-mobile .search-dropdown .primary-view-all {
  font-size: 1rem;
  line-height: 1.125;
  align-items: center;
  background-color: #3d5d6b;
  color: #fff;
  display: flex;
  font-weight: 600;
  height: 50px;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.global-search-typeahead .search-dropdown .primary-view-all:focus, .global-search-typeahead .search-dropdown .primary-view-all.active, .global-search-typeahead-mobile .search-dropdown .primary-view-all:focus, .global-search-typeahead-mobile .search-dropdown .primary-view-all.active {
  outline: 0;
  text-decoration: underline;
}
.global-search-typeahead .search-dropdown .category-list:not(:first-of-type), .global-search-typeahead-mobile .search-dropdown .category-list:not(:first-of-type) {
  margin-top: 1.5rem;
}
.global-search-typeahead .search-dropdown .doctor-name, .global-search-typeahead-mobile .search-dropdown .doctor-name {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #fff;
  font-weight: 600;
}
.global-search-typeahead .search-dropdown .location-name, .global-search-typeahead-mobile .search-dropdown .location-name {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #fff;
  font-weight: 600;
}
.global-search-typeahead .search-dropdown .dropdown-container, .global-search-typeahead-mobile .search-dropdown .dropdown-container {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  flex-direction: column;
  margin: 0;
}
.global-search-typeahead .search-dropdown .dropdown-container .dropdown-primary, .global-search-typeahead-mobile .search-dropdown .dropdown-container .dropdown-primary {
  background-color: #2c373f;
  order: 2;
  padding: 1.75rem 1.75rem 1.25rem;
}
.global-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary, .global-search-typeahead-mobile .search-dropdown .dropdown-container .dropdown-secondary {
  display: flex;
  background-color: #3d5d6b;
  order: 1;
}
.global-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .heading-5,
.global-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary ul, .global-search-typeahead-mobile .search-dropdown .dropdown-container .dropdown-secondary .heading-5,
.global-search-typeahead-mobile .search-dropdown .dropdown-container .dropdown-secondary ul {
  display: none;
}
.global-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .view-all-link, .global-search-typeahead-mobile .search-dropdown .dropdown-container .dropdown-secondary .view-all-link {
  display: none;
}
.global-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .view-all-link.mobile-find-link, .global-search-typeahead-mobile .search-dropdown .dropdown-container .dropdown-secondary .view-all-link.mobile-find-link {
  align-items: center;
  border-bottom: 1px solid #2c373f;
  color: #fff;
  display: flex;
  justify-content: center;
  margin-top: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 0.75rem 0.5rem;
}
.global-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .category-list, .global-search-typeahead-mobile .search-dropdown .dropdown-container .dropdown-secondary .category-list {
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 0 0 50%;
  margin-top: 0;
}
.global-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .category-list:not(:first-of-type) .mobile-find-link, .global-search-typeahead-mobile .search-dropdown .dropdown-container .dropdown-secondary .category-list:not(:first-of-type) .mobile-find-link {
  border-left: 1px solid #2c373f;
}
.global-search-typeahead .search-dropdown .cta-container .current-network, .global-search-typeahead-mobile .search-dropdown .cta-container .current-network {
  display: none;
}
.global-search-typeahead .search-dropdown .search-doctors .profile-image, .global-search-typeahead-mobile .search-dropdown .search-doctors .profile-image {
  height: 62px;
  min-width: 62px;
  width: 62px;
  flex: 0 0 62px;
  margin-right: 1.5rem;
  border: none;
  box-shadow: none;
}
.global-search-typeahead .search-dropdown .search-doctors li, .global-search-typeahead-mobile .search-dropdown .search-doctors li {
  align-items: center;
  display: flex;
}
.global-search-typeahead .search-dropdown .search-doctors svg, .global-search-typeahead-mobile .search-dropdown .search-doctors svg {
  height: 100%;
  width: 100%;
}
.global-search-typeahead .search-dropdown .search-locations li, .global-search-typeahead-mobile .search-dropdown .search-locations li {
  align-items: center;
  display: flex;
}
.global-search-typeahead .search-dropdown .search-locations svg, .global-search-typeahead-mobile .search-dropdown .search-locations svg {
  flex: 0 0 25px;
  height: 25px;
  margin-right: 1.5rem;
  fill: #a2c3d6;
  width: 25px;
}
@media (min-width: 992px) {
  .global-search-typeahead .search-dropdown, .global-search-typeahead-mobile .search-dropdown {
    width: 65%;
  }
  .global-search-typeahead .search-dropdown .dropdown-container, .global-search-typeahead-mobile .search-dropdown .dropdown-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    flex-direction: row;
    margin: 0;
  }
  .global-search-typeahead .search-dropdown .dropdown-container .dropdown-primary, .global-search-typeahead-mobile .search-dropdown .dropdown-container .dropdown-primary {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
    border-right: 1px solid #2c373f;
    order: 1;
    padding: 1.75rem 2rem 1.25rem 1.75rem;
  }
  .global-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary, .global-search-typeahead-mobile .search-dropdown .dropdown-container .dropdown-secondary {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
    display: block;
    background-color: #333f48;
    font-weight: 300;
    order: 2;
    padding: 1.75rem 2rem 1.25rem 2rem;
  }
  .global-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .heading-5, .global-search-typeahead-mobile .search-dropdown .dropdown-container .dropdown-secondary .heading-5 {
    display: block;
  }
  .global-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary ul, .global-search-typeahead-mobile .search-dropdown .dropdown-container .dropdown-secondary ul {
    display: block;
    color: #fff;
  }
  .global-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .view-all-link, .global-search-typeahead-mobile .search-dropdown .dropdown-container .dropdown-secondary .view-all-link {
    display: inline-block;
  }
  .global-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .view-all-link.mobile-find-link, .global-search-typeahead-mobile .search-dropdown .dropdown-container .dropdown-secondary .view-all-link.mobile-find-link {
    display: none;
  }
  .global-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .category-list, .global-search-typeahead-mobile .search-dropdown .dropdown-container .dropdown-secondary .category-list {
    display: block;
  }
  .global-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .category-list:not(:first-of-type), .global-search-typeahead-mobile .search-dropdown .dropdown-container .dropdown-secondary .category-list:not(:first-of-type) {
    margin-top: 1.5rem;
  }
  .global-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .category-list:not(:first-of-type) .view-all-link, .global-search-typeahead-mobile .search-dropdown .dropdown-container .dropdown-secondary .category-list:not(:first-of-type) .view-all-link {
    border-left: none;
  }
  .global-search-typeahead .search-dropdown .cta-container, .global-search-typeahead-mobile .search-dropdown .cta-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    background-color: #f2f9ff;
    margin: 0;
    padding: 0 1.75rem;
  }
  .global-search-typeahead .search-dropdown .cta-container .primary-view-all, .global-search-typeahead-mobile .search-dropdown .cta-container .primary-view-all {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
    justify-content: flex-start;
  }
  .global-search-typeahead .search-dropdown .cta-container .current-network, .global-search-typeahead-mobile .search-dropdown .cta-container .current-network {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
    font-size: 0.875rem;
    line-height: 1.1428571429;
    color: #3d5d6b;
    display: flex;
    align-items: center;
    padding-left: 2rem;
  }
}

.hero-secondary {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  color: #006D9D;
  margin-bottom: 1rem;
}
@media (min-width: 576px) {
  .hero-secondary {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .hero-secondary {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .hero-secondary {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .hero-secondary {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.hero-secondary.has-border .hero-border {
  border: 0.5rem solid #a2c3d6;
  padding: 1.185rem 1rem;
}
.hero-secondary.has-picture .hero-border {
  border: none;
  padding: 0;
}
.hero-secondary:not(.has-picture) .hero-content {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0;
}
.hero-secondary:not(.has-border):not(.has-picture) .hero-content-container {
  padding-left: 0;
  padding-right: 0;
  position: relative;
}
.hero-secondary:not(.has-border):not(.has-picture) .hero-content {
  text-align: left;
  margin-top: 1rem;
}
.hero-secondary:not(.has-border):not(.has-picture) .hero-title {
  display: none;
}
.hero-secondary:not(.has-border):not(.has-picture) .btn, .hero-secondary:not(.has-border):not(.has-picture) .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .hero-secondary:not(.has-border):not(.has-picture) .mktoButton {
  align-self: flex-start;
}
.hero-secondary.has-picture .hero-img {
  display: block;
  background-color: #2c373f;
}
.hero-secondary.has-logo.logo-wide .specialty-logo {
  width: 150px;
}
.hero-secondary.has-logo .specialty-logo {
  display: block;
  width: 100px;
  margin: 0 auto 1rem;
}
.hero-secondary.has-logo .specialty-logo.has-border {
  padding-bottom: 0.5rem;
  border-bottom: 0.25rem solid #ffcd55;
}
.hero-secondary.has-logo .specialty-logo .logo {
  width: 100%;
}
.hero-secondary.has-logo .specialty-logo .logo-white {
  display: none;
}
.hero-secondary.has-logo:not(.has-picture) .hero-title, .hero-secondary.has-logo:not(.has-border):not(.has-picture) .hero-title {
  display: none;
}
.hero-secondary.has-logo:not(.has-picture) .hero-description, .hero-secondary.has-logo:not(.has-border):not(.has-picture) .hero-description {
  text-align: left;
  width: calc(100% - 100px);
}
.hero-secondary.has-logo.dark-bg.has-logo .specialty-logo .logo-full-color {
  display: none;
}
.hero-secondary.has-logo.dark-bg.has-logo .specialty-logo .logo-white {
  display: block;
}
.hero-secondary.has-logo.dark-bg .hero-title {
  color: #f5f5f6;
}
.hero-secondary.has-logo.dark-bg .hero-description {
  color: #f2f9ff;
}
.hero-secondary .hero-outer {
  background-color: #2c373f;
  border-radius: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
}
.hero-secondary .hero-img {
  display: none;
  position: relative;
}
.hero-secondary .hero-img img,
.hero-secondary .hero-img source {
  object-fit: cover;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%;";
  width: 100%;
  border-radius: 0;
  height: 25rem;
  clip-path: circle(700px at 50% -19rem);
}
.hero-secondary .hero-img.no-clip img, .hero-secondary .hero-img.no-clip source {
  clip-path: none;
}
.hero-secondary .hero-content-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  background-color: #2c373f;
}
@media (min-width: 576px) {
  .hero-secondary .hero-content-container {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .hero-secondary .hero-content-container {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .hero-secondary .hero-content-container {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .hero-secondary .hero-content-container {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.hero-secondary .hero-content {
  flex: auto;
  max-width: 100%;
  display: block;
  padding: 1rem 0 1rem;
  text-align: center;
}
.hero-secondary .hero-title {
  font-size: 1.75rem;
  line-height: 1.2142857143;
  font-weight: 700;
  color: #fff;
  display: block;
  margin-bottom: 0.5rem;
  margin-top: 0;
}
.hero-secondary .hero-title.has-border {
  padding-bottom: 0.5rem;
  border-bottom: 0.25rem solid #ffcd55;
}
.hero-secondary .hero-title:nth-last-child(2) {
  margin-bottom: 1rem;
}
.hero-secondary .hero-title.title-light {
  font-weight: 300;
  margin-bottom: 0.5rem;
}
.hero-secondary .hero-description {
  font-size: 1rem;
  line-height: 1.125;
  color: #fff;
  padding-top: 0.5rem;
  margin-bottom: 2rem;
}
.hero-secondary .button-group {
  display: flex;
  flex: 1 0 100%;
  flex-flow: column;
}
.hero-secondary .btn, .hero-secondary .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .hero-secondary .mktoButton {
  display: block;
}
.hero-secondary .btn:not(:only-of-type), .hero-secondary .marketo-form .mktoForm .mktoButton:not(:only-of-type), .marketo-form .mktoForm .hero-secondary .mktoButton:not(:only-of-type) {
  margin: 0 0.25rem 0.625rem;
}
.hero-secondary .btn:hover, .hero-secondary .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .hero-secondary .mktoButton:hover, .hero-secondary .btn:focus, .hero-secondary .marketo-form .mktoForm .mktoButton:focus, .marketo-form .mktoForm .hero-secondary .mktoButton:focus {
  color: #ffcd55;
  background-color: #2c373f;
  text-decoration: none;
}
@media (min-width: 576px) {
  .hero-secondary .hero-img img,
.hero-secondary .hero-img source {
    height: 30rem;
    clip-path: circle(800px at 50% -21rem);
  }
  .hero-secondary .hero-content-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .hero-secondary.has-logo.logo-wide .specialty-logo {
    width: 170px;
  }
  .hero-secondary.has-logo .specialty-logo {
    width: 120px;
  }
  .hero-secondary .button-group {
    flex: 1 0 50%;
    flex-flow: row;
    justify-content: center;
  }
  .hero-secondary .btn, .hero-secondary .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .hero-secondary .mktoButton {
    display: inline-block;
  }
  .hero-secondary .btn:not(:only-of-type), .hero-secondary .marketo-form .mktoForm .mktoButton:not(:only-of-type), .marketo-form .mktoForm .hero-secondary .mktoButton:not(:only-of-type) {
    margin: 0 0.25rem 0.625rem;
    width: 50%;
  }
}
@media (min-width: 768px) {
  .hero-secondary .hero-img img,
.hero-secondary .hero-img source {
    height: auto;
    clip-path: none;
  }
  .hero-secondary .hero-content {
    z-index: 1;
  }
  .hero-secondary:not(.has-picture) .hero-content-container {
    position: relative;
  }
  .hero-secondary:not(.has-picture) .hero-content {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }
  .hero-secondary:not(.has-border):not(.has-picture) .hero-content-container {
    padding-left: 0;
    padding-right: 0;
  }
  .hero-secondary:not(.has-border):not(.has-picture) .hero-content {
    text-align: left;
    margin-top: 1rem;
  }
  .hero-secondary:not(.has-border):not(.has-picture) .hero-title {
    display: none;
  }
  .hero-secondary:not(.has-border):not(.has-picture) .btn, .hero-secondary:not(.has-border):not(.has-picture) .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .hero-secondary:not(.has-border):not(.has-picture) .mktoButton {
    align-self: flex-start;
  }
  .hero-secondary .hero-content {
    padding: 0;
  }
  .hero-secondary .button-group {
    flex: 1 0 100%;
    flex-flow: wrap;
    justify-content: flex-start;
  }
  .hero-secondary .btn, .hero-secondary .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .hero-secondary .mktoButton {
    font-size: 1rem;
    line-height: 1.125;
    padding: 0.5rem 0.75rem;
  }
  .hero-secondary .btn:not(:only-of-type), .hero-secondary .marketo-form .mktoForm .mktoButton:not(:only-of-type), .marketo-form .mktoForm .hero-secondary .mktoButton:not(:only-of-type) {
    margin-left: 0;
    margin-right: 0.5rem;
    width: auto;
  }
  .hero-secondary.has-logo.logo-wide .specialty-logo {
    width: 220px;
  }
  .hero-secondary.has-logo .specialty-logo {
    display: block;
    float: right;
    margin-bottom: 1rem;
    width: 170px;
    position: relative;
    top: 0;
    right: 0;
  }
  .hero-secondary.has-logo .specialty-logo .logo {
    width: 100%;
  }
  .hero-secondary.has-logo .hero-content-container {
    justify-content: flex-end;
  }
  .hero-secondary.has-logo .hero-title {
    clear: both;
  }
  .hero-secondary.has-logo:not(.has-picture) .hero-title, .hero-secondary.has-logo:not(.has-border):not(.has-picture) .hero-title {
    display: none;
  }
  .hero-secondary.has-logo:not(.has-picture) .hero-description, .hero-secondary.has-logo:not(.has-border):not(.has-picture) .hero-description {
    width: calc(100% - 200px);
  }
  .hero-secondary .hero-content-container {
    display: flex;
    justify-content: flex-start;
    bottom: 0;
    height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 0;
    position: absolute;
    clip-path: circle(66% at center left);
  }
  .hero-secondary .hero-content-container.center {
    justify-content: center;
    text-align: center;
  }
  .hero-secondary .hero-content-container.center .hero-content {
    text-align: center;
  }
  .hero-secondary .hero-content-container.center .hero-description {
    text-align: center;
  }
  .hero-secondary .hero-content-container.center .btn, .hero-secondary .hero-content-container.center .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .hero-secondary .hero-content-container.center .mktoButton {
    align-self: center;
  }
  .hero-secondary .hero-content-container.right {
    justify-content: flex-end;
    text-align: right;
    clip-path: circle(66% at center right);
  }
  .hero-secondary .hero-content-container.right .hero-content {
    text-align: right;
  }
  .hero-secondary .hero-content-container.right .hero-description {
    text-align: right;
  }
  .hero-secondary .hero-content-container.right .button-group {
    justify-content: flex-end;
  }
  .hero-secondary .hero-content-container.right .btn, .hero-secondary .hero-content-container.right .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .hero-secondary .hero-content-container.right .mktoButton {
    align-self: flex-end;
  }
  .hero-secondary .hero-content-container.right .btn:not(:only-of-type), .hero-secondary .hero-content-container.right .marketo-form .mktoForm .mktoButton:not(:only-of-type), .marketo-form .mktoForm .hero-secondary .hero-content-container.right .mktoButton:not(:only-of-type) {
    margin-right: 0;
    margin-left: 0.5rem;
  }
  .hero-secondary .hero-content {
    flex: 0 0 48%;
    max-width: 48%;
    flex-direction: column;
    align-self: center;
    text-align: left;
  }
  .hero-secondary .hero-title {
    font-size: 1.5rem;
    line-height: 1.25;
    font-weight: 600;
    padding: 0;
    margin-bottom: 0.75rem;
  }
  .hero-secondary .hero-title.title-light {
    margin-bottom: 0.5rem;
  }
  .hero-secondary .hero-description {
    font-size: 1.25rem;
    line-height: 1.2;
  }
  .hero-secondary .btn, .hero-secondary .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .hero-secondary .mktoButton {
    align-self: flex-start;
  }
  .two-column .hero-secondary.has-border .hero-border {
    border: 0.5rem solid #a2c3d6;
    padding: 1.185rem 1rem;
  }
  .two-column .hero-secondary.has-picture .hero-border {
    border: none;
    padding: 0;
  }
  .two-column .hero-secondary:not(.has-picture) .hero-content {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }
  .two-column .hero-secondary:not(.has-border):not(.has-picture) .hero-content-container {
    padding-left: 0;
    padding-right: 0;
    position: relative;
  }
  .two-column .hero-secondary:not(.has-border):not(.has-picture) .hero-content {
    text-align: left;
    margin-top: 1rem;
  }
  .two-column .hero-secondary:not(.has-border):not(.has-picture) .hero-title {
    display: none;
  }
  .two-column .hero-secondary:not(.has-border):not(.has-picture) .btn, .two-column .hero-secondary:not(.has-border):not(.has-picture) .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .two-column .hero-secondary:not(.has-border):not(.has-picture) .mktoButton {
    align-self: flex-start;
  }
  .two-column .hero-secondary.has-picture .hero-img {
    display: block;
  }
  .two-column .hero-secondary.has-logo.dark-bg .specialty-logo .logo-full-color {
    display: block;
  }
  .two-column .hero-secondary.has-logo.dark-bg .specialty-logo .logo-white {
    display: none;
  }
  .two-column .hero-secondary.has-logo.logo-wide .specialty-logo {
    width: 170px;
  }
  .two-column .hero-secondary.has-logo .specialty-logo {
    float: none;
    width: 120px;
    margin: 0 auto 1rem;
  }
  .two-column .hero-secondary.has-logo:not(.has-picture) .hero-title, .two-column .hero-secondary.has-logo:not(.has-border):not(.has-picture) .hero-title {
    display: none;
  }
  .two-column .hero-secondary.has-logo:not(.has-picture) .hero-description, .two-column .hero-secondary.has-logo:not(.has-border):not(.has-picture) .hero-description {
    width: calc(100% - 100px);
  }
  .two-column .hero-secondary .hero-outer {
    background-color: #2c373f;
    border-radius: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
  }
  .two-column .hero-secondary .hero-img {
    display: none;
    position: relative;
  }
  .two-column .hero-secondary .hero-img img,
.two-column .hero-secondary .hero-img source {
    object-fit: cover;
    object-position: 50% 50%;
    font-family: "object-fit: cover; object-position: 50% 50%;";
    width: 100%;
    height: 25rem;
    clip-path: circle(700px at 50% -19rem);
  }
  .two-column .hero-secondary .hero-content-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    clip-path: none;
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .two-column .hero-secondary .hero-content-container {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .two-column .hero-secondary .hero-content-container {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .two-column .hero-secondary .hero-content-container {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .two-column .hero-secondary .hero-content-container {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .two-column .hero-secondary .hero-content-container.center, .two-column .hero-secondary .hero-content-container.right {
    justify-content: center;
    text-align: center;
  }
  .two-column .hero-secondary .hero-content-container.center .hero-content, .two-column .hero-secondary .hero-content-container.right .hero-content {
    text-align: center;
  }
  .two-column .hero-secondary .hero-content-container.center .hero-description, .two-column .hero-secondary .hero-content-container.right .hero-description {
    text-align: center;
  }
  .two-column .hero-secondary .hero-content-container.center .button-group, .two-column .hero-secondary .hero-content-container.right .button-group {
    justify-content: center;
  }
  .two-column .hero-secondary .hero-content-container.center .btn, .two-column .hero-secondary .hero-content-container.center .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .two-column .hero-secondary .hero-content-container.center .mktoButton, .two-column .hero-secondary .hero-content-container.right .btn, .two-column .hero-secondary .hero-content-container.right .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .two-column .hero-secondary .hero-content-container.right .mktoButton {
    align-self: center;
  }
}
@media (min-width: 768px) {
  .two-column .hero-secondary .hero-content {
    flex: auto;
    max-width: 100%;
    display: block;
    padding: 1rem 0 1rem;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .two-column .hero-secondary .hero-title {
    font-size: 1.5rem;
    line-height: 1.25;
    color: #fff;
    margin-bottom: 0.75rem;
    margin-top: 0;
  }
  .two-column .hero-secondary .hero-title:nth-last-child(2) {
    margin-bottom: 1rem;
  }
  .two-column .hero-secondary .hero-title.title-light {
    font-weight: 300;
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 768px) {
  .two-column .hero-secondary .button-group {
    flex: 1 0 50%;
    flex-flow: row;
    justify-content: center;
  }
}
@media (min-width: 768px) {
  .two-column .hero-secondary .btn, .two-column .hero-secondary .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .two-column .hero-secondary .mktoButton {
    display: inline-block;
  }
  .two-column .hero-secondary .btn:not(:only-of-type), .two-column .hero-secondary .marketo-form .mktoForm .mktoButton:not(:only-of-type), .marketo-form .mktoForm .two-column .hero-secondary .mktoButton:not(:only-of-type) {
    margin: 0 0.25rem 0.625rem;
  }
}
@media (min-width: 992px) {
  .hero-secondary.dark-bg .hero-title {
    color: #f5f5f6;
  }
  .hero-secondary:not(.has-picture) .hero-content-container {
    position: relative;
  }
  .hero-secondary:not(.has-picture) .hero-content {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }
  .hero-secondary:not(.has-border):not(.has-picture) .hero-content-container {
    padding-left: 0;
    padding-right: 0;
  }
  .hero-secondary:not(.has-border):not(.has-picture) .hero-content {
    text-align: left;
    margin-top: 1rem;
  }
  .hero-secondary:not(.has-border):not(.has-picture) .hero-title {
    display: none;
  }
  .hero-secondary:not(.has-border):not(.has-picture) .btn, .hero-secondary:not(.has-border):not(.has-picture) .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .hero-secondary:not(.has-border):not(.has-picture) .mktoButton {
    align-self: flex-start;
  }
  .two-column .hero-secondary.dark-bg.has-logo .specialty-logo .logo-full-color {
    display: none;
  }
  .two-column .hero-secondary.dark-bg.has-logo .specialty-logo .logo-white {
    display: block;
  }
  .two-column .hero-secondary.dark-bg .hero-description {
    color: #f2f9ff;
  }
  .two-column .hero-secondary.has-logo.logo-wide .specialty-logo {
    width: 200px;
  }
  .two-column .hero-secondary.has-logo .specialty-logo {
    display: block;
    float: right;
    margin-bottom: 1rem;
    width: 150px;
    position: relative;
    top: 0;
    right: 0;
  }
  .two-column .hero-secondary.has-logo .specialty-logo .logo {
    width: 100%;
  }
  .two-column .hero-secondary.has-logo .hero-content-container {
    justify-content: flex-end;
  }
  .two-column .hero-secondary.has-logo .hero-title {
    clear: both;
  }
  .two-column .hero-secondary.has-logo:not(.has-picture) .hero-title, .two-column .hero-secondary.has-logo:not(.has-border):not(.has-picture) .hero-title {
    display: none;
  }
  .two-column .hero-secondary.has-logo:not(.has-picture) .hero-description, .two-column .hero-secondary.has-logo:not(.has-border):not(.has-picture) .hero-description {
    width: calc(100% - 200px);
  }
  .two-column .hero-secondary .hero-img img,
.two-column .hero-secondary .hero-img source {
    height: auto;
    clip-path: none;
  }
  .two-column .hero-secondary .hero-content {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .two-column .hero-secondary .hero-title {
    font-size: 1.75rem;
    line-height: 1.2142857143;
    padding: 0;
  }
  .two-column .hero-secondary .hero-title.has-border {
    border-bottom: 0.25rem solid #ffcd55;
  }
  .two-column .hero-secondary .hero-description {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    margin-bottom: 1rem;
    display: block;
  }
  .two-column .hero-secondary .button-group {
    justify-content: flex-start;
  }
  .two-column .hero-secondary .btn, .two-column .hero-secondary .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .two-column .hero-secondary .mktoButton {
    font-size: 1rem;
    line-height: 1.125;
    padding: 0.4375rem 0.5rem;
  }
  .two-column .hero-secondary .btn:not(:only-of-type), .two-column .hero-secondary .marketo-form .mktoForm .mktoButton:not(:only-of-type), .marketo-form .mktoForm .two-column .hero-secondary .mktoButton:not(:only-of-type) {
    margin-left: 0;
    margin-right: 0.5rem;
  }
  .two-column .hero-secondary .hero-content-container {
    display: flex;
    justify-content: flex-start;
    background-position: -35vw 50%;
    padding-left: 1rem;
    padding-right: 1rem;
    position: absolute;
    clip-path: circle(66% at center left);
  }
  .two-column .hero-secondary .hero-content-container.center {
    justify-content: center;
    text-align: center;
  }
  .two-column .hero-secondary .hero-content-container.center .hero-content {
    text-align: center;
  }
  .two-column .hero-secondary .hero-content-container.center .hero-description {
    text-align: center;
  }
  .two-column .hero-secondary .hero-content-container.center .btn, .two-column .hero-secondary .hero-content-container.center .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .two-column .hero-secondary .hero-content-container.center .mktoButton {
    align-self: center;
  }
  .two-column .hero-secondary .hero-content-container.right {
    justify-content: flex-end;
    text-align: right;
    clip-path: circle(66% at center right);
  }
  .two-column .hero-secondary .hero-content-container.right .hero-content {
    text-align: right;
  }
  .two-column .hero-secondary .hero-content-container.right .hero-description {
    text-align: right;
  }
  .two-column .hero-secondary .hero-content-container.right .button-group {
    justify-content: flex-end;
  }
  .two-column .hero-secondary .hero-content-container.right .btn, .two-column .hero-secondary .hero-content-container.right .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .two-column .hero-secondary .hero-content-container.right .mktoButton {
    align-self: flex-end;
  }
  .two-column .hero-secondary .hero-content-container.right .btn:not(:only-of-type), .two-column .hero-secondary .hero-content-container.right .marketo-form .mktoForm .mktoButton:not(:only-of-type), .marketo-form .mktoForm .two-column .hero-secondary .hero-content-container.right .mktoButton:not(:only-of-type) {
    margin-right: 0;
    margin-left: 0.5rem;
  }
  .two-column .hero-secondary .hero-content {
    flex: 0 0 50%;
    max-width: 50%;
    flex-direction: column;
    align-self: center;
    text-align: left;
    padding: 0 1rem;
  }
  .hero-secondary .hero-content-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .hero-secondary .hero-content {
    padding: 0;
  }
  .hero-secondary .btn:not(:only-of-type), .hero-secondary .marketo-form .mktoForm .mktoButton:not(:only-of-type), .marketo-form .mktoForm .hero-secondary .mktoButton:not(:only-of-type) {
    margin-left: 0;
    margin-right: 0.5rem;
  }
  .hero-secondary.has-logo.logo-wide .specialty-logo {
    width: 235px;
  }
  .hero-secondary.has-logo .specialty-logo {
    display: block;
    float: right;
    margin-bottom: 1rem;
    width: 185px;
    position: relative;
    top: 0;
    right: 0;
  }
  .hero-secondary.has-logo .specialty-logo .logo {
    width: 100%;
  }
  .hero-secondary.has-logo .hero-content-container {
    justify-content: flex-end;
  }
  .hero-secondary.has-logo .hero-title {
    clear: both;
  }
  .hero-secondary.has-logo:not(.has-picture) .hero-title, .hero-secondary.has-logo:not(.has-border):not(.has-picture) .hero-title {
    display: none;
  }
  .hero-secondary.has-logo:not(.has-picture) .hero-description, .hero-secondary.has-logo:not(.has-border):not(.has-picture) .hero-description {
    width: calc(100% - 200px);
  }
  .hero-secondary .hero-content-container {
    justify-content: flex-start;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    clip-path: circle(63% at center left);
  }
  .hero-secondary .hero-content-container.center {
    justify-content: center;
    text-align: center;
  }
  .hero-secondary .hero-content-container.center .hero-content {
    text-align: center;
  }
  .hero-secondary .hero-content-container.center .hero-description {
    text-align: center;
  }
  .hero-secondary .hero-content-container.center .btn, .hero-secondary .hero-content-container.center .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .hero-secondary .hero-content-container.center .mktoButton {
    align-self: center;
  }
  .hero-secondary .hero-content-container.right {
    justify-content: flex-end;
    clip-path: circle(63% at center right);
  }
  .hero-secondary .hero-content-container.right .hero-content {
    text-align: right;
  }
  .hero-secondary .hero-content-container.right .hero-description {
    text-align: right;
  }
  .hero-secondary .hero-content-container.right .btn, .hero-secondary .hero-content-container.right .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .hero-secondary .hero-content-container.right .mktoButton {
    align-self: flex-end;
  }
  .hero-secondary .hero-content-container.right .btn:not(:only-of-type), .hero-secondary .hero-content-container.right .marketo-form .mktoForm .mktoButton:not(:only-of-type), .marketo-form .mktoForm .hero-secondary .hero-content-container.right .mktoButton:not(:only-of-type) {
    margin-right: 0;
    margin-left: 0.5rem;
  }
  .hero-secondary .hero-content {
    flex: 0 0 45%;
    max-width: 45%;
    flex-direction: column;
    align-self: center;
    text-align: left;
  }
  .hero-secondary .hero-title {
    font-size: 2rem;
    line-height: 1.1875;
    padding: 0;
  }
  .hero-secondary .btn, .hero-secondary .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .hero-secondary .mktoButton {
    align-self: flex-start;
  }
}
@media (min-width: 1200px) {
  .hero-secondary.has-logo.logo-wide .specialty-logo {
    width: 250px;
  }
  .hero-secondary.has-logo .specialty-logo {
    width: 200px;
  }
  .two-column .hero-secondary .hero-content-container {
    clip-path: circle(63% at center left);
  }
  .two-column .hero-secondary .hero-content-container.right {
    clip-path: circle(63% at center right);
  }
  .two-column .hero-secondary .hero-content {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .two-column .hero-secondary .hero-title {
    font-size: 2rem;
    line-height: 1.1875;
  }
  .hero-secondary .hero-content-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .hero-secondary .hero-title {
    font-size: 2.75rem;
    line-height: 1.0909090909;
    padding: 0;
  }
  .hero-secondary .hero-content {
    padding: 0;
  }
  .hero-secondary .btn, .hero-secondary .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .hero-secondary .mktoButton {
    font-size: 1rem;
    line-height: 1.125;
    padding: 0.4375rem 3rem;
  }
}

.language-dropdown .dropdown-toggle {
  font-size: 1rem;
  line-height: 1.125;
  align-items: center;
  background: none;
  border: none;
  color: #006D9D;
  display: flex;
  margin: 0 0.25rem 0 auto;
  outline: 0;
  padding: 0;
}
.language-dropdown .dropdown-toggle:hover, .language-dropdown .dropdown-toggle:focus {
  text-decoration: underline;
}
.language-dropdown .dropdown-toggle:after {
  display: none;
}
.language-dropdown .dropdown-toggle svg {
  fill: #006D9D;
  height: 1.25rem;
  margin-right: 0.375rem;
  width: 1.25rem;
}
.language-dropdown .dropdown-menu {
  border-color: #e4e5e6;
  margin-top: 0.25rem;
  max-width: calc(100vw - 30px);
  width: calc(100vw - 30px);
}
.language-dropdown .dropdown-header {
  font-size: 1rem;
  line-height: 1.125;
  border-bottom: 1px solid #e4e5e6;
  color: #006D9D;
  font-weight: 600;
  padding: 1rem;
  text-align: center;
}
.language-dropdown .dropdown-item {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #3d5d6b;
  padding: 0.625rem 1rem;
}
.language-dropdown .dropdown-item:hover {
  background-color: #f2f9ff;
  color: #3d5d6b;
}
.language-dropdown .dropdown-item:first-of-type {
  margin-top: 0.75rem;
}
.language-dropdown .dropdown-item:last-of-type {
  margin-bottom: 0.5rem;
}
.language-dropdown .dropdown-item .language-name {
  pointer-events: none;
}
.language-dropdown .in-language-example {
  color: #006D9D;
  pointer-events: none;
}
@media (min-width: 576px) {
  .language-dropdown .dropdown-menu {
    width: 350px;
  }
}
@media (min-width: 768px) {
  .language-dropdown .dropdown-toggle {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}

.leftnav {
  margin-bottom: 2rem;
}
.leftnav.callout .link-parent,
.leftnav.callout .link-adjacent {
  display: flex;
}
.leftnav .link-parent,
.leftnav .link-adjacent {
  border-radius: 5px;
  margin-bottom: 0.75rem;
  padding: 1rem;
  position: relative;
  text-align: left;
  width: 100%;
}
.leftnav .link-parent:active,
.leftnav .link-adjacent:active {
  background-color: #006D9D !important;
  border-color: #006D9D !important;
  color: #fff !important;
  box-shadow: none !important;
}
.leftnav .link-parent {
  background-color: #2c373f;
  border-color: #2c373f;
  color: #fff;
  padding-left: 2.5rem;
}
.leftnav .link-parent:hover, .leftnav .link-parent:focus {
  background-color: #3d5d6b;
  border-color: #3d5d6b;
  color: #fff;
  box-shadow: none;
}
.leftnav .link-adjacent {
  background-color: #3d5d6b;
  border-color: #3d5d6b;
  color: #fff;
  padding-right: 2.5rem;
}
.leftnav .link-adjacent:hover, .leftnav .link-adjacent:focus {
  background-color: #2c373f;
  border-color: #2c373f;
  color: #fff;
  box-shadow: none;
}
.leftnav .accordion-link {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  background-color: transparent;
  border: transparent;
  color: #3d5d6b;
  display: inline-block;
  font-weight: 400;
  padding: 1rem 2.5rem 1rem 1rem;
  text-align: left;
  width: 100%;
}
.leftnav .accordion-link:hover, .leftnav .accordion-link:focus {
  color: #006D9D;
  outline: 0;
}
.leftnav .accordion-link.current {
  color: #005557;
  font-weight: 600;
  pointer-events: none;
}
.leftnav .accordion-item-body .accordion-link {
  font-size: 1rem;
  line-height: 1.125;
  padding: 0.5rem 2.5rem 0.5rem 1.5rem;
}
.leftnav .icon-arrow-left,
.leftnav .icon-arrow-right {
  align-self: center;
  fill: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.leftnav .icon-arrow-left {
  left: 1rem;
}
.leftnav .icon-arrow-right {
  right: 1rem;
}
.leftnav .icon-arrow-down {
  width: 0.75rem;
}
.leftnav .nav-accordion {
  background-color: #fff;
}
.leftnav .nav-accordion > .accordion-item > .accordion-item-body > .accordion-item-body-inner > .accordion-item {
  margin: 0.5rem 0;
}
.leftnav .nav-accordion > .accordion-item > .accordion-item-body > .accordion-item-body-inner > .accordion-item-body-inner {
  padding: 0.375rem 0;
}
.leftnav .nav-accordion .accordion-item {
  font-weight: 400;
}
.leftnav .nav-accordion .accordion-item .link-adjacent {
  display: flex;
  margin-top: 0.75rem;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle {
  color: #2c373f;
  font-weight: 400;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:hover, .leftnav .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:focus {
  color: #006D9D;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:hover .icon, .leftnav .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:focus .icon {
  fill: #006D9D;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .accordion-item-label {
  white-space: normal;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .icon {
  fill: #2c373f;
  transition: all 0.5s ease;
  transition-property: color, transform, margin;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active {
  background-color: #fff;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active .accordion-item-toggle {
  font-weight: 400;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active .accordion-item-toggle .icon {
  margin-top: -0.5rem;
  transform: rotate(180deg);
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body {
  font-size: 1rem;
  line-height: 1.125;
  height: auto;
  margin-bottom: 1rem;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner {
  font-size: 1rem;
  line-height: 1.125;
  height: auto;
  padding: 0;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item {
  border: 0;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title .accordion-item-toggle {
  font-size: 1rem;
  line-height: 1.125;
  background-color: transparent;
  color: #3d5d6b;
  font-weight: 400;
  padding: 0.5rem 2.5rem 0.5rem 1.5rem;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title .accordion-item-toggle:hover .accordion-item-label, .leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title .accordion-item-toggle:focus .accordion-item-label {
  color: #006D9D;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title .accordion-item-toggle:hover .icon, .leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title .accordion-item-toggle:focus .icon {
  fill: #006D9D;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active .accordion-item-toggle {
  background-color: #2c373f;
  font-weight: 600;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active .accordion-item-toggle:hover .accordion-item-label, .leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active .accordion-item-toggle:focus .accordion-item-label {
  color: #fff;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active .accordion-item-toggle:hover .icon, .leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active .accordion-item-toggle:focus .icon {
  fill: #fff;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active .accordion-item-toggle .accordion-item-label {
  color: #f5f5f6;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active .accordion-item-toggle .icon {
  fill: #f5f5f6;
  margin-top: -0.5rem;
  transform: rotate(180deg);
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active ~ .accordion-item-body {
  background-color: #f5f5f6;
  margin-bottom: auto;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner {
  background-color: transparent;
  padding: 0.5rem 0;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-link {
  padding: 0.5rem 2.5rem 0.5rem 2.5rem;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title .accordion-item-toggle {
  background-color: transparent;
  color: #3d5d6b;
  padding: 0.5rem 2.5rem;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title .accordion-item-toggle:hover .accordion-item-label, .leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title .accordion-item-toggle:focus .accordion-item-label {
  color: #006D9D;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title .accordion-item-toggle:hover .icon, .leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title .accordion-item-toggle:focus .icon {
  color: #006D9D;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title .accordion-item-toggle .accordion-item-label {
  color: #006D9D;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active .accordion-item-toggle {
  background-color: #f5f5f6;
  font-weight: 600;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active .accordion-item-toggle:hover .icon, .leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active .accordion-item-toggle:focus .icon {
  fill: #006D9D;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active .accordion-item-toggle .icon {
  fill: #006D9D;
  margin-top: -0.5rem;
  transform: rotate(180deg);
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active ~ .accordion-item-body {
  background-color: #f5f5f6;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner {
  background-color: transparent;
  padding: 0;
}
.leftnav .nav-accordion .accordion-item .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-item > .accordion-item-title.active ~ .accordion-item-body .accordion-item-body-inner .accordion-link {
  padding: 0.5rem 2.5rem 0.5rem 3.5rem;
}
@media (min-width: 768px) {
  .leftnav .link-parent,
.leftnav .link-adjacent {
    display: flex;
  }
  .leftnav .accordion-container {
    padding: 0;
  }
  .leftnav .accordion-container .nav-accordion {
    background-color: #fff;
  }
  .leftnav .accordion-container .nav-accordion .link-adjacent {
    display: none;
  }
  .leftnav .accordion-container .nav-accordion .accordion-item {
    display: block;
  }
}

.legend {
  font-size: 0.875rem;
  line-height: 1.1428571429;
}
.legend .legend-label {
  display: inline-block;
  padding-right: 0.75rem;
}
.legend .legend-data {
  display: inline-block;
  margin-bottom: 0;
}
.legend .legend-data li {
  display: inline-block;
  padding-right: 0.5rem;
}
.legend .legend-data li:last-child {
  padding-right: 0;
}
@media (min-width: 768px) {
  .legend {
    font-size: 1rem;
    line-height: 1.125;
  }
}

.list-filters {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .list-filters {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .list-filters {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .list-filters {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .list-filters {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.list-filters form {
  background-color: #f2f9ff;
  border-radius: 5px;
  padding: 1.5rem 1rem;
  position: relative;
}
.list-filters form button[type=submit] {
  margin-top: 1rem;
}
.list-filters .filter-group {
  margin-bottom: 1rem;
}
.list-filters label {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #2c373f;
  font-weight: 600;
}
.list-filters input[type=text] {
  background-color: #fff;
}
.list-filters select {
  background-color: #fff !important;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  padding-right: 2.5rem;
  position: relative;
  appearance: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  display: block;
}
.list-filters select::-ms-expand {
  display: none;
}
.list-filters .clear-btn {
  margin: 1.5rem 1rem 0.5rem;
  padding: 0;
}
@media (min-width: 768px) {
  .list-filters form {
    display: flex;
    flex-wrap: wrap;
  }
  .list-filters form .filter-group {
    flex: 1 1 0;
    margin-bottom: 0;
    margin-right: 1rem;
  }
  .list-filters form .filter-group:last-of-type {
    margin-right: 0;
  }
  .list-filters form button[type=submit] {
    align-self: flex-end;
    flex-basis: 100%;
    height: 42px;
  }
  .list-filters .clear-btn {
    margin: 0.5rem 1rem 0.5rem;
    position: absolute;
    top: 0;
    right: 2px;
  }
}
@media (min-width: 1200px) {
  .list-filters form .filter-group:last-of-type {
    margin-right: 1rem;
  }
  .list-filters form button[type=submit] {
    flex-basis: auto;
  }
}

.listing-overview {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .listing-overview {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .listing-overview {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .listing-overview {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .listing-overview {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.listing-overview .listing-overview-inner {
  background-color: #fff;
  margin-bottom: 1rem;
  max-width: 100%;
}
.listing-overview .card-deck {
  flex-direction: column;
}
.listing-overview .card-deck .listing-overview-container {
  display: flex;
  flex-basis: auto;
  flex-flow: column;
  margin-bottom: 4rem;
  max-width: 100%;
  padding: 1.5rem;
  background-color: #f5f5f6;
  border-radius: 5px;
}
.listing-overview .card-deck .listing-overview-container.is-expanded .listing-overview-content {
  max-height: none;
}
.listing-overview .card-deck .listing-overview-container.is-expanded .btn-link {
  display: block;
}
.listing-overview .card-deck .listing-overview-container .listing-overview-content {
  flex: 1 1 auto;
  margin-bottom: 2rem;
  overflow: hidden;
  padding: 0;
}
.listing-overview .card-deck .listing-overview-container .listing-overview-content button {
  display: none;
}
.listing-overview .section-title::after {
  background-color: #ffcd55;
}
.listing-overview .btn-link {
  color: #212529;
  background-color: #ffcd55;
  border-color: #ffcd55;
}
.listing-overview .btn-link:hover, .listing-overview .btn-link:focus {
  color: #2c373f;
  background-color: #fff;
  text-decoration: none;
}
.listing-overview .section-subtitle {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-weight: 400;
  color: #2c373f;
  text-align: left;
}
.listing-overview .offering-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.listing-overview .offering-list li {
  font-size: 1rem;
  line-height: 1.125;
  width: 100%;
  min-height: 3rem;
  border-bottom: 2px solid #e4e5e6;
  line-height: 1.1428571429;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3rem;
  padding: 2rem 0;
}
.listing-overview .offering-list li a {
  min-width: -webkit-fill-available;
}
.listing-overview .offering-list li a svg {
  float: right;
  margin-right: -1rem;
}
@media (min-width: 576px) {
  .listing-overview .card-deck {
    flex-flow: column;
    padding-left: 15px;
    padding-right: 15px;
  }
  .listing-overview .offering-list {
    columns: 2;
    column-gap: 30px;
    column-fill: balance;
  }
}
@media (min-width: 768px) {
  .two-column .listing-overview .card-deck {
    flex-flow: column;
  }
  .two-column .listing-overview .listing-overview-container:only-child .offering-list {
    columns: 2;
  }
  .listing-overview .listing-overview-inner {
    margin-bottom: 2rem;
  }
  .listing-overview .card-deck {
    flex-flow: row wrap;
  }
  .listing-overview .card-deck .listing-overview-container {
    flex-basis: calc(50% - 1rem);
    max-width: calc(50% - 1rem);
    overflow: auto;
    padding: 1.5rem 2rem 1.5rem;
    margin-bottom: 1.5rem;
  }
  .listing-overview .card-deck .listing-overview-container:nth-child(odd) {
    margin-left: 0;
  }
  .listing-overview .card-deck .listing-overview-container:nth-child(even) {
    margin-right: 0;
  }
  .listing-overview .card-deck .listing-overview-container:last-child:nth-child(odd) {
    margin-left: auto;
    margin-right: auto;
  }
  .listing-overview .card-deck .listing-overview-container:only-child {
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .listing-overview .card-deck .listing-overview-container .listing-overview-content {
    max-height: none;
  }
  .listing-overview .card-deck .listing-overview-container .toggle-expand {
    display: none;
  }
  .listing-overview .section-subtitle {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
  .listing-overview .offering-list li {
    margin-bottom: 0.5rem;
  }
  .listing-overview .btn-primary, .listing-overview .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .listing-overview .mktoButton {
    display: block;
    max-width: 12rem;
  }
}
@media (min-width: 992px) {
  .two-column .listing-overview .card-deck {
    flex-flow: row wrap;
  }
  .two-column .listing-overview .card-deck .listing-overview-container:only-child .offering-list {
    columns: 2;
  }
  .two-column .listing-overview .offering-list {
    columns: 1;
  }
  .listing-overview .section-subtitle {
    font-size: 1.25rem;
    line-height: 1.2;
    flex-basis: 50%;
    padding-right: 4rem;
  }
  .listing-overview .listing-overview-container {
    padding: 3rem 3rem 1.5rem;
  }
  .listing-overview .listing-overview-container:only-child .listing-overview-content {
    margin-bottom: 2rem;
    overflow: hidden;
    padding: 0;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .listing-overview .listing-overview-container:only-child .offering-list {
    columns: 4;
    max-width: 100%;
    min-width: 100%;
    margin-top: 0rem;
  }
  .listing-overview .listing-overview-container:only-child .section-title {
    flex-basis: 100%;
  }
  .listing-overview .listing-overview-container:only-child .section-subtitle {
    font-size: 1.25rem;
    line-height: 1.2;
    flex-basis: 50%;
    padding-right: 3rem;
    padding-bottom: 1rem;
  }
  .listing-overview .listing-overview-container:only-child .section-subtitle + .offering-list {
    columns: 2;
    max-width: 50%;
    min-width: 50%;
    margin-top: -2rem;
  }
}
@media (min-width: 1200px) {
  .two-column .listing-overview .offering-list {
    columns: 2;
  }
  .two-column .listing-overview .card-deck .listing-overview-container:only-child .offering-list {
    columns: 4;
  }
  .two-column .listing-overview .card-deck .listing-overview-container:only-child .section-subtitle + .offering-list {
    columns: 2;
  }
  .listing-overview .card-deck .listing-overview-container:only-child {
    padding: 3rem;
  }
  .listing-overview .card-deck .listing-overview-container:only-child h4.section-subtitle {
    max-width: 30rem;
  }
}

.mapbox-map .mapbox-map-popup-content {
  display: none;
}
.mapbox-map .mapboxgl-popup {
  max-width: calc(100% - 30px) !important;
  margin-top: -65px;
}
.mapbox-map .mapboxgl-popup:before {
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 30px solid #e4e5e6;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -12px);
}
.mapbox-map .mapboxgl-popup:after {
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #f5f5f6;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -13px);
}
.mapbox-map .mapboxgl-popup-content {
  position: relative;
  background-color: #f5f5f6;
  border: solid 0.25rem #e4e5e6;
  border-radius: 5px;
}
.mapbox-map .mapboxgl-popup-content .card {
  background-color: #f5f5f6;
}

.marketo-form {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 3rem;
}
@media (min-width: 576px) {
  .marketo-form {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .marketo-form {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .marketo-form {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .marketo-form {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.generic .marketo-form, .two-column .marketo-form {
  padding: 0;
}
.marketo-form.success .mktoForm {
  display: none !important;
}
.marketo-form.success .thank-you-message {
  display: flex;
}
.marketo-form.wide .mktoForm {
  display: block;
}
.marketo-form.wide .mktoForm > .mktoFormRow {
  margin-bottom: 0;
}
.marketo-form.wide .mktoForm > .mktoFormRow > .mktoFormCol {
  margin-bottom: 0 !important;
}
.marketo-form.wide.success .mktoForm {
  display: none;
}
.marketo-form.wide.success .thank-you-message {
  display: flex;
}
.marketo-form .thank-you-message {
  display: none;
}
.marketo-form .thank-you-message p {
  text-align: center;
}
.marketo-form .form-description {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  margin-bottom: 1.5rem;
}
.marketo-form .form-description :last-child {
  margin-bottom: 0;
}
.marketo-form .form-description p {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
.marketo-form .form-description p a {
  font-weight: 700;
}
.marketo-form .mktoForm {
  font-family: "proxima-nova", sans-serif;
}
.marketo-form .mktoForm > .mktoFormRow {
  margin-bottom: 1.5rem !important;
}
.marketo-form .mktoForm > .mktoFormRow > .mktoFormCol {
  margin-bottom: 0 !important;
}
.marketo-form .mktoForm .mktoFormRow {
  margin-bottom: 1.5rem;
  max-width: 400px;
}
.marketo-form .mktoForm .mktoFormRow.hidden {
  margin-bottom: 0 !important;
}
.marketo-form .mktoForm .mktoAsterix {
  float: left;
  padding-right: 0.25rem;
}
.marketo-form .mktoForm .mktoFieldWrap:not(.mktoRequiredField) .mktoAsterix {
  display: none;
}
.marketo-form .mktoForm label,
.marketo-form .mktoForm .mktoLabel {
  font-size: 1rem;
  line-height: 1.125;
  color: #2c373f;
  font-family: "proxima-nova", sans-serif;
  width: 100% !important;
}
.marketo-form .mktoForm legend {
  display: none;
}
.marketo-form .mktoForm .mktoEmailField,
.marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .mktoTextField {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  background-color: #f2f9ff;
  border: 1px solid #f2f9ff;
  border-radius: 0.3125rem;
  color: #2c373f;
  width: 100% !important;
}
.marketo-form .mktoForm .mktoEmailField:not(:placeholder-shown),
.marketo-form .mktoForm .mktoTelField:not(:placeholder-shown),
.marketo-form .mktoForm .mktoTextField:not(:placeholder-shown) {
  background-color: #f2f9ff;
  border-color: #f2f9ff;
}
.marketo-form .mktoForm .mktoEmailField:focus,
.marketo-form .mktoForm .mktoTelField:focus,
.marketo-form .mktoForm .mktoTextField:focus {
  background-color: #f5f5f6;
  border-color: #006D9D;
  box-shadow: none;
}
.marketo-form .mktoForm .mktoEmailField:hover,
.marketo-form .mktoForm .mktoTelField:hover,
.marketo-form .mktoForm .mktoTextField:hover {
  border-color: #006D9D;
}
.marketo-form .mktoForm select,
.marketo-form .mktoForm textarea {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  background-color: #f2f9ff;
  border: 1px solid #f2f9ff;
  border-radius: 0.3125rem;
  color: #2c373f;
  width: 100% !important;
}
.marketo-form .mktoForm select:focus,
.marketo-form .mktoForm textarea:focus {
  background-color: #f5f5f6;
  border-color: #006D9D;
  box-shadow: none;
}
.marketo-form .mktoForm select:hover,
.marketo-form .mktoForm textarea:hover {
  border-color: #006D9D;
}
.marketo-form .mktoForm select {
  height: 2.625rem;
}
.marketo-form .mktoForm .mktoCheckboxList label {
  font-size: 1rem;
  line-height: 1.125;
  display: block;
}
.marketo-form .mktoForm .mktoRadioList label {
  font-size: 1rem;
  line-height: 1.125;
}
.marketo-form .mktoForm fieldset .mktoFieldWrap label {
  font-size: 1rem;
  line-height: 1.125;
}
.marketo-form .mktoForm fieldset .mktoFieldWrap h4 {
  font-size: 1rem;
  line-height: 1.125;
}
.marketo-form .mktoForm fieldset .mktoCheckboxList {
  flex-direction: column-reverse;
}
.marketo-form .mktoForm fieldset .mktoCheckboxList label {
  padding: 0 0 0 1.75rem;
}
.marketo-form .mktoForm fieldset .mktoCheckboxList label::after {
  top: auto !important;
}
.marketo-form .mktoForm .mktoErrorMsg {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  color: #b23838;
  padding-top: 0.5rem;
}
footer .marketo-form .mktoForm .mktoErrorMsg {
  color: #fff;
}
.marketo-form .mktoForm .g-recaptcha {
  visibility: visible;
}
.marketo-form.lp-mktoform {
  background-color: #f2f9ff;
  border-radius: 5px;
  padding: 2.5rem 3rem !important;
}
.marketo-form.lp-mktoform .mktoEmailField,
.marketo-form.lp-mktoform .mktoTelField,
.marketo-form.lp-mktoform .mktoTextField,
.marketo-form.lp-mktoform select,
.marketo-form.lp-mktoform textarea {
  background-color: #fff;
  border: 1px solid #f2f9ff;
}
.marketo-form.lp-mktoform .mktoEmailField:focus,
.marketo-form.lp-mktoform .mktoTelField:focus,
.marketo-form.lp-mktoform .mktoTextField:focus,
.marketo-form.lp-mktoform select:focus,
.marketo-form.lp-mktoform textarea:focus {
  background-color: #fff;
  border-color: #a2c3d6;
  box-shadow: none;
}
.marketo-form.lp-mktoform .mktoEmailField:not(:placeholder-shown),
.marketo-form.lp-mktoform .mktoTelField:not(:placeholder-shown),
.marketo-form.lp-mktoform .mktoTextField:not(:placeholder-shown),
.marketo-form.lp-mktoform select:not(:placeholder-shown),
.marketo-form.lp-mktoform textarea:not(:placeholder-shown) {
  background-color: #fff;
  border-color: #f2f9ff;
}
.marketo-form .g-recaptcha {
  visibility: hidden;
}
.marketo-form .g-recaptcha div {
  width: 168px !important;
}
.marketo-form .g-recaptcha div div {
  border-left: 5px solid transparent;
  border-top: 5px solid transparent;
  border-radius: 5px;
  transition: background-color 400ms ease-out;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/250687/spiffygif_86x86.gif");
  background-repeat: no-repeat;
  background-position: center center;
}
.marketo-form .g-recaptcha.g-recaptcha {
  margin-top: 20px;
  margin-left: 110px;
}
.marketo-form .g-recaptcha.mktoInvalid > div > div {
  background-color: #f63c00;
  transition: background-color 400ms ease-in;
}
@media (min-width: 576px) {
  .marketo-form.wide .mktoForm {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .marketo-form.wide .mktoForm > .mktoFormRow {
    flex-basis: calc(50% - 0.5rem);
    margin-bottom: 0.5rem;
    max-width: none;
    padding: 0;
  }
  .hero-secondary + .two-column .landing-page .marketo-form {
    margin-top: 0;
  }
  .marketo-form.lp-mktoform {
    padding: 2.5rem 3rem !important;
  }
}
@media (min-width: 768px) {
  .marketo-form.wide .mktoForm > .mktoFormRow {
    flex-basis: calc(50% - 15px);
  }
  .marketo-form.lp-mktoform {
    padding: 2rem 2.5rem !important;
  }
  .marketo-form.left, .marketo-form.center, .marketo-form.right {
    max-width: 400px;
  }
  .two-column-main .hero-secondary + .two-column .landing-page .marketo-form.left, .two-column-main .hero-secondary + .two-column .landing-page .marketo-form.center, .two-column-main .hero-secondary + .two-column .landing-page .marketo-form.right {
    max-width: auto;
  }
}
@media (min-width: 992px) {
  .hero-secondary + .two-column .landing-page .marketo-form {
    margin-top: -12rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .marketo-form.left {
    float: left;
    margin-left: 0;
    margin-right: 1.5rem;
  }
  .marketo-form.center {
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
  }
  .marketo-form.center .mktoForm {
    display: grid;
    justify-content: center;
  }
  .marketo-form.right {
    float: right;
    margin-left: 1.5rem;
    margin-right: 0;
  }
  .two-column-main .marketo-form.left, .two-column-main .marketo-form.right {
    /* max-width: 350px; */
  }
}
@media (min-width: 1200px) {
  .hero-secondary + .two-column .landing-page .marketo-form {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .marketo-form.lp-mktoform {
    padding: 2.5rem 3rem !important;
  }
  .marketo-form.full-width .mktoForm .mktoFormRow,
.marketo-form.full-width .mktoForm .mktoButtonRow {
    max-width: none;
  }
}

.media-item {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  min-width: 150px;
  padding-top: 0;
  width: 100%;
}
@media (min-width: 576px) {
  .media-item {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .media-item {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .media-item {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .media-item {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.media-item.summary-top {
  display: flex;
  flex-direction: column-reverse;
}
.generic .media-item, .two-column-main .media-item, .two-column .media-item, .container .media-item {
  padding: 0;
}
.media-item.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.media-item.center .figure-caption,
.media-item.center .figure-credits {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  text-align: center;
}
.media-item .figure-img {
  width: 100%;
  max-height: 500px;
  margin-bottom: 0;
  object-fit: contain;
  object-position: center;
  font-family: "object-fit: contain; object-position: center;";
  border-radius: 5px;
}
.media-item.has-background .figure-text {
  padding: 1rem 1.25rem;
  background-color: #f2f9ff;
}
.media-item .figure-text {
  padding: 1rem 0;
  text-align: center;
}
.media-item .figure-caption.center,
.media-item .figure-credits.center {
  text-align: center;
}
.media-item .figure-caption {
  font-size: 1rem;
  line-height: 1.125;
  color: #2c373f;
}
.media-item .figure-caption ~ .figure-credits {
  padding-top: 0.5rem;
}
.media-item .figure-credits {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  color: #2c373f;
  font-style: italic;
}
.media-item img {
  border-radius: 5px;
}
@media (min-width: 768px) {
  .media-item {
    display: block;
    width: 40%;
  }
  .media-item.center {
    width: 70%;
  }
  .media-item.left {
    float: left;
    margin-left: 0;
    margin-right: 1.5rem;
  }
  .media-item.right {
    float: right;
    margin-left: 1.5rem;
    margin-right: 0;
  }
  .media-item.left, .media-item.right {
    margin-bottom: 1.5rem;
  }
  .media-item.left:not(.has-background) .figure-text, .media-item.right:not(.has-background) .figure-text {
    padding-bottom: 0;
  }
  .media-item.left .wistia_embed .w-bpb-wrapper, .media-item.right .wistia_embed .w-bpb-wrapper {
    height: 80px !important;
    width: 80px !important;
    top: calc(50% - 40px) !important;
    left: calc(50% - 40px) !important;
  }
  .media-item.full-width {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .media-item.center {
    width: 804px;
  }
  .media-item.left .wistia_embed .w-bpb-wrapper, .media-item.right .wistia_embed .w-bpb-wrapper {
    height: 112px !important;
    width: 112px !important;
    top: calc(50% - 56px) !important;
    left: calc(50% - 56px) !important;
  }
  .generic .media-item.center, .two-column-main .media-item.center, .container .media-item.center {
    width: 60%;
  }
}

.media-summary {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .media-summary {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .media-summary {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .media-summary {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .media-summary {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.media-summary.summary-top .media-summary-content-container {
  flex-direction: column-reverse;
}
.media-summary .media-summary-inner {
  background-color: #f5f5f6;
  margin-bottom: 1rem;
  max-width: 100%;
}
.media-summary .heading-3 {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #2c373f;
  font-weight: 800;
}
.media-summary .heading-3:after {
  background-color: #ffcd55;
  bottom: -4px;
  content: "";
  display: block;
  height: 4px;
  left: 0;
  position: relative;
  width: 4rem;
}
.media-summary .heading-4 {
  font-size: 1rem;
  line-height: 1.25;
  color: #3d5d6b;
  font-weight: 600;
}
.media-summary p {
  font-size: 1rem;
  line-height: 1.5;
  color: #2c373f;
  font-weight: 400;
}
.media-summary .btn, .media-summary .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .media-summary .mktoButton {
  margin-top: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
}
.media-summary .btn:not(:last-of-type), .media-summary .marketo-form .mktoForm .mktoButton:not(:last-of-type), .marketo-form .mktoForm .media-summary .mktoButton:not(:last-of-type) {
  margin-right: 1rem;
}
.media-summary .media-summary-content {
  background-color: #f5f5f6;
  padding: 1rem 1.625rem;
}
.media-summary .media-summary-content > :last-child {
  margin-bottom: 0;
}
.media-summary .media-summary-content > :last-child > :last-child {
  margin-bottom: 0;
}
.media-summary .media-summary-content .read-more-body {
  max-height: 100px;
}
.media-summary .media-summary-content .description {
  padding-top: 1rem;
}
.media-summary .media-summary-content .description .story-container {
  padding-right: 1rem;
  min-width: 12rem;
}
.media-summary .media-summary-player {
  flex: auto;
  max-width: 100%;
}
.media-summary .media-summary-player .media-item {
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}
.media-summary .media-summary-player .media-item .heading-4 {
  display: none;
}
.media-summary .media-summary-player .media-item .figure-text {
  display: none;
}
.media-summary .media-summary-content-container {
  background-color: #f5f5f6;
  border: 0;
  flex: auto;
  max-width: 100%;
}
.media-summary .two-columns {
  column-gap: 40px;
  padding-left: 1.5rem;
}
.media-summary .read-more, .media-summary .read-more:not(.in-container) {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 576px) {
  .media-summary .media-summary-content {
    padding: 2rem;
  }
}
@media (min-width: 768px) {
  .media-summary .heading-3 {
    font-weight: 800;
  }
  .media-summary .heading-4 {
    display: none;
  }
}
@media (min-width: 992px) {
  .media-summary.always-stacked .media-summary-content-container {
    flex-direction: column;
  }
  .media-summary.always-stacked .media-summary-content {
    flex-basis: auto;
    max-width: 100%;
  }
  .media-summary.always-stacked .media-summary-player {
    flex-basis: auto;
    max-width: 100%;
    width: 100%;
  }
  .media-summary.always-stacked .figure-img {
    max-height: none;
  }
  .media-summary.right .media-summary-content-container {
    flex-direction: row-reverse;
  }
  .media-summary.summary-top .media-summary-content-container {
    flex-direction: row;
  }
  .media-summary.summary-top.right .media-summary-content-container {
    flex-direction: row-reverse;
  }
  .media-summary.summary-top.always-stacked .media-summary-content-container {
    flex-direction: column-reverse;
  }
  .media-summary .media-summary-content-container {
    flex-direction: row;
    padding: 0;
    margin-bottom: 2rem;
  }
  .media-summary .media-summary-content {
    padding: 3rem;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    padding: 2rem;
  }
  .media-summary .media-summary-content .description {
    padding-top: 1rem;
  }
  .media-summary .media-summary-content .description .story-container {
    min-width: 10rem;
  }
  .media-summary .media-summary-player {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    width: 58.333333%;
  }
}
@media (min-width: 1200px) {
  .media-summary .media-summary-content {
    padding: 4rem;
    padding-top: 3rem;
  }
}

.meredith-recipes {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  min-height: calc(100vh - 90px);
}
@media (min-width: 576px) {
  .meredith-recipes {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .meredith-recipes {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .meredith-recipes {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .meredith-recipes {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.meredith-recipes.error {
  min-height: 300px;
}
.meredith-recipes .mcl {
  margin-left: -10px;
  margin-right: -10px;
}
.meredith-recipes .mcl h1,
.meredith-recipes .mcl h2,
.meredith-recipes .mcl h3,
.meredith-recipes .mcl h4,
.meredith-recipes .mcl h5,
.meredith-recipes .mcl h6 {
  line-height: 1;
}
@media (min-width: 992px) {
  .meredith-recipes {
    min-height: calc(100vh - 141px + 70px);
  }
  .meredith-recipes .mcl:before {
    content: "";
    display: block;
    height: 70px;
    margin-top: -70px;
    pointer-events: none;
    visibility: hidden;
  }
  .healthplan-theme .meredith-recipes .mcl:before {
    height: 60px;
    margin-top: -60px;
  }
}

.message {
  width: 100%;
  margin: 0;
}
.modal-open {
  padding-right: 0 !important;
}

.message .modal-close-btn {
  background-color: transparent;
  border: 0;
  opacity: 1;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 1.625rem;
  top: 1rem;
  transition: opacity 0.1s ease-in-out;
}
.message .modal-close-btn .icon {
  height: 1.5rem;
  fill: #e4e5e6;
  transition: fill 0.2s ease-in-out;
  width: 1.5rem;
}
.message .alert {
  background: #2c373f;
  border: 0;
  border-radius: 0;
  border-bottom: 0.25rem solid #fff;
  margin: 0;
  padding: 1rem 0;
}
.message .alert .alert-content {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  text-align: center;
}
@media (min-width: 576px) {
  .message .alert .alert-content {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .message .alert .alert-content {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .message .alert .alert-content {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .message .alert .alert-content {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.message .alert .alert-icon {
  align-self: center;
  margin-bottom: 0.375rem;
}
.message .alert .alert-icon .icon {
  fill: #ffcd55;
  height: 40px;
  width: 47px;
}
.message .alert .alert-title {
  font-size: 1rem;
  line-height: 1.5;
  color: #ffcd55;
  font-weight: 700;
  margin-bottom: 1rem;
  padding: 0 0.5rem;
}
.message .alert .alert-cta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.message .alert .alert-cta-link {
  font-size: 1rem;
  line-height: 1.125;
  color: #fff;
  font-weight: 600;
  margin: 0 1rem 1rem;
  position: relative;
  text-transform: uppercase;
}
.message .alert .alert-cta-link:hover {
  text-decoration: none;
}
.message .alert .alert-cta-link span {
  padding-bottom: 2px;
  position: relative;
}
.message .alert .alert-cta-link span:after {
  background-color: #ffcd55;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  transition: width 0.25s ease-in-out, opacity 0.25s ease-out;
  width: 100%;
}
.message .modal {
  position: fixed;
  top: 0;
  z-index: 1050;
  padding-right: 0 !important;
}
.message .urgent .modal-content {
  background-color: #2c373f;
  border: 0.375rem solid #2c373f;
  border-radius: 5px;
  padding: 1.75rem 2rem;
  text-align: center;
}
.message .urgent .modal-content .icon-message {
  fill: #b23838;
}
.message .urgent .modal-content .btn, .message .urgent .modal-content .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .message .urgent .modal-content .mktoButton {
  color: #212529;
  background-color: #ffcd55;
  border-color: #ffcd55;
  background-color: #ffcd55;
  border-color: #ffcd55;
  color: #2c373f;
  padding: 0.375rem 0.75rem;
  text-transform: uppercase;
}
.message .urgent .modal-content .btn:hover, .message .urgent .modal-content .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .message .urgent .modal-content .mktoButton:hover {
  color: #fff;
  background-color: transparent;
  border-color: #ffcd55;
}
.message .urgent .modal-content .btn:focus, .message .urgent .modal-content .marketo-form .mktoForm .mktoButton:focus, .marketo-form .mktoForm .message .urgent .modal-content .mktoButton:focus, .message .urgent .modal-content .btn.focus, .message .urgent .modal-content .marketo-form .mktoForm .focus.mktoButton, .marketo-form .mktoForm .message .urgent .modal-content .focus.mktoButton {
  color: #fff;
  background-color: transparent;
  border-color: #ffcd55;
  box-shadow: 0 0 0 0.2rem rgba(222, 180, 78, 0.5);
}
.message .urgent .modal-content .btn.disabled, .message .urgent .modal-content .marketo-form .mktoForm .disabled.mktoButton, .marketo-form .mktoForm .message .urgent .modal-content .disabled.mktoButton, .message .urgent .modal-content .btn:disabled, .message .urgent .modal-content .marketo-form .mktoForm .mktoButton:disabled, .marketo-form .mktoForm .message .urgent .modal-content .mktoButton:disabled {
  color: #212529;
  background-color: #ffcd55;
  border-color: #ffcd55;
}
.message .urgent .modal-content .btn:not(:disabled):not(.disabled):active, .message .urgent .modal-content .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .message .urgent .modal-content .mktoButton:not(:disabled):not(.disabled):active, .message .urgent .modal-content .btn:not(:disabled):not(.disabled).active, .message .urgent .modal-content .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .message .urgent .modal-content .mktoButton:not(:disabled):not(.disabled).active, .show > .message .urgent .modal-content .btn.dropdown-toggle, .show > .message .urgent .modal-content .marketo-form .mktoForm .dropdown-toggle.mktoButton, .marketo-form .mktoForm .show > .message .urgent .modal-content .dropdown-toggle.mktoButton {
  color: #212529;
  background-color: #ffbe22;
  border-color: #ffba15;
}
.message .urgent .modal-content .btn:not(:disabled):not(.disabled):active:focus, .message .urgent .modal-content .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .message .urgent .modal-content .mktoButton:not(:disabled):not(.disabled):active:focus, .message .urgent .modal-content .btn:not(:disabled):not(.disabled).active:focus, .message .urgent .modal-content .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .message .urgent .modal-content .mktoButton:not(:disabled):not(.disabled).active:focus, .show > .message .urgent .modal-content .btn.dropdown-toggle:focus, .show > .message .urgent .modal-content .marketo-form .mktoForm .dropdown-toggle.mktoButton:focus, .marketo-form .mktoForm .show > .message .urgent .modal-content .dropdown-toggle.mktoButton:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 180, 78, 0.5);
}
.message .urgent .modal-content .modal-header {
  border-bottom: none;
  color: #e4e5e6;
  display: block;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  padding: 0;
}
.message .urgent .modal-content .modal-header .icon-alert {
  fill: #ffcd55;
  height: 100px;
  flex: 1 1 auto;
  margin-bottom: 1rem;
  max-width: 116px;
  width: 116px;
}
.message .urgent .modal-content .modal-close-btn {
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
  outline: 0;
}
.message .urgent .modal-content .modal-close-btn svg {
  stroke: #e4e5e6;
}
.message .urgent .modal-content .modal-title {
  font-size: 2rem;
  line-height: 1.1875;
  color: #ffcd55;
  font-weight: 700;
  flex: 1;
  text-transform: uppercase;
}
.message .urgent .modal-content .modal-body {
  font-size: 1.25rem;
  line-height: 1.5;
  color: #e4e5e6;
  font-weight: 400;
  margin-bottom: 1.5rem;
  padding: 0;
}
.message .urgent .modal-content .modal-body a {
  color: #fff;
  text-decoration: underline;
}
.message .urgent .modal-content .modal-body a:hover, .message .urgent .modal-content .modal-body a:focus {
  color: #fff;
  text-decoration: none;
}
.message .urgent .modal-content .modal-footer {
  border-top: none;
  justify-content: center;
  padding: 0;
}
@media (min-width: 576px) {
  .message .modal-close-btn:hover .icon {
    fill: #fff;
  }
  .message .alert .alert-content {
    padding: 0 5rem;
  }
  .message .alert .alert-title {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
  .message .alert .alert-cta {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
  .message .alert .alert-cta-link:hover span:after {
    opacity: 0;
    width: 0;
  }
}
@media (min-width: 768px) {
  .message .alert {
    padding-top: 1.75rem;
  }
  .message .alert .alert-content {
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 70px 0 15px;
    text-align: left;
  }
  .message .alert .alert-icon {
    align-self: unset;
    margin-bottom: 0;
    margin-right: 1rem;
  }
  .message .alert .alert-icon .icon {
    height: 50px;
    width: 58px;
  }
  .message .alert .alert-title {
    margin-bottom: 0.25rem;
    max-width: 500px;
    padding-left: 0;
    padding-right: 2rem;
  }
  .message .alert .alert-cta {
    flex-wrap: nowrap;
  }
  .message .alert .alert-cta-link {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    margin: 0;
    transition: color 0.2s, border-bottom-color 0.2s;
  }
  .message .alert .alert-cta-link:hover {
    border-bottom-color: #2c373f;
    color: #fff;
  }
  .message .alert .alert-cta-link:nth-child(2) {
    margin-left: 1.5rem;
  }
  .message .urgent {
    max-width: 750px;
  }
  .message .urgent .modal-content {
    margin: auto;
    padding: 3.25rem 4rem 2.25rem;
    text-align: left;
  }
  .message .urgent .modal-content .modal-header {
    display: flex;
  }
  .message .urgent .modal-content .modal-header .icon-alert {
    height: 75px;
    margin-bottom: 0;
    margin-right: 2.5rem;
    max-width: 85px;
    width: 85px;
  }
  .message .urgent .modal-content .modal-title {
    margin-top: -7px;
  }
  .message .urgent .modal-content .modal-body {
    font-size: 1.5rem;
    line-height: 1.6666666667;
  }
}
@media (min-width: 992px) {
  .message .alert .alert-content {
    padding-right: 4rem;
  }
  .message .alert .alert-title {
    padding-right: 2rem;
  }
  .message .alert .alert-cta {
    display: flex;
    flex-grow: 1;
    justify-content: space-evenly;
  }
  .message .alert .alert-cta-link {
    white-space: nowrap;
  }
  .message .alert .alert-cta-link:nth-child(2) {
    margin-left: 3rem;
  }
}
@media (min-width: 1200px) {
  .message .alert .alert-content {
    padding-left: 15px;
  }
  .message .alert .alert-title {
    font-size: 1.25rem;
    line-height: 1.2;
  }
  .message .alert .alert-cta {
    justify-content: flex-end;
    justify-self: flex-end;
  }
  .message .alert .alert-cta-link {
    font-size: 1.25rem;
    line-height: 1.2;
  }
  .message .alert .alert-cta-link:nth-child(2) {
    margin-left: 5rem;
  }
}
@media (min-width: 1440px) {
  .message .alert .alert-content {
    padding-right: 15px;
  }
}

.nav-accordion {
  background-color: #fff;
  position: relative;
}
.nav-accordion.flush .accordion-item > .accordion-item-title > .accordion-item-toggle .icon {
  display: none;
}
.nav-accordion .accordion-item {
  border-bottom: 1px solid #cbced1;
  padding: 0;
}
.nav-accordion .accordion-item[aria-expanded=true] > .accordion-item-title .accordion-item-toggle {
  color: #2c373f;
  font-weight: 600;
}
.nav-accordion .accordion-item[aria-expanded=true] > .accordion-item-title .accordion-item-toggle .icon-arrow-down {
  transform: rotate(180deg) translateY(50%);
}
.nav-accordion .accordion-item[aria-expanded=true] > .accordion-item-title .accordion-item-tags {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  width: 100%;
}
.nav-accordion .accordion-item[aria-expanded=true] > .accordion-item-body {
  display: block;
  height: auto;
}
.nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle {
  color: #3d5d6b;
  font-weight: 300;
}
.nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle:hover, .nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle:focus {
  color: #006D9D;
}
.nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle .icon-arrow-down {
  transform: translateY(-50%);
}
.nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle .label-secondary {
  opacity: 1;
}
.nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-tags {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}
.nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-body {
  overflow: hidden;
  transition: height 0.4s ease-in-out;
}
.nav-accordion .accordion-item[aria-expanded=true].opening > .accordion-item-body {
  overflow: hidden;
  transition: height 0.4s ease-in-out;
}
.nav-accordion .accordion-item .accordion-item-title {
  position: relative;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  background: none;
  border: none;
  color: #3d5d6b;
  display: flex;
  font-weight: 300;
  margin: 0;
  padding: 1rem 2.5rem 1rem 1rem;
  position: relative;
  text-align: left;
  width: 100%;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:hover, .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:focus {
  color: #3d5d6b;
  outline: 0;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:hover .icon-arrow-down, .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:focus .icon-arrow-down {
  fill: #3d5d6b;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .accordion-item-label {
  white-space: nowrap;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .label-secondary {
  color: #006D9D;
  font-weight: 300;
  padding-left: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity 0.4s ease-in-out;
  white-space: nowrap;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .icon-arrow-down {
  fill: #006D9D;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-tags {
  background-color: #fff;
  transition: opacity 0.2s ease-in-out;
  z-index: 1;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-tags .tag-list {
  padding: 0 1rem 0.5rem;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-tags .tag-list:last-of-type {
  padding-bottom: 1rem;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-tags .tag-list .tag:first-of-type {
  padding-top: 0;
}
.nav-accordion .accordion-item .accordion-item-body {
  display: none;
  height: 0;
}
.nav-accordion .accordion-item .accordion-item-body .accordion-item-body-inner {
  padding: 0 1rem 1rem;
}
@media (min-width: 992px) {
  .nav-accordion.flush .accordion-item > .accordion-item-title > .accordion-item-toggle,
.nav-accordion.flush .accordion-item > .accordion-item-body > .accordion-item-body-inner {
    padding-left: 0;
    padding-right: 0;
  }
  .nav-accordion .accordion-item .accordion-item-body .accordion-item-body-inner > .rbt {
    margin-left: -1rem;
    width: calc(100% + 2rem);
  }
}

.news-list {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .news-list {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .news-list {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .news-list {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .news-list {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.news-list .news-item {
  background-color: #f2f9ff;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-bottom: 2rem;
  min-height: 325px;
  position: relative;
}
.news-list .news-item-content {
  padding: 2rem;
  width: 100%;
}
.news-list .news-img {
  height: 265px;
  width: 100%;
}
.news-list .news-img img,
.news-list .news-img source {
  height: 100%;
  object-fit: cover;
  object-position: center 40%;
  font-family: "object-fit: cover; object-position: center 40%;";
  width: 100%;
}
.news-list .type-and-location {
  display: flex;
  margin-bottom: 0.75rem;
}
.news-list .news-type,
.news-list .news-location {
  font-size: 1rem;
  line-height: 1.125;
  color: #3d5d6b;
  font-weight: 600;
  margin: 0;
  position: relative;
}
.news-list .news-type:not(:first-of-type),
.news-list .news-location:not(:first-of-type) {
  margin-left: 0.625rem;
  padding-left: 0.625rem;
}
.news-list .news-type:not(:first-of-type):before,
.news-list .news-location:not(:first-of-type):before {
  content: "|";
  display: inline-block;
  left: 0;
  padding-right: 0;
  position: absolute;
  transform: translate(-50%, 0px);
}
.news-list .news-title {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #2c373f;
  font-weight: 700;
  margin-bottom: 1rem;
}
.news-list .news-description {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #2c373f;
  font-weight: 400;
  margin: 0;
}
.news-list .read-more-link {
  color: #006D9D;
}
.news-list .view-all-link {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #006D9D;
  display: inline-block;
  font-weight: 700;
  margin-top: 1.75rem;
  padding: 0;
}
@media (min-width: 768px) {
  .news-list .news-listings {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
  }
  .news-list .news-item {
    flex: 0 1 48.5%;
    margin-bottom: 4rem;
    max-width: 48.5%;
    min-height: 485px;
  }
}
@media (min-width: 992px) {
  .news-list .news-item {
    flex: 0 1 48.5%;
    max-width: 48.5%;
  }
}

.one-up {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .one-up {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .one-up {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .one-up {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .one-up {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.one-up .one-up-inner {
  background-color: #fff;
  margin-bottom: 1rem;
  max-width: 100%;
}
.one-up .one-up-title {
  font-size: 1.75rem;
  line-height: 1.2142857143;
  color: #2c373f;
  margin-top: 0;
}
.one-up p {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #3d5d6b;
  font-weight: 600;
}
.one-up .btn, .one-up .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .one-up .mktoButton {
  padding-left: 3rem;
  padding-right: 3rem;
}
.one-up .one-up-content {
  margin-bottom: 1rem;
}
.one-up .card .card-img-top {
  display: none;
}
.one-up .card .card-body .card-img-top {
  display: block;
}
.one-up .one-up-img {
  order: 2;
  border-radius: 5px;
  margin-bottom: 1rem;
}
.one-up .one-up-img img,
.one-up .one-up-img source {
  object-fit: cover;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%;";
  height: 15rem;
  width: 100%;
}
.one-up .one-up-content-container {
  flex: auto;
  max-width: 100%;
  padding: 2rem;
}
@media (min-width: 576px) {
  .one-up .one-up-img {
    height: 18rem;
  }
  .one-up .one-up-img img,
.one-up .one-up-img source {
    height: 18rem;
  }
}
@media (min-width: 768px) {
  .two-column .one-up .one-up-inner {
    padding-top: 0;
    padding-left: 0;
  }
  .two-column .one-up .card .card-img-top {
    display: none;
  }
  .two-column .one-up .card .card-body .card-img-top {
    display: block;
  }
  .two-column .one-up .one-up-content-container {
    flex-direction: column;
    margin-bottom: 6rem;
  }
  .two-column .one-up .one-up-img {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    margin-top: 0;
    margin-bottom: 1rem;
    margin-left: 0;
    height: 15rem;
    max-height: none;
    min-height: 0;
  }
  .two-column .one-up .one-up-img img,
.two-column .one-up .one-up-img source {
    height: 15rem;
    max-height: none;
    min-height: 0;
  }
  .two-column .one-up .one-up-content {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 2.5rem 2rem;
  }
  .two-column .one-up .one-up-title {
    font-size: 1.75rem;
    line-height: 1.2142857143;
  }
  .two-column .one-up .divider {
    margin: 1rem auto 1rem 0;
  }
  .two-column .one-up.right .one-up-content-container {
    flex-direction: column;
  }
  .one-up.right .one-up-inner {
    padding-left: 0;
    padding-right: 2.5rem;
  }
  .one-up.right .one-up-content-container {
    flex-direction: row;
  }
  .one-up.right .one-up-img {
    margin-left: auto;
  }
  .one-up .one-up-content {
    margin-bottom: 1rem;
  }
  .one-up .card .card-img-top {
    display: block;
  }
  .one-up .card .card-body .card-img-top {
    display: none;
  }
  .one-up .one-up-inner {
    padding-left: 1.5rem;
  }
  .one-up .one-up-title {
    font-size: 2rem;
    line-height: 1.0625;
    margin-bottom: 0;
  }
  .one-up .divider {
    margin: 1.75rem auto 1.75rem 0;
  }
  .one-up p {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    color: #3d5d6b;
  }
  .one-up .one-up-content-container {
    flex-direction: row-reverse;
    padding: 0;
    margin-bottom: 2rem;
  }
  .one-up .one-up-img {
    height: auto;
    min-height: 19rem;
    margin-right: auto;
    order: 1;
    flex: 0 0 calc(50% + 1rem);
    max-width: calc(50% + 1rem);
    width: calc(50% + 1rem);
  }
  .one-up .one-up-img img,
.one-up .one-up-img source {
    height: 100%;
    max-height: 340px;
    min-height: 19rem;
  }
  .one-up .one-up-content {
    align-self: center;
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 2.5rem 2rem;
  }
}
@media (min-width: 992px) {
  .two-column .one-up .one-up-inner {
    padding-top: 1.5rem;
  }
  .two-column .one-up .one-up-content-container {
    flex-direction: row-reverse;
    margin-bottom: 3rem;
  }
  .two-column .one-up .one-up-content {
    margin-bottom: 0;
    padding: 2.5rem 2rem;
  }
  .two-column .one-up .one-up-img {
    height: auto;
    min-height: 16rem;
    margin-right: auto;
    margin-bottom: 2rem;
    order: 1;
  }
  .two-column .one-up .one-up-img img,
.two-column .one-up .one-up-img source {
    height: 100%;
    max-height: 20rem;
    min-height: 16rem;
  }
  .two-column .one-up.right .one-up-inner {
    padding-left: 0;
    padding-right: 1.5rem;
  }
  .two-column .one-up.right .one-up-content-container {
    flex-direction: row;
  }
  .two-column .one-up.right .one-up-img {
    margin-left: auto;
    margin-right: 0;
  }
  .one-up.right .one-up-inner {
    padding-left: 0;
    padding-right: 4rem;
  }
  .one-up.right .one-up-img {
    margin-left: auto;
    margin-right: -2.5rem;
  }
  .one-up.right .one-up-content {
    padding: 3rem 0 3rem 3rem;
  }
  .one-up .one-up-content-container {
    margin-bottom: 3rem;
  }
  .one-up .one-up-inner {
    padding-left: 4rem;
  }
  .one-up .one-up-img {
    min-height: 24rem;
    margin-left: -3.5rem;
    margin-right: auto;
    flex: 0 0 calc(50% + 3rem);
    max-width: calc(50% + 3rem);
    width: calc(50% + 3rem);
  }
  .one-up .one-up-img img,
.one-up .one-up-img source {
    max-height: 400px;
    min-height: 24rem;
  }
  .one-up .one-up-content {
    flex: 0 0 calc(50% - 3rem);
    max-width: calc(50% - 3rem);
    padding: 3rem 3rem 3rem 0;
  }
}
@media (min-width: 1200px) {
  .two-column .one-up .one-up-inner {
    padding-top: 3rem;
  }
  .two-column .one-up .card .card-img-top {
    display: block;
  }
  .two-column .one-up .card .card-body .card-img-top {
    display: none;
  }
  .two-column .one-up .one-up-img {
    margin-left: -1rem;
    margin-bottom: 0;
    min-height: 20rem;
    flex: 0 0 calc(50% + 2rem);
    max-width: calc(50% + 2rem);
    width: calc(50% + 2rem);
  }
  .two-column .one-up .one-up-img img,
.two-column .one-up .one-up-img source {
    max-height: 22rem;
    min-height: 20rem;
  }
  .two-column .one-up .one-up-content {
    flex: 0 0 calc(50% - 3rem);
    max-width: calc(50% - 3rem);
    padding: 0.5rem 2.5rem 2.5rem 0;
  }
  .two-column .one-up.right .one-up-inner {
    padding-left: 0;
    padding-right: 3rem;
  }
  .two-column .one-up.right .one-up-img {
    margin-right: -2rem;
  }
  .two-column .one-up.right .one-up-content {
    padding: 2.5rem 0 2.5rem 2.5rem;
  }
  .one-up.right .one-up-content {
    padding-left: 5rem;
  }
  .one-up .one-up-content {
    padding-right: 5rem;
  }
}

.pagination-controls {
  margin-bottom: 1.5rem;
  transition: opacity 0.4s ease-in-out;
}
.pagination-controls .pagination-counts {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.pagination-controls .showing-vs-total {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #3d5d6b;
}
.pagination-controls .pagination-nav {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.pagination-controls .pagination-nav .showing-vs-total {
  display: none;
}
.pagination-controls .pagination .page-item {
  margin-right: 0.5rem;
}
.pagination-controls .pagination .page-item.active .page-link {
  cursor: default;
  background-color: #2c373f;
  border-color: #2c373f;
}
.pagination-controls .pagination .page-item.disabled:first-child .page-link, .pagination-controls .pagination .page-item.disabled:last-child .page-link {
  background-color: #e4e5e6;
}
.pagination-controls .pagination .page-item.disabled .page-link .icon {
  stroke: #cbced1;
}
.pagination-controls .pagination .page-item .page-link {
  font-size: 1rem;
  line-height: 1.125;
  border-radius: 5px;
  font-weight: 700;
}
.pagination-controls .pagination .page-item .page-link:hover .icon {
  stroke: #0056b3;
}
.pagination-controls .pagination .page-item .page-link span {
  align-items: center;
  display: flex;
  height: 17px;
}
.pagination-controls .pagination .page-item .page-link .icon {
  height: 0.75rem;
  margin-top: -2px;
  stroke: #2c373f;
  width: 0.5rem;
}
.pagination-controls .pagination .page-item:last-child {
  margin-right: 0;
}
@media (min-width: 768px) {
  .pagination-controls {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
  }
  .pagination-controls .pagination-nav {
    align-items: center;
    display: flex;
    margin-bottom: 0;
    width: auto;
  }
  .pagination-controls .pagination-nav .showing-vs-total {
    display: inline-block;
    padding-right: 1rem;
  }
  .pagination-controls .pagination-nav .pagination {
    margin-bottom: 0;
  }
  .pagination-controls .pagination-counts {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    margin-bottom: 0;
  }
  .pagination-controls .pagination-counts .showing-vs-total {
    display: none;
  }
}

.page-header {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .page-header {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .page-header {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .page-header {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .page-header {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.page-header .page-header-inner {
  background-color: #fff;
  margin-bottom: 1rem;
  padding-top: 1rem;
  position: relative;
}
.page-header .breadcrumb {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  background-color: #fff;
  color: #006D9D;
  font-weight: 700;
  margin-bottom: 0.625rem;
  padding-bottom: 0;
  padding-left: 0;
}
.page-header .breadcrumb-item {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  color: #006D9D;
  font-weight: 700;
  padding-left: 0;
}
.page-header .breadcrumb-item::before {
  display: none;
}
.page-header .breadcrumb-item:not(:last-child) {
  display: none;
}
.page-header .breadcrumb-item > a {
  color: #006D9D;
}
.page-header .breadcrumb-item > a > .icon {
  fill: #3579ad;
  margin-top: -0.15rem;
  padding-right: 0.5rem;
  width: 1.5rem;
}
.page-header .divider {
  border-top: 0;
  margin-left: auto;
  margin-top: 0.75rem;
  margin-right: auto;
}
.page-header .heading-1 {
  font-size: 2rem;
  line-height: 1.0625;
  color: #2c373f;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0.5rem;
}
.page-header .social {
  display: none;
  float: right;
  padding-top: 0.5rem;
  padding-left: 3rem;
}
.page-header .language-dropdown {
  position: absolute;
  right: 0;
  top: 26px;
}
@media (min-width: 768px) {
  .page-header .breadcrumb {
    font-size: 1rem;
    line-height: 1.125;
  }
  .page-header .breadcrumb-item {
    font-size: 1rem;
    line-height: 1.125;
    color: #006D9D;
    font-weight: 600;
    padding-left: 0.625rem;
  }
  .page-header .breadcrumb-item::before {
    color: #a2c3d6;
    display: inline-block;
  }
  .page-header .breadcrumb-item:not(:last-child) {
    display: block;
  }
  .page-header .breadcrumb-item:first-child {
    padding-left: 0.125rem;
  }
  .page-header .breadcrumb-item > a > .icon {
    display: none;
  }
  .page-header .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 0.625rem;
  }
  .page-header .divider {
    margin-left: 0;
  }
  .page-header .heading-1 {
    font-size: 2rem;
    line-height: 1.0625;
    text-align: left;
    margin-bottom: 0.5rem;
  }
  .page-header .social {
    display: block;
  }
  .page-header .language-dropdown {
    top: 64px;
  }
  .page-header .language-dropdown + .heading-1 {
    padding-right: 100px;
  }
}
@media (min-width: 992px) {
  .page-header .page-header-inner {
    background-color: #fff;
    margin-bottom: 1rem;
    padding-top: 1.5rem;
  }
  .page-header .breadcrumb {
    margin-bottom: 1.25rem;
  }
  .page-header .heading-1 {
    font-size: 2.25rem;
    line-height: 1.1111111111;
    margin-bottom: 0.5rem;
  }
  .page-header .divider {
    border-top: 0;
    margin-left: 0;
    margin-top: 1rem;
    margin-right: auto;
  }
  .page-header .language-dropdown {
    top: 81px;
  }
}

.podcast-player .podcast-player--container {
  max-width: 845px;
  margin: 0 auto;
  padding-bottom: 2.5rem;
}
.podcast-player .audio-player {
  padding: 10px 0 0;
  margin-bottom: 22px;
  display: flow-root;
}
.podcast-player .audio-player .audio-player--controls {
  display: flex;
  justify-content: center;
  margin-top: 22px;
  position: relative;
}
.podcast-player .audio-player .audio-player--controls .navigation-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}
.podcast-player .audio-player .audio-player--controls .navigation-controls .play-button {
  background: #ffcd55;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 30px;
  border: none;
}
.podcast-player .audio-player .audio-player--controls .navigation-controls .play-button .play-button--svg {
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 32px;
  border-color: transparent transparent transparent #3d5d6b;
  transition: 300ms opacity ease;
  cursor: pointer;
  border-style: solid;
  border-width: 16px 0 16px 24px;
  margin-left: 5px;
}
.podcast-player .audio-player .audio-player--controls .navigation-controls .play-button.playing .play-button--svg {
  border-width: 0px 7px 0px 7px;
  margin-left: 0px;
  width: 25px;
  border-color: transparent #3d5d6b;
  transition: 300ms all ease;
}
.podcast-player .audio-player .audio-player--controls .navigation-controls .play-button:hover .play-button--svg {
  opacity: 0.9;
}
.podcast-player .audio-player .audio-player--controls .navigation-controls .play-button:focus .play-button--svg {
  outline: none;
}
.podcast-player .audio-player .audio-player--controls .navigation-controls .skip-icon {
  height: 26px;
  width: 26px;
  cursor: pointer;
  stroke-width: 0px;
}
.podcast-player .audio-player .audio-player--controls .volume-control {
  display: none;
  align-items: center;
  gap: 10px;
}
.podcast-player .audio-player .audio-player--controls .volume-control #volume-slider input {
  width: 87px;
}
.podcast-player .audio-player .audio-player--controls .volume-control #mute-button {
  cursor: pointer;
}
.podcast-player .audio-player .audio-player--controls .volume-control #mute-button svg {
  width: 27px;
  height: 23px;
  stroke-width: 0px;
}
.podcast-player .audio-player .audio-player--controls .podcast--overflow {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 0;
}
.podcast-player .audio-player .audio-player--controls .podcast--overflow .dropdown-toggle:after {
  content: none;
}
.podcast-player .audio-player .audio-player--controls .podcast--overflow .podcast--overflow-button {
  float: right;
  width: 30px;
}
.podcast-player .audio-player .audio-player--controls .podcast--overflow .podcast--overflow-button svg {
  width: 26px;
  stroke-width: 0px;
}
.podcast-player .audio-player .audio-player--controls .podcast--overflow .dropdown-menu {
  top: 30px;
  left: auto;
  right: 0;
  width: 170px;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.1);
  border-color: #fff;
}
.podcast-player .audio-player .audio-player--controls .podcast--overflow .dropdown-menu .dropdown-menu {
  top: -1px;
  right: -1px;
}
.podcast-player .audio-player .audio-player--controls .podcast--overflow .dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  font-size: 12px;
  line-height: initial;
  font-weight: 700;
  height: 40px;
  padding: 0 0 0 22px;
  background: #fff;
}
.podcast-player .audio-player .audio-player--controls .podcast--overflow .dropdown-item.btn-group {
  padding: 0;
  position: initial;
}
.podcast-player .audio-player .audio-player--controls .podcast--overflow .dropdown-item.btn-group .sub-menu svg {
  width: 7px;
  height: 14px;
}
.podcast-player .audio-player .audio-player--controls .podcast--overflow .dropdown-item svg,
.podcast-player .audio-player .audio-player--controls .podcast--overflow .dropdown-item .icon {
  width: 20px;
  height: 20px;
  stroke-width: 0px;
}
.podcast-player .audio-player .audio-player--controls .podcast--overflow .dropdown-item:hover {
  background: #a2c3d6;
  color: #3d5d6b;
}
.podcast-player .audio-player .audio-player--controls .podcast--overflow .dropdown-item.playback-speed-option {
  font-weight: 400;
  font-size: 14px;
}
.podcast-player .audio-player .audio-player--controls .podcast--overflow .dropdown-item.active {
  font-weight: 700;
  background: #fff;
  color: #3d5d6b;
  position: relative;
}
.podcast-player .audio-player .audio-player--controls .podcast--overflow .dropdown-item.active::after {
  border-bottom: 3px solid #f7aa00;
  border-right: 3px solid #f7aa00;
  content: "";
  display: block;
  margin: 0.25rem 0.375rem;
  position: absolute;
  top: 7px;
  right: 29px;
  -webkit-transform: rotate(45deg) translate(-1px, -1px);
  -ms-transform: rotate(45deg) translate(-1px, -1px);
  transform: rotate(45deg) translate(-1px, -1px);
  width: 0.5rem;
  height: 1rem;
}
.podcast-player .audio-player .audio-player--controls input[type=range]::-webkit-slider-runnable-track {
  background: #cbced1;
}
.podcast-player .audio-player .audio-player--controls input[type=range]::before {
  background: #3d5d6b;
}
.podcast-player .audio-player .audio-player--controls input[type=range]::-webkit-slider-thumb {
  border: 1px solid #3d5d6b;
  background-color: #3d5d6b;
}
.podcast-player .audio-player .audio-player--controls input[type=range]:active::-webkit-slider-thumb {
  background: #3d5d6b;
}
.podcast-player .audio-player .audio-player--controls input[type=range]::-moz-range-track {
  background: #cbced1;
}
.podcast-player .audio-player .audio-player--controls input[type=range]::-moz-range-progress {
  background-color: #3d5d6b;
  height: 7px;
  border-radius: 4px 0 0 4px;
}
.podcast-player .audio-player .audio-player--controls input[type=range]::-moz-range-thumb {
  border: 1px solid #3d5d6b;
  background-color: #3d5d6b;
}
.podcast-player .audio-player .audio-player--controls input[type=range]:active::-moz-range-thumb {
  background: #3d5d6b;
}
.podcast-player .audio-player .audio-player--controls input[type=range]::-ms-fill-lower {
  background-color: #3d5d6b;
}
.podcast-player .audio-player .audio-player--controls input[type=range]::-ms-fill-upper {
  background: linear-gradient(to right, #3d5d6b var(--buffered-width), #cbced1 var(--buffered-width));
}
.podcast-player .audio-player .audio-player--controls input[type=range]::-ms-thumb {
  border: 1px solid #3d5d6b;
  background-color: #3d5d6b;
}
.podcast-player .audio-player .audio-player--controls input[type=range]:active::-ms-thumb {
  background: #3d5d6b;
}
.podcast-player .audio-player .audio-player--progress {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 19px;
}
.podcast-player .audio-player .audio-player--progress input[type=range] {
  width: 100%;
}
.podcast-player .audio-player .audio-player--progress input[type=range]::-webkit-slider-runnable-track {
  background: #cbced1;
}
.podcast-player .audio-player .audio-player--progress input[type=range]::before {
  background-color: #ffcd55;
}
.podcast-player .audio-player .audio-player--progress input[type=range]::-webkit-slider-thumb {
  border: 1px solid #ffcd55;
  background-color: #ffcd55;
}
.podcast-player .audio-player .audio-player--progress input[type=range]:active::-webkit-slider-thumb {
  background: #ffcd55;
}
.podcast-player .audio-player .audio-player--progress input[type=range]::-moz-range-track {
  background: #cbced1;
}
.podcast-player .audio-player .audio-player--progress input[type=range]::-moz-range-progress {
  background-color: #ffcd55;
  height: 7px;
  border-radius: 4px 0 0 4px;
}
.podcast-player .audio-player .audio-player--progress input[type=range]::-moz-range-thumb {
  border: 1px solid #ffcd55;
  background-color: #ffcd55;
}
.podcast-player .audio-player .audio-player--progress input[type=range]:active::-moz-range-thumb {
  background: #ffcd55;
}
.podcast-player .audio-player .audio-player--progress input[type=range]::-ms-fill-lower {
  background-color: #cbced1;
}
.podcast-player .audio-player .audio-player--progress input[type=range]::-ms-fill-upper {
  background: linear-gradient(to right, #cbced1 var(--buffered-width), #a2c3d6 var(--buffered-width));
}
.podcast-player .audio-player .audio-player--progress input[type=range]::-ms-thumb {
  border: 1px solid #cbced1;
  background-color: #cbced1;
}
.podcast-player .audio-player .audio-player--progress input[type=range]:active::-ms-thumb {
  background: #cbced1;
}
.podcast-player .audio-player input[type=range] {
  position: relative;
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  height: 17px;
  margin-left: 0;
  float: left;
  outline: none;
}
.podcast-player .audio-player input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  border-radius: 4px;
}
.podcast-player .audio-player input[type=range]::before {
  position: absolute;
  content: "";
  top: 5px;
  left: 0;
  width: var(--range-before-width);
  height: 7px;
  cursor: pointer;
  border-radius: 4px;
}
.podcast-player .audio-player input[type=range]::-webkit-slider-thumb {
  position: relative;
  -webkit-appearance: none;
  box-sizing: content-box;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  cursor: pointer;
  margin: -3px 0 0 0;
  transform: scale(1.4);
}
.podcast-player .audio-player input[type=range]:active::-webkit-slider-thumb {
  transform: scale(1.6);
}
.podcast-player .audio-player input[type=range]::-moz-range-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  border-radius: 4px;
}
.podcast-player .audio-player input[type=range]::-moz-focus-outer {
  border: 0;
  border-radius: 4px;
}
.podcast-player .audio-player input[type=range]::-moz-range-thumb {
  box-sizing: content-box;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  cursor: pointer;
}
.podcast-player .audio-player input[type=range]:active::-moz-range-thumb {
  transform: scale(1.4);
}
.podcast-player .audio-player input[type=range]::-ms-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  background: transparent;
  border: solid transparent;
  color: transparent;
  border-radius: 4px;
}
.podcast-player .audio-player input[type=range]::-ms-thumb {
  position: relative;
  -webkit-appearance: none;
  box-sizing: content-box;
  height: 11px;
  width: 11px;
  cursor: pointer;
  margin: -3px 0 0 0;
  transform: scale(1.4);
  border-radius: 50%;
}
.podcast-player .audio-player input[type=range]:active::-ms-thumb {
  transform: scale(1.6);
}
.podcast-player .podcast--transcript-drawer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.podcast-player .podcast--transcript-drawer.active #podcast--transcript {
  display: block;
}
.podcast-player .podcast--transcript-drawer #podcast--transcript {
  display: none;
}
.podcast-player .podcast--transcript-drawer #podcast--transcript .podcast--transcript-title-line {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 26px;
}
.podcast-player .podcast--transcript-drawer #podcast--transcript .podcast--transcript-title-line .podcast--transcript-title {
  margin-bottom: 10px;
}
.podcast-player .podcast--transcript-drawer #podcast--transcript .podcast--transcript-title-line .podcast--transcript-popout span {
  color: #006D9D;
  text-decoration: underline;
}
.podcast-player .podcast--transcript-drawer #podcast--transcript .podcast--transcript-title-line .podcast--transcript-popout svg {
  fill: #006D9D;
  margin-left: 3px;
  height: 19px;
  width: 19px;
}
.podcast-player .podcast--transcript-drawer #podcast--transcript .podcast--transcript-title-line .podcast--transcript-popout:hover span {
  color: #3d5d6b;
}
.podcast-player .podcast--transcript-drawer #podcast--transcript .podcast--transcript-title-line .podcast--transcript-popout:hover svg {
  fill: #3d5d6b;
}
.podcast-player .podcast--transcript-drawer #podcast--transcript .podcast--transcript-content {
  max-height: none;
  padding: 0 30px 0 0;
  margin-top: 34px;
}
.podcast-player .podcast--transcript-drawer #podcast--transcript ::-webkit-scrollbar {
  height: 7px;
  width: 7px;
  overflow: hidden;
}
.podcast-player .podcast--transcript-drawer #podcast--transcript ::-webkit-scrollbar-track {
  border-radius: 4px;
}
.podcast-player .podcast--transcript-drawer #podcast--transcript ::-webkit-scrollbar-thumb {
  border: 0;
  border-radius: 4px;
}

/** SPACINGS **/
.bk--dark-teal + .bk--dark-teal,
.bk--white + .bk--white {
  padding-top: 0 !important;
}

@media (min-width: 768px) {
  .podcast-player .audio-player {
    padding: 0;
    margin-bottom: 37px;
  }
  .podcast-player .audio-player .audio-player--progress {
    padding-top: 13px;
  }
  .podcast-player .audio-player .audio-player--progress div {
    font-size: 16px;
  }
  .podcast-player .audio-player .audio-player--controls {
    justify-content: space-between;
  }
  .podcast-player .audio-player .audio-player--controls > div {
    min-width: 130px;
  }
  .podcast-player .audio-player .audio-player--controls .volume-control {
    display: flex;
  }
  .podcast-player .audio-player .audio-player--controls .podcast--overflow {
    position: relative;
  }
  .podcast-player .podcast--transcript-drawer #podcast--transcript .podcast--transcript-title-line {
    margin-bottom: 7px;
    flex-wrap: nowrap;
  }
  .podcast-player .podcast--transcript-drawer #podcast--transcript .podcast--transcript-title-line a {
    white-space: nowrap;
    padding-left: 30px;
  }
  .podcast-player .podcast--transcript-drawer #podcast--transcript .podcast--transcript-content {
    max-height: none;
    margin-top: 37px;
  }
}
.price-estimates {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
@media (min-width: 576px) {
  .price-estimates {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .price-estimates {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .price-estimates {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .price-estimates {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.price-estimates .insurance-btns-container {
  display: flex;
  margin: 0.5rem 0 1.5rem;
}
.price-estimates .insured-btn,
.price-estimates .uninsured-btn {
  width: 50%;
}
.price-estimates .insured-btn:first-of-type,
.price-estimates .uninsured-btn:first-of-type {
  margin-right: 1rem;
}
.price-estimates .insured-btn[aria-expanded=true],
.price-estimates .uninsured-btn[aria-expanded=true] {
  background-color: #2c373f;
  border-color: #2c373f;
}
.price-estimates .insured-panel,
.price-estimates .uninsured-panel {
  display: none;
}
.price-estimates .insured-panel[aria-hidden=false],
.price-estimates .uninsured-panel[aria-hidden=false] {
  display: block;
  margin-top: 2rem;
}
.price-estimates .csv-table {
  padding-left: 0;
  padding-right: 0;
}
.price-estimates .options .col-lg-4 {
  margin-bottom: 1rem;
}
.price-estimates .options .heading-5 {
  color: #2c373f;
}
.price-estimates .options .btn, .price-estimates .options .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .price-estimates .options .mktoButton {
  width: 100%;
}
.price-estimates .options .btn:not(:last-child), .price-estimates .options .marketo-form .mktoForm .mktoButton:not(:last-child), .marketo-form .mktoForm .price-estimates .options .mktoButton:not(:last-child) {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .price-estimates .insurance-btns-container {
    display: block;
  }
  .price-estimates .insured-btn,
.price-estimates .uninsured-btn {
    width: auto;
  }
}

.appointments {
  margin: 32px calc(30px / -2);
  padding: 0 1rem;
  margin: 0;
  padding: 0;
}
.appointments .icon {
  fill: #fff;
}
.appointments .btn, .appointments .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .appointments .mktoButton {
  line-height: 1;
}
.appointments .date-range-selection .btn, .appointments .date-range-selection .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .appointments .date-range-selection .mktoButton {
  padding: 0.5rem 1rem;
  /* override bootstrap -1px margin-left on .btn-group>.btn:not(:first-child) */
  margin-left: 0;
}
.appointments .select-date[type=text] {
  background-color: #fff;
  border-color: #fff;
  color: #a2c3d6;
  padding: 1px;
  text-align: center;
  width: 145px;
}
.appointments .select-date[type=text]:hover {
  border-color: #a2c3d6;
}
.appointments .select-date[type=text]:focus {
  border-color: transparent;
}
.appointments .day-label {
  font-size: 1rem;
  line-height: 1.125;
  color: #2c373f;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
}
.appointments .appointments-inner {
  background-color: #f2f9ff;
  height: 100%;
  display: block;
  text-align: center;
  padding: 1.5rem;
  border-radius: 5px;
}
.appointments .appointments-inner .form-control, .appointments .appointments-inner .share-story-modal form input[type=text], .share-story-modal form .appointments .appointments-inner input[type=text],
.appointments .appointments-inner .share-story-modal form input[type=email],
.share-story-modal form .appointments .appointments-inner input[type=email],
.appointments .appointments-inner .share-story-modal form input[type=tel],
.share-story-modal form .appointments .appointments-inner input[type=tel],
.appointments .appointments-inner .share-story-modal form select,
.share-story-modal form .appointments .appointments-inner select,
.appointments .appointments-inner .share-story-modal form textarea,
.share-story-modal form .appointments .appointments-inner textarea, .appointments .appointments-inner textarea, .appointments .appointments-inner .bmi-calculator input[type=text], .bmi-calculator .appointments .appointments-inner input[type=text],
.appointments .appointments-inner .bmi-calculator select,
.bmi-calculator .appointments .appointments-inner select, .appointments .appointments-inner .marketo-form .mktoForm .mktoEmailField, .marketo-form .mktoForm .appointments .appointments-inner .mktoEmailField,
.appointments .appointments-inner .marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .appointments .appointments-inner .mktoTelField,
.appointments .appointments-inner .marketo-form .mktoForm .mktoTextField,
.marketo-form .mktoForm .appointments .appointments-inner .mktoTextField, .appointments .appointments-inner .marketo-form .mktoForm select, .marketo-form .mktoForm .appointments .appointments-inner select,
.appointments .appointments-inner .marketo-form .mktoForm textarea,
.marketo-form .mktoForm .appointments .appointments-inner textarea {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}
.appointments .appointments-inner .appt-covid-message {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-weight: 600;
  margin-bottom: 0.375rem;
  padding-top: 1.5rem;
}
.appointments .appointments-inner .warning-message {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #8b0000;
  font-weight: 700;
  margin-bottom: 0.375rem;
  padding-top: 1.5rem;
}
.appointments .appointment-title {
  font-size: 1.25rem;
  line-height: 1.2;
  margin-bottom: 2rem;
}
.appointments .appointment-request-btn {
  margin-bottom: 1.5rem;
}
.appointments .disclaimer {
  font-size: 1.125rem;
  line-height: 1.6666666667;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}
.appointments .disclaimer a {
  font-weight: 700;
}
.appointments .r1-question {
  margin-bottom: 1rem;
}
.appointments .form-group {
  display: flex;
  justify-content: center;
}
.appointments .form-group .radio-wrapper label, .appointments .form-group .marketo-form .mktoForm .mktoRadioList label, .marketo-form .mktoForm .appointments .form-group .mktoRadioList label {
  padding: 0 0 0 0.5rem;
}
.appointments .appointment-selection {
  padding: 2rem 0 1rem;
  position: relative;
  width: 100%;
}
.appointments .appointment-selection.is-loading .day-list-buttons {
  position: relative;
}
.appointments .appointment-selection.is-loading .day-list-buttons:after {
  border: 2px solid #006D9D;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  content: " ";
  display: block;
  pointer-events: none;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  animation: spin 1.4s infinite linear;
  position: relative;
  left: 10px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.appointments .appointment-selection.is-loading .day-list-buttons:after {
  left: 50%;
  margin-left: -13px;
  margin-top: -13px;
  position: absolute;
  top: 50%;
}
.appointments .appointment-selection.no-appointments .no-appointments-message {
  opacity: 1;
}
.appointments .appointment-selection .no-appointments-message {
  color: #a2c3d6;
  margin-left: 10%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transition: opacity, 0.4s;
  width: 80%;
}
.appointments .appointment-selection .icon {
  stroke: #fff;
}
.appointments .day-list {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0;
}
.appointments .day-list > li {
  display: flex;
  flex-basis: 25%;
  flex-direction: column;
  opacity: 0.3;
  transition: opacity 0.4s;
}
.appointments .day-list > li.has-appointments {
  opacity: 1;
  pointer-events: auto;
}
.appointments .day-list > li.has-appointments .btn-select-time:disabled {
  opacity: 0.3;
}
.appointments .day-list > li:nth-child(n+4) {
  display: none;
}
.appointments .day-list > li > .btn:last-child, .appointments .marketo-form .mktoForm .day-list > li > .mktoButton:last-child, .marketo-form .mktoForm .appointments .day-list > li > .mktoButton:last-child {
  margin-top: auto;
}
.appointments .btn-select-time {
  color: #212529;
  background-color: #a2c3d6;
  border-color: #a2c3d6;
  padding: 0.75rem 2.625rem;
}
.appointments .btn-select-time:hover {
  color: #fff;
  background-color: #2c373f;
  border-color: #2c373f;
}
.appointments .btn-select-time:focus, .appointments .btn-select-time.focus {
  color: #fff;
  background-color: #2c373f;
  border-color: #2c373f;
  box-shadow: 0 0 0 0.2rem rgba(143, 171, 188, 0.5);
}
.appointments .btn-select-time.disabled, .appointments .btn-select-time:disabled {
  color: #212529;
  background-color: #a2c3d6;
  border-color: #a2c3d6;
}
.appointments .btn-select-time:not(:disabled):not(.disabled):active, .appointments .btn-select-time:not(:disabled):not(.disabled).active, .show > .appointments .btn-select-time.dropdown-toggle {
  color: #212529;
  background-color: #7facc6;
  border-color: #76a6c2;
}
.appointments .btn-select-time:not(:disabled):not(.disabled):active:focus, .appointments .btn-select-time:not(:disabled):not(.disabled).active:focus, .show > .appointments .btn-select-time.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(143, 171, 188, 0.5);
}
.appointments .btn-select-time svg {
  pointer-events: none;
}
.appointments .appointment-list {
  min-height: 186px;
  padding: 0.375rem 0;
}
.appointments .appointment-list li {
  padding: 0.375rem 0;
}
.appointments .appointment-list li.is-hidden {
  display: none;
}
.appointments .appointment-list .btn, .appointments .appointment-list .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .appointments .appointment-list .mktoButton {
  font-size: 1rem;
  line-height: 1.125;
  font-weight: 400;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
.appointments .appointment-list .appointment-type {
  text-transform: lowercase;
}
.appointments .appointment-list .appointment-type:before {
  left: 6px;
  position: absolute;
  top: 16px;
}
@media (min-width: 576px) {
  .appointments {
    margin: 0;
    padding: 0;
  }
  .appointments .btn-select-time {
    padding: 0.75rem 3.375rem;
  }
  .appointments .day-list > li {
    flex-basis: 22%;
  }
  .appointments .day-list > li:nth-child(n+4) {
    display: flex;
  }
  .appointments .day-list > li:nth-child(n+5) {
    display: none;
  }
  .appointments .appointment-list {
    min-height: 244px;
  }
  .appointments .appointment-list .btn, .appointments .appointment-list .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .appointments .appointment-list .mktoButton {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
  .appointments .appointment-list .appointment-type:before {
    left: 12px;
    top: 1.1rem;
  }
}
@media (min-width: 768px) {
  .appointments .appointments-inner {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .appointments .appointment-selection {
    order: 1;
    padding-bottom: 0;
  }
  .appointments .day-list > li {
    flex-basis: 18%;
  }
  .appointments .day-list > li:nth-child(n+3) {
    display: flex;
  }
  .appointments .day-list > li:nth-child(n+4) {
    display: none;
  }
  .appointments .day-label {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    margin-bottom: 2rem;
  }
}
@media (min-width: 992px) {
  .appointments .appointments-inner .appt-covid-message {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    font-weight: 600;
    margin-bottom: 0.375rem;
    padding-top: 1.5rem;
  }
  .appointments .btn-select-time {
    padding: 0.75rem 2.625rem;
  }
  .appointments .day-list > li {
    flex-basis: 19%;
  }
  .appointments .day-list > li:nth-child(n+4) {
    display: flex;
  }
  .appointments .day-list > li:nth-child(n+5) {
    display: none;
  }
  .appointments .appointment-list {
    min-height: 302px;
  }
  .appointments .appointment-list .appointment-type:before {
    left: 6px;
  }
}
@media (min-width: 1200px) {
  .appointments .appointments-inner {
    padding: 2rem 3rem;
  }
  .appointments .btn-select-date {
    padding: 0.5rem 2rem;
  }
  .appointments .btn-select-time {
    padding: 0.75rem 3.375rem;
  }
  .appointments .day-list > li {
    flex-basis: 18%;
  }
  .appointments .day-list > li:nth-child(n+5) {
    display: flex;
  }
  .appointments .appointment-list .appointment-type:before {
    left: 12px;
  }
}

.bi-appointments {
  margin: 32px calc(30px / -2);
  padding: 0 1rem;
  margin: 0;
  padding: 0;
}
.bi-appointments .icon {
  fill: #fff;
}
.bi-appointments .btn, .bi-appointments .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .bi-appointments .mktoButton {
  line-height: 1;
}
.bi-appointments .date-range-selection .btn, .bi-appointments .date-range-selection .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .bi-appointments .date-range-selection .mktoButton {
  padding: 0.5rem 1rem;
  /* override bootstrap -1px margin-left on .btn-group>.btn:not(:first-child) */
  margin-left: 0;
  background-color: #212529;
  border-color: #212529;
  border-radius: 6rem !important;
}
.bi-appointments .date-range-selection .btn:hover, .bi-appointments .date-range-selection .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .bi-appointments .date-range-selection .mktoButton:hover, .bi-appointments .date-range-selection .btn:focus, .bi-appointments .date-range-selection .marketo-form .mktoForm .mktoButton:focus, .marketo-form .mktoForm .bi-appointments .date-range-selection .mktoButton:focus, .bi-appointments .date-range-selection .btn:active, .bi-appointments .date-range-selection .marketo-form .mktoForm .mktoButton:active, .marketo-form .mktoForm .bi-appointments .date-range-selection .mktoButton:active {
  background-color: #fff !important;
  border-color: #212529 !important;
}
.bi-appointments .date-range-selection .btn:hover .icon, .bi-appointments .date-range-selection .marketo-form .mktoForm .mktoButton:hover .icon, .marketo-form .mktoForm .bi-appointments .date-range-selection .mktoButton:hover .icon, .bi-appointments .date-range-selection .btn:focus .icon, .bi-appointments .date-range-selection .marketo-form .mktoForm .mktoButton:focus .icon, .marketo-form .mktoForm .bi-appointments .date-range-selection .mktoButton:focus .icon, .bi-appointments .date-range-selection .btn:active .icon, .bi-appointments .date-range-selection .marketo-form .mktoForm .mktoButton:active .icon, .marketo-form .mktoForm .bi-appointments .date-range-selection .mktoButton:active .icon {
  fill: #212529;
}
.bi-appointments .select-date[type=text] {
  background-color: #fff;
  border-color: #fff;
  color: #a2c3d6;
  padding: 1px;
  text-align: center;
  width: 145px;
}
.bi-appointments .select-date[type=text]:hover {
  border-color: #a2c3d6;
}
.bi-appointments .select-date[type=text]:focus {
  border-color: transparent;
}
.bi-appointments .day-label {
  font-size: 1rem;
  line-height: 1.125;
  color: #2c373f;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
}
.bi-appointments .appointments-inner {
  height: 100%;
  display: block;
  text-align: center;
}
.bi-appointments .appointments-inner .form-control, .bi-appointments .appointments-inner .share-story-modal form input[type=text], .share-story-modal form .bi-appointments .appointments-inner input[type=text],
.bi-appointments .appointments-inner .share-story-modal form input[type=email],
.share-story-modal form .bi-appointments .appointments-inner input[type=email],
.bi-appointments .appointments-inner .share-story-modal form input[type=tel],
.share-story-modal form .bi-appointments .appointments-inner input[type=tel],
.bi-appointments .appointments-inner .share-story-modal form select,
.share-story-modal form .bi-appointments .appointments-inner select,
.bi-appointments .appointments-inner .share-story-modal form textarea,
.share-story-modal form .bi-appointments .appointments-inner textarea, .bi-appointments .appointments-inner textarea, .bi-appointments .appointments-inner .bmi-calculator input[type=text], .bmi-calculator .bi-appointments .appointments-inner input[type=text],
.bi-appointments .appointments-inner .bmi-calculator select,
.bmi-calculator .bi-appointments .appointments-inner select, .bi-appointments .appointments-inner .marketo-form .mktoForm .mktoEmailField, .marketo-form .mktoForm .bi-appointments .appointments-inner .mktoEmailField,
.bi-appointments .appointments-inner .marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .bi-appointments .appointments-inner .mktoTelField,
.bi-appointments .appointments-inner .marketo-form .mktoForm .mktoTextField,
.marketo-form .mktoForm .bi-appointments .appointments-inner .mktoTextField, .bi-appointments .appointments-inner .marketo-form .mktoForm select, .marketo-form .mktoForm .bi-appointments .appointments-inner select,
.bi-appointments .appointments-inner .marketo-form .mktoForm textarea,
.marketo-form .mktoForm .bi-appointments .appointments-inner textarea {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}
.bi-appointments .appointments-inner .appt-covid-message {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-weight: 600;
  margin-bottom: 0.375rem;
  padding-top: 1.5rem;
}
.bi-appointments .appointments-inner .has-virtual {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.bi-appointments .appointments-inner .has-virtual select {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  display: block;
  margin-top: 1.5rem;
  padding: 0.5rem 2rem 0.5rem 1rem;
  border: 1px solid #cbced1;
  border-radius: 6px;
}
.bi-appointments .appointments-inner :not(.has-virtual) select {
  display: none;
}
.bi-appointments .appointment-title {
  font-size: 1.5rem;
  line-height: 1.25;
  margin-bottom: 2rem;
}
.bi-appointments .appointment-request-btn {
  color: #fff;
  margin-bottom: 1.5rem;
}
.bi-appointments .disclaimer {
  font-size: 1.125rem;
  line-height: 1.6666666667;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}
.bi-appointments .r1-question {
  margin-bottom: 1rem;
}
.bi-appointments .form-group {
  display: flex;
  justify-content: center;
}
.bi-appointments .form-group .radio-wrapper label, .bi-appointments .form-group .marketo-form .mktoForm .mktoRadioList label, .marketo-form .mktoForm .bi-appointments .form-group .mktoRadioList label {
  padding: 0 0 0 0.5rem;
}
.bi-appointments .appointment-selection {
  padding: 2rem 0 1rem;
  position: relative;
  width: 100%;
}
.bi-appointments .appointment-selection.is-loading .day-list-buttons {
  position: relative;
}
.bi-appointments .appointment-selection.is-loading .day-list-buttons:after {
  border: 2px solid #006D9D;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  content: " ";
  display: block;
  pointer-events: none;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  animation: spin 1.4s infinite linear;
  position: relative;
  left: 10px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.bi-appointments .appointment-selection.is-loading .day-list-buttons:after {
  left: 50%;
  margin-left: -13px;
  margin-top: -13px;
  position: absolute;
  top: 50%;
}
.bi-appointments .appointment-selection.no-appointments .no-appointments-message {
  opacity: 1;
}
.bi-appointments .appointment-selection .no-appointments-message {
  color: #a2c3d6;
  margin-left: 10%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transition: opacity, 0.4s;
  width: 80%;
}
.bi-appointments .appointment-selection .icon {
  stroke: #fff;
}
.bi-appointments .day-list {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0;
}
.bi-appointments .day-list > li {
  display: flex;
  flex-basis: 30%;
  flex-direction: column;
  opacity: 0.3;
  transition: opacity 0.4s;
}
.bi-appointments .day-list > li.has-appointments {
  opacity: 1;
  pointer-events: auto;
}
.bi-appointments .day-list > li.has-appointments .btn-select-time:disabled {
  opacity: 0.3;
}
.bi-appointments .day-list > li:nth-child(n+4) {
  display: none;
}
.bi-appointments .day-list > li > .btn:last-child, .bi-appointments .marketo-form .mktoForm .day-list > li > .mktoButton:last-child, .marketo-form .mktoForm .bi-appointments .day-list > li > .mktoButton:last-child {
  margin-top: auto;
}
.bi-appointments .btn-select-time {
  color: #212529;
  background-color: #a2c3d6;
  border-color: #a2c3d6;
  padding: 0.75rem 2.625rem;
}
.bi-appointments .btn-select-time:hover {
  color: #fff;
  background-color: #2c373f;
  border-color: #2c373f;
}
.bi-appointments .btn-select-time:focus, .bi-appointments .btn-select-time.focus {
  color: #fff;
  background-color: #2c373f;
  border-color: #2c373f;
  box-shadow: 0 0 0 0.2rem rgba(143, 171, 188, 0.5);
}
.bi-appointments .btn-select-time.disabled, .bi-appointments .btn-select-time:disabled {
  color: #212529;
  background-color: #a2c3d6;
  border-color: #a2c3d6;
}
.bi-appointments .btn-select-time:not(:disabled):not(.disabled):active, .bi-appointments .btn-select-time:not(:disabled):not(.disabled).active, .show > .bi-appointments .btn-select-time.dropdown-toggle {
  color: #212529;
  background-color: #7facc6;
  border-color: #76a6c2;
}
.bi-appointments .btn-select-time:not(:disabled):not(.disabled):active:focus, .bi-appointments .btn-select-time:not(:disabled):not(.disabled).active:focus, .show > .bi-appointments .btn-select-time.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(143, 171, 188, 0.5);
}
.bi-appointments .btn-select-time svg {
  pointer-events: none;
}
.bi-appointments .appointment-list {
  min-height: 186px;
  padding: 0.375rem 0;
}
.bi-appointments .appointment-list li {
  padding: 0.375rem 0;
}
.bi-appointments .appointment-list li.is-hidden {
  display: none;
}
.bi-appointments .appointment-list .btn, .bi-appointments .appointment-list .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .bi-appointments .appointment-list .mktoButton {
  font-size: 1rem;
  line-height: 1.125;
  font-weight: 600;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.bi-appointments .appointment-list .btn .icon, .bi-appointments .appointment-list .marketo-form .mktoForm .mktoButton .icon, .marketo-form .mktoForm .bi-appointments .appointment-list .mktoButton .icon {
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.125rem;
}
.bi-appointments .appointment-list .appointment-type {
  text-transform: lowercase;
}
.bi-appointments .appointment-list .appointment-type:before {
  left: 6px;
  position: absolute;
  top: 16px;
}
@media (min-width: 576px) {
  .bi-appointments {
    margin: 0;
    padding: 0;
  }
  .bi-appointments .appointments-inner .has-virtual {
    flex-direction: row;
    align-items: baseline;
  }
  .bi-appointments .appointments-inner .has-virtual select {
    margin-top: 0;
  }
  .bi-appointments .btn-select-time {
    padding: 0.75rem 3.375rem;
  }
  .bi-appointments .day-list > li {
    flex-basis: 22%;
  }
  .bi-appointments .day-list > li:nth-child(n+4) {
    display: flex;
  }
  .bi-appointments .day-list > li:nth-child(n+5) {
    display: none;
  }
  .bi-appointments .appointment-list .btn, .bi-appointments .appointment-list .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .bi-appointments .appointment-list .mktoButton {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
  .bi-appointments .appointment-list .appointment-type:before {
    left: 12px;
    top: 1.1rem;
  }
}
@media (min-width: 768px) {
  .bi-appointments .appointment-selection {
    order: 1;
    padding-bottom: 1.5rem;
  }
  .bi-appointments .day-list > li {
    flex-basis: 18%;
  }
  .bi-appointments .day-list > li:nth-child(n+4) {
    display: block;
  }
  .bi-appointments .day-list > li:nth-child(n+5) {
    display: none;
  }
  .bi-appointments .day-label {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    margin-bottom: 1rem;
  }
}
@media (min-width: 992px) {
  .bi-appointments .appointments-inner .appt-covid-message {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    font-weight: 600;
    margin-bottom: 0.375rem;
    padding-top: 1.5rem;
  }
  .bi-appointments .btn-select-time {
    padding: 0.75rem 2.625rem;
  }
  .bi-appointments .day-list > li {
    flex-basis: 19%;
  }
  .bi-appointments .day-list > li:nth-child(n+4) {
    display: flex;
  }
  .bi-appointments .day-list > li:nth-child(n+5) {
    display: none;
  }
  .bi-appointments .appointment-list .appointment-type:before {
    left: 6px;
  }
}
@media (min-width: 1200px) {
  .bi-appointments .btn-select-date {
    padding: 0.5rem 2rem;
  }
  .bi-appointments .btn-select-time {
    padding: 0.75rem 3.375rem;
  }
  .bi-appointments .day-list > li {
    flex-basis: 18%;
  }
  .bi-appointments .day-list > li:nth-child(n+5) {
    display: flex;
  }
  .bi-appointments .appointment-list .appointment-type:before {
    left: 12px;
  }
}

.doctor-profile-detail {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 3rem;
  padding-top: 1rem;
}
@media (min-width: 576px) {
  .doctor-profile-detail {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .doctor-profile-detail {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .doctor-profile-detail {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .doctor-profile-detail {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.doctor-profile-detail .profile-detail-navigation {
  margin-bottom: 1.5rem;
}
.doctor-profile-detail .back-to-results {
  display: none;
}
.doctor-profile-detail .profile-detail-card {
  border: 0;
  text-align: center;
}
.doctor-profile-detail .profile-detail-card .icon-phone-filled {
  flex: 0 0 2rem;
  height: 1.6rem;
  width: 1.5rem;
  stroke: none;
  margin-top: -0.2rem;
}
.doctor-profile-detail .card-header,
.doctor-profile-detail .card-body,
.doctor-profile-detail .card-footer {
  background-color: transparent;
  border: 0;
}
.doctor-profile-detail .appointment-type-label, .doctor-profile-detail .phone-number {
  font-weight: 600;
}
.doctor-profile-detail .card-footer .appointment-type-label {
  padding: 1rem 0 1rem 0;
}
.doctor-profile-detail .profile-image {
  display: inline-block;
  height: 176px;
  width: 176px;
  border: none;
}
.doctor-profile-detail .profile-name {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #2c373f;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 1.5rem;
}
.doctor-profile-detail .ratings {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 44px;
  margin-top: 1.25rem;
}
.doctor-profile-detail .ratings .rating-count {
  font-size: 1rem;
  line-height: 1.125;
  margin-top: 0.5rem;
}
.doctor-profile-detail .profile-specialties {
  margin-bottom: 1.5rem;
}
.doctor-profile-detail .specialty {
  font-size: 1rem;
  line-height: 1.125;
  color: #2c373f;
  display: block;
  font-weight: 700;
  margin-bottom: 0;
  font-style: italic;
}
.doctor-profile-detail .specialty + .specialty {
  padding-top: 0.5rem;
}
.doctor-profile-detail .profile-biography {
  color: #3d5d6b;
  margin-bottom: 3rem;
  position: relative;
  text-align: left;
}
.doctor-profile-detail .profile-biography p,
.doctor-profile-detail .profile-biography li {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  text-align: left;
}
.doctor-profile-detail .affiliation-logo {
  display: inline-block;
  max-width: 175px;
}
.doctor-profile-detail .expanded-content {
  display: none;
}
.doctor-profile-detail .quick-links {
  list-style: none;
  padding: 1rem 0 0 0;
}
.doctor-profile-detail .quick-links li + li {
  padding-top: 1rem;
}
.doctor-profile-detail .quick-links a {
  color: #2c373f;
  display: block;
  font-weight: 700;
  padding: 1rem;
}
.doctor-profile-detail .large-only {
  display: none;
}
@media (min-width: 576px) {
  .doctor-profile-detail .profile-detail-navigation {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.375rem;
  }
  .doctor-profile-detail .back-to-results {
    font-size: 1rem;
    line-height: 1.125;
    display: inline-block;
    font-weight: 600;
  }
  .doctor-profile-detail .back-to-results svg {
    fill: #006D9D;
    margin-right: 0.75rem;
  }
  .doctor-profile-detail .profile-detail-card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 1rem;
    text-align: left;
    background-color: #f5f5f6;
  }
  .doctor-profile-detail .card-header {
    text-align: center;
  }
  .doctor-profile-detail .card-header,
.doctor-profile-detail .card-footer {
    width: 235px;
    text-align: center;
  }
  .doctor-profile-detail .card-header .appointment-type-label,
.doctor-profile-detail .card-footer .appointment-type-label {
    padding: 1rem 0 0 0;
  }
  .doctor-profile-detail .card-body {
    order: 1;
    width: calc(100% - 235px);
  }
  .doctor-profile-detail .quick-links li + li {
    padding-top: 0.5rem;
  }
  .doctor-profile-detail .quick-links a {
    font-size: 1rem;
    line-height: 1.125;
    padding: 0.375rem 0 0.375rem 0;
  }
  .doctor-profile-detail .phone-number {
    font-size: 1rem;
    line-height: 1.125;
  }
  .doctor-profile-detail .affiliation-logo {
    display: inline-block;
    margin: auto;
  }
  .doctor-profile-detail .affiliation-logo:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  .doctor-profile-detail {
    padding-top: 1.75rem;
  }
  .doctor-profile-detail .profile-detail-card {
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 5px;
  }
  .doctor-profile-detail .card-footer {
    width: 232px;
  }
  .doctor-profile-detail .card-body {
    width: calc(100% - 232px);
  }
  .doctor-profile-detail .quick-links li + li {
    padding-top: 0.5rem;
  }
  .doctor-profile-detail .quick-links a {
    font-size: 1rem;
    line-height: 1.125;
    padding: 0.375rem 0 0.375rem 0;
  }
}
@media (min-width: 992px) {
  .doctor-profile-detail .profile-detail-card {
    padding-bottom: 1.5rem;
    padding-top: 2rem;
    text-align: left;
  }
  .doctor-profile-detail .card-header {
    padding: 0 1.25rem 0.5rem 1.25rem;
    width: 225px;
  }
  .doctor-profile-detail .appointment-type-label, .doctor-profile-detail .phone-number {
    float: right;
  }
  .doctor-profile-detail .appointment-type {
    margin-left: 0.25rem;
  }
  .doctor-profile-detail .card-footer {
    padding: 0.75rem 1.25rem 0 2rem;
    width: 243px;
    display: none;
  }
  .doctor-profile-detail .card-footer .appointment-type-label {
    padding: 0;
  }
  .doctor-profile-detail .card-body {
    order: 0;
    padding: 0.5rem 1.25rem 0 1.25rem;
    width: calc(100% - 568px);
  }
  .doctor-profile-detail .quick-links {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: -3rem;
  }
  .doctor-profile-detail .quick-links li {
    max-width: 35%;
  }
  .doctor-profile-detail .quick-links li a {
    display: flex;
    justify-content: center;
    width: 14rem;
  }
  .doctor-profile-detail .large-only {
    display: block;
  }
  .doctor-profile-detail .large-only.affiliation {
    padding-top: 1rem;
  }
  .doctor-profile-detail .large-only.quick-links {
    display: flex;
  }
}
@media (min-width: 1200px) {
  .doctor-profile-detail .profile-detail-card {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
}

.location-profile-detail {
  margin-bottom: 3rem;
}
.location-profile-detail .contact-link {
  background-color: #ffcd55;
  color: #2c373f;
}
.location-profile-detail.view-mode-map .trigger-map {
  display: none;
}
.location-profile-detail.view-mode-map .location-profile-images {
  opacity: 0;
  pointer-events: none;
}
.location-profile-detail.view-mode-map .trigger-images {
  display: inline-block;
}
.location-profile-detail.view-mode-map .location-profile-map {
  opacity: 1;
  pointer-events: auto;
}
.location-profile-detail .page-header .page-header-inner {
  padding-top: 10px;
  padding-bottom: 0px;
  margin-top: 0px;
}
.location-profile-detail .page-header .language-dropdown {
  position: absolute;
  right: 0;
  top: 20px;
}
.location-profile-detail .trigger-images {
  display: none;
}
.location-profile-detail .location-profile-map {
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}
.location-profile-detail .location-profile-map .mapbox-map {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.location-profile-detail .location-profile-map .mapboxgl-canvas-container {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.location-profile-detail .location-profile-map .mapboxgl-marker {
  display: flex;
  justify-content: center;
}
.location-profile-detail .view-mode-select {
  background-color: #fff;
  border-radius: 2rem;
  padding: 0.25rem 0.75rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
}
.location-profile-detail .view-mode-toggle {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  color: #2c373f;
  font-weight: 700;
}
.location-profile-detail .view-mode-toggle .icon {
  fill: #2c373f;
  padding-bottom: 2px;
  padding-right: 3px;
}
.location-profile-detail .view-mode-toggle:focus, .location-profile-detail .view-mode-toggle:hover, .location-profile-detail .view-mode-toggle:active {
  color: #2c373f;
  outline: 0;
  text-decoration: none;
}
.location-profile-detail .view-mode-toggle:focus .icon, .location-profile-detail .view-mode-toggle:hover .icon, .location-profile-detail .view-mode-toggle:active .icon {
  fill: #2c373f;
}
.location-profile-detail .profile-detail-navigation {
  display: none;
}
.location-profile-detail .profile-detail-card {
  border-width: 0.5rem 0;
}
.location-profile-detail .card-header,
.location-profile-detail .card-footer {
  background-color: transparent;
  border: 0;
}
.location-profile-detail .card-body {
  background-color: #f5f5f6;
}
.location-profile-detail .card-header {
  height: 100%;
  padding: 0;
  position: relative;
}
.location-profile-detail .open-or-closed {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  color: #3d5d6b;
  font-weight: 700;
}
.location-profile-detail .open-or-closed:not(:only-child):after {
  border-right: solid 1px #2c373f;
  content: "";
  display: inline-block;
  margin-bottom: -3px;
  margin-right: 0.25rem;
  padding-left: 0.75rem;
  height: 17px;
}
.location-profile-detail .open-or-closed.now-open {
  color: #2c373f;
}
.location-profile-detail .btn-link {
  background-color: transparent;
  border: 0;
}
.location-profile-detail .location-type {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  color: #2c373f;
}
.location-profile-detail .profile-name {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #2c373f;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 0.25rem;
}
.location-profile-detail .profile-specialties {
  margin-bottom: 1.5rem;
}
.location-profile-detail .specialty {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  color: #3d5d6b;
  display: block;
  margin: 0;
}
.location-profile-detail .specialty + .specialty {
  padding-top: 0.25rem;
}
.location-profile-detail .distance-and-directions,
.location-profile-detail .location-address,
.location-profile-detail .phone-number {
  font-size: 1rem;
  line-height: 1.125;
  padding-bottom: 0.25rem;
}
.location-profile-detail .distance-and-directions,
.location-profile-detail .phone-number a,
.location-profile-detail .email a,
.location-profile-detail .in-facility a {
  color: #006D9D;
}
.location-profile-detail .distance {
  font-weight: 700;
}
.location-profile-detail .distance:after {
  content: "|";
  display: inline-block;
  transform: translate(50%);
  padding-left: 0.3rem;
  color: #2c373f;
}
.location-profile-detail .distance + a {
  padding-left: 0.5rem;
}
.location-profile-detail .phone-number a {
  padding-right: 0.3rem;
}
.location-profile-detail .phone-number a + a {
  padding-left: 0.3rem;
}
.location-profile-detail .phone-number,
.location-profile-detail .email {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  font-weight: 600;
}
.location-profile-detail .hours-list {
  font-weight: 600;
  color: #2c373f;
  display: inline-block;
  list-style: none;
  margin: 0.75rem 0;
  padding: 0.625rem 0;
  width: 255px;
}
.location-profile-detail .hours-list li {
  display: flex;
  padding: 0.125rem 0;
  position: relative;
}
.location-profile-detail .hours-list li span {
  font-size: 1rem;
  line-height: 1.125;
  text-align: center;
  width: 65%;
}
.location-profile-detail .hours-list li span:first-child {
  text-align: left;
  width: 19%;
}
.location-profile-detail .hours-list li span:last-child {
  text-align: right;
  width: 16%;
}
.location-profile-detail .hours-list li span:last-child.open-24-hours {
  color: #005557;
  text-align: left;
  width: 100%;
}
.location-profile-detail .hours-list .icon-24-hours {
  height: 1.75rem;
  width: 2rem;
  stroke: #005557;
  stroke-linecap: round;
  stroke-width: 1rem;
  fill: #005557;
}
.location-profile-detail .in-facility {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  display: block;
}
.location-profile-detail .in-facility a {
  color: #2c373f;
  font-weight: 600;
}
.location-profile-detail .contact-link {
  font-size: 1rem;
  line-height: 1.125;
  display: block;
  font-weight: 700;
  margin: 1rem 0 0;
  max-width: 255px;
  padding: 0.375rem 2rem;
  text-align: center;
}
.location-profile-detail .affiliation-logo {
  max-width: 100px;
  position: absolute;
  bottom: 1rem;
  right: 0.5rem;
}
.location-profile-detail .section-title:after {
  background-color: #ffcd55;
}
@media (min-width: 768px) {
  .location-profile-detail {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 1.75rem;
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .location-profile-detail {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .location-profile-detail {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .location-profile-detail {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .location-profile-detail {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .location-profile-detail .breadcrumb {
    display: none;
  }
}
@media (min-width: 768px) {
  .location-profile-detail .profile-detail-navigation {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.375rem;
  }
}
@media (min-width: 768px) {
  .location-profile-detail .page-header {
    padding: 0px 0px;
  }
  .location-profile-detail .page-header .page-header-inner {
    padding: 10px 0px;
    margin-top: 0px;
  }
  .location-profile-detail .page-header .language-dropdown {
    top: -6px !important;
  }
}
@media (min-width: 768px) {
  .location-profile-detail .back-to-results {
    font-size: 1rem;
    line-height: 1.125;
    display: inline-block;
    font-weight: 600;
  }
  .location-profile-detail .back-to-results svg {
    fill: #006D9D;
    margin-right: 0.75rem;
  }
}
@media (min-width: 768px) {
  .location-profile-detail .profile-detail-card {
    border-width: 0.75rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
}
@media (min-width: 768px) {
  .location-profile-detail .card-header {
    display: flex;
    height: auto;
    max-width: 650px;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .location-profile-detail .location-profile-images {
    display: flex;
    overflow: hidden;
  }
  .location-profile-detail .location-profile-images .img-fluid {
    font-family: "object-fit: cover; object-position: center;";
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .location-profile-detail .open-or-closed {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
  .location-profile-detail .open-or-closed:after {
    border-right-width: 2px;
    margin-right: 0.5rem;
    padding-left: 1rem;
  }
}
@media (min-width: 768px) {
  .location-profile-detail .view-mode-toggle {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}
@media (min-width: 768px) {
  .location-profile-detail .card-body {
    padding-bottom: 4.5rem;
    position: relative;
    border-radius: 5px 0 0 5px;
  }
}
@media (min-width: 768px) {
  .location-profile-detail .location-type,
.location-profile-detail .specialty {
    font-size: 1rem;
    line-height: 1.125;
  }
}
@media (min-width: 768px) {
  .location-profile-detail .hours-list {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .location-profile-detail .in-facility {
    margin-top: 1rem;
  }
}
@media (min-width: 768px) {
  .location-profile-detail .affiliation-logo {
    bottom: 2rem;
    right: 1.5rem;
  }
}
@media (min-width: 992px) {
  .location-profile-detail .card-header {
    max-width: 740px;
  }
  .location-profile-detail .page-header {
    padding: 0px 0px;
  }
  .location-profile-detail .page-header .page-header-inner {
    padding: 10px 0px;
    margin-top: 0px;
  }
  .location-profile-detail .page-header .language-dropdown {
    top: -6px !important;
  }
  .location-profile-detail .breadcrumb {
    display: none;
  }
  .location-profile-detail .hours-list {
    display: inline-block;
  }
}
@media (min-width: 1200px) {
  .location-profile-detail .distance-and-directions,
.location-profile-detail .location-address,
.location-profile-detail .phone-number,
.location-profile-detail .email,
.location-profile-detail .hours-list li,
.location-profile-detail .in-facility {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
  .location-profile-detail .page-header {
    padding: 0px 0px;
  }
  .location-profile-detail .page-header .page-header-inner {
    padding: 10px 0px;
    margin-top: 0px;
  }
  .location-profile-detail .page-header .language-dropdown {
    top: -6px !important;
  }
  .location-profile-detail .breadcrumb {
    display: none;
  }
  .location-profile-detail .card-body {
    padding-bottom: 1.5rem;
  }
}

.location-edw-profile-detail {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 3rem;
  padding-top: 1rem;
}
@media (min-width: 576px) {
  .location-edw-profile-detail {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .location-edw-profile-detail {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .location-edw-profile-detail {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .location-edw-profile-detail {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.location-edw-profile-detail .profile-detail-navigation {
  margin-bottom: 1.5rem;
}
.location-edw-profile-detail .back-to-results {
  display: none;
}
.location-edw-profile-detail .profile-detail-card {
  border: 0;
  text-align: center;
}
.location-edw-profile-detail .card-header,
.location-edw-profile-detail .card-body,
.location-edw-profile-detail .card-footer {
  background-color: transparent;
  border: 0;
}
.location-edw-profile-detail .profile-image {
  display: inline-block;
  height: 128px;
  width: 120px;
}
.location-edw-profile-detail .profile-name {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #2c373f;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 1.5rem;
}
.location-edw-profile-detail .ratings {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 44px;
  margin-top: 1.25rem;
}
.location-edw-profile-detail .ratings .rating-count {
  font-size: 1rem;
  line-height: 1.125;
  margin-top: 0.5rem;
}
.location-edw-profile-detail .profile-specialties {
  margin-bottom: 1.5rem;
}
.location-edw-profile-detail .specialty {
  font-size: 1rem;
  line-height: 1.125;
  color: #3d5d6b;
  display: block;
  font-weight: 700;
  margin-bottom: 0;
}
.location-edw-profile-detail .specialty + .specialty {
  padding-top: 0.5rem;
}
.location-edw-profile-detail .profile-biography {
  color: #3d5d6b;
  margin-bottom: 3rem;
  position: relative;
  text-align: left;
}
.location-edw-profile-detail .profile-biography p,
.location-edw-profile-detail .profile-biography li {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  text-align: left;
}
.location-edw-profile-detail .affiliation-logo {
  display: inline-block;
  max-width: 175px;
}
.location-edw-profile-detail .expanded-content,
.location-edw-profile-detail .phone-number {
  display: none;
}
.location-edw-profile-detail .quick-links {
  list-style: none;
  padding: 1rem 0 0 0;
}
.location-edw-profile-detail .quick-links li + li {
  padding-top: 1rem;
}
.location-edw-profile-detail .quick-links a {
  background-color: #f2f9ff;
  color: #00487c;
  display: block;
  font-weight: 700;
  padding: 1rem;
}
@media (min-width: 576px) {
  .location-edw-profile-detail .profile-detail-navigation {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.375rem;
  }
  .location-edw-profile-detail .back-to-results {
    font-size: 1rem;
    line-height: 1.125;
    display: inline-block;
    font-weight: 600;
  }
  .location-edw-profile-detail .back-to-results svg {
    fill: #006D9D;
    margin-right: 0.75rem;
  }
  .location-edw-profile-detail .profile-detail-card {
    border: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 1rem;
    text-align: left;
  }
  .location-edw-profile-detail .card-header {
    text-align: center;
  }
  .location-edw-profile-detail .card-header,
.location-edw-profile-detail .card-footer {
    width: 235px;
  }
  .location-edw-profile-detail .card-body {
    order: 1;
    width: calc(100% - 235px);
  }
  .location-edw-profile-detail .quick-links li + li {
    padding-top: 0.5rem;
  }
  .location-edw-profile-detail .quick-links a {
    font-size: 1rem;
    line-height: 1.125;
    padding: 0.375rem 0 0.375rem 1rem;
  }
  .location-edw-profile-detail .phone-number {
    font-size: 1rem;
    line-height: 1.125;
  }
  .location-edw-profile-detail .phone-number,
.location-edw-profile-detail .affiliation-logo {
    display: inline-block;
  }
  .location-edw-profile-detail .affiliation-logo {
    display: inline-block;
    max-width: 115px;
  }
  .location-edw-profile-detail .affiliation-logo:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  .location-edw-profile-detail {
    padding-top: 1.75rem;
  }
  .location-edw-profile-detail .profile-detail-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .location-edw-profile-detail .card-footer {
    width: 232px;
  }
  .location-edw-profile-detail .card-body {
    width: calc(100% - 232px);
  }
  .location-edw-profile-detail .quick-links li + li {
    padding-top: 0.5rem;
  }
  .location-edw-profile-detail .quick-links a {
    font-size: 1rem;
    line-height: 1.125;
    padding: 0.375rem 0 0.375rem 1rem;
  }
}
@media (min-width: 992px) {
  .location-edw-profile-detail .profile-detail-card {
    padding-bottom: 1.5rem;
    padding-top: 2rem;
    text-align: left;
  }
  .location-edw-profile-detail .card-header {
    padding: 0 1.25rem 0.5rem 1.25rem;
    width: 225px;
  }
  .location-edw-profile-detail .card-footer {
    padding: 0.75rem 1.25rem 0 2rem;
    width: 243px;
  }
  .location-edw-profile-detail .card-body {
    order: 0;
    padding: 0.5rem 1.25rem 0 1.25rem;
    width: calc(100% - 568px);
  }
  .location-edw-profile-detail .quick-links a {
    margin-left: -2rem;
    width: calc(100% + 2rem);
    padding-left: 2rem;
  }
}
@media (min-width: 1200px) {
  .location-edw-profile-detail .profile-detail-card {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
}

.locations-and-appointments {
  margin-bottom: 2rem;
}
.locations-and-appointments .section-title:after {
  background-color: #ffcd55;
}
.locations-and-appointments.no-online-scheduling .locations .carousel-item {
  display: block;
  float: none;
}
.locations-and-appointments.no-online-scheduling .read-more.is-expanded .read-more-expanded {
  display: block;
}
.locations-and-appointments.no-online-scheduling.single-location .appointments, .locations-and-appointments.no-online-scheduling.two-locations .appointments, .locations-and-appointments.no-online-scheduling.three-locations .appointments {
  display: none;
}
.locations-and-appointments.practice-members .location-details .subsection-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 1rem;
}
.locations-and-appointments.practice-members .location-details .subsection-content .appointment-type-label {
  padding-bottom: 0;
}
.locations-and-appointments .map {
  margin-bottom: 1.5rem;
}
.locations-and-appointments .locations {
  margin-bottom: 2rem;
}
.locations-and-appointments #locationServiceAppt .locations {
  height: 0;
}
.locations-and-appointments #locationServiceAppt .appointments {
  flex: 0 0 100%;
  max-width: 100%;
}
.locations-and-appointments #locationServiceAppt .carousel-inner,
.locations-and-appointments #locationServiceAppt .carousel-controls,
.locations-and-appointments #locationServiceAppt .carousel-indicators {
  visibility: hidden;
}
.locations-and-appointments .distance-and-directions,
.locations-and-appointments .location-details {
  max-width: 564px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 1rem;
}
.locations-and-appointments .distance-and-directions {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  padding-left: 2rem;
}
.locations-and-appointments .distance {
  font-size: 1rem;
  line-height: 1.125;
  display: inline-block;
  font-weight: 600;
  padding-right: 0.5rem;
}
.locations-and-appointments .distance::after {
  content: "|";
  display: inline-block;
  pointer-events: none;
  position: relative;
  left: 0.5rem;
}
.locations-and-appointments .get-directions {
  font-size: 1rem;
  line-height: 0.875;
  border-radius: 5px;
  padding: 0.375rem 0.5rem;
}
.locations-and-appointments .subsection-title {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-weight: 700;
  margin-bottom: 0.25rem;
}
.locations-and-appointments .subsection-content {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  margin-bottom: 1rem;
}
.locations-and-appointments .subsection-content:not(:last-child) {
  margin-bottom: 1.5rem;
}
.locations-and-appointments .ratings {
  align-items: center;
  display: flex;
  height: 18px;
  justify-content: center;
  margin: 0.5rem 0;
}
.locations-and-appointments .ratings .rating-stars {
  margin-right: 0.75rem;
}
.locations-and-appointments .ratings .rating-count {
  font-size: 1rem;
  line-height: 1.125;
  color: #3d5d6b;
}
.locations-and-appointments address {
  margin-bottom: 0.25rem;
}
.locations-and-appointments .phone-and-fax {
  font-weight: 600;
}
.locations-and-appointments .icon-phone-filled {
  stroke-width: 0;
}
.locations-and-appointments .fax-number {
  margin-left: 1rem;
  overflow: visible;
  position: relative;
}
.locations-and-appointments .fax-number:before {
  content: "|";
  display: inline-block;
  pointer-events: none;
  position: absolute;
  left: -0.8rem;
}
.locations-and-appointments .fax-label {
  color: #006D9D;
  padding-left: 0.5rem;
}
.locations-and-appointments .office-hours-list {
  list-style: none;
  margin-bottom: 1rem;
  padding-left: 0;
}
.locations-and-appointments .office-hours-list li {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
.locations-and-appointments .physician-info {
  text-align: center;
  padding: 1rem 0;
  width: 100%;
}
.locations-and-appointments .physician-info .profile-image {
  height: 160px;
  margin: 0 auto 1rem;
  min-width: 160px;
  width: 160px;
}
.locations-and-appointments .carousel-controls {
  padding: 3rem 1rem 0rem;
}
.locations-and-appointments .carousel-controls,
.locations-and-appointments .carousel-indicators {
  display: flex;
  z-index: 5;
}
.locations-and-appointments .carousel-indicators {
  top: 100%;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 0.5rem;
}
.locations-and-appointments .carousel-indicators li {
  background-color: #fff;
  border-radius: 50%;
  height: 12px;
  margin: 0 4px;
  width: 12px;
  border: 1px solid #2c373f;
}
.locations-and-appointments .carousel-indicators li.active {
  pointer-events: none;
  background-color: #2c373f;
}
.locations-and-appointments .carousel-control-next,
.locations-and-appointments .carousel-control-prev {
  position: relative;
  bottom: 0;
  z-index: 1;
  background-color: #2c373f;
  border-radius: 6rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  opacity: 1;
  padding: 0;
  text-align: center;
  transform: translateY(-50%);
  transition: opacity 0.15s ease;
  width: 2rem;
}
.locations-and-appointments .carousel-control-next:hover, .locations-and-appointments .carousel-control-next:focus, .locations-and-appointments .carousel-control-next:active,
.locations-and-appointments .carousel-control-prev:hover,
.locations-and-appointments .carousel-control-prev:focus,
.locations-and-appointments .carousel-control-prev:active {
  background-color: #3d5d6b;
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 1;
}
.locations-and-appointments .carousel-control-next:focus:not(:hover),
.locations-and-appointments .carousel-control-prev:focus:not(:hover) {
  background-color: #2c373f;
}
.locations-and-appointments .carousel-control-next .icon,
.locations-and-appointments .carousel-control-prev .icon {
  fill: #fff;
  height: 1rem;
}
.locations-and-appointments .carousel-control-next {
  right: 10px;
}
.locations-and-appointments .carousel-control-prev {
  left: 10px;
}
.locations-and-appointments .icon-arrow-left {
  margin-right: 0.125rem;
}
.locations-and-appointments .icon-arrow-right {
  margin-left: 0.125rem;
}
.locations-and-appointments .view-other-locations {
  font-size: 1.25rem;
  line-height: 1.2;
  background: transparent;
  border: 0;
  flex-grow: 1;
  outline: none;
  text-align: center;
}
.locations-and-appointments .view-other-locations .icon-arrow-down {
  margin-left: 0.5rem;
}
.locations-and-appointments .carousel-item.no-appointments .appointment-request-container, .locations-and-appointments .carousel-item.no-online-scheduling .appointment-request-container {
  display: block;
}
.locations-and-appointments .carousel-item.no-appointments .appointments-summary, .locations-and-appointments .carousel-item.no-online-scheduling .appointments-summary {
  display: none;
}
.locations-and-appointments .carousel-item.active,
.locations-and-appointments .carousel-item-next,
.locations-and-appointments .carousel-item-prev {
  display: block;
}
.locations-and-appointments .doctors-carousel .phone-and-fax {
  margin-bottom: 1rem;
}
.locations-and-appointments .doctors-carousel .affiliation-logo {
  float: right;
  width: 115px;
}
.locations-and-appointments .appointments {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 100%;
  max-width: 100%;
  display: block;
}
.locations-and-appointments #appointmentsInnerForm {
  height: 100%;
}
.locations-and-appointments .legend {
  text-align: center;
}
.locations-and-appointments .appointments-modal .appointments {
  display: block;
}
.locations-and-appointments .appointment-request-container {
  font-size: 1rem;
  line-height: 1.125;
  display: none;
  background-color: #f2f9ff;
  border-top: none;
  padding: 1rem 2rem;
}
.locations-and-appointments .appointment-request-container .appointment-request-btn {
  color: #fff;
  padding: 7px;
  width: 100%;
}
.locations-and-appointments .appointment-request-container .appointment-request-btn + .appointment-request-phone {
  padding-top: 1rem;
}
.locations-and-appointments .appointment-request-container .appointment-request-phone {
  font-size: 1rem;
  line-height: 1.125;
  align-items: center;
  color: #2c373f;
  display: flex;
  justify-content: center;
  padding: 0 1rem;
}
.locations-and-appointments .appointment-request-container .appointment-request-phone svg {
  flex: 0 0 2rem;
  height: 2rem;
  margin-right: 1rem;
  width: 2rem;
}
.locations-and-appointments .appointment-request-container .appointment-request-phone a {
  display: block;
}
.locations-and-appointments .appointments-summary {
  font-size: 1rem;
  line-height: 1.125;
  display: none;
  background-color: #f5f5f6;
  border-top: none;
  padding: 1rem 2rem;
}
.locations-and-appointments .appointments-summary .location-address {
  border-bottom: 1px solid #a2c3d6;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.locations-and-appointments .appointments-summary .location-address:empty {
  display: none;
}
.locations-and-appointments .appointments-summary .address {
  color: #006D9D;
}
.locations-and-appointments .appointments-summary .appointment-type-label {
  display: none;
}
.locations-and-appointments .appointments-summary .appointment-heading {
  color: #2c373f;
  font-weight: 700;
  margin-bottom: 0.75rem;
}
.locations-and-appointments .appointments-summary .appointment-date {
  color: #006D9D;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.locations-and-appointments .appointments-summary .appointment-time {
  margin-right: 0.875rem;
}
.locations-and-appointments .appointments-summary .modal-open-btn {
  background: none;
  border: none;
  color: #006D9D;
  font-weight: 400;
  padding: 0 0.625rem 0 0;
  position: relative;
}
.locations-and-appointments .appointments-summary .modal-open-btn:after {
  border-bottom: 6px solid transparent;
  border-left: 6px solid #00487c;
  border-top: 6px solid transparent;
  clear: both;
  content: "";
  display: inline-block;
  height: 0;
  left: 100%;
  position: absolute;
  top: 3px;
  width: 0;
}
.locations-and-appointments .appointments-summary .modal-open-btn:hover, .locations-and-appointments .appointments-summary .modal-open-btn:focus {
  background: none;
  border: none;
  color: #006D9D;
  outline: 0;
  text-decoration: underline;
}
.locations-and-appointments .appointments-summary .legend {
  padding-top: 1rem;
  text-align: left;
}
.locations-and-appointments .available-providers {
  display: block;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.locations-and-appointments .available-providers .heading-5 {
  margin-bottom: 0.825rem;
}
.locations-and-appointments .provider-list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 1rem;
  list-style: none;
}
.locations-and-appointments .provider-list .provider-list-item {
  display: block;
  width: 100%;
  margin: 1rem 0rem;
  padding: 0rem 0rem 0 3rem;
}
.locations-and-appointments .provider-list .provider-list-item.active .provider-item {
  background-color: #f2f9ff;
  pointer-events: none;
}
.locations-and-appointments .provider-list .provider-list-item.active .cta-link {
  display: none;
}
.locations-and-appointments .provider-list .provider-item {
  background: transparent;
  border: none;
  display: flex;
  width: 100%;
  flex-flow: row;
  align-items: center;
  gap: 1rem;
}
.locations-and-appointments .provider-list .provider-item:focus {
  background-color: #f2f9ff;
  outline: none;
  text-decoration: none;
}
.locations-and-appointments .provider-list .provider-item:focus .cta-link {
  text-decoration: underline;
}
.locations-and-appointments .provider-list .provider-item:hover {
  background-color: #f5f5f6;
  outline: none;
  text-decoration: none;
}
.locations-and-appointments .provider-list .provider-item:hover .cta-link {
  text-decoration: underline;
}
.locations-and-appointments .provider-list .provider-item svg {
  pointer-events: none;
}
.locations-and-appointments .provider-list .profile-image {
  margin: auto 0rem auto -56px;
}
.locations-and-appointments .provider-list .profile-content {
  font-size: 1rem;
  line-height: 1.125;
  color: #3d5d6b;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1rem;
  text-align: left;
}
.locations-and-appointments .provider-list .profile-content-main {
  flex-grow: 1;
}
.locations-and-appointments .provider-list .profile-name {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #2c373f;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.locations-and-appointments .provider-list .cta-link {
  color: #006D9D;
  font-weight: 700;
  padding-top: 1rem;
}
.locations-and-appointments .locations-modal .modal-dialog {
  max-width: 800px;
}
.locations-and-appointments .locations-modal .modal-content {
  border: 0;
}
.locations-and-appointments .locations-modal .modal-body {
  padding: 2rem;
}
.locations-and-appointments .locations-modal .modal-body:only-child {
  padding: 2rem;
}
.locations-and-appointments .locations-modal .modal-close-btn {
  background: transparent;
  border: 0;
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.locations-and-appointments .locations-modal .modal-close-btn svg {
  height: 1.25rem;
  width: 1.25rem;
}
.locations-and-appointments .locations-modal .location-select-list {
  columns: 1;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.locations-and-appointments .locations-modal .location-select-list li {
  display: block;
  padding: 0.5rem 0;
  break-inside: avoid-column;
}
.locations-and-appointments .locations-modal .location-name {
  font-weight: 700;
}
.locations-and-appointments .button-container {
  margin-bottom: 1.5rem;
  width: 100%;
  text-align: center;
}
.locations-and-appointments .read-more-expanded {
  width: 100%;
}
@media (max-width: 767.98px) {
  .locations-and-appointments .carousel-item {
    float: left;
  }
  .locations-and-appointments .locations-modal .location-select-list {
    columns: 2;
  }
  .locations-and-appointments .locations-modal .location-select-list li {
    display: inline-block;
  }
}
@media (min-width: 576px) {
  .locations-and-appointments .locations {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .locations-and-appointments .locations .distance-and-directions {
    padding-left: 0;
  }
  .locations-and-appointments .map {
    margin: 0 auto 1.5rem;
    max-width: 500px;
  }
}
@media (min-width: 768px) {
  .locations-and-appointments {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .locations-and-appointments {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .locations-and-appointments {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .locations-and-appointments {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .locations-and-appointments {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .locations-and-appointments.no-online-scheduling.single-location .locations {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .locations-and-appointments.no-online-scheduling.single-location .locations .carousel-item {
    display: flex;
  }
  .locations-and-appointments.no-online-scheduling.single-location .locations .carousel-item .map,
.locations-and-appointments.no-online-scheduling.single-location .locations .carousel-item .location-details {
    width: 50%;
  }
  .locations-and-appointments.no-online-scheduling.single-location .locations .carousel-item .map {
    margin-bottom: 0;
  }
  .locations-and-appointments.no-online-scheduling.single-location .locations .carousel-item .location-details {
    padding-left: 30px;
  }
  .locations-and-appointments.no-online-scheduling.single-location .appointments {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    display: block;
  }
  .locations-and-appointments.no-online-scheduling.single-location .appointments .appointment-request-container {
    display: block;
  }
  .locations-and-appointments.no-online-scheduling.two-locations .locations {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .locations-and-appointments.no-online-scheduling.two-locations .locations .carousel-inner {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .locations-and-appointments.no-online-scheduling.two-locations .locations .carousel-item {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 50%;
    max-width: 50%;
    display: block;
    margin-right: 0;
  }
  .locations-and-appointments.no-online-scheduling.two-locations .appointments {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    display: block;
  }
  .locations-and-appointments.no-online-scheduling.two-locations .appointments .appointment-request-container {
    display: block;
  }
  .locations-and-appointments.no-online-scheduling.three-locations .locations {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .locations-and-appointments.no-online-scheduling.three-locations .locations .carousel-inner {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    width: auto;
  }
  .locations-and-appointments.no-online-scheduling.three-locations .locations .carousel-item {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    display: block;
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
  .locations-and-appointments.no-online-scheduling.three-locations .appointments {
    flex: 0 0 100%;
    max-width: 100%;
    display: block;
  }
  .locations-and-appointments.no-online-scheduling.three-locations .appointments .appointment-request-container {
    display: block;
  }
  .locations-and-appointments.no-online-scheduling .locations .distance-and-directions {
    margin-bottom: 0;
  }
  .locations-and-appointments.no-online-scheduling .appointments .appointments-inner {
    align-content: center;
    height: auto;
  }
  .locations-and-appointments.no-online-scheduling .appointments .date-range-selection,
.locations-and-appointments.no-online-scheduling .appointments .appointment-selection,
.locations-and-appointments.no-online-scheduling .appointments .legend {
    display: none;
  }
  .locations-and-appointments.no-online-scheduling .appointments .appointment-request-container {
    padding: 0;
    width: 100%;
  }
  .locations-and-appointments.no-online-scheduling .appointments .appointment-request-container svg {
    flex: 0 0 2.25rem;
    height: 2.25rem;
    width: 2.25rem;
  }
  .locations-and-appointments.no-online-scheduling .appointments .appointment-request-container .appointment-request-phone {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    padding: 0;
  }
  .locations-and-appointments.no-online-scheduling .appointments .appointment-request-container a {
    display: block;
  }
  .locations-and-appointments.no-online-scheduling .read-more.is-expanded .read-more-expanded {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) {
  .locations-and-appointments.no-online-scheduling .carousel-controls,
.locations-and-appointments.no-online-scheduling .carousel-indicators, .locations-and-appointments.practice-members .carousel-controls,
.locations-and-appointments.practice-members .carousel-indicators {
    display: none;
  }
}
@media (min-width: 768px) {
  .locations-and-appointments .carousel-controls {
    align-items: center;
    margin-top: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 768px) {
  .locations-and-appointments .carousel-indicators {
    display: flex;
  }
  .locations-and-appointments .carousel-indicators li {
    background-color: #fff;
    border-radius: 50%;
    height: 12px;
    margin: 0 4px;
    width: 12px;
    border: 1px solid #2c373f;
  }
  .locations-and-appointments .carousel-indicators li.active {
    pointer-events: none;
    background-color: #2c373f;
  }
}
@media (min-width: 768px) {
  .locations-and-appointments .view-other-locations {
    font-size: 1rem;
    line-height: 1.5;
  }
}
@media (min-width: 768px) {
  .locations-and-appointments .locations-and-appointments-inner {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media (min-width: 768px) {
  .locations-and-appointments .locations {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .locations-and-appointments .distance-and-directions {
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .locations-and-appointments .location-details {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .locations-and-appointments .map {
    margin-right: 0;
  }
}
@media (min-width: 768px) {
  .locations-and-appointments .appointments-summary {
    display: none;
  }
}
@media (min-width: 768px) {
  .locations-and-appointments .office-hours-list {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .locations-and-appointments .carousel-item.no-appointments .appointment-request-container, .locations-and-appointments .carousel-item.no-online-scheduling .appointment-request-container {
    display: none;
  }
  .locations-and-appointments .carousel-item.is-telemedicine {
    position: relative;
  }
  .locations-and-appointments .carousel-item.is-telemedicine .location-details {
    background-color: #f5f5f6;
    height: 400px;
  }
  .locations-and-appointments .carousel-item.is-telemedicine .location-details .subsection-title {
    align-items: center;
    background: url("/assets/images/telemedicine.png");
    background-position-y: 0;
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    height: 400px;
    justify-content: flex-end;
    padding-bottom: 4rem;
  }
  .locations-and-appointments .carousel-item.is-telemedicine .location-details .subsection-title svg {
    height: 3rem;
    width: 3rem;
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  .locations-and-appointments .legend {
    margin: 1.5rem auto 0;
    order: 2;
  }
}
@media (min-width: 768px) {
  .locations-and-appointments .appointments {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
    display: block;
  }
}
@media (min-width: 768px) {
  .locations-and-appointments .appointment-selection {
    margin-left: -1rem;
    margin-right: -1rem;
    width: calc(100% + 2rem);
  }
}
@media (min-width: 768px) {
  .locations-and-appointments .available-providers {
    display: block;
    padding-top: initial;
    padding-left: initial;
    padding-right: initial;
  }
}
@media (min-width: 768px) {
  .locations-and-appointments .provider-list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 1rem;
    list-style: none;
  }
  .locations-and-appointments .provider-list .provider-list-item {
    display: block;
    width: 50%;
    padding: 0rem 2rem 0 3rem;
  }
  .locations-and-appointments .provider-list .provider-list-item.active .provider-item {
    background-color: #f2f9ff;
    pointer-events: none;
  }
  .locations-and-appointments .provider-list .provider-list-item.active .cta-link {
    display: none;
  }
  .locations-and-appointments .provider-list .provider-item {
    background: transparent;
    border: none;
    display: flex;
    width: 100%;
  }
  .locations-and-appointments .provider-list .provider-item:focus {
    background-color: #f2f9ff;
    outline: none;
    text-decoration: none;
  }
  .locations-and-appointments .provider-list .provider-item:focus .cta-link {
    text-decoration: underline;
  }
  .locations-and-appointments .provider-list .provider-item:hover {
    background-color: #f5f5f6;
    outline: none;
    text-decoration: none;
  }
  .locations-and-appointments .provider-list .provider-item:hover .cta-link {
    text-decoration: underline;
  }
  .locations-and-appointments .provider-list .provider-item svg {
    pointer-events: none;
  }
  .locations-and-appointments .provider-list .profile-content {
    font-size: 1rem;
    line-height: 1.125;
    color: #3d5d6b;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 1rem;
    text-align: left;
  }
  .locations-and-appointments .provider-list .profile-content-main {
    flex-grow: 1;
  }
  .locations-and-appointments .provider-list .profile-name {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    color: #2c373f;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .locations-and-appointments .provider-list .cta-link {
    color: #006D9D;
    font-weight: 700;
  }
}
@media (min-width: 768px) {
  .locations-and-appointments .locations-modal .modal-dialog {
    max-width: 800px;
  }
  .locations-and-appointments .locations-modal .modal-content {
    border: 0;
  }
  .locations-and-appointments .locations-modal .modal-body {
    padding: 2rem;
  }
  .locations-and-appointments .locations-modal .modal-body:only-child {
    padding: 2rem;
  }
  .locations-and-appointments .locations-modal .modal-close-btn {
    background: transparent;
    border: 0;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  .locations-and-appointments .locations-modal .modal-close-btn svg {
    height: 1.25rem;
    width: 1.25rem;
  }
  .locations-and-appointments .locations-modal .location-select-list {
    columns: 2;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }
  .locations-and-appointments .locations-modal .location-select-list li {
    display: inline-block;
    padding: 0.5rem 0;
    break-inside: avoid-column;
  }
  .locations-and-appointments .locations-modal .location-name {
    font-weight: 700;
  }
}
@media (min-width: 992px) {
  .locations-and-appointments .locations {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .locations-and-appointments .appointments {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .locations-and-appointments .radio-wrapper, .locations-and-appointments .marketo-form .mktoForm .mktoRadioList, .marketo-form .mktoForm .locations-and-appointments .mktoRadioList {
    display: inline-flex;
    margin-right: 1rem;
  }
}
@media (min-width: 1200px) {
  .locations-and-appointments .legend {
    margin: 0;
    order: 0;
  }
  .locations-and-appointments .provider-list .provider-list-item {
    width: 33.33%;
  }
}

.locations-and-appointments-edw {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  font-size: 1rem;
  line-height: 1.875;
  margin-bottom: 2rem;
  padding-top: 1rem;
  color: #333;
}
.locations-and-appointments-edw.no-online-scheduling .locations .carousel-item {
  display: block;
  float: none;
}
.locations-and-appointments-edw.no-online-scheduling .read-more.is-expanded .read-more-expanded {
  display: block;
}
.locations-and-appointments-edw.no-online-scheduling.single-location .appointments, .locations-and-appointments-edw.no-online-scheduling.two-locations .appointments, .locations-and-appointments-edw.no-online-scheduling.three-locations .appointments {
  display: none;
}
.locations-and-appointments-edw.practice-members .location-details .subsection-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 1rem;
}
.locations-and-appointments-edw.practice-members .location-details .subsection-content .appointment-type-label {
  padding-bottom: 0;
}
.locations-and-appointments-edw .locations-and-appointments-inner {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding: 2rem 1rem 1rem;
}
.locations-and-appointments-edw .locations-and-appointments-inner.border {
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.13);
}
.locations-and-appointments-edw .distance-and-directions,
.locations-and-appointments-edw .location-details {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
.locations-and-appointments-edw .distance-and-directions {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}
.locations-and-appointments-edw .distance {
  font-size: 1rem;
  line-height: 1.125;
  color: #006D9D;
  display: inline-block;
  font-weight: 600;
  padding-right: 1rem;
}
.locations-and-appointments-edw .get-directions {
  font-size: 1rem;
  line-height: 0.875;
  border-radius: 5px;
  padding: 0.375rem 1rem;
}
.locations-and-appointments-edw p {
  font-size: 1rem;
  line-height: 1.875;
}
.locations-and-appointments-edw .section-title {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #333;
  font-weight: 700;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  text-align: left;
}
.locations-and-appointments-edw .section-title:after {
  display: none;
}
.locations-and-appointments-edw .section-subtitle {
  font-size: 1.25rem;
  line-height: 1.5;
  color: #333;
  font-weight: 700;
  margin-bottom: 0.25rem;
  padding-bottom: 1rem;
}
.locations-and-appointments-edw .section-subtitle:after {
  display: none;
}
.locations-and-appointments-edw .subsection {
  width: 100%;
  min-width: 200px;
}
.locations-and-appointments-edw .subsection-title {
  font-size: 1rem;
  line-height: 1.5;
  color: #146B9A;
  font-weight: 700;
  margin-bottom: 0.25rem;
}
.locations-and-appointments-edw .subsection-content {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}
.locations-and-appointments-edw .subsection-content:not(:last-child) {
  margin-bottom: 1.5rem;
}
.locations-and-appointments-edw .ratings {
  align-items: center;
  display: flex;
  height: 18px;
  justify-content: center;
  margin: 0.5rem 0;
}
.locations-and-appointments-edw .ratings .rating-stars {
  margin-right: 0.75rem;
}
.locations-and-appointments-edw .ratings .rating-count {
  font-size: 1rem;
  line-height: 1.125;
  color: #3d5d6b;
}
.locations-and-appointments-edw address {
  margin-bottom: 0.25rem;
}
.locations-and-appointments-edw .phone-and-fax {
  font-weight: 600;
}
.locations-and-appointments-edw .fax-number {
  margin-left: 2rem;
  overflow: visible;
  position: relative;
}
.locations-and-appointments-edw .fax-number:before {
  content: "|";
  display: inline-block;
  pointer-events: none;
  position: absolute;
  left: -1.5rem;
}
.locations-and-appointments-edw .fax-label {
  color: #146B9A;
  padding-left: 0.5rem;
}
.locations-and-appointments-edw .office-hours-list {
  list-style: none;
  margin-bottom: 1rem;
  padding-left: 0;
}
.locations-and-appointments-edw .office-hours-list li {
  font-size: 1rem;
  line-height: 1.5;
}
.locations-and-appointments-edw .physician-info {
  text-align: center;
  padding: 1rem 0;
  width: 100%;
}
.locations-and-appointments-edw .physician-info .profile-image {
  height: 160px;
  margin: 0 auto 1rem;
  min-width: 160px;
  width: 160px;
}
.locations-and-appointments-edw .carousel-controls,
.locations-and-appointments-edw .carousel-indicators {
  display: flex;
  z-index: 5;
  padding-right: 15px;
  padding-left: 15px;
}
.locations-and-appointments-edw .carousel-indicators {
  top: 100%;
  align-items: center;
}
.locations-and-appointments-edw .carousel-indicators li {
  background-color: #3d5d6b;
  border-radius: 50%;
  height: 12px;
  margin: 0 4px;
  width: 12px;
}
.locations-and-appointments-edw .carousel-indicators li.active {
  pointer-events: none;
}
.locations-and-appointments-edw .carousel-control-prev,
.locations-and-appointments-edw .carousel-control-next {
  background-color: #146B9A;
  opacity: 1;
  padding: 1.5rem 2.5rem;
  position: relative;
  width: auto;
}
.locations-and-appointments-edw .carousel-control-prev .icon,
.locations-and-appointments-edw .carousel-control-next .icon {
  fill: #fff;
}
.locations-and-appointments-edw .view-other-locations {
  font-size: 1.25rem;
  line-height: 1.2;
  background: transparent;
  border: 0;
  flex-grow: 1;
  outline: none;
  text-align: center;
}
.locations-and-appointments-edw .view-other-locations .icon-arrow-down {
  margin-left: 0.5rem;
}
.locations-and-appointments-edw .carousel-item.no-appointments .appointment-request-container, .locations-and-appointments-edw .carousel-item.no-online-scheduling .appointment-request-container {
  display: block;
}
.locations-and-appointments-edw .carousel-item.no-appointments .appointments-summary, .locations-and-appointments-edw .carousel-item.no-online-scheduling .appointments-summary {
  display: none;
}
.locations-and-appointments-edw .carousel-item.active,
.locations-and-appointments-edw .carousel-item-next,
.locations-and-appointments-edw .carousel-item-prev {
  display: block;
}
.locations-and-appointments-edw .doctors-carousel .phone-and-fax {
  margin-bottom: 1rem;
}
.locations-and-appointments-edw .doctors-carousel .affiliation-logo {
  float: right;
  width: 115px;
}
.locations-and-appointments-edw .appointments {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 100%;
  max-width: 100%;
  display: block;
}
.locations-and-appointments-edw .legend {
  text-align: center;
}
.locations-and-appointments-edw .appointments-modal .appointments {
  display: block;
}
.locations-and-appointments-edw .appointment-request-container {
  font-size: 1rem;
  line-height: 1.125;
  display: none;
  border-top: none;
  padding: 1rem 2rem;
}
.locations-and-appointments-edw .appointment-request-container .appointment-request-btn {
  color: #fff;
  padding: 7px;
  width: 100%;
}
.locations-and-appointments-edw .appointment-request-container .appointment-request-btn + .appointment-request-phone {
  padding-top: 1rem;
}
.locations-and-appointments-edw .appointment-request-container .appointment-request-phone {
  font-size: 1rem;
  line-height: 1.125;
  align-items: center;
  color: #2c373f;
  display: flex;
  justify-content: center;
  padding: 0 1rem;
}
.locations-and-appointments-edw .appointment-request-container .appointment-request-phone svg {
  flex: 0 0 2rem;
  height: 2rem;
  margin-right: 1rem;
  width: 2rem;
}
.locations-and-appointments-edw .appointment-request-container .appointment-request-phone a {
  display: block;
}
.locations-and-appointments-edw .appointment-request-container .appointment-request-phone a span {
  display: inline-block;
}
.locations-and-appointments-edw .appointment-request-container .appointment-request-phone .btn, .locations-and-appointments-edw .appointment-request-container .appointment-request-phone .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .locations-and-appointments-edw .appointment-request-container .appointment-request-phone .mktoButton {
  border-radius: 50rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}
.locations-and-appointments-edw .appointments-summary {
  font-size: 1rem;
  line-height: 1.125;
  border-top: none;
  padding: 1rem;
  display: flex;
  flex-flow: column;
}
.locations-and-appointments-edw .appointments-summary .location-address {
  padding-bottom: 1rem;
}
.locations-and-appointments-edw .appointments-summary .location-address:empty {
  display: none;
}
.locations-and-appointments-edw .appointments-summary .address {
  color: #146B9A;
}
.locations-and-appointments-edw .appointments-summary .appointment-type-label {
  display: none;
}
.locations-and-appointments-edw .appointments-summary .appointment-heading {
  color: #2c373f;
  font-weight: 700;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
}
.locations-and-appointments-edw .appointments-summary .appointment-date {
  color: #146B9A;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.locations-and-appointments-edw .appointments-summary .appointment-time {
  margin-right: 0.875rem;
}
.locations-and-appointments-edw .appointments-summary .modal-open-btn {
  background: none;
  border: none;
  color: #006D9D;
  font-weight: 400;
  padding: 0 0.625rem 0 0;
  position: relative;
}
.locations-and-appointments-edw .appointments-summary .modal-open-btn:after {
  border-bottom: 6px solid transparent;
  border-left: 6px solid #00487c;
  border-top: 6px solid transparent;
  clear: both;
  content: "";
  display: inline-block;
  height: 0;
  left: 100%;
  position: absolute;
  top: 3px;
  width: 0;
}
.locations-and-appointments-edw .appointments-summary .modal-open-btn:hover, .locations-and-appointments-edw .appointments-summary .modal-open-btn:focus {
  background: none;
  border: none;
  color: #006D9D;
  outline: 0;
  text-decoration: underline;
}
.locations-and-appointments-edw .appointments-summary .legend {
  padding-top: 1rem;
  text-align: left;
}
.locations-and-appointments-edw .appointments-summary .btn, .locations-and-appointments-edw .appointments-summary .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .locations-and-appointments-edw .appointments-summary .mktoButton {
  border-radius: 50rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  max-width: 350px;
  align-self: center;
}
.locations-and-appointments-edw .appointments-summary .select-custom {
  overflow: hidden;
  position: relative;
  margin-bottom: 0.5rem;
  width: 100%;
}
.locations-and-appointments-edw .appointments-summary .select-custom svg {
  pointer-events: none;
}
.locations-and-appointments-edw .appointments-summary .select-custom select {
  position: relative;
  appearance: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  display: block;
}
.locations-and-appointments-edw .appointments-summary .select-custom-inner::-ms-expand {
  display: none;
}
.locations-and-appointments-edw .appointments-summary .select-custom-inner {
  border-bottom: 1px solid #454545;
  border-left: none;
  border-top: none;
  border-right: none;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  appearance: none;
}
.locations-and-appointments-edw .appointments-summary .icon-arrow-down {
  position: absolute;
  right: 0.75rem;
  bottom: 1rem;
  display: block;
  padding: 0;
}
.locations-and-appointments-edw .available-providers {
  display: block;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.locations-and-appointments-edw .available-providers .heading-5 {
  margin-bottom: 0.825rem;
}
.locations-and-appointments-edw .provider-list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 1rem;
  list-style: none;
}
.locations-and-appointments-edw .provider-list .provider-list-item {
  display: block;
  width: 100%;
}
.locations-and-appointments-edw .provider-list .provider-list-item.active .provider-item {
  background-color: #f2f9ff;
  pointer-events: none;
}
.locations-and-appointments-edw .provider-list .provider-list-item.active .cta-link {
  display: none;
}
.locations-and-appointments-edw .provider-list .provider-item {
  background: transparent;
  border: none;
  display: flex;
  padding: 1rem 0.5rem;
  width: 100%;
}
.locations-and-appointments-edw .provider-list .provider-item:hover, .locations-and-appointments-edw .provider-list .provider-item:focus {
  background-color: #f2f9ff;
  outline: none;
  text-decoration: none;
}
.locations-and-appointments-edw .provider-list .provider-item:hover .cta-link, .locations-and-appointments-edw .provider-list .provider-item:focus .cta-link {
  text-decoration: underline;
}
.locations-and-appointments-edw .provider-list .provider-item svg {
  pointer-events: none;
}
.locations-and-appointments-edw .provider-list .profile-image {
  height: 90px;
  width: 90px;
  min-width: 90px;
}
.locations-and-appointments-edw .provider-list .profile-content {
  font-size: 1rem;
  line-height: 1.125;
  color: #3d5d6b;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1rem;
  text-align: left;
  height: 85px;
}
.locations-and-appointments-edw .provider-list .profile-content-main {
  flex-grow: 1;
}
.locations-and-appointments-edw .provider-list .profile-name {
  font-size: 1rem;
  line-height: 1.125;
  color: #146B9A;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.locations-and-appointments-edw .provider-list .cta-link {
  color: #146B9A;
  font-weight: 700;
}
.locations-and-appointments-edw .locations-modal .modal-dialog {
  max-width: 800px;
}
.locations-and-appointments-edw .locations-modal .modal-content {
  border: 0;
}
.locations-and-appointments-edw .locations-modal .modal-body {
  padding: 2rem;
}
.locations-and-appointments-edw .locations-modal .modal-body:only-child {
  padding: 2rem;
}
.locations-and-appointments-edw .locations-modal .modal-close-btn {
  background: transparent;
  border: 0;
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.locations-and-appointments-edw .locations-modal .modal-close-btn svg {
  height: 1.25rem;
  width: 1.25rem;
}
.locations-and-appointments-edw .locations-modal .location-select-list {
  columns: 1;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.locations-and-appointments-edw .locations-modal .location-select-list li {
  display: block;
  padding: 0.5rem 0;
  break-inside: avoid-column;
}
.locations-and-appointments-edw .locations-modal .location-name {
  font-weight: 700;
}
.locations-and-appointments-edw .button-container {
  margin-bottom: 1.5rem;
  width: 100%;
  text-align: center;
}
.locations-and-appointments-edw .read-more-expanded {
  width: 100%;
}
@media (max-width: 767.98px) {
  .locations-and-appointments-edw .carousel-item {
    float: left;
    margin-bottom: 3rem;
  }
  .locations-and-appointments-edw .locations-modal .location-select-list {
    columns: 2;
  }
  .locations-and-appointments-edw .locations-modal .location-select-list li {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .locations-and-appointments-edw.no-online-scheduling.single-location .locations .carousel-item .location-details {
    padding-left: 30px;
  }
  .locations-and-appointments-edw.no-online-scheduling.single-location .appointments {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    display: block;
  }
  .locations-and-appointments-edw.no-online-scheduling.single-location .appointments .appointment-request-container {
    display: block;
  }
  .locations-and-appointments-edw.no-online-scheduling.two-locations .locations {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .locations-and-appointments-edw.no-online-scheduling.two-locations .locations .carousel-inner {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .locations-and-appointments-edw.no-online-scheduling.two-locations .locations .carousel-item {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 50%;
    max-width: 50%;
    display: block;
    margin-right: 0;
  }
  .locations-and-appointments-edw.no-online-scheduling.two-locations .appointments {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    display: block;
  }
  .locations-and-appointments-edw.no-online-scheduling.two-locations .appointments .appointment-request-container {
    display: block;
  }
  .locations-and-appointments-edw.no-online-scheduling.three-locations .locations {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .locations-and-appointments-edw.no-online-scheduling.three-locations .locations .carousel-inner {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    width: auto;
  }
  .locations-and-appointments-edw.no-online-scheduling.three-locations .locations .carousel-item {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    display: block;
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
  .locations-and-appointments-edw.no-online-scheduling.three-locations .appointments {
    flex: 0 0 100%;
    max-width: 100%;
    display: block;
  }
  .locations-and-appointments-edw.no-online-scheduling.three-locations .appointments .appointment-request-container {
    display: block;
  }
  .locations-and-appointments-edw.no-online-scheduling .locations .distance-and-directions {
    margin-bottom: 0;
  }
  .locations-and-appointments-edw.no-online-scheduling .appointments .appointments-inner {
    align-content: center;
    height: auto;
  }
  .locations-and-appointments-edw.no-online-scheduling .appointments .date-range-selection,
.locations-and-appointments-edw.no-online-scheduling .appointments .appointment-selection,
.locations-and-appointments-edw.no-online-scheduling .appointments .legend {
    display: none;
  }
  .locations-and-appointments-edw.no-online-scheduling .appointments .appointment-request-container {
    padding: 0;
    width: 100%;
  }
  .locations-and-appointments-edw.no-online-scheduling .appointments .appointment-request-container svg {
    flex: 0 0 2.25rem;
    height: 2.25rem;
    width: 2.25rem;
  }
  .locations-and-appointments-edw.no-online-scheduling .appointments .appointment-request-container .appointment-request-phone {
    font-size: 1rem;
    line-height: 1.125;
    padding: 0;
  }
  .locations-and-appointments-edw.no-online-scheduling .appointments .appointment-request-container a {
    display: block;
  }
  .locations-and-appointments-edw.no-online-scheduling .read-more.is-expanded .read-more-expanded {
    display: flex;
    flex-wrap: wrap;
  }
  .locations-and-appointments-edw.no-online-scheduling .carousel-controls,
.locations-and-appointments-edw.no-online-scheduling .carousel-indicators, .locations-and-appointments-edw.practice-members .carousel-controls,
.locations-and-appointments-edw.practice-members .carousel-indicators {
    display: none;
  }
  .locations-and-appointments-edw .carousel-control-prev,
.locations-and-appointments-edw .carousel-control-next {
    background-color: #146B9A;
    opacity: 1;
    padding: 1.5rem 0.5rem;
    position: relative;
    width: auto;
  }
  .locations-and-appointments-edw .carousel-control-prev .icon,
.locations-and-appointments-edw .carousel-control-next .icon {
    fill: #fff;
  }
  .locations-and-appointments-edw .carousel-controls {
    align-items: center;
    margin-top: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
  .locations-and-appointments-edw .carousel-indicators {
    display: flex;
  }
  .locations-and-appointments-edw .carousel-indicators li {
    background-color: #3d5d6b;
    border-radius: 50%;
    height: 12px;
    margin: 0 4px;
    width: 12px;
  }
  .locations-and-appointments-edw .carousel-indicators li.active {
    pointer-events: none;
  }
  .locations-and-appointments-edw .view-other-locations {
    font-size: 1rem;
    line-height: 1.5;
  }
  .locations-and-appointments-edw .locations {
    margin-bottom: 0;
  }
  .locations-and-appointments-edw .location-details {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
  }
  .locations-and-appointments-edw .location-details .subsection {
    width: 50%;
  }
  .locations-and-appointments-edw .location-details .subsection.directions {
    order: 1;
  }
  .locations-and-appointments-edw .location-details .subsection.contact-info {
    order: 3;
  }
  .locations-and-appointments-edw .location-details .subsection.office-hours {
    order: 2;
  }
  .locations-and-appointments-edw .office-hours-list {
    margin-bottom: 0;
  }
  .locations-and-appointments-edw .carousel-item.no-appointments .appointment-request-container, .locations-and-appointments-edw .carousel-item.no-online-scheduling .appointment-request-container {
    display: block;
  }
  .locations-and-appointments-edw .carousel-item.is-telemedicine {
    position: relative;
  }
  .locations-and-appointments-edw .carousel-item.is-telemedicine .location-details {
    background-color: #f5f5f6;
    height: 400px;
  }
  .locations-and-appointments-edw .carousel-item.is-telemedicine .location-details .subsection-title {
    align-items: center;
    background: url("/assets/images/telemedicine.png");
    background-position-y: 0;
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    height: 400px;
    justify-content: flex-end;
    padding-bottom: 4rem;
  }
  .locations-and-appointments-edw .carousel-item.is-telemedicine .location-details .subsection-title svg {
    height: 3rem;
    width: 3rem;
    margin-bottom: 1rem;
  }
  .locations-and-appointments-edw .legend {
    margin: 1.5rem auto 0;
    order: 2;
  }
  .locations-and-appointments-edw .appointments {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
    display: block;
  }
  .locations-and-appointments-edw .appointment-selection {
    margin-left: -1rem;
    margin-right: -1rem;
    width: calc(100% + 2rem);
  }
  .locations-and-appointments-edw .available-providers {
    display: block;
    padding-top: initial;
    padding-left: initial;
    padding-right: initial;
  }
  .locations-and-appointments-edw .provider-list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 1rem;
    list-style: none;
  }
  .locations-and-appointments-edw .provider-list .provider-list-item {
    display: block;
    width: 50%;
  }
  .locations-and-appointments-edw .provider-list .provider-list-item.active .provider-item {
    background-color: #f2f9ff;
    pointer-events: none;
  }
  .locations-and-appointments-edw .provider-list .provider-list-item.active .cta-link {
    display: none;
  }
  .locations-and-appointments-edw .provider-list .provider-item {
    background: transparent;
    border: none;
    display: flex;
    padding: 1rem 0.5rem;
    width: 100%;
  }
  .locations-and-appointments-edw .provider-list .provider-item:hover, .locations-and-appointments-edw .provider-list .provider-item:focus {
    background-color: #f2f9ff;
    outline: none;
    text-decoration: none;
  }
  .locations-and-appointments-edw .provider-list .provider-item:hover .cta-link, .locations-and-appointments-edw .provider-list .provider-item:focus .cta-link {
    text-decoration: underline;
  }
  .locations-and-appointments-edw .provider-list .provider-item svg {
    pointer-events: none;
  }
  .locations-and-appointments-edw .provider-list .profile-image {
    height: 90px;
    width: 90px;
    min-width: 90px;
  }
  .locations-and-appointments-edw .provider-list .profile-content {
    font-size: 1rem;
    line-height: 1.125;
    color: #3d5d6b;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 1rem;
    text-align: left;
    height: 85px;
  }
  .locations-and-appointments-edw .provider-list .profile-content-main {
    flex-grow: 1;
  }
  .locations-and-appointments-edw .provider-list .profile-name {
    font-size: 1rem;
    line-height: 1.125;
    color: #146B9A;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .locations-and-appointments-edw .provider-list .cta-link {
    color: #146B9A;
    font-weight: 700;
  }
  .locations-and-appointments-edw .locations-modal .modal-dialog {
    max-width: 800px;
  }
  .locations-and-appointments-edw .locations-modal .modal-content {
    border: 0;
  }
  .locations-and-appointments-edw .locations-modal .modal-body {
    padding: 2rem;
  }
  .locations-and-appointments-edw .locations-modal .modal-body:only-child {
    padding: 2rem;
  }
  .locations-and-appointments-edw .locations-modal .modal-close-btn {
    background: transparent;
    border: 0;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  .locations-and-appointments-edw .locations-modal .modal-close-btn svg {
    height: 1.25rem;
    width: 1.25rem;
  }
  .locations-and-appointments-edw .locations-modal .location-select-list {
    columns: 2;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }
  .locations-and-appointments-edw .locations-modal .location-select-list li {
    display: inline-block;
    padding: 0.5rem 0;
    break-inside: avoid-column;
  }
  .locations-and-appointments-edw .locations-modal .location-name {
    font-weight: 700;
  }
}
@media (min-width: 992px) {
  .locations-and-appointments-edw .appointments {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .locations-and-appointments-edw .radio-wrapper, .locations-and-appointments-edw .marketo-form .mktoForm .mktoRadioList, .marketo-form .mktoForm .locations-and-appointments-edw .mktoRadioList {
    display: inline-flex;
    margin-right: 1rem;
  }
}
@media (min-width: 1200px) {
  .locations-and-appointments-edw .location-details .subsection {
    width: auto;
  }
  .locations-and-appointments-edw .location-details .subsection.directions {
    order: 1;
    flex-grow: 1;
  }
  .locations-and-appointments-edw .location-details .subsection.contact-info {
    order: 2;
    flex-grow: 2;
  }
  .locations-and-appointments-edw .location-details .subsection.office-hours {
    order: 3;
    flex-grow: 2;
  }
  .locations-and-appointments-edw .legend {
    margin: 0;
    order: 0;
  }
  .locations-and-appointments-edw .provider-list .provider-list-item {
    width: 33.33%;
  }
  .locations-and-appointments-edw .appointments-summary {
    flex-flow: row;
  }
  .locations-and-appointments-edw .appointments-summary .location-address {
    margin-right: 2rem;
    flex: 2 2 0;
  }
  .locations-and-appointments-edw .appointments-summary .btn, .locations-and-appointments-edw .appointments-summary .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .locations-and-appointments-edw .appointments-summary .mktoButton {
    align-self: flex-start;
    margin-top: 1.25rem;
  }
}

.profile-insurance {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .profile-insurance {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .profile-insurance {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .profile-insurance {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .profile-insurance {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.profile-insurance .insurance-list {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #f5f5f6;
  border-radius: 5px;
  padding: 2.5rem;
}
.profile-insurance .insurance-list li {
  display: inline-block;
  margin: 0;
  padding-bottom: 0.375rem;
  width: 100%;
}
.profile-insurance .notation {
  font-size: 1rem;
  line-height: 1.125;
  margin-bottom: 0.75rem;
}
.profile-insurance .read-more {
  border-radius: 5px;
}
.profile-insurance .button-container {
  margin-bottom: 4rem;
  margin-top: -2.5rem;
  background-color: #f5f5f6;
  height: 5rem;
  border-radius: 5px;
}
.profile-insurance .button-container .read-more-link {
  position: relative;
}
.profile-insurance .button-container .read-more-link.is-expanded {
  font-size: 1rem;
  line-height: 1.125;
  display: inline-block;
  font-weight: 700;
}
.profile-insurance .section-title:after {
  background-color: #ffcd55;
}
@media (min-width: 576px) {
  .profile-insurance .insurance-list {
    columns: 2;
    column-gap: 30px;
  }
  .profile-insurance .insurance-list li {
    padding-bottom: 0.75rem;
  }
}
@media (min-width: 992px) {
  .profile-insurance .read-more .read-more-body {
    max-height: none;
  }
  .profile-insurance .read-more .button-container {
    display: none;
  }
  .profile-insurance .insurance-list {
    columns: 3;
  }
  .profile-insurance .toggle-expand {
    display: none;
  }
}
@media (min-width: 1200px) {
  .profile-insurance .insurance-list {
    columns: 4;
  }
}

.profile-meet-the-provider {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .profile-meet-the-provider {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .profile-meet-the-provider {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .profile-meet-the-provider {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .profile-meet-the-provider {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.profile-meet-the-provider .section-title:after {
  background-color: #ffcd55;
}
.profile-meet-the-provider .read-more .read-more-body {
  max-height: 350px;
  border-radius: 5px;
}
.profile-meet-the-provider .button-container {
  margin-bottom: 4rem;
  margin-top: -2.5rem;
  background-color: #f5f5f6;
  height: 5rem;
  border-radius: 5px;
}
.profile-meet-the-provider .button-container .read-more-link {
  position: relative;
}
.profile-meet-the-provider .button-container .read-more-link.is-expanded {
  font-size: 1rem;
  line-height: 1.125;
  display: inline-block;
  font-weight: 700;
}
.profile-meet-the-provider .card-subtitle {
  margin-bottom: 0.5rem;
}
.profile-meet-the-provider .section-subtitle {
  color: #2c373f;
}
.profile-meet-the-provider .provider-snippets {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  background-color: #f5f5f6;
  padding: 3rem;
}
.profile-meet-the-provider .provider-snippet {
  margin-bottom: 3rem;
  padding-right: 3rem;
}
.profile-meet-the-provider .provider-snippet:last-child {
  margin-bottom: 0;
}
.profile-meet-the-provider .provider-snippet p {
  font-size: 1.125rem;
  line-height: 1.1111111111;
}
.profile-meet-the-provider .provider-snippet li {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
.profile-meet-the-provider .provider-snippet .read-more {
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .profile-meet-the-provider .provider-snippets {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    background-color: #f5f5f6;
    padding: 3rem;
  }
  .profile-meet-the-provider .provider-snippet {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 2rem;
  }
  .profile-meet-the-provider .provider-snippet .read-more {
    margin-bottom: 3rem;
  }
}
@media (min-width: 992px) {
  .profile-meet-the-provider .read-more .read-more-body {
    max-height: none;
  }
  .profile-meet-the-provider .read-more .button-container {
    display: none;
  }
  .profile-meet-the-provider .provider-snippet {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .profile-meet-the-provider .toggle-expand {
    display: none;
  }
}

.urgent-care-check-in {
  background-color: #f5f5f6;
  padding: 2rem;
  margin-bottom: 2rem;
}
.urgent-care-check-in .appointment-heading {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #2c373f;
  font-weight: 700;
  margin-bottom: 1.75rem;
}
.urgent-care-check-in .wait-time,
.urgent-care-check-in .people-in-line {
  font-size: 1rem;
  line-height: 1.125;
  color: #00487c;
  font-weight: 700;
}
.urgent-care-check-in .wait-time {
  border-bottom: 1px solid #a2c3d6;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}
.urgent-care-check-in .people-in-line {
  margin-bottom: 1.75rem;
}
@media (min-width: 768px) {
  .urgent-care-check-in {
    float: right;
    margin-left: 2rem;
    width: 388px;
  }
}

.profile-ratings {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .profile-ratings {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .profile-ratings {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .profile-ratings {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .profile-ratings {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.profile-ratings .section-title {
  margin-bottom: 0;
}
.profile-ratings .section-title:after {
  background-color: #ffcd55;
}
.profile-ratings .read-more .read-more-body {
  max-height: 350px;
}
.profile-ratings .read-more .read-more-link {
  position: absolute;
}
.profile-ratings .read-more .read-more-link.is-expanded {
  font-size: 1rem;
  line-height: 1.125;
  bottom: 0;
  display: inline-block;
  font-weight: 700;
  left: 0;
  position: relative;
  padding: 2rem 0;
  transform: none;
  text-align: center;
}
.profile-ratings .button-container {
  margin-bottom: 4rem;
  margin-top: -2.5rem;
  background-color: #f5f5f6;
  height: 5rem;
  border-radius: 5px;
}
.profile-ratings .button-container .read-more-link {
  position: relative;
}
.profile-ratings .button-container .read-more-link.is-expanded {
  font-size: 1rem;
  line-height: 1.125;
  display: inline-block;
  font-weight: 700;
}
.profile-ratings .ratings-grid {
  background-color: #f5f5f6;
  display: flex;
  padding: 1rem 2rem 2rem;
  margin-top: 2rem;
  flex-direction: column;
  border-radius: 5px;
}
.profile-ratings .overall-rating {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-bottom: 1.75rem;
}
.profile-ratings .overall-rating .rating-number {
  color: #2c373f;
  font-size: 80px;
  margin-bottom: 0.5rem;
}
.profile-ratings .overall-rating .rating-count {
  font-size: 1rem;
  line-height: 1.125;
  color: #3d5d6b;
  margin-top: 0.5rem;
}
.profile-ratings .reviews-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.profile-ratings .reviews-list li:before {
  display: none;
}
.profile-ratings .reviews-list li:not(:last-of-type) {
  margin-bottom: 1.625rem;
}
.profile-ratings .reviews-list li .review-rating {
  font-size: 1.5rem;
  line-height: 1.25;
  align-items: center;
  color: #2c373f;
  display: flex;
  font-weight: 700;
}
.profile-ratings .reviews-list li .review-rating .rating-number {
  flex-basis: 2.5rem;
  min-width: 2.5rem;
}
.profile-ratings .reviews-list li .review-rating .rating-stars {
  margin-left: 0.75rem;
}
.profile-ratings .reviews-list li .review-text {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #006D9D;
  margin-top: 0.5rem;
}
.profile-ratings .reviews-list li .review-text.rating-only {
  color: #a2c3d6;
}
.profile-ratings .reviews-list li .review-text .review-title {
  font-weight: 600;
}
.profile-ratings .reviews-list li .review-text .review-title + .review-comment {
  margin-top: 0.5rem;
}
@media (min-width: 768px) {
  .profile-ratings .read-more:not(.is-expanded) .read-more-body {
    max-height: 300px;
  }
  .profile-ratings .ratings-grid {
    display: flex;
    flex-direction: row;
  }
  .profile-ratings .overall-rating {
    flex-basis: 180px;
    min-width: 180px;
    margin-bottom: 2rem;
    margin-top: 1rem;
    margin-right: 40px;
  }
  .profile-ratings .reviews-list li {
    align-items: flex-start;
    display: flex;
  }
  .profile-ratings .reviews-list li .review-text {
    color: #006D9D;
    margin-left: 3.125rem;
    margin-top: 0.25rem;
  }
}
@media (min-width: 992px) {
  .profile-ratings .overall-rating {
    flex-basis: 240px;
    min-width: 240px;
    margin-right: 80px;
  }
}

.pull-quote {
  background-color: #2c373f;
  border: none;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 3rem 1rem 1.5rem;
}
@media (min-width: 576px) {
  .pull-quote {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .pull-quote {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .pull-quote {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .pull-quote {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.pull-quote.small, .pull-quote.medium {
  flex: 0 0 75%;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
.pull-quote.large {
  flex: 0 0 100%;
  width: 100%;
}
.pull-quote .card-header {
  background-color: transparent;
  border-bottom: none;
  color: #fff;
  padding-top: 0;
  padding-bottom: 0.5rem;
  text-align: center;
}
.pull-quote .profile-image {
  background-color: #2c373f;
  border-color: #ffcd55;
  font-size: 69px;
  font-weight: 600;
  height: 4rem;
  margin-top: 0rem;
  margin-right: auto;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
  width: 4rem;
  min-width: 4rem;
}
.pull-quote .profile-image span {
  top: -0.6875rem;
  position: relative;
  color: #ffcd55;
}
.pull-quote .card-body {
  background-color: transparent;
  border-bottom: none;
  padding-top: 0;
  padding-right: 3rem;
  padding-bottom: 0.5rem;
  padding-left: 3rem;
}
.pull-quote .card-body p {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #fff;
  font-weight: 600;
}
.pull-quote .card-body p:last-child {
  margin-bottom: 0;
}
.pull-quote .card-footer {
  background-color: transparent;
  border-top: none;
  color: #fff;
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-left: 3rem;
}
.pull-quote .card-footer .quote-name {
  font-size: 1rem;
  line-height: 1.125;
  display: inline-block;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}
.pull-quote .card-footer .designation {
  text-transform: uppercase;
}
.pull-quote .card-footer .title {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  display: block;
  margin-bottom: 0.25rem;
  font-style: italic;
}
@media (min-width: 768px) {
  .pull-quote {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  .two-column .pull-quote.medium .card-body {
    padding-left: 10rem;
    padding-right: 3rem;
  }
  .pull-quote .card-header {
    border-bottom: none;
    padding-bottom: 0.5rem;
    position: absolute;
    top: 1.325rem;
  }
  .pull-quote .card-footer {
    padding-left: 10rem;
  }
  .pull-quote.left {
    float: left;
    margin-right: 2rem;
  }
  .pull-quote.center {
    margin-left: auto;
    margin-right: auto;
  }
  .pull-quote.right {
    float: right;
    margin-left: 2rem;
  }
  .pull-quote.small {
    flex: 0 0 33.33333%;
    width: 33.33333%;
  }
  .pull-quote.small .card-header {
    border-bottom: none;
    padding-bottom: 0.5rem;
    position: relative;
    top: 0;
  }
  .pull-quote.small .card-body {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .pull-quote.small .card-footer {
    padding-left: 3rem;
  }
  .pull-quote.medium {
    flex: 0 0 66.66666%;
    width: 66.66666%;
  }
  .pull-quote.medium .card-body {
    padding-left: 10rem;
    padding-right: 3rem;
  }
  .pull-quote.large {
    flex: 0 0 100%;
    width: 100%;
  }
  .pull-quote.large .card-body {
    padding-left: 10rem;
    padding-right: 3rem;
  }
}
@media (min-width: 992px) {
  .two-column .pull-quote.large .card-body {
    padding-left: 10rem;
    padding-right: 5rem;
  }
}
@media (min-width: 1200px) {
  .two-column .pull-quote.large .card-body {
    padding-left: 10rem;
    padding-right: 10rem;
  }
}

.quiz {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .quiz {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .quiz {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .quiz {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .quiz {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.quiz .question-panel {
  display: none;
}
.quiz .question-panel.active {
  display: block;
}
.quiz .quiz-card {
  background-color: #f5f5f6;
}
.quiz .heading-4 {
  color: #2c373f;
  margin-bottom: 1.75rem;
}
.quiz .validation-message,
.quiz .contact-validation-message,
.quiz .email-validation-message {
  color: #b23838;
  display: none;
}
.quiz .validation-message.active,
.quiz .contact-validation-message.active,
.quiz .email-validation-message.active {
  display: block;
}
.quiz .form-control, .quiz .share-story-modal form input[type=text], .share-story-modal form .quiz input[type=text],
.quiz .share-story-modal form input[type=email],
.share-story-modal form .quiz input[type=email],
.quiz .share-story-modal form input[type=tel],
.share-story-modal form .quiz input[type=tel],
.quiz .share-story-modal form select,
.share-story-modal form .quiz select,
.quiz .share-story-modal form textarea,
.share-story-modal form .quiz textarea, .quiz html .microsite-theme .marketo-form .mktoForm .mktoEmailField, html .microsite-theme .marketo-form .mktoForm .quiz .mktoEmailField,
.quiz html .microsite-theme .marketo-form .mktoForm .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .quiz .mktoTelField,
.quiz html .microsite-theme .marketo-form .mktoForm .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .quiz .mktoTextField,
.quiz html .microsite-theme .marketo-form .mktoForm select,
html .microsite-theme .marketo-form .mktoForm .quiz select,
.quiz html .microsite-theme .marketo-form .mktoForm textarea,
html .microsite-theme .marketo-form .mktoForm .quiz textarea,
.quiz html .moments-theme .marketo-form .mktoForm .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .quiz .mktoEmailField,
.quiz html .moments-theme .marketo-form .mktoForm .mktoTelField,
html .moments-theme .marketo-form .mktoForm .quiz .mktoTelField,
.quiz html .moments-theme .marketo-form .mktoForm .mktoTextField,
html .moments-theme .marketo-form .mktoForm .quiz .mktoTextField,
.quiz html .moments-theme .marketo-form .mktoForm select,
html .moments-theme .marketo-form .mktoForm .quiz select,
.quiz html .moments-theme .marketo-form .mktoForm textarea,
html .moments-theme .marketo-form .mktoForm .quiz textarea, .quiz textarea, .quiz .bmi-calculator input[type=text], .bmi-calculator .quiz input[type=text],
.quiz .bmi-calculator select,
.bmi-calculator .quiz select, .quiz .marketo-form .mktoForm .mktoEmailField, .marketo-form .mktoForm .quiz .mktoEmailField,
.quiz .marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .quiz .mktoTelField,
.quiz .marketo-form .mktoForm .mktoTextField,
.marketo-form .mktoForm .quiz .mktoTextField, .quiz .marketo-form .mktoForm select, .marketo-form .mktoForm .quiz select,
.quiz .marketo-form .mktoForm textarea,
.marketo-form .mktoForm .quiz textarea {
  margin-bottom: 1rem;
}
.quiz .form-control[type=email],
.quiz .share-story-modal form input[type=email],
.share-story-modal form .quiz input[type=email],
.quiz .share-story-modal form select[type=email],
.share-story-modal form .quiz select[type=email],
.quiz .share-story-modal form textarea[type=email],
.share-story-modal form .quiz textarea[type=email], .quiz textarea[type=email], .quiz .bmi-calculator input[type=email][type=text], .bmi-calculator .quiz input[type=email][type=text],
.quiz .bmi-calculator select[type=email],
.bmi-calculator .quiz select[type=email], .quiz .marketo-form .mktoForm [type=email].mktoEmailField, .marketo-form .mktoForm .quiz [type=email].mktoEmailField,
.quiz .marketo-form .mktoForm [type=email].mktoTelField,
.marketo-form .mktoForm .quiz [type=email].mktoTelField,
.quiz .marketo-form .mktoForm [type=email].mktoTextField,
.marketo-form .mktoForm .quiz [type=email].mktoTextField, .quiz .marketo-form .mktoForm select[type=email], .marketo-form .mktoForm .quiz select[type=email],
.quiz .marketo-form .mktoForm textarea[type=email],
.marketo-form .mktoForm .quiz textarea[type=email] {
  margin-bottom: 0;
}
.quiz .form-control[type=email].invalid + .email-validation-message,
.quiz .share-story-modal form input[type=email].invalid + .email-validation-message,
.share-story-modal form .quiz input[type=email].invalid + .email-validation-message,
.quiz .share-story-modal form select[type=email].invalid + .email-validation-message,
.share-story-modal form .quiz select[type=email].invalid + .email-validation-message,
.quiz .share-story-modal form textarea[type=email].invalid + .email-validation-message, .quiz textarea[type=email].invalid + .email-validation-message, .quiz .bmi-calculator input[type=email].invalid[type=text] + .email-validation-message, .bmi-calculator .quiz input[type=email].invalid[type=text] + .email-validation-message,
.quiz .bmi-calculator select[type=email].invalid + .email-validation-message,
.bmi-calculator .quiz select[type=email].invalid + .email-validation-message, .quiz .marketo-form .mktoForm [type=email].invalid.mktoEmailField + .email-validation-message, .marketo-form .mktoForm .quiz [type=email].invalid.mktoEmailField + .email-validation-message,
.quiz .marketo-form .mktoForm [type=email].invalid.mktoTelField + .email-validation-message,
.marketo-form .mktoForm .quiz [type=email].invalid.mktoTelField + .email-validation-message,
.quiz .marketo-form .mktoForm [type=email].invalid.mktoTextField + .email-validation-message,
.marketo-form .mktoForm .quiz [type=email].invalid.mktoTextField + .email-validation-message, .quiz .marketo-form .mktoForm select[type=email].invalid + .email-validation-message, .marketo-form .mktoForm .quiz select[type=email].invalid + .email-validation-message,
.quiz .marketo-form .mktoForm textarea[type=email].invalid + .email-validation-message {
  display: block;
}
.quiz input[type=text], .quiz input[type=email], .quiz input[type=search] {
  background-color: #fff;
}
.quiz input[type=text]:hover, .quiz input[type=email]:hover, .quiz input[type=search]:hover {
  border-color: #006D9D;
}
.quiz .success-message,
.quiz .failure-message {
  display: none;
}
.quiz .success-message.active,
.quiz .failure-message.active {
  display: block;
}
.quiz .btn, .quiz .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .quiz .mktoButton {
  display: block;
  margin: 2rem auto 0;
}
.quiz .progress-bar-container {
  background-color: #cbced1;
  position: relative;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 2rem;
}
.quiz .progress-bar {
  width: 0%;
  height: 100%;
  background-color: #005557;
  text-align: center;
  line-height: 30px;
  transition: width 0.6s ease-in-out;
}
.quiz .progress-percent {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #fff;
  display: flex;
  justify-content: center;
  font-weight: 600;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
@media (min-width: 768px) {
  .quiz .quiz-card {
    padding: 2.5rem 2.25rem;
  }
}

.quizlet {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background-color: #f2f9ff;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .quizlet {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .quizlet {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .quizlet {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .quizlet {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.quizlet.intro .quizlet-progress,
.quizlet.intro form {
  display: none;
}
.quizlet.intro .quizlet-intro {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.quizlet.intro .quizlet-intro .marketo-form input[type=checkbox]:checked + label:after {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}
.quizlet.intro .quizlet-intro form {
  display: block;
}
.quizlet.completed .quizlet-heading,
.quizlet.completed .quizlet-progress-container {
  display: none;
}
.quizlet.completed .retake-btn {
  display: block;
  margin-top: 1.5rem;
}
.quizlet .inner-container {
  padding: 2rem 0;
  background-color: #f2f9ff;
}
.quizlet .quizlet-heading,
.quizlet .outcome-heading {
  border: none;
  color: #2c373f;
  margin-bottom: 2rem;
  text-align: center;
}
.quizlet .quizlet-intro {
  display: none;
}
.quizlet .quizlet-progress {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  position: relative;
}
.quizlet .quizlet-progress.center-sm {
  justify-content: center;
}
.quizlet .quizlet-progress .start-over-btn {
  display: none;
  font-size: 0.875rem;
  line-height: 1.1428571429;
  font-weight: 600;
  padding: 0 0 2rem;
  position: relative;
  left: 0;
  width: 100%;
  text-align: center;
}
.quizlet .quizlet-progress .start-over-btn.visible {
  display: block;
}
.quizlet .question-indicator {
  display: flex;
  flex: 0 1 20%;
  justify-content: center;
  margin-bottom: 1.125rem;
  position: relative;
}
.quizlet .question-indicator:not(:first-of-type):before {
  content: "";
  display: block;
  width: 100%;
  background-color: #006D9D;
  height: 1px;
  left: -50%;
  position: absolute;
  top: 50%;
}
.quizlet .question-indicator:nth-of-type(5n + 1):before {
  display: none;
}
.quizlet .question-indicator.completed .question-number {
  background-color: #2c373f;
  border-color: #2c373f;
  color: #cbced1;
}
.quizlet .question-indicator.active .question-number {
  background-color: #005557;
  border-color: #005557;
  color: #fff;
}
.quizlet .question-number {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  align-items: center;
  background-color: #fff;
  border: 2px solid #005557;
  border-radius: 15px;
  color: #005557;
  display: flex;
  font-weight: 700;
  height: 30px;
  justify-content: center;
  position: relative;
  width: 30px;
  z-index: 1;
}
.quizlet .question-list {
  padding: 0;
  list-style-type: none;
}
.quizlet .question-panel {
  display: none;
}
.quizlet .question-panel.active {
  display: block;
}
.quizlet .question-desc {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #000;
  margin: 0 auto 2rem;
}
.quizlet .answer-grid {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}
.quizlet .answer-grid.true-false:after {
  display: none;
}
.quizlet .answer-grid.true-false .answer-item {
  flex: 0 1 48.5%;
  max-width: 48.5%;
}
.quizlet .answer-grid.true-false .answer-item:first-of-type label {
  margin-left: auto;
}
.quizlet .answer-grid.true-false .answer-content {
  padding-bottom: 1rem;
}
.quizlet .answer-grid.true-false input[type=radio] + label {
  max-width: 190px;
}
.quizlet .answer-grid.true-false input[type=radio] + label:before {
  bottom: 50%;
  left: auto;
  right: 1rem;
  transform: translateY(50%);
}
.quizlet .answer-grid.true-false input[type=radio] + label:after {
  bottom: 50%;
  left: auto;
  margin: 0;
  right: 1.125rem;
  transform: translateY(50%);
}
.quizlet .answer-grid.agree-disagree {
  flex-direction: column;
  position: relative;
}
.quizlet .answer-grid.agree-disagree:before {
  content: "";
  display: block;
  width: 1px;
  background-color: #006D9D;
  height: 80%;
  position: absolute;
  left: 12px;
  top: 11px;
  transform: translateX(-50%);
}
.quizlet .answer-grid.agree-disagree .answer-item {
  flex: 0 1 100%;
  max-width: 100%;
}
.quizlet .answer-grid.agree-disagree .answer-content {
  background-color: transparent;
  padding: 0.125rem 0 0 2rem;
}
.quizlet .answer-grid.agree-disagree input[type=radio]:checked + label {
  border-color: transparent;
}
.quizlet .answer-grid.agree-disagree input[type=radio] + label:before {
  background-color: #f2f9ff;
  bottom: auto;
  left: 0;
  margin: 0;
}
.quizlet .answer-grid.agree-disagree input[type=radio] + label:after {
  bottom: auto;
  left: 2px;
  margin: 0;
  top: 2px;
}
.quizlet .answer-item {
  flex: 0 1 48.5%;
  margin-bottom: 2rem;
  max-width: 48.5%;
  position: relative;
}
.quizlet .answer-item label {
  font-size: 1rem;
  line-height: 1.125;
  border: 2px solid transparent;
  color: #2c373f;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  position: relative;
}
.quizlet .answer-content {
  background-color: #fff;
  display: block;
  height: 100%;
  padding: 1rem 1rem 3.5rem;
  border-radius: 5px;
}
.quizlet input[type=checkbox] {
  margin-top: 2px;
  opacity: 0;
}
.quizlet input[type=checkbox]:checked + label {
  border-color: #a2c3d6;
  border-radius: 5px;
}
.quizlet input[type=checkbox]:checked + label .answer-content {
  background-color: transparent;
}
.quizlet input[type=checkbox]:checked + label:before {
  background-color: #2c373f;
}
.quizlet input[type=checkbox]:checked + label:after {
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  content: "";
  display: block;
  height: 1.375rem;
  right: 1rem;
  margin: 0.375rem 0.625rem;
  position: absolute;
  bottom: 1rem;
  transform: rotate(45deg) translate(-2px, -3px);
  width: 0.875rem;
}
.quizlet input[type=checkbox]:hover {
  cursor: pointer;
}
.quizlet input[type=checkbox]:hover + label:before {
  border-color: #006D9D;
}
.quizlet input[type=checkbox]:focus + label:before {
  box-shadow: 0 0 0 0.2rem rgba(0, 109, 157, 0.25);
}
.quizlet input[type=checkbox] + label:before {
  border: 2px solid #2c373f;
  border-radius: 3px;
  content: "";
  display: block;
  height: 2.125rem;
  right: 1rem;
  position: absolute;
  bottom: 1rem;
  width: 2.125rem;
}
.quizlet input[type=checkbox] + label:hover {
  cursor: pointer;
}
.quizlet input[type=checkbox] + label:hover:before {
  border-color: #006D9D;
}
.quizlet input[type=radio] {
  opacity: 0;
  position: absolute;
}
.quizlet input[type=radio]:checked + label {
  border-color: #a2c3d6;
  border-radius: 5px;
}
.quizlet input[type=radio]:checked + label .answer-content {
  background-color: transparent;
}
.quizlet input[type=radio]:checked + label:before {
  border-color: #fff;
}
.quizlet input[type=radio]:checked + label:after {
  background-color: #2c373f;
  border-radius: 50%;
  content: "";
  display: block;
  height: 1rem;
  bottom: 1.25rem;
  left: 0;
  right: 0;
  margin: 0.125rem auto;
  position: absolute;
  width: 1rem;
}
.quizlet input[type=radio]:checked:hover + label:before {
  border-color: #fff;
}
.quizlet input[type=radio]:hover {
  cursor: pointer;
}
.quizlet input[type=radio]:hover + label:before {
  border-color: #006D9D;
}
.quizlet input[type=radio]:focus + label:before {
  box-shadow: 0 0 0 0.2rem rgba(0, 109, 157, 0.25);
}
.quizlet input[type=radio] + label:before {
  border: 2px solid #2c373f;
  border-radius: 50%;
  content: "";
  display: block;
  height: 1.25rem;
  bottom: 1.25rem;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  width: 1.25rem;
}
.quizlet input[type=radio] + label:hover {
  cursor: pointer;
}
.quizlet input[type=radio] + label:hover:before {
  border-color: #006D9D;
}
.quizlet .answer-image-container {
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: 40%;
  position: relative;
  width: 100%;
}
.quizlet .answer-image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.quizlet .answer-image img {
  font-family: "object-fit: cover; object-position: center 25%;";
  height: 100%;
  object-fit: cover;
  object-position: center 25%;
  width: 100%;
}
.quizlet .validation-message,
.quizlet .contact-validation-message,
.quizlet .email-validation-message {
  color: #b23838;
  display: none;
}
.quizlet .validation-message.active,
.quizlet .contact-validation-message.active,
.quizlet .email-validation-message.active {
  display: block;
}
.quizlet .form-control, .quizlet .share-story-modal form input[type=text], .share-story-modal form .quizlet input[type=text],
.quizlet .share-story-modal form input[type=email],
.share-story-modal form .quizlet input[type=email],
.quizlet .share-story-modal form input[type=tel],
.share-story-modal form .quizlet input[type=tel],
.quizlet .share-story-modal form select,
.share-story-modal form .quizlet select,
.quizlet .share-story-modal form textarea,
.share-story-modal form .quizlet textarea, .quizlet html .microsite-theme .marketo-form .mktoForm .mktoEmailField, html .microsite-theme .marketo-form .mktoForm .quizlet .mktoEmailField,
.quizlet html .microsite-theme .marketo-form .mktoForm .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .quizlet .mktoTelField,
.quizlet html .microsite-theme .marketo-form .mktoForm .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .quizlet .mktoTextField,
.quizlet html .microsite-theme .marketo-form .mktoForm select,
html .microsite-theme .marketo-form .mktoForm .quizlet select,
.quizlet html .microsite-theme .marketo-form .mktoForm textarea,
html .microsite-theme .marketo-form .mktoForm .quizlet textarea,
.quizlet html .moments-theme .marketo-form .mktoForm .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .quizlet .mktoEmailField,
.quizlet html .moments-theme .marketo-form .mktoForm .mktoTelField,
html .moments-theme .marketo-form .mktoForm .quizlet .mktoTelField,
.quizlet html .moments-theme .marketo-form .mktoForm .mktoTextField,
html .moments-theme .marketo-form .mktoForm .quizlet .mktoTextField,
.quizlet html .moments-theme .marketo-form .mktoForm select,
html .moments-theme .marketo-form .mktoForm .quizlet select,
.quizlet html .moments-theme .marketo-form .mktoForm textarea,
html .moments-theme .marketo-form .mktoForm .quizlet textarea, .quizlet textarea, .quizlet .bmi-calculator input[type=text], .bmi-calculator .quizlet input[type=text],
.quizlet .bmi-calculator select,
.bmi-calculator .quizlet select, .quizlet .marketo-form .mktoForm .mktoEmailField, .marketo-form .mktoForm .quizlet .mktoEmailField,
.quizlet .marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .quizlet .mktoTelField,
.quizlet .marketo-form .mktoForm .mktoTextField,
.marketo-form .mktoForm .quizlet .mktoTextField, .quizlet .marketo-form .mktoForm select, .marketo-form .mktoForm .quizlet select,
.quizlet .marketo-form .mktoForm textarea,
.marketo-form .mktoForm .quizlet textarea {
  margin-bottom: 1rem;
}
.quizlet .form-control[type=email],
.quizlet .share-story-modal form input[type=email],
.share-story-modal form .quizlet input[type=email],
.quizlet .share-story-modal form select[type=email],
.share-story-modal form .quizlet select[type=email],
.quizlet .share-story-modal form textarea[type=email],
.share-story-modal form .quizlet textarea[type=email], .quizlet textarea[type=email], .quizlet .bmi-calculator input[type=email][type=text], .bmi-calculator .quizlet input[type=email][type=text],
.quizlet .bmi-calculator select[type=email],
.bmi-calculator .quizlet select[type=email], .quizlet .marketo-form .mktoForm [type=email].mktoEmailField, .marketo-form .mktoForm .quizlet [type=email].mktoEmailField,
.quizlet .marketo-form .mktoForm [type=email].mktoTelField,
.marketo-form .mktoForm .quizlet [type=email].mktoTelField,
.quizlet .marketo-form .mktoForm [type=email].mktoTextField,
.marketo-form .mktoForm .quizlet [type=email].mktoTextField, .quizlet .marketo-form .mktoForm select[type=email], .marketo-form .mktoForm .quizlet select[type=email],
.quizlet .marketo-form .mktoForm textarea[type=email],
.marketo-form .mktoForm .quizlet textarea[type=email] {
  margin-bottom: 0;
}
.quizlet .form-control[type=email].invalid + .email-validation-message,
.quizlet .share-story-modal form input[type=email].invalid + .email-validation-message,
.share-story-modal form .quizlet input[type=email].invalid + .email-validation-message,
.quizlet .share-story-modal form select[type=email].invalid + .email-validation-message,
.share-story-modal form .quizlet select[type=email].invalid + .email-validation-message,
.quizlet .share-story-modal form textarea[type=email].invalid + .email-validation-message, .quizlet textarea[type=email].invalid + .email-validation-message, .quizlet .bmi-calculator input[type=email].invalid[type=text] + .email-validation-message, .bmi-calculator .quizlet input[type=email].invalid[type=text] + .email-validation-message,
.quizlet .bmi-calculator select[type=email].invalid + .email-validation-message,
.bmi-calculator .quizlet select[type=email].invalid + .email-validation-message, .quizlet .marketo-form .mktoForm [type=email].invalid.mktoEmailField + .email-validation-message, .marketo-form .mktoForm .quizlet [type=email].invalid.mktoEmailField + .email-validation-message,
.quizlet .marketo-form .mktoForm [type=email].invalid.mktoTelField + .email-validation-message,
.marketo-form .mktoForm .quizlet [type=email].invalid.mktoTelField + .email-validation-message,
.quizlet .marketo-form .mktoForm [type=email].invalid.mktoTextField + .email-validation-message,
.marketo-form .mktoForm .quizlet [type=email].invalid.mktoTextField + .email-validation-message, .quizlet .marketo-form .mktoForm select[type=email].invalid + .email-validation-message, .marketo-form .mktoForm .quizlet select[type=email].invalid + .email-validation-message,
.quizlet .marketo-form .mktoForm textarea[type=email].invalid + .email-validation-message {
  display: block;
}
.quizlet .btn, .quizlet .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .quizlet .mktoButton {
  display: block;
  margin-top: 2rem;
}
.quizlet .prev-next-btns {
  display: flex;
  flex-flow: column-reverse;
}
.quizlet .prev-next-btns .btn-prev {
  background-color: #2c373f;
  color: #fff;
}
.quizlet .prev-next-btns .btn-prev:hover {
  cursor: pointer;
  background-color: transparent;
  color: #2c373f;
  border-color: #2c373f;
}
.quizlet .prev-next-btns .btn-prev:hover:before {
  border-color: #2c373f;
}
.quizlet .btn-next,
.quizlet .btn-submit {
  margin-left: auto;
}
.quizlet .reset-btn {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #006D9D;
  font-weight: 400;
  padding: 0;
  margin: auto;
  text-align: left;
}
.quizlet .retake-btn {
  display: none;
}
.quizlet .outcome {
  display: none;
}
.quizlet .outcome.active {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.quizlet .media-item {
  padding: 0;
}
.quizlet button[type=submit].mktoButton {
  display: none !important;
}
.quizlet .marketo-form {
  margin-top: 1.5rem;
  margin-bottom: 0;
}
.quizlet .marketo-form .mktoForm {
  margin-left: auto;
  margin-right: auto;
}
.quizlet .marketo-form .mktoForm .mktoFormRow {
  margin-bottom: 1.5rem !important;
  margin: auto;
}
.quizlet .marketo-form .mktoForm .mktoField {
  background-color: #fff !important;
}
.quizlet .marketo-form .g-recaptcha {
  height: 0;
  margin: 0;
}
@media (min-width: 576px) {
  .quizlet .prev-next-btns {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  }
  .quizlet .prev-next-btns [value=Next] {
    margin-left: auto;
  }
}
@media (min-width: 768px) {
  .quizlet {
    background-color: transparent;
  }
  .quizlet .inner-container {
    padding: 2.5rem 2.25rem;
  }
  .quizlet .question-desc {
    width: 80%;
  }
  .quizlet .answer-grid.agree-disagree {
    flex-direction: row;
    margin: auto;
    position: relative;
    width: 80%;
  }
  .quizlet .answer-grid.agree-disagree:before {
    height: 1px;
    width: 80%;
    left: 0;
    right: 0;
    margin: auto;
    top: 28px;
    transform: translateY(-50%);
  }
  .quizlet .answer-grid.agree-disagree .answer-item {
    flex: 0 1 20%;
    max-width: 20%;
  }
  .quizlet .answer-grid.agree-disagree .answer-content {
    background-color: transparent;
    padding: 3.5rem 1rem 1rem;
  }
  .quizlet .answer-grid.agree-disagree input[type=radio]:checked + label {
    border-color: transparent;
  }
  .quizlet .answer-grid.agree-disagree input[type=radio] + label {
    text-align: center;
  }
  .quizlet .answer-grid.agree-disagree input[type=radio] + label:before {
    background-color: #f2f9ff;
    bottom: auto;
    left: 0;
    margin: auto;
    right: 0;
    top: 1rem;
  }
  .quizlet .answer-grid.agree-disagree input[type=radio] + label:after {
    bottom: auto;
    left: 0;
    margin: auto;
    right: 0;
    top: 1.125rem;
  }
}
@media (min-width: 992px) {
  .quizlet .quizlet-progress-container {
    display: flex;
    justify-content: center;
  }
  .quizlet .quizlet-progress {
    display: inline-flex;
    margin: 0 auto 2rem;
  }
  .quizlet .quizlet-progress.center-lg {
    justify-content: center;
  }
  .quizlet .quizlet-progress .start-over-btn {
    display: none;
    font-size: 0.875rem;
    line-height: 1.1428571429;
    font-weight: 600;
    padding: 0;
    position: absolute;
    top: -25px;
    left: 0;
    width: auto;
  }
  .quizlet .quizlet-progress .start-over-btn.visible {
    display: block;
  }
  .quizlet .quizlet-progress .reset-btn {
    top: 5px;
    transform: translateX(-100%);
  }
  .quizlet .question-indicator {
    flex: 0 1 10%;
    min-width: 50px;
  }
  .quizlet .question-indicator:nth-of-type(5n + 1):before {
    display: block;
  }
  .quizlet .question-indicator:nth-of-type(10n + 1):before {
    display: none;
  }
  .quizlet .answer-grid:after {
    content: " ";
    flex: 0 1 31.5%;
    max-width: 31.5%;
  }
  .quizlet .answer-item {
    flex: 0 1 31.5%;
    margin-bottom: 2.5rem;
    max-width: 31.5%;
  }
}
@media (min-width: 1200px) {
  .quizlet .inner-container {
    padding: 3.5rem 10rem;
  }
  .quizlet .media-item.full-width {
    margin-left: -40px;
    width: calc(100% + 80px);
  }
  .quizlet .media-item.full-width .figure-img {
    max-height: none;
  }
}

.rating-stars {
  display: flex;
  justify-content: space-between;
  width: 130px;
}
.rating-stars .star {
  display: inline-block;
  position: relative;
  height: 18px;
  width: 18px;
}
.rating-stars .star svg {
  fill: #a2c3d6;
  height: 18px;
  width: 18px;
  position: absolute;
  top: 0;
  left: 0;
}
.rating-stars .star .star-fill {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 0;
  height: 18px;
}
.rating-stars .star .star-fill svg {
  fill: #ffcd55;
}
.rating-stars .star:nth-child(1) .star-fill {
  max-width: 100%;
  width: calc((var(--rating) - (1 - 1)) * 100%);
}
.rating-stars .star:nth-child(2) .star-fill {
  max-width: 100%;
  width: calc((var(--rating) - (2 - 1)) * 100%);
}
.rating-stars .star:nth-child(3) .star-fill {
  max-width: 100%;
  width: calc((var(--rating) - (3 - 1)) * 100%);
}
.rating-stars .star:nth-child(4) .star-fill {
  max-width: 100%;
  width: calc((var(--rating) - (4 - 1)) * 100%);
}
.rating-stars .star:nth-child(5) .star-fill {
  max-width: 100%;
  width: calc((var(--rating) - (5 - 1)) * 100%);
}

/**
   * React Bootstrap Typeahead
   *
   * Include this base css in our codebase so that we no longer have
   * to load this file externally in the global layout:
   * https://unpkg.com/react-bootstrap-typeahead@5.1.1/css/Typeahead.css
   */
.rbt .rbt-input-main::-ms-clear {
  display: none;
}

/**
   * Menu
   */
.rbt-menu {
  margin-bottom: 2px;
}

.rbt-menu > .dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
}

.rbt-menu > .dropdown-item:focus {
  outline: none;
}

.rbt-menu-pagination-option {
  text-align: center;
}

/**
   * Multi-select Input
   */
.rbt-input-multi {
  cursor: text;
  overflow: hidden;
  position: relative;
}

.rbt-input-multi.focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  color: #495057;
  outline: 0;
}

.rbt-input-multi.form-control, .share-story-modal form input.rbt-input-multi[type=text],
.share-story-modal form input.rbt-input-multi[type=email],
.share-story-modal form input.rbt-input-multi[type=tel],
.share-story-modal form select.rbt-input-multi,
.share-story-modal form textarea.rbt-input-multi, html .microsite-theme .marketo-form .mktoForm .rbt-input-multi.mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .rbt-input-multi.mktoTelField,
html .microsite-theme .marketo-form .mktoForm .rbt-input-multi.mktoTextField,
html .microsite-theme .marketo-form .mktoForm select.rbt-input-multi,
html .microsite-theme .marketo-form .mktoForm textarea.rbt-input-multi,
html .moments-theme .marketo-form .mktoForm .rbt-input-multi.mktoEmailField,
html .moments-theme .marketo-form .mktoForm .rbt-input-multi.mktoTelField,
html .moments-theme .marketo-form .mktoForm .rbt-input-multi.mktoTextField,
html .moments-theme .marketo-form .mktoForm select.rbt-input-multi,
html .moments-theme .marketo-form .mktoForm textarea.rbt-input-multi, textarea.rbt-input-multi, .bmi-calculator input.rbt-input-multi[type=text],
.bmi-calculator select.rbt-input-multi, .marketo-form .mktoForm .rbt-input-multi.mktoEmailField,
.marketo-form .mktoForm .rbt-input-multi.mktoTelField,
.marketo-form .mktoForm .rbt-input-multi.mktoTextField, .marketo-form .mktoForm select.rbt-input-multi,
.marketo-form .mktoForm textarea.rbt-input-multi {
  height: auto;
}

.rbt-input-multi.form-control[disabled], .share-story-modal form input.rbt-input-multi[disabled][type=text],
.share-story-modal form input.rbt-input-multi[disabled][type=email],
.share-story-modal form input.rbt-input-multi[disabled][type=tel],
.share-story-modal form select.rbt-input-multi[disabled],
.share-story-modal form textarea.rbt-input-multi[disabled], textarea.rbt-input-multi[disabled], .bmi-calculator input.rbt-input-multi[disabled][type=text],
.bmi-calculator select.rbt-input-multi[disabled], .marketo-form .mktoForm .rbt-input-multi[disabled].mktoEmailField,
.marketo-form .mktoForm .rbt-input-multi[disabled].mktoTelField,
.marketo-form .mktoForm .rbt-input-multi[disabled].mktoTextField, .marketo-form .mktoForm select.rbt-input-multi[disabled],
.marketo-form .mktoForm textarea.rbt-input-multi[disabled] {
  background-color: #e9ecef;
  opacity: 1;
}

.rbt-input-multi.is-invalid.focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.rbt-input-multi.is-valid.focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.rbt-input-multi input::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.rbt-input-multi input:-ms-input-placeholder {
  color: #6c757d;
}

.rbt-input-multi input::-webkit-input-placeholder {
  color: #6c757d;
}

.rbt-input-multi .rbt-input-wrapper {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -4px;
  margin-top: -1px;
  overflow: hidden;
}

.rbt-input-multi .rbt-input-main {
  margin: 1px 0 4px;
}

/**
   * Close Button
   */
.rbt-close {
  z-index: 1;
}

.rbt-close-lg {
  font-size: 24px;
}

/**
   * Token
   */
.rbt-token {
  background-color: #e7f4ff;
  border: 0;
  border-radius: 0.25rem;
  color: #007bff;
  display: inline-block;
  line-height: 1em;
  margin: 1px 3px 2px 0;
  padding: 4px 7px;
  position: relative;
}

.rbt-token-disabled {
  background-color: rgba(0, 0, 0, 0.1);
  color: #495057;
  pointer-events: none;
}

.rbt-token-removeable {
  cursor: pointer;
  padding-right: 21px;
}

.rbt-token-active {
  background-color: #007bff;
  color: #fff;
  outline: none;
  text-decoration: none;
}

.rbt-token .rbt-token-remove-button {
  bottom: 0;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  opacity: 1;
  outline: none;
  padding: 3px 7px;
  position: absolute;
  right: 0;
  text-shadow: none;
  top: -2px;
}

/**
   * Loader + CloseButton container
   */
.rbt-aux {
  align-items: center;
  display: flex;
  bottom: 0;
  justify-content: center;
  pointer-events: none;
  /* Don't block clicks on the input */
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
}

.rbt-aux-lg {
  width: 46px;
}

.rbt-aux .rbt-close {
  margin-top: -4px;
  pointer-events: auto;
  /* Override pointer-events: none; above */
}

.has-aux .rbt-input {
  padding-right: 34px;
}

.rbt-highlight-text {
  background-color: inherit;
  color: inherit;
  font-weight: bold;
  padding: 0;
}

/**
   * Input Groups
   */
.input-group > .rbt {
  flex: 1;
}

.input-group > .rbt .rbt-input-hint,
.input-group > .rbt .rbt-aux {
  z-index: 5;
}

.input-group > .rbt:not(:first-child) .form-control, .input-group > .rbt:not(:first-child) .share-story-modal form input[type=text], .share-story-modal form .input-group > .rbt:not(:first-child) input[type=text],
.input-group > .rbt:not(:first-child) .share-story-modal form input[type=email],
.share-story-modal form .input-group > .rbt:not(:first-child) input[type=email],
.input-group > .rbt:not(:first-child) .share-story-modal form input[type=tel],
.share-story-modal form .input-group > .rbt:not(:first-child) input[type=tel],
.input-group > .rbt:not(:first-child) .share-story-modal form select,
.share-story-modal form .input-group > .rbt:not(:first-child) select,
.input-group > .rbt:not(:first-child) html .microsite-theme .marketo-form .mktoForm textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > .rbt:not(:first-child) textarea,
.input-group > .rbt:not(:first-child) html .moments-theme .marketo-form .mktoForm textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .rbt:not(:first-child) textarea, .input-group > .rbt:not(:first-child) textarea, .input-group > .rbt:not(:first-child) .bmi-calculator input[type=text], .bmi-calculator .input-group > .rbt:not(:first-child) input[type=text],
.input-group > .rbt:not(:first-child) .bmi-calculator select,
.bmi-calculator .input-group > .rbt:not(:first-child) select, .input-group > .rbt:not(:first-child) .marketo-form .mktoForm .mktoEmailField, .marketo-form .mktoForm .input-group > .rbt:not(:first-child) .mktoEmailField,
.input-group > .rbt:not(:first-child) .marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .input-group > .rbt:not(:first-child) .mktoTelField,
.input-group > .rbt:not(:first-child) .marketo-form .mktoForm .mktoTextField,
.marketo-form .mktoForm .input-group > .rbt:not(:first-child) .mktoTextField, .input-group > .rbt:not(:first-child) .marketo-form .mktoForm select, .marketo-form .mktoForm .input-group > .rbt:not(:first-child) select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .rbt:not(:last-child) .form-control, .input-group > .rbt:not(:last-child) .share-story-modal form input[type=text], .share-story-modal form .input-group > .rbt:not(:last-child) input[type=text],
.input-group > .rbt:not(:last-child) .share-story-modal form input[type=email],
.share-story-modal form .input-group > .rbt:not(:last-child) input[type=email],
.input-group > .rbt:not(:last-child) .share-story-modal form input[type=tel],
.share-story-modal form .input-group > .rbt:not(:last-child) input[type=tel],
.input-group > .rbt:not(:last-child) .share-story-modal form select,
.share-story-modal form .input-group > .rbt:not(:last-child) select,
.input-group > .rbt:not(:last-child) html .microsite-theme .marketo-form .mktoForm textarea,
html .microsite-theme .marketo-form .mktoForm .input-group > .rbt:not(:last-child) textarea,
.input-group > .rbt:not(:last-child) html .moments-theme .marketo-form .mktoForm textarea,
html .moments-theme .marketo-form .mktoForm .input-group > .rbt:not(:last-child) textarea, .input-group > .rbt:not(:last-child) textarea, .input-group > .rbt:not(:last-child) .bmi-calculator input[type=text], .bmi-calculator .input-group > .rbt:not(:last-child) input[type=text],
.input-group > .rbt:not(:last-child) .bmi-calculator select,
.bmi-calculator .input-group > .rbt:not(:last-child) select, .input-group > .rbt:not(:last-child) .marketo-form .mktoForm .mktoEmailField, .marketo-form .mktoForm .input-group > .rbt:not(:last-child) .mktoEmailField,
.input-group > .rbt:not(:last-child) .marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .input-group > .rbt:not(:last-child) .mktoTelField,
.input-group > .rbt:not(:last-child) .marketo-form .mktoForm .mktoTextField,
.marketo-form .mktoForm .input-group > .rbt:not(:last-child) .mktoTextField, .input-group > .rbt:not(:last-child) .marketo-form .mktoForm select, .marketo-form .mktoForm .input-group > .rbt:not(:last-child) select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.read-more {
  margin-bottom: 3rem;
  position: relative;
}
.read-more:not(.in-container) {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .read-more:not(.in-container) {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .read-more:not(.in-container) {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .read-more:not(.in-container) {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .read-more:not(.in-container) {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.read-more.has-border {
  border: 0.75rem solid #a2c3d6;
  padding: 1.5rem;
}
.read-more.has-border .read-more-body {
  margin-bottom: 0;
}
.read-more.has-border .button-container {
  margin-top: 1.5rem;
}
.read-more.has-border .toggle-expand {
  bottom: -30px;
  /* this needs to change in accordance with button width */
  left: calc(50% - 69px);
  position: absolute;
}
.read-more.is-expanded > .read-more-body {
  max-height: none;
}
.read-more.is-expanded > .read-more-condensed {
  display: none;
}
.read-more.is-expanded > .read-more-expanded {
  display: block;
}
.read-more .read-more-body {
  margin-bottom: 2rem;
  max-height: 280px;
  overflow: hidden;
}
.read-more p {
  font-size: 1.25rem;
  line-height: 1.2;
}
.read-more .read-more-expanded {
  display: none;
}
.read-more .read-more-link {
  font-size: 1rem;
  line-height: 1.125;
  bottom: -2rem;
  display: inline-block;
  font-weight: 700;
  left: 0;
  position: absolute;
}
.read-more .button-container {
  display: flex;
  justify-content: center;
}
.read-more .btn.toggle-expand, .read-more .marketo-form .mktoForm .toggle-expand.mktoButton, .marketo-form .mktoForm .read-more .toggle-expand.mktoButton {
  background-color: #ffcd55;
}
.read-more .btn.toggle-expand:hover, .read-more .marketo-form .mktoForm .toggle-expand.mktoButton:hover, .marketo-form .mktoForm .read-more .toggle-expand.mktoButton:hover {
  background-color: #fff;
}
.read-more .btn.toggle-expand .icon, .read-more .marketo-form .mktoForm .toggle-expand.mktoButton .icon, .marketo-form .mktoForm .read-more .toggle-expand.mktoButton .icon {
  height: 14px;
  fill: #fff;
  width: 28px;
}
.read-more .read-more-link.is-expanded > .expandToggleLabel,
.read-more .button-container .btn.is-expanded > .expandToggleLabel,
.read-more .button-container .marketo-form .mktoForm .is-expanded.mktoButton > .expandToggleLabel,
.marketo-form .mktoForm .read-more .button-container .is-expanded.mktoButton > .expandToggleLabel {
  display: none;
}
.read-more .read-more-link.is-expanded > .collapseToggleLabel,
.read-more .button-container .btn.is-expanded > .collapseToggleLabel,
.read-more .button-container .marketo-form .mktoForm .is-expanded.mktoButton > .collapseToggleLabel,
.marketo-form .mktoForm .read-more .button-container .is-expanded.mktoButton > .collapseToggleLabel {
  display: inline;
}
.read-more .read-more-link.is-expanded > .icon,
.read-more .button-container .btn.is-expanded > .icon,
.read-more .button-container .marketo-form .mktoForm .is-expanded.mktoButton > .icon,
.marketo-form .mktoForm .read-more .button-container .is-expanded.mktoButton > .icon {
  transform: rotate(180deg);
}
.read-more .read-more-link > .collapseToggleLabel,
.read-more .button-container .btn > .collapseToggleLabel,
.read-more .button-container .marketo-form .mktoForm .mktoButton > .collapseToggleLabel,
.marketo-form .mktoForm .read-more .button-container .mktoButton > .collapseToggleLabel {
  display: none;
}
@media (min-width: 768px) {
  .read-more {
    margin-bottom: 4rem;
  }
}

.related-areas {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .related-areas {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .related-areas {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .related-areas {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .related-areas {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.related-areas .related-areas-inner {
  background-color: #2c373f;
  margin-bottom: 1.25rem;
  padding: 2.5rem 2rem;
  border-radius: 5px;
}
.related-areas .title {
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: 700;
  color: #fff;
  margin-bottom: 1rem;
}
.related-areas .btn, .related-areas .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .related-areas .mktoButton {
  margin-bottom: 1rem;
  padding: 0.75rem 2rem;
  width: 100%;
}
.related-areas .btn:hover, .related-areas .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .related-areas .mktoButton:hover, .related-areas .btn:focus, .related-areas .marketo-form .mktoForm .mktoButton:focus, .marketo-form .mktoForm .related-areas .mktoButton:focus {
  color: #ffcd55;
  background-color: #2c373f;
}
.related-areas .btn:last-child, .related-areas .marketo-form .mktoForm .mktoButton:last-child, .marketo-form .mktoForm .related-areas .mktoButton:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .related-areas .related-areas-inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;
  }
  .related-areas .title {
    margin: 0;
    padding-right: 2rem;
  }
  .related-areas .area-buttons {
    display: flex;
    justify-content: space-around;
  }
  .related-areas .btn, .related-areas .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .related-areas .mktoButton {
    align-items: center;
    display: flex;
    flex: 1 1 0;
    justify-content: center;
    margin-bottom: 0;
    margin-right: 1rem;
    width: auto;
  }
  .related-areas .btn:last-child, .related-areas .marketo-form .mktoForm .mktoButton:last-child, .marketo-form .mktoForm .related-areas .mktoButton:last-child {
    margin-right: 0;
  }
  .two-column .related-areas .related-areas-inner {
    display: block;
    padding: 2.5rem 2rem;
  }
  .two-column .related-areas .title {
    margin-bottom: 1rem;
    padding-right: 0;
  }
  .two-column .related-areas .area-buttons {
    display: block;
  }
  .two-column .related-areas .btn, .two-column .related-areas .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .two-column .related-areas .mktoButton {
    margin-bottom: 1rem;
    margin-right: 0;
    padding: 0.75rem;
    width: 100%;
  }
  .two-column .related-areas .btn:last-child, .two-column .related-areas .marketo-form .mktoForm .mktoButton:last-child, .marketo-form .mktoForm .two-column .related-areas .mktoButton:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .related-areas .area-buttons {
    flex-basis: 80%;
  }
  .related-areas .btn, .related-areas .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .related-areas .mktoButton {
    margin-right: 2rem;
  }
  .two-column .related-areas .related-areas-inner {
    display: flex;
    padding: 1rem 1.5rem;
  }
  .two-column .related-areas .title {
    font-size: 1.25rem;
    line-height: 1.2;
    margin-bottom: 0;
    padding-right: 1.5rem;
  }
  .two-column .related-areas .area-buttons {
    display: flex;
  }
  .two-column .related-areas .btn, .two-column .related-areas .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .two-column .related-areas .mktoButton {
    margin-bottom: 0;
    margin-right: 1rem;
    width: auto;
  }
  .two-column .related-areas .btn:last-child, .two-column .related-areas .marketo-form .mktoForm .mktoButton:last-child, .marketo-form .mktoForm .two-column .related-areas .mktoButton:last-child {
    margin-right: 0;
  }
}

.related-content {
  background-color: #f2f9ff;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  max-width: 524px;
}
.related-content .content {
  display: block;
  padding: 1.75rem 1.25rem 2rem;
  width: 100%;
}
.related-content .description,
.related-content .buttons {
  flex-basis: 100%;
  flex-flow: row;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.related-content .buttons a {
  display: block;
  margin-bottom: 0.25rem;
  width: 100%;
}
.related-content .buttons a:last-of-type {
  margin-bottom: 0;
}
.related-content .buttons .btn, .related-content .buttons .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .related-content .buttons .mktoButton {
  margin-bottom: 1rem;
}
.related-content .title {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #006D9D;
  font-weight: 700;
  margin-bottom: 1rem;
}
.related-content p {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
.related-content .image-area {
  position: relative;
}
.related-content .image {
  object-fit: cover;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%;";
  height: 100%;
  width: 100%;
}
@media (min-width: 576px) {
  .related-content .content {
    display: flex;
    padding: 1.75rem 1.25rem 2rem;
    width: 100%;
  }
  .related-content .description,
.related-content .buttons {
    flex-basis: 50%;
    max-width: 50%;
    flex-flow: column;
  }
}
@media (min-width: 768px) {
  .related-content.left {
    margin-left: 0;
  }
  .related-content.right {
    margin-right: 0;
  }
  .two-column .related-content.left {
    margin-left: auto;
  }
  .two-column .related-content.right {
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .two-column .related-content.left {
    margin-left: 0;
  }
  .two-column .related-content.right {
    margin-right: 0;
  }
}

.related-nurse-practitioners {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .related-nurse-practitioners {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .related-nurse-practitioners {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .related-nurse-practitioners {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .related-nurse-practitioners {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.related-nurse-practitioners .related-areas-inner {
  background-color: #2c373f;
  margin-bottom: 1.25rem;
  padding: 2.5rem 2.5rem 2.5rem 3.125rem;
}
.related-nurse-practitioners .title {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-weight: 700;
  color: #ffcd55;
  margin-bottom: 2rem;
}
.related-nurse-practitioners .description {
  color: #ffcd55;
  margin-top: 0.825rem;
  padding-top: 1.5rem;
  position: relative;
}
.related-nurse-practitioners .description:before {
  background-color: #ffcd55;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  height: 4px;
  width: 58px;
}
.related-nurse-practitioners .profile-image {
  height: 80px;
  min-width: 80px;
  width: 80px;
}
.related-nurse-practitioners .profile-details {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.related-nurse-practitioners .doctor-name {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #fff;
  font-weight: 700;
}
.related-nurse-practitioners .doctor-name:hover, .related-nurse-practitioners .doctor-name:focus {
  text-decoration: underline;
}
.related-nurse-practitioners .specialty {
  font-size: 1rem;
  line-height: 1.125;
  color: #ffcd55;
  font-weight: 700;
  margin-top: 0.25rem;
}
@media (min-width: 768px) {
  .related-nurse-practitioners .related-areas-inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .related-nurse-practitioners .title {
    margin: 0;
    padding-right: 2rem;
  }
  .related-nurse-practitioners .description {
    max-width: 256px;
  }
  .related-nurse-practitioners .area-profiles {
    display: flex;
    justify-content: space-around;
  }
  .related-nurse-practitioners .profile {
    align-items: center;
    display: flex;
    flex: 1 1 0;
    justify-content: center;
    margin-bottom: 0;
    margin-right: 1rem;
    width: auto;
  }
  .related-nurse-practitioners .profile:last-child {
    margin-right: 0;
  }
  .related-nurse-practitioners .profile-details {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1.5rem;
  }
}
@media (min-width: 992px) {
  .related-nurse-practitioners .area-profiles {
    flex-basis: 80%;
  }
  .related-nurse-practitioners .profile {
    margin-right: 2rem;
  }
}

.related-quick-links {
  float: none;
}
.related-quick-links .quick-links-inner {
  background-color: #f2f9ff;
  flex-basis: auto;
  margin-bottom: 2rem;
  max-width: 100%;
  padding: 1.25rem;
  border-radius: 5px;
}
.related-quick-links .quick-links-content {
  margin-bottom: 0;
  padding: 0;
}
.related-quick-links .section-title {
  margin-bottom: 1.25rem;
  padding-bottom: 0;
  text-align: center;
}
.related-quick-links .section-title::after {
  display: none;
}
.related-quick-links .offering-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.related-quick-links li {
  font-size: 1rem;
  line-height: 1.125;
  background-color: #2c373f;
  display: inline-block;
  font-weight: 400;
  margin-bottom: 1rem;
  padding: 0.575rem 0.25rem;
  text-align: center;
  width: 100%;
  border-radius: 5px;
}
.related-quick-links li a {
  color: #fff;
}
@media (min-width: 576px) {
  .related-quick-links {
    width: 50%;
    float: right;
  }
  .related-quick-links .quick-links-inner {
    margin-left: 2rem;
  }
}
@media (min-width: 768px) {
  .related-quick-links {
    width: 33.333333%;
    float: right;
  }
  .two-column .related-quick-links {
    width: 50%;
  }
  .related-quick-links .section-title {
    padding-bottom: 0;
  }
  .related-quick-links .section-title::after {
    display: none;
  }
}
@media (min-width: 992px) {
  .related-quick-links {
    width: 25%;
    float: right;
  }
  .two-column .related-quick-links {
    width: 33.333333%;
  }
}
@media (min-width: 1200px) {
  .related-quick-links {
    width: 20%;
    float: right;
  }
  .two-column .related-quick-links {
    width: 30%;
  }
}

.related-specialties {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .related-specialties {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .related-specialties {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .related-specialties {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .related-specialties {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.related-specialties .section-title:after {
  background-color: #ffcd55;
}
.related-specialties .related-inner {
  margin-bottom: 4rem;
  max-width: 100%;
  padding: 1.5rem;
}
.related-specialties .related-inner.is-expanded .toggle-expand .icon {
  transform: rotate(180deg);
}
.related-specialties .related-inner .toggle-expand {
  bottom: -30px;
  position: relative;
}
.related-specialties .related-inner .toggle-expand .icon {
  height: 14px;
  fill: #fff;
  width: 28px;
}
.related-specialties .related-inner a {
  text-decoration: none;
}
.related-specialties .related-specialties-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  overflow: hidden;
  padding: 0;
}
.related-specialties .related-specialties-content.read-more-body {
  max-height: 100%;
}
.related-specialties .offering-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.related-specialties .offering-list li {
  font-size: 1rem;
  line-height: 1.125;
  display: inline-block;
  margin: 0;
  padding-bottom: 0.375rem;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid #a2c3d6;
  margin-bottom: 1rem;
}
@media (min-width: 576px) {
  .related-specialties .offering-list {
    columns: 2;
    column-gap: 30px;
  }
  .related-specialties .offering-list li {
    font-size: 1rem;
    line-height: 1.125;
    padding-bottom: 1rem;
  }
  .related-specialties.is-two-col .offering-list {
    columns: 1;
  }
  .two-column .related-specialties .offering-list {
    columns: 1;
  }
}
@media (min-width: 768px) {
  .related-specialties .section-title {
    padding-bottom: 0;
    margin-bottom: 2rem;
  }
  .related-specialties .section-title::after {
    display: none;
  }
  .related-specialties .related-inner {
    border: none;
    padding: 1.75rem 0 1.5rem;
    margin-bottom: 1.5rem;
  }
  .related-specialties .related-specialties-content {
    max-height: none;
    overflow: auto;
    padding: 0;
  }
  .related-specialties .related-specialties-content button {
    display: none;
  }
  .related-specialties .toggle-expand {
    display: none;
  }
  .related-specialties .offering-list {
    columns: 3;
  }
  .related-specialties .offering-list li {
    font-size: 1rem;
    line-height: 1.125;
  }
  .related-specialties.is-two-col .offering-list {
    columns: 2;
  }
  .two-column .related-specialties .offering-list {
    columns: 2;
  }
  .two-column .related-specialties.is-two-col .offering-list {
    columns: 2;
  }
}
@media (min-width: 992px) {
  .related-specialties .offering-list {
    columns: 3;
  }
  .two-column .related-specialties .offering-list {
    columns: 3;
  }
}

.related-list-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .related-list-container {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .related-list-container {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .related-list-container {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .related-list-container {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.related-list-container .section-title {
  margin-bottom: 1.25rem;
  padding-top: 2rem;
}
.related-list-container .section-title:after {
  background-color: #ffcd55;
}
.related-list-container .description {
  font-size: 1.125rem;
  line-height: 1.1111111111;
}
.related-list-container .btn-container {
  padding-bottom: 2rem;
  text-align: center;
  width: 100%;
}
.related-list-container .view-all-bottom {
  display: inline-block;
}
.related-list-container .related-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  gap: 1rem;
}
.related-list-container .related-list.doctor .related-list-item .related-DoL {
  margin-top: 52px;
}
.related-list-container .related-list.doctor .related-list-item .related-DoL .profile-image {
  height: 128px;
  min-width: 128px;
  margin-top: calc((130px) / -2);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  width: 128px;
}
.related-list-container .related-list-item {
  display: flex;
  flex: 100%;
  max-width: 100%;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 0;
  margin-bottom: 1rem;
}
.related-list-container .related-DoL {
  border-radius: 5px;
  display: flex;
  margin-top: 73px;
  width: 100%;
  background-color: #f5f5f6;
}
.related-list-container .related-DoL .card-header {
  background-color: transparent;
  border-bottom: none;
  flex: 1;
  padding-bottom: 1.5rem;
  text-align: center;
}
.related-list-container .related-DoL .profile-image {
  display: block;
  height: 144px;
  min-width: 144px;
  margin-top: -85px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  width: 144px;
  border: none;
  box-shadow: none;
}
.related-list-container .related-DoL .profile-image[href]:hover, .related-list-container .related-DoL .profile-image[href]:focus {
  text-decoration: none;
}
.related-list-container .related-DoL .profile-image[href]:hover + .doctor-details .doctor-name, .related-list-container .related-DoL .profile-image[href]:focus + .doctor-details .doctor-name {
  color: #006D9D;
  text-decoration: underline;
}
.related-list-container .related-DoL .doctor-details, .related-list-container .related-DoL .location-details {
  display: flex;
  flex-direction: column;
}
.related-list-container .related-DoL .doctor-details .doctor-location, .related-list-container .related-DoL .location-details .doctor-location {
  margin-top: auto;
  display: flex;
  justify-content: space-evenly;
}
.related-list-container .related-DoL .doctor-details .location-address, .related-list-container .related-DoL .location-details .location-address {
  display: flex;
  flex-wrap: wrap;
}
.related-list-container .related-DoL .doctor-name,
.related-list-container .related-DoL .location-name {
  font-size: 1rem;
  line-height: 1.125;
  color: #2c373f;
  display: flex;
  font-weight: 700;
  margin-bottom: 0.5rem;
  justify-content: center;
}
.related-list-container .related-DoL .doctor-name:last-child,
.related-list-container .related-DoL .location-name:last-child {
  margin-bottom: 0;
}
.related-list-container .related-DoL .doctor-name[href]:hover, .related-list-container .related-DoL .doctor-name[href]:focus,
.related-list-container .related-DoL .location-name[href]:hover,
.related-list-container .related-DoL .location-name[href]:focus {
  color: #006D9D;
}
.related-list-container .related-DoL .ratings {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  min-height: 18px;
  justify-content: center;
  margin-bottom: 0.5rem;
}
.related-list-container .related-DoL .ratings .rating-stars {
  margin-right: 0.75rem;
}
.related-list-container .related-DoL .ratings .rating-count {
  font-size: 0.875rem;
  line-height: 1;
  color: #3d5d6b;
  margin: 3px 0 1px 2px;
}
.related-list-container .related-DoL .ratings-fill {
  background-color: #ffcd55;
  height: 100%;
  width: 100%;
}
.related-list-container .related-DoL .icon-view-tile {
  fill: #ffcd55;
}
.related-list-container .related-DoL .distance-and-location {
  margin-bottom: 0.5rem;
}
.related-list-container .related-DoL .distance-and-location .subsection-content {
  margin-top: 0.375rem;
  padding-right: 0.5rem;
  display: flex;
  flex-basis: 50%;
  align-items: center;
  flex-direction: row-reverse;
  height: 0.625rem;
}
.related-list-container .related-DoL .distance-and-location .subsection-content:after {
  background-color: #000;
  right: 0;
  content: "";
  display: block;
  height: 11px;
  left: calc(50% - 1px);
  position: absolute;
  width: 1px;
}
.related-list-container .related-DoL .distance-and-location .subsection-content:last-child {
  flex-basis: 100%;
  padding-right: 0;
  justify-content: space-evenly;
}
.related-list-container .related-DoL .distance-and-location .subsection-content:last-child:after {
  display: none;
}
.related-list-container .related-DoL .distance-and-location .distance {
  display: flex;
  font-weight: 700;
  margin-top: 0.375rem;
  flex-basis: 100%;
  flex-direction: row;
  height: 0.625rem;
  align-items: center;
  justify-content: space-evenly;
}
.related-list-container .related-DoL .distance-and-location .distance span {
  font-weight: 400;
}
.related-list-container .related-DoL .distance-and-location .location-address .subsection-content + .distance {
  display: flex;
  font-weight: 700;
  margin-top: 0.375rem;
  flex-basis: 50%;
  flex-direction: row;
  padding-left: 0.5rem;
  height: 0.625rem;
  align-items: center;
  justify-content: start;
}
.related-list-container .related-DoL .specialties {
  margin-bottom: 0;
}
.related-list-container .related-DoL .doctor-location,
.related-list-container .related-DoL .specialty,
.related-list-container .related-DoL .address {
  font-size: 1rem;
  line-height: 1.125;
  display: flex;
  font-weight: 400;
  justify-content: space-evenly;
  flex-basis: 100%;
  padding-bottom: 0.5rem;
}
.related-list-container .related-DoL .doctor-location:last-child,
.related-list-container .related-DoL .specialty:last-child,
.related-list-container .related-DoL .address:last-child {
  margin-bottom: 0;
}
.related-list-container .related-DoL .address {
  padding-bottom: 0.5rem;
}
.related-list-container .related-DoL .appointment-type-label {
  font-size: 1rem;
  line-height: 1.125;
  border-top: 1px solid #a2c3d6;
  font-weight: 600;
  padding: 0.75rem 0;
  margin-top: 1rem;
}
.related-list-container .related-DoL .appointment-type-label .appointment-type.patients-new:before {
  position: relative;
  top: 1px;
}
@media (min-width: 576px) {
  .related-list-container .related-list .related-list-item .related-DoL .card-header {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
  .related-list-container .related-list .related-list-item .related-DoL .profile-image {
    height: 144px;
    min-width: 144px;
    margin-top: -100px;
    width: 144px;
  }
  .related-list-container .related-list .related-list-item .related-DoL .doctor-details {
    width: 100%;
  }
  .related-list-container .related-list .related-list-item .related-DoL .appointment-type-label {
    font-size: 0.875rem;
    line-height: 1.1428571429;
    padding: 0.75rem 0 0;
  }
  .related-list-container .related-list.doctor {
    padding-right: 1.5rem;
  }
  .related-list-container .related-list.doctor .related-list-item .related-DoL {
    margin-left: 44px;
    margin-top: 0;
    width: calc(100% - 44px);
  }
  .related-list-container .related-list.doctor .related-list-item .related-DoL .card-header {
    display: flex;
    flex-direction: row;
    padding: 1rem 1rem 1rem 0.5rem;
  }
  .related-list-container .related-list.doctor .related-list-item .related-DoL .profile-image {
    margin: auto 1rem auto -64px;
  }
  .related-list-container .related-list.doctor .related-list-item .related-DoL .doctor-details {
    text-align: left;
    width: 100%;
    padding: 0.5rem 0 0 1rem;
  }
  .related-list-container .related-list.doctor .related-list-item .related-DoL .doctor-details .doctor-name, .related-list-container .related-list.doctor .related-list-item .related-DoL .doctor-details .specialty, .related-list-container .related-list.doctor .related-list-item .related-DoL .doctor-details .doctor-location {
    justify-content: start;
  }
  .related-list-container .related-list.doctor .related-list-item .related-DoL .ratings {
    justify-content: flex-start;
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  .related-list-container {
    justify-content: flex-start;
  }
  .related-list-container .section-title {
    text-align: center;
  }
  .related-list-container .section-title::after {
    left: calc(50% - 29px);
  }
  .related-list-container .related-list-item {
    flex: calc(50% - 15px);
    max-width: calc(50% - 15px);
    padding-top: 0.5rem;
    padding-right: 1rem;
  }
  .related-list-container .related-list {
    justify-content: flex-start;
  }
  .related-list-container .related-list.doctor {
    padding-right: 0;
  }
  .related-list-container .related-list.doctor .related-list-item {
    flex: calc(50% - 8px);
    max-width: calc(50% - 8px);
  }
  .related-list-container .related-list.doctor .related-list-item .related-DoL {
    height: 100%;
  }
  .related-list-container .related-list .related-DoL {
    height: calc(100% - 73px);
  }
  .related-list-container .related-list.two-col-layout .related-list-item, .two-column .related-list-container .related-list .related-list-item {
    flex: 100%;
    max-width: 100%;
  }
  .related-list-container .related-list.two-col-layout .related-list-item .related-DoL .card-header, .two-column .related-list-container .related-list .related-list-item .related-DoL .card-header {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
  .related-list-container .related-list.two-col-layout .related-list-item .related-DoL .doctor-details, .two-column .related-list-container .related-list .related-list-item .related-DoL .doctor-details {
    width: 100%;
  }
  .related-list-container .related-list.two-col-layout.doctor .related-list-item, .two-column .related-list-container .related-list.doctor .related-list-item {
    flex: 100%;
    max-width: 100%;
  }
  .related-list-container .related-list.two-col-layout.doctor .related-list-item .related-DoL, .two-column .related-list-container .related-list.doctor .related-list-item .related-DoL {
    margin-left: 44px;
    margin-top: 0;
  }
  .related-list-container .related-list.two-col-layout.doctor .related-list-item .related-DoL .card-header, .two-column .related-list-container .related-list.doctor .related-list-item .related-DoL .card-header {
    display: flex;
    flex-direction: row;
    padding: 1.5rem 4rem 1.5rem 0.5rem;
  }
  .related-list-container .related-list.two-col-layout.doctor .related-list-item .related-DoL .doctor-details, .two-column .related-list-container .related-list.doctor .related-list-item .related-DoL .doctor-details {
    text-align: left;
    width: 100%;
  }
  .related-list-container .related-list.two-col-layout.doctor .related-list-item .related-DoL .appointment-type-label, .two-column .related-list-container .related-list.doctor .related-list-item .related-DoL .appointment-type-label {
    font-size: 0.875rem;
    line-height: 1.1428571429;
  }
  .related-list-container .related-list.two-col-layout.doctor .related-list-item .related-DoL .doctor-location, .two-column .related-list-container .related-list.doctor .related-list-item .related-DoL .doctor-location {
    text-align: left;
  }
}
@media (min-width: 992px) {
  .related-list-container .section-title {
    display: inline-block;
    margin-bottom: 1rem;
    text-align: left;
  }
  .related-list-container .section-title::after {
    left: 0;
  }
  .related-list-container .related-DoL .card-header {
    display: flex;
    flex-direction: column;
  }
  .related-list-container .related-DoL .doctor-name,
.related-list-container .related-DoL .location-name {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
  .related-list-container .related-list {
    gap: 1rem;
  }
  .related-list-container .related-list.doctor .related-list-item {
    flex: calc(50% - 12px);
    max-width: calc(50% - 12px);
  }
  .related-list-container .related-list.doctor:after {
    content: " ";
    flex: calc(50% - 12px);
    max-width: calc(50% - 12px);
  }
  .related-list-container .related-list:after {
    content: " ";
    flex: calc(33.3333333333% - 20px);
    max-width: calc(33.3333333333% - 20px);
    width: 100%;
  }
  .related-list-container .related-list.two-col-layout .related-list-item, .two-column .related-list-container .related-list .related-list-item {
    flex: calc(50% - 15px);
    max-width: calc(50% - 15px);
  }
  .related-list-container .related-list.two-col-layout .related-list-item .related-DoL .card-header, .two-column .related-list-container .related-list .related-list-item .related-DoL .card-header {
    display: flex;
    flex-direction: column;
    padding: 1rem 0.3rem 0.5rem;
  }
  .related-list-container .related-list.two-col-layout .related-list-item .related-DoL .doctor-details, .two-column .related-list-container .related-list .related-list-item .related-DoL .doctor-details {
    width: 100%;
  }
  .related-list-container .related-list.two-col-layout .related-list-item .related-DoL .doctor-location, .two-column .related-list-container .related-list .related-list-item .related-DoL .doctor-location {
    text-align: center;
  }
  .related-list-container .related-list.two-col-layout.doctor .related-list-item, .two-column .related-list-container .related-list.doctor .related-list-item {
    flex: calc(50% - 8px);
    max-width: calc(50% - 8px);
  }
  .related-list-container .related-list.two-col-layout.doctor .related-list-item .related-DoL, .two-column .related-list-container .related-list.doctor .related-list-item .related-DoL {
    display: block;
    margin-top: 0;
  }
  .related-list-container .related-list.two-col-layout.doctor .related-list-item .related-DoL .card-header, .two-column .related-list-container .related-list.doctor .related-list-item .related-DoL .card-header {
    display: flex;
    flex-direction: row;
    padding: 1.5rem 4rem 1.5rem 0.5rem;
  }
  .related-list-container .related-list.two-col-layout.doctor .related-list-item .related-DoL .doctor-details, .two-column .related-list-container .related-list.doctor .related-list-item .related-DoL .doctor-details {
    text-align: left;
    width: 100%;
  }
  .related-list-container .related-list.two-col-layout.doctor .related-list-item .related-DoL .doctor-location, .two-column .related-list-container .related-list.doctor .related-list-item .related-DoL .doctor-location {
    text-align: left;
  }
  .related-list-container .related-list.two-col-layout:after, .two-column .related-list-container .related-list:after {
    display: none;
  }
}
@media (min-width: 1200px) {
  .related-list-container .related-list.two-col-layout .related-list-item, .two-column .related-list-container .related-list .related-list-item {
    flex: calc(33.3333333333% - 20px);
    max-width: calc(33.3333333333% - 20px);
    flex-direction: row;
  }
  .related-list-container .related-list.two-col-layout .doctor-location, .two-column .related-list-container .related-list .doctor-location {
    text-align: left;
  }
  .related-list-container .related-list.two-col-layout:after, .two-column .related-list-container .related-list:after {
    content: " ";
    display: block;
    flex: calc(33.3333333333% - 20px);
    max-width: calc(33.3333333333% - 20px);
  }
  .related-list-container .related-list.doctor .related-list-item {
    flex: calc(33.3333333333% - 20px);
    max-width: calc(33.3333333333% - 20px);
  }
  .related-list-container .related-list.doctor .related-list-item .related-DoL .card-header {
    padding-right: 2rem;
  }
}

.filter-list {
  background-color: #fff;
  pointer-events: none;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  top: 20vh;
  padding-bottom: 80px;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  transition: visibility 0s ease-in-out 400ms, opacity 0.2s ease-in-out, top 0.2s ease-in-out;
  z-index: 9;
}
.filter-list.filters-open {
  display: block;
  padding: 1rem;
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
  top: 0;
  transition-delay: 0s;
}
.filter-list.view-all-open #view-all-root {
  display: block;
}
.filter-list.view-all-open > .accordion-container {
  display: none;
}
.filter-list .accordion-container {
  padding: 0;
}
.filter-list .mobile-filter-header {
  align-items: center;
  background-color: #fff;
  color: #2c373f;
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding: 0 1rem;
  position: relative;
}
.filter-list .mobile-filter-header .mobile-close-btn {
  font-size: 1.25rem;
  line-height: 1.2;
  background: none;
  border: none;
  color: #006D9D;
  font-weight: 800;
  margin-left: -1rem;
  padding: 1rem;
}
.filter-list .mobile-filter-header .mobile-close-btn .icon {
  fill: #006D9D;
  height: 1rem;
}
.filter-list .mobile-filter-header .mobile-close-btn:hover, .filter-list .mobile-filter-header .mobile-close-btn:focus {
  outline: 0;
}
.filter-list .mobile-filter-header .mobile-close-btn:hover .icon, .filter-list .mobile-filter-header .mobile-close-btn:focus .icon {
  fill: #006D9D;
}
.filter-list .mobile-filter-header .icon-arrow-right {
  transform: rotate(180deg);
}
.filter-list .mobile-filter-header .filter-heading {
  font-size: 1.75rem;
  line-height: 1.2142857143;
  font-weight: 800;
  left: 50%;
  margin: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 100px);
  pointer-events: none;
}
.filter-list .mobile-filter-header .filter-link {
  color: #006D9D;
}
.filter-list .nav-accordion {
  background-color: #f2f9ff;
}
.filter-list .nav-accordion .accordion-item {
  border-color: #cbced1;
}
.filter-list .nav-accordion .accordion-item[aria-expanded=true] .accordion-item-title .accordion-item-toggle {
  color: #006D9D;
  font-weight: 600;
}
.filter-list .nav-accordion .accordion-item[aria-expanded=true] .accordion-item-title .accordion-item-toggle .icon {
  fill: #006D9D;
}
.filter-list .nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle {
  color: #2c373f;
}
.filter-list .nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle:hover, .filter-list .nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle:focus {
  color: #a2c3d6;
}
.filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle {
  color: #2c373f;
  font-weight: 400;
}
.filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:hover, .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:focus {
  color: #005557;
}
.filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:hover .icon, .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:focus .icon {
  fill: #005557;
}
.filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .label-secondary {
  font-weight: 400;
}
.filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .icon {
  fill: #2c373f;
}
.filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-tags {
  background-color: #f2f9ff;
}
.filter-list .nav-accordion .accordion-item .accordion-item-body {
  color: #2c373f;
  font-weight: 600;
}
.filter-list .nav-accordion .accordion-item .accordion-item-body input[type=text], .filter-list .nav-accordion .accordion-item .accordion-item-body input[type=email], .filter-list .nav-accordion .accordion-item .accordion-item-body input[type=search] {
  background-color: #fff;
  color: #cbced1;
}
.filter-list .nav-accordion .accordion-item .accordion-item-body input[type=text][placeholder], .filter-list .nav-accordion .accordion-item .accordion-item-body input[type=email][placeholder], .filter-list .nav-accordion .accordion-item .accordion-item-body input[type=search][placeholder] {
  color: #333f48;
}
.filter-list .nav-accordion .accordion-item .accordion-item-body .range-container .range-label-list li.active span {
  color: #2c373f;
}
.filter-list .checkbox-filter {
  align-items: center;
  display: flex;
  padding: 1rem;
}
.filter-list .checkbox-filter .checkbox-wrapper, .filter-list .checkbox-filter .marketo-form .mktoForm .mktoCheckboxList, .marketo-form .mktoForm .filter-list .checkbox-filter .mktoCheckboxList {
  margin: 0 1rem 0 0;
}
.filter-list .checkbox-filter .checkbox-wrapper input[type=checkbox] + label, .filter-list .checkbox-filter .marketo-form .mktoForm .mktoCheckboxList input[type=checkbox] + label, .marketo-form .mktoForm .filter-list .checkbox-filter .mktoCheckboxList input[type=checkbox] + label {
  color: #2c373f;
  font-weight: 600;
}
.filter-list .checkbox-filter img {
  display: none;
}
.filter-list .checkbox-filter.isMHMG-container {
  display: none;
}
.filter-list .mobile-filter-show-btn {
  align-items: center;
  bottom: 0;
  display: flex;
  height: 80px;
  padding: 0 15px 1rem;
  position: fixed;
  width: calc(100% - 2rem);
}
.filter-list .mobile-filter-show-btn .filter-show-btn {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  width: 100%;
}
.filter-list .mobile-filter-show-btn.loading .filter-show-btn {
  color: transparent;
  position: relative;
}
.filter-list .mobile-filter-show-btn.loading .filter-show-btn:after {
  border: 2px solid #fff;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  content: " ";
  display: block;
  pointer-events: none;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  animation: spin 1.4s infinite linear;
  position: relative;
  left: 10px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.filter-list .mobile-filter-show-btn.loading .filter-show-btn:after {
  left: 50%;
  margin-left: -13px;
  margin-top: -13px;
  position: absolute;
  top: 50%;
}
.filter-list .tag-list .tag {
  color: #005557;
}
.filter-list .filter-header {
  display: none;
}
.filter-list .filter-link,
.filter-list .show-more-trigger {
  font-size: 1rem;
  line-height: 1.125;
  background: none;
  border: 0;
  color: #006D9D;
  font-weight: 600;
  margin: 0;
  outline: 0;
  padding: 0;
}
.filter-list .filter-link:hover, .filter-list .filter-link:focus,
.filter-list .show-more-trigger:hover,
.filter-list .show-more-trigger:focus {
  text-decoration: underline;
}
.filter-list .filter-link:disabled,
.filter-list .show-more-trigger:disabled {
  color: #a2c3d6;
  pointer-events: none;
}
.filter-list .range-container {
  padding-top: 1.5rem;
  margin: 0 1rem;
}
.filter-list #view-all-root {
  display: none;
}
.filter-list .view-all-trigger {
  padding-top: 1rem;
}
.filter-list .view-all-panel {
  background-color: #f2f9ff;
  color: #fff;
  display: block;
}
.filter-list .view-all-panel .view-all-header {
  display: none;
}
.filter-list .view-all-panel .view-all-dismiss-container .dismiss-btn {
  font-size: 1.25rem;
  line-height: 1.2;
  background: none;
  border: none;
  color: #006D9D;
  font-weight: 800;
  margin-left: -1rem;
  padding: 1rem;
}
.filter-list .view-all-panel .view-all-dismiss-container .dismiss-btn .icon {
  fill: #006D9D;
}
.filter-list .view-all-panel .view-all-dismiss-container .dismiss-btn:hover, .filter-list .view-all-panel .view-all-dismiss-container .dismiss-btn:focus {
  outline: 0;
}
.filter-list .view-all-panel .view-all-dismiss-container .dismiss-btn:hover .icon, .filter-list .view-all-panel .view-all-dismiss-container .dismiss-btn:focus .icon {
  fill: #006D9D;
}
.filter-list .view-all-panel input[type=text], .filter-list .view-all-panel input[type=email], .filter-list .view-all-panel input[type=search] {
  background-color: #fff;
  color: #2c373f;
}
.filter-list .view-all-panel input[type=text][placeholder], .filter-list .view-all-panel input[type=email][placeholder], .filter-list .view-all-panel input[type=search][placeholder] {
  color: #3d5d6b;
}
.filter-list .view-all-panel .rbt {
  margin: 1rem 1rem 0;
}
.filter-list .view-all-panel .tag-list {
  padding: 0 1rem;
}
.filter-list .view-all-panel .range-container {
  padding: 1.5rem 0 0;
  margin: 0 2rem;
}
.filter-list .view-all-panel .accordion-label {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #006D9D;
  margin: 2rem 1rem 0;
}
.filter-list .view-all-panel .nav-accordion {
  border-top: 1px solid #006D9D;
  margin-top: 1rem;
}
.filter-list .view-all-panel .nav-accordion .object-section {
  display: flex;
  padding: 0.5rem 1rem 0.5rem 0;
}
.filter-list .view-all-panel .nav-accordion .object-section .object-section-label {
  font-size: 1rem;
  line-height: 1.125;
  margin-right: 2rem;
  min-width: 16px;
}
.filter-list .view-all-panel .nav-accordion .object-section .object-section-content {
  border-bottom: 1px solid #a2c3d6;
  padding-bottom: 0.5rem;
  width: 100%;
}
.filter-list .view-all-panel .nav-accordion .object-section:last-of-type .object-section-content {
  border-bottom: 0;
}
.filter-list .view-all-panel .view-all-no-accordion {
  border-top: 1px solid #006D9D;
  margin-top: 1rem;
  padding: 1.625rem 1.5rem 0.25rem;
}
.filter-list .filter-availability {
  padding: 0.25rem 0;
}
.filter-list .filter-availability hr {
  border-color: #006D9D;
  margin: 1.5rem 0;
}
.filter-list .filter-availability .secondary-filter-title {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #fff;
  font-weight: 600;
}
.filter-list .filter-availability p {
  font-size: 1rem;
  line-height: 1.125;
}
.filter-list .filter-availability .radio-btns {
  padding-top: 0.25rem;
}
.filter-list .filter-availability .radio-btns input[type=radio]:checked + label {
  background-color: #005557;
  border-color: #005557;
}
.filter-list .filter-availability .radio-btns input[type=radio]:focus + label {
  background-color: #005557;
  border-color: #005557;
  box-shadow: 0 0 0 0.2rem rgba(0, 85, 87, 0.5);
}
.filter-list .filter-availability .radio-btns input[type=radio] + label {
  background-color: #a2c3d6;
  border-color: #a2c3d6;
}
.filter-list .filter-availability .radio-btns input[type=radio]:disabled + label {
  background-color: #cbced1;
  border-color: #cbced1;
  pointer-events: none;
}
.filter-list .filter-availability .radio-btns input[type=radio]:disabled:checked + label {
  background-color: #cbced1;
  border-color: #cbced1;
}
.filter-list .filter-gender {
  padding: 0.25rem 0;
}
.filter-list .filter-gender .radio-wrapper:last-of-type, .filter-list .filter-gender .marketo-form .mktoForm .mktoRadioList:last-of-type, .marketo-form .mktoForm .filter-list .filter-gender .mktoRadioList:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .filter-list {
    background-color: transparent;
    display: block;
    height: auto;
    opacity: 1;
    overflow: visible;
    pointer-events: auto;
    visibility: visible;
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    z-index: 7;
  }
  .filter-list.view-all-open {
    overflow: visible;
  }
  .filter-list .accordion-container {
    margin-bottom: 0;
  }
  .filter-list .nav-accordion {
    background-color: #fff;
  }
  .filter-list .nav-accordion .accordion-item {
    border-color: #cbced1;
  }
  .filter-list .nav-accordion .accordion-item[aria-expanded=true] .accordion-item-title .accordion-item-toggle {
    color: #2c373f;
  }
  .filter-list .nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle {
    color: #3d5d6b;
  }
  .filter-list .nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle:hover, .filter-list .nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle:focus {
    color: #006D9D;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle {
    color: #3d5d6b;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:hover, .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:focus {
    color: #006D9D;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .label-secondary {
    color: #006D9D;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-tags {
    background-color: #fff;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-body {
    color: #3d5d6b;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-body input[type=text], .filter-list .nav-accordion .accordion-item .accordion-item-body input[type=email], .filter-list .nav-accordion .accordion-item .accordion-item-body input[type=search] {
    background-color: #f2f9ff;
    color: #2c373f;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-body input[type=text][placeholder], .filter-list .nav-accordion .accordion-item .accordion-item-body input[type=email][placeholder], .filter-list .nav-accordion .accordion-item .accordion-item-body input[type=search][placeholder] {
    color: #3d5d6b;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-body .rbt .icon {
    stroke: #2c373f;
  }
  .filter-list .nav-accordion .accordion-item .accordion-item-body .rbt .icon.disabled {
    stroke: #cbced1;
  }
  .filter-list .checkbox-filter .checkbox-wrapper input[type=checkbox] + label, .filter-list .checkbox-filter .marketo-form .mktoForm .mktoCheckboxList input[type=checkbox] + label, .marketo-form .mktoForm .filter-list .checkbox-filter .mktoCheckboxList input[type=checkbox] + label {
    color: #3d5d6b;
  }
  .filter-list .checkbox-filter img {
    display: block;
    height: auto;
    width: 80px;
  }
  .filter-list .mobile-filter-show-btn {
    display: none;
  }
  .filter-list .tag-list .tag {
    color: #006D9D;
  }
  .filter-list .mobile-filter-header {
    display: none;
  }
  .filter-list .filter-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .filter-list .filter-header .filter-heading {
    font-size: 1rem;
    line-height: 1.125;
    font-weight: 400;
    color: #a2c3d6;
    margin: 0;
    padding-left: 1rem;
  }
  .filter-list .filter-header .clear-btn-container {
    margin-right: 2px;
  }
  .filter-list .filter-link,
.filter-list .show-more-trigger {
    color: #006D9D;
  }
  .filter-list .range-container {
    margin: 0;
  }
  .filter-list .view-all-panel {
    background-color: #fff;
    border: 1px solid #a2c3d6;
    box-shadow: 0 10px 18px rgba(0, 0, 0, 0.12);
    color: #3d5d6b;
    display: block;
    margin-bottom: 2rem;
    margin-top: 1rem;
    padding: 1rem;
  }
  .filter-list .view-all-panel .view-all-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.25rem;
  }
  .filter-list .view-all-panel .view-all-header .search-title {
    margin: 0;
  }
  .filter-list .view-all-panel .view-all-dismiss-container {
    margin-left: 1rem;
  }
  .filter-list .view-all-panel .view-all-dismiss-container .dismiss-btn {
    margin-left: 0;
    padding: 0;
  }
  .filter-list .view-all-panel .rbt {
    margin: 0;
  }
  .filter-list .view-all-panel .range-container {
    padding: 1.5rem 0 0;
    margin: 0;
  }
  .filter-list .view-all-panel .tag-list {
    padding: 0 0.1rem;
  }
  .filter-list .view-all-panel .accordion-label {
    font-size: 1.125rem;
    line-height: 1.1111111111;
    color: #006D9D;
    margin: 2rem 0 0;
  }
  .filter-list .view-all-panel .nav-accordion {
    border-top: 1px solid #a2c3d6;
  }
  .filter-list .view-all-panel .nav-accordion .object-section {
    padding-right: 1.5rem;
  }
  .filter-list .view-all-panel .view-all-no-accordion {
    border-color: #a2c3d6;
  }
  .filter-list .filter-availability hr {
    border-color: rgba(0, 0, 0, 0.1);
  }
  .filter-list .filter-availability .secondary-filter-title {
    color: #2c373f;
  }
  .filter-list .filter-availability .radio-btns input[type=radio]:checked + label {
    background-color: #2c373f;
    border-color: #2c373f;
  }
  .filter-list .filter-availability .radio-btns input[type=radio]:focus + label {
    background-color: #2c373f;
    border-color: #2c373f;
    box-shadow: 0 0 0 0.2rem rgba(44, 55, 63, 0.35);
  }
  .filter-list .filter-availability .radio-btns input[type=radio] + label {
    background-color: #a2c3d6;
    border-color: #a2c3d6;
  }
  .filter-list .filter-gender {
    padding: 0.25rem 0;
  }
  .filter-list .filter-gender .radio-wrapper:last-of-type, .filter-list .filter-gender .marketo-form .mktoForm .mktoRadioList:last-of-type, .marketo-form .mktoForm .filter-list .filter-gender .mktoRadioList:last-of-type {
    margin-bottom: 0;
  }
}

.search-page-results .results-controls {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 1rem 0;
  width: 100%;
}
.search-page-results .results-controls .legend,
.search-page-results .results-controls .results-view-mode {
  display: none;
}
.search-page-results .results-controls > * {
  margin-top: 1rem;
  width: 40%;
}
.search-page-results .results-controls .results-control.results-control-dropdown select {
  width: 100%;
}
.search-page-results .results-controls .results-control.results-view-mode button {
  background: none;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
}
.search-page-results .results-controls .results-control.results-view-mode button:first-child {
  padding-right: 1.25rem;
}
.search-page-results .results-controls .results-control.results-view-mode button[disabled] .icon {
  fill: #006D9D;
}
.search-page-results .results-controls .results-control.results-view-mode button .icon {
  fill: #a2c3d6;
}
.search-page-results .results-controls .results-view-mode,
.search-page-results .results-controls .results-control-dropdown {
  text-align: right;
}
.search-page-results .results-controls .results-control-dropdown select {
  text-align-last: right;
}
.search-page-results .results-controls .mobile-filters-toggle {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: 1rem auto;
}
.search-page-results .results-count,
.search-page-results .results-view-mode {
  font-size: 1rem;
  line-height: 1.125;
}
.search-page-results .results-count {
  color: #2c373f;
  font-weight: 600;
}
.search-page-results .results-list-container {
  position: relative;
  min-height: 320px;
}
.search-page-results .results-list-container.loading .results-pagination-info {
  opacity: 0;
}
.search-page-results .results-list-container.loading .loading-overlay {
  opacity: 1;
}
.search-page-results .no-results-message {
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f5f5f6;
}
.search-page-results .no-results-message .no-results-image {
  margin-left: auto;
  margin-right: auto;
  padding: 2.5rem;
}
.search-page-results .no-results-message h2 {
  font-size: 1.75rem;
  line-height: 1.2142857143;
  margin-bottom: 1.25rem;
}
.search-page-results .no-results-message p {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  margin-bottom: 0.5rem;
}
.search-page-results .no-results-message ul {
  margin-bottom: 0;
}
.search-page-results .no-results-message ul li {
  font-size: 1.125rem;
  line-height: 1.1111111111;
}
.search-page-results .results-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  padding-top: 0.75rem;
}
.search-page-results .results-list-item {
  padding-bottom: 1rem;
}
@media (min-width: 768px) {
  .search-page-results .results-controls {
    gap: 1rem;
    justify-content: space-evenly;
  }
  .search-page-results .results-controls > * {
    margin-top: 0;
    width: auto;
  }
  .search-page-results .results-controls .results-control {
    text-align: center;
  }
  .search-page-results .results-controls .results-control.results-count {
    flex-grow: 1;
    text-align: left;
  }
  .search-page-results .results-controls .results-control.results-view-mode {
    text-align: right;
  }
  .search-page-results .results-controls .results-control + .results-control {
    flex: 0 1 auto;
    padding-left: 1rem;
    border-left: 1px solid #cbced1;
  }
  .search-page-results .results-controls .legend,
.search-page-results .results-controls .results-view-mode {
    display: block;
  }
  .search-page-results .results-controls .mobile-filters-toggle {
    margin-left: 1.75rem;
    padding-left: 3.375rem;
    padding-right: 3.375rem;
  }
  .search-page-results .no-results-message {
    display: flex;
    flex-direction: row;
  }
  .search-page-results .no-results-message .no-results-image {
    padding: 2.5rem 0.5rem 2.5rem 3rem;
  }
  .search-page-results .no-results-message .card-body {
    padding: 2.5rem 2rem;
  }
  .search-page-results .no-results-message h2 {
    font-size: 2rem;
    line-height: 1.1875;
  }
  .search-page-results .no-results-message p {
    font-size: 1.25rem;
    line-height: 1.2;
  }
  .search-page-results .no-results-message ul li {
    font-size: 1.25rem;
    line-height: 1.2;
  }
  .search-page-results .results-list-container {
    min-height: 500px;
  }
  .search-page-results .results-list {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    padding-top: 1rem;
  }
  .search-page-results .results-list.view-mode-list {
    flex-direction: column;
  }
  .search-page-results .results-list.view-mode-list .results-list-item {
    flex: auto;
    max-width: none;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  .search-page-results .results-list.view-mode-list .results-list-item:last-child {
    padding-bottom: 0;
  }
  .search-page-results .results-list-item {
    flex: 50%;
    max-width: 50%;
  }
  .search-page-results .results-list-item:nth-of-type(odd) {
    padding-right: 0.75rem;
  }
  .search-page-results .results-list-item:nth-of-type(even) {
    padding-left: 0.75rem;
  }
}
@media (min-width: 992px) {
  .search-page-results .results-controls {
    padding: 0.625rem 0 1rem;
  }
  .search-page-results .mobile-filters-toggle {
    display: none;
  }
}

.doctor-result {
  border: solid 0.25rem #e4e5e6;
  border-radius: 5px;
  background-color: #e4e5e6;
  display: flex;
  flex-direction: column;
  margin-top: calc(
	(130px / 2) - 0.25rem
);
}
.doctor-result .card-header {
  background-color: #fff;
  border-bottom: none;
  border-radius: 5px;
  padding-top: 0;
  padding-bottom: 0.5rem;
  text-align: center;
}
.doctor-result .card-header .location-address {
  padding-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.doctor-result .card-header .open-status {
  float: right;
  margin-top: 1rem;
  margin-right: -1.25rem;
  margin-left: 0.5rem;
  position: relative;
  padding: 0.5rem 1rem 0.5rem 1.25rem;
  background-color: #e4e5e6;
  border-radius: 6rem 0 0 6rem;
  font-weight: 600;
}
.doctor-result .profile-image {
  display: block;
  margin-top: calc((130px) / -2);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}
.doctor-result .profile-image[href]:hover, .doctor-result .profile-image[href]:focus {
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.24);
  text-decoration: none;
}
.doctor-result .profile-image[href]:hover + .doctor-details .doctor-name, .doctor-result .profile-image[href]:focus + .doctor-details .doctor-name {
  color: #006D9D;
  text-decoration: underline;
}
.doctor-result .profile-image.has-hours {
  display: flex;
  flex-direction: column;
}
.doctor-result .profile-image.has-hours .location-image {
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.doctor-result .profile-image.has-hours .location-image img {
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%;";
  width: 100%;
}
.doctor-result .profile-image.has-hours .location-image svg {
  height: 100%;
  width: 100%;
}
.doctor-result .profile-image.has-hours .open-status {
  font-size: 1rem;
  line-height: 1.125;
  background-color: #fff;
  bottom: 0;
  color: #3d5d6b;
  font-weight: 700;
  flex: 0 0 34px;
  padding: 3px 1.125rem 0;
  text-align: center;
  width: 100%;
}
.doctor-result .profile-image.has-hours .open-status.open {
  color: #128b40;
}
.doctor-result .doctor-details {
  width: 100%;
}
.doctor-result .doctor-name {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #006D9D;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.doctor-result .doctor-name[href]:hover, .doctor-result .doctor-name[href]:focus {
  color: #006D9D;
}
.doctor-result .doctor-ratings {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
}
.doctor-result .doctor-ratings .rating-stars {
  margin-right: 0.75rem;
}
.doctor-result .doctor-ratings .rating-count {
  font-size: 1rem;
  line-height: 1.125;
  color: #3d5d6b;
}
.doctor-result .specialty {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  display: block;
  margin-bottom: 0.25rem;
}
.doctor-result .specialty:last-child {
  margin-bottom: 0;
}
.doctor-result .doctor-location {
  text-align: left;
  padding: 2rem 0 1rem;
}
.doctor-result .doctor-location .appointment-type-label {
  padding: 0.125rem 0 0.25rem;
}
.doctor-result .doctor-location .appointment-type-label .appointment-type {
  padding-left: 0.125rem;
}
.doctor-result .affiliation-logo {
  float: right;
  max-width: 95px;
}
.doctor-result .distance-and-location,
.doctor-result .phone-number,
.doctor-result .appointment-type-label,
.doctor-result .location-name,
.doctor-result .address,
.doctor-result .appointment-time,
.doctor-result .modal-open-btn {
  font-size: 0.875rem;
  line-height: 1.1428571429;
}
.doctor-result .appointment-heading,
.doctor-result .appointment-date {
  font-size: 0.75rem;
  line-height: 1.1666666667;
}
.doctor-result .location-name {
  color: #006D9D;
  display: block;
  margin-bottom: 0.5rem;
}
.doctor-result .location-name:only-child {
  margin-bottom: 0;
}
.doctor-result .distance,
.doctor-result .phone-number {
  color: #006D9D;
  font-weight: 600;
  padding-right: 0.5rem;
}
.doctor-result .distance-and-location a {
  font-weight: 600;
}
.doctor-result .distance-and-location .get-directions::before {
  content: "|";
  padding-right: 0.75rem;
}
.doctor-result .appointment-type-label {
  color: #2c373f;
}
.doctor-result .card-footer {
  background-color: #e4e5e6;
  border-top: none;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.doctor-result .card-footer .onlineSchDown {
  margin-bottom: 1rem;
  text-align: center;
}
.doctor-result .card-footer .location-address {
  border-bottom: 1px solid #cbced1;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.doctor-result .card-footer .location-address:empty {
  display: none;
}
.doctor-result .card-footer .address {
  color: #2c373f;
  font-weight: 400;
}
.doctor-result .card-footer .appointment-type-label {
  display: none;
}
.doctor-result .card-footer .appointment-button-container {
  display: flex;
  justify-content: center;
}
.doctor-result .card-footer .appointment-heading {
  color: #2c373f;
  font-weight: 700;
  margin-bottom: 0.75rem;
}
.doctor-result .card-footer .appointment-date {
  color: #006D9D;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.doctor-result .card-footer .appointment-time {
  margin-right: 1rem;
}
.doctor-result .card-footer .appointment-request-container {
  padding: 0 1.5rem;
}
.doctor-result .card-footer .appointment-request-btn {
  color: #fff;
  padding: 7px;
  width: 100%;
}
.doctor-result .card-footer .appointment-request-btn + .appointment-request-phone {
  padding-top: 1rem;
}
.doctor-result .card-footer .appointment-request-phone {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  align-items: center;
  color: #2c373f;
  display: flex;
  justify-content: center;
  padding: 0;
  font-weight: 600;
}
.doctor-result .card-footer .appointment-request-phone svg {
  flex: 0 0 2rem;
  height: 2rem;
  margin-right: 1rem;
  width: 2rem;
  stroke: none;
}
.doctor-result .card-footer .appointment-request-phone a {
  color: #006D9D;
  display: block;
}
.doctor-result .card-footer .modal-open-btn {
  background: none;
  border: none;
  color: #006D9D;
  font-weight: 400;
  padding: 0 0.625rem 0 0;
  position: relative;
}
.doctor-result .card-footer .modal-open-btn:after {
  border-bottom: 6px solid transparent;
  border-left: 6px solid #00487c;
  border-top: 6px solid transparent;
  clear: both;
  content: "";
  display: inline-block;
  height: 0;
  left: 100%;
  position: absolute;
  top: 1px;
  width: 0;
}
.doctor-result .card-footer .modal-open-btn:hover, .doctor-result .card-footer .modal-open-btn:focus {
  background: none;
  border: none;
  color: #006D9D;
  outline: 0;
  text-decoration: underline;
}
.doctor-result .card-footer .legend {
  display: none;
}
.view-mode-map .doctor-result {
  height: auto;
  justify-items: flex-start;
  flex-direction: column;
  background-color: #cbced1;
  border: none;
  margin-left: 0;
  margin-top: 0;
}
.view-mode-map .doctor-result .card-header {
  align-items: center;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding: 0;
  text-align: left;
  width: auto;
  background-color: #f5f5f6;
  border: 0.25rem solid #e4e5e6;
}
.view-mode-map .doctor-result .card-header .appointment-type-label {
  display: none;
}
.view-mode-map .doctor-result .profile-image {
  margin: 0 1rem 0 0;
  border-radius: 0;
  border: none;
  min-width: 175px;
}
.view-mode-map .doctor-result .profile-image.has-hours .location-image svg {
  top: 50%;
}
.view-mode-map .doctor-result .doctor-details {
  padding: 1rem 0;
}
.view-mode-map .doctor-result .doctor-location {
  padding: 1rem 0 0;
}
.view-mode-map .doctor-result .card-footer {
  display: none;
  width: 0;
}
.doctor-result .loc-provider-specialty {
  font-size: 1rem;
  line-height: 1.125;
  display: block;
  background-color: #f5f5f6;
  border-top: none;
}
.doctor-result .loc-provider-specialty .loc-provider-specialty-content {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.doctor-result .loc-provider-specialty .loc-provider-specialty-list {
  font-size: 1rem;
  line-height: 1.125;
  align-items: center;
  color: #006D9D;
  display: inline-block;
  justify-content: center;
  padding: 0 1rem;
  list-style: none;
}
.doctor-result .loc-provider-specialty .loc-provider-specialty-list a {
  display: block;
}
@media (min-width: 768px) {
  .doctor-result {
    height: calc(100% - calc(
	(130px / 2) - 0.25rem
));
    justify-content: space-between;
  }
  .view-mode-list .doctor-result {
    height: auto;
    justify-items: flex-start;
    flex-direction: row;
    margin-left: calc(
	(130px / 2) - 0.25rem
);
    margin-top: 0;
  }
  .view-mode-list .doctor-result .card-header,
.view-mode-list .doctor-result .card-footer {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 50%;
  }
  .view-mode-list .doctor-result .card-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-left: 0;
    text-align: left;
  }
  .view-mode-list .doctor-result .card-header .appointment-type-label {
    display: none;
  }
  .view-mode-list .doctor-result .card-header .open-status {
    float: right;
    margin-top: 0;
    bottom: 0;
    position: relative;
  }
  .view-mode-list .doctor-result .doctor-ratings {
    justify-content: flex-start;
  }
  .view-mode-list .doctor-result .profile-image {
    margin-top: 0;
    margin-left: calc((130px) / -2);
    margin-right: 1rem;
    margin-bottom: 0;
  }
  .view-mode-list .doctor-result .doctor-location {
    padding-bottom: 0;
  }
  .view-mode-list .doctor-result .card-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: 50%;
  }
  .view-mode-list .doctor-result .card-footer .appointment-type-label {
    font-size: 1rem;
    line-height: 1.125;
    display: block;
    padding-bottom: 0.5rem;
  }
  .view-mode-list .doctor-result .card-footer .appointment-type-label .appointment-type {
    padding-left: 0.125rem;
  }
  .view-mode-list .doctor-result .card-footer .appointment-type-label:not(:last-of-type) {
    padding-bottom: 0.25rem;
  }
  .view-mode-list .doctor-result .card-footer .location-address:empty {
    display: block;
    padding-bottom: 0;
  }
  .view-mode-list .doctor-result .card-footer .appointment-button-container,
.view-mode-list .doctor-result .card-footer .appointment-request-phone {
    justify-content: left;
  }
  .view-mode-list .doctor-result .card-footer .appointment-request-container {
    padding: 0;
  }
  .view-mode-map .doctor-result .card-header {
    flex-direction: column;
    overflow: visible;
  }
  .view-mode-map .doctor-result .card-header .doctor-location {
    align-items: center;
  }
  .view-mode-map .doctor-result .card-header .open-status {
    position: absolute;
    top: 1rem;
    right: 0;
    margin-right: 0;
  }
  .view-mode-map .doctor-result .profile-image {
    display: flex;
    flex: 0 0 175px;
    min-height: 175px;
    min-width: 175px;
    position: relative;
  }
  .view-mode-map .doctor-result .doctor-details {
    padding: 1rem 1rem 1rem 0;
  }
  .view-mode-map .doctor-result .doctor-details .doctor-name {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
  .doctor-result .specialty {
    font-size: 1rem;
    line-height: 1.125;
  }
  .doctor-result .affiliation-logo {
    max-width: 115px;
  }
  .doctor-result .distance-and-location,
.doctor-result .phone-number,
.doctor-result .appointment-type-label,
.doctor-result .location-name,
.doctor-result .address,
.doctor-result .appointment-time,
.doctor-result .modal-open-btn {
    font-size: 1rem;
    line-height: 1.125;
  }
  .doctor-result .appointment-heading,
.doctor-result .appointment-date {
    font-size: 0.875rem;
    line-height: 1.1428571429;
  }
  .doctor-result .card-footer .appointment-button-container {
    justify-content: center;
  }
  .doctor-result .card-footer .appointment-time {
    margin-right: 1.4rem;
  }
  .doctor-result .card-footer .modal-open-btn:after {
    top: 4px;
  }
}
@media (min-width: 1200px) {
  .doctor-result .card-footer .appointment-request-phone {
    padding: 0 1.25rem;
  }
  .doctor-result .card-footer .loc-provider-specialty-content {
    padding: 0 1.25rem;
  }
  .view-mode-map .doctor-result .doctor-details {
    padding-right: 2rem;
  }
  .view-mode-map .doctor-result .doctor-details .doctor-name {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}

.location-map {
  height: calc(100vh - 270px);
  min-height: 345px;
  overflow: hidden;
  position: relative;
}
.location-map .mapboxgl-marker {
  position: absolute;
}
.location-map .mapboxgl-map {
  overflow: hidden;
}
.location-map .mapboxgl-popup {
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.12);
  max-width: calc(100% - 30px) !important;
  margin-top: -75px;
}
.location-map .mapboxgl-popup:before {
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 30px solid #f5f5f6;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -12px);
}
.location-map .mapboxgl-popup:after {
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border-left: 19px solid transparent;
  border-right: 19px solid transparent;
  border-top: 19px solid #f5f5f6;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -3px);
}
.location-map .mapboxgl-popup-content {
  position: relative;
}
.location-map .doctor-result .profile-image {
  display: none;
}
.location-map .doctor-result .doctor-details {
  padding: 1rem;
}
.location-map .mapboxgl-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.location-map .mapboxgl-canvas-container {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.location-map .mapboxgl-canvas {
  position: absolute;
}
.location-map .mapboxgl-canvas:focus {
  outline: 0;
}
.location-map .spider-leg-container {
  width: 1px;
  height: 1px;
  overflow: display;
  will-change: transform;
  /* Animations specific styles */
}
.location-map .spider-leg-container .spider-leg-pin {
  position: relative;
  z-index: 1;
}
.location-map .spider-leg-container .spider-leg-pin.active .icon {
  fill: #006D9D;
}
.location-map .spider-leg-container .spider-leg-pin .icon {
  border-radius: 50%;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.24);
  transform: translate(-50%, -50%);
  fill: #2c373f;
}
.location-map .spider-leg-container .spider-leg-pin .icon:hover {
  cursor: pointer;
  fill: #006D9D;
}
.location-map .spider-leg-container .spider-leg-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 2px;
  background: linear-gradient(to top, #006D9D calc(100% - 0.9rem), transparent 0.9rem);
  pointer-events: none;
  transform-origin: bottom;
  z-index: 0;
  height: 0;
}
.location-map .spider-leg-container.animate {
  transition: margin 0.15s linear;
}
.location-map .spider-leg-container.animate .spider-leg-line {
  transition: all 0.15s linear;
  transition-delay: inherit;
}
.location-map .spider-leg-container.animate.initial .spider-leg-line, .location-map .spider-leg-container.animate.exit .spider-leg-line {
  height: 0 !important;
}
.location-map .spider-leg-container.initial, .location-map .spider-leg-container.exit {
  margin-left: 0 !important;
  margin-top: 0 !important;
  height: 0;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
  .location-map {
    min-height: 240px;
    height: calc(100vh - 70px);
  }
}
@media (min-width: 768px) {
  .location-map .mapboxgl-popup {
    max-width: 550px !important;
  }
  .location-map .doctor-result .profile-image {
    display: flex;
    border-radius: 50%;
    margin: -87.5px auto 1rem;
  }
  .location-map .doctor-result .doctor-details,
.location-map .doctor-result .doctor-location {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .location-map {
    height: calc(100vh - 224px);
    min-height: 480px;
  }
}
@media (min-width: 1200px) {
  .location-map .mapboxgl-popup {
    max-width: 650px !important;
  }
}

.location-result .doctor-location {
  padding: 1rem 0;
}
.location-result .card-footer .appointment-type-label {
  border-bottom: 1px solid #a2c3d6;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.location-result .card-footer.has-urgent-care-check-in .appointment-heading {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  margin-bottom: 1.75rem;
}
.location-result .card-footer.has-urgent-care-check-in .wait-time,
.location-result .card-footer.has-urgent-care-check-in .patients-in-line {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  color: #00487c;
  font-weight: 700;
}
.location-result .card-footer.has-urgent-care-check-in .wait-time {
  border-bottom: 1px solid #a2c3d6;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}
.location-result .card-footer.has-urgent-care-check-in .patients-in-line {
  margin-bottom: 1.75rem;
}
.location-result .card-footer.has-urgent-care-check-in .btn-primary, .location-result .card-footer.has-urgent-care-check-in .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .location-result .card-footer.has-urgent-care-check-in .mktoButton {
  width: 170px;
}
.location-result .card-footer .location-address {
  display: none;
}
@media (min-width: 768px) {
  .location-result .card-header .doctor-location {
    display: none;
  }
  .location-result .card-footer .location-address {
    display: block;
  }
  .location-result .card-footer .address {
    margin-bottom: 0.25rem;
  }
  .view-mode-map .location-result .card-header .doctor-location {
    display: flex;
    flex-direction: column;
  }
  .view-mode-map .location-result .card-header .phone-number {
    padding-top: 0.375rem;
    order: 1;
  }
}

.search-page {
  min-height: calc(100vh - 90px);
  padding-top: 1.5em;
}
.search-page:not(.find-a-location) {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .search-page:not(.find-a-location) {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .search-page:not(.find-a-location) {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .search-page:not(.find-a-location) {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .search-page:not(.find-a-location) {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.search-page.find-a-location .search-title {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .search-page.find-a-location .search-title {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .search-page.find-a-location .search-title {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .search-page.find-a-location .search-title {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .search-page.find-a-location .search-title {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.search-page.find-a-location .search-title:after {
  background-color: #ffcd55;
  display: block;
  position: relative;
  content: "";
  height: 4px;
  margin: 1rem auto;
  width: 4.375rem;
}
.search-page.find-a-location .search-page-wrapper-outer {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .search-page.find-a-location .search-page-wrapper-outer {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .search-page.find-a-location .search-page-wrapper-outer {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .search-page.find-a-location .search-page-wrapper-outer {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .search-page.find-a-location .search-page-wrapper-outer {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.search-page.find-a-location .search-page-wrapper-outer.view-mode-map .results-list {
  margin-left: -15px;
  padding-top: 0;
  width: calc(100% + 30px);
}
.search-page.find-a-location .search-page-wrapper-outer.view-mode-map .results-list .no-results-message {
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.12);
  left: 50%;
  margin-bottom: 0;
  margin-top: 0;
  max-width: 675px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 15px);
  z-index: 2;
}
.search-page.find-a-location .search-page-wrapper-outer.view-mode-map .results-list .no-results-message + .location-map {
  pointer-events: none;
}
.search-page.find-a-location .search-page-wrapper-outer.view-mode-map .results-list .no-results-message .card-body {
  padding: 0.75rem 1rem;
}
.search-page.find-a-location .search-page-wrapper-outer.view-mode-map .results-list .no-results-message h2 {
  margin-bottom: 1rem;
}
.search-page.find-a-location .search-page-wrapper-outer.view-mode-map .results-list .no-results-message p {
  font-size: 1rem;
  line-height: 1.125;
}
.search-page.find-a-location .search-page-wrapper-outer.view-mode-map .results-list .no-results-message ul li {
  font-size: 1rem;
  line-height: 1.125;
}
.search-page.find-a-location .search-page-wrapper-outer.view-mode-map .loading-overlay {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  padding-top: 0.75rem;
}
.search-page.find-a-location .results-view-mode {
  display: block;
  width: 20%;
}
.search-page .search-title {
  font-size: 1.5rem;
  line-height: 1.25;
  margin-bottom: 1.25rem;
  text-align: center;
}
.search-page .divider {
  margin: 1rem auto;
}
.search-page .seo-content {
  background-color: #f2f9ff;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
  padding: 1.25rem 1.5rem;
}
.search-page .seo-content p {
  font-size: 1rem;
  line-height: 1.125;
}
.search-page .seo-content p:last-of-type {
  margin-bottom: 0;
}
.search-page .seo-content p a {
  font-weight: 700;
}
.search-page .seo-title {
  font-size: 1.5rem;
  line-height: 1.25;
  color: #2c373f;
  font-weight: 700;
  margin-bottom: 1rem;
}
.search-page .search-page-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.search-page .search-page-rail {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.search-page .search-page-results {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
  .search-page.find-a-location .search-page-wrapper-outer.view-mode-map .results-list-container {
    min-height: calc(100vh - 70px);
  }
}
@media (min-width: 768px) {
  .search-page.find-a-location .results-view-mode {
    width: auto;
    order: 1;
  }
  .search-page.find-a-location .search-page-wrapper-outer.view-mode-map .results-list-container {
    min-height: 320px;
  }
  .search-page.find-a-location .search-page-wrapper-outer.view-mode-map .results-list .no-results-message {
    width: 100%;
  }
  .search-page.find-a-location .search-page-wrapper-outer.view-mode-map .results-list .no-results-message .card-body {
    padding: 2rem;
  }
  .search-page.find-a-location .search-page-wrapper-outer.view-mode-map .results-list .no-results-message h2 {
    margin-bottom: 1.25rem;
  }
  .search-page.find-a-location .search-page-wrapper-outer.view-mode-map .results-list .no-results-message p {
    font-size: 1.25rem;
    line-height: 1.2;
  }
  .search-page.find-a-location .search-page-wrapper-outer.view-mode-map .results-list .no-results-message ul li {
    font-size: 1.25rem;
    line-height: 1.2;
  }
}
@media (min-width: 992px) {
  .search-page {
    min-height: calc(100vh - 141px);
    padding-top: 2em;
  }
  .search-page .search-title {
    font-size: 1.25rem;
    line-height: 1.2;
    text-align: left;
  }
  .search-page .divider {
    margin: 1rem auto 1rem 0;
  }
  .search-page .seo-content {
    margin-top: 0;
  }
  .search-page .seo-content p {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
  .search-page .mobile-filters-toggle {
    display: none;
  }
  .search-page .search-page-rail {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .search-page .search-page-results {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .search-page.find-a-location .search-title {
    text-align: left;
  }
  .search-page.find-a-location .search-title::after {
    margin: 1rem auto 1rem 0;
  }
  .search-page.find-a-location .divider {
    margin: 1rem auto 1rem 15px;
  }
  .search-page.find-a-location .search-page-wrapper-outer.view-mode-map {
    margin-left: 0;
  }
  .search-page.find-a-location .search-page-wrapper-outer.view-mode-map .search-page-results {
    margin-top: -60px;
  }
  .search-page.find-a-location .search-page-wrapper-outer.view-mode-map .seo-content,
.search-page.find-a-location .search-page-wrapper-outer.view-mode-map .results-controls,
.search-page.find-a-location .search-page-wrapper-outer.view-mode-map .results-list-container {
    width: calc(100vw - (50% + 45px));
  }
  .search-page.find-a-location .search-page-wrapper-outer.view-mode-map .results-list {
    margin-left: 0;
    padding-top: 1rem;
    width: calc(100% + 15px);
  }
  .search-page.find-a-location .search-page-wrapper-outer.view-mode-map .results-list .no-results-message {
    margin: 0 0.5rem;
  }
  .search-page.find-a-location .search-page-wrapper-outer.view-mode-map .results-list .no-results-message .card-body {
    padding: 2.5rem;
  }
  .search-page.find-a-location .search-page-wrapper-outer.view-mode-map .loading-overlay {
    left: 0;
    padding-top: 1rem;
    width: calc(100% + 15px);
  }
  .search-page.find-a-location .search-page-wrapper-outer.view-mode-map .loading-overlay:after {
    margin-left: -1rem;
    margin-top: -1rem;
  }
}
@media (min-width: 1200px) {
  .search-page .search-title.view-mode-map {
    margin-left: 0;
  }
}

.services-callout {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .services-callout {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .services-callout {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .services-callout {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .services-callout {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.services-callout .services-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.services-callout .description {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.services-callout .description p {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #3d5d6b;
  font-weight: 600;
}
.services-callout .services-actions {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.services-callout .callout-links {
  background-color: #f2f9ff;
  padding: 1.5rem 1.5rem 1rem;
  margin-bottom: 1rem;
}
.services-callout .callout-links .callout-links-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.services-callout .callout-links .callout-links-header .heading-6 {
  margin-bottom: 0;
  margin-right: 0.75rem;
}
.services-callout .callout-links .callout-links-header a {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  font-weight: 600;
}
.services-callout .callout-links .callout-links-header a:focus {
  outline: 0;
  text-decoration: underline;
}
.services-callout .callout-links .callout-links-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.services-callout .callout-links .btn-icon {
  background-color: #fff;
  border-color: #fff;
  color: #a2c3d6;
  margin-top: 1.25rem;
  padding: 0.625rem 0.75rem;
  position: relative;
}
.services-callout .callout-links .btn-icon:not(:last-of-type) {
  margin-bottom: 0.5rem;
}
.services-callout .callout-links .btn-icon:hover {
  background-color: #f5f5f6;
  border-color: #f5f5f6;
}
.services-callout .callout-links .btn-icon:focus {
  box-shadow: 0 0 0 0.2rem rgba(162, 195, 214, 0.5);
}
.services-callout .callout-links .btn-icon picture,
.services-callout .callout-links .btn-icon svg {
  align-items: center;
  display: flex;
  height: 2rem;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(-50%, -65%);
  width: 3rem;
}
.services-callout .callout-links .btn-icon picture img,
.services-callout .callout-links .btn-icon svg img {
  width: 100%;
}
.services-callout .callout-links .callout-links-note {
  font-size: 1rem;
  line-height: 1.125;
  color: rgba(61, 93, 107, 0.8);
  font-style: italic;
  margin-bottom: 0;
  margin-left: 0.125rem;
}
.services-callout .services-links {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.services-callout .services-links a {
  font-size: 1rem;
  line-height: 1.125;
  color: #fff;
  background-color: #a2c3d6;
  display: block;
  font-weight: 600;
  padding: 1rem 1.5rem;
  width: 100%;
}
.services-callout .services-links a:not(:last-of-type) {
  margin-bottom: 0.5rem;
}
.services-callout .services-links a:focus {
  outline: 0;
  text-decoration: underline;
}
@media (min-width: 768px) {
  .services-callout .services-actions-container {
    display: flex;
  }
  .services-callout .callout-links {
    margin-bottom: 0;
  }
  .services-callout .callout-links .callout-links-container {
    flex-direction: row;
  }
  .services-callout .callout-links .btn-icon:not(:last-of-type) {
    margin-bottom: 0;
    margin-right: 0.875rem;
  }
  .services-callout .services-links {
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .services-callout .description {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    padding-right: 1rem;
  }
  .services-callout .services-actions {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .services-callout .services-actions {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
    padding-left: 15px;
  }
  .services-callout .services-links {
    padding-left: 30px;
  }
}

.social-share {
  text-align: center;
}
.social-share a,
.social-share button {
  background: transparent;
  border: 0;
  padding: 0;
}
.social-share a:hover,
.social-share button:hover {
  text-decoration: none;
}
.social-share a:hover .icon,
.social-share button:hover .icon {
  fill: #006D9D;
  stroke: #006D9D;
}
.social-share a + a,
.social-share a + button,
.social-share button + a,
.social-share button + button {
  margin-left: 0.75rem;
}
.social-share .icon {
  fill: #3d5d6b;
  height: 2rem;
  pointer-events: none;
  stroke: #3d5d6b;
  width: 2rem;
}

.two-up {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .two-up {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .two-up {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .two-up {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .two-up {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.two-up .two-up-inner {
  background-color: #fff;
  margin-bottom: 2rem;
  max-width: 100%;
}
.two-up .heading-1:first-of-type,
.two-up .heading-3:first-of-type,
.two-up .heading-3:first-of-type,
.two-up .heading-4:first-of-type,
.two-up .heading-5:first-of-type,
.two-up .heading-6:first-of-type {
  margin-top: 0;
}
.two-up .heading-3 {
  font-size: 1.75rem;
  line-height: 1.0714285714;
  color: #2c373f;
}
.two-up .divider {
  margin: 1rem auto 0 0;
}
.two-up .edw-title {
  text-transform: uppercase;
}
.two-up p {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #3d5d6b;
}
.two-up .btn, .two-up .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .two-up .mktoButton {
  padding-left: 3rem;
  padding-right: 3rem;
}
.two-up .card-deck {
  flex-direction: column;
}
.two-up .card-deck:first-of-type {
  display: none;
}
.two-up .two-up-header,
.two-up .two-up-content,
.two-up .two-up-footer {
  background-color: transparent;
  border-top: none;
  border-bottom: none;
}
.two-up .two-up-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1.25rem 3.25rem 0;
}
.two-up .two-up-content {
  padding: 1.25rem 3.25rem;
}
.two-up .two-up-footer {
  padding: 0 3.25rem 1.25rem;
  margin-bottom: 1rem;
}
.two-up .two-up-img {
  order: 2;
  display: block;
  border-radius: 5px;
  margin-bottom: 1rem;
}
.two-up .two-up-img img,
.two-up .two-up-img source {
  object-fit: cover;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%;";
  height: 15rem;
  width: 100%;
}
.two-up .two-up-content-container {
  flex: auto;
  max-width: 100%;
  padding: 0;
  margin-bottom: 1rem;
}
@media (min-width: 576px) {
  .two-up .card-deck {
    flex-flow: column;
  }
  .two-up .two-up-img {
    height: 18rem;
  }
  .two-up .two-up-img img,
.two-up .two-up-img source {
    height: 18rem;
  }
}
@media (min-width: 768px) {
  .two-column .two-up .two-up-inner {
    margin-bottom: 0;
  }
  .two-column .two-up .card-deck {
    flex-flow: column;
  }
  .two-column .two-up .card-deck:first-of-type {
    display: none;
  }
  .two-column .two-up .card-deck:not(:first-of-type) .two-up-content-container .two-up-header {
    display: flex;
  }
  .two-column .two-up .two-up-content-container {
    flex: 1 1 0;
    max-width: 100%;
    padding: 0;
    margin-bottom: 1rem;
  }
  .two-column .two-up .two-up-content-container:first-child {
    margin-left: 0;
    margin-right: 15px;
  }
  .two-column .two-up .two-up-content-container:not(:first-child) {
    margin-left: 0;
  }
  .two-column .two-up .two-up-header {
    padding: 1.25rem 3.25rem 0;
  }
  .two-column .two-up .two-up-content {
    padding: 1.25rem 3.25rem;
  }
  .two-column .two-up .two-up-footer {
    padding: 0 3.25rem 1.25rem;
  }
  .two-column .two-up .two-up-img {
    height: 18rem;
  }
  .two-column .two-up .two-up-img img,
.two-column .two-up .two-up-img source {
    height: 18rem;
  }
  .two-column .two-up.center .two-up-content-container {
    margin-left: 15px;
  }
  .two-column .two-up.right .two-up-content-container {
    margin-left: 15px;
    margin-right: 0;
  }
  .two-column .two-up.right .two-up-img {
    margin-right: -2.5rem;
  }
  .two-up.center .two-up-inner {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .two-up.center .two-up-content-container:first-child {
    margin-left: 15px;
  }
  .two-up.center .two-up-content-container:not(:first-child) {
    margin-right: 15px;
  }
  .two-up.center .two-up-img {
    margin-left: auto;
    margin-right: auto;
  }
  .two-up.center .two-up-img:not(.left):not(.right) {
    width: calc(100% - 3rem);
  }
  .two-up.right .two-up-inner {
    padding-left: 0;
    padding-right: 2.5rem;
  }
  .two-up.right .two-up-content-container:first-child {
    margin-left: 15px;
  }
  .two-up.right .two-up-content-container:not(:first-child) {
    margin-right: 0;
  }
  .two-up.right .two-up-img {
    margin-left: auto;
    margin-right: -2rem;
  }
  .two-up .card-deck {
    flex-flow: row wrap;
  }
  .two-up .card-deck:first-of-type {
    display: flex;
  }
  .two-up .card-deck:not(:first-of-type) .two-up-content-container .two-up-header {
    display: none;
  }
  .two-up .two-up-content-container {
    flex: 1 0 40%;
    max-width: 50%;
    padding: 0;
    margin-bottom: 0;
    justify-content: flex-end;
  }
  .two-up .two-up-content-container:first-child {
    margin-left: 0;
    margin-right: 1rem;
  }
  .two-up .two-up-content-container:not(:first-child) {
    margin-left: 1rem;
  }
  .two-up .two-up-header {
    padding: 0 1.5rem;
  }
  .two-up .two-up-content {
    padding: 2rem 1.5rem;
  }
  .two-up .two-up-footer {
    padding: 0 1.5rem 2rem;
  }
  .two-up .two-up-img {
    height: 10.75rem;
    width: 100%;
  }
  .two-up .two-up-img img,
.two-up .two-up-img source {
    height: 10.75rem;
  }
  .two-up .two-up-img.left {
    margin-left: -2rem;
    margin-right: auto;
  }
  .two-up .two-up-img.center {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 3rem);
  }
  .two-up .two-up-img.right {
    margin-left: auto;
    margin-right: -2rem;
  }
  .two-up .two-up-content {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .two-column .two-up .two-up-inner {
    margin-bottom: 2rem;
  }
  .two-column .two-up .card-deck {
    flex-flow: row wrap;
  }
  .two-column .two-up .card-deck:first-of-type {
    display: flex;
  }
  .two-column .two-up .card-deck:not(:first-of-type) .two-up-content-container .two-up-header {
    display: none;
  }
  .two-column .two-up .two-up-content-container {
    flex: 1 0 40%;
    max-width: 50%;
    margin-bottom: 0;
  }
  .two-column .two-up .two-up-content-container:first-child {
    margin-right: 1rem;
  }
  .two-column .two-up .two-up-content-container:not(:first-child) {
    margin-left: 1rem;
  }
  .two-column .two-up .two-up-header {
    padding: 0 1.5rem;
  }
  .two-column .two-up .two-up-content {
    padding: 2rem 1.5rem;
  }
  .two-column .two-up .two-up-footer {
    padding: 0 1.5rem 2rem;
  }
  .two-column .two-up .two-up-img {
    height: 10.75rem;
  }
  .two-column .two-up .two-up-img img,
.two-column .two-up .two-up-img source {
    height: 10.75rem;
  }
  .two-column .two-up.center .two-up-inner {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .two-column .two-up.right .two-up-inner {
    padding-left: 0;
    padding-right: 2.5rem;
  }
  .two-up.center .two-up-inner {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .two-up.right .two-up-inner {
    padding-left: 0;
    padding-right: 5rem;
  }
  .two-up.right .two-up-img {
    margin-left: auto;
    margin-right: -2.5rem;
  }
  .two-up .two-up-content-container:first-child {
    margin-right: 1.5rem;
  }
  .two-up .two-up-content-container:not(:first-child) {
    margin-left: 1.5rem;
  }
  .two-up .two-up-img {
    height: 14rem;
  }
  .two-up .two-up-img img,
.two-up .two-up-img source {
    height: 14rem;
  }
  .two-up .two-up-img.left {
    margin-left: -2.5rem;
    margin-right: auto;
  }
  .two-up .two-up-img.right {
    margin-left: auto;
    margin-right: -2.5rem;
  }
}
@media (min-width: 1200px) {
  .two-column .two-up .two-up-content {
    padding: 2rem 1.5rem;
  }
  .two-column .two-up .two-up-img {
    height: 14rem;
  }
  .two-column .two-up .two-up-img img,
.two-column .two-up .two-up-img source {
    height: 14rem;
  }
  .two-column .two-up.center .two-up-inner {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .two-column .two-up.right .two-up-inner {
    padding-left: 0;
    padding-right: 4rem;
  }
  .two-up .two-up-img {
    height: 18rem;
  }
  .two-up .two-up-img img,
.two-up .two-up-img source {
    height: 18rem;
  }
}

.two-column {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .two-column {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .two-column {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .two-column {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .two-column {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.two-column.alt-background {
  background-color: #f2f9ff;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.two-column .row > * {
  margin-bottom: 1.25rem;
}
.two-column .row > * > :last-child {
  margin-bottom: 0;
}
.two-column .landing-page .heading-2 {
  font-size: 1.75rem;
  line-height: 1.2142857143;
  font-weight: 700;
}
.two-column .landing-page .heading-3 {
  font-size: 1.5rem;
  line-height: 1.4166666667;
}
.two-column .landing-page .heading-3:nth-child(n+3) {
  font-weight: 400;
}
.two-column .landing-page.two-column-sidebar h2 {
  font-size: 1.5rem;
  line-height: 1.4166666667;
}
@media (min-width: 768px) {
  .two-column .row > * {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .two-column .landing-page.two-column-main {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .two-column .landing-page.two-column-sidebar h2 {
    font-size: 1.75rem;
    line-height: 1.2142857143;
  }
}
@media (min-width: 1200px) {
  .two-column .landing-page.two-column-main {
    flex: 0 0 43%;
    max-width: 43%;
  }
}

.thank-you-message {
  align-items: center;
  background-color: #3d5d6b;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6rem 2rem;
  position: relative;
  text-align: center;
}
.thank-you-message .thank-you-bubble {
  font-size: 1.5rem;
  line-height: 1;
  border: 3px solid #ffcd55;
  border-radius: 15px;
  color: #ffcd55;
  font-weight: 700;
  margin-bottom: 2rem;
  padding: 0.625rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.thank-you-message .thank-you-bubble:before {
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-top: 13px solid #ffcd55;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.thank-you-message .thank-you-bubble:after {
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #3d5d6b;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.thank-you-message .thank-you-content {
  color: #fff;
}
.thank-you-message .thank-you-content .heading-3,
.thank-you-message .thank-you-content .heading-4,
.thank-you-message .thank-you-content .heading-5,
.thank-you-message .thank-you-content h3,
.thank-you-message .thank-you-content h4,
.thank-you-message .thank-you-content h5 {
  color: #e4e5e6;
  font-weight: 600;
}
.thank-you-message .thank-you-content .heading-3 {
  font-size: 1.5rem;
  line-height: 1.25;
}
.thank-you-message .thank-you-content .heading-4 {
  font-size: 1.25rem;
  line-height: 1.2;
}
.thank-you-message .thank-you-content p {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-weight: 600;
}
.thank-you-message .thank-you-content p a {
  color: #ffcd55;
}
@media (min-width: 768px) {
  .thank-you-message {
    padding: 6rem 3rem;
  }
}

.three-column {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .three-column {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .three-column {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .three-column {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .three-column {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.three-column.alt-background {
  background-color: #f2f9ff;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.three-column .row > * {
  margin-bottom: 1.25rem;
}
.three-column .row > * > :last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .three-column .row > * {
    margin-bottom: 0;
  }
}

.three-up {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .three-up {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .three-up {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .three-up {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .three-up {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.two-column-sidebar .three-up {
  margin-bottom: 2rem;
  padding: 0;
}
.two-column-sidebar .three-up .card {
  border: 0;
}
.two-column-sidebar .three-up .card:not(:last-of-type) {
  margin-bottom: 2rem;
}
.three-up .three-up-inner {
  background-color: #fff;
  margin-bottom: 1.5rem;
  max-width: 100%;
  text-align: center;
}
.three-up .three-up-inner.right .three-up-content {
  margin-left: 1rem;
  text-align: right;
  right: 0;
}
.three-up .three-up-inner.right .divider-syndicated {
  border-top: 0;
  margin-left: auto;
  margin-right: 0;
}
.three-up .heading-4 {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #3d5d6b;
  font-weight: 700;
  margin-bottom: 1rem;
}
.three-up p {
  font-size: 1rem;
  line-height: 1.125;
  color: #3d5d6b;
  font-weight: 600;
  margin-bottom: 0.75rem;
}
.three-up p span {
  padding-right: 0.5rem;
}
.three-up p span:after {
  content: "/";
  padding-left: 0.5rem;
}
.three-up p span:last-child:after {
  content: "";
  padding-left: 0.5rem;
}
.three-up .btn-primary, .three-up .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .three-up .mktoButton {
  padding-left: 3rem;
  padding-right: 3rem;
  margin-left: auto;
  margin-right: auto;
}
.three-up .btn-link {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  padding: 0;
  color: #005557;
  font-weight: 700;
  text-transform: uppercase;
}
.three-up .btn-link.video {
  position: relative;
  padding-right: 1.5rem;
}
.three-up .btn-link.video:after {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  content: "►";
  padding-left: 0.5rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.three-up .divider-syndicated {
  border-bottom: 2px solid #c5d6df;
  margin-bottom: 0.25rem;
  margin-left: 0;
  margin-right: auto;
  margin-top: 1rem;
  width: 4rem;
}
.three-up .card-deck {
  flex-direction: column;
}
.three-up .three-up-content {
  margin-bottom: 0;
  padding: 1.5rem 1rem 1.5rem 0;
  text-align: left;
  width: calc(100% - 1rem);
}
.three-up .three-up-footer {
  background-color: transparent;
  border-top: 0;
  margin-bottom: 1.5rem;
  padding: 0 1.25rem 0.75rem 0;
  text-align: left;
}
.three-up .three-up-img {
  position: relative;
  border-radius: 0;
  height: 300px;
  overflow: hidden;
}
.three-up .three-up-img img,
.three-up .three-up-img source {
  object-fit: cover;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%;";
  min-height: 300px;
  height: 100%;
  max-height: 400px;
  width: 100%;
}
.three-up .three-up-content-container {
  border: 0;
  flex: auto;
  min-height: 300px;
  height: auto;
  max-width: 100%;
  padding: 0;
  margin-bottom: 2rem;
}
.three-up .modal-content {
  background: transparent;
  border: none;
}
.three-up .modal-content .modal-body {
  padding: 0;
}
.three-up .modal-content .media-item {
  width: 100%;
  padding: 0;
}
.three-up .modal-content .media-item .heading-4 {
  display: none;
}
.three-up .modal-content .media-item .figure-text {
  background-color: #f2f9ff;
  padding: 1rem 1.25rem;
}
@media (min-width: 576px) {
  .three-up .three-up-content {
    max-width: 425px;
    margin-right: auto;
    width: 100%;
  }
  .three-up .three-up-img img,
.three-up .three-up-img source {
    max-height: none;
  }
}
@media (min-width: 768px) {
  .three-up .card-deck {
    flex-flow: wrap;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .three-up .three-up-content-container {
    flex: 0 1 48.5%;
    max-width: 48.5%;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
  }
  .three-up .three-up-content-container:nth-child(odd) {
    margin-left: 0;
  }
  .three-up .three-up-content-container:nth-child(even) {
    margin-right: 0;
  }
  .three-up .three-up-content-container:nth-child(odd):last-child {
    margin-left: auto;
    margin-right: auto;
  }
  .three-up .three-up-content {
    margin-right: auto;
  }
  .three-up .three-up-img img,
.three-up .three-up-img source {
    min-height: 300px;
    max-height: 400px;
  }
}
@media (min-width: 992px) {
  .three-up .three-up-content-container {
    flex: 0 1 32.333333%;
    max-width: 32.333333%;
  }
  .three-up .three-up-content-container:nth-child(3n+1) {
    margin-left: 0;
    margin-right: auto;
  }
  .three-up .three-up-content-container:nth-child(3n+1) {
    margin-left: 0;
    margin-right: auto;
  }
  .three-up .three-up-content-container:nth-child(3n+1):last-child {
    margin-left: auto;
  }
  .three-up .three-up-content-container:nth-child(3n+1):nth-last-child(2) {
    margin-left: auto;
    margin-right: 0.5rem;
  }
  .three-up .three-up-content-container:nth-child(3n+2) {
    margin-left: auto;
    margin-right: auto;
  }
  .three-up .three-up-content-container:nth-child(3n+2):last-child, .three-up .three-up-content-container:nth-child(3n+2):nth-child(odd):last-child {
    margin-left: 0.5rem;
    margin-right: auto;
  }
  .three-up .three-up-content-container:nth-child(3n+3), .three-up .three-up-content-container:nth-child(odd):last-child {
    margin-left: auto;
    margin-right: 0;
  }
}
@media (min-width: 1200px) {
  .three-up .three-up-img img,
.three-up .three-up-img source {
    min-height: 360px;
    max-height: 400px;
  }
}

.up-header {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
@media (min-width: 576px) {
  .up-header {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .up-header {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .up-header {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .up-header {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.up-header .heading-3 {
  font-size: 1.75rem;
  line-height: 1.0714285714;
  color: #2c373f;
  font-weight: 600;
}
.up-header .one-up,
.up-header .two-up,
.up-header .three-up {
  padding-left: 0;
  padding-right: 0;
}

.custom-modal {
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity 0.2s ease-in-out;
  transition-delay: 0.2s;
  width: 100%;
  z-index: 10;
}
.custom-modal-open .custom-modal {
  opacity: 1;
  overflow: auto;
  pointer-events: auto;
  transition-delay: 0s;
}
.custom-modal .modal-grid {
  margin: 0 auto;
  overflow: hidden;
}
.custom-modal .modal-content {
  background: #fff;
  border-radius: 0;
  min-height: 100%;
  opacity: 0;
  position: relative;
  transition: all 0.2s ease-in-out;
  transform: translateY(3rem);
  width: 100%;
}
.custom-modal-open .custom-modal .modal-content {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.2s;
}
.custom-modal .modal-content .modal-close-btn {
  font-size: 1.125rem;
  line-height: 0.8888888889;
  background: none;
  border: none;
  color: #006D9D;
  font-weight: 800;
  right: 1.5rem;
  padding: 0;
  position: absolute;
  top: 1.5rem;
}
.custom-modal .modal-content .modal-close-btn .icon {
  fill: #006D9D;
}
.custom-modal .modal-content .modal-close-btn:hover, .custom-modal .modal-content .modal-close-btn:focus {
  outline: 0;
}
.custom-modal .modal-content .modal-close-btn:hover .icon, .custom-modal .modal-content .modal-close-btn:focus .icon {
  fill: #006D9D;
}
@media (min-width: 992px) {
  .custom-modal {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    overflow: auto;
  }
  .custom-modal .modal-scroll-container {
    max-height: 100vh;
    overflow: auto;
    width: 100%;
  }
  .custom-modal .modal-grid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    max-width: none;
  }
}
@media (min-width: 992px) and (min-width: 576px) {
  .custom-modal .modal-grid {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) and (min-width: 768px) {
  .custom-modal .modal-grid {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .custom-modal .modal-grid {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  .custom-modal .modal-grid {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .custom-modal .modal-content {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
    border: solid 0.25rem #a2c3d6;
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.12);
    height: auto;
    margin: 3rem 0;
    min-height: auto;
  }
}

.doctor-appointments .doctor-result {
  align-items: center;
  background-color: #fff;
  border: none;
  height: auto;
  justify-content: space-between;
  margin: 0;
  padding: 1rem 15px;
}
.doctor-appointments .profile-image {
  margin: 0 0 2rem 0;
}
.doctor-appointments .doctor-details {
  margin-bottom: 1rem;
}
.doctor-appointments .doctor-details.name-container {
  text-align: center;
}
.doctor-appointments .distance-and-location {
  text-align: center;
}
.doctor-appointments .doctor-location {
  padding: 2rem 0 0;
}
.doctor-appointments .location-select .select-wrapper {
  align-items: center;
  background-color: #f2f9ff;
  color: #2c373f;
  display: flex;
  padding: 0.375rem;
  position: relative;
  margin: 0.25rem -0.375rem;
}
.doctor-appointments .location-select .select-wrapper:focus {
  border-color: #005557;
  box-shadow: 0 0 0 0.2rem rgba(0, 85, 87, 0.5);
}
.doctor-appointments .location-select .select-wrapper select {
  font-size: 1rem;
  line-height: 1.125;
  background-color: transparent;
  border: 0;
  color: #3d5d6b;
  display: block;
  padding-right: 2rem;
  white-space: normal;
  width: 100%;
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
  /* For IE10 */
}
.doctor-appointments .location-select .select-wrapper select::-ms-expand {
  display: none;
}
.doctor-appointments .location-select .select-wrapper select:focus {
  outline: 0;
}
.doctor-appointments .location-select .select-wrapper .icon {
  pointer-events: none;
  position: absolute;
  right: 0.5rem;
  stroke: #006D9D;
  top: 0.5rem;
  width: 0.75rem;
}
.doctor-appointments .location-address {
  font-size: 1rem;
  line-height: 1.125;
  margin-bottom: 1rem;
}
.doctor-appointments .phone-number {
  font-size: 1rem;
  line-height: 1.125;
}
@media (min-width: 992px) {
  .doctor-appointments .doctor-result {
    flex-direction: row;
    justify-content: flex-start;
    padding: 1rem 3rem 1rem 2rem;
  }
  .doctor-appointments .profile-image {
    height: 100px;
    margin: 0 1.5rem 0 0;
    min-width: 100px;
    width: 100px;
  }
  .doctor-appointments .doctor-details {
    flex-grow: 1;
    margin-bottom: 0;
    margin-right: 2rem;
  }
  .doctor-appointments .doctor-details.name-container {
    text-align: left;
  }
  .doctor-appointments .doctor-details:last-child {
    max-width: 45%;
  }
  .doctor-appointments .distance-and-location {
    text-align: left;
  }
  .doctor-appointments .doctor-location {
    padding: 1rem 0 0;
  }
}

.video {
  padding: 56.25% 0 0 0;
  position: relative;
}
.video.standard {
  padding: 56.25% 0 0 0;
  position: relative;
}
.video.anamorphic {
  padding: 42% 0 0 0;
  position: relative;
}
.video.academy {
  padding: 75% 0 0 0;
  position: relative;
}
.video.square {
  padding: 100% 0 0 0;
  position: relative;
}
.video.widescreen {
  padding: 50% 0 0 0;
  position: relative;
}
.video.loaded {
  padding: 0;
}
.video.loaded .wistia_embed {
  position: relative;
}
.video .wistia_embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.video .wistia_embed > .wistis_swatch {
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: opacity 200ms;
  width: 100%;
}
.video .wistia_embed > .wistis_swatch > img {
  filter: blur(5px);
  height: 100%;
  object-fit: contain;
  font-family: "object-fit: contain;";
  width: 100%;
}
.video .wistia_embed > div {
  border-radius: 5px !important;
}
.video .wistia_embed .w-bpb-wrapper {
  border-radius: 50% !important;
  height: 80px !important;
  overflow: hidden;
  width: 80px !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  top: calc(50% - 40px) !important;
  left: calc(50% - 40px) !important;
}
.video .wistia_embed .w-bpb-wrapper button {
  height: 100% !important;
  width: 100% !important;
}
.video .wistia_embed .w-bpb-wrapper button div {
  border-radius: 50% !important;
}
.video .wistia_embed .w-bpb-wrapper button div,
.video .wistia_embed .w-bpb-wrapper button svg {
  width: 100% !important;
  height: 100% !important;
  background-color: #ffcd55;
  border-radius: 7rem;
}
@media (min-width: 576px) {
  .video .wistia_embed .w-bpb-wrapper {
    height: 112px !important;
    width: 112px !important;
    top: calc(50% - 56px) !important;
    left: calc(50% - 56px) !important;
  }
}

.bi-omnibar {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 1rem;
  padding-bottom: 4rem;
}
.bi-omnibar .bi-omnibar-inner {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  border-radius: 1rem;
  /* Tashika felt that bottom padding in the figma specs were too high. Moved bottom padding to 1.5rem (24px) */
  /* See https://mhhsmarketing.atlassian.net/browse/SIT-2047 */
  padding: 1rem 0 1.5rem;
  position: relative;
  /* We need to override blockit's margin:left and right to correctly vertically align. */
  /* See SIT-2083 https://mhhsmarketing.atlassian.net/browse/SIT-2083*/
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 576px) {
  .bi-omnibar .bi-omnibar-inner {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .bi-omnibar .bi-omnibar-inner {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .bi-omnibar .bi-omnibar-inner {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .bi-omnibar .bi-omnibar-inner {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.bi-omnibar .bi-omnibar-inner.minified .filter-group:not(:first-of-type),
.bi-omnibar .bi-omnibar-inner.minified button[type=submit] {
  display: none;
}
.bi-omnibar .bi-omnibar-inner.minified .bi-omnibar-search-typeahead .search-dropdown {
  margin-top: -1rem;
}
.bi-omnibar .heading-2 {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #2c373f;
  display: block;
  margin-bottom: 1rem;
  padding-bottom: 0.725rem;
  width: 100%;
  text-align: left;
}
.bi-omnibar .heading-2:after {
  background-color: #ffcd55;
  top: 10px;
  bottom: 0;
  content: "";
  display: block;
  height: 4px;
  position: relative;
  width: 58px;
}
.bi-omnibar .button-group {
  display: flex;
  gap: 16px 16px;
  flex-wrap: wrap;
}
.bi-omnibar .button-group a {
  font-size: 1rem;
  font-family: "proxima-nova";
  line-height: 1.125rem;
  background: none;
  color: #006D9D;
  font-weight: 600;
  justify-content: flex-start;
  outline: 0;
  position: relative;
  min-width: 29%;
  height: 1rem;
  flex-basis: 100%;
  align-items: center;
  display: flex;
}
.bi-omnibar .button-group a span {
  max-width: 85%;
  position: relative;
  display: block;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 4px;
}
.bi-omnibar .button-group .icon-arrow-right {
  fill: #006D9D;
  pointer-events: none;
  top: calc(50% - 7px);
  height: 1rem;
  width: 1.25rem;
  padding-left: 0.75rem;
}
.bi-omnibar form {
  position: relative;
}
.bi-omnibar form .search-form {
  border-bottom: 1px solid rgba(55, 55, 55, 0.5);
  display: flex;
}
.bi-omnibar .filter-group {
  margin-bottom: 1.5rem;
  position: relative;
}
.bi-omnibar label {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #2c373f;
  font-weight: 600;
  text-align: left;
}
.bi-omnibar .icon {
  fill: #a2c3d6;
  display: flex;
  align-self: center;
  z-index: 1;
  right: 2rem;
}
.bi-omnibar .form-group > input,
.bi-omnibar .form-group > label,
.bi-omnibar .form-group > select,
.bi-omnibar .form-label-group > input,
.bi-omnibar .form-label-group > label {
  background-color: transparent;
  border: 1px solid #2c373f;
  border-radius: 2rem;
  padding: 1rem 2.75rem 1rem 1.75rem;
}
.bi-omnibar .form-group > label,
.bi-omnibar .form-label-group > label {
  position: absolute;
  top: -10px;
  left: 0px;
  border: none;
  display: block;
  width: 100%;
  margin-bottom: 0;
  color: #3d5d6b;
  pointer-events: none;
  cursor: text;
  transition: all 0.1s ease-in-out;
}
.bi-omnibar .form-group input::placeholder,
.bi-omnibar .form-label-group input::placeholder {
  color: transparent;
}
.bi-omnibar .form-group input:not(:placeholder-shown) ~ label,
.bi-omnibar .form-label-group input:not(:placeholder-shown) ~ label {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  display: none;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.bi-omnibar select {
  background-color: #fff !important;
  border-radius: 0;
  border: none;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  padding-right: 2.5rem;
  position: relative;
  appearance: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  display: block;
}
.bi-omnibar select::-ms-expand {
  display: none;
}
.bi-omnibar .clear-btn {
  margin: 1.5rem 1rem 0.5rem;
  padding: 0;
}
.bi-omnibar .bi-omnibar-search-typeahead {
  position: relative;
}
.bi-omnibar .bi-omnibar-search-typeahead.search-focus .search-dropdown {
  display: block;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-typeahead-input::-ms-clear {
  display: none;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown {
  background-color: #fff;
  border: 1px solid #cbced1;
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.12);
  display: none;
  margin-top: -3.5rem;
  position: absolute;
  top: 100%;
  left: 50%;
  text-align: left;
  transform: translateX(-50%);
  width: calc(100vw - 60px);
  max-width: 1240px;
  z-index: 6;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown ul li {
  font-size: 1rem;
  line-height: 1.125;
  text-align: left;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown ul li:before {
  display: none;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown ul li:not(:last-of-type) {
  margin-bottom: 0.5rem;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown ul li a {
  font-size: 1rem;
  line-height: 1.125;
  color: #3d5d6b;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown ul li a:focus, .bi-omnibar .bi-omnibar-search-typeahead .search-dropdown ul li a.active {
  outline: 0;
  text-decoration: underline;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .heading-5 {
  margin-bottom: 0.625rem;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .view-all-link {
  font-size: 1rem;
  line-height: 1.125;
  color: #006D9D;
  display: inline-block;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 0;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .view-all-link:focus, .bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .view-all-link.active {
  outline: 0;
  text-decoration: underline;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .view-all-link.looking-for {
  color: #cbced1;
  font-style: italic;
  margin-top: 0;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .primary-view-all {
  font-size: 1rem;
  line-height: 1.125;
  align-items: center;
  background-color: #f2f9ff;
  color: #006D9D;
  display: flex;
  font-weight: 600;
  height: 50px;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .primary-view-all:focus, .bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .primary-view-all.active {
  outline: 0;
  text-decoration: underline;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .category-list:not(:first-of-type) {
  margin-top: 1.5rem;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .doctor-name {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #006D9D;
  font-weight: 600;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .location-name {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #006D9D;
  font-weight: 600;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .dropdown-container {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  flex-direction: column;
  margin: 0;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .dropdown-container .dropdown-primary {
  order: 1;
  padding: 1.75rem 1.75rem 1.25rem;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary {
  order: 2;
  padding: 0.75rem 1.75rem 1.25rem;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .view-all-link {
  display: none;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .view-all-link.mobile-find-link {
  align-items: center;
  border-bottom: 1px solid #cbced1;
  color: #2c373f;
  display: flex;
  justify-content: center;
  margin-top: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 0.75rem 0.5rem;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .category-list {
  align-items: center;
  display: block;
  justify-content: center;
  margin-top: 0;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .category-list:not(:first-of-type) {
  margin-top: 1.5rem;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .category-list:not(:first-of-type) .mobile-find-link {
  border-left: 1px solid #cbced1;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .cta-container .current-network {
  display: none;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .search-doctors .profile-image {
  height: 62px;
  min-width: 62px;
  width: 62px;
  flex: 0 0 62px;
  margin-right: 1.5rem;
  border: none;
  box-shadow: none;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .search-doctors li {
  align-items: center;
  display: flex;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .search-doctors svg {
  height: 100%;
  width: 100%;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .search-locations li {
  align-items: center;
  display: flex;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .search-locations svg {
  flex: 0 0 25px;
  height: 25px;
  margin-right: 1.5rem;
  fill: #2c373f;
  width: 25px;
}
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .emergency-disclaimer {
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 400;
  font-style: italic;
  color: red;
  margin-bottom: 1.25rem;
}
@media (max-width: 991.98px) {
  .bi-omnibar .emergency-disclaimer {
    padding-top: 1rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    margin-bottom: 0rem !important;
  }
}
@media (min-width: 576px) {
  .bi-omnibar .form-group input:not(:placeholder-shown) ~ label,
.bi-omnibar .form-label-group input:not(:placeholder-shown) ~ label {
    display: block;
  }
  .bi-omnibar .form-group > label,
.bi-omnibar .form-label-group > label {
    top: 0px;
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .bi-omnibar {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .bi-omnibar {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .bi-omnibar {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .bi-omnibar {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .bi-omnibar.with-page-hero {
    background-color: #fff;
  }
  .bi-omnibar.with-page-hero .bi-omnibar-inner {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .bi-omnibar .heading-2 {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}
@media (min-width: 768px) {
  .bi-omnibar .bi-omnibar-search-typeahead .search-dropdown {
    left: 0;
    margin-top: 0.5rem;
    transform: none;
    width: calc(100vw - 80px);
  }
}
@media (min-width: 768px) {
  .bi-omnibar form {
    display: flex;
    flex-wrap: wrap;
  }
  .bi-omnibar form .filter-group {
    flex: 2 2 0;
    margin-bottom: 0;
    margin-right: 1rem;
  }
  .bi-omnibar form .filter-group:last-of-type {
    flex: 1 1 0;
    margin-right: 0;
  }
  .bi-omnibar form button[type=submit] {
    flex-basis: 100%;
    height: 3rem;
    margin-top: 1rem;
  }
}
@media (min-width: 768px) {
  .bi-omnibar .clear-btn {
    margin: 0.5rem 1rem 0.5rem;
    position: absolute;
    top: 0;
    right: 2px;
  }
}
@media (min-width: 768px) {
  .bi-omnibar .button-group {
    display: flex;
    gap: 16px 16px;
    flex-wrap: wrap;
  }
  .bi-omnibar .button-group a {
    font-size: 1rem;
    line-height: 1.125rem;
    padding: 0.5rem 0.5rem;
    min-width: 29%;
    height: 1.25rem;
    flex-basis: calc(33.3% - 16px);
    align-items: center;
    display: flex;
  }
}
@media (min-width: 992px) {
  .bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .dropdown-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    flex-direction: row;
    margin: 0;
    padding: 1.75rem 1.75rem 1.25rem;
  }
  .bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .dropdown-container .dropdown-primary {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
    border-right: 1px solid #3d5d6b;
    order: 1;
    padding: 0 2rem 0 0;
  }
  .bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
    display: block;
    order: 2;
    padding: 0 0 0 2rem;
  }
  .bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .heading-5,
.bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary ul {
    display: block;
  }
  .bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .view-all-link {
    display: inline-block;
  }
  .bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .view-all-link.mobile-find-link {
    display: none;
  }
  .bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .category-list {
    display: block;
  }
  .bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .category-list:not(:first-of-type) {
    margin-top: 1.5rem;
  }
  .bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .dropdown-container .dropdown-secondary .category-list:not(:first-of-type) .view-all-link {
    border-left: none;
  }
  .bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .cta-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    background-color: #f2f9ff;
    margin: 0;
    padding: 0 1.75rem;
  }
  .bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .cta-container .primary-view-all {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
    justify-content: flex-start;
  }
  .bi-omnibar .bi-omnibar-search-typeahead .search-dropdown .cta-container .current-network {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
    font-size: 0.875rem;
    line-height: 1.1428571429;
    color: #3d5d6b;
    display: flex;
    align-items: center;
    padding-left: 2rem;
  }
}
@media (min-width: 1200px) {
  .bi-omnibar .heading-2 {
    font-size: 1.75rem;
    line-height: 1.2142857143;
  }
  .bi-omnibar .bi-omnibar-search-typeahead .search-dropdown {
    left: 0;
    transform: none;
    width: calc(100vw - 100px);
  }
  .bi-omnibar form .filter-group:last-of-type {
    margin-right: 1rem;
  }
  .bi-omnibar form button[type=submit] {
    flex-basis: auto;
    margin-top: 0;
  }
}

.bi-scheduling-detail {
  display: flex;
  flex-direction: column-reverse;
  padding-top: 2rem;
  margin-top: 0.5rem;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 3rem;
}
.bi-scheduling-detail.success .bi-scheduling-card {
  display: none;
}
.bi-scheduling-detail.success .three-step-tracker {
  border-radius: 6px;
}
.bi-scheduling-detail .bi-scheduling-inner {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .bi-scheduling-detail .bi-scheduling-inner {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .bi-scheduling-detail .bi-scheduling-inner {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .bi-scheduling-detail .bi-scheduling-inner {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .bi-scheduling-detail .bi-scheduling-inner {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.bi-scheduling-detail .bi-scheduling-card {
  border-radius: 0 0 6px 6px;
}
.bi-scheduling-detail .card-header,
.bi-scheduling-detail .card-footer {
  background-color: #fff;
  border: none;
}
.bi-scheduling-detail .card-header {
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
}
.bi-scheduling-detail .card-footer {
  background-color: #e4e5e6;
  border-radius: 5px;
  padding: 0.5rem 1.5rem 1.5rem;
}
.bi-scheduling-detail .profile-image {
  margin: 0 1rem 1rem 0;
  object-fit: cover;
  object-position: top;
  font-family: "object-fit: cover; object-position: top;";
  background-color: #fff;
  height: 128px;
  min-width: 128px;
  overflow: hidden;
  position: relative;
  width: 128px;
}
.bi-scheduling-detail .doctor-details {
  padding-right: 1rem;
}
.bi-scheduling-detail .doctor-details span {
  font-weight: 600;
}
.bi-scheduling-detail .doctor-name {
  font-size: 1.25rem;
  line-height: 1.2;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.bi-scheduling-detail .location-address {
  font-size: 1rem;
  line-height: 1.125;
  margin-bottom: 1rem;
}
.bi-scheduling-detail .location-address:empty {
  display: none;
}
.bi-scheduling-detail .distance-and-location,
.bi-scheduling-detail .appointment-request-phone {
  font-size: 1rem;
  line-height: 1.125;
  color: #006D9D;
  font-weight: 600;
}
.bi-scheduling-detail .three-step-tracker {
  margin-bottom: 0;
  border: 1px solid #cbced1;
  border-radius: 6px 6px 0 0;
}
.bi-scheduling-detail .three-step-tracker .step-title,
.bi-scheduling-detail .three-step-tracker .step-detail {
  margin-left: 1.5rem;
}
.bi-scheduling-detail .three-step-tracker .step-title {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
}
.bi-scheduling-detail .three-step-tracker .step-detail {
  font-size: 1rem;
  line-height: 1.125;
  font-weight: 600;
}
.bi-scheduling-detail .three-step-tracker .step-one,
.bi-scheduling-detail .three-step-tracker .step-two,
.bi-scheduling-detail .three-step-tracker .step-three {
  color: #2c373f;
  display: flex;
  align-items: center;
  padding: 1.25rem;
}
.bi-scheduling-detail .three-step-tracker .step-one .icon,
.bi-scheduling-detail .three-step-tracker .step-two .icon,
.bi-scheduling-detail .three-step-tracker .step-three .icon {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #2c373f;
  border-radius: 9999px;
  stroke: #fff;
}
.bi-scheduling-detail .three-step-tracker .step-one.active,
.bi-scheduling-detail .three-step-tracker .step-two.active,
.bi-scheduling-detail .three-step-tracker .step-three.active {
  color: #006D9D;
}
.bi-scheduling-detail .three-step-tracker .step-one.active .icon,
.bi-scheduling-detail .three-step-tracker .step-two.active .icon,
.bi-scheduling-detail .three-step-tracker .step-three.active .icon {
  background-color: #006D9D;
}
.bi-scheduling-detail .three-step-tracker .step-one.next,
.bi-scheduling-detail .three-step-tracker .step-two.next,
.bi-scheduling-detail .three-step-tracker .step-three.next {
  color: #3d5d6b;
}
.bi-scheduling-detail .three-step-tracker .step-one.next .icon,
.bi-scheduling-detail .three-step-tracker .step-two.next .icon,
.bi-scheduling-detail .three-step-tracker .step-three.next .icon {
  background-color: #fff;
  border-width: 2px;
  border-color: #3d5d6b;
}
.bi-scheduling-detail .three-step-tracker .arrow {
  display: none;
}
.bi-scheduling-detail .specialty {
  font-size: 1rem;
  line-height: 1.25;
}
@media (min-width: 768px) {
  .bi-scheduling-detail {
    height: calc(100% - calc(
	(130px / 2) - 0.25rem
));
    justify-content: space-between;
  }
}
@media (min-width: 992px) {
  .bi-scheduling-detail .bi-scheduling-card {
    flex-direction: row;
  }
  .bi-scheduling-detail .profile-image {
    margin: 0 1rem 0 0;
  }
  .bi-scheduling-detail .card-header,
.bi-scheduling-detail .card-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 0%;
  }
  .bi-scheduling-detail .card-header {
    padding: 1rem 0.5rem 1rem 1rem;
  }
  .bi-scheduling-detail .card-footer {
    align-items: flex-start;
    padding: 1.5rem;
    justify-content: space-around;
    flex-direction: column;
  }
  .bi-scheduling-detail .card-footer .location-address {
    margin-bottom: 0;
  }
  .bi-scheduling-detail .three-step-tracker {
    display: flex;
    margin-bottom: 0;
  }
  .bi-scheduling-detail .three-step-tracker li {
    flex: 1 1 0%;
  }
  .bi-scheduling-detail .three-step-tracker .arrow {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1.25rem;
  }
  .bi-scheduling-detail .three-step-tracker .arrow svg {
    color: #cbced1;
  }
}
@media (min-width: 1200px) {
  .bi-scheduling-detail .bi-search-filters .filters-form .filter {
    flex: 0 0 calc(33.33% - 30px);
    margin-right: 40px;
  }
}

.bi-scheduling-success {
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 3rem;
}
.bi-scheduling-success .bi-scheduling-inner {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
}
@media (min-width: 576px) {
  .bi-scheduling-success .bi-scheduling-inner {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .bi-scheduling-success .bi-scheduling-inner {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .bi-scheduling-success .bi-scheduling-inner {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .bi-scheduling-success .bi-scheduling-inner {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.bi-scheduling-success .bi-scheduling-card {
  border-top: 1px solid #cbced1;
  border-radius: 0 0 6px 6px;
  margin-top: 2rem;
  padding-top: 2rem;
}
.bi-scheduling-success .card-header,
.bi-scheduling-success .card-footer {
  background-color: #fff;
  border: none;
}
.bi-scheduling-success .card-header {
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
}
.bi-scheduling-success .card-footer {
  background-color: #e4e5e6;
  border-radius: 5px;
  padding: 0.5rem 1.5rem 1.5rem;
}
.bi-scheduling-success .print-details {
  display: flex;
  flex-direction: column;
  border-top-width: 1px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  align-items: center;
}
.bi-scheduling-success .print-details a {
  font-weight: 700;
}
.bi-scheduling-success .profile-image {
  margin: 0 1rem 1rem 0;
  object-fit: cover;
  object-position: top;
  font-family: "object-fit: cover; object-position: top;";
  background-color: #fff;
  height: 128px;
  min-width: 128px;
  overflow: hidden;
  position: relative;
  width: 128px;
}
.bi-scheduling-success .appointment-info {
  font-size: 1.75rem;
  line-height: 1.2142857143;
  color: #2c373f;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.bi-scheduling-success .doctor-details {
  padding-right: 1rem;
}
.bi-scheduling-success .doctor-details span {
  font-weight: 600;
}
.bi-scheduling-success .doctor-name {
  font-size: 1.25rem;
  line-height: 1.2;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.bi-scheduling-success .location-address {
  font-size: 1rem;
  line-height: 1.125;
  margin-bottom: 1rem;
}
.bi-scheduling-success .location-address:empty {
  display: none;
}
.bi-scheduling-success .distance-and-location,
.bi-scheduling-success .appointment-request-phone {
  font-size: 1rem;
  line-height: 1.125;
  color: #006D9D;
  font-weight: 600;
}
.bi-scheduling-success .instructions {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f6;
  border-radius: 6px;
  margin-top: 1.75rem;
}
.bi-scheduling-success .instructions-title {
  font-size: 1.5rem;
  line-height: 1.25;
  color: #2c373f;
  font-weight: 600;
}
.bi-scheduling-success .instructions-details {
  font-size: 1rem;
  line-height: 1.5;
}
.bi-scheduling-success .appointment-details #demographics {
  padding: 2rem 0;
}
.bi-scheduling-success .appointment-details #demographics h2 {
  font-size: 2rem;
  line-height: 1.1875;
  font-weight: 700;
  color: #2c373f;
}
.bi-scheduling-success .appointment-details #demographics label {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
  color: #2c373f;
}
.bi-scheduling-success .specialty {
  font-size: 1rem;
  line-height: 1.25;
}
@media (min-width: 768px) {
  .bi-scheduling-success {
    height: calc(100% - calc(
	(130px / 2) - 0.25rem
));
    justify-content: space-between;
  }
  .bi-scheduling-success .print-details {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (min-width: 992px) {
  .bi-scheduling-success .heading-2 {
    padding-top: 3rem;
  }
  .bi-scheduling-success .bi-scheduling-card {
    flex-direction: row;
  }
  .bi-scheduling-success .profile-image {
    margin: 0 1rem 0 0;
  }
  .bi-scheduling-success .card-header,
.bi-scheduling-success .card-footer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: 1 1 0%;
  }
  .bi-scheduling-success .card-header {
    padding: 1rem 0.5rem 1rem 1rem;
  }
  .bi-scheduling-success .card-footer {
    align-items: flex-start;
    padding: 1.5rem;
    justify-content: space-around;
    flex-direction: column;
  }
  .bi-scheduling-success .card-footer .location-address {
    margin-bottom: 0;
  }
}
.bi-search-results {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-left: auto !important;
  margin-right: auto !important;
}
.bi-search-results .bi-search-filters {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  /* Adjust margins for search container according to Figma specs. See https://mhhsmarketing.atlassian.net/browse/SIT-2083*/
  padding-right: 0px !important;
  padding-left: 0px !important;
}
@media (min-width: 576px) {
  .bi-search-results .bi-search-filters {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .bi-search-results .bi-search-filters {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .bi-search-results .bi-search-filters {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .bi-search-results .bi-search-filters {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.bi-search-results .bi-search-filters .filter-btn span {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
}
.bi-search-results .bi-search-filters .filter-btn .icon {
  flex-shrink: 0;
  margin-left: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
}
.bi-search-results .bi-search-filters .filters-container {
  margin-bottom: 1rem;
  padding-top: 1.5rem;
}
.bi-search-results .bi-search-filters .filters-form {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.bi-search-results .bi-search-filters .filters-form .filter {
  margin-bottom: 1.5rem;
}
.bi-search-results .bi-search-filters .filters-form .filter .filter-label {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  font-weight: 600;
}
.bi-search-results .bi-search-filters .filters-form .filter .filter-field .two-fields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bi-search-results .bi-search-filters .filters-form .filter .filter-field .two-fields input, .bi-search-results .bi-search-filters .filters-form .filter .filter-field .two-fields select {
  flex: 0 0 calc(50% - 5px);
}
.bi-search-results .bi-search-filters .filters-form .filter .filter-field input {
  font-size: 1rem;
  line-height: 1.125;
  color: #3d5d6b;
  font-weight: 600;
}
.bi-search-results .bi-search-filters .filters-form .filter .filter-field select {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  padding: 0.5rem 1rem;
  border: 1px solid #cbced1;
  border-radius: 6px;
  width: 100%;
}
.bi-search-results .profile-image {
  display: block;
  margin-bottom: 1rem;
}
.bi-search-results .profile-image[href]:hover, .bi-search-results .profile-image[href]:focus {
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.24);
  text-decoration: none;
}
.bi-search-results .profile-image[href]:hover + .doctor-details .doctor-name, .bi-search-results .profile-image[href]:focus + .doctor-details .doctor-name {
  color: #006D9D;
  text-decoration: underline;
}
.bi-search-results .profile-image.has-hours {
  display: flex;
  flex-direction: column;
}
.bi-search-results .profile-image.has-hours .location-image {
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.bi-search-results .profile-image.has-hours .location-image img {
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%;";
  width: 100%;
}
.bi-search-results .profile-image.has-hours .location-image svg {
  background-color: #a2c3d6;
  position: absolute;
  height: auto;
  left: 50%;
  min-height: 100%;
  top: 56%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.bi-search-results .profile-image.has-hours .open-status {
  font-size: 1rem;
  line-height: 1.125;
  background-color: #fff;
  bottom: 0;
  color: #3d5d6b;
  font-weight: 700;
  flex: 0 0 34px;
  padding: 3px 1.125rem 0;
  text-align: center;
  width: 100%;
}
.bi-search-results .profile-image.has-hours .open-status.open {
  color: #128b40;
}
.bi-search-results .doctor-details {
  width: 100%;
  padding-right: 1rem;
  text-align: left;
}
.bi-search-results .more-locations {
  padding-top: 1rem;
}
.bi-search-results .doctor-name {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #006D9D;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.bi-search-results .doctor-name[href]:hover, .bi-search-results .doctor-name[href]:focus {
  color: #006D9D;
}
.bi-search-results .doctor-ratings {
  font-weight: 600;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
}
.bi-search-results .doctor-ratings .rating-stars {
  margin-right: 0.75rem;
  width: auto;
}
.bi-search-results .doctor-ratings .rating-count {
  font-size: 1rem;
  line-height: 1.125;
  color: #3d5d6b;
}
.bi-search-results .specialty {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  font-weight: 600;
  display: block;
  margin-bottom: 0.25rem;
}
.bi-search-results .specialty:last-child {
  margin-bottom: 0;
}
.bi-search-results .doctor-location {
  text-align: left;
  padding: 2rem 0 1rem;
}
.bi-search-results .doctor-location .appointment-type-label {
  padding: 0.125rem 0;
}
.bi-search-results .phone-number,
.bi-search-results .appointment-type-label,
.bi-search-results .location-name,
.bi-search-results .address,
.bi-search-results .appointment-time,
.bi-search-results .modal-open-btn {
  font-size: 0.875rem;
  line-height: 1.1428571429;
}
.bi-search-results .distance-and-location,
.bi-search-results .appointment-request-phone {
  font-size: 1rem;
  line-height: 1.125;
  font-weight: 600;
}
.bi-search-results .distance-and-location .in-miles::after {
  content: "|";
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.bi-search-results .distance-and-location .get-directions::before {
  content: none;
}
.bi-search-results .appointment-heading,
.bi-search-results .appointment-date {
  font-size: 0.75rem;
  line-height: 1.1666666667;
}
.bi-search-results .location-name {
  color: #006D9D;
  display: block;
  margin-bottom: 0.5rem;
}
.bi-search-results .location-name:only-child {
  margin-bottom: 0;
}
.bi-search-results .distance {
  color: #006D9D;
  font-weight: 600;
  padding-right: 0.5rem;
}
.bi-search-results .appointment-type-label {
  color: #2c373f;
}
.bi-search-results .card-footer {
  background-color: #e4e5e6;
  border-top: none;
  padding: 1rem 1.5rem;
}
.bi-search-results .card-footer .location-address {
  font-size: 1rem;
  line-height: 1.125;
  border-bottom: none;
  margin-bottom: 0;
}
.bi-search-results .card-footer .location-address:empty {
  display: none;
}
.bi-search-results .card-footer .appointment-type-label {
  display: none;
}
.bi-search-results .card-footer .appointment-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
}
.bi-search-results .card-footer .appointment-heading {
  color: #2c373f;
  font-weight: 700;
  margin-bottom: 0.75rem;
}
.bi-search-results .card-footer .appointment-date {
  color: #006D9D;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.bi-search-results .card-footer .appointment-time {
  margin-right: 1rem;
}
.bi-search-results .card-footer .appointment-request-btn {
  color: #fff;
  padding: 7px;
  width: 100%;
}
.bi-search-results .card-footer .appointment-request-btn + .appointment-request-phone {
  padding-top: 1rem;
}
.bi-search-results .card-footer .appointment-request-phone {
  justify-content: flex-start;
}
.bi-search-results .card-footer .appointment-request-phone svg {
  flex: 0 0 2rem;
  height: 2rem;
  margin-right: 1rem;
  width: 2rem;
}
.bi-search-results .card-footer .appointment-request-phone a {
  color: #006D9D;
  display: block;
}
.bi-search-results .card-footer .modal-open-btn {
  background: none;
  border: none;
  color: #006D9D;
  font-weight: 400;
  padding: 0 0.625rem 0 0;
  position: relative;
}
.bi-search-results .card-footer .modal-open-btn:after {
  border-bottom: 6px solid transparent;
  border-left: 6px solid #00487c;
  border-top: 6px solid transparent;
  clear: both;
  content: "";
  display: inline-block;
  height: 0;
  left: 100%;
  position: absolute;
  top: 1px;
  width: 0;
}
.bi-search-results .card-footer .modal-open-btn:hover, .bi-search-results .card-footer .modal-open-btn:focus {
  background: none;
  border: none;
  color: #006D9D;
  outline: 0;
  text-decoration: underline;
}
.bi-search-results .card-footer .legend {
  padding-top: 1rem;
}
.view-mode-map .bi-search-results {
  height: auto;
  justify-items: flex-start;
  flex-direction: row;
  margin-left: 0;
  margin-top: 0;
}
.view-mode-map .bi-search-results .card-header {
  align-items: center;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding: 0;
  text-align: left;
  width: auto;
}
.view-mode-map .bi-search-results .card-header .appointment-type-label {
  display: none;
}
.view-mode-map .bi-search-results .profile-image {
  margin: 0 1rem 0 0;
  border-radius: 0;
  border: none;
  min-width: 175px;
}
.view-mode-map .bi-search-results .profile-image.has-hours .location-image svg {
  top: 50%;
}
.view-mode-map .bi-search-results .doctor-details {
  padding: 1rem 0;
}
.view-mode-map .bi-search-results .doctor-location {
  padding: 1rem 0 0;
}
.view-mode-map .bi-search-results .card-footer {
  display: none;
  width: 0;
}
.bi-search-results .loc-provider-specialty {
  font-size: 1rem;
  line-height: 1.125;
  display: block;
  background-color: #f5f5f6;
  border-top: none;
}
.bi-search-results .loc-provider-specialty .loc-provider-specialty-content {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.bi-search-results .loc-provider-specialty .loc-provider-specialty-list {
  font-size: 1rem;
  line-height: 1.125;
  align-items: center;
  color: #006D9D;
  display: inline-block;
  justify-content: center;
  padding: 0 1rem;
  list-style: none;
}
.bi-search-results .loc-provider-specialty .loc-provider-specialty-list a {
  display: block;
}
.bi-search-results .card-header.compromise {
  text-align: center;
}
.bi-search-results .card-header.compromise > .affiliation {
  display: none;
}
.bi-search-results .card-header.compromise > .doctor-details > .affiliation {
  display: block;
}
.bi-search-results .card-header.compromise > .doctor-details > .affiliation .affiliation-logo {
  float: none;
  margin-left: auto;
  margin-right: auto;
}
.bi-search-results .card-header.compromise .profile-image {
  margin-left: auto;
  margin-right: auto;
}
.bi-search-results .card-header.compromise .doctor-ratings {
  justify-content: center;
}
@media (min-width: 768px) {
  .bi-search-results {
    height: calc(100% - calc(
	(130px / 2) - 0.25rem
));
    justify-content: space-between;
  }
  .bi-search-results .bi-search-filters .filters-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .bi-search-results .bi-search-filters .filters-form .filter {
    margin-bottom: 1.5rem;
    flex: 0 0 calc(50% - 10px);
  }
  .bi-search-results .bi-search-filters .filters-form.hidden {
    display: none;
  }
  .bi-search-results .bi-result-card {
    margin-left: calc(65px + 0.5rem);
  }
  .bi-search-results .card-header,
.bi-search-results .card-footer {
    display: flex;
    flex-direction: row;
  }
  .bi-search-results .card-header {
    align-items: center;
  }
  .bi-search-results .card-header.compromise {
    text-align: center;
  }
  .bi-search-results .card-header.compromise > .affiliation {
    display: block;
  }
  .bi-search-results .card-header.compromise .doctor-details {
    text-align: left;
  }
  .bi-search-results .card-header.compromise > .doctor-details > .affiliation {
    display: none;
  }
  .bi-search-results .card-header.compromise .profile-image {
    margin-right: 1rem;
  }
  .bi-search-results .card-header.compromise .doctor-ratings {
    justify-content: flex-start;
  }
  .bi-search-results .profile-image {
    margin-right: 1rem;
  }
  .view-mode-list .bi-search-results {
    height: auto;
    justify-items: flex-start;
    flex-direction: row;
    margin-left: calc(
	(130px / 2) - 0.25rem
);
    margin-top: 0;
  }
  .view-mode-list .bi-search-results .card-header,
.view-mode-list .bi-search-results .card-footer {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 50%;
  }
  .view-mode-list .bi-search-results .card-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-left: 0;
    text-align: left;
  }
  .view-mode-list .bi-search-results .card-header .appointment-type-label {
    display: none;
  }
  .view-mode-list .bi-search-results .doctor-ratings {
    justify-content: flex-start;
  }
  .view-mode-list .bi-search-results .profile-image {
    margin-top: 0;
    margin-left: calc((130px) / -2);
    margin-right: 1rem;
    margin-bottom: 0;
  }
  .view-mode-list .bi-search-results .doctor-location {
    padding-bottom: 0;
  }
  .view-mode-list .bi-search-results .card-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
  }
  .view-mode-list .bi-search-results .card-footer .appointment-type-label {
    font-size: 1rem;
    line-height: 1.125;
    display: block;
  }
  .view-mode-list .bi-search-results .card-footer .appointment-type-label:not(:last-of-type) {
    padding-bottom: 0.25rem;
  }
  .view-mode-list .bi-search-results .card-footer .location-address:empty {
    display: block;
    padding-bottom: 0;
  }
  .view-mode-list .bi-search-results .card-footer .appointment-request-container {
    padding: 0;
  }
  .view-mode-map .bi-search-results .profile-image {
    display: flex;
    flex: 0 0 135px;
    min-height: 155px;
    min-width: 175px;
    position: relative;
  }
  .view-mode-map .bi-search-results .profile-image .open-status {
    align-items: center;
    display: flex;
    flex: 0 0 47px;
    justify-content: center;
    padding-top: 0;
  }
  .view-mode-map .bi-search-results .doctor-details {
    padding: 1rem 1rem 1rem 0;
  }
  .view-mode-map .bi-search-results .doctor-details .doctor-name {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
  .bi-search-results .specialty {
    font-size: 1rem;
    line-height: 1.125;
  }
  .bi-search-results .affiliation {
    border-left: 1px solid #3d5d6b;
    padding-left: 1rem;
    order: 3;
  }
  .bi-search-results .distance-and-location,
.bi-search-results .phone-number,
.bi-search-results .appointment-type-label,
.bi-search-results .location-name,
.bi-search-results .address,
.bi-search-results .appointment-time,
.bi-search-results .modal-open-btn {
    font-size: 1rem;
    line-height: 1.125;
  }
  .bi-search-results .appointment-heading,
.bi-search-results .appointment-date {
    font-size: 0.875rem;
    line-height: 1.1428571429;
  }
  .bi-search-results .card-footer {
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .bi-search-results .card-footer .appointment-time {
    margin-right: 1.4rem;
  }
  .bi-search-results .card-footer .appointment-request-phone {
    padding: 0;
  }
  .bi-search-results .card-footer .location-address {
    margin-bottom: 0;
  }
  .bi-search-results .card-footer .modal-open-btn:after {
    top: 4px;
  }
  .bi-search-results .card-footer .legend {
    display: none;
  }
}
@media (min-width: 992px) {
  .bi-search-results .bi-search-filters .filter-btn {
    display: none;
  }
  .bi-search-results .bi-search-filters .filters-form {
    justify-content: flex-start;
  }
  .bi-search-results .bi-search-filters .filters-form .filter {
    flex: 0 0 calc(33.3333% - 20px);
    margin-right: 30px;
  }
  .bi-search-results .bi-search-filters .filters-form .filter:nth-child(3n) {
    margin-right: 0;
    margin-left: auto;
  }
  .bi-search-results .bi-result-card {
    flex-direction: row;
  }
  .bi-search-results .bi-result-card .card-header {
    flex: 0 0 55%;
    max-width: 55%;
  }
  .bi-search-results .bi-result-card .card-footer {
    flex: 0 0 45%;
    max-width: 45%;
  }
}
@media (min-width: 1200px) {
  .bi-search-results .bi-search-filters .filters-form .filter {
    flex: 0 0 calc(33.33% - 30px);
    margin-right: 40px;
  }
  .bi-search-results .card-footer .loc-provider-specialty-content {
    padding: 0 1.25rem;
  }
  .view-mode-map .bi-search-results .profile-image {
    flex: 0 0 175px;
    margin-right: 2rem;
    min-height: 205px;
    min-width: 232px;
  }
  .view-mode-map .bi-search-results .doctor-details {
    padding-right: 2rem;
  }
  .view-mode-map .bi-search-results .doctor-details .doctor-name {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
}

.broadcast .media-item {
  width: 100%;
  padding: 0;
  margin-bottom: 0.75rem;
  padding-top: 0.5rem;
}
.broadcast .media-item .heading-4 {
  display: none;
}
.broadcast .media-item .figure-text {
  display: none;
}
@media (min-width: 768px) {
  .broadcast .media-item {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 992px) {
  .broadcast .media-item {
    width: 50%;
    padding-top: 1rem;
  }
}

.footer {
  background: linear-gradient(to right, #2c373f 0%, #2c373f 50%, #333f48 50%, #333f48 100%);
  clear: both;
}
.footer .footer-inner {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
}
@media (min-width: 576px) {
  .footer .footer-inner {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .footer .footer-inner {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .footer .footer-inner {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .footer .footer-inner {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.footer .marketing {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  background-color: #2c373f;
  padding: 3.5rem 15px 3rem;
}
.footer .marketing .btn, .footer .marketing .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .footer .marketing .mktoButton {
  border-radius: 6rem;
  display: block;
  padding: 0.75rem 3.375rem;
}
.footer .marketing .btn.btn-primary, .footer .marketing .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .footer .marketing .mktoButton, .footer .marketing .btn:not([class*=btn-]) {
  color: #212529;
  background-color: #ffcd55;
  border-color: #ffcd55;
}
.footer .marketing .btn.btn-primary:hover, .footer .marketing .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .footer .marketing .mktoButton:hover, .footer .marketing .btn:not([class*=btn-]):hover {
  color: #fff;
  background-color: #2c373f;
  border-color: #ffcd55;
}
.footer .marketing .btn.btn-primary:focus, .footer .marketing .marketo-form .mktoForm .mktoButton:focus, .marketo-form .mktoForm .footer .marketing .mktoButton:focus, .footer .marketing .btn.btn-primary.focus, .footer .marketing .marketo-form .mktoForm .focus.mktoButton, .marketo-form .mktoForm .footer .marketing .focus.mktoButton, .footer .marketing .btn:not([class*=btn-]):focus, .footer .marketing .btn:not([class*=btn-]).focus {
  color: #fff;
  background-color: #2c373f;
  border-color: #ffcd55;
  box-shadow: 0 0 0 0.2rem rgba(222, 180, 78, 0.5);
}
.footer .marketing .btn.btn-primary.disabled, .footer .marketing .marketo-form .mktoForm .disabled.mktoButton, .marketo-form .mktoForm .footer .marketing .disabled.mktoButton, .footer .marketing .btn.btn-primary:disabled, .footer .marketing .marketo-form .mktoForm .mktoButton:disabled, .marketo-form .mktoForm .footer .marketing .mktoButton:disabled, .footer .marketing .btn:not([class*=btn-]).disabled, .footer .marketing .btn:not([class*=btn-]):disabled {
  color: #212529;
  background-color: #ffcd55;
  border-color: #ffcd55;
}
.footer .marketing .btn.btn-primary:not(:disabled):not(.disabled):active, .footer .marketing .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .footer .marketing .mktoButton:not(:disabled):not(.disabled):active, .footer .marketing .btn.btn-primary:not(:disabled):not(.disabled).active, .footer .marketing .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .footer .marketing .mktoButton:not(:disabled):not(.disabled).active, .show > .footer .marketing .btn.btn-primary.dropdown-toggle, .show > .footer .marketing .marketo-form .mktoForm .dropdown-toggle.mktoButton, .marketo-form .mktoForm .show > .footer .marketing .dropdown-toggle.mktoButton, .footer .marketing .btn:not([class*=btn-]):not(:disabled):not(.disabled):active, .footer .marketing .btn:not([class*=btn-]):not(:disabled):not(.disabled).active, .show > .footer .marketing .btn:not([class*=btn-]).dropdown-toggle {
  color: #212529;
  background-color: #ffbe22;
  border-color: #ffba15;
}
.footer .marketing .btn.btn-primary:not(:disabled):not(.disabled):active:focus, .footer .marketing .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .footer .marketing .mktoButton:not(:disabled):not(.disabled):active:focus, .footer .marketing .btn.btn-primary:not(:disabled):not(.disabled).active:focus, .footer .marketing .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .footer .marketing .mktoButton:not(:disabled):not(.disabled).active:focus, .show > .footer .marketing .btn.btn-primary.dropdown-toggle:focus, .show > .footer .marketing .marketo-form .mktoForm .dropdown-toggle.mktoButton:focus, .marketo-form .mktoForm .show > .footer .marketing .dropdown-toggle.mktoButton:focus, .footer .marketing .btn:not([class*=btn-]):not(:disabled):not(.disabled):active:focus, .footer .marketing .btn:not([class*=btn-]):not(:disabled):not(.disabled).active:focus, .show > .footer .marketing .btn:not([class*=btn-]).dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 180, 78, 0.5);
}
.footer .marketing .logo-container {
  display: inline-block;
}
.footer .marketing .profile-image {
  background-color: transparent;
  border: none;
  box-shadow: none;
  height: 230px;
  width: 230px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.25rem;
}
.footer .marketing .profile-image img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}
.footer .marketing .tagline {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  margin-bottom: 2rem;
}
.footer .marketing .form-container {
  margin-bottom: 2rem;
}
.footer .marketing .marketo-form {
  background-color: transparent;
  padding: 0;
  margin-bottom: 0;
}
.footer .marketing .marketo-form label {
  display: none;
}
.footer .marketing .marketo-form input {
  background-color: #f2f9ff;
  border: 1px solid #f2f9ff;
  border-radius: 0;
  height: 3.125rem;
  padding: 0.75rem 1rem;
}
.footer .marketing .marketo-form .mktoFormRow {
  margin-bottom: 1rem !important;
  margin-right: 0 !important;
  max-width: none;
}
.footer .marketing .marketo-form .mktoFormRow.hidden {
  margin-bottom: 0 !important;
}
.footer .marketing .marketo-form .mktoButton {
  padding: 0.4375rem 3.375rem;
  font-size: 1rem;
  line-height: 1.375;
  border-radius: 0.3125rem;
  margin-bottom: 0 !important;
  width: 100%;
}
.footer .marketing .marketo-form .thank-you-message {
  background-color: transparent;
  padding: 0;
}
.footer .marketing .marketo-form .thank-you-message .thank-you-bubble {
  display: none;
}
.footer .social-icons {
  text-align: center;
  margin-bottom: 2rem;
}
.footer .social-icons a {
  background: transparent;
  border: 0;
  padding: 0;
}
.footer .social-icons a:hover {
  text-decoration: none;
}
.footer .social-icons a:hover .icon {
  fill: #ffebbb;
  stroke: #ffebbb;
}
.footer .social-icons a + a {
  margin-left: 0.75rem;
}
.footer .social-icons .icon {
  fill: #ffcd55;
  height: 2rem;
  pointer-events: none;
  stroke: #ffcd55;
  width: 2rem;
}
.footer img {
  height: 73px;
  margin-bottom: 3rem;
  width: 234px;
}
.footer p {
  font-size: 1.5rem;
  line-height: 1.25;
  color: #fff;
  margin-bottom: 1.5rem;
}
.footer .social-share {
  margin-bottom: 2rem;
}
.footer .icon {
  fill: #a2c3d6;
  stroke: #a2c3d6;
}
.footer .form-group,
.footer .form-control,
.footer .share-story-modal form input[type=text],
.share-story-modal form .footer input[type=text],
.footer .share-story-modal form input[type=email],
.share-story-modal form .footer input[type=email],
.footer .share-story-modal form input[type=tel],
.share-story-modal form .footer input[type=tel],
.footer .share-story-modal form select,
.share-story-modal form .footer select,
.footer .share-story-modal form textarea,
.share-story-modal form .footer textarea,
.footer html .microsite-theme .marketo-form .mktoForm .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .footer .mktoEmailField,
.footer html .microsite-theme .marketo-form .mktoForm .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .footer .mktoTelField,
.footer html .microsite-theme .marketo-form .mktoForm .mktoTextField,
html .microsite-theme .marketo-form .mktoForm .footer .mktoTextField,
.footer html .microsite-theme .marketo-form .mktoForm select,
html .microsite-theme .marketo-form .mktoForm .footer select,
.footer html .microsite-theme .marketo-form .mktoForm textarea,
html .microsite-theme .marketo-form .mktoForm .footer textarea,
.footer html .moments-theme .marketo-form .mktoForm .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .footer .mktoEmailField,
.footer html .moments-theme .marketo-form .mktoForm .mktoTelField,
html .moments-theme .marketo-form .mktoForm .footer .mktoTelField,
.footer html .moments-theme .marketo-form .mktoForm .mktoTextField,
html .moments-theme .marketo-form .mktoForm .footer .mktoTextField,
.footer html .moments-theme .marketo-form .mktoForm select,
html .moments-theme .marketo-form .mktoForm .footer select,
.footer html .moments-theme .marketo-form .mktoForm textarea,
html .moments-theme .marketo-form .mktoForm .footer textarea,
.footer textarea,
.footer .bmi-calculator input[type=text],
.bmi-calculator .footer input[type=text],
.footer .bmi-calculator select,
.bmi-calculator .footer select,
.footer .marketo-form .mktoForm .mktoEmailField,
.marketo-form .mktoForm .footer .mktoEmailField,
.footer .marketo-form .mktoForm .mktoTelField,
.marketo-form .mktoForm .footer .mktoTelField,
.footer .marketo-form .mktoForm .mktoTextField,
.marketo-form .mktoForm .footer .mktoTextField,
.footer .marketo-form .mktoForm select,
.marketo-form .mktoForm .footer select,
.footer .marketo-form .mktoForm textarea,
.marketo-form .mktoForm .footer textarea {
  font-size: 1rem;
  line-height: 1.125;
  color: #3d5d6b;
}
.footer .form-group,
.footer .form-label-group {
  position: relative;
}
.footer .form-group > input,
.footer .form-group > label,
.footer .form-label-group > input,
.footer .form-label-group > label {
  background-color: #f2f9ff;
  border: 1px solid #f2f9ff;
  border-radius: 0;
  height: 3.125rem;
  padding: 0.75rem 1rem;
}
.footer .form-group > input,
.footer .form-label-group > input {
  padding-bottom: calc(0.75rem - 1px);
}
.footer .form-group > label,
.footer .form-label-group > label {
  position: absolute;
  top: 1px;
  left: 0;
  border: none;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  color: #3d5d6b;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  transition: all 0.1s ease-in-out;
}
.footer .form-group input::placeholder,
.footer .form-label-group input::placeholder,
.footer .form-group input::-ms-input-placeholder,
.footer .form-label-group input::-ms-input-placeholder {
  color: transparent;
}
.footer .links {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background-color: #333f48;
  text-align: center;
  padding: 2.5rem 15px 1rem;
}
.footer .link-lists,
.footer .link-lists-constant {
  font-size: 1rem;
  line-height: 1.25;
}
.footer .link-lists ol,
.footer .link-lists-constant ol {
  padding-left: 0;
  list-style: none;
}
.footer .link-lists ol li,
.footer .link-lists-constant ol li {
  padding-top: 1.5rem;
}
.footer .link-lists ol li:before,
.footer .link-lists-constant ol li:before {
  display: none;
}
.footer .link-lists ol li a,
.footer .link-lists-constant ol li a {
  font-size: 1rem;
  line-height: 1.125;
  /*eta, zeta*/
  color: #ffcd55;
  font-weight: 700;
}
.footer .link-lists ol li ol,
.footer .link-lists-constant ol li ol {
  display: none;
  padding-left: 0;
}
.footer .link-lists ol li ol li,
.footer .link-lists-constant ol li ol li {
  padding-top: 0.25rem;
}
.footer .link-lists ol li ol li:first-child,
.footer .link-lists-constant ol li ol li:first-child {
  padding-top: 1.25rem;
}
.footer .link-lists ol li ol li a,
.footer .link-lists-constant ol li ol li a {
  font-size: 1rem;
  line-height: 1.125;
  /*eta, zeta*/
  color: #fff;
  display: block;
  font-weight: 400;
}
.footer .link-lists-constant {
  margin-bottom: 4rem;
}
.footer .link-lists-constant ol li:first-child {
  padding-top: 0.75rem;
}
.footer-small {
  display: block;
}
.footer .copyright,
.footer .policy-terms {
  display: block;
}
.footer .copyright p,
.footer .policy-terms p {
  font-size: 0.75rem;
  line-height: 1.1666666667;
  color: #fff;
}
.footer .copyright ol,
.footer .policy-terms ol {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding-left: 0;
}
.footer .copyright ol li:first-child::before,
.footer .policy-terms ol li:first-child::before {
  display: none;
}
.footer .copyright ol li::before,
.footer .policy-terms ol li::before {
  color: #fff;
  content: "|";
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.footer .copyright ol li a,
.footer .policy-terms ol li a {
  font-size: 0.75rem;
  line-height: 1.1666666667;
  color: #fff;
  font-weight: 400;
}
@media (min-width: 768px) {
  .footer .marketing {
    margin-left: auto;
    margin-right: auto;
  }
  .footer .link-lists ol,
.footer .link-lists-constant ol {
    display: flex;
    flex-flow: row wrap;
  }
  .footer .link-lists ol li,
.footer .link-lists-constant ol li {
    flex-basis: calc(50% - 1.5rem);
    width: calc(50% - 1.5rem);
  }
  .footer .link-lists ol li:nth-child(odd), .footer .link-lists ol li:nth-child(even),
.footer .link-lists-constant ol li:nth-child(odd),
.footer .link-lists-constant ol li:nth-child(even) {
    padding-left: 0.5rem;
    padding-right: 1rem;
  }
  .footer .link-lists ol li ol,
.footer .link-lists-constant ol li ol {
    display: block;
    flex-basis: auto;
    width: auto;
  }
  .footer .link-lists ol li ol li,
.footer .link-lists-constant ol li ol li {
    display: block;
    flex-basis: auto;
    padding-left: 0;
    padding-right: 0;
    width: auto;
  }
  .footer .link-lists ol li ol li:nth-child(odd), .footer .link-lists ol li ol li:nth-child(even),
.footer .link-lists-constant ol li ol li:nth-child(odd),
.footer .link-lists-constant ol li ol li:nth-child(even) {
    padding-left: 0;
    padding-right: 0;
  }
  .footer .link-lists-constant {
    margin-bottom: 4rem;
  }
  .footer .link-lists-constant ol li:first-child {
    padding-top: 1.5rem;
  }
  .footer-small {
    display: flex;
    flex-flow: row-reverse wrap;
    float: right;
    flex-basis: calc(100% - 2.5rem);
    margin-left: 2.5rem;
    width: calc(100% - 2.5rem);
  }
  .footer-small .copyright {
    flex-basis: calc(65% - 0.5rem);
    width: calc(65% - 0.5rem);
    margin-right: 0.5rem;
    text-align: left;
  }
  .footer-small .copyright p {
    padding-top: 0.325rem;
  }
  .footer-small .policy-terms {
    flex-basis: calc(35% - 0.5rem);
    width: calc(35% - 0.5rem);
    margin-left: 0.5rem;
    text-align: right;
  }
  .footer-small .policy-terms ol {
    display: inline-flex;
  }
}
@media (min-width: 992px) {
  .footer .footer-inner {
    flex-direction: row;
  }
  .footer .marketing {
    flex-basis: calc(30% - 1rem);
    float: left;
    height: auto;
    width: calc(30% - 1rem);
    margin-left: 0;
    margin-right: 1rem;
    padding: 4.25rem 15px 1rem;
  }
  .footer .marketing .btn, .footer .marketing .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .footer .marketing .mktoButton {
    display: inline-block;
  }
  .footer .marketing .marketo-form .mktoButton {
    width: auto;
  }
  .footer .marketing .marketo-form .thank-you-message {
    align-items: flex-start;
  }
  .footer .links {
    float: right;
    display: flex;
    flex-basis: calc(70% - 1rem);
    width: calc(70% - 1rem);
    flex-flow: row wrap;
    text-align: left;
    padding: 3.5rem 15px 1rem;
  }
  .footer .link-lists {
    flex-basis: calc(70% - 2rem);
    float: left;
    height: auto;
    margin-left: 2rem;
    margin-bottom: 4rem;
    margin-right: 0;
    width: calc(70% - 2rem);
  }
  .footer .link-lists-constant {
    flex-basis: calc(30% - 1rem);
    float: right;
    height: auto;
    margin-left: 1rem;
    margin-right: 0;
    width: calc(30% - 1rem);
  }
  .footer .link-lists-constant ol li {
    flex-basis: auto;
    height: auto;
    width: 100%;
  }
  .footer .link-lists-constant ol li:nth-child(odd), .footer .link-lists-constant ol li:nth-child(even) {
    padding-left: 0;
    padding-right: 0;
  }
}

.healthplan-theme .smaller-text {
  font-size: 1rem;
  line-height: 1.125;
}
.healthplan-theme .link-highlight {
  background-color: #dbedd3;
  color: #006D9D;
  text-decoration: underline;
}
.healthplan-theme .link-highlight:hover, .healthplan-theme .link-highlight:focus {
  background-color: rgba(219, 237, 211, 0.5);
  color: #a2c3d6;
}
.healthplan-theme .callout-card {
  background-color: #f2f9ff;
  background-clip: padding-box;
  border: 0.5px solid rgba(112, 112, 112, 0.38);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  color: #006D9D;
  margin-left: auto;
  max-width: 280px;
  padding: 3rem 1.5rem !important;
}
.healthplan-theme .callout-card .heading-4 {
  margin-bottom: 1.5rem;
}
.healthplan-theme .callout-card p {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
.healthplan-theme .callout-card p:last-child {
  margin-bottom: 0;
}
.healthplan-theme .hero-primary {
  margin-bottom: 1rem;
}
.healthplan-theme .hero-secondary {
  margin-bottom: 1rem;
}
.healthplan-theme .insurance-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 2rem 1.75rem;
  border-radius: 5px;
  background-color: #f5f5f6;
}
.healthplan-theme .insurance-card .insurance-columns .column:not(:first-of-type) {
  margin-top: 3rem;
}
.healthplan-theme .insurance-card .heading-4 {
  margin-bottom: 0.75rem;
}
.healthplan-theme .insurance-card .logo-img {
  height: 36px;
}
.healthplan-theme .insurance-card .logo-img img {
  height: 100%;
}
.healthplan-theme .insurance-card .img-wrapper {
  max-width: 400px;
}
.healthplan-theme .insurance-card .insurance-card-img {
  margin-bottom: 1.5rem;
  margin-left: -4%;
  width: 108%;
}
.healthplan-theme .insurance-card p {
  color: #707070;
  margin-bottom: 1.25rem;
  margin-top: 0.75rem;
}
.healthplan-theme .insurance-card a {
  font-size: 1rem;
  line-height: 1;
  font-weight: 600;
  margin-top: auto;
}
.healthplan-theme .insurance-card a.ext-img {
  margin-top: 3rem;
}
.healthplan-theme .insurance-card a[target=_blank] {
  position: relative;
  display: inline-block;
}
.healthplan-theme .insurance-card a[target=_blank]:after {
  background-image: url(/assets/images/external-black.png);
  background-size: cover;
  background-position: 50% 50%;
  bottom: -3px;
  display: inline-block;
  content: "";
  height: 16px;
  position: relative;
  width: 28px;
  margin-right: -28px;
}
.healthplan-theme .available-providers {
  display: block;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.healthplan-theme .available-providers .heading-5 {
  margin-bottom: 0.825rem;
  padding-top: 1.5rem;
}
.healthplan-theme .locations-and-appointments .provider-list .provider-item {
  padding: 1.5rem 0.5rem;
}
@media (min-width: 768px) {
  .healthplan-theme .two-column p {
    margin-bottom: 1.5rem;
  }
  .healthplan-theme .hero-primary {
    margin-bottom: 1.5rem;
  }
  .healthplan-theme .hero-secondary {
    margin-bottom: 2rem;
  }
  .healthplan-theme .insurance-card .insurance-columns {
    display: flex;
    height: 100%;
    justify-content: space-between;
  }
  .healthplan-theme .insurance-card .insurance-columns .column {
    display: flex;
    flex: 0 1 calc(50% - 1.75rem - 1px - 15px);
    flex-direction: column;
  }
  .healthplan-theme .insurance-card .insurance-columns .column:not(:first-of-type) {
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  .healthplan-theme .search-dropdown {
    width: 65%;
  }
  .healthplan-theme .search-dropdown .dropdown-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    background-color: #2c373f;
    flex-direction: row;
    margin: 0;
    padding: 1.75rem 1.75rem 1.25rem;
  }
  .healthplan-theme .search-dropdown .dropdown-container .dropdown-primary {
    flex: 0 0 100%;
    max-width: 100%;
    border-right: none;
    order: 1;
    padding: 0 2rem 0 0;
  }
}
@media (min-width: 1200px) {
  .healthplan-theme .hero-primary.dark-bg .hero-content-container .hero-content {
    height: 60%;
  }
}

.healthplan-theme .global-navigation.navbar-fixed {
  height: 117px;
}
.healthplan-theme .global-navigation.navbar-fixed.no-transition .main-nav .navbar-brand {
  transition: none;
}
.healthplan-theme .global-navigation.navbar-fixed.no-transition .main-nav .main-nav-links {
  transition: none;
}
.healthplan-theme .global-navigation.navbar-fixed.no-transition .main-nav .fixed-search-btn {
  animation: none;
}
.healthplan-theme .global-navigation.navbar-fixed .hamburger-nav {
  height: calc(100% - 60px);
  top: 60px;
}
.healthplan-theme .global-navigation.navbar-fixed .hamburger-nav .primary-nav .nav-links .sub-nav-header button {
  color: #1f1c17;
}
.healthplan-theme .global-navigation.navbar-fixed .hamburger-nav .secondary-nav .nav-links li a {
  color: #1f1c17;
}
.healthplan-theme .global-navigation.navbar-fixed .hamburger-nav .icon-arrow-right,
.healthplan-theme .global-navigation.navbar-fixed .hamburger-nav .icon-arrow-left {
  fill: #1f1c17;
}
.healthplan-theme .global-navigation.navbar-fixed .hamburger-nav .image-links .image-cta .text-overlay {
  background-color: #1f1c17;
}
.healthplan-theme .global-navigation.navbar-fixed .hamburger-nav .image-links .image-cta .text-overlay .icon-arrow-right {
  fill: #1f1c17;
}
.healthplan-theme .global-navigation.navbar-fixed .main-nav {
  height: 60px;
}
.healthplan-theme .global-navigation.navbar-fixed .main-nav .navbar-brand {
  height: 47px;
  margin: 0.5rem 0;
  width: 175px;
}
.healthplan-theme .global-navigation.navbar-fixed .main-nav .main-nav-links {
  padding-right: 50px;
  transition: padding 0.1s ease-in-out;
}
.healthplan-theme .global-navigation.navbar-fixed .main-nav .main-nav-links > li > a {
  padding-bottom: 1rem;
}
.healthplan-theme .global-navigation.navbar-fixed .main-nav .fixed-search-btn {
  background-color: transparent;
  position: absolute;
  right: 15px;
  top: 0;
}
.healthplan-theme .global-navigation.navbar-fixed .main-nav .fixed-search-btn .icon-search {
  stroke: #000;
  stroke-width: 1px;
}
.healthplan-theme .global-navigation.navbar-fixed .main-nav-links {
  padding-right: 0;
}
.healthplan-theme .global-navigation.hamburger-nav-open .icon-menu-close-alt {
  display: block;
}
.healthplan-theme .global-navigation.hamburger-nav-open .icon-menu-open-alt {
  display: none;
}
.healthplan-theme .global-navigation .icon-menu-close-alt {
  display: none;
}
.healthplan-theme .global-navigation .global-search-typeahead {
  background-color: #006D9D;
}
.healthplan-theme .global-navigation .hamburger-nav {
  height: calc(100% - 72px);
  top: 72px;
}
.healthplan-theme .global-navigation .hamburger-nav .nav-links li {
  font-size: 1rem;
}
.healthplan-theme .global-navigation .hamburger-nav .secondary-nav li a:hover {
  text-decoration: underline;
}
.healthplan-theme .global-navigation .main-nav {
  height: auto;
}
.healthplan-theme .global-navigation .navbar-brand {
  height: 47px;
  margin: 0.75rem 0;
  padding: 1px 0;
}
.healthplan-theme .global-navigation .main-nav-links {
  padding: 0;
  justify-content: space-around;
}
.healthplan-theme .global-navigation .main-nav-links > li > a {
  font-size: 1rem;
  line-height: 1.125;
  padding-bottom: 1rem;
}
.healthplan-theme .global-navigation .main-nav-links .nav-item-secondary a {
  color: #000;
}
@media (min-width: 768px) {
  .healthplan-theme .global-navigation .navbar-brand {
    height: 63px;
  }
  .healthplan-theme .global-navigation .hamburger-nav {
    height: calc(100% - 87px);
    top: 87px;
  }
  .healthplan-theme .global-navigation .utility-nav-container {
    position: relative;
    justify-content: flex-end;
    background-color: #fff;
  }
  .healthplan-theme .global-navigation .utility-nav-container .utility-nav-links {
    padding: 0 1rem;
  }
  .healthplan-theme .global-navigation .utility-nav-container .utility-nav-links li {
    border-right-color: #fff;
  }
  .healthplan-theme .global-navigation .utility-nav-container .utility-nav-links li:first-of-type {
    padding-left: 1rem;
  }
  .healthplan-theme .global-navigation .utility-nav-container .utility-nav-links li a {
    font-size: 0.875rem;
    line-height: 1;
    color: #2c373f;
    display: inline-block;
    font-weight: 600;
    padding: 0.5rem 1rem;
  }
  .healthplan-theme .global-navigation .utility-nav-container .global-search-trigger {
    font-size: 0.875rem;
    line-height: 1;
    height: auto;
    padding: 0.5rem 1.5rem;
  }
  .healthplan-theme .global-navigation .utility-nav-container .global-search-trigger .icon {
    stroke: #2c373f;
  }
}
@media (min-width: 768px) {
  .healthplan-theme .global-navigation .main-nav .main-nav-links > li > a.active {
    border-bottom-color: #ffcd55;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links > li a[target=_blank] {
    padding-right: 1.5rem;
    position: relative;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links > li a[target=_blank]:after {
    background-image: url(/assets/images/external-black.png);
    background-size: cover;
    bottom: 10px;
    content: "";
    height: 28px;
    position: absolute;
    width: 28px;
    right: -2px;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links > li.has-sub-nav > .sub-nav {
    display: none;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active.sub-nav-opening > .sub-nav {
    animation: sub-nav-slide-down 0.4s;
    animation-timing-function: ease-in-out;
    z-index: -1;
  }
  @keyframes sub-nav-slide-down {
    from {
      transform: translate(-50%, calc(-100% - 8px));
    }
    to {
      transform: translate(-50%, 0);
    }
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active.sub-nav-opening > .sub-nav .sub-nav-container {
    box-shadow: none;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active.sub-nav-closing > a .icon-arrow-down {
    transform: rotate(0deg);
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active.sub-nav-closing > .sub-nav {
    animation: sub-nav-slide-up 0.4s;
    animation-timing-function: ease-in-out;
    transform: translate(-50%, calc(-100% - 8px));
    z-index: -1;
  }
  @keyframes sub-nav-slide-up {
    from {
      transform: translate(-50%, 0);
    }
    to {
      transform: translate(-50%, calc(-100% - 24px));
    }
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active.sub-nav-closing > .sub-nav .sub-nav-container {
    box-shadow: none;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active > a .icon-arrow-down {
    transform: rotate(180deg);
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active > .sub-nav {
    background: #2c373f;
    border: 1px solid #2c373f;
    border-top: 0;
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.12);
    display: block;
    padding: 0;
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    left: 50%;
    width: 32%;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active > .sub-nav .sub-nav {
    margin-left: 2rem;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active .nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links > li.has-sub-nav.sub-nav-active .nav-links li {
    padding: 6px 0;
    text-align: left;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav {
    text-align: left;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav ul {
    list-style-type: none;
    padding: 0;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav ul li {
    font-size: 1rem;
    line-height: 1.125;
    margin-bottom: 0.75rem;
    text-align: left;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav ul li a {
    font-size: 1rem;
    line-height: 1.125;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav ul li a:focus {
    outline: 0;
    text-decoration: underline;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .heading-6 {
    margin-bottom: 0.75rem;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .sub-nav-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    background-color: #2c373f;
    box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.06);
    margin: 0;
    padding: 1.75rem 1.75rem 1.25rem;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .sub-nav-primary {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
    border-right: none;
    padding-right: 0rem;
    flex: 100%;
    max-width: 100%;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .sub-nav-primary.no-border {
    border-right: none;
    padding-right: 0;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .sub-nav-secondary {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    padding: 0 1rem 0 3rem;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .featured-items {
    margin-bottom: 2rem;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .featured-items:first-child {
    margin-bottom: 2.5rem;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .featured-links {
    columns: 1;
    column-gap: 1rem;
    line-height: 0;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .featured-links > li > a.active {
    border-bottom-color: #ffcd55;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .featured-links > li a[target=_blank] {
    padding-right: 1.5rem;
    position: relative;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .featured-links > li a[target=_blank]:after {
    background-image: url(/assets/images/external-white.png);
    background-size: cover;
    bottom: -5px;
    content: "";
    height: 28px;
    position: absolute;
    width: 28px;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .quick-links-header {
    align-items: center;
    display: flex;
    margin-bottom: 0.75rem;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .quick-links-header .heading-6 {
    margin-bottom: 0;
    margin-right: 0.75rem;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .quick-links-header a {
    font-size: 0.875rem;
    line-height: 1.1428571429;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .quick-links-header a:focus {
    outline: 0;
    text-decoration: underline;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .quick-links-container {
    display: flex;
    margin-bottom: 1rem;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .btn-icon {
    background-color: #fff;
    border-color: #fff;
    color: #a2c3d6;
    margin-left: 1.5rem;
    padding-left: 1.75rem;
    padding-right: 1rem;
    position: relative;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .btn-icon:not(:last-of-type) {
    margin-right: 1rem;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .btn-icon:hover {
    background-color: #f5f5f6;
    border-color: #f5f5f6;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .btn-icon:focus {
    box-shadow: 0 0 0 0.2rem rgba(162, 195, 214, 0.5);
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .btn-icon picture,
.healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .btn-icon svg {
    align-items: center;
    display: flex;
    height: 2rem;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 3rem;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .btn-icon picture img,
.healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .btn-icon svg img {
    width: 100%;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .quick-links .quick-links-note {
    font-size: 0.75rem;
    line-height: 1.1666666667;
    color: #3d5d6b;
    font-style: italic;
    margin-bottom: 0;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .section-list li {
    margin-bottom: 0.5rem;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .section-list a {
    font-size: 1rem;
    line-height: 1.125;
    color: #fff;
    background-color: #a2c3d6;
    display: block;
    font-weight: 600;
    padding: 1rem;
    width: 100%;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .sub-nav-categories {
    display: flex;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .sub-nav-categories .sub-nav-category {
    flex: 1;
    margin-right: 3rem;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .browse-all-link {
    font-size: 1rem;
    line-height: 1.125;
    align-items: center;
    display: flex;
    font-weight: 600;
    height: 50px;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  .healthplan-theme .global-navigation .main-nav .main-nav-links .sub-nav .browse-all-link:focus {
    outline: 0;
    text-decoration: underline;
  }
}
@media (min-width: 1200px) {
  .healthplan-theme .global-navigation .main-nav-links {
    padding: 0 10px 0 50px;
    justify-content: space-between;
  }
  .healthplan-theme .global-navigation .main-nav-links > li > a {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
  .healthplan-theme .global-navigation .utility-nav-container .utility-nav-links li a {
    font-size: 0.875rem;
    line-height: 1.1428571429;
  }
  .healthplan-theme .global-navigation .utility-nav-container .global-search-trigger {
    font-size: 1rem;
    line-height: 1;
  }
}

.footer-healthplan a[target=_blank] {
  padding-right: 1.5rem;
  position: relative;
}
.footer-healthplan a[target=_blank]:after {
  background-image: url(/assets/images/external-white.png);
  background-size: cover;
  bottom: -3px;
  content: "";
  height: 28px;
  position: absolute;
  width: 28px;
}
.footer-healthplan .footer-wrapper {
  background-color: #2c373f;
  padding: 2.5rem 0 2.5rem;
}
.footer-healthplan .footer-content {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .footer-healthplan .footer-content {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .footer-healthplan .footer-content {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .footer-healthplan .footer-content {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .footer-healthplan .footer-content {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.footer-healthplan .marketing {
  text-align: left;
}
.footer-healthplan .mh-logo-full {
  display: flex;
  flex-flow: row;
  margin-bottom: 2.5rem;
  justify-content: center;
}
.footer-healthplan .mh-logo-full .logo-wrapper,
.footer-healthplan .mh-logo-full img {
  width: 11.75rem;
}
.footer-healthplan .mh-logo-full img {
  border-radius: 0;
  border-right: solid 1px #ffcd55;
  padding-right: 1rem;
}
.footer-healthplan .mh-logo-full .title-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0 0 0 1rem;
}
.footer-healthplan .mh-logo-full .title-list li {
  font-size: 0.75rem;
  line-height: 1.3333333333;
  color: #fff;
  display: inline-block;
}
.footer-healthplan .link-list {
  list-style: none;
  margin: 0;
  padding: 0.625rem 0 0;
  text-align: center;
}
.footer-healthplan .link-list ul {
  list-style: none;
  margin: 0.75rem 0;
  padding: 0;
}
.footer-healthplan .link-list li {
  padding-bottom: 0.75rem;
}
.footer-healthplan .link-list a {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #ffcd55;
  display: inline-block;
  max-width: 245px;
}
.footer-healthplan .link-list > li {
  padding-bottom: 1.25rem;
}
.footer-healthplan .link-list > li > a {
  font-weight: 600;
}
.footer-healthplan .link-list > li > ul li a {
  color: #fff;
  font-weight: 400;
}
.footer-healthplan .visit-cta {
  margin-bottom: 3rem;
}
.footer-healthplan .visit-cta a {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #fff;
  font-weight: 700;
}
.footer-healthplan .visit-cta .logo-wrapper {
  margin: 2rem auto 1rem;
  width: 155px;
}
.footer-healthplan .visit-cta .mh-logo {
  width: 155px;
}
.footer-healthplan .footer-primary .disclaimer,
.footer-healthplan .footer-secondary .disclaimer {
  color: #fff;
  margin-bottom: 2rem;
}
.footer-healthplan .footer-primary .disclaimer p,
.footer-healthplan .footer-secondary .disclaimer p {
  font-size: 1rem;
  line-height: 1.125;
}
.footer-healthplan .footer-primary .disclaimer {
  display: none;
}
.footer-healthplan .footer-secondary {
  text-align: center;
}
.footer-healthplan .copyright {
  font-size: 1rem;
  line-height: 1.125;
  color: #fff;
}
.footer-healthplan .copyright span:first-child {
  padding-right: 1rem;
}
.footer-healthplan .legal-links {
  display: flex;
  list-style: none;
  padding-left: 0;
  flex-flow: wrap;
  margin: auto;
  max-width: 245px;
  justify-content: center;
}
.footer-healthplan .legal-links li:after {
  content: "";
  border-right: 1px solid #fff;
  padding-left: 0.825rem;
  margin-right: 0.825rem;
}
.footer-healthplan .legal-links li:nth-of-type(even):after, .footer-healthplan .legal-links li:last-child:after {
  display: none;
}
.footer-healthplan .legal-links li a {
  font-size: 1rem;
  line-height: 1.125;
  color: #fff;
}
@media (min-width: 576px) {
  .footer-healthplan .link-list a {
    max-width: none;
  }
  .footer-healthplan .legal-links {
    max-width: none;
  }
  .footer-healthplan .legal-links li:nth-of-type(even):after {
    display: inline;
  }
  .footer-healthplan .legal-links li:last-child:after {
    display: none;
  }
}
@media (min-width: 768px) {
  .footer-healthplan .footer-wrapper {
    padding: 2.75rem 0;
  }
  .footer-healthplan .footer-primary {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1.75rem;
  }
  .footer-healthplan .footer-primary .disclaimer {
    display: block;
    flex: 0 0 100%;
    padding-top: 2rem;
    margin-bottom: 0.5rem;
  }
  .footer-healthplan .footer-primary .disclaimer p {
    font-size: 0.875rem;
    line-height: 1.2857142857;
    max-width: 500px;
  }
  .footer-healthplan .mh-logo-full {
    flex-flow: row wrap;
    padding-right: 2rem;
    width: 51%;
    justify-content: flex-start;
  }
  .footer-healthplan .mh-logo-full .logo-wrapper,
.footer-healthplan .mh-logo-full img {
    width: 8.5rem;
  }
  .footer-healthplan .mh-logo-full .title-list li {
    font-size: 0.625rem;
    line-height: 1.2;
  }
  .footer-healthplan .link-list {
    margin-top: 0;
    padding-top: 0;
    text-align: left;
  }
  .footer-healthplan .link-list:not(:last-child) {
    padding-right: 1.5rem;
    max-width: 35%;
  }
  .footer-healthplan .link-list ul {
    margin: 1.25rem 0 0;
  }
  .footer-healthplan .link-list ul a {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
  .footer-healthplan .link-list li {
    padding-bottom: 0.625rem;
  }
  .footer-healthplan .link-list > li {
    padding-bottom: 1.25rem;
  }
  .footer-healthplan .footer-secondary {
    align-items: flex-end;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    text-align: initial;
  }
  .footer-healthplan .footer-secondary > * {
    flex-basis: 50%;
  }
  .footer-healthplan .footer-secondary .disclaimer {
    display: none;
  }
  .footer-healthplan .copyright {
    font-size: 0.875rem;
    line-height: 1.1428571429;
    margin-bottom: 0;
  }
  .footer-healthplan .visit-cta {
    flex-basis: 100%;
    margin-bottom: 1.75rem;
    text-align: right;
  }
  .footer-healthplan .visit-cta a {
    font-size: 1.125rem;
    line-height: 1.1111111111;
  }
  .footer-healthplan .visit-cta .logo-wrapper {
    margin: 0 0.25rem 1rem auto;
    width: 115px;
  }
  .footer-healthplan .visit-cta .mh-logo {
    width: 115px;
  }
  .footer-healthplan .legal-links {
    justify-content: flex-end;
  }
}
@media (min-width: 992px) {
  .footer-healthplan .mh-logo-full:after {
    height: 50px;
    left: 215px;
    top: 11px;
  }
  .footer-healthplan .mh-logo-full .title-list {
    top: 9px;
    left: 232px;
  }
  .footer-healthplan .mh-logo-full .title-list li {
    font-size: 0.75rem;
    line-height: 1.3333333333;
  }
  .footer-healthplan .mh-logo-full .logo-wrapper,
.footer-healthplan .mh-logo-full img {
    width: 10rem;
  }
  .footer-healthplan .link-list:not(:last-child) {
    padding-right: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .footer-healthplan .link-list:not(:last-child) {
    padding-right: 2.5rem;
  }
  .footer-healthplan .link-list:last-child {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
  }
  .footer-healthplan .link-list:last-child li {
    flex-basis: 50%;
  }
  .footer-healthplan .footer-primary .disclaimer {
    max-width: 25.625rem;
  }
}

@media (min-width: 768px) {
  .healthplan-theme .view-mode-list .doctor-result .card-header {
    flex-grow: 1;
  }
}

.page-header-healthplan {
  background-color: rgba(0, 109, 157, 0.06);
  margin-bottom: 2rem;
}
.page-header-healthplan.white-bg {
  background-color: #fff;
}
.page-header-healthplan + .message .alert,
.page-header-healthplan + div + .message .alert {
  margin-bottom: 2rem;
  margin-top: -2rem;
}
.page-header-healthplan + .find-a-doctor,
.page-header-healthplan + .find-a-location {
  margin-top: -2rem;
}
.page-header-healthplan .page-header-inner {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 2rem;
  padding-bottom: 0.25rem;
}
@media (min-width: 576px) {
  .page-header-healthplan .page-header-inner {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .page-header-healthplan .page-header-inner {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .page-header-healthplan .page-header-inner {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .page-header-healthplan .page-header-inner {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.page-header-healthplan .page-header-top {
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-flow: column wrap;
}
.page-header-healthplan .page-header-top .social:nth-child(1) {
  order: 2;
}
.page-header-healthplan .page-header-top .heading-1:nth-child(2) {
  order: 1;
}
.page-header-healthplan .breadcrumb {
  background-color: transparent;
  margin: 0 0 1rem;
  padding: 0;
}
.page-header-healthplan .breadcrumb .breadcrumb-item {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #006D9D;
  font-weight: 400;
  display: none;
  padding-left: 0;
}
.page-header-healthplan .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  display: none;
}
.page-header-healthplan .breadcrumb .breadcrumb-item:last-of-type {
  display: block;
}
.page-header-healthplan .breadcrumb .breadcrumb-item:last-of-type .link-backward:before {
  display: none;
}
.page-header-healthplan .breadcrumb .icon-arrow-left {
  height: 1rem;
  position: relative;
  top: -2px;
  fill: #006D9D;
}
.page-header-healthplan .breadcrumb .icon-home {
  fill: #000;
}
.page-header-healthplan .breadcrumb .icon-home:hover, .page-header-healthplan .breadcrumb .icon-home:focus {
  fill: #006D9D;
}
.page-header-healthplan .btn-enroll-now {
  color: #2c373f;
  background-color: #ffcd55;
  border-color: #ffcd55;
  float: none;
  margin-left: 0;
  margin-bottom: 0.75rem;
  padding: 0.4375rem 2.375rem;
}
.page-header-healthplan .heading-1 {
  margin-bottom: 0.625rem;
}
.page-header-healthplan .heading-1:last-child {
  padding-bottom: 0.5rem;
}
.page-header-healthplan .page-header-bottom .subtitle {
  margin-bottom: 0;
  padding-bottom: 0.625rem;
  padding-right: 4rem;
}
.page-header-healthplan .page-header-bottom .link-forward {
  font-size: 1.25rem;
  line-height: 1.2;
  background-color: rgba(0, 109, 157, 0.06);
  display: block;
  margin-left: -15px;
  padding: 1rem 4rem 1rem 15px;
  width: calc(100% + 30px);
  font-weight: 700;
}
.page-header-healthplan .customer-service {
  padding-top: 0.5rem;
}
.page-header-healthplan .customer-service p {
  font-size: 1rem;
  line-height: 1.125;
  margin-bottom: 1rem;
}
.page-header-healthplan .customer-service p a {
  font-weight: 700;
}
@media (min-width: 576px) {
  .page-header-healthplan .page-header-top {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-flow: row wrap;
  }
  .page-header-healthplan .page-header-top nav {
    flex: 0 0 50%;
  }
  .page-header-healthplan .page-header-top .social:nth-child(1) {
    order: 2;
  }
  .page-header-healthplan .page-header-top .heading-1:nth-child(2) {
    flex: 0 0 60%;
    order: 1;
  }
  .page-header-healthplan .page-header-top .heading-1:nth-child(3) {
    flex: 0 0 100%;
  }
  .page-header-healthplan .btn-enroll-now {
    float: right;
    margin-left: 1.5rem;
  }
  .page-header-healthplan .customer-service {
    padding-top: 0.5rem;
  }
  .page-header-healthplan .customer-service p {
    font-size: 1rem;
    line-height: 1.125;
    margin-bottom: 0.3rem;
  }
}
@media (min-width: 992px) {
  .page-header-healthplan {
    margin-bottom: 3rem;
  }
  .page-header-healthplan + .message .alert,
.page-header-healthplan + div + .message .alert {
    margin-bottom: 3rem;
    margin-top: -3rem;
  }
  .page-header-healthplan + .find-a-doctor,
.page-header-healthplan + .find-a-location {
    margin-top: -3rem;
  }
  .page-header-healthplan + .find-a-location .search-page-wrapper-outer.view-mode-map .search-page-results {
    margin-top: 0;
  }
  .page-header-healthplan .page-header-inner {
    padding-bottom: 1.5rem;
    padding-top: 2rem;
  }
  .page-header-healthplan .breadcrumb {
    align-items: center;
    display: flex;
    margin-bottom: 0.75rem;
  }
  .page-header-healthplan .breadcrumb .breadcrumb-item {
    font-size: 1rem;
    line-height: 0.75;
    display: block;
    font-weight: 600;
    position: relative;
  }
  .page-header-healthplan .breadcrumb .breadcrumb-item + .breadcrumb-item {
    margin-left: 0.375rem;
    padding-left: 0.675rem;
  }
  .page-header-healthplan .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
    color: #006D9D;
    content: "/";
    display: inline-block;
    height: 95%;
    left: 0;
    padding-right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
  }
  .page-header-healthplan .breadcrumb .breadcrumb-item .link-backward:before {
    display: none;
  }
  .page-header-healthplan .breadcrumb .breadcrumb-item .icon-arrow-left {
    display: none;
  }
  .page-header-healthplan .breadcrumb .breadcrumb-item .icon {
    height: 0.875rem;
    width: 0.875rem;
  }
  .page-header-healthplan .breadcrumb .breadcrumb-item .icon.icon-home {
    position: relative;
    top: -1px;
    height: 0.775rem;
  }
  .page-header-healthplan .btn-enroll-now {
    padding: 0.4375rem 3.375rem;
  }
  .page-header-healthplan .social {
    /* display: block;
    margin-left: auto;
    margin-bottom: 0.75rem; */
  }
  .page-header-healthplan .social:only-child {
    /* margin-bottom: 0;
    position: absolute;
    top: 0.5rem;
    right: 0; */
  }
  .page-header-healthplan .heading-1 {
    margin-bottom: 1.25rem;
  }
  .page-header-healthplan .heading-1:last-child {
    margin-bottom: 0.5rem;
    padding-bottom: 0;
  }
  .page-header-healthplan .page-header-bottom {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
  }
  .page-header-healthplan .page-header-bottom .subtitle {
    padding-bottom: 0;
  }
  .page-header-healthplan .page-header-bottom .subtitle:not(:only-child) {
    padding-bottom: 0.75rem;
  }
  .page-header-healthplan .page-header-bottom .subtitle-container .link-forward {
    display: inline-block;
  }
  .page-header-healthplan .page-header-bottom .link-forward {
    font-size: 1rem;
    line-height: 1.125;
    background-color: transparent;
    display: block;
    margin-bottom: 3px;
    margin-left: 0;
    padding: 0 1.125rem 0 0;
    width: auto;
  }
}

.healthplan-theme .callout .heading-3 {
  text-align: center;
}

.social-share-healthplan {
  display: none;
  float: right;
}
.social-share-healthplan .social-label {
  font-size: 0.75rem;
  line-height: 1.1666666667;
  color: #006D9D;
  font-weight: 700;
  letter-spacing: 0.6px;
  margin-right: 0.5rem;
  text-transform: uppercase;
}
.social-share-healthplan button {
  display: flex;
  background: transparent;
  border: 0;
  padding: 0;
}
.social-share-healthplan button:hover .icon {
  fill: #2c373f;
  stroke: #2c373f;
}
.social-share-healthplan button + button {
  margin-left: 0.25rem;
}
.social-share-healthplan .icon {
  fill: #006D9D;
  height: 1.5rem;
  pointer-events: none;
  stroke: #006D9D;
  width: 1.5rem;
}
@media (min-width: 992px) {
  .social-share-healthplan {
    align-items: center;
    display: inline-flex;
    padding-top: 0.5rem;
  }
}

.healthplan-modal.fade .modal-dialog {
  transform: translateY(-15px);
}
.healthplan-modal.show .modal-dialog {
  transform: none;
}
.healthplan-modal .modal-scroll-container {
  max-height: 100vh;
  overflow: auto;
  padding: 15px 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.healthplan-modal .modal-container {
  align-items: flex-start;
  display: flex;
}
.healthplan-modal .modal-dialog {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  align-items: flex-start;
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
}
@media (min-width: 576px) {
  .healthplan-modal .modal-dialog {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .healthplan-modal .modal-dialog {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .healthplan-modal .modal-dialog {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .healthplan-modal .modal-dialog {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.healthplan-modal .modal-content {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 0;
  border-radius: 10px;
  border: none;
  margin: auto;
}
.healthplan-modal .modal-body {
  padding: 2rem 1.5rem 1rem;
}
.healthplan-modal .modal-body:only-child {
  padding: 3rem 1.5rem;
}
.healthplan-modal .modal-footer {
  background-color: rgba(0, 109, 157, 0.06);
  border: none;
  display: block;
  padding: 1.5rem;
}
.healthplan-modal .heading-2 {
  font-size: 2rem;
  line-height: 1.1875;
  color: #006D9D;
  font-weight: 700;
  margin-bottom: 3rem;
}
.healthplan-modal .heading-2 + p {
  margin-top: -2rem;
  margin-bottom: 1.5rem;
}
.healthplan-modal .link-forward {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-weight: 600;
}
.healthplan-modal .link-forward:hover, .healthplan-modal .link-forward:focus {
  color: #006D9D;
  outline: 0;
  text-decoration: underline;
}
.healthplan-modal .help-text {
  font-size: 1rem;
  line-height: 1.125;
  color: #707070;
  margin: 0.5rem 0 0;
}
.healthplan-modal .button-container .btn, .healthplan-modal .button-container .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .healthplan-modal .button-container .mktoButton {
  font-size: 1.25rem;
  line-height: 1.2;
  border-radius: 25px;
  min-height: 62px;
  padding: 1rem;
  width: 100%;
}
.healthplan-modal .button-container .btn.btn-primary, .healthplan-modal .button-container .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .healthplan-modal .button-container .mktoButton {
  color: #fff;
  background-color: #006D9D;
  border-color: #006D9D;
}
.healthplan-modal .button-container .btn.btn-primary:hover, .healthplan-modal .button-container .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .healthplan-modal .button-container .mktoButton:hover {
  color: #fff;
  background-color: #2c373f;
  border-color: #2c373f;
}
.healthplan-modal .button-container .btn.btn-primary:focus, .healthplan-modal .button-container .marketo-form .mktoForm .mktoButton:focus, .marketo-form .mktoForm .healthplan-modal .button-container .mktoButton:focus, .healthplan-modal .button-container .btn.btn-primary.focus, .healthplan-modal .button-container .marketo-form .mktoForm .focus.mktoButton, .marketo-form .mktoForm .healthplan-modal .button-container .focus.mktoButton {
  color: #fff;
  background-color: #2c373f;
  border-color: #2c373f;
  box-shadow: 0 0 0 0.2rem rgba(38, 131, 172, 0.5);
}
.healthplan-modal .button-container .btn.btn-primary.disabled, .healthplan-modal .button-container .marketo-form .mktoForm .disabled.mktoButton, .marketo-form .mktoForm .healthplan-modal .button-container .disabled.mktoButton, .healthplan-modal .button-container .btn.btn-primary:disabled, .healthplan-modal .button-container .marketo-form .mktoForm .mktoButton:disabled, .marketo-form .mktoForm .healthplan-modal .button-container .mktoButton:disabled {
  color: #fff;
  background-color: #006D9D;
  border-color: #006D9D;
}
.healthplan-modal .button-container .btn.btn-primary:not(:disabled):not(.disabled):active, .healthplan-modal .button-container .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .healthplan-modal .button-container .mktoButton:not(:disabled):not(.disabled):active, .healthplan-modal .button-container .btn.btn-primary:not(:disabled):not(.disabled).active, .healthplan-modal .button-container .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .healthplan-modal .button-container .mktoButton:not(:disabled):not(.disabled).active, .show > .healthplan-modal .button-container .btn.btn-primary.dropdown-toggle, .show > .healthplan-modal .button-container .marketo-form .mktoForm .dropdown-toggle.mktoButton, .marketo-form .mktoForm .show > .healthplan-modal .button-container .dropdown-toggle.mktoButton {
  color: #fff;
  background-color: #004a6a;
  border-color: #00415d;
}
.healthplan-modal .button-container .btn.btn-primary:not(:disabled):not(.disabled):active:focus, .healthplan-modal .button-container .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .healthplan-modal .button-container .mktoButton:not(:disabled):not(.disabled):active:focus, .healthplan-modal .button-container .btn.btn-primary:not(:disabled):not(.disabled).active:focus, .healthplan-modal .button-container .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .healthplan-modal .button-container .mktoButton:not(:disabled):not(.disabled).active:focus, .show > .healthplan-modal .button-container .btn.btn-primary.dropdown-toggle:focus, .show > .healthplan-modal .button-container .marketo-form .mktoForm .dropdown-toggle.mktoButton:focus, .marketo-form .mktoForm .show > .healthplan-modal .button-container .dropdown-toggle.mktoButton:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 131, 172, 0.5);
}
.healthplan-modal .button-container .btn.btn-secondary, .healthplan-modal .button-container .marketo-form .mktoForm .btn-secondary.mktoButton, .marketo-form .mktoForm .healthplan-modal .button-container .btn-secondary.mktoButton {
  color: #212529;
  background-color: #a2c3d6;
  border-color: #a2c3d6;
  color: #2c373f;
}
.healthplan-modal .button-container .btn.btn-secondary:hover, .healthplan-modal .button-container .marketo-form .mktoForm .btn-secondary.mktoButton:hover, .marketo-form .mktoForm .healthplan-modal .button-container .btn-secondary.mktoButton:hover {
  color: #212529;
  background-color: #a2c3d6;
  border-color: #a2c3d6;
}
.healthplan-modal .button-container .btn.btn-secondary:focus, .healthplan-modal .button-container .marketo-form .mktoForm .btn-secondary.mktoButton:focus, .marketo-form .mktoForm .healthplan-modal .button-container .btn-secondary.mktoButton:focus, .healthplan-modal .button-container .btn.btn-secondary.focus, .healthplan-modal .button-container .marketo-form .mktoForm .btn-secondary.focus.mktoButton, .marketo-form .mktoForm .healthplan-modal .button-container .btn-secondary.focus.mktoButton {
  color: #212529;
  background-color: #a2c3d6;
  border-color: #a2c3d6;
  box-shadow: 0 0 0 0.2rem rgba(143, 171, 188, 0.5);
}
.healthplan-modal .button-container .btn.btn-secondary.disabled, .healthplan-modal .button-container .marketo-form .mktoForm .btn-secondary.disabled.mktoButton, .marketo-form .mktoForm .healthplan-modal .button-container .btn-secondary.disabled.mktoButton, .healthplan-modal .button-container .btn.btn-secondary:disabled, .healthplan-modal .button-container .marketo-form .mktoForm .btn-secondary.mktoButton:disabled, .marketo-form .mktoForm .healthplan-modal .button-container .btn-secondary.mktoButton:disabled {
  color: #212529;
  background-color: #a2c3d6;
  border-color: #a2c3d6;
}
.healthplan-modal .button-container .btn.btn-secondary:not(:disabled):not(.disabled):active, .healthplan-modal .button-container .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .healthplan-modal .button-container .btn-secondary.mktoButton:not(:disabled):not(.disabled):active, .healthplan-modal .button-container .btn.btn-secondary:not(:disabled):not(.disabled).active, .healthplan-modal .button-container .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .healthplan-modal .button-container .btn-secondary.mktoButton:not(:disabled):not(.disabled).active, .show > .healthplan-modal .button-container .btn.btn-secondary.dropdown-toggle, .show > .healthplan-modal .button-container .marketo-form .mktoForm .btn-secondary.dropdown-toggle.mktoButton, .marketo-form .mktoForm .show > .healthplan-modal .button-container .btn-secondary.dropdown-toggle.mktoButton {
  color: #212529;
  background-color: #7facc6;
  border-color: #76a6c2;
}
.healthplan-modal .button-container .btn.btn-secondary:not(:disabled):not(.disabled):active:focus, .healthplan-modal .button-container .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .healthplan-modal .button-container .btn-secondary.mktoButton:not(:disabled):not(.disabled):active:focus, .healthplan-modal .button-container .btn.btn-secondary:not(:disabled):not(.disabled).active:focus, .healthplan-modal .button-container .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .healthplan-modal .button-container .btn-secondary.mktoButton:not(:disabled):not(.disabled).active:focus, .show > .healthplan-modal .button-container .btn.btn-secondary.dropdown-toggle:focus, .show > .healthplan-modal .button-container .marketo-form .mktoForm .btn-secondary.dropdown-toggle.mktoButton:focus, .marketo-form .mktoForm .show > .healthplan-modal .button-container .btn-secondary.dropdown-toggle.mktoButton:focus {
  box-shadow: 0 0 0 0.2rem rgba(143, 171, 188, 0.5);
}
.healthplan-modal .button-container .btn:not(:first-child), .healthplan-modal .button-container .marketo-form .mktoForm .mktoButton:not(:first-child), .marketo-form .mktoForm .healthplan-modal .button-container .mktoButton:not(:first-child) {
  margin-top: 1rem;
}
.healthplan-modal .list-container:not(:last-of-type) {
  margin-bottom: 2rem;
}
.healthplan-modal .list-container .heading-4 {
  margin-bottom: 0.75rem;
}
.healthplan-modal .list-container ul {
  padding: 0;
  margin: 0;
}
.healthplan-modal .list-container ul li:before {
  display: none;
}
.healthplan-modal .list-container ul li:not(:last-of-type) {
  margin-bottom: 0.625rem;
}
@media (min-width: 768px) {
  .healthplan-modal.fade .modal-dialog {
    transform: translateY(-3rem);
  }
  .healthplan-modal.show .modal-dialog {
    transform: none;
  }
  .healthplan-modal .modal-scroll-container {
    padding: 3rem 0;
  }
  .healthplan-modal .modal-content {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .healthplan-modal .modal-body {
    padding: 4rem 4rem 1rem;
  }
  .healthplan-modal .modal-body:only-child {
    padding: 5.5rem 4rem;
  }
  .healthplan-modal .modal-footer {
    padding: 1.5rem 4rem;
  }
  .healthplan-modal .heading-2 {
    text-align: center;
  }
  .healthplan-modal .heading-2 + p {
    margin-bottom: 2.5rem;
    text-align: center;
  }
  .healthplan-modal .button-container {
    display: flex;
  }
  .healthplan-modal .button-container .btn, .healthplan-modal .button-container .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .healthplan-modal .button-container .mktoButton {
    flex: 1 1 0;
  }
  .healthplan-modal .button-container .btn:not(:first-child), .healthplan-modal .button-container .marketo-form .mktoForm .mktoButton:not(:first-child), .marketo-form .mktoForm .healthplan-modal .button-container .mktoButton:not(:first-child) {
    margin-left: 1rem;
    margin-top: 0;
  }
  .healthplan-modal .multi-list-container {
    display: flex;
  }
  .healthplan-modal .multi-list-container .list-container {
    flex: 1 1 0;
  }
  .healthplan-modal .multi-list-container .list-container:not(:last-of-type) {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}
@media (min-width: 992px) {
  .healthplan-modal .modal-content {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .healthplan-modal .multi-list-container .list-container:not(:last-of-type) {
    margin-right: 2rem;
  }
}
@media (min-width: 1200px) {
  .healthplan-modal .modal-content {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .healthplan-modal .multi-list-container .list-container:not(:last-of-type) {
    margin-right: 2.5rem;
  }
}

@media (min-width: 768px) {
  .insurance-cards-container {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
  }
  .insurance-cards-container .insurance-card {
    flex: 0 1 calc(49% - 15px);
    margin-right: 1rem;
  }
  .insurance-cards-container .insurance-card.double-wide, .insurance-cards-container .insurance-card.full-width {
    flex: 0 1 100%;
    margin-right: 1rem;
  }
  .col-lg-8 .insurance-cards-container .insurance-card {
    flex: 0 1 calc(49% - 15px);
    margin-right: 1rem;
  }
  .col-lg-4 .insurance-cards-container .insurance-card {
    flex: 0 1 calc(49% - 15px);
    margin-right: 1rem;
  }
}
@media (min-width: 1200px) {
  .insurance-cards-container .btn, .insurance-cards-container .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .insurance-cards-container .mktoButton {
    padding: 0.75rem 2.375rem;
  }
  .insurance-cards-container .insurance-card {
    flex: 0 1 calc(25% - 22.5px);
    margin-right: 1rem;
  }
  .insurance-cards-container .insurance-card.double-wide {
    flex: 0 1 calc(49% - 15px);
    margin-right: 1rem;
  }
  .col-lg-4 .insurance-cards-container .insurance-card {
    flex: 0 1 100%;
  }
}

.insurance-networks {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .insurance-networks {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .insurance-networks {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .insurance-networks {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .insurance-networks {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.insurance-networks [class*=col-] > .heading-4 {
  margin-bottom: 1.25rem;
}
.insurance-networks .insurance-card {
  padding: 1.75rem 3.5rem;
}
.insurance-networks .insurance-card .insurance-card-img {
  margin-bottom: 0;
  margin-left: 0;
  width: 100%;
}
.insurance-networks .insurance-card .insurance-card-img.magnify-bottom {
  margin-bottom: -15%;
}
.insurance-networks .insurance-card .insurance-card-img.magnify-left {
  width: 111%;
  margin-bottom: -10%;
  margin-left: -11%;
}
.insurance-networks .insurance-card .insurance-card-img.magnify-left-alt {
  width: 110%;
  margin-bottom: -10%;
  margin-left: -10%;
}
.insurance-networks .insurance-card .link-forward {
  margin-top: 1.75rem;
}

.phcs-page .subtext {
  color: #000;
}
.phcs-page .lighter-text {
  color: #707070;
}
.phcs-page ol {
  padding-left: 1.25rem;
}
.phcs-page a[target=_blank] {
  padding-right: 1.75rem;
  position: relative;
  display: inline-block;
}
.phcs-page a[target=_blank]:after {
  background-image: url(/assets/images/external-black.png);
  background-size: cover;
  background-position: 50% 50%;
  bottom: -3px;
  display: inline-block;
  content: "";
  height: 16px;
  position: relative;
  width: 28px;
  margin-right: -28px;
}
.phcs-page .phcs-img {
  display: block;
  margin-left: -1rem;
  width: 200px;
}

.childrens-theme .btn.btn-primary, .childrens-theme .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .childrens-theme .mktoButton, .childrens-theme .btn:not([class*=btn-]) {
  color: #fff;
  background-color: #6fb068;
  border-color: #6fb068;
}
.childrens-theme .btn.btn-primary:hover, .childrens-theme .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .childrens-theme .mktoButton:hover, .childrens-theme .btn:not([class*=btn-]):hover {
  color: #fff;
  background-color: #509536;
  border-color: #509536;
}
.childrens-theme .btn.btn-primary:focus, .childrens-theme .marketo-form .mktoForm .mktoButton:focus, .marketo-form .mktoForm .childrens-theme .mktoButton:focus, .childrens-theme .btn.btn-primary.focus, .childrens-theme .marketo-form .mktoForm .focus.mktoButton, .marketo-form .mktoForm .childrens-theme .focus.mktoButton, .childrens-theme .btn:not([class*=btn-]):focus, .childrens-theme .btn:not([class*=btn-]).focus {
  color: #fff;
  background-color: #509536;
  border-color: #509536;
  box-shadow: 0 0 0 0.2rem rgba(133, 188, 127, 0.5);
}
.childrens-theme .btn.btn-primary.disabled, .childrens-theme .marketo-form .mktoForm .disabled.mktoButton, .marketo-form .mktoForm .childrens-theme .disabled.mktoButton, .childrens-theme .btn.btn-primary:disabled, .childrens-theme .marketo-form .mktoForm .mktoButton:disabled, .marketo-form .mktoForm .childrens-theme .mktoButton:disabled, .childrens-theme .btn:not([class*=btn-]).disabled, .childrens-theme .btn:not([class*=btn-]):disabled {
  color: #fff;
  background-color: #6fb068;
  border-color: #6fb068;
}
.childrens-theme .btn.btn-primary:not(:disabled):not(.disabled):active, .childrens-theme .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .childrens-theme .mktoButton:not(:disabled):not(.disabled):active, .childrens-theme .btn.btn-primary:not(:disabled):not(.disabled).active, .childrens-theme .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .childrens-theme .mktoButton:not(:disabled):not(.disabled).active, .show > .childrens-theme .btn.btn-primary.dropdown-toggle, .show > .childrens-theme .marketo-form .mktoForm .dropdown-toggle.mktoButton, .marketo-form .mktoForm .show > .childrens-theme .dropdown-toggle.mktoButton, .childrens-theme .btn:not([class*=btn-]):not(:disabled):not(.disabled):active, .childrens-theme .btn:not([class*=btn-]):not(:disabled):not(.disabled).active, .show > .childrens-theme .btn:not([class*=btn-]).dropdown-toggle {
  color: #fff;
  background-color: #56964f;
  border-color: #518e4a;
}
.childrens-theme .btn.btn-primary:not(:disabled):not(.disabled):active:focus, .childrens-theme .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .childrens-theme .mktoButton:not(:disabled):not(.disabled):active:focus, .childrens-theme .btn.btn-primary:not(:disabled):not(.disabled).active:focus, .childrens-theme .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .childrens-theme .mktoButton:not(:disabled):not(.disabled).active:focus, .show > .childrens-theme .btn.btn-primary.dropdown-toggle:focus, .show > .childrens-theme .marketo-form .mktoForm .dropdown-toggle.mktoButton:focus, .marketo-form .mktoForm .show > .childrens-theme .dropdown-toggle.mktoButton:focus, .childrens-theme .btn:not([class*=btn-]):not(:disabled):not(.disabled):active:focus, .childrens-theme .btn:not([class*=btn-]):not(:disabled):not(.disabled).active:focus, .show > .childrens-theme .btn:not([class*=btn-]).dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 188, 127, 0.5);
}
.childrens-theme .btn.btn-secondary, .childrens-theme .marketo-form .mktoForm .btn-secondary.mktoButton, .marketo-form .mktoForm .childrens-theme .btn-secondary.mktoButton {
  color: #fff;
  background-color: #509536;
  border-color: #509536;
}
.childrens-theme .btn.btn-secondary:hover, .childrens-theme .marketo-form .mktoForm .btn-secondary.mktoButton:hover, .marketo-form .mktoForm .childrens-theme .btn-secondary.mktoButton:hover {
  color: #fff;
  background-color: #6fb068;
  border-color: #6fb068;
}
.childrens-theme .btn.btn-secondary:focus, .childrens-theme .marketo-form .mktoForm .btn-secondary.mktoButton:focus, .marketo-form .mktoForm .childrens-theme .btn-secondary.mktoButton:focus, .childrens-theme .btn.btn-secondary.focus, .childrens-theme .marketo-form .mktoForm .btn-secondary.focus.mktoButton, .marketo-form .mktoForm .childrens-theme .btn-secondary.focus.mktoButton {
  color: #fff;
  background-color: #6fb068;
  border-color: #6fb068;
  box-shadow: 0 0 0 0.2rem rgba(106, 165, 84, 0.5);
}
.childrens-theme .btn.btn-secondary.disabled, .childrens-theme .marketo-form .mktoForm .btn-secondary.disabled.mktoButton, .marketo-form .mktoForm .childrens-theme .btn-secondary.disabled.mktoButton, .childrens-theme .btn.btn-secondary:disabled, .childrens-theme .marketo-form .mktoForm .btn-secondary.mktoButton:disabled, .marketo-form .mktoForm .childrens-theme .btn-secondary.mktoButton:disabled {
  color: #fff;
  background-color: #509536;
  border-color: #509536;
}
.childrens-theme .btn.btn-secondary:not(:disabled):not(.disabled):active, .childrens-theme .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .childrens-theme .btn-secondary.mktoButton:not(:disabled):not(.disabled):active, .childrens-theme .btn.btn-secondary:not(:disabled):not(.disabled).active, .childrens-theme .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .childrens-theme .btn-secondary.mktoButton:not(:disabled):not(.disabled).active, .show > .childrens-theme .btn.btn-secondary.dropdown-toggle, .show > .childrens-theme .marketo-form .mktoForm .btn-secondary.dropdown-toggle.mktoButton, .marketo-form .mktoForm .show > .childrens-theme .btn-secondary.dropdown-toggle.mktoButton {
  color: #fff;
  background-color: #3c7028;
  border-color: #376625;
}
.childrens-theme .btn.btn-secondary:not(:disabled):not(.disabled):active:focus, .childrens-theme .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .childrens-theme .btn-secondary.mktoButton:not(:disabled):not(.disabled):active:focus, .childrens-theme .btn.btn-secondary:not(:disabled):not(.disabled).active:focus, .childrens-theme .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .childrens-theme .btn-secondary.mktoButton:not(:disabled):not(.disabled).active:focus, .show > .childrens-theme .btn.btn-secondary.dropdown-toggle:focus, .show > .childrens-theme .marketo-form .mktoForm .btn-secondary.dropdown-toggle.mktoButton:focus, .marketo-form .mktoForm .show > .childrens-theme .btn-secondary.dropdown-toggle.mktoButton:focus {
  box-shadow: 0 0 0 0.2rem rgba(106, 165, 84, 0.5);
}
.childrens-theme .btn.btn-tertiary, .childrens-theme .marketo-form .mktoForm .btn-tertiary.mktoButton, .marketo-form .mktoForm .childrens-theme .btn-tertiary.mktoButton {
  color: #212529;
  background-color: #a0bf9b;
  border-color: #a0bf9b;
  color: #fff;
}
.childrens-theme .btn.btn-tertiary:hover, .childrens-theme .marketo-form .mktoForm .btn-tertiary.mktoButton:hover, .marketo-form .mktoForm .childrens-theme .btn-tertiary.mktoButton:hover {
  color: #fff;
  background-color: #509536;
  border-color: #509536;
}
.childrens-theme .btn.btn-tertiary:focus, .childrens-theme .marketo-form .mktoForm .btn-tertiary.mktoButton:focus, .marketo-form .mktoForm .childrens-theme .btn-tertiary.mktoButton:focus, .childrens-theme .btn.btn-tertiary.focus, .childrens-theme .marketo-form .mktoForm .btn-tertiary.focus.mktoButton, .marketo-form .mktoForm .childrens-theme .btn-tertiary.focus.mktoButton {
  color: #fff;
  background-color: #509536;
  border-color: #509536;
  box-shadow: 0 0 0 0.2rem rgba(141, 168, 138, 0.5);
}
.childrens-theme .btn.btn-tertiary.disabled, .childrens-theme .marketo-form .mktoForm .btn-tertiary.disabled.mktoButton, .marketo-form .mktoForm .childrens-theme .btn-tertiary.disabled.mktoButton, .childrens-theme .btn.btn-tertiary:disabled, .childrens-theme .marketo-form .mktoForm .btn-tertiary.mktoButton:disabled, .marketo-form .mktoForm .childrens-theme .btn-tertiary.mktoButton:disabled {
  color: #212529;
  background-color: #a0bf9b;
  border-color: #a0bf9b;
}
.childrens-theme .btn.btn-tertiary:not(:disabled):not(.disabled):active, .childrens-theme .marketo-form .mktoForm .btn-tertiary.mktoButton:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .childrens-theme .btn-tertiary.mktoButton:not(:disabled):not(.disabled):active, .childrens-theme .btn.btn-tertiary:not(:disabled):not(.disabled).active, .childrens-theme .marketo-form .mktoForm .btn-tertiary.mktoButton:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .childrens-theme .btn-tertiary.mktoButton:not(:disabled):not(.disabled).active, .show > .childrens-theme .btn.btn-tertiary.dropdown-toggle, .show > .childrens-theme .marketo-form .mktoForm .btn-tertiary.dropdown-toggle.mktoButton, .marketo-form .mktoForm .show > .childrens-theme .btn-tertiary.dropdown-toggle.mktoButton {
  color: #212529;
  background-color: #82ab7c;
  border-color: #7ba674;
}
.childrens-theme .btn.btn-tertiary:not(:disabled):not(.disabled):active:focus, .childrens-theme .marketo-form .mktoForm .btn-tertiary.mktoButton:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .childrens-theme .btn-tertiary.mktoButton:not(:disabled):not(.disabled):active:focus, .childrens-theme .btn.btn-tertiary:not(:disabled):not(.disabled).active:focus, .childrens-theme .marketo-form .mktoForm .btn-tertiary.mktoButton:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .childrens-theme .btn-tertiary.mktoButton:not(:disabled):not(.disabled).active:focus, .show > .childrens-theme .btn.btn-tertiary.dropdown-toggle:focus, .show > .childrens-theme .marketo-form .mktoForm .btn-tertiary.dropdown-toggle.mktoButton:focus, .marketo-form .mktoForm .show > .childrens-theme .btn-tertiary.dropdown-toggle.mktoButton:focus {
  box-shadow: 0 0 0 0.2rem rgba(141, 168, 138, 0.5);
}

.childrens-theme .hero-primary .hero-title {
  color: #6fb068;
}

.childrens-theme .hero-secondary .hero-title {
  color: #6fb068;
}

.childrens-theme .related-areas .related-areas-inner {
  background-color: #6fb068;
}
.childrens-theme .related-areas .btn, .childrens-theme .related-areas .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .childrens-theme .related-areas .mktoButton {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
  color: #509536;
}
.childrens-theme .related-areas .btn:hover, .childrens-theme .related-areas .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .childrens-theme .related-areas .mktoButton:hover {
  color: #212529;
  background-color: #a2c3d6;
  border-color: #a2c3d6;
}
.childrens-theme .related-areas .btn:focus, .childrens-theme .related-areas .marketo-form .mktoForm .mktoButton:focus, .marketo-form .mktoForm .childrens-theme .related-areas .mktoButton:focus, .childrens-theme .related-areas .btn.focus, .childrens-theme .related-areas .marketo-form .mktoForm .focus.mktoButton, .marketo-form .mktoForm .childrens-theme .related-areas .focus.mktoButton {
  color: #212529;
  background-color: #a2c3d6;
  border-color: #a2c3d6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.childrens-theme .related-areas .btn.disabled, .childrens-theme .related-areas .marketo-form .mktoForm .disabled.mktoButton, .marketo-form .mktoForm .childrens-theme .related-areas .disabled.mktoButton, .childrens-theme .related-areas .btn:disabled, .childrens-theme .related-areas .marketo-form .mktoForm .mktoButton:disabled, .marketo-form .mktoForm .childrens-theme .related-areas .mktoButton:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.childrens-theme .related-areas .btn:not(:disabled):not(.disabled):active, .childrens-theme .related-areas .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .childrens-theme .related-areas .mktoButton:not(:disabled):not(.disabled):active, .childrens-theme .related-areas .btn:not(:disabled):not(.disabled).active, .childrens-theme .related-areas .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .childrens-theme .related-areas .mktoButton:not(:disabled):not(.disabled).active, .show > .childrens-theme .related-areas .btn.dropdown-toggle, .show > .childrens-theme .related-areas .marketo-form .mktoForm .dropdown-toggle.mktoButton, .marketo-form .mktoForm .show > .childrens-theme .related-areas .dropdown-toggle.mktoButton {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.childrens-theme .related-areas .btn:not(:disabled):not(.disabled):active:focus, .childrens-theme .related-areas .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .childrens-theme .related-areas .mktoButton:not(:disabled):not(.disabled):active:focus, .childrens-theme .related-areas .btn:not(:disabled):not(.disabled).active:focus, .childrens-theme .related-areas .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .childrens-theme .related-areas .mktoButton:not(:disabled):not(.disabled).active:focus, .show > .childrens-theme .related-areas .btn.dropdown-toggle:focus, .show > .childrens-theme .related-areas .marketo-form .mktoForm .dropdown-toggle.mktoButton:focus, .marketo-form .mktoForm .show > .childrens-theme .related-areas .dropdown-toggle.mktoButton:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.childrens-theme .related-areas .btn:hover, .childrens-theme .related-areas .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .childrens-theme .related-areas .mktoButton:hover {
  color: #509536;
}

.childrens-theme .video .wistia_embed .w-bpb-wrapper button div {
  background-color: #6fb068 !important;
  mix-blend-mode: normal !important;
}
.childrens-theme .video .wistia_embed .w-bpb-wrapper button div:hover div {
  background-color: #509536 !important;
}

.microsite-theme .audio-player {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1.25rem;
}
@media (min-width: 576px) {
  .microsite-theme .audio-player {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .microsite-theme .audio-player {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .microsite-theme .audio-player {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .microsite-theme .audio-player {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.microsite-theme .audio-player .audio-title {
  font-size: 1.25rem;
  line-height: 1.6;
  color: #000;
  margin-bottom: 0;
}
.microsite-theme .audio-player .audio-description {
  font-size: 1.25rem;
  line-height: 1.5;
}
.microsite-theme .audio-player audio {
  width: 100%;
  max-width: 100%;
}
.microsite-theme .audio-player audio::-webkit-media-controls-play-button {
  transform: scale(1.5, 1.5);
  left: -2px;
}
@media (min-width: 768px) {
  .microsite-theme .audio-player {
    display: flex;
  }
  .microsite-theme .audio-player .audio-text {
    flex: 0 0 calc(50% + 0.5rem);
    max-width: calc(50% + 0.5rem);
    padding-right: 1rem;
  }
  .microsite-theme .audio-player .audio-element {
    display: flex;
    flex: 0 0 calc(50% - 0.5rem);
    justify-content: flex-end;
    max-width: calc(50% - 0.5rem);
    padding-top: 1.75rem;
  }
  .microsite-theme .audio-player audio {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .microsite-theme .audio-player .audio-text {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .microsite-theme .audio-player .audio-element {
    display: flex;
    flex: 0 0 40%;
    justify-content: flex-end;
    max-width: 40%;
    padding-top: 1.75rem;
  }
}

.microsite-theme .btn, .microsite-theme .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .microsite-theme .mktoButton {
  padding-left: 2rem;
  padding-right: 2rem;
  font-weight: 600;
  text-decoration: none;
}
.microsite-theme .btn.btn-primary, .microsite-theme .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .microsite-theme .mktoButton, .microsite-theme .btn:not([class*=btn-]) {
  color: #212529;
  background-color: #fcb930;
  border-color: #fcb930;
  color: #404040;
}
.microsite-theme .btn.btn-primary:hover, .microsite-theme .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .microsite-theme .mktoButton:hover, .microsite-theme .btn:not([class*=btn-]):hover {
  color: #212529;
  background-color: #fdc758;
  border-color: #fdc758;
}
.microsite-theme .btn.btn-primary:focus, .microsite-theme .marketo-form .mktoForm .mktoButton:focus, .marketo-form .mktoForm .microsite-theme .mktoButton:focus, .microsite-theme .btn.btn-primary.focus, .microsite-theme .marketo-form .mktoForm .focus.mktoButton, .marketo-form .mktoForm .microsite-theme .focus.mktoButton, .microsite-theme .btn:not([class*=btn-]):focus, .microsite-theme .btn:not([class*=btn-]).focus {
  color: #212529;
  background-color: #fdc758;
  border-color: #fdc758;
  box-shadow: 0 0 0 0.2rem rgba(219, 163, 47, 0.5);
}
.microsite-theme .btn.btn-primary.disabled, .microsite-theme .marketo-form .mktoForm .disabled.mktoButton, .marketo-form .mktoForm .microsite-theme .disabled.mktoButton, .microsite-theme .btn.btn-primary:disabled, .microsite-theme .marketo-form .mktoForm .mktoButton:disabled, .marketo-form .mktoForm .microsite-theme .mktoButton:disabled, .microsite-theme .btn:not([class*=btn-]).disabled, .microsite-theme .btn:not([class*=btn-]):disabled {
  color: #212529;
  background-color: #fcb930;
  border-color: #fcb930;
}
.microsite-theme .btn.btn-primary:not(:disabled):not(.disabled):active, .microsite-theme .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .microsite-theme .mktoButton:not(:disabled):not(.disabled):active, .microsite-theme .btn.btn-primary:not(:disabled):not(.disabled).active, .microsite-theme .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .microsite-theme .mktoButton:not(:disabled):not(.disabled).active, .show > .microsite-theme .btn.btn-primary.dropdown-toggle, .show > .microsite-theme .marketo-form .mktoForm .dropdown-toggle.mktoButton, .marketo-form .mktoForm .show > .microsite-theme .dropdown-toggle.mktoButton, .microsite-theme .btn:not([class*=btn-]):not(:disabled):not(.disabled):active, .microsite-theme .btn:not([class*=btn-]):not(:disabled):not(.disabled).active, .show > .microsite-theme .btn:not([class*=btn-]).dropdown-toggle {
  color: #212529;
  background-color: #f5a604;
  border-color: #e99e03;
}
.microsite-theme .btn.btn-primary:not(:disabled):not(.disabled):active:focus, .microsite-theme .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .microsite-theme .mktoButton:not(:disabled):not(.disabled):active:focus, .microsite-theme .btn.btn-primary:not(:disabled):not(.disabled).active:focus, .microsite-theme .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .microsite-theme .mktoButton:not(:disabled):not(.disabled).active:focus, .show > .microsite-theme .btn.btn-primary.dropdown-toggle:focus, .show > .microsite-theme .marketo-form .mktoForm .dropdown-toggle.mktoButton:focus, .marketo-form .mktoForm .show > .microsite-theme .dropdown-toggle.mktoButton:focus, .microsite-theme .btn:not([class*=btn-]):not(:disabled):not(.disabled):active:focus, .microsite-theme .btn:not([class*=btn-]):not(:disabled):not(.disabled).active:focus, .show > .microsite-theme .btn:not([class*=btn-]).dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 163, 47, 0.5);
}
.microsite-theme .btn.btn-primary:hover, .microsite-theme .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .microsite-theme .mktoButton:hover, .microsite-theme .btn:not([class*=btn-]):hover {
  color: #000;
  background-color: #fff;
  border-color: #ffcd55;
}
.microsite-theme .btn.btn-secondary, .microsite-theme .marketo-form .mktoForm .btn-secondary.mktoButton, .marketo-form .mktoForm .microsite-theme .btn-secondary.mktoButton {
  color: #212529;
  background-color: #cccccc;
  border-color: #cccccc;
  color: #404040;
}
.microsite-theme .btn.btn-secondary:hover, .microsite-theme .marketo-form .mktoForm .btn-secondary.mktoButton:hover, .marketo-form .mktoForm .microsite-theme .btn-secondary.mktoButton:hover {
  color: #212529;
  background-color: #e4e5e6;
  border-color: #e4e5e6;
}
.microsite-theme .btn.btn-secondary:focus, .microsite-theme .marketo-form .mktoForm .btn-secondary.mktoButton:focus, .marketo-form .mktoForm .microsite-theme .btn-secondary.mktoButton:focus, .microsite-theme .btn.btn-secondary.focus, .microsite-theme .marketo-form .mktoForm .btn-secondary.focus.mktoButton, .marketo-form .mktoForm .microsite-theme .btn-secondary.focus.mktoButton {
  color: #212529;
  background-color: #e4e5e6;
  border-color: #e4e5e6;
  box-shadow: 0 0 0 0.2rem rgba(178, 179, 180, 0.5);
}
.microsite-theme .btn.btn-secondary.disabled, .microsite-theme .marketo-form .mktoForm .btn-secondary.disabled.mktoButton, .marketo-form .mktoForm .microsite-theme .btn-secondary.disabled.mktoButton, .microsite-theme .btn.btn-secondary:disabled, .microsite-theme .marketo-form .mktoForm .btn-secondary.mktoButton:disabled, .marketo-form .mktoForm .microsite-theme .btn-secondary.mktoButton:disabled {
  color: #212529;
  background-color: #cccccc;
  border-color: #cccccc;
}
.microsite-theme .btn.btn-secondary:not(:disabled):not(.disabled):active, .microsite-theme .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .microsite-theme .btn-secondary.mktoButton:not(:disabled):not(.disabled):active, .microsite-theme .btn.btn-secondary:not(:disabled):not(.disabled).active, .microsite-theme .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .microsite-theme .btn-secondary.mktoButton:not(:disabled):not(.disabled).active, .show > .microsite-theme .btn.btn-secondary.dropdown-toggle, .show > .microsite-theme .marketo-form .mktoForm .btn-secondary.dropdown-toggle.mktoButton, .marketo-form .mktoForm .show > .microsite-theme .btn-secondary.dropdown-toggle.mktoButton {
  color: #212529;
  background-color: #b3b3b3;
  border-color: #acacac;
}
.microsite-theme .btn.btn-secondary:not(:disabled):not(.disabled):active:focus, .microsite-theme .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .microsite-theme .btn-secondary.mktoButton:not(:disabled):not(.disabled):active:focus, .microsite-theme .btn.btn-secondary:not(:disabled):not(.disabled).active:focus, .microsite-theme .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .microsite-theme .btn-secondary.mktoButton:not(:disabled):not(.disabled).active:focus, .show > .microsite-theme .btn.btn-secondary.dropdown-toggle:focus, .show > .microsite-theme .marketo-form .mktoForm .btn-secondary.dropdown-toggle.mktoButton:focus, .marketo-form .mktoForm .show > .microsite-theme .btn-secondary.dropdown-toggle.mktoButton:focus {
  box-shadow: 0 0 0 0.2rem rgba(178, 179, 180, 0.5);
}
.microsite-theme .btn.btn-secondary:hover, .microsite-theme .marketo-form .mktoForm .btn-secondary.mktoButton:hover, .marketo-form .mktoForm .microsite-theme .btn-secondary.mktoButton:hover {
  color: #000;
  background-color: #fff;
  border-color: #cccccc;
}
.microsite-theme .btn[target=_blank]:after, .microsite-theme .marketo-form .mktoForm [target=_blank].mktoButton:after, .marketo-form .mktoForm .microsite-theme [target=_blank].mktoButton:after {
  background-image: url(/assets/images/external-black.png);
  background-size: cover;
  background-position: 50% 50%;
  bottom: -3px;
  display: inline-block;
  content: "";
  height: 16px;
  position: relative;
  width: 28px;
  margin-right: -8px;
}
.microsite-theme .section-title {
  color: #000;
  font-size: 45px;
  font-weight: 300;
  line-height: 1;
  padding-bottom: 0;
  margin-bottom: 1.5rem;
  text-align: left;
}
.microsite-theme .section-title:after {
  display: none;
}
@media (min-width: 768px) {
  .microsite-theme .section-title {
    font-size: 55px;
  }
}
@media (min-width: 768px) {
  .microsite-theme .two-column .row,
.microsite-theme .three-column .row {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  .microsite-theme .two-column [class^=col-],
.microsite-theme .three-column [class^=col-] {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.microsite-theme .footer {
  background-color: #092a3e;
}
.microsite-theme .footer p {
  color: #fff;
}
.microsite-theme .footer .marketing p {
  font-size: 1.5rem;
  line-height: 1.25;
}
.microsite-theme .footer .btn, .microsite-theme .footer .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .microsite-theme .footer .mktoButton {
  padding-left: 3.375rem;
  padding-right: 3.375rem;
  font-weight: 600;
}
.microsite-theme .footer .link-lists ol li ol li a,
.microsite-theme .footer .link-lists-constant ol li ol li a {
  color: #fff;
}

.microsite-theme .gallery .carousel-control-next,
.microsite-theme .gallery .carousel-control-prev,
.moments-theme .gallery .carousel-control-next,
.moments-theme .gallery .carousel-control-prev {
  background-color: transparent;
  height: 55px;
  margin: 0 0.625rem;
  opacity: 1;
  padding: 0;
  width: 40px;
}
.microsite-theme .gallery .carousel-control-next:before,
.microsite-theme .gallery .carousel-control-prev:before,
.moments-theme .gallery .carousel-control-next:before,
.moments-theme .gallery .carousel-control-prev:before {
  content: "";
  display: block;
  height: 40px;
  border: 3px solid #fff;
  width: 40px;
}
.microsite-theme .gallery .carousel-control-prev:before,
.moments-theme .gallery .carousel-control-prev:before {
  border-right: none;
  border-top: none;
  transform: translateX(25%) rotate(45deg);
}
.microsite-theme .gallery .carousel-control-next:before,
.moments-theme .gallery .carousel-control-next:before {
  border-left: none;
  border-top: none;
  transform: translateX(-25%) rotate(-45deg);
}
.microsite-theme .gallery .icon-arrow-left,
.microsite-theme .gallery .icon-arrow-right,
.moments-theme .gallery .icon-arrow-left,
.moments-theme .gallery .icon-arrow-right {
  display: none;
  fill: #fff;
}
.microsite-theme .gallery .carousel-indicators,
.moments-theme .gallery .carousel-indicators {
  justify-content: center;
  margin: 0;
  padding-top: 1rem;
  width: 100%;
}
.microsite-theme .gallery .carousel-indicators li,
.moments-theme .gallery .carousel-indicators li {
  background-color: #000;
  height: 9px;
  margin-left: 0.375rem;
  margin-right: 0.375rem;
  opacity: 0.4;
  width: 9px;
}
.microsite-theme .gallery .carousel-indicators li.active,
.moments-theme .gallery .carousel-indicators li.active {
  opacity: 1;
}
.microsite-theme .gallery .carousel-caption,
.moments-theme .gallery .carousel-caption {
  margin-top: 0;
  padding-top: 0.5rem;
  width: 100%;
}
.microsite-theme .gallery .figure-caption,
.moments-theme .gallery .figure-caption {
  color: #000;
}
.microsite-theme .gallery .figure-credit,
.moments-theme .gallery .figure-credit {
  color: #404040;
}
.microsite-theme .gallery .carousel.onethird .carousel-control-next,
.microsite-theme .gallery .carousel.onethird .carousel-control-prev, .microsite-theme .gallery .carousel.onehalf .carousel-control-next,
.microsite-theme .gallery .carousel.onehalf .carousel-control-prev, .microsite-theme .gallery .carousel.twothird .carousel-control-next,
.microsite-theme .gallery .carousel.twothird .carousel-control-prev,
.moments-theme .gallery .carousel.onethird .carousel-control-next,
.moments-theme .gallery .carousel.onethird .carousel-control-prev,
.moments-theme .gallery .carousel.onehalf .carousel-control-next,
.moments-theme .gallery .carousel.onehalf .carousel-control-prev,
.moments-theme .gallery .carousel.twothird .carousel-control-next,
.moments-theme .gallery .carousel.twothird .carousel-control-prev {
  height: 55px;
  width: 40px;
}
.microsite-theme .gallery .carousel.onethird .carousel-indicators, .microsite-theme .gallery .carousel.onehalf .carousel-indicators, .microsite-theme .gallery .carousel.twothird .carousel-indicators,
.moments-theme .gallery .carousel.onethird .carousel-indicators,
.moments-theme .gallery .carousel.onehalf .carousel-indicators,
.moments-theme .gallery .carousel.twothird .carousel-indicators {
  margin: 0;
}
.microsite-theme .gallery .carousel .carousel-inner,
.moments-theme .gallery .carousel .carousel-inner {
  width: 100%;
}
@media (min-width: 1200px) {
  .microsite-theme .two-column .two-column .row [class^=col]:first-of-type .gallery,
.moments-theme .two-column .two-column .row [class^=col]:first-of-type .gallery {
    padding-right: 3rem;
  }
  .microsite-theme .two-column .two-column .row [class^=col]:last-of-type .gallery,
.moments-theme .two-column .two-column .row [class^=col]:last-of-type .gallery {
    padding-left: 3rem;
  }
  .microsite-theme .gallery .carousel.onehalf,
.moments-theme .gallery .carousel.onehalf {
    flex: 0 0 calc(50% - 0.5rem - 3rem);
    max-width: calc(50% - 0.5rem - 3rem);
  }
}

.moments-theme .gallery .carousel-control-next,
.moments-theme .gallery .carousel-control-prev {
  height: 30px;
  width: 20px;
}
.moments-theme .gallery .carousel-control-next:before,
.moments-theme .gallery .carousel-control-prev:before {
  border-width: 2px;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.moments-theme .gallery .carousel-indicators li {
  background-color: #bbbbbb;
  opacity: 1;
}
.moments-theme .gallery .carousel-indicators li.active {
  background-color: #000;
  opacity: 1;
}

.microsite-theme .global-navigation.navbar-fixed .main-nav {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
}
.microsite-theme .global-navigation .main-nav {
  box-shadow: none;
  border-bottom: none;
}
.microsite-theme .global-navigation .btn[target=_blank]::after, .microsite-theme .global-navigation .marketo-form .mktoForm [target=_blank].mktoButton::after, .marketo-form .mktoForm .microsite-theme .global-navigation [target=_blank].mktoButton::after {
  background-image: none;
  display: none;
}
@media (min-width: 992px) {
  .microsite-theme .global-navigation.navbar-fixed .main-nav:before {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
  }
  .microsite-theme .global-navigation .main-nav:before {
    box-shadow: none;
    border-bottom: none;
  }
}

.microsite-theme .hero-secondary {
  margin-bottom: 2.5rem;
  max-width: none;
  padding: 0;
}
.microsite-theme .hero-secondary .hero-title {
  color: #000;
}
@media (min-width: 992px) {
  .microsite-theme .hero-secondary .hero-img {
    text-align: center;
  }
  .microsite-theme .hero-secondary .hero-img img,
.microsite-theme .hero-secondary .hero-img source {
    max-height: 600px;
    max-width: 1920px;
  }
  .microsite-theme .hero-secondary .hero-content-container {
    left: 0;
    right: 0;
  }
}

html .microsite-theme .marketo-form,
html .moments-theme .marketo-form {
  background-color: #f5f5f6;
  padding: 1.5rem;
}
html .microsite-theme .marketo-form .mktoForm label,
html .microsite-theme .marketo-form .mktoForm .mktoLabel,
html .moments-theme .marketo-form .mktoForm label,
html .moments-theme .marketo-form .mktoForm .mktoLabel {
  color: #404040;
}
html .microsite-theme .marketo-form .mktoForm .mktoEmailField,
html .microsite-theme .marketo-form .mktoForm .mktoTelField,
html .microsite-theme .marketo-form .mktoForm .mktoTextField,
html .microsite-theme .marketo-form .mktoForm select,
html .microsite-theme .marketo-form .mktoForm textarea,
html .moments-theme .marketo-form .mktoForm .mktoEmailField,
html .moments-theme .marketo-form .mktoForm .mktoTelField,
html .moments-theme .marketo-form .mktoForm .mktoTextField,
html .moments-theme .marketo-form .mktoForm select,
html .moments-theme .marketo-form .mktoForm textarea {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  background-color: #fff;
  border: 1px solid #fff;
  border-bottom-color: #cbced1;
  border-radius: 0;
  color: #404040;
}
html .microsite-theme .marketo-form .mktoForm .mktoEmailField:focus,
html .microsite-theme .marketo-form .mktoForm .mktoTelField:focus,
html .microsite-theme .marketo-form .mktoForm .mktoTextField:focus,
html .microsite-theme .marketo-form .mktoForm select:focus,
html .microsite-theme .marketo-form .mktoForm textarea:focus,
html .moments-theme .marketo-form .mktoForm .mktoEmailField:focus,
html .moments-theme .marketo-form .mktoForm .mktoTelField:focus,
html .moments-theme .marketo-form .mktoForm .mktoTextField:focus,
html .moments-theme .marketo-form .mktoForm select:focus,
html .moments-theme .marketo-form .mktoForm textarea:focus {
  background-color: #fff;
  border-color: #cbced1;
  box-shadow: none;
}
html .microsite-theme .marketo-form .mktoForm .mktoEmailField::placeholder,
html .microsite-theme .marketo-form .mktoForm .mktoTelField::placeholder,
html .microsite-theme .marketo-form .mktoForm .mktoTextField::placeholder,
html .microsite-theme .marketo-form .mktoForm select::placeholder,
html .microsite-theme .marketo-form .mktoForm textarea::placeholder,
html .moments-theme .marketo-form .mktoForm .mktoEmailField::placeholder,
html .moments-theme .marketo-form .mktoForm .mktoTelField::placeholder,
html .moments-theme .marketo-form .mktoForm .mktoTextField::placeholder,
html .moments-theme .marketo-form .mktoForm select::placeholder,
html .moments-theme .marketo-form .mktoForm textarea::placeholder {
  color: #666666;
}
html .microsite-theme .marketo-form .mktoForm .mktoCheckboxList label,
html .moments-theme .marketo-form .mktoForm .mktoCheckboxList label {
  font-size: 1rem;
  line-height: 1.125;
}
html .microsite-theme .marketo-form .mktoForm .mktoCheckboxList input[type=checkbox]:focus + label:before,
html .moments-theme .marketo-form .mktoForm .mktoCheckboxList input[type=checkbox]:focus + label:before {
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.25);
}
html .microsite-theme .marketo-form .mktoForm .mktoCheckboxList input[type=checkbox] + label,
html .moments-theme .marketo-form .mktoForm .mktoCheckboxList input[type=checkbox] + label {
  padding: 0 0 0 2rem;
  position: relative;
}
html .microsite-theme .marketo-form .mktoForm .mktoCheckboxList input[type=checkbox] + label:before,
html .moments-theme .marketo-form .mktoForm .mktoCheckboxList input[type=checkbox] + label:before {
  border-color: #666666;
  border-width: 1px;
}
html .microsite-theme .marketo-form .mktoForm .mktoCheckboxList input[type=checkbox] + label:after,
html .moments-theme .marketo-form .mktoForm .mktoCheckboxList input[type=checkbox] + label:after {
  border-color: #fcb930;
}
html .microsite-theme .two-column [class^=col] .marketo-form,
html .microsite-theme .three-column [class^=col] .marketo-form,
html .moments-theme .two-column [class^=col] .marketo-form,
html .moments-theme .three-column [class^=col] .marketo-form {
  padding: 1.5rem;
}
@media (min-width: 992px) {
  html .microsite-theme .two-column [class^=col] .marketo-form,
html .moments-theme .two-column [class^=col] .marketo-form {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media (min-width: 1200px) {
  html .microsite-theme .two-column [class^=col] .marketo-form,
html .moments-theme .two-column [class^=col] .marketo-form {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
.microsite-theme .field-validation-error,
.moments-theme .field-validation-error {
  display: block;
  margin: -0.75rem 0 1rem;
}

html .moments-theme .marketo-form {
  background-color: transparent;
  padding: 0;
}

.microsite-theme .media-summary .media-summary-inner {
  background-color: #f5f5f6;
}
.microsite-theme .media-summary .media-summary-player {
  background-color: #f5f5f6;
}
.microsite-theme .media-summary .media-summary-content {
  background-color: #f5f5f6;
  padding: 1.5rem 1.5rem 2rem;
}
.microsite-theme .media-summary .media-summary-content .btn, .microsite-theme .media-summary .media-summary-content .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .microsite-theme .media-summary .media-summary-content .mktoButton {
  width: 100%;
}
.microsite-theme .media-summary .heading-4 {
  font-size: 1.25rem;
  line-height: 1.5;
  color: #000;
  font-weight: 700;
}
.microsite-theme .media-summary .heading-3 {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}
.microsite-theme .media-summary p {
  font-size: 1.25rem;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .microsite-theme .media-summary .media-summary-content .btn, .microsite-theme .media-summary .media-summary-content .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .microsite-theme .media-summary .media-summary-content .mktoButton {
    width: auto;
  }
}
@media (min-width: 992px) {
  .microsite-theme .media-summary:not(.always-stacked) .media-summary-player {
    flex: 0 0 calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
    position: relative;
  }
  .microsite-theme .media-summary:not(.always-stacked) .media-summary-player .media-item:not(.is-video) {
    bottom: 0;
    position: absolute;
    top: 0;
  }
  .microsite-theme .media-summary:not(.always-stacked) .media-summary-player .media-item:not(.is-video) .figure-img {
    font-family: "object-fit: cover; object-position: center;";
    height: 100%;
    object-fit: cover;
  }
  .microsite-theme .media-summary:not(.always-stacked) .media-summary-content {
    flex: 0 0 calc(50% + 0.5rem);
    max-width: calc(50% + 0.5rem);
  }
  .microsite-theme .media-summary .media-summary-content {
    background-color: #f5f5f6;
    padding: 2.5rem 2.5rem 2.75rem;
  }
}
.microsite-theme .three-column .media-summary .media-summary-content {
  padding: 2.25rem 2.5rem 2.5rem;
}
@media (min-width: 992px) {
  .microsite-theme .two-column .media-summary .media-summary-content {
    padding: 2.25rem 2.5rem 2.5rem;
  }
}
@media (min-width: 1200px) {
  .microsite-theme .two-column .media-summary:not(.always-stacked) .media-summary-player {
    flex: 0 0 calc(50% - 0.5rem - 3rem);
    max-width: calc(50% - 0.5rem - 3rem);
  }
  .microsite-theme .two-column .media-summary:not(.always-stacked) .media-summary-content {
    flex: 0 0 calc(50% + 0.5rem + 3rem);
    max-width: calc(50% + 0.5rem + 3rem);
    padding-left: 7rem;
  }
  .microsite-theme .two-column .media-summary.right:not(.always-stacked) .media-summary-content {
    padding-left: 2.5rem;
    padding-right: 7rem;
  }
  .microsite-theme .two-column .two-column .row [class^=col]:first-of-type .media-summary.always-stacked {
    padding-right: 3rem;
  }
  .microsite-theme .two-column .two-column .row [class^=col]:last-of-type .media-summary.always-stacked {
    padding-left: 3rem;
  }
}

.microsite-theme .microsite-left-nav .accordion-item {
  margin-top: 3px;
  margin-bottom: 3px;
}
.microsite-theme .microsite-left-nav .accordion-item .accordion-item-label {
  width: 100%;
}
.microsite-theme .microsite-left-nav .accordion-item .accordion-item-toggle.collapsed .icon {
  fill: #2c373f;
  transition: all 0.5s ease;
  transition-property: color, transform, margin;
}
.microsite-theme .microsite-left-nav .accordion-item .accordion-item-toggle:not(.collapsed) .icon {
  transform: rotate(180deg);
  transition: all 0.5s ease;
}
.microsite-theme .microsite-left-nav .heading-4 {
  border-radius: 0;
  border: none;
  color: #000;
  font-weight: 400;
  margin-bottom: 1px;
}
.microsite-theme .microsite-left-nav .heading-4 a {
  font-size: 1.25rem;
  line-height: 1.5;
  background-color: #cbced1;
  color: #000;
  display: block;
  padding: 0.375rem 1.25rem;
  text-decoration: underline;
  border-radius: 5px;
}
.microsite-theme .microsite-left-nav .heading-4 a:hover, .microsite-theme .microsite-left-nav .heading-4 a:focus {
  text-decoration: none;
}
.microsite-theme .microsite-left-nav .accordion-cta {
  border-radius: 5px;
  border: none;
  color: #000;
  font-weight: 700;
  margin-bottom: 1px;
}
.microsite-theme .microsite-left-nav .accordion-cta a:first-child {
  margin-top: 0.1rem;
}
.microsite-theme .microsite-left-nav .accordion-cta a {
  font-size: 1.125rem;
  line-height: 1.6666666667;
  background-color: #ffcd55;
  color: #2c373f;
  display: flex;
  padding: 0.2rem 1.25rem;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  border-radius: 5px;
  border: 5px solid #ffcd55;
  align-items: center;
  margin-top: 1rem;
}
.microsite-theme .microsite-left-nav .accordion-cta a span {
  width: 100%;
}
.microsite-theme .microsite-left-nav .accordion-cta a:hover, .microsite-theme .microsite-left-nav .accordion-cta a:focus {
  background-color: #fff;
  color: #000;
}
.microsite-theme .microsite-left-nav .btn-expand {
  font-size: 1.125rem;
  line-height: 1.6666666667;
  background-color: #cbced1;
  border-radius: 5px;
  border: none;
  border-left: 10px solid transparent;
  box-shadow: none;
  color: #000;
  display: flex;
  font-weight: 700;
  padding: 0.375rem 1.25rem 0.375rem 0.625rem;
  text-align: left;
  text-decoration: underline;
  transition: all 0.15s ease-in-out;
  width: 100%;
  align-items: center;
}
.microsite-theme .microsite-left-nav .btn-expand.active {
  border-left-color: #ffcd55;
}
.microsite-theme .microsite-left-nav .btn-expand.active-parent.collapsed {
  border-left-color: #ffcd55;
}
.microsite-theme .microsite-left-nav .btn-expand:not(.collapsed) {
  text-decoration: none;
}
.microsite-theme .microsite-left-nav .btn-expand:hover {
  background-color: #ffcd55;
}
.microsite-theme .microsite-left-nav .icon-arrow-left,
.microsite-theme .microsite-left-nav .icon-arrow-right {
  fill: #2c373f;
  position: relative;
  left: 0.3rem;
}
.microsite-theme .microsite-left-nav .accordion-header {
  margin-bottom: 0;
}
.microsite-theme .microsite-left-nav .accordion-header h5 {
  margin-bottom: 0;
}
.microsite-theme .microsite-left-nav .accordion-body {
  background-color: #f5f5f6;
  border: none;
  padding: 0.625rem 1.25rem 1rem 0 !important;
}
.microsite-theme .microsite-left-nav .inner-list-title {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #666666;
  font-style: italic;
  font-weight: 700;
  margin-bottom: 0.25rem;
  padding-left: 30px;
}
.microsite-theme .microsite-left-nav .overview-link {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  border-left: 10px solid transparent;
  font-style: normal;
  font-weight: 700;
  padding: 0.375rem 0 0.375rem 1.25rem;
  margin-bottom: 1.375rem;
}
.microsite-theme .microsite-left-nav .overview-link a {
  color: #666666;
  text-decoration: underline;
}
.microsite-theme .microsite-left-nav .overview-link a:hover {
  text-decoration: none;
}
.microsite-theme .microsite-left-nav .overview-link.active {
  border-left-color: #ffcd55;
}
.microsite-theme .microsite-left-nav .overview-link.active a {
  pointer-events: none;
  text-decoration: none;
}
.microsite-theme .microsite-left-nav ul {
  list-style: none;
  padding: 0;
  margin-bottom: 1.75rem;
}
.microsite-theme .microsite-left-nav ul:last-of-type {
  margin-bottom: 0;
}
.microsite-theme .microsite-left-nav ul li {
  margin: 0;
  padding: 0.25rem 0 0.25rem 1.25rem;
  border-left: 10px solid transparent;
}
.microsite-theme .microsite-left-nav ul li:before {
  display: none;
}
.microsite-theme .microsite-left-nav ul li.active {
  border-left-color: #ffcd55;
}
.microsite-theme .microsite-left-nav ul li.active a {
  pointer-events: none;
  text-decoration: none;
}
.microsite-theme .microsite-left-nav ul li a {
  font-size: 1rem;
  line-height: 1;
  color: #000;
  text-decoration: underline;
}
.microsite-theme .microsite-left-nav ul li a:hover, .microsite-theme .microsite-left-nav ul li a:focus {
  text-decoration: none;
}
.microsite-theme .leftnav.has-page-adjacent {
  border-radius: 5px;
  border: none;
  color: #000;
  font-weight: 700;
  margin-bottom: 1px;
}
.microsite-theme .leftnav.has-page-adjacent :active {
  background-color: #fff !important;
  border-color: #ffcd55 !important;
  color: #000 !important;
}
.microsite-theme .leftnav.has-page-adjacent a:first-child {
  margin-top: 0.2rem;
}
.microsite-theme .leftnav.has-page-adjacent a {
  font-size: 1.125rem;
  line-height: 1.6666666667;
  background-color: #ffcd55;
  color: #2c373f;
  display: flex;
  padding: 0.2rem 1.25rem;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  border-radius: 5px;
  border: 5px solid #ffcd55;
  align-items: center;
  margin-top: 1rem;
}
.microsite-theme .leftnav.has-page-adjacent a span {
  width: 100%;
  font-weight: 700;
}
.microsite-theme .leftnav.has-page-adjacent a:hover, .microsite-theme .leftnav.has-page-adjacent a:focus {
  background-color: #fff;
  color: #000;
}
.microsite-theme .leftnav.has-page-adjacent a .icon-arrow-left,
.microsite-theme .leftnav.has-page-adjacent a .icon-arrow-right {
  fill: #2c373f;
}

.microsite-theme .page-header .breadcrumb {
  padding-top: 0;
  margin-bottom: 1rem;
}
.microsite-theme .page-header .breadcrumb-item a {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  color: #000;
  font-weight: 400;
}
.microsite-theme .page-header .breadcrumb-item a > .icon {
  fill: #000;
  width: 1.25rem;
}
.microsite-theme .page-header .heading-1 {
  font-size: 50px;
  font-weight: 400;
  color: #000;
}
.microsite-theme .page-header .language-dropdown,
.microsite-theme .page-header .divider {
  display: none;
}
@media (min-width: 768px) {
  .microsite-theme .page-header .page-header-inner {
    padding-top: 1.25rem;
  }
  .microsite-theme .page-header .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.25rem;
  }
  .microsite-theme .page-header .breadcrumb-item + .breadcrumb-item::before {
    font-size: 0.875rem;
    line-height: 1.2857142857;
    content: ">";
    color: #000;
    font-weight: 400;
    padding-right: 0.25rem;
  }
  .microsite-theme .page-header .social {
    align-items: center;
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0.625rem;
  }
  .microsite-theme .page-header .social:before {
    font-size: 0.875rem;
    line-height: 1.2857142857;
    color: #000;
    content: "Share";
    display: block;
    font-weight: 600;
    margin-right: 1.25rem;
  }
  .microsite-theme .page-header .social .social-share .icon {
    fill: #000;
    height: 30px;
    stroke: #000;
    width: 30px;
  }
  .microsite-theme .page-header .social .social-share a + a,
.microsite-theme .page-header .social .social-share a + button,
.microsite-theme .page-header .social .social-share button + a,
.microsite-theme .page-header .social .social-share button + button {
    margin-left: 1rem;
  }
  .microsite-theme .page-header .heading-1 {
    padding-right: 250px;
  }
}

.microsite-theme .quizlet .inner-container {
  background-color: #f5f5f6;
}
.microsite-theme .quizlet .quizlet-heading {
  color: #2c373f;
  font-weight: 400;
}
.microsite-theme .quizlet .question-indicator:not(:first-of-type):before {
  background-color: #ccd5d7;
  height: 2px;
}
.microsite-theme .quizlet .question-indicator.completed .question-number {
  background-color: #ccd5d7;
  border-color: #ccd5d7;
  color: #a2c3d6;
}
.microsite-theme .quizlet .question-indicator.active .question-number {
  background-color: #a2c3d6;
  border-color: #a2c3d6;
  color: #fff;
}
.microsite-theme .quizlet .question-number {
  border: 2px solid #ccd5d7;
  color: #a2c3d6;
}
.microsite-theme .quizlet .question-desc {
  font-size: 1.125rem;
  line-height: 1.1111111111;
}
.microsite-theme .quizlet .answer-grid.agree-disagree:before {
  background-color: #a2c3d6;
}
.microsite-theme .quizlet .answer-grid.agree-disagree input[type=radio]:checked + label .answer-content {
  background-color: transparent;
  color: #2c373f;
}
.microsite-theme .quizlet .answer-grid.agree-disagree input[type=radio] + label:before {
  background-color: #fff;
}
.microsite-theme .quizlet input[type=checkbox]:checked + label {
  border-color: transparent;
}
.microsite-theme .quizlet input[type=checkbox]:checked + label .answer-content {
  background-color: #a2c3d6;
  color: #fff;
}
.microsite-theme .quizlet input[type=checkbox]:checked + label:before {
  background-color: transparent;
  border-color: #fcb930;
}
.microsite-theme .quizlet input[type=checkbox]:checked + label:after {
  border-bottom: 3px solid #a2c3d6;
  border-right: 3px solid #a2c3d6;
  content: "";
  display: block;
  height: 1.375rem;
  right: 1rem;
  margin: 0.375rem 0.625rem;
  position: absolute;
  bottom: 1rem;
  transform: rotate(45deg) translate(-2px, -3px);
  width: 0.875rem;
}
.microsite-theme .quizlet input[type=checkbox]:checked:hover + label:before {
  border-color: #fcb930;
}
.microsite-theme .quizlet input[type=checkbox]:checked:focus + label:before {
  box-shadow: 0 0 0 0.2rem rgba(252, 185, 48, 0.25);
}
.microsite-theme .quizlet input[type=checkbox]:hover + label:before {
  border-color: #a2c3d6;
}
.microsite-theme .quizlet input[type=checkbox]:focus + label:before {
  box-shadow: 0 0 0 0.2rem rgba(162, 195, 214, 0.25);
}
.microsite-theme .quizlet input[type=checkbox] + label .answer-content {
  color: #2c373f;
}
.microsite-theme .quizlet input[type=checkbox] + label:before {
  border: 2px solid #a2c3d6;
}
.microsite-theme .quizlet input[type=checkbox] + label:hover:before {
  border-color: #a2c3d6;
}
.microsite-theme .quizlet input[type=radio]:checked + label {
  border-color: transparent;
}
.microsite-theme .quizlet input[type=radio]:checked + label .answer-content {
  background-color: #a2c3d6;
  color: #fff;
}
.microsite-theme .quizlet input[type=radio]:checked + label:before {
  border-color: #fcb930;
}
.microsite-theme .quizlet input[type=radio]:checked + label:after {
  background-color: #fcb930;
}
.microsite-theme .quizlet input[type=radio]:checked:hover + label:before {
  border-color: #fcb930;
}
.microsite-theme .quizlet input[type=radio]:hover + label:before {
  border-color: #a2c3d6;
}
.microsite-theme .quizlet input[type=radio]:focus + label:before {
  box-shadow: 0 0 0 0.2rem rgba(252, 185, 48, 0.25);
}
.microsite-theme .quizlet input[type=radio] + label .answer-content {
  color: #2c373f;
}
.microsite-theme .quizlet input[type=radio] + label:before {
  border: 2px solid #a2c3d6;
}
.microsite-theme .quizlet input[type=radio] + label:hover {
  cursor: pointer;
}
.microsite-theme .quizlet input[type=radio] + label:hover:before {
  border-color: #a2c3d6;
}
@media (min-width: 992px) {
  .microsite-theme .quizlet .quizlet-progress {
    margin-bottom: 2rem;
  }
}

.microsite-theme .thank-you-message {
  background-color: transparent;
}
.microsite-theme .thank-you-message .thank-you-bubble {
  background-color: #2c373f;
  border-color: #2c373f;
  color: #fff;
}
.microsite-theme .thank-you-message .thank-you-bubble:before {
  border-top-color: #2c373f;
}
.microsite-theme .thank-you-message .thank-you-bubble:after {
  border-top-color: #2c373f;
}
.microsite-theme .thank-you-message .thank-you-content {
  color: #404040;
}
.microsite-theme .thank-you-message .thank-you-content .heading-3,
.microsite-theme .thank-you-message .thank-you-content .heading-4,
.microsite-theme .thank-you-message .thank-you-content .heading-5,
.microsite-theme .thank-you-message .thank-you-content h3,
.microsite-theme .thank-you-message .thank-you-content h4,
.microsite-theme .thank-you-message .thank-you-content h5 {
  color: #404040;
}
.microsite-theme .thank-you-message .thank-you-content p a {
  color: #006D9D;
}

.microsite-theme body {
  font-family: "proxima-nova", sans-serif;
}
.microsite-theme h1,
.microsite-theme h2,
.microsite-theme h3,
.microsite-theme h4,
.microsite-theme h5,
.microsite-theme h6 {
  color: #000;
}
.microsite-theme p,
.microsite-theme .body-copy {
  font-size: 1.25rem;
  line-height: 1.5;
  color: #404040;
}
.microsite-theme .list-heading {
  font-size: 1.25rem;
  line-height: 1.5;
  color: #404040;
  font-weight: 700;
  margin-bottom: 0;
}
.microsite-theme .list-unstyled li {
  font-size: 1.25rem;
  line-height: 1.5;
  color: #404040;
}
.microsite-theme .rte a:not(.btn),
.microsite-theme .article-content a:not(.btn) {
  color: #404040;
  text-decoration: underline;
}
.microsite-theme .rte a:not(.btn):hover,
.microsite-theme .article-content a:not(.btn):hover {
  text-decoration: none;
}
.microsite-theme .generic h2,
.microsite-theme .rte h2 {
  color: #000;
  font-size: 45px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 0;
}
.microsite-theme .generic p,
.microsite-theme .rte p {
  font-size: 1.25rem;
  line-height: 1.5;
  color: #404040;
}
.microsite-theme .generic p:first-of-type,
.microsite-theme .rte p:first-of-type {
  padding-top: 0;
}
@media (min-width: 768px) {
  .microsite-theme .generic h2,
.microsite-theme .rte h2 {
    font-size: 55px;
  }
}

.microsite-theme .video .wistia_embed .w-bpb-wrapper,
.moments-theme .video .wistia_embed .w-bpb-wrapper {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
  height: 200px !important;
  width: 200px !important;
  top: calc(50% - 100px) !important;
  left: calc(50% - 100px) !important;
}
.microsite-theme .video .wistia_embed .w-bpb-wrapper button div,
.moments-theme .video .wistia_embed .w-bpb-wrapper button div {
  background-color: transparent !important;
}
.microsite-theme .video .wistia_embed .w-bpb-wrapper button svg,
.moments-theme .video .wistia_embed .w-bpb-wrapper button svg {
  opacity: 0.8;
}
.microsite-theme .video .wistia_embed .w-bpb-wrapper button:hover svg,
.moments-theme .video .wistia_embed .w-bpb-wrapper button:hover svg {
  opacity: 1;
}

.moments-footer {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  background-color: #1f1c17;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}
.moments-footer .footer-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  padding: 3.5rem 4rem;
}
@media (min-width: 576px) {
  .moments-footer .footer-container {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .moments-footer .footer-container {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .moments-footer .footer-container {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .moments-footer .footer-container {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.moments-footer ul {
  color: #fff;
  padding: 0;
}
.moments-footer ul li {
  list-style-type: none;
}
.moments-footer .footer-top {
  margin-bottom: 90px;
}
.moments-footer .footer-top li {
  margin-bottom: 1.375rem;
}
.moments-footer .footer-top a {
  color: #fff;
}
.moments-footer .follow-label {
  margin-bottom: 1.25rem;
}
.moments-footer .follow-icons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  width: 100%;
}
.moments-footer .follow-icons li {
  margin-right: 0.5rem;
}
.moments-footer .follow-icons li:last-of-type {
  margin-right: 0;
}
.moments-footer .follow-icons a {
  display: block;
  max-width: 46px;
}
.moments-footer .follow-icons a:hover .icon, .moments-footer .follow-icons a:focus .icon {
  fill: #fcb930;
}
.moments-footer .follow-icons .icon {
  fill: #fff;
  height: 46px;
  width: 100%;
  transition: fill 0.15s ease-in-out;
}
@media (min-width: 576px) {
  .moments-footer .follow-icons {
    justify-content: flex-start;
  }
  .moments-footer .follow-icons li {
    margin-right: 1.25rem;
  }
  .moments-footer .follow-icons li:last-of-type {
    margin-right: 0;
  }
}
@media (min-width: 992px) {
  .moments-footer {
    font-size: 15px;
    line-height: 2;
  }
  .moments-footer .footer-container {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 4rem 15px;
  }
  .moments-footer .footer-top {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    padding-right: 3rem;
  }
  .moments-footer .footer-top li {
    margin-bottom: 0;
    position: relative;
  }
  .moments-footer .footer-top li:not(:last-of-type) {
    margin-right: 0.75rem;
    padding-right: 0.75rem;
  }
  .moments-footer .footer-top li:not(:last-of-type):after {
    bottom: 0;
    content: "|";
    display: block;
    position: absolute;
    right: 0;
    transform: translateX(50%);
  }
  .moments-footer .footer-bottom {
    align-items: center;
    display: flex;
  }
  .moments-footer .follow-label {
    margin-bottom: 0;
    margin-right: 1.25rem;
  }
  .moments-footer .follow-icons .icon {
    height: 40px;
    width: 40px;
  }
}

.moments-hero .hero-outer {
  border-radius: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;
}
.moments-hero .hero-img {
  display: block;
  height: 100vh;
  min-height: 400px;
  position: relative;
  text-align: center;
}
.moments-hero .hero-img img,
.moments-hero .hero-img source {
  font-family: "object-fit: cover; object-position: center bottom;";
  height: 100vh;
  min-height: 400px;
  object-fit: cover;
  object-position: center bottom;
  width: 100%;
}
.moments-hero .hero-content-container {
  background-color: transparent;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 17.25vh;
}
.moments-hero .hero-content {
  margin: auto;
  max-width: 750px;
  padding: 0 10px;
  width: 100%;
}
.moments-hero .hero-eyebrow {
  font-size: 1.5rem;
  line-height: 1.2083333333;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  letter-spacing: 3.36px;
  margin: 0;
  text-transform: uppercase;
}
.moments-hero .hero-subtitle {
  font-size: 2rem;
  line-height: 1.21875;
  color: #fff;
  font-family: "IBM Plex Serif", serif;
  font-weight: 400;
  margin: 0;
}
.moments-hero .hero-logo {
  margin: 0.625rem 0 1rem;
}
.moments-hero .hero-logo img {
  width: 100%;
}
.moments-hero .scroll-arrow {
  bottom: 2rem;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
}
.moments-hero .scroll-arrow img {
  height: auto;
  width: 52px;
}
.moments-hero .scroll-btn {
  background: transparent;
  border: none;
  margin: 0;
  outline: 0;
  padding: 0;
}
@media (min-width: 992px) {
  .moments-hero .hero-img {
    height: calc(100vh - 70px);
    min-height: 500px;
  }
  .moments-hero .hero-img img,
.moments-hero .hero-img source {
    height: calc(100vh - 70px);
    min-height: 500px;
  }
  .moments-hero .hero-content-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    top: 9vh;
  }
}
@media (min-width: 992px) and (min-width: 576px) {
  .moments-hero .hero-content-container {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) and (min-width: 768px) {
  .moments-hero .hero-content-container {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .moments-hero .hero-content-container {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  .moments-hero .hero-content-container {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .moments-hero .hero-content {
    max-width: 775px;
    padding: 0;
    width: 70%;
  }
}
@media (min-width: 992px) {
  .moments-hero .hero-eyebrow {
    font-size: 1.25rem;
    line-height: 1.25;
    padding: 0 2rem;
  }
}
@media (min-width: 992px) {
  .moments-hero .hero-logo {
    margin: 2.25% 0 3.1%;
  }
}
@media (min-width: 992px) {
  .moments-hero .hero-subtitle {
    font-size: 2rem;
    line-height: 1.375;
    padding: 0 2rem;
  }
}
@media (min-width: 992px) {
  .moments-hero .scroll-arrow {
    display: none;
  }
}

.more-stories {
  background-color: #1f1c17;
  margin-top: 2rem;
  padding: 3rem 0 0;
}
.more-stories .more-stories-header {
  color: #fff;
  font-family: "IBM Plex Serif", serif;
  font-size: 50px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 2.5rem;
  text-align: center;
}
.more-stories .stories-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .more-stories .stories-container {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .more-stories .stories-container {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .more-stories .stories-container {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .more-stories .stories-container {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.more-stories .story-listings {
  margin: 0;
  padding: 0;
}
.more-stories .story-card {
  margin: 0;
  margin-bottom: 1.25rem;
}
.more-stories .story-card:before {
  display: none;
}
.more-stories .story-card:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .more-stories .story-listings {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .more-stories .story-card {
    flex: 0 1 calc(50% - 0.625rem);
    max-width: calc(50% - 0.625rem);
  }
  .more-stories .story-card:last-of-type {
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 992px) {
  .more-stories .more-stories-header {
    font-size: 60px;
    margin: 0 0 3rem;
    text-align: left;
  }
  .more-stories .story-listings:after {
    content: " ";
    flex: 0 1 calc(33.3333% - 13.3333333333px);
    max-width: calc(33.3333% - 13.3333333333px);
  }
  .more-stories .story-card {
    flex: 0 1 calc(33.3333% - 13.3333333333px);
    max-width: calc(33.3333% - 13.3333333333px);
  }
}
@media (min-width: 1200px) {
  .more-stories .story-listings:before {
    content: " ";
    flex: 0 1 calc(25% - 15px);
    max-width: calc(25% - 15px);
    order: 1;
  }
  .more-stories .story-listings:after {
    content: " ";
    flex: 0 1 calc(25% - 15px);
    max-width: calc(25% - 15px);
  }
  .more-stories .story-card {
    flex: 0 1 calc(25% - 15px);
    margin-bottom: 0;
    max-width: calc(25% - 15px);
  }
  .more-stories .story-card:last-of-type {
    margin-bottom: 0;
  }
}

.share-schedule-buttons .btn, .share-schedule-buttons .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .share-schedule-buttons .mktoButton {
  align-items: center;
  background-color: transparent;
  border: 3px solid #fcb930;
  border-radius: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-family: "IBM Plex Serif", serif;
  font-size: 26px;
  font-weight: 400;
  height: 110px;
  justify-content: center;
  line-height: 1;
  margin-bottom: 1.25rem;
  position: relative;
  width: 100%;
}
.share-schedule-buttons .btn svg, .share-schedule-buttons .marketo-form .mktoForm .mktoButton svg, .marketo-form .mktoForm .share-schedule-buttons .mktoButton svg {
  fill: #fcb930;
  flex: 1 0 auto;
  height: 26px;
  margin-top: 0.5rem;
  max-height: 26px;
  max-width: 26px;
  transition: fill 0.15s ease-in-out;
  width: 26px;
}
.share-schedule-buttons .btn:hover, .share-schedule-buttons .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .share-schedule-buttons .mktoButton:hover, .share-schedule-buttons .btn:focus, .share-schedule-buttons .marketo-form .mktoForm .mktoButton:focus, .marketo-form .mktoForm .share-schedule-buttons .mktoButton:focus {
  background-color: #fcb930;
  border-color: #fcb930;
}
.share-schedule-buttons .btn:hover svg, .share-schedule-buttons .marketo-form .mktoForm .mktoButton:hover svg, .marketo-form .mktoForm .share-schedule-buttons .mktoButton:hover svg, .share-schedule-buttons .btn:focus svg, .share-schedule-buttons .marketo-form .mktoForm .mktoButton:focus svg, .marketo-form .mktoForm .share-schedule-buttons .mktoButton:focus svg {
  fill: #fff;
}
.moments-story .share-schedule-buttons .btn, .moments-story .share-schedule-buttons .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .moments-story .share-schedule-buttons .mktoButton {
  color: #000;
}
@media (min-width: 768px) {
  .share-schedule-buttons {
    display: flex;
    justify-content: space-between;
  }
  .share-schedule-buttons .btn, .share-schedule-buttons .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .share-schedule-buttons .mktoButton {
    flex: 0 1 calc(50% - 10px);
    flex-direction: row;
    font-size: 30px;
    justify-content: space-between;
    max-width: calc(50% - 10px);
    padding: 1rem 3.5rem 1rem 2.5rem;
    text-align: left;
  }
  .share-schedule-buttons .btn svg, .share-schedule-buttons .marketo-form .mktoForm .mktoButton svg, .marketo-form .mktoForm .share-schedule-buttons .mktoButton svg {
    margin-top: 0;
  }
}

.share-story-modal {
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  outline: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  z-index: 10;
}
.share-story-modal.visible {
  display: block;
}
.share-story-modal.visible .modal-dialog {
  transform: translateY(-15px);
}
.share-story-modal.open {
  opacity: 1;
}
.share-story-modal.open .modal-dialog {
  transform: none;
}
.share-story-modal .modal-scroll-container {
  height: 100%;
  max-height: 100%;
  overflow: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.share-story-modal .modal-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: auto;
  padding: 0;
}
.share-story-modal .modal-dialog {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  align-items: flex-start;
  display: flex;
  margin: 0 auto;
  max-width: none;
  padding: 0;
  transition: transform 0.3s ease-out;
}
@media (min-width: 576px) {
  .share-story-modal .modal-dialog {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .share-story-modal .modal-dialog {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .share-story-modal .modal-dialog {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .share-story-modal .modal-dialog {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.share-story-modal .modal-close-btn {
  background: transparent;
  border: none;
  outline: 0;
  position: absolute;
  right: 15px;
  top: 15px;
}
.share-story-modal .modal-close-btn .icon {
  height: 30px;
  stroke: #707070;
  width: 30px;
}
.share-story-modal .modal-close-btn:hover .icon, .share-story-modal .modal-close-btn:focus .icon {
  stroke: #fcb930;
}
.share-story-modal .modal-content {
  border: none;
  border-radius: 0;
  margin: auto;
  min-height: 100vh;
}
.share-story-modal .modal-body {
  background-color: #eee;
  padding: 3.5rem 1rem 1rem;
}
.share-story-modal .modal-title {
  color: #000;
  font-family: "IBM Plex Serif", serif;
  font-size: 50px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 1.5rem;
}
.share-story-modal .modal-description {
  font-size: 1rem;
  line-height: 1.375;
  color: #333;
  font-family: "IBM Plex Serif", serif;
  font-weight: 400;
}
.share-story-modal form {
  padding: 0;
}
.share-story-modal form label {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  color: #333;
  font-family: "Lato", sans-serif;
  padding-left: 2px;
}
.share-story-modal form .form-group {
  margin-bottom: 0;
}
.share-story-modal form input[type=text],
.share-story-modal form input[type=email],
.share-story-modal form input[type=tel],
.share-story-modal form select,
.share-story-modal form textarea {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  background-color: #fff;
  border: 1px solid #fff;
  border-bottom-color: #cbced1;
  border-radius: 0;
  color: #404040;
  margin-bottom: 1rem;
}
.share-story-modal form input[type=text]:focus,
.share-story-modal form input[type=email]:focus,
.share-story-modal form input[type=tel]:focus,
.share-story-modal form select:focus,
.share-story-modal form textarea:focus {
  background-color: #fff;
  border-color: #cbced1;
  box-shadow: none;
}
.share-story-modal form input[type=text]::placeholder,
.share-story-modal form input[type=email]::placeholder,
.share-story-modal form input[type=tel]::placeholder,
.share-story-modal form select::placeholder,
.share-story-modal form textarea::placeholder {
  color: #666666;
}
.share-story-modal form .btn, .share-story-modal form .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .share-story-modal form .mktoButton {
  color: #212529;
  background-color: #fcb930;
  border-color: #fcb930;
  color: #2c373f;
  margin: 0;
}
.share-story-modal form .btn:hover, .share-story-modal form .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .share-story-modal form .mktoButton:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fcb930;
}
.share-story-modal form .btn:focus, .share-story-modal form .marketo-form .mktoForm .mktoButton:focus, .marketo-form .mktoForm .share-story-modal form .mktoButton:focus, .share-story-modal form .btn.focus, .share-story-modal form .marketo-form .mktoForm .focus.mktoButton, .marketo-form .mktoForm .share-story-modal form .focus.mktoButton {
  color: #212529;
  background-color: #fff;
  border-color: #fcb930;
  box-shadow: 0 0 0 0.2rem rgba(219, 163, 47, 0.5);
}
.share-story-modal form .btn.disabled, .share-story-modal form .marketo-form .mktoForm .disabled.mktoButton, .marketo-form .mktoForm .share-story-modal form .disabled.mktoButton, .share-story-modal form .btn:disabled, .share-story-modal form .marketo-form .mktoForm .mktoButton:disabled, .marketo-form .mktoForm .share-story-modal form .mktoButton:disabled {
  color: #212529;
  background-color: #fcb930;
  border-color: #fcb930;
}
.share-story-modal form .btn:not(:disabled):not(.disabled):active, .share-story-modal form .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .share-story-modal form .mktoButton:not(:disabled):not(.disabled):active, .share-story-modal form .btn:not(:disabled):not(.disabled).active, .share-story-modal form .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .share-story-modal form .mktoButton:not(:disabled):not(.disabled).active, .show > .share-story-modal form .btn.dropdown-toggle, .show > .share-story-modal form .marketo-form .mktoForm .dropdown-toggle.mktoButton, .marketo-form .mktoForm .show > .share-story-modal form .dropdown-toggle.mktoButton {
  color: #212529;
  background-color: #f5a604;
  border-color: #e99e03;
}
.share-story-modal form .btn:not(:disabled):not(.disabled):active:focus, .share-story-modal form .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .share-story-modal form .mktoButton:not(:disabled):not(.disabled):active:focus, .share-story-modal form .btn:not(:disabled):not(.disabled).active:focus, .share-story-modal form .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .share-story-modal form .mktoButton:not(:disabled):not(.disabled).active:focus, .show > .share-story-modal form .btn.dropdown-toggle:focus, .show > .share-story-modal form .marketo-form .mktoForm .dropdown-toggle.mktoButton:focus, .marketo-form .mktoForm .show > .share-story-modal form .dropdown-toggle.mktoButton:focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 163, 47, 0.5);
}
.share-story-modal form .btn:hover, .share-story-modal form .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .share-story-modal form .mktoButton:hover {
  color: #2c373f;
}
.share-story-modal .thank-you-message {
  background-color: #eee;
}
.share-story-modal .thank-you-message .thank-you-bubble {
  background-color: #1f1c17;
  border-color: #1f1c17;
  border-radius: 8px;
  color: #fcb930;
  font-family: "IBM Plex Serif", serif;
  font-size: 50px;
  font-weight: 400;
}
.share-story-modal .thank-you-message .thank-you-bubble:before {
  border-top: 13px solid #1f1c17;
}
.share-story-modal .thank-you-message .thank-you-bubble:after {
  border-top: 9px solid #1f1c17;
}
.share-story-modal .thank-you-message .thank-you-content p {
  font-size: 1rem;
  line-height: 1.375;
  color: #000;
  font-family: "IBM Plex Serif", serif;
  font-weight: 400;
}
@media (min-width: 768px) {
  .share-story-modal.visible .modal-dialog {
    transform: translateY(-3rem);
  }
  .share-story-modal.open .modal-dialog {
    transform: none;
  }
  .share-story-modal .modal-scroll-container {
    height: auto;
    height: auto;
  }
  .share-story-modal .modal-container {
    padding: 3rem 0;
  }
  .share-story-modal .modal-content {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
    min-height: 0;
  }
  .share-story-modal .modal-body {
    padding: 4rem 4rem 1rem;
  }
  .share-story-modal .modal-close-btn {
    right: 30px;
    top: 30px;
  }
  .share-story-modal .modal-description {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
  .share-story-modal .thank-you-message .thank-you-content p {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media (min-width: 992px) {
  .share-story-modal.visible {
    align-items: center;
    display: flex;
  }
  .share-story-modal .modal-container {
    height: auto;
    max-height: 100vh;
  }
  .share-story-modal .modal-body {
    padding: 70px 110px 1rem;
  }
}

.story-article {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  font-family: "IBM Plex Serif", serif;
  padding: 3rem 0;
}
@media (min-width: 576px) {
  .story-article {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .story-article {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .story-article {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .story-article {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.story-article h2,
.story-article h3,
.story-article h4,
.story-article h5,
.story-article h6 {
  font-size: 1.25rem;
  line-height: 1.5;
  color: #333;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.story-article .story-container {
  padding: 0 15px;
}
.story-article .story-title {
  color: #000;
  font-family: "IBM Plex Serif", serif;
  font-size: 50px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 1.5rem;
  margin-top: 0;
  padding-right: 2rem;
}
.story-article .story-details {
  font-size: 1.25rem;
  line-height: 1.2;
  margin-bottom: 3rem;
}
.story-article .story-details .author-name {
  display: block;
  text-transform: uppercase;
}
.story-article .story-share {
  margin-bottom: 2rem;
}
.story-article .story-share .share-label {
  font-size: 1rem;
  line-height: 1.125;
  color: #000;
  font-family: "Lato", sans-serif;
  margin-bottom: 0.75rem;
}
.story-article .story-share .share-buttons {
  display: flex;
}
.story-article .story-share .share-buttons button {
  background: transparent;
  border: none;
  margin-right: 1rem;
  padding: 0;
}
.story-article .story-share .share-buttons button .icon {
  fill: #000;
  height: 36px;
  transition: fill 0.15s ease-in-out;
  width: 36px;
}
.story-article .story-share .share-buttons button:hover .icon, .story-article .story-share .share-buttons button:focus .icon {
  fill: #fcb930;
}
.story-article .story-content {
  font-size: 1.25rem;
  line-height: 1.5;
  color: #333;
}
.story-article .story-content p {
  font-size: 1.25rem;
  line-height: 1.5;
}
.story-article .story-quote {
  margin: 4rem 0;
  text-align: center;
  width: 100%;
}
.story-article .story-quote .quote {
  font-size: 2.25rem;
  line-height: 1.1111111111;
  color: #000;
  margin: 1rem 0;
}
.story-article .story-quote .by {
  font-size: 1.25rem;
  line-height: 1.2;
  color: #333;
  display: block;
  margin-top: 1rem;
}
.story-article .story-quote img {
  max-height: 50vh;
  max-width: 100%;
}
.story-article .story-quote .img-left {
  margin-top: -3rem;
}
.story-article .story-quote .img-right {
  margin-bottom: -3rem;
}
.story-article .story-gallery {
  margin: 4.5rem 0;
}
.story-article .gallery {
  padding: 0;
}
@media (min-width: 768px) {
  .story-article .story-quote img {
    max-height: 25vh;
  }
  .story-article .story-quote .img-left {
    text-align: left;
  }
  .story-article .story-quote .img-right {
    text-align: right;
  }
}
@media (min-width: 992px) {
  .story-article {
    padding: 60px 0;
  }
  .story-article .story-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    padding: 0;
    position: relative;
  }
  .story-article .story-title {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
    font-size: 60px;
    margin: 0 auto 2rem;
  }
  .story-article .story-details {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
    font-size: 1rem;
    line-height: 1.5;
    display: flex;
    margin: 0 auto 2rem;
  }
  .story-article .story-details .author-name {
    display: inline;
  }
  .story-article .story-details div {
    position: relative;
  }
  .story-article .story-details div:not(:last-of-type) {
    margin-right: 0.75rem;
    padding-right: 0.75rem;
  }
  .story-article .story-details div:not(:last-of-type):after {
    bottom: 0;
    content: "|";
    display: block;
    position: absolute;
    right: 0;
    transform: translateX(50%);
  }
  .story-article .story-share {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: left;
    top: 30px;
  }
  .story-article .story-share .share-label {
    margin-bottom: 1.5rem;
  }
  .story-article .story-share .share-buttons {
    align-items: flex-start;
    flex-direction: column;
  }
  .story-article .story-share .share-buttons button {
    margin-bottom: 1.25rem;
    margin-right: 0;
  }
  .story-article .story-content {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
    margin: auto;
  }
  .story-article .story-quote {
    margin: 1rem 0;
    padding: 7.5rem 15px 6.5rem;
    position: relative;
  }
  .story-article .story-quote .quote {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
    font-size: 60px;
    margin: auto;
  }
  .story-article .story-quote .img-container {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
    padding: 0;
    z-index: -1;
  }
  .story-article .story-quote img {
    max-height: none;
  }
  .story-article .story-quote .img-left {
    bottom: 50%;
    left: 0;
    margin-top: 0;
    position: absolute;
  }
  .story-article .story-quote .img-right {
    margin-bottom: 0;
    position: absolute;
    right: 0;
    top: 50%;
  }
  .story-article .story-quote.img-left-lower .img-left {
    bottom: auto;
    top: 50%;
  }
  .story-article .story-quote.img-left-lower .img-right {
    bottom: 50%;
    top: auto;
  }
  .story-article .story-gallery {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
    margin: 5rem auto 4rem;
  }
}
@media (min-width: 1200px) {
  .story-article .story-quote {
    padding: 7.5rem 0 6.5rem;
  }
}

.story-card {
  background-color: #fff;
  border-bottom: 6px solid #fcb930;
  margin: 0 0 1.25rem;
  padding: 0;
  width: 100%;
}
.story-card:before {
  display: none !important;
}
.story-card.video-card .story-image-inner:after {
  border-bottom: 35px solid transparent;
  border-left: 70px solid rgba(255, 255, 255, 0.5);
  border-top: 35px solid transparent;
  content: "";
  display: block;
  height: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: border-color 0.2s ease-in-out;
  width: 0;
}
.story-card .story-link {
  color: #000;
}
.story-card .story-link:hover, .story-card .story-link:focus {
  text-decoration: none;
}
.story-card .story-link:hover .story-image-container, .story-card .story-link:focus .story-image-container {
  filter: brightness(0.7);
}
.story-card .story-link:hover .story-image-container .story-image-inner:after, .story-card .story-link:focus .story-image-container .story-image-inner:after {
  border-left: 70px solid rgba(255, 255, 255, 0.8);
}
.story-card .story-link:hover .story-title, .story-card .story-link:focus .story-title {
  text-decoration: underline;
}
.story-card .story-image-container {
  height: 0;
  padding-top: 100%;
  position: relative;
  transition: filter 0.2s ease-in-out;
  width: 100%;
}
.story-card .story-image-inner {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.story-card .story-image-inner img {
  font-family: "object-fit:cover; object-position: center;";
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}
.story-card .story-content {
  padding: 1.75rem 2.25rem;
}
.story-card .story-subject {
  font-size: 1rem;
  line-height: 1.125;
  color: #000;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
}
.story-card .story-title {
  font-size: 2rem;
  line-height: 1.1875;
  border-bottom: 2px solid #707070;
  color: #000;
  font-family: "IBM Plex Serif", serif;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 1;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.story-card .story-description {
  font-size: 1rem;
  line-height: 1.375;
  color: #000;
  font-family: "IBM Plex Serif", serif;
  font-weight: 400;
}

.story-hero .moments-brand {
  display: none;
}
.story-hero .hero-media {
  align-items: center;
  display: flex;
  height: auto;
  min-height: calc(80vh - 70px);
  margin-bottom: 0;
  position: relative;
}
.story-hero .hero-media .hero-img,
.story-hero .hero-media .video-img-btn {
  height: calc(80vh - 70px);
  min-height: 400px;
  width: 100%;
}
.story-hero .hero-media .hero-img img,
.story-hero .hero-media .hero-img source,
.story-hero .hero-media .video-img-btn img,
.story-hero .hero-media .video-img-btn source {
  border: none;
  font-family: "object-fit: cover; object-position: center center;";
  height: 100%;
  min-height: 400px;
  object-fit: cover;
  object-position: center center;
  width: 100%;
}
.story-hero .hero-media .video-img-btn {
  border: 0;
  height: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
}
.story-hero .hero-media .video-img-btn:after {
  border-bottom: 35px solid transparent;
  border-left: 70px solid rgba(255, 255, 255, 0.5);
  border-top: 35px solid transparent;
  content: "";
  display: block;
  height: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: border-color 0.2s ease-in-out;
  width: 0;
}
.story-hero .hero-media .video-img-btn.playing {
  display: none;
}
.story-hero .hero-media .video {
  min-height: 400px;
  width: 100%;
}
.story-hero .hero-media .video .wistia_embed {
  align-items: center;
  display: flex;
  min-height: 400px;
}
.story-hero .media-item {
  margin: 0;
  padding: 0;
  width: 100%;
}
@media (min-width: 992px) {
  .story-hero .hero-outer {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 70px);
    justify-content: space-between;
    padding: 0;
    padding-bottom: 7.5vh;
    padding-top: 7.5vh;
  }
}
@media (min-width: 992px) and (min-width: 576px) {
  .story-hero .hero-outer {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) and (min-width: 768px) {
  .story-hero .hero-outer {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .story-hero .hero-outer {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  .story-hero .hero-outer {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .story-hero .moments-brand {
    display: block;
  }
  .story-hero .moments-brand img {
    height: 7vh;
    margin-bottom: 1.5rem;
    max-width: 500px;
    min-height: 50px;
  }
}
@media (min-width: 992px) {
  .story-hero .hero-media {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    height: auto;
    min-height: 65vh;
    width: calc(100% + 30px);
  }
  .story-hero .hero-media .hero-img,
.story-hero .hero-media .video {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
    height: 100%;
    margin: auto;
    text-align: center;
    width: auto;
  }
  .story-hero .hero-media .hero-img img,
.story-hero .hero-media .hero-img source,
.story-hero .hero-media .video img,
.story-hero .hero-media .video source {
    font-family: "object-fit: contain; object-position: center center;";
    height: auto;
    margin: auto;
    max-width: 100%;
    object-fit: contain;
    object-position: center center;
    width: auto;
  }
  .story-hero .hero-media .hero-img .wistia_embed,
.story-hero .hero-media .video .wistia_embed {
    display: block;
    min-height: none;
  }
  .story-hero .hero-media .video-img-btn {
    display: none;
  }
}

.story-list {
  background-color: #1f1c17;
  padding-bottom: 1.5rem;
  padding-top: 15px;
}
.story-list.expanded .story-card {
  display: block;
}
.story-list.expanded .view-more-btn {
  display: none;
}
.story-list .story-list-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .story-list .story-list-container {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .story-list .story-list-container {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 992px) {
  .story-list .story-list-container {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 1200px) {
  .story-list .story-list-container {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
.story-list .story-listings {
  margin: 0;
  padding: 0;
}
.story-list .story-card {
  display: none;
  margin: 0;
  margin-bottom: 1.25rem;
}
.story-list .story-card:before {
  display: none;
}
.story-list .story-card:not(:last-of-type) {
  margin-bottom: 1.25rem;
}
.story-list .view-more-btn {
  align-items: center;
  background-color: #fcb930;
  border-color: #fcb930;
  border-radius: 0;
  color: #fff;
  display: flex;
  font-family: "IBM Plex Serif", serif;
  font-size: 26px;
  font-weight: 400;
  height: 110px;
  justify-content: center;
  margin-bottom: 1.25rem;
  text-decoration: none;
  width: 100%;
}
.story-list .view-more-btn:hover, .story-list .view-more-btn:focus {
  background-color: #000;
  border-color: #000;
}
@media (min-width: 768px) {
  .story-list .story-listings {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .story-list .story-card {
    flex: 0 1 calc(50% - 0.625rem);
    max-width: calc(50% - 0.625rem);
  }
  .story-list .view-more-btn {
    font-size: 30px;
  }
}
@media (min-width: 992px) {
  .story-list {
    padding-top: 0;
  }
  .story-list .story-listings {
    margin-top: -110px;
  }
  .story-list .story-listings:after {
    content: " ";
    flex: 0 1 calc(33.3333% - 13.3333333333px);
    max-width: calc(33.3333% - 13.3333333333px);
  }
  .story-list .story-card {
    flex: 0 1 calc(33.3333% - 13.3333333333px);
    max-width: calc(33.3333% - 13.3333333333px);
  }
}
@media (min-width: 1200px) {
  .story-list .story-listings:before {
    content: " ";
    flex: 0 1 calc(25% - 15px);
    max-width: calc(25% - 15px);
    order: 1;
  }
  .story-list .story-listings:after {
    content: " ";
    flex: 0 1 calc(25% - 15px);
    max-width: calc(25% - 15px);
  }
  .story-list .story-card {
    flex: 0 1 calc(25% - 15px);
    max-width: calc(25% - 15px);
  }
}

.advance {
  /* Elements */
  /* BUTTON */
  /* SVGs */
  /* SCROLLBAR */
  /** SPACINGS **/
  /** BACKGROUNDS **/
  /** blue **/
  /** gray **/
  /** teal **/
  /** yellow **/
  /** gray **/
  /** teal **/
  /** yellow **/
  /* extend color left */
  /* breadcrumbs */
}
.advance img {
  display: block;
  max-width: 100%;
}
.advance a {
  color: #3579ad;
  transition: color 0.2s;
}
.advance a:hover {
  color: #00364a;
}
.advance .btn, .advance .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .advance .mktoButton {
  padding: 9px 18px 7px;
  font-size: 14px;
  line-height: initial;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  /* button size */
  /* button color */
}
.advance .btn:hover, .advance .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .advance .mktoButton:hover {
  text-decoration: none;
}
.advance .btn.btn-lg, .advance .marketo-form .mktoForm .btn-lg.mktoButton, .marketo-form .mktoForm .advance .btn-lg.mktoButton, .advance .btn-group-lg > .btn, .advance .marketo-form .mktoForm .btn-group-lg > .mktoButton, .marketo-form .mktoForm .advance .btn-group-lg > .mktoButton {
  padding: 10px 20px;
  font-size: 16px;
  line-height: 26px;
}
.advance .btn.btn-md, .advance .marketo-form .mktoForm .btn-md.mktoButton, .marketo-form .mktoForm .advance .btn-md.mktoButton {
  margin-bottom: -6px;
  font-size: 14px;
  line-height: 26px;
  padding: 10px 20px;
}
.advance .btn.btn-sm, .advance .marketo-form .mktoForm .btn-sm.mktoButton, .marketo-form .mktoForm .advance .btn-sm.mktoButton, .advance .btn-group-sm > .btn, .advance .marketo-form .mktoForm .btn-group-sm > .mktoButton, .marketo-form .mktoForm .advance .btn-group-sm > .mktoButton {
  padding: 4px 18px 4px;
  font-size: 14px;
  line-height: 26px;
}
.advance .btn.btn-primary, .advance .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .advance .mktoButton {
  color: #212529;
  background-color: #f7aa00;
  border-color: #f7aa00;
}
.advance .btn.btn-primary:hover, .advance .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .advance .mktoButton:hover {
  color: #fff;
  background-color: #00364a;
  border-color: #00364a;
}
.advance .btn.btn-primary:focus, .advance .marketo-form .mktoForm .mktoButton:focus, .marketo-form .mktoForm .advance .mktoButton:focus, .advance .btn.btn-primary.focus, .advance .marketo-form .mktoForm .focus.mktoButton, .marketo-form .mktoForm .advance .focus.mktoButton {
  color: #fff;
  background-color: #00364a;
  border-color: #00364a;
  box-shadow: 0 0 0 0.2rem rgba(215, 150, 6, 0.5);
}
.advance .btn.btn-primary.disabled, .advance .marketo-form .mktoForm .disabled.mktoButton, .marketo-form .mktoForm .advance .disabled.mktoButton, .advance .btn.btn-primary:disabled, .advance .marketo-form .mktoForm .mktoButton:disabled, .marketo-form .mktoForm .advance .mktoButton:disabled {
  color: #212529;
  background-color: #f7aa00;
  border-color: #f7aa00;
}
.advance .btn.btn-primary:not(:disabled):not(.disabled):active, .advance .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .advance .mktoButton:not(:disabled):not(.disabled):active, .advance .btn.btn-primary:not(:disabled):not(.disabled).active, .advance .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .advance .mktoButton:not(:disabled):not(.disabled).active, .show > .advance .btn.btn-primary.dropdown-toggle, .show > .advance .marketo-form .mktoForm .dropdown-toggle.mktoButton, .marketo-form .mktoForm .show > .advance .dropdown-toggle.mktoButton {
  color: #fff;
  background-color: #c48700;
  border-color: #b77e00;
}
.advance .btn.btn-primary:not(:disabled):not(.disabled):active:focus, .advance .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .advance .mktoButton:not(:disabled):not(.disabled):active:focus, .advance .btn.btn-primary:not(:disabled):not(.disabled).active:focus, .advance .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .advance .mktoButton:not(:disabled):not(.disabled).active:focus, .show > .advance .btn.btn-primary.dropdown-toggle:focus, .show > .advance .marketo-form .mktoForm .dropdown-toggle.mktoButton:focus, .marketo-form .mktoForm .show > .advance .dropdown-toggle.mktoButton:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 150, 6, 0.5);
}
.advance .btn.btn-primary:hover, .advance .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .advance .mktoButton:hover {
  color: #fff;
}
.advance .btn.btn-secondary, .advance .marketo-form .mktoForm .btn-secondary.mktoButton, .marketo-form .mktoForm .advance .btn-secondary.mktoButton {
  color: #212529;
  background-color: #47d2d7;
  border-color: #47d2d7;
}
.advance .btn.btn-secondary:hover, .advance .marketo-form .mktoForm .btn-secondary.mktoButton:hover, .marketo-form .mktoForm .advance .btn-secondary.mktoButton:hover {
  color: #fff;
  background-color: #00364a;
  border-color: #00364a;
}
.advance .btn.btn-secondary:focus, .advance .marketo-form .mktoForm .btn-secondary.mktoButton:focus, .marketo-form .mktoForm .advance .btn-secondary.mktoButton:focus, .advance .btn.btn-secondary.focus, .advance .marketo-form .mktoForm .btn-secondary.focus.mktoButton, .marketo-form .mktoForm .advance .btn-secondary.focus.mktoButton {
  color: #fff;
  background-color: #00364a;
  border-color: #00364a;
  box-shadow: 0 0 0 0.2rem rgba(65, 184, 189, 0.5);
}
.advance .btn.btn-secondary.disabled, .advance .marketo-form .mktoForm .btn-secondary.disabled.mktoButton, .marketo-form .mktoForm .advance .btn-secondary.disabled.mktoButton, .advance .btn.btn-secondary:disabled, .advance .marketo-form .mktoForm .btn-secondary.mktoButton:disabled, .marketo-form .mktoForm .advance .btn-secondary.mktoButton:disabled {
  color: #212529;
  background-color: #47d2d7;
  border-color: #47d2d7;
}
.advance .btn.btn-secondary:not(:disabled):not(.disabled):active, .advance .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .advance .btn-secondary.mktoButton:not(:disabled):not(.disabled):active, .advance .btn.btn-secondary:not(:disabled):not(.disabled).active, .advance .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .advance .btn-secondary.mktoButton:not(:disabled):not(.disabled).active, .show > .advance .btn.btn-secondary.dropdown-toggle, .show > .advance .marketo-form .mktoForm .btn-secondary.dropdown-toggle.mktoButton, .marketo-form .mktoForm .show > .advance .btn-secondary.dropdown-toggle.mktoButton {
  color: #fff;
  background-color: #2abcc1;
  border-color: #28b2b7;
}
.advance .btn.btn-secondary:not(:disabled):not(.disabled):active:focus, .advance .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .advance .btn-secondary.mktoButton:not(:disabled):not(.disabled):active:focus, .advance .btn.btn-secondary:not(:disabled):not(.disabled).active:focus, .advance .marketo-form .mktoForm .btn-secondary.mktoButton:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .advance .btn-secondary.mktoButton:not(:disabled):not(.disabled).active:focus, .show > .advance .btn.btn-secondary.dropdown-toggle:focus, .show > .advance .marketo-form .mktoForm .btn-secondary.dropdown-toggle.mktoButton:focus, .marketo-form .mktoForm .show > .advance .btn-secondary.dropdown-toggle.mktoButton:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 184, 189, 0.5);
}
.advance .btn.btn-tertiary, .advance .marketo-form .mktoForm .btn-tertiary.mktoButton, .marketo-form .mktoForm .advance .btn-tertiary.mktoButton {
  color: #212529;
  background-color: #eff3f7;
  border-color: #eff3f7;
  color: #00364a;
}
.advance .btn.btn-tertiary:hover, .advance .marketo-form .mktoForm .btn-tertiary.mktoButton:hover, .marketo-form .mktoForm .advance .btn-tertiary.mktoButton:hover {
  color: #212529;
  background-color: #c5d6df;
  border-color: #c5d6df;
}
.advance .btn.btn-tertiary:focus, .advance .marketo-form .mktoForm .btn-tertiary.mktoButton:focus, .marketo-form .mktoForm .advance .btn-tertiary.mktoButton:focus, .advance .btn.btn-tertiary.focus, .advance .marketo-form .mktoForm .btn-tertiary.focus.mktoButton, .marketo-form .mktoForm .advance .btn-tertiary.focus.mktoButton {
  color: #212529;
  background-color: #c5d6df;
  border-color: #c5d6df;
  box-shadow: 0 0 0 0.2rem rgba(208, 212, 216, 0.5);
}
.advance .btn.btn-tertiary.disabled, .advance .marketo-form .mktoForm .btn-tertiary.disabled.mktoButton, .marketo-form .mktoForm .advance .btn-tertiary.disabled.mktoButton, .advance .btn.btn-tertiary:disabled, .advance .marketo-form .mktoForm .btn-tertiary.mktoButton:disabled, .marketo-form .mktoForm .advance .btn-tertiary.mktoButton:disabled {
  color: #212529;
  background-color: #eff3f7;
  border-color: #eff3f7;
}
.advance .btn.btn-tertiary:not(:disabled):not(.disabled):active, .advance .marketo-form .mktoForm .btn-tertiary.mktoButton:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .advance .btn-tertiary.mktoButton:not(:disabled):not(.disabled):active, .advance .btn.btn-tertiary:not(:disabled):not(.disabled).active, .advance .marketo-form .mktoForm .btn-tertiary.mktoButton:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .advance .btn-tertiary.mktoButton:not(:disabled):not(.disabled).active, .show > .advance .btn.btn-tertiary.dropdown-toggle, .show > .advance .marketo-form .mktoForm .btn-tertiary.dropdown-toggle.mktoButton, .marketo-form .mktoForm .show > .advance .btn-tertiary.dropdown-toggle.mktoButton {
  color: #212529;
  background-color: #cddae6;
  border-color: #c5d3e2;
}
.advance .btn.btn-tertiary:not(:disabled):not(.disabled):active:focus, .advance .marketo-form .mktoForm .btn-tertiary.mktoButton:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .advance .btn-tertiary.mktoButton:not(:disabled):not(.disabled):active:focus, .advance .btn.btn-tertiary:not(:disabled):not(.disabled).active:focus, .advance .marketo-form .mktoForm .btn-tertiary.mktoButton:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .advance .btn-tertiary.mktoButton:not(:disabled):not(.disabled).active:focus, .show > .advance .btn.btn-tertiary.dropdown-toggle:focus, .show > .advance .marketo-form .mktoForm .btn-tertiary.dropdown-toggle.mktoButton:focus, .marketo-form .mktoForm .show > .advance .btn-tertiary.dropdown-toggle.mktoButton:focus {
  box-shadow: 0 0 0 0.2rem rgba(208, 212, 216, 0.5);
}
.advance .btn.btn-link, .advance .marketo-form .mktoForm .btn-link.mktoButton, .marketo-form .mktoForm .advance .btn-link.mktoButton {
  color: #212529;
  background-color: #eff3f7;
  border-color: #eff3f7;
  border: 1px #c5d6df solid;
  color: #00364a;
}
.advance .btn.btn-link:hover, .advance .marketo-form .mktoForm .btn-link.mktoButton:hover, .marketo-form .mktoForm .advance .btn-link.mktoButton:hover {
  color: #fff;
  background-color: #00364a;
  border-color: #00364a;
}
.advance .btn.btn-link:focus, .advance .marketo-form .mktoForm .btn-link.mktoButton:focus, .marketo-form .mktoForm .advance .btn-link.mktoButton:focus, .advance .btn.btn-link.focus, .advance .marketo-form .mktoForm .btn-link.focus.mktoButton, .marketo-form .mktoForm .advance .btn-link.focus.mktoButton {
  color: #fff;
  background-color: #00364a;
  border-color: #00364a;
  box-shadow: 0 0 0 0.2rem rgba(208, 212, 216, 0.5);
}
.advance .btn.btn-link.disabled, .advance .marketo-form .mktoForm .btn-link.disabled.mktoButton, .marketo-form .mktoForm .advance .btn-link.disabled.mktoButton, .advance .btn.btn-link:disabled, .advance .marketo-form .mktoForm .btn-link.mktoButton:disabled, .marketo-form .mktoForm .advance .btn-link.mktoButton:disabled {
  color: #212529;
  background-color: #eff3f7;
  border-color: #eff3f7;
}
.advance .btn.btn-link:not(:disabled):not(.disabled):active, .advance .marketo-form .mktoForm .btn-link.mktoButton:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .advance .btn-link.mktoButton:not(:disabled):not(.disabled):active, .advance .btn.btn-link:not(:disabled):not(.disabled).active, .advance .marketo-form .mktoForm .btn-link.mktoButton:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .advance .btn-link.mktoButton:not(:disabled):not(.disabled).active, .show > .advance .btn.btn-link.dropdown-toggle, .show > .advance .marketo-form .mktoForm .btn-link.dropdown-toggle.mktoButton, .marketo-form .mktoForm .show > .advance .btn-link.dropdown-toggle.mktoButton {
  color: #212529;
  background-color: #cddae6;
  border-color: #c5d3e2;
}
.advance .btn.btn-link:not(:disabled):not(.disabled):active:focus, .advance .marketo-form .mktoForm .btn-link.mktoButton:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .advance .btn-link.mktoButton:not(:disabled):not(.disabled):active:focus, .advance .btn.btn-link:not(:disabled):not(.disabled).active:focus, .advance .marketo-form .mktoForm .btn-link.mktoButton:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .advance .btn-link.mktoButton:not(:disabled):not(.disabled).active:focus, .show > .advance .btn.btn-link.dropdown-toggle:focus, .show > .advance .marketo-form .mktoForm .btn-link.dropdown-toggle.mktoButton:focus, .marketo-form .mktoForm .show > .advance .btn-link.dropdown-toggle.mktoButton:focus {
  box-shadow: 0 0 0 0.2rem rgba(208, 212, 216, 0.5);
}
.advance .btn.btn-link:hover, .advance .marketo-form .mktoForm .btn-link.mktoButton:hover, .marketo-form .mktoForm .advance .btn-link.mktoButton:hover {
  background: #00364a;
  color: #fff;
}
.advance .btn.btn-link svg, .advance .marketo-form .mktoForm .btn-link.mktoButton svg, .marketo-form .mktoForm .advance .btn-link.mktoButton svg {
  margin: -3px -9px -2px 7px;
  height: 28px;
  width: 28px;
  fill: #47d2d7;
  color: #00364a;
}
.advance .btn.btn-link.btn-sm svg, .advance .marketo-form .mktoForm .btn-link.btn-sm.mktoButton svg, .marketo-form .mktoForm .advance .btn-link.btn-sm.mktoButton svg, .advance .btn-group-sm > .btn.btn-link svg, .advance .marketo-form .mktoForm .btn-group-sm > .btn-link.mktoButton svg, .marketo-form .mktoForm .advance .btn-group-sm > .btn-link.mktoButton svg {
  margin: -2px -7px 0 5px;
  height: 22px;
  width: 22px;
}
.advance .advance--bk--dark-teal .btn-primary:hover, .advance .advance--bk--dark-teal .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .advance .advance--bk--dark-teal .mktoButton:hover,
.advance .advance--bk--dark-teal .btn-secondary:hover {
  background: #eff3f7;
  color: #00364a;
}
.advance .advance--bk--dark-teal .btn-tertiary {
  background: #fff;
}
.advance .advance--bk--dark-teal .btn-tertiary:hover {
  background: #eff3f7;
}
@media (min-width: 992px) {
  .advance .btn, .advance .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .advance .mktoButton {
    padding: 10px 20px;
    font-size: 16px;
    line-height: 26px;
  }
}
.advance main svg {
  fill: #00364a;
  stroke-width: 0;
}
.advance main svg path,
.advance main svg circle {
  stroke-width: 0;
}
.advance main svg.icon-white {
  fill: #fff;
  color: #00364a;
}
.advance ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.advance ::-webkit-scrollbar-track {
  background-color: #c5d6df;
}
.advance ::-webkit-scrollbar-thumb {
  background-color: #19647e;
  background-clip: padding-box;
  border: 15px solid rgba(0, 0, 0, 0);
}
.advance .advance--bk--dark-teal + .advance--bk--dark-teal,
.advance .advance--bk--white + .advance--bk--white {
  padding-top: 0 !important;
}
.advance .advance--bk--dark-teal {
  background-color: #00364a;
  color: #fff;
}
.advance .advance--bk--dark-teal h1,
.advance .advance--bk--dark-teal p,
.advance .advance--bk--dark-teal span {
  color: #fff;
}
.advance .advance--bk--gray {
  background-color: #eff3f7;
}
.advance .advance--bk--teal {
  background-color: #47d2d7;
}
.advance .advance--bk--yellow {
  background-color: #f7aa00;
}
.advance .advance--txt--gray {
  color: #eff3f7 !important;
}
.advance .advance--txt--teal {
  color: #47d2d7;
}
.advance .advance--txt--dark-teal {
  color: #00364a;
}
.advance .advance--txt--mid-bluegrey {
  color: #4d7489;
}
.advance .advance--txt--yellow {
  color: #f7aa00;
}
.advance .extend-left::after {
  position: absolute;
  width: 50vw;
  top: 0;
  content: "";
  height: 100%;
  right: 100%;
  background: #00364a;
}
.advance .breadcrumb {
  background: transparent;
  padding: 8px 0 10px 0;
  border-radius: 0;
}
.advance .breadcrumb .breadcrumb-item {
  display: block;
  padding-left: 5px;
  font-size: 16px;
}
.advance .breadcrumb .breadcrumb-item::before {
  display: inline-block;
  content: "/";
  padding: 0 5px 0 1px;
  color: #006366;
}
.advance .breadcrumb .breadcrumb-item:first-child {
  padding-left: 0;
}
.advance .breadcrumb .breadcrumb-item:first-child::before {
  display: none;
}
.advance .breadcrumb .breadcrumb-item .icon {
  display: none;
}
.advance .breadcrumb a {
  color: #fff;
}

.advance {
  /*TYPOGRAPHY */
}
.advance main {
  color: #3f596c;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.advance .blue {
  color: #19647e;
}
.advance h1,
.advance .heading-1,
.advance .heading-1-sans {
  font-size: 24px;
  line-height: 34px;
  font-family: "Merriweather", serif;
  font-weight: 500;
  color: #00364a;
}
.advance h2,
.advance .heading-2,
.advance .heading-2-sans {
  font-size: 20px;
  line-height: 27px;
  font-family: "Merriweather", serif;
  font-weight: 600;
  color: #00364a;
}
.advance h3,
.advance .heading-3,
.advance .heading-3-sans {
  font-size: 18px;
  line-height: 28px;
  font-family: "Merriweather", serif;
  font-weight: 600;
  color: #00364a;
}
.advance h4,
.advance .heading-4,
.advance .heading-4-sans {
  font-size: 16px;
  line-height: 26px;
  font-family: "Merriweather", serif;
  font-weight: 600;
  color: #00364a;
}
.advance .heading-4-sans {
  line-height: 22px;
}
.advance h5,
.advance .heading-5,
.advance .heading-5-sans {
  font-size: 14px;
  line-height: initial;
  font-family: "Merriweather", serif;
  font-weight: 600;
  color: #00364a;
}
.advance .heading-1-sans,
.advance .heading-2-sans,
.advance .heading-3-sans,
.advance .heading-4-sans,
.advance .heading-5-sans {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.advance .body-lg,
.advance .body-lg p {
  font-size: 18px;
  line-height: 28px;
  font-family: "Open Sans", sans-serif;
}
.advance .body-lg ul > li:before,
.advance .body-lg p ul > li:before {
  margin-left: -1.4rem;
}
.advance .body-md,
.advance .body-md p {
  font-size: 16px;
  line-height: 26px;
  font-family: "Open Sans", sans-serif;
}
.advance .body-sm,
.advance .body-sm p {
  font-size: 14px;
  line-height: initial;
  font-family: "Open Sans", sans-serif;
}
.advance .body-md h1,
.advance .body-md h2,
.advance .body-md h3,
.advance .body-md h4,
.advance .body-md h5,
.advance .body-lg h1,
.advance .body-lg h2,
.advance .body-lg h3,
.advance .body-lg h4,
.advance .body-lg h5 {
  margin-bottom: 0.55em;
}
.advance label,
.advance .label {
  font-size: 12px;
  line-height: initial;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.advance p {
  font-size: 1.2rem;
  font-family: "Open Sans", sans-serif;
}
.advance .marketing p {
  font-size: 1.25rem;
  line-height: 1.2;
}
.advance .copyright p {
  font-size: 0.75rem;
  line-height: 1.1666666667;
}
@media (min-width: 992px) {
  .advance h1,
.advance .heading-1,
.advance .heading-1-sans {
    font-size: 36px;
    line-height: 48px;
  }
  .advance h2,
.advance .heading-2,
.advance .heading-2-sans {
    font-size: 24px;
    line-height: 34px;
  }
  .advance .heading-2-sans {
    line-height: auto;
  }
}

.advance main form,
.advance main .marketo-form .mktoForm,
.advance main .input-icon-container {
  font-family: "Open Sans", sans-serif;
  row-gap: 1px;
}
.advance main form label,
.advance main .marketo-form .mktoForm label,
.advance main .input-icon-container label {
  font-size: 16px;
  line-height: initial;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #4d7489;
  margin-bottom: 12px;
}
.advance main form input,
.advance main form textarea,
.advance main form select,
.advance main .marketo-form .mktoForm input,
.advance main .marketo-form .mktoForm textarea,
.advance main .marketo-form .mktoForm select,
.advance main .input-icon-container input,
.advance main .input-icon-container textarea,
.advance main .input-icon-container select {
  background: #eff3f7;
  height: 50px;
  border-radius: 8px;
  appearance: none;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  line-height: 50px;
  padding: 0 20px 0 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px #99b4c3 solid;
}
.advance main form input:focus,
.advance main form textarea:focus,
.advance main form select:focus,
.advance main .marketo-form .mktoForm input:focus,
.advance main .marketo-form .mktoForm textarea:focus,
.advance main .marketo-form .mktoForm select:focus,
.advance main .input-icon-container input:focus,
.advance main .input-icon-container textarea:focus,
.advance main .input-icon-container select:focus {
  border: 1px #4d7489 solid;
}
.advance main form input:focus + .icon, .advance main form input:focus + button .icon, .advance main form input + button:focus .icon,
.advance main form textarea:focus + .icon,
.advance main form textarea:focus + button .icon,
.advance main form textarea + button:focus .icon,
.advance main form select:focus + .icon,
.advance main form select:focus + button .icon,
.advance main form select + button:focus .icon,
.advance main .marketo-form .mktoForm input:focus + .icon,
.advance main .marketo-form .mktoForm input:focus + button .icon,
.advance main .marketo-form .mktoForm input + button:focus .icon,
.advance main .marketo-form .mktoForm textarea:focus + .icon,
.advance main .marketo-form .mktoForm textarea:focus + button .icon,
.advance main .marketo-form .mktoForm textarea + button:focus .icon,
.advance main .marketo-form .mktoForm select:focus + .icon,
.advance main .marketo-form .mktoForm select:focus + button .icon,
.advance main .marketo-form .mktoForm select + button:focus .icon,
.advance main .input-icon-container input:focus + .icon,
.advance main .input-icon-container input:focus + button .icon,
.advance main .input-icon-container input + button:focus .icon,
.advance main .input-icon-container textarea:focus + .icon,
.advance main .input-icon-container textarea:focus + button .icon,
.advance main .input-icon-container textarea + button:focus .icon,
.advance main .input-icon-container select:focus + .icon,
.advance main .input-icon-container select:focus + button .icon,
.advance main .input-icon-container select + button:focus .icon {
  fill: #00364a;
}
.advance main form textarea,
.advance main .marketo-form .mktoForm textarea,
.advance main .input-icon-container textarea {
  line-height: 20px;
  padding: 15px 20px 15px 20px;
}
.advance main form input[type=radio],
.advance main form input[type=checkbox],
.advance main .marketo-form .mktoForm input[type=radio],
.advance main .marketo-form .mktoForm input[type=checkbox],
.advance main .input-icon-container input[type=radio],
.advance main .input-icon-container input[type=checkbox] {
  height: 24px;
  width: 24px;
  margin-right: 16px;
  padding: 0;
  background: #fff;
}
.advance main form input[type=radio] + label:before,
.advance main form input[type=checkbox] + label:before,
.advance main .marketo-form .mktoForm input[type=radio] + label:before,
.advance main .marketo-form .mktoForm input[type=checkbox] + label:before,
.advance main .input-icon-container input[type=radio] + label:before,
.advance main .input-icon-container input[type=checkbox] + label:before {
  content: "";
  height: 24px;
  width: 24px;
  border: 2px #99b4c3 solid;
}
.advance main form input[type=radio]:checked + label:after,
.advance main form input[type=checkbox]:checked + label:after,
.advance main .marketo-form .mktoForm input[type=radio]:checked + label:after,
.advance main .marketo-form .mktoForm input[type=checkbox]:checked + label:after,
.advance main .input-icon-container input[type=radio]:checked + label:after,
.advance main .input-icon-container input[type=checkbox]:checked + label:after {
  height: 1rem;
  left: 2px;
}
.advance main form input[type=radio]:hover + label:before, .advance main form input[type=radio]:focus + label:before,
.advance main form input[type=checkbox]:hover + label:before,
.advance main form input[type=checkbox]:focus + label:before,
.advance main .marketo-form .mktoForm input[type=radio]:hover + label:before,
.advance main .marketo-form .mktoForm input[type=radio]:focus + label:before,
.advance main .marketo-form .mktoForm input[type=checkbox]:hover + label:before,
.advance main .marketo-form .mktoForm input[type=checkbox]:focus + label:before,
.advance main .input-icon-container input[type=radio]:hover + label:before,
.advance main .input-icon-container input[type=radio]:focus + label:before,
.advance main .input-icon-container input[type=checkbox]:hover + label:before,
.advance main .input-icon-container input[type=checkbox]:focus + label:before {
  border-color: #47d2d7;
}
.advance main form input[type=radio],
.advance main .marketo-form .mktoForm input[type=radio],
.advance main .input-icon-container input[type=radio] {
  border-radius: 12px;
}
.advance main form input[type=radio]:checked + label:after,
.advance main .marketo-form .mktoForm input[type=radio]:checked + label:after,
.advance main .input-icon-container input[type=radio]:checked + label:after {
  height: 1rem;
  left: 0px;
  width: 1rem;
}
.advance main form .input-icon-container,
.advance main .marketo-form .mktoForm .input-icon-container,
.advance main .input-icon-container .input-icon-container {
  background: #eff3f7 !important;
  border-radius: 8px;
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.advance main form .input-icon-container input,
.advance main form .input-icon-container textarea,
.advance main form .input-icon-container select,
.advance main .marketo-form .mktoForm .input-icon-container input,
.advance main .marketo-form .mktoForm .input-icon-container textarea,
.advance main .marketo-form .mktoForm .input-icon-container select,
.advance main .input-icon-container .input-icon-container input,
.advance main .input-icon-container .input-icon-container textarea,
.advance main .input-icon-container .input-icon-container select {
  padding-right: 55px;
}
.advance main form .input-icon-container .icon-btn,
.advance main .marketo-form .mktoForm .input-icon-container .icon-btn,
.advance main .input-icon-container .input-icon-container .icon-btn {
  top: 0;
  right: 0;
}
.advance main form .input-icon-container .icon,
.advance main .marketo-form .mktoForm .input-icon-container .icon,
.advance main .input-icon-container .input-icon-container .icon {
  width: 30px;
  height: 30px;
  transform: rotate(90deg);
  transform-origin: center;
  position: absolute;
  top: 10px;
  right: 15px;
  color: #fff;
  fill: #4d7489;
}
.advance main form .input-icon-container .icon.icon-search-circle,
.advance main .marketo-form .mktoForm .input-icon-container .icon.icon-search-circle,
.advance main .input-icon-container .input-icon-container .icon.icon-search-circle {
  transform: none;
}
.advance main form .input-icon-container--sm,
.advance main .marketo-form .mktoForm .input-icon-container--sm,
.advance main .input-icon-container .input-icon-container--sm {
  display: inline-block;
}
.advance main form .input-icon-container--sm input,
.advance main form .input-icon-container--sm select,
.advance main .marketo-form .mktoForm .input-icon-container--sm input,
.advance main .marketo-form .mktoForm .input-icon-container--sm select,
.advance main .input-icon-container .input-icon-container--sm input,
.advance main .input-icon-container .input-icon-container--sm select {
  height: 36px;
  padding: 5px 46px 5px 18px;
  line-height: 26px;
  font-size: 12px;
  font-weight: bold;
}
.advance main form .input-icon-container--sm .icon,
.advance main .marketo-form .mktoForm .input-icon-container--sm .icon,
.advance main .input-icon-container .input-icon-container--sm .icon {
  width: 22px;
  height: 22px;
  top: 7px;
  right: 18px;
}
@media (min-width: 992px) {
  .advance main form,
.advance main .input-icon-container {
    row-gap: 2px;
  }
}

.advance .video .wistia_embed .w-bottom-bar-left,
.advance .video .wistia_embed .w-bottom-bar-middle,
.advance .video .wistia_embed .w-bottom-bar-right {
  background-color: rgb(71, 210, 215) !important;
}
.advance .video .wistia_embed .w-bottom-bar-left .w-slider-wrapper,
.advance .video .wistia_embed .w-bottom-bar-middle .w-slider-wrapper,
.advance .video .wistia_embed .w-bottom-bar-right .w-slider-wrapper {
  background-color: rgba(71, 210, 215, 0.4) !important;
}
.advance .video .wistia_embed .w-bpb-wrapper {
  border-width: 0 !important;
}
.advance .video .wistia_embed .w-bpb-wrapper button div {
  background-color: #47d2d7 !important;
  mix-blend-mode: normal !important;
}
.advance .video .wistia_embed .w-bpb-wrapper button div:hover div {
  background-color: #00364a !important;
  color: #fff;
}
.advance .video .wistia_embed .w-bpb-wrapper button svg {
  height: 85px !important;
  width: 125px !important;
  left: -22px !important;
}
@media (min-width: 576px) {
  .advance .video .wistia_embed .w-bpb-wrapper button svg {
    height: 115px !important;
    width: 180px !important;
    left: -33px !important;
  }
}
.advance .video .wistia_embed .w-bpb-wrapper button svg polygon {
  fill: #00364a;
}
.advance .video .wistia_embed div[data-handle=playbarlessProgressIndicator] div div div {
  background-color: #47d2d7 !important;
}

.advance .advance--article-card {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 -20px 50px;
  position: relative;
}
.advance .advance--article-card::after {
  content: "";
  position: absolute;
  background: #c5d6df;
  top: 0;
  bottom: 0;
  left: 0;
  width: 6px;
}
.advance .advance--article-card::before {
  content: "";
  position: absolute;
  background: #c5d6df;
  top: 100%;
  left: 15px;
  right: 15px;
  height: 1px;
  margin-top: 9px;
}
.advance .advance--article-card .advance--article-card--content .advance--article-card--image {
  width: 100%;
  position: relative;
}
.advance .advance--article-card .advance--article-card--content .advance--article-card--image img {
  max-height: 46.9vw;
  width: 100%;
  display: block;
  object-fit: cover;
  flex: 0 0 auto;
}
.advance .advance--article-card .advance--article-card--content .advance--article-card--info {
  padding: 14px 0 20px 0;
}
.advance .advance--article-card .advance--article-card--content .advance--article-card--info .advance--article-card--date {
  padding-bottom: 18px;
  color: #4d7489;
}
.advance .advance--article-card .advance--article-card--content .advance--article-card--info .advance--article-card--title {
  padding-bottom: 2px;
}
.advance .advance--article-card .advance--article-card--content .advance--article-card--info .advance--article-card--desc p {
  margin: 0 0 6px;
}
.advance .advance--article-card .advance--article-card--content .advance--article-card--info .advance--article-card--btn {
  padding-top: 10px;
}
@media (min-width: 768px) {
  .advance .advance--article-card {
    padding-left: 29px;
    padding-right: 0;
    margin: 0 0 2px;
    min-height: 412px;
  }
  .advance .advance--article-card::after {
    width: 6px;
  }
  .advance .advance--article-card::before {
    margin-top: 20px;
    left: 0;
    right: 0;
  }
  .advance .advance--article-card .advance--article-card--content .advance--article-card--image img {
    max-height: 14.1vw;
  }
  .advance .advance--article-card .advance--article-card--content .advance--article-card--info {
    padding: 20px 0 25px 0;
  }
  .advance .advance--article-card .advance--article-card--content .advance--article-card--info .advance--article-card--title {
    padding-bottom: 1px;
  }
  .advance .advance--article-card .advance--article-card--content .advance--article-card--info .advance--article-card--title * {
    font-size: 18px;
    line-height: 28px;
  }
  .advance .advance--article-card .advance--article-card--content .advance--article-card--info .advance--article-card--btn {
    padding-top: 13px;
  }
}

.advance .advance--article-cta {
  padding-top: 36px;
  padding-bottom: 36px;
}
.advance .advance--article-cta .card {
  border-left-width: 0;
  border-right-width: 0;
  border-width: 0px;
  margin: 0 -20px;
  max-width: none;
}
.advance .advance--article-cta .card .heading {
  padding-bottom: 28px;
  padding-top: 16px;
}
.advance .advance--article-cta .card .card--content {
  padding: 0 20px;
  margin-bottom: -10px;
}
.advance .advance--article-cta .card .advance--article-card--date {
  display: none;
}
@media (min-width: 768px) {
  .advance .advance--article-cta {
    padding-top: 63px;
    padding-bottom: 63px;
    /* article card variation */
  }
  .advance .advance--article-cta .card {
    border-width: 14px;
    margin: 0 0 15px;
    padding-top: 16px;
    padding-bottom: 68px;
  }
  .advance .advance--article-cta .card .heading {
    padding-bottom: 7px;
    padding-top: 5px;
  }
  .advance .advance--article-cta .card .card--content {
    max-width: 884px;
    padding: 20px;
  }
  .advance .advance--article-cta .advance--article-card {
    margin: 20px 0;
    min-height: unset;
  }
  .advance .advance--article-cta .advance--article-card::before {
    left: 0px;
    right: 0;
    display: block;
    margin-top: 28px;
  }
  .advance .advance--article-cta .advance--article-card .advance--article-card--content {
    display: flex;
    min-height: 0;
  }
  .advance .advance--article-cta .advance--article-card .advance--article-card--image {
    width: 47%;
    padding-left: 3px;
    overflow: hidden;
  }
  .advance .advance--article-cta .advance--article-card .advance--article-card--info {
    flex: 1;
    padding: 5px 0 0 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.advance .advance--article-grid + .advance--article-grid {
  margin-top: -2px;
}
.advance .advance--article-grid,
.advance .advance--article-grid--scroll {
  padding-top: 36px;
  padding-bottom: 36px;
}
.advance .advance--article-grid .heading,
.advance .advance--article-grid--scroll .heading {
  padding-bottom: 20px;
  padding-top: 15px;
}
.advance .advance--article-grid .article-grid--contain,
.advance .advance--article-grid--scroll .article-grid--contain {
  margin-bottom: -15px;
}
.advance .advance--article-grid .article-grid--contain .advance--article-card--date,
.advance .advance--article-grid--scroll .article-grid--contain .advance--article-card--date {
  display: none;
}
.advance .advance--article-grid .btn--container,
.advance .advance--article-grid--scroll .btn--container {
  text-align: center;
  padding: 0px;
  margin: 33px 0 3px;
}
.advance .advance--article-grid.advance--bk--gray .container::before, .advance .advance--article-grid.advance--bk--gray .container::after,
.advance .advance--article-grid--scroll.advance--bk--gray .container::before,
.advance .advance--article-grid--scroll.advance--bk--gray .container::after {
  background: #eff3f7;
}
.advance .advance--article-grid.advance--bk--gray .heading,
.advance .advance--article-grid--scroll.advance--bk--gray .heading {
  padding-bottom: 22px;
  padding-top: 8px;
  margin-top: -12px;
}
.advance .advance--article-grid.advance--bk--gray .advance--article-card .btn, .advance .advance--article-grid.advance--bk--gray .advance--article-card .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .advance .advance--article-grid.advance--bk--gray .advance--article-card .mktoButton,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .btn,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .marketo-form .mktoForm .mktoButton,
.marketo-form .mktoForm .advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .mktoButton {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
  border: 1px solid #c5d6df;
}
.advance .advance--article-grid.advance--bk--gray .advance--article-card .btn:hover, .advance .advance--article-grid.advance--bk--gray .advance--article-card .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .advance .advance--article-grid.advance--bk--gray .advance--article-card .mktoButton:hover,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .btn:hover,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .marketo-form .mktoForm .mktoButton:hover,
.marketo-form .mktoForm .advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .mktoButton:hover {
  color: #fff;
  background-color: #00364a;
  border-color: #00364a;
}
.advance .advance--article-grid.advance--bk--gray .advance--article-card .btn:focus, .advance .advance--article-grid.advance--bk--gray .advance--article-card .marketo-form .mktoForm .mktoButton:focus, .marketo-form .mktoForm .advance .advance--article-grid.advance--bk--gray .advance--article-card .mktoButton:focus, .advance .advance--article-grid.advance--bk--gray .advance--article-card .btn.focus, .advance .advance--article-grid.advance--bk--gray .advance--article-card .marketo-form .mktoForm .focus.mktoButton, .marketo-form .mktoForm .advance .advance--article-grid.advance--bk--gray .advance--article-card .focus.mktoButton,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .btn:focus,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .marketo-form .mktoForm .mktoButton:focus,
.marketo-form .mktoForm .advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .mktoButton:focus,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .btn.focus,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .marketo-form .mktoForm .focus.mktoButton,
.marketo-form .mktoForm .advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .focus.mktoButton {
  color: #fff;
  background-color: #00364a;
  border-color: #00364a;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.advance .advance--article-grid.advance--bk--gray .advance--article-card .btn.disabled, .advance .advance--article-grid.advance--bk--gray .advance--article-card .marketo-form .mktoForm .disabled.mktoButton, .marketo-form .mktoForm .advance .advance--article-grid.advance--bk--gray .advance--article-card .disabled.mktoButton, .advance .advance--article-grid.advance--bk--gray .advance--article-card .btn:disabled, .advance .advance--article-grid.advance--bk--gray .advance--article-card .marketo-form .mktoForm .mktoButton:disabled, .marketo-form .mktoForm .advance .advance--article-grid.advance--bk--gray .advance--article-card .mktoButton:disabled,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .btn.disabled,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .marketo-form .mktoForm .disabled.mktoButton,
.marketo-form .mktoForm .advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .disabled.mktoButton,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .btn:disabled,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .marketo-form .mktoForm .mktoButton:disabled,
.marketo-form .mktoForm .advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .mktoButton:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.advance .advance--article-grid.advance--bk--gray .advance--article-card .btn:not(:disabled):not(.disabled):active, .advance .advance--article-grid.advance--bk--gray .advance--article-card .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active, .marketo-form .mktoForm .advance .advance--article-grid.advance--bk--gray .advance--article-card .mktoButton:not(:disabled):not(.disabled):active, .advance .advance--article-grid.advance--bk--gray .advance--article-card .btn:not(:disabled):not(.disabled).active, .advance .advance--article-grid.advance--bk--gray .advance--article-card .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active, .marketo-form .mktoForm .advance .advance--article-grid.advance--bk--gray .advance--article-card .mktoButton:not(:disabled):not(.disabled).active, .show > .advance .advance--article-grid.advance--bk--gray .advance--article-card .btn.dropdown-toggle, .show > .advance .advance--article-grid.advance--bk--gray .advance--article-card .marketo-form .mktoForm .dropdown-toggle.mktoButton, .marketo-form .mktoForm .show > .advance .advance--article-grid.advance--bk--gray .advance--article-card .dropdown-toggle.mktoButton,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .btn:not(:disabled):not(.disabled):active,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active,
.marketo-form .mktoForm .advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .mktoButton:not(:disabled):not(.disabled):active,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .btn:not(:disabled):not(.disabled).active,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active,
.marketo-form .mktoForm .advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .mktoButton:not(:disabled):not(.disabled).active,
.show > .advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .btn.dropdown-toggle,
.show > .advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .marketo-form .mktoForm .dropdown-toggle.mktoButton,
.marketo-form .mktoForm .show > .advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .dropdown-toggle.mktoButton {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.advance .advance--article-grid.advance--bk--gray .advance--article-card .btn:not(:disabled):not(.disabled):active:focus, .advance .advance--article-grid.advance--bk--gray .advance--article-card .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active:focus, .marketo-form .mktoForm .advance .advance--article-grid.advance--bk--gray .advance--article-card .mktoButton:not(:disabled):not(.disabled):active:focus, .advance .advance--article-grid.advance--bk--gray .advance--article-card .btn:not(:disabled):not(.disabled).active:focus, .advance .advance--article-grid.advance--bk--gray .advance--article-card .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active:focus, .marketo-form .mktoForm .advance .advance--article-grid.advance--bk--gray .advance--article-card .mktoButton:not(:disabled):not(.disabled).active:focus, .show > .advance .advance--article-grid.advance--bk--gray .advance--article-card .btn.dropdown-toggle:focus, .show > .advance .advance--article-grid.advance--bk--gray .advance--article-card .marketo-form .mktoForm .dropdown-toggle.mktoButton:focus, .marketo-form .mktoForm .show > .advance .advance--article-grid.advance--bk--gray .advance--article-card .dropdown-toggle.mktoButton:focus,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .btn:not(:disabled):not(.disabled):active:focus,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled):active:focus,
.marketo-form .mktoForm .advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .mktoButton:not(:disabled):not(.disabled):active:focus,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .btn:not(:disabled):not(.disabled).active:focus,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .marketo-form .mktoForm .mktoButton:not(:disabled):not(.disabled).active:focus,
.marketo-form .mktoForm .advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .mktoButton:not(:disabled):not(.disabled).active:focus,
.show > .advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .btn.dropdown-toggle:focus,
.show > .advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .marketo-form .mktoForm .dropdown-toggle.mktoButton:focus,
.marketo-form .mktoForm .show > .advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .dropdown-toggle.mktoButton:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.advance .advance--article-grid.advance--bk--gray .advance--article-card .btn:hover, .advance .advance--article-grid.advance--bk--gray .advance--article-card .marketo-form .mktoForm .mktoButton:hover, .marketo-form .mktoForm .advance .advance--article-grid.advance--bk--gray .advance--article-card .mktoButton:hover,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .btn:hover,
.advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .marketo-form .mktoForm .mktoButton:hover,
.marketo-form .mktoForm .advance .advance--article-grid--scroll.advance--bk--gray .advance--article-card .mktoButton:hover {
  color: #fff;
}
.advance .advance--article-grid.advance--bk--gray .btn--container,
.advance .advance--article-grid--scroll.advance--bk--gray .btn--container {
  margin: 7px 0 1px;
}
.advance .advance--article-grid--scroll .container {
  position: relative;
}
.advance .advance--article-grid--scroll .container::before, .advance .advance--article-grid--scroll .container::after {
  background: #fff;
  width: 20px;
  left: 0;
  bottom: 0;
  content: "";
  position: absolute;
  z-index: 9;
  height: 100px;
}
.advance .advance--article-grid--scroll .container::after {
  left: unset;
  right: 0;
}
.advance .advance--article-grid--scroll .article-grid--contain {
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 20px 20px;
  margin: 0 -20px;
}
.advance .advance--article-grid--scroll .row {
  flex-wrap: nowrap;
}
@media (min-width: 768px) {
  .advance .advance--article-grid + .advance--article-grid {
    margin-top: -16px;
  }
  .advance .advance--article-grid,
.advance .advance--article-grid--scroll {
    padding-top: 63px;
    padding-bottom: 63px;
  }
  .advance .advance--article-grid .heading,
.advance .advance--article-grid--scroll .heading {
    padding-bottom: 16px;
    padding-top: 15px;
  }
  .advance .advance--article-grid .article-grid--contain,
.advance .advance--article-grid--scroll .article-grid--contain {
    margin: 12px 0 4px;
    padding-bottom: 28px;
  }
  .advance .advance--article-grid .article-grid--contain .row,
.advance .advance--article-grid--scroll .article-grid--contain .row {
    row-gap: 30px;
  }
  .advance .advance--article-grid .btn--container,
.advance .advance--article-grid--scroll .btn--container {
    text-align: center;
    padding: 42px 0 0px;
    margin: -3px 0 -10px;
  }
  .advance .advance--article-grid.advance--bk--gray .heading,
.advance .advance--article-grid--scroll.advance--bk--gray .heading {
    padding-bottom: 24px;
    padding-top: 5px;
    margin-top: -12px;
  }
  .advance .advance--article-grid.advance--bk--gray .btn--container,
.advance .advance--article-grid--scroll.advance--bk--gray .btn--container {
    text-align: center;
    margin-bottom: -15px;
  }
  .advance .advance--article-grid .article-grid--contain {
    position: relative;
    margin: 12px 0 4px;
  }
  .advance .advance--article-grid .article-grid--contain::after {
    border-bottom: 1px #c5d6df solid;
    content: "";
    position: absolute;
    top: 100%;
    left: 0px;
    right: 0px;
  }
  .advance .advance--article-grid .article-grid--contain > .row {
    margin: 0 -12px;
  }
  .advance .advance--article-grid .article-grid--contain > .row > div {
    padding-right: 12px;
    padding-left: 12px;
  }
  .advance .advance--article-grid .advance--article-card::before {
    display: none;
  }
  .advance .advance--article-grid--scroll .article-grid--contain {
    margin: 12px -20px 6px;
  }
  .advance .advance--article-grid--scroll .container::before, .advance .advance--article-grid--scroll .container::after {
    top: 0;
    height: unset;
  }
}

.advance .advance--article-hero,
.advance .advance--podcast-hero {
  padding-top: 36px;
  padding-bottom: 0;
}
.advance .advance--article-hero hr,
.advance .advance--podcast-hero hr {
  display: none;
}
.advance .advance--article-hero .breadcrumb,
.advance .advance--podcast-hero .breadcrumb {
  border-bottom: 6px #24a0a5 solid;
  padding-bottom: 25px;
  margin: -4px 0 30px;
  font-weight: 600;
}
.advance .advance--article-hero .article-page-header,
.advance .advance--podcast-hero .article-page-header {
  display: flex;
  flex-direction: column;
  padding: 0 0 37px 0;
  position: relative;
}
.advance .advance--article-hero .article-page-header .page-header--info,
.advance .advance--podcast-hero .article-page-header .page-header--info {
  order: 1;
  flex-grow: 1;
  min-width: 50%;
}
.advance .advance--article-hero .article-page-header .page-header--info .info--content-label,
.advance .advance--podcast-hero .article-page-header .page-header--info .info--content-label {
  color: #f7aa00;
}
.advance .advance--article-hero .article-page-header .page-header--info .info--content-date,
.advance .advance--podcast-hero .article-page-header .page-header--info .info--content-date {
  color: #c5d6df;
}
.advance .advance--article-hero .article-page-header .page-header--info .info--content-date::before,
.advance .advance--podcast-hero .article-page-header .page-header--info .info--content-date::before {
  content: "";
  display: inline-block;
  height: 4px;
  width: 4px;
  margin: 0 10px 2px 8px;
  background: #006366;
  border-radius: 50%;
  vertical-align: middle;
}
.advance .advance--article-hero .article-page-header .page-header--social,
.advance .advance--podcast-hero .article-page-header .page-header--social {
  display: flex;
  white-space: nowrap;
  align-items: center;
  order: 4;
}
.advance .advance--article-hero .article-page-header .page-header--social .social-share,
.advance .advance--podcast-hero .article-page-header .page-header--social .social-share {
  border-radius: 10px;
  height: 36px;
  padding: 0 14px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  color: #00364a;
}
.advance .advance--article-hero .article-page-header .page-header--social .social-share .label,
.advance .advance--podcast-hero .article-page-header .page-header--social .social-share .label {
  margin-right: 10px;
}
.advance .advance--article-hero .article-page-header .page-header--social .social-share svg.icon,
.advance .advance--podcast-hero .article-page-header .page-header--social .social-share svg.icon {
  fill: #00364a;
  color: #00364a;
  height: 20px;
  width: 20px;
  display: block;
}
.advance .advance--article-hero .article-page-header .page-header--social .social-share button:hover svg.icon,
.advance .advance--podcast-hero .article-page-header .page-header--social .social-share button:hover svg.icon {
  opacity: 0.8;
}
.advance .advance--article-hero .article-page-header .page-header--heading,
.advance .advance--podcast-hero .article-page-header .page-header--heading {
  flex-basis: 50%;
  order: 3;
  padding: 25px 2rem 6px 0;
}
.advance .advance--article-hero .article-page-header .page-header--image,
.advance .advance--podcast-hero .article-page-header .page-header--image {
  flex-basis: 50%;
  flex-grow: 1;
  margin: 35px -20px 0;
  order: 5;
}
.advance .advance--article-hero .article-page-header .page-header--image .image-container,
.advance .advance--podcast-hero .article-page-header .page-header--image .image-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}
.advance .advance--article-hero .article-page-header .page-header--image img,
.advance .advance--podcast-hero .article-page-header .page-header--image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
@media (min-width: 576px) {
  .advance .advance--article-hero .article-page-header .page-header--social,
.advance .advance--podcast-hero .article-page-header .page-header--social {
    width: 233px;
    min-width: 233px;
  }
}
@media (min-width: 992px) {
  .advance .advance--article-hero,
.advance .advance--podcast-hero {
    padding-top: 36px;
    padding-bottom: 0;
  }
  .advance .advance--article-hero .breadcrumb,
.advance .advance--podcast-hero .breadcrumb {
    padding-bottom: 24px;
    margin: 6px 0 38px;
  }
  .advance .advance--article-hero .page-header--contain,
.advance .advance--podcast-hero .page-header--contain {
    max-width: 1064px;
    margin: 0 auto;
  }
  .advance .advance--article-hero .article-page-header,
.advance .advance--podcast-hero .article-page-header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .advance .advance--article-hero .article-page-header .page-header--info,
.advance .advance--podcast-hero .article-page-header .page-header--info {
    font-size: 14px;
  }
  .advance .advance--article-hero .article-page-header .page-header--social,
.advance .advance--podcast-hero .article-page-header .page-header--social {
    margin: 5px 0 -5px;
    order: 2;
    justify-content: flex-end;
  }
  .advance .advance--article-hero .article-page-header .page-header--heading,
.advance .advance--podcast-hero .article-page-header .page-header--heading {
    padding: 52px 3rem 0 0;
  }
  .advance .advance--article-hero .article-page-header .page-header--image,
.advance .advance--podcast-hero .article-page-header .page-header--image {
    margin: 35px 0 0;
  }
  .advance .advance--podcast-hero .article-page-header {
    padding-bottom: 60px;
  }
  .advance .advance--podcast-hero .article-page-header .page-header--info {
    font-size: 14px;
    margin-bottom: 32px;
  }
  .advance .advance--podcast-hero .article-page-header .page-header--social {
    margin: 4px 0 28px;
  }
  .advance .advance--podcast-hero .article-page-header .page-header--heading {
    width: auto;
    flex-grow: 1;
    width: 50%;
    padding: 3px 3rem 0 0;
    align-self: flex-start;
  }
  .advance .advance--podcast-hero .article-page-header .page-header--image {
    margin: 0;
  }
}

.advance {
  /* filters */
  /* listing */
  /* pagination */
}
.advance .list-filters {
  padding: 36px 20px;
}
.advance .list-filters .filter-group--contain {
  margin-top: 1px;
  border: 10px #c5d6df solid;
}
.advance .list-filters .filter-group--contain .filter-group--toggle {
  padding: 23px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.advance .list-filters .filter-group--contain .filter-group--toggle .heading {
  margin: 0;
}
.advance .list-filters .filter-group--contain .filter-group--toggle .icon {
  color: #00364a;
  fill: #47d2d7;
  margin-left: 15px;
  width: 30px;
  height: 30px;
  transform: rotate(90deg);
}
.advance .list-filters .filter-group--contain.active .filter-group--toggle .icon {
  transform: rotate(-90deg);
}
.advance .list-filters .filter-group--contain.active form {
  display: flex;
  flex-direction: column;
}
.advance .list-filters .filter-group--contain form {
  display: none;
  border: none;
  background: #fff;
  padding: 4px 20px 15px;
}
.advance .list-filters .filter-group--contain form input,
.advance .list-filters .filter-group--contain form select {
  background: #eff3f7 !important;
}
.advance .list-filters .filter-group--contain form button[type=submit] {
  display: none;
}
.advance .article-list-paginated {
  padding-top: 36px;
  padding-bottom: 36px;
  padding-left: 20px;
  padding-right: 20px;
}
.advance .article-list-paginated > .showing-vs-total {
  margin: -28px 0 24px;
  line-height: 22px;
}
.advance .article-list-paginated .article-listing {
  margin-left: 0;
  margin-right: 0;
}
.advance .article-list-paginated .article-listing:not(:last-of-type) {
  margin-bottom: 3rem;
  min-height: 160px;
}
.advance .article-list-paginated .article-listing p {
  display: block;
}
.advance .article-list-paginated .article-listing .advance--article-card--date {
  display: none;
}
.advance .pagination-controls {
  padding-top: 18px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0;
}
.advance .pagination-controls > div {
  max-width: 45vw;
}
.advance .pagination-controls .pagination-quantity {
  order: 1;
}
.advance .pagination-controls .pagination-link {
  order: 4;
  text-align: right;
  display: none;
}
.advance .pagination-controls .pagination-counts {
  order: 2;
  justify-content: flex-end;
  margin: -3px 0 3px;
}
.advance .pagination-controls .pagination-nav {
  max-width: unset;
  flex-grow: 1;
  flex-flow: column-reverse;
  align-items: center;
  order: 5;
  width: 100%;
  margin: 0 0 3px;
}
.advance .pagination-controls .pagination-nav .showing-vs-total {
  padding: 0;
  font-size: 18px;
}
.advance .pagination-controls .pagination-nav .pagination {
  padding-top: 25px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.advance .pagination-controls .pagination-nav .page-link {
  border: 4px #fff solid;
  background: #fff;
  color: #4d7489;
  border-radius: 5px;
}
.advance .pagination-controls .pagination-nav .page-link:hover {
  color: #00364a;
}
.advance .pagination-controls .pagination-nav .page-item {
  margin: 0;
}
.advance .pagination-controls .pagination-nav .page-item.next-page .page-link, .advance .pagination-controls .pagination-nav .page-item.prev-page .page-link {
  border: none;
  background: #4d7489;
  border-radius: 50%;
}
.advance .pagination-controls .pagination-nav .page-item.next-page .page-link span svg, .advance .pagination-controls .pagination-nav .page-item.prev-page .page-link span svg {
  fill: #fff;
}
.advance .pagination-controls .pagination-nav .page-item.next-page .page-link:hover, .advance .pagination-controls .pagination-nav .page-item.prev-page .page-link:hover {
  background: #00364a;
}
.advance .pagination-controls .pagination-nav .page-item.next-page.prev-page, .advance .pagination-controls .pagination-nav .page-item.prev-page.prev-page {
  margin-right: 5px;
}
.advance .pagination-controls .pagination-nav .page-item.next-page.prev-page span, .advance .pagination-controls .pagination-nav .page-item.prev-page.prev-page span {
  margin: 2px 1px -2px -1px;
}
.advance .pagination-controls .pagination-nav .page-item.next-page.next-page, .advance .pagination-controls .pagination-nav .page-item.prev-page.next-page {
  margin-left: 5px;
}
.advance .pagination-controls .pagination-nav .page-item.next-page.next-page span, .advance .pagination-controls .pagination-nav .page-item.prev-page.next-page span {
  margin: 2px -1px -2px 1px;
}
.advance .pagination-controls .pagination-nav .page-item.next-page.disabled .page-link, .advance .pagination-controls .pagination-nav .page-item.prev-page.disabled .page-link {
  background: #c5d6df;
}
.advance .pagination-controls .pagination-nav .page-number .page-link {
  padding: 5px 0;
  width: 41px;
  height: 36px;
}
.advance .pagination-controls .pagination-nav .page-number:nth-child(2).active .page-link {
  margin-left: 10px;
}
.advance .pagination-controls .pagination-nav .page-number:nth-child(5).active .page-link {
  margin-right: 10px;
}
.advance .pagination-controls .pagination-nav .page-number.active .page-link {
  border-color: #47d2d7;
  color: #00364a;
}
@media (min-width: 576px) {
  .advance .pagination-controls .pagination-nav .pagination {
    justify-content: center;
    gap: 18px;
  }
}
@media (min-width: 768px) {
  .advance .list-filters {
    padding-top: 63px;
    padding-bottom: 63px;
    margin-top: -18px;
  }
  .advance .list-filters .filter-group--contain {
    border-width: 14px;
    padding: 15px 30px;
    margin-top: 2px;
  }
  .advance .list-filters .filter-group--contain .filter-group--toggle {
    padding: 20px 15px;
    justify-content: flex-start;
  }
  .advance .list-filters .filter-group--contain .filter-group--toggle .icon {
    margin-top: -3px;
  }
  .advance .list-filters .filter-group--contain.active form {
    flex-direction: row;
    padding: 15px 15px 40px;
    flex-wrap: wrap;
    gap: 20px;
  }
  .advance .list-filters .filter-group--contain.active form .filter-group {
    margin: 0;
    width: calc(50% - 10px);
    flex: auto;
  }
  .advance .article-list-paginated {
    padding-top: 63px;
    padding-bottom: 63px;
  }
  .advance .article-list-paginated > .showing-vs-total {
    margin: -45px 0 54px;
    font-size: 18px;
    line-height: 28px;
  }
  .advance .article-list-paginated .article-listing .advance--article-card {
    min-height: unset;
  }
  .advance .article-list-paginated .article-listing .advance--article-card .advance--article-card--content {
    display: flex;
    flex-direction: row;
    flex-flow: row-reverse;
    justify-content: space-between;
    gap: 50px;
  }
  .advance .article-list-paginated .article-listing .advance--article-card .advance--article-card--content .advance--article-card--image {
    max-width: 24.4%;
  }
  .advance .article-list-paginated .article-listing .advance--article-card .advance--article-card--content .advance--article-card--image a,
.advance .article-list-paginated .article-listing .advance--article-card .advance--article-card--content .advance--article-card--image img {
    display: block;
    max-height: 160px;
  }
  .advance .article-list-paginated .article-listing .advance--article-card .advance--article-card--content .advance--article-card--info {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 0 0 18px;
  }
  .advance .article-list-paginated .article-listing .advance--article-card .advance--article-card--content .advance--article-card--info .advance--article-card--date {
    display: block;
  }
  .advance .pagination-controls {
    align-items: flex-start;
  }
  .advance .pagination-controls .pagination-counts {
    display: none;
  }
  .advance .pagination-controls .pagination-link {
    display: block;
  }
  .advance .pagination-controls .pagination-nav {
    padding-top: 40px;
    width: auto;
  }
  .advance .pagination-controls .pagination-nav .pagination {
    padding-bottom: 28px;
    padding-top: unset;
  }
  .advance .pagination-controls .pagination-nav .page-link {
    border-width: 4px;
  }
}
@media (min-width: 992px) {
  .advance .list-filters .filter-group--contain.active form {
    padding: 18px 15px 50px;
    flex-wrap: nowrap;
  }
  .advance .pagination-controls {
    padding-top: 30px;
    margin-bottom: -20px;
  }
  .advance .pagination-controls > div {
    width: 285px;
  }
  .advance .pagination-controls .pagination-nav {
    padding-top: 0px;
    order: 3;
  }
}

.advance--podcast-player {
  padding-top: 36px;
  padding-bottom: 36px;
}
.advance--podcast-player .advance--podcast-player--container {
  max-width: 845px;
  margin: 0 auto;
  padding-bottom: 17px;
}
.advance--podcast-player .audio-player {
  padding: 10px 0 0;
  margin-bottom: 22px;
}
.advance--podcast-player .audio-player .audio-player--controls {
  display: flex;
  justify-content: center;
  margin-top: 22px;
  position: relative;
}
.advance--podcast-player .audio-player .audio-player--controls .navigation-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}
.advance--podcast-player .audio-player .audio-player--controls .navigation-controls .play-button {
  background: #f7aa00;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 30px;
  border: none;
}
.advance--podcast-player .audio-player .audio-player--controls .navigation-controls .play-button .play-button--svg {
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 32px;
  border-color: transparent transparent transparent #00364a;
  transition: 300ms opacity ease;
  cursor: pointer;
  border-style: solid;
  border-width: 16px 0 16px 24px;
  margin-left: 5px;
}
.advance--podcast-player .audio-player .audio-player--controls .navigation-controls .play-button.playing .play-button--svg {
  border-width: 0px 7px 0px 7px;
  margin-left: 0px;
  width: 25px;
  border-color: transparent #00364a;
  transition: 300ms all ease;
}
.advance--podcast-player .audio-player .audio-player--controls .navigation-controls .play-button:hover .play-button--svg {
  opacity: 0.9;
}
.advance--podcast-player .audio-player .audio-player--controls .navigation-controls .play-button:focus .play-button--svg {
  outline: none;
}
.advance--podcast-player .audio-player .audio-player--controls .navigation-controls .skip-icon {
  height: 26px;
  width: 26px;
  cursor: pointer;
}
.advance--podcast-player .audio-player .audio-player--controls .volume-control {
  display: none;
  align-items: center;
  gap: 10px;
}
.advance--podcast-player .audio-player .audio-player--controls .volume-control #volume-slider input {
  width: 87px;
}
.advance--podcast-player .audio-player .audio-player--controls .volume-control #mute-button {
  cursor: pointer;
}
.advance--podcast-player .audio-player .audio-player--controls .volume-control #mute-button svg {
  width: 27px;
  height: 23px;
}
.advance--podcast-player .audio-player .audio-player--controls .advance-podcast--overflow {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 0;
}
.advance--podcast-player .audio-player .audio-player--controls .advance-podcast--overflow .dropdown-toggle:after {
  content: none;
}
.advance--podcast-player .audio-player .audio-player--controls .advance-podcast--overflow .advance-podcast--overflow-button {
  float: right;
  width: 30px;
}
.advance--podcast-player .audio-player .audio-player--controls .advance-podcast--overflow .advance-podcast--overflow-button svg {
  width: 26px;
}
.advance--podcast-player .audio-player .audio-player--controls .advance-podcast--overflow .dropdown-menu {
  top: 30px;
  left: auto;
  right: 0;
  width: 170px;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.1);
  border-color: #eff3f7;
}
.advance--podcast-player .audio-player .audio-player--controls .advance-podcast--overflow .dropdown-menu .dropdown-menu {
  top: -1px;
  right: -1px;
}
.advance--podcast-player .audio-player .audio-player--controls .advance-podcast--overflow .dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  font-size: 12px;
  line-height: initial;
  font-weight: 700;
  height: 40px;
  padding: 0 0 0 22px;
  background: #fff;
}
.advance--podcast-player .audio-player .audio-player--controls .advance-podcast--overflow .dropdown-item.btn-group {
  padding: 0;
  position: initial;
}
.advance--podcast-player .audio-player .audio-player--controls .advance-podcast--overflow .dropdown-item.btn-group .sub-menu svg {
  width: 7px;
  height: 14px;
}
.advance--podcast-player .audio-player .audio-player--controls .advance-podcast--overflow .dropdown-item svg,
.advance--podcast-player .audio-player .audio-player--controls .advance-podcast--overflow .dropdown-item .icon {
  width: 20px;
  height: 20px;
}
.advance--podcast-player .audio-player .audio-player--controls .advance-podcast--overflow .dropdown-item:hover {
  background: #47d2d7;
  color: #00364a;
}
.advance--podcast-player .audio-player .audio-player--controls .advance-podcast--overflow .dropdown-item.playback-speed-option {
  font-weight: 400;
  font-size: 14px;
}
.advance--podcast-player .audio-player .audio-player--controls .advance-podcast--overflow .dropdown-item.active {
  font-weight: 700;
  background: #fff;
  color: #00364a;
  position: relative;
}
.advance--podcast-player .audio-player .audio-player--controls .advance-podcast--overflow .dropdown-item.active::after {
  border-bottom: 3px solid #f7aa00;
  border-right: 3px solid #f7aa00;
  content: "";
  display: block;
  margin: 0.25rem 0.375rem;
  position: absolute;
  top: 7px;
  right: 29px;
  -webkit-transform: rotate(45deg) translate(-1px, -1px);
  -ms-transform: rotate(45deg) translate(-1px, -1px);
  transform: rotate(45deg) translate(-1px, -1px);
  width: 0.5rem;
  height: 1rem;
}
.advance--podcast-player .audio-player .audio-player--controls input[type=range]::-webkit-slider-runnable-track {
  background: #c5d6df;
}
.advance--podcast-player .audio-player .audio-player--controls input[type=range]::before {
  background: #00364a;
}
.advance--podcast-player .audio-player .audio-player--controls input[type=range]::-webkit-slider-thumb {
  border: 1px solid #00364a;
  background-color: #00364a;
}
.advance--podcast-player .audio-player .audio-player--controls input[type=range]:active::-webkit-slider-thumb {
  background: #00364a;
}
.advance--podcast-player .audio-player .audio-player--controls input[type=range]::-moz-range-track {
  background: #c5d6df;
}
.advance--podcast-player .audio-player .audio-player--controls input[type=range]::-moz-range-progress {
  background-color: #00364a;
  height: 7px;
  border-radius: 4px 0 0 4px;
}
.advance--podcast-player .audio-player .audio-player--controls input[type=range]::-moz-range-thumb {
  border: 1px solid #00364a;
  background-color: #00364a;
}
.advance--podcast-player .audio-player .audio-player--controls input[type=range]:active::-moz-range-thumb {
  background: #00364a;
}
.advance--podcast-player .audio-player .audio-player--controls input[type=range]::-ms-fill-lower {
  background-color: #00364a;
}
.advance--podcast-player .audio-player .audio-player--controls input[type=range]::-ms-fill-upper {
  background: linear-gradient(to right, #00364a var(--buffered-width), #c5d6df var(--buffered-width));
}
.advance--podcast-player .audio-player .audio-player--controls input[type=range]::-ms-thumb {
  border: 1px solid #00364a;
  background-color: #00364a;
}
.advance--podcast-player .audio-player .audio-player--controls input[type=range]:active::-ms-thumb {
  background: #00364a;
}
.advance--podcast-player .audio-player .audio-player--progress {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 19px;
}
.advance--podcast-player .audio-player .audio-player--progress input[type=range] {
  width: 100%;
}
.advance--podcast-player .audio-player .audio-player--progress input[type=range]::-webkit-slider-runnable-track {
  background: #c5d6df;
}
.advance--podcast-player .audio-player .audio-player--progress input[type=range]::before {
  background-color: #f7aa00;
}
.advance--podcast-player .audio-player .audio-player--progress input[type=range]::-webkit-slider-thumb {
  border: 1px solid #f7aa00;
  background-color: #f7aa00;
}
.advance--podcast-player .audio-player .audio-player--progress input[type=range]:active::-webkit-slider-thumb {
  background: #f7aa00;
}
.advance--podcast-player .audio-player .audio-player--progress input[type=range]::-moz-range-track {
  background: #c5d6df;
}
.advance--podcast-player .audio-player .audio-player--progress input[type=range]::-moz-range-progress {
  background-color: #f7aa00;
  height: 7px;
  border-radius: 4px 0 0 4px;
}
.advance--podcast-player .audio-player .audio-player--progress input[type=range]::-moz-range-thumb {
  border: 1px solid #f7aa00;
  background-color: #f7aa00;
}
.advance--podcast-player .audio-player .audio-player--progress input[type=range]:active::-moz-range-thumb {
  background: #f7aa00;
}
.advance--podcast-player .audio-player .audio-player--progress input[type=range]::-ms-fill-lower {
  background-color: #c5d6df;
}
.advance--podcast-player .audio-player .audio-player--progress input[type=range]::-ms-fill-upper {
  background: linear-gradient(to right, #c5d6df var(--buffered-width), #47d2d7 var(--buffered-width));
}
.advance--podcast-player .audio-player .audio-player--progress input[type=range]::-ms-thumb {
  border: 1px solid #c5d6df;
  background-color: #c5d6df;
}
.advance--podcast-player .audio-player .audio-player--progress input[type=range]:active::-ms-thumb {
  background: #c5d6df;
}
.advance--podcast-player .audio-player input[type=range] {
  position: relative;
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  height: 17px;
  margin-left: 0;
  float: left;
  outline: none;
}
.advance--podcast-player .audio-player input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  border-radius: 4px;
}
.advance--podcast-player .audio-player input[type=range]::before {
  position: absolute;
  content: "";
  top: 5px;
  left: 0;
  width: var(--range-before-width);
  height: 7px;
  cursor: pointer;
  border-radius: 4px;
}
.advance--podcast-player .audio-player input[type=range]::-webkit-slider-thumb {
  position: relative;
  -webkit-appearance: none;
  box-sizing: content-box;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  cursor: pointer;
  margin: -3px 0 0 0;
  transform: scale(1.4);
}
.advance--podcast-player .audio-player input[type=range]:active::-webkit-slider-thumb {
  transform: scale(1.6);
}
.advance--podcast-player .audio-player input[type=range]::-moz-range-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  border-radius: 4px;
}
.advance--podcast-player .audio-player input[type=range]::-moz-focus-outer {
  border: 0;
  border-radius: 4px;
}
.advance--podcast-player .audio-player input[type=range]::-moz-range-thumb {
  box-sizing: content-box;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  cursor: pointer;
}
.advance--podcast-player .audio-player input[type=range]:active::-moz-range-thumb {
  transform: scale(1.4);
}
.advance--podcast-player .audio-player input[type=range]::-ms-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  background: transparent;
  border: solid transparent;
  color: transparent;
  border-radius: 4px;
}
.advance--podcast-player .audio-player input[type=range]::-ms-thumb {
  position: relative;
  -webkit-appearance: none;
  box-sizing: content-box;
  height: 11px;
  width: 11px;
  cursor: pointer;
  margin: -3px 0 0 0;
  transform: scale(1.4);
  border-radius: 50%;
}
.advance--podcast-player .audio-player input[type=range]:active::-ms-thumb {
  transform: scale(1.6);
}
.advance--podcast-player .advance-podcast--transcript-drawer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.advance--podcast-player .advance-podcast--transcript-drawer.active #advance-podcast--transcript {
  display: block;
}
.advance--podcast-player .advance-podcast--transcript-drawer #advance-podcast--transcript {
  display: none;
}
.advance--podcast-player .advance-podcast--transcript-drawer #advance-podcast--transcript .advance-podcast--transcript-title-line {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 26px;
}
.advance--podcast-player .advance-podcast--transcript-drawer #advance-podcast--transcript .advance-podcast--transcript-title-line .advance-podcast--transcript-title {
  margin-bottom: 10px;
}
.advance--podcast-player .advance-podcast--transcript-drawer #advance-podcast--transcript .advance-podcast--transcript-title-line .advance-podcast--transcript-popout span {
  color: #3579ad;
  text-decoration: underline;
}
.advance--podcast-player .advance-podcast--transcript-drawer #advance-podcast--transcript .advance-podcast--transcript-title-line .advance-podcast--transcript-popout svg {
  fill: #3579ad;
  margin-left: 3px;
  height: 19px;
  width: 19px;
}
.advance--podcast-player .advance-podcast--transcript-drawer #advance-podcast--transcript .advance-podcast--transcript-title-line .advance-podcast--transcript-popout:hover span {
  color: #00364a;
}
.advance--podcast-player .advance-podcast--transcript-drawer #advance-podcast--transcript .advance-podcast--transcript-title-line .advance-podcast--transcript-popout:hover svg {
  fill: #00364a;
}
.advance--podcast-player .advance-podcast--transcript-drawer #advance-podcast--transcript .advance-podcast--transcript-content {
  max-height: 540px;
  padding: 0 30px 0 0;
  overflow: scroll;
  overflow-x: hidden;
  margin-top: 34px;
  scrollbar-width: thin;
  scrollbar-color: #00364a #99b4c3;
}
.advance--podcast-player .advance-podcast--transcript-drawer #advance-podcast--transcript ::-webkit-scrollbar {
  height: 7px;
  width: 7px;
  overflow: hidden;
}
.advance--podcast-player .advance-podcast--transcript-drawer #advance-podcast--transcript ::-webkit-scrollbar-track {
  border-radius: 4px;
}
.advance--podcast-player .advance-podcast--transcript-drawer #advance-podcast--transcript ::-webkit-scrollbar-thumb {
  border: 0;
  border-radius: 4px;
}

@media (min-width: 768px) {
  .advance--podcast-player {
    padding-top: 63px;
    padding-bottom: 63px;
  }
  .advance--podcast-player .audio-player {
    padding: 0;
    margin-bottom: 37px;
  }
  .advance--podcast-player .audio-player .audio-player--progress {
    padding-top: 13px;
  }
  .advance--podcast-player .audio-player .audio-player--progress div {
    font-size: 16px;
  }
  .advance--podcast-player .audio-player .audio-player--controls {
    justify-content: space-between;
  }
  .advance--podcast-player .audio-player .audio-player--controls > div {
    min-width: 130px;
  }
  .advance--podcast-player .audio-player .audio-player--controls .volume-control {
    display: flex;
  }
  .advance--podcast-player .audio-player .audio-player--controls .advance-podcast--overflow {
    position: relative;
  }
  .advance--podcast-player .advance-podcast--transcript-drawer #advance-podcast--transcript .advance-podcast--transcript-title-line {
    margin-bottom: 7px;
    flex-wrap: nowrap;
  }
  .advance--podcast-player .advance-podcast--transcript-drawer #advance-podcast--transcript .advance-podcast--transcript-title-line a {
    white-space: nowrap;
    padding-left: 30px;
  }
  .advance--podcast-player .advance-podcast--transcript-drawer #advance-podcast--transcript .advance-podcast--transcript-content {
    max-height: 480px;
    margin-top: 37px;
  }
}
.advance header {
  z-index: 999999;
}
.advance .advance--brand-bar,
.advance .advance--brand-bar--spacer {
  height: 60px;
  position: relative;
  z-index: 99999;
}
.advance .advance--brand-bar img,
.advance .advance--brand-bar--spacer img {
  max-height: 37px;
}
.advance .advance--brand-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #99b4c3;
}
.advance .advance--brand-bar img {
  margin: 0 auto;
  width: auto;
}
.advance .advance--brand-bar.fixed {
  top: 0px;
  position: fixed;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.08);
}
@media (min-width: 992px) {
  .advance .advance--brand-bar,
.advance .advance--brand-bar--spacer {
    height: 70px;
    padding: 15px 0;
  }
  .advance .advance--brand-bar img,
.advance .advance--brand-bar--spacer img {
    max-height: 40px;
  }
  .advance .home .advance--brand-bar,
.advance .home .advance--brand-bar--spacer {
    height: 90px;
  }
  .advance .home .advance--brand-bar img,
.advance .home .advance--brand-bar--spacer img {
    max-height: 52px;
  }
  .advance .advance--brand-bar.fixed {
    top: 70px;
  }
}

.advance .generic .alt-background {
  background-color: #eff3f7;
}
.advance .advance--article-content {
  padding-top: 36px;
  padding-bottom: 36px;
}
.advance .advance--article-content .generic {
  max-width: calc(884px - 40px);
  padding: 22px 0px;
  margin: 0 auto;
}
.advance .advance--article-content .generic .rte p:first-of-type {
  padding-top: 0;
}
.advance .advance--article-content .generic *:last-child {
  margin-bottom: 0;
}
.advance .advance--article-content figure,
.advance .advance--article-content .media-item {
  margin: 24px auto 16px;
}
.advance .advance--article-content figure img,
.advance .advance--article-content .media-item img {
  margin: 0;
  float: none;
}
.advance .advance--article-content figure:not(.has-background) .video,
.advance .advance--article-content .media-item:not(.has-background) .video {
  margin: 0 -20px;
}
.advance .advance--article-content figure .figure-text,
.advance .advance--article-content .media-item .figure-text {
  padding: 8px 0;
}
.advance .advance--article-content figure figcaption,
.advance .advance--article-content figure .figure-caption,
.advance .advance--article-content figure .figure-credits,
.advance .advance--article-content .media-item figcaption,
.advance .advance--article-content .media-item .figure-caption,
.advance .advance--article-content .media-item .figure-credits {
  font-size: 14px;
  line-height: initial;
  text-align: left;
  padding: 0;
}
.advance .advance--article-content figure figcaption + figcaption,
.advance .advance--article-content figure .figure-caption + figcaption,
.advance .advance--article-content figure .figure-credits + figcaption,
.advance .advance--article-content .media-item figcaption + figcaption,
.advance .advance--article-content .media-item .figure-caption + figcaption,
.advance .advance--article-content .media-item .figure-credits + figcaption {
  padding-top: 4px;
}
.advance .advance--article-content figure > figcaption, .advance .advance--article-content figure > .figure-caption,
.advance .advance--article-content .media-item > figcaption,
.advance .advance--article-content .media-item > .figure-caption {
  padding: 8px 0;
}
.advance .advance--article-content figure.has-background .figure-text,
.advance .advance--article-content .media-item.has-background .figure-text {
  padding: 8px 10px;
}
.advance .advance--article-content p {
  margin-top: 1.7rem;
  margin-bottom: 1.7rem;
}
.advance .advance--article-content p + h2, .advance .advance--article-content p + h3, .advance .advance--article-content p + h4 {
  margin-top: 1.7rem;
}
.advance .advance--article-content p + h2 + ul, .advance .advance--article-content p + h3 + ul, .advance .advance--article-content p + h4 + ul {
  margin-top: 1rem;
}
.advance .gallery .carousel-control-prev,
.advance .gallery .carousel-control-next,
.advance .gallery .carousel-indicators li {
  background-color: #47d2d7;
}
.advance .gallery .icon-arrow-left,
.advance .gallery .icon-arrow-right {
  fill: #00364a;
}
.advance .pull-quote,
.advance .advance--article-content .pull-quote {
  margin: 28px auto 30px;
  background: #fff;
  border-bottom: 6px #c5d6df solid;
  padding: 80px 0 25px;
  float: none !important;
}
.advance .pull-quote .card-header,
.advance .advance--article-content .pull-quote .card-header {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
}
.advance .pull-quote .card-header .profile-image,
.advance .advance--article-content .pull-quote .card-header .profile-image {
  position: relative;
  background: transparent;
  border: none;
  color: #00364a;
  box-shadow: none;
  overflow: visible;
  margin: 0;
  padding: 0;
}
.advance .pull-quote .card-header .profile-image span,
.advance .advance--article-content .pull-quote .card-header .profile-image span {
  display: none;
}
.advance .pull-quote .card-header .profile-image::after,
.advance .advance--article-content .pull-quote .card-header .profile-image::after {
  content: url("../images/icons/advance-quote.svg");
  position: absolute;
  top: 14px;
  left: 16px;
  content: "";
  display: block;
  height: 33px;
  width: 40px;
  background-size: 40px 33px;
  background-image: url("../images/icons/advance-quote.svg");
  background-repeat: no-repeat;
}
.advance .pull-quote .card-header::before,
.advance .advance--article-content .pull-quote .card-header::before {
  content: "";
  width: 0px;
  height: 0px;
  border-top: 76px solid #f7aa00;
  border-right: 79px solid transparent;
  top: 0px;
  left: 0px;
  position: absolute;
}
.advance .pull-quote .card-body,
.advance .pull-quote .card-footer,
.advance .advance--article-content .pull-quote .card-body,
.advance .advance--article-content .pull-quote .card-footer {
  padding: 0;
  text-align: left;
}
.advance .pull-quote .card-body p,
.advance .pull-quote .card-footer p,
.advance .advance--article-content .pull-quote .card-body p,
.advance .advance--article-content .pull-quote .card-footer p {
  text-align: left !important;
  line-height: 28px;
  color: #3f596c;
}
.advance .pull-quote .card-footer,
.advance .advance--article-content .pull-quote .card-footer {
  padding-top: 9px;
  font-family: "Merriweather", serif;
}
.advance .pull-quote .card-footer p,
.advance .advance--article-content .pull-quote .card-footer p {
  color: #19647e;
  margin: 0;
}
.advance .pull-quote .card-footer .quote-name,
.advance .advance--article-content .pull-quote .card-footer .quote-name {
  font-weight: 700;
  font-family: "Merriweather", serif;
}
@media (min-width: 992px) {
  .advance .advance--article-content {
    padding-top: 63px;
    padding-bottom: 63px;
  }
  .advance .advance--article-content .generic {
    padding: 40px 0px;
  }
  .advance .advance--article-content figure,
.advance .advance--article-content .media-item {
    margin-bottom: 1.7rem;
  }
  .advance .advance--article-content figure.right, .advance .advance--article-content figure.left,
.advance .advance--article-content .media-item.right,
.advance .advance--article-content .media-item.left {
    margin: 0 auto 16px;
  }
  .advance .advance--article-content figure.right.right, .advance .advance--article-content figure.left.right,
.advance .advance--article-content .media-item.right.right,
.advance .advance--article-content .media-item.left.right {
    margin-left: 40px;
  }
  .advance .advance--article-content figure.right.left, .advance .advance--article-content figure.left.left,
.advance .advance--article-content .media-item.right.left,
.advance .advance--article-content .media-item.left.left {
    margin-right: 40px;
  }
  .advance .advance--article-content figure:not(.has-background) .video,
.advance .advance--article-content .media-item:not(.has-background) .video {
    margin: 0;
  }
  .advance .advance--article-content figure:not(.right):not(.left):not(.media-right):not(.media-left):not(.image-right):not(.image-left),
.advance .advance--article-content .media-item:not(.right):not(.left):not(.media-right):not(.media-left):not(.image-right):not(.image-left) {
    width: 100%;
  }
  .advance .advance--article-content figure img,
.advance .advance--article-content .media-item img {
    width: auto;
  }
  .advance .pull-quote,
.advance .advance--article-content .pull-quote {
    margin: 2.5rem auto 3rem;
    padding: 70px 30px 30px;
  }
  .advance .pull-quote .card-header .profile-image,
.advance .advance--article-content .pull-quote .card-header .profile-image {
    padding: 0 20px;
    font-size: 100px;
  }
  .advance .pull-quote .card-header .profile-image::after,
.advance .advance--article-content .pull-quote .card-header .profile-image::after {
    width: 54px;
    height: 44px;
    background-size: 54px 44px;
    top: 18px;
    left: 22px;
  }
  .advance .pull-quote .card-header::before,
.advance .advance--article-content .pull-quote .card-header::before {
    border-top: 102px solid #f7aa00;
    border-right: 106px solid transparent;
  }
  .advance .pull-quote .card-body,
.advance .pull-quote .card-footer,
.advance .advance--article-content .pull-quote .card-body,
.advance .advance--article-content .pull-quote .card-footer {
    padding-right: 79px;
    padding-left: 79px;
  }
}

.advance .advance--directory-grid {
  padding-top: 36px;
  padding-bottom: 36px;
  margin-top: -4px;
}
.advance .advance--directory-grid .row {
  row-gap: 0px;
  padding-bottom: 20px;
}
.advance .advance--directory-grid .row .col-12 {
  margin-bottom: 1rem;
}
.advance .advance--directory-grid .row .card {
  height: 100%;
  padding: 39px 40px 18px;
  position: relative;
  border-width: 10px;
  margin: 0;
}
.advance .advance--directory-grid .row .card .row {
  gap: 19px;
  padding-top: 20px;
}
.advance .advance--directory-grid .row .card a {
  display: block;
  padding-bottom: 15px;
  position: relative;
  font-weight: 600;
  font-size: 16px;
}
.advance .advance--directory-grid .row .card a::before {
  content: "";
  background: #47d2d7;
  height: 4px;
  width: 39px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.advance .advance--directory-grid .row .card a:hover {
  color: #00364a;
  text-decoration: none;
}
.advance .advance--directory-grid .row .card.view-all {
  padding: 30px;
  border-bottom-width: 10px;
  height: 100px;
}
.advance .advance--directory-grid .row .card.view-all a {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  color: #00364a;
}
.advance .advance--directory-grid .row .card.view-all a::before {
  display: none;
}
.advance .advance--directory-grid .row .card.view-all:hover {
  border-color: #47d2d7;
}
.advance .advance--directory-grid .row .card.view-all:hover a {
  color: #00364a;
  background: #eff3f7;
}
@media (min-width: 768px) {
  .advance .advance--directory-grid {
    padding-top: 63px;
    padding-bottom: 63px;
    margin-top: 0px;
  }
  .advance .advance--directory-grid .container > .row {
    row-gap: 30px;
    column-gap: 20px;
    margin: 0;
  }
  .advance .advance--directory-grid .container > .row > div {
    padding: 0;
    flex: 0 0 calc(50% - 13.33px);
    max-width: calc(50% - 13.33px);
  }
  .advance .advance--directory-grid .container > .row .card {
    border-width: 14px;
    padding: 37px 36px 28px;
    margin: 0;
  }
  .advance .advance--directory-grid .container > .row .card .row {
    padding-top: 25px;
  }
  .advance .advance--directory-grid .container > .row .card.view-all {
    height: 100%;
  }
  .advance .advance--directory-grid .container > .row .card.view-all a {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .advance .advance--directory-grid .container > .row {
    row-gap: 38px;
    column-gap: 20px;
  }
  .advance .advance--directory-grid .container > .row > div {
    padding: 0;
    flex: 0 0 calc(33.33% - 13.33px);
    max-width: calc(33.33% - 13.33px);
  }
}

.advance .advance--featured-article {
  padding-top: 36px;
  padding-bottom: 36px;
}
.advance .advance--featured-article .featured-article--info--container {
  display: flex;
  align-items: center;
}
.advance .advance--featured-article .featured-article--info {
  padding-top: 2px;
  padding-bottom: 34px;
}
.advance .advance--featured-article .featured-article--info .featured-article--contenttype {
  padding-bottom: 18px;
  color: #f7aa00;
}
.advance .advance--featured-article .featured-article--info h1 {
  margin: 0 0 10px;
}
.advance .advance--featured-article .featured-article--info .featured-article--desc {
  padding-bottom: 18px;
}
.advance .advance--featured-article .featured-article--info .featured-article--desc p {
  color: #c5d6df;
  font-weight: 400;
  line-height: initial;
}
.advance .advance--featured-article .featured-article--info .featured-article--button {
  padding-top: 2px;
}
.advance .advance--featured-article .featured-article--info .featured-article--button .btn, .advance .advance--featured-article .featured-article--info .featured-article--button .marketo-form .mktoForm .mktoButton, .marketo-form .mktoForm .advance .advance--featured-article .featured-article--info .featured-article--button .mktoButton {
  margin-top: 5px;
}
.advance .advance--featured-article .featured-article--image {
  border: 10px #24a0a5 solid;
  padding: 15px 0 0 15px;
  margin: 0 -30px 20px -20px;
  position: relative;
}
.advance .advance--featured-article .featured-article--image a {
  display: block;
  margin: 0 0 -32px;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}
.advance .advance--featured-article .featured-article--image img {
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (min-width: 992px) {
  .advance .advance--featured-article {
    padding-top: 63px;
    padding-bottom: 63px;
  }
  .advance .advance--featured-article .featured-article--info {
    padding-top: 0;
    padding-bottom: 28px;
  }
  .advance .advance--featured-article .featured-article--info .featured-article--contenttype {
    padding-bottom: 20px;
  }
  .advance .advance--featured-article .featured-article--info h1 {
    margin: 0 0 6px;
  }
  .advance .advance--featured-article .featured-article--info .featured-article--desc {
    max-width: 520px;
    padding-bottom: 2px;
  }
  .advance .advance--featured-article .featured-article--info .featured-article--desc p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
  }
  .advance .advance--featured-article .featured-article--image {
    margin: -40px 25px 14px -14px;
    border-width: 14px;
    padding: 21px 0 0 23px;
  }
  .advance .advance--featured-article .featured-article--image a {
    margin: 0 -39px -49px 0;
  }
}

.advance .advance--featured-specialties {
  padding-top: 36px;
  padding-bottom: 36px;
}
.advance .advance--featured-specialties a:hover {
  color: #00364a;
  text-decoration: none;
}
.advance .advance--featured-specialties .card {
  padding: 33px 20px 34px;
  border: 10px solid #c5d6df;
  margin: 0 -30px;
}
.advance .advance--featured-specialties .card .featured-specialties--header {
  padding-bottom: 12px;
  justify-content: flex-start;
  flex-direction: column;
}
.advance .advance--featured-specialties .card .featured-specialties--header .col:nth-child(2) {
  display: flex;
  flex-grow: unset;
}
.advance .advance--featured-specialties .card .featured-specialties--header .col:nth-child(2) a {
  white-space: nowrap;
  text-decoration: underline;
  display: block;
  padding-top: 5px;
  color: #4d7489;
}
.advance .advance--featured-specialties .card .featured-specialties--header .col:nth-child(2) a:hover {
  color: #00364a;
}
.advance .advance--featured-specialties .card .featured-specialties--content > div {
  padding-top: 27px;
}
.advance .advance--featured-specialties .card .featured-specialties--content > div a {
  display: block;
  position: relative;
  padding-bottom: 16px;
}
.advance .advance--featured-specialties .card .featured-specialties--content > div a::before {
  content: "";
  background: #47d2d7;
  height: 4px;
  width: 39px;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (min-width: 992px) {
  .advance .advance--featured-specialties {
    padding-top: 63px;
    padding-bottom: 63px;
  }
  .advance .advance--featured-specialties .card {
    padding: 38px 85px 76px;
    border-width: 14px;
    margin: 0;
  }
  .advance .advance--featured-specialties .card .featured-specialties--header {
    padding-bottom: 10px;
    justify-content: space-between;
    flex-direction: row;
  }
  .advance .advance--featured-specialties .card .featured-specialties--header .col:nth-child(2) {
    justify-content: flex-end;
  }
  .advance .advance--featured-specialties .card .featured-specialties--header .col:nth-child(2) a {
    padding-top: 3px;
  }
  .advance .advance--featured-specialties .card .featured-specialties--content > div {
    padding-top: 30px;
  }
}

.advance main .advance--form {
  padding-top: 36px;
  padding-bottom: 36px;
}
.advance main .marketo-form {
  padding: 8px 0 0;
  margin: 0;
  max-width: 846px;
}
.advance main .marketo-form .form-description p {
  line-height: 1.5;
}
.advance main .marketo-form .thank-you-message {
  background: #00364a;
}
.advance main .marketo-form .thank-you-message .thank-you-bubble:after {
  border-top-color: #00364a;
}
.advance main .marketo-form .mktoForm {
  padding: 27px 0 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.advance main .marketo-form .mktoForm > .mktoFormRow {
  flex-basis: 100%;
  margin: 0 0 20px !important;
}
.advance main .marketo-form .mktoForm > .mktoFormRow--hidden {
  flex-basis: 100%;
  height: 0;
  overflow: hidden;
  margin: 0 !important;
}
.advance main .marketo-form .mktoForm .mktoButtonRow {
  flex-basis: 100%;
}
.advance main .marketo-form .mktoForm .mktoButtonRow button {
  color: #212529;
  background-color: #47d2d7;
  border-color: #47d2d7;
  min-width: 122px;
  min-height: 48px;
}
.advance main .marketo-form .mktoForm .mktoButtonRow button:hover {
  color: #fff;
  background-color: #00364a;
  border-color: #00364a;
}
.advance main .marketo-form .mktoForm .mktoButtonRow button:focus, .advance main .marketo-form .mktoForm .mktoButtonRow button.focus {
  color: #fff;
  background-color: #00364a;
  border-color: #00364a;
  box-shadow: 0 0 0 0.2rem rgba(65, 184, 189, 0.5);
}
.advance main .marketo-form .mktoForm .mktoButtonRow button.disabled, .advance main .marketo-form .mktoForm .mktoButtonRow button:disabled {
  color: #212529;
  background-color: #47d2d7;
  border-color: #47d2d7;
}
.advance main .marketo-form .mktoForm .mktoButtonRow button:not(:disabled):not(.disabled):active, .advance main .marketo-form .mktoForm .mktoButtonRow button:not(:disabled):not(.disabled).active, .show > .advance main .marketo-form .mktoForm .mktoButtonRow button.dropdown-toggle {
  color: #fff;
  background-color: #2abcc1;
  border-color: #28b2b7;
}
.advance main .marketo-form .mktoForm .mktoButtonRow button:not(:disabled):not(.disabled):active:focus, .advance main .marketo-form .mktoForm .mktoButtonRow button:not(:disabled):not(.disabled).active:focus, .show > .advance main .marketo-form .mktoForm .mktoButtonRow button.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 184, 189, 0.5);
}
.advance main .marketo-form .mktoForm .mktoInstruction,
.advance main .marketo-form .mktoForm .mktoErrorMsg {
  font-size: 14px;
}
.advance main .marketo-form .mktoForm label {
  margin-bottom: 11px;
}
.advance main .marketo-form .mktoForm input:not(:placeholder-shown),
.advance main .marketo-form .mktoForm textarea:not(:placeholder-shown) {
  background: #fff;
}
.advance main .marketo-form .mktoForm input:focus,
.advance main .marketo-form .mktoForm textarea:focus {
  border-color: #47d2d7;
}
.advance main .marketo-form .mktoForm textarea {
  height: 151px;
}
.advance main .marketo-form .mktoForm select {
  background-image: url("../images/media-library/advance/carat-circle.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
  background-size: 32px 32px;
}
.advance main .marketo-form .mktoForm select:focus {
  border-color: #47d2d7;
}
.advance main .marketo-form .mktoForm .mktoRadioList,
.advance main .marketo-form .mktoForm .mktoCheckboxList {
  margin: 5px 0 10px;
}
.advance main .marketo-form .mktoForm .mktoRadioList label,
.advance main .marketo-form .mktoForm .mktoCheckboxList label {
  font-weight: 400;
  margin: 0;
  padding: 0;
}
.advance main .marketo-form .mktoForm .mktoRadioList + .mktoCheckboxList, .advance main .marketo-form .mktoForm .mktoRadioList + .mktoRadioList,
.advance main .marketo-form .mktoForm .mktoCheckboxList + .mktoCheckboxList,
.advance main .marketo-form .mktoForm .mktoCheckboxList + .mktoRadioList {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .advance main .advance--form {
    padding-top: 63px;
    padding-bottom: 63px;
  }
  .advance main .marketo-form {
    padding: 0;
  }
  .advance main .marketo-form .mktoForm {
    padding: 27px 0 7px;
  }
  .advance main .marketo-form .mktoForm > .mktoFormRow {
    flex-basis: calc(50% - 15px);
  }
  .advance main .marketo-form .mktoForm > .mktoFormRow--full {
    flex-basis: 100%;
    max-width: 100%;
  }
  .advance main .marketo-form .mktoForm .mktoRadioList label,
.advance main .marketo-form .mktoForm .mktoCheckboxList label {
    margin-bottom: 0;
  }
}

.advance .advance--form-bar {
  position: relative;
  margin-bottom: -10px;
}
.advance .advance--form-bar .row > div {
  position: static !important;
}
.advance .advance--form-bar .form-bar--form-trigger {
  position: relative;
  padding: 0 20px;
  margin: 0 -20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 60px;
  color: #00364a;
}
.advance .advance--form-bar .form-bar--form-trigger .icon {
  height: 27px;
  width: 27px;
  margin: 0 20px 0 0;
}
.advance .advance--form-bar .form-bar--form-trigger .icon.icon-carat-circle {
  position: absolute;
  right: 0;
  top: 14px;
  transform: rotate(90deg);
  height: 33px;
  width: 33px;
}
.advance .advance--form-bar .form-bar--form-contain {
  position: relative;
  display: none;
  left: 0px;
  right: 0px;
  z-index: 999;
  margin: 0 -20px;
  background: #fff;
  border: 10px #47d2d7 solid;
  padding: 30px 3px 18px;
}
.advance .advance--form-bar .form-bar--form-contain .heading {
  margin-bottom: 10px;
}
.advance .advance--form-bar .form-bar--form-contain p {
  color: #4d7489;
}
.advance .advance--form-bar .form-bar--form-contain form {
  padding-top: 16px;
}
.advance .advance--form-bar .form-bar--form-contain form input {
  height: 56px;
  line-height: 56px;
}
.advance .advance--form-bar .form-bar--form-contain form > .mktoFormRow {
  margin: 0 0 14px !important;
}
.advance .advance--form-bar .form-bar--form-contain form .mktoErrorMsg {
  padding: 11px 0 3px;
}
.advance .advance--form-bar .form-bar--form-contain .thank-you-message {
  background: #47d2d7;
}
.advance .advance--form-bar .form-bar--form-contain .thank-you-message .thank-you-bubble {
  border-color: #00364a;
  color: #00364a;
}
.advance .advance--form-bar .form-bar--form-contain .thank-you-message .thank-you-bubble:after {
  border-top-color: #47d2d7;
}
.advance .advance--form-bar .form-bar--form-contain .thank-you-message .thank-you-bubble:before {
  border-top-color: #00364a;
}
.advance .advance--form-bar .form-bar--form-contain .thank-you-message .thank-you-content,
.advance .advance--form-bar .form-bar--form-contain .thank-you-message .thank-you-content p {
  color: #00364a;
}
.advance .advance--form-bar .form-contain--yellow {
  border-color: #f7aa00;
}
.advance .advance--form-bar .form-contain--yellow .thank-you-message {
  background: #f7aa00;
}
.advance .advance--form-bar .form-contain--yellow .thank-you-message .thank-you-bubble:after {
  border-top-color: #f7aa00;
}
.advance .advance--form-bar .active .form-bar--form-trigger .icon-carat-circle {
  transform: rotate(-90deg);
}
.advance .advance--form-bar .active .form-bar--form-contain {
  display: block;
}
@media (min-width: 768px) {
  .advance .advance--form-bar {
    position: relative;
    margin: 0;
  }
  .advance .advance--form-bar .row > div {
    position: static !important;
  }
  .advance .advance--form-bar .form-bar--form-trigger {
    font-size: 18px;
    line-height: 28px;
    height: 68px;
  }
  .advance .advance--form-bar .form-bar--form-trigger .icon {
    height: 33px;
    width: 33px;
  }
  .advance .advance--form-bar .form-bar--form-trigger .icon.icon-carat-circle {
    top: 18px;
  }
  .advance .advance--form-bar .form-bar--form-trigger.advance--bk--teal {
    padding-right: 50px;
  }
  .advance .advance--form-bar .form-bar--form-trigger.advance--bk--teal .icon-carat-circle {
    right: 75px;
  }
  .advance .advance--form-bar .form-bar--form-trigger.advance--bk--yellow {
    padding-left: 75px;
  }
  .advance .advance--form-bar .form-bar--form-trigger::after {
    position: absolute;
    width: 50vw;
    top: 0;
    content: "";
    height: 100%;
  }
  .advance .advance--form-bar .form-bar--form-trigger.advance--bk--teal::after {
    background: #47d2d7;
    right: 100%;
  }
  .advance .advance--form-bar .form-bar--form-trigger.advance--bk--yellow::after {
    background: #f7aa00;
    left: 100%;
  }
  .advance .advance--form-bar .form-bar--form-contain {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0;
    padding: 55px 30px 50px;
    border-width: 14px;
  }
  .advance .advance--form-bar .form-bar--form-contain .row > div:first-child {
    padding-right: 3.5vw;
  }
  .advance .advance--form-bar .form-bar--form-contain form {
    margin: -5px 0 0;
    padding: 0;
  }
  .advance .advance--form-bar .form-bar--form-contain form > .mktoFormRow {
    margin: 0 0 18px !important;
  }
}
@media (min-width: 1200px) {
  .advance .advance--form-bar .form-bar--form-contain .row > div:first-child {
    padding-right: 7.4vw;
  }
}

.advance .advance-page-header {
  background: #00364a;
  position: relative;
  max-height: 800px;
  padding-top: 16px;
  padding-bottom: 20px;
  border-bottom: 14px #24a0a5 solid;
}
.advance .advance-page-header .page-header-inner {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background: transparent;
  position: relative;
  z-index: 1;
}
.advance .advance-page-header .page-header-inner h1 {
  color: #fff;
  padding: 26px 0 3px;
  margin: 0;
}
.advance .advance-page-header .page-header-inner .description {
  color: #fff;
}
.advance .advance-page-header .page-header-inner .icon {
  fill: #fff;
  stroke: #fff;
}
.advance .advance-page-header .page-header-inner .language-dropdown .dropdown-toggle {
  color: #fff;
}
.advance .advance-page-header .page-header-inner .social,
.advance .advance-page-header .page-header-inner .language-dropdown {
  display: none;
}
.advance .advance-page-header .page-header-inner .description {
  color: #fff;
}
.advance .advance-page-header .divider {
  display: none;
}
@media (min-width: 768px) {
  .advance .advance-page-header {
    background: #24a0a5;
    border-bottom: none;
    padding-top: 18px;
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .advance .advance-page-header .page-header-inner {
    max-width: 1317px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .advance .advance-page-header .page-header-inner {
    max-width: 1318px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .advance .advance-page-header .page-header-inner {
    max-width: 1319px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .advance .advance-page-header .page-header-inner {
    max-width: 1320px !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 768px) {
  .advance .advance-page-header .page-header-inner h1 {
    padding: 20px 0 50px;
  }
}
@media (min-width: 768px) {
  .advance .advance-page-header .page-header-inner .description {
    max-width: 40%;
  }
}
@media (min-width: 768px) {
  .advance .advance-page-header .page-header-inner .language-dropdown {
    right: 15px;
  }
}
@media (min-width: 768px) {
  .advance .advance-page-header .page-header--extend {
    content: "";
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .advance .advance-page-header .page-header--extend::after {
    background: #00364a;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 49.5vw;
    content: "";
    transform: rotate(12deg);
    height: 900px;
    transform-origin: top right;
  }
}

.advance .advance-page-header.has-image {
  position: relative;
  border-bottom: none;
}
.advance .advance-page-header.has-image .page-header-inner h1 {
  padding-top: 28px;
}
.advance .advance-page-header.has-image .hero-img {
  margin: 30px -15px -24px;
  display: block;
}
.advance .advance-page-header.has-image .hero-img img {
  max-height: 48.6vw;
  width: 100%;
  object-fit: cover;
  object-position: top right;
}
@media (min-width: 768px) {
  .advance .advance-page-header.has-image .page-header-inner {
    min-height: 325px;
    max-height: 500px;
    z-index: 2;
    position: relative;
  }
  .advance .advance-page-header.has-image .page-header-inner h1 {
    padding-top: 65px;
    max-width: 45%;
  }
  .advance .advance-page-header.has-image .hero-img {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    z-index: 0;
  }
  .advance .advance-page-header.has-image .hero-img img {
    width: 75%;
    height: 100%;
    max-height: unset;
  }
  .advance .advance-page-header.has-image .page-header--extend {
    z-index: 1;
  }
  .advance .advance-page-header.has-image .page-header--extend::after {
    background: linear-gradient(90deg, rgb(0, 54, 74) 60%, rgba(0, 54, 74, 0.95) 85%, rgba(0, 54, 74, 0.85));
  }
}
@media (min-width: 992px) {
  .advance .advance-page-header.has-image .page-header-inner {
    min-height: 375px;
    max-height: 550px;
  }
}
@media (min-width: 1200px) {
  .advance .advance-page-header.has-image .page-header-inner {
    min-height: 425px;
    max-height: 600px;
  }
}
@media (min-width: 1400px) {
  .advance .advance-page-header.has-image .page-header-inner {
    min-height: 475px;
    max-height: 650px;
  }
}

.advance .generic .rte p,
.advance .generic .rte li,
.advance .generic .rte span, .advance .generic.rte p,
.advance .generic.rte li,
.advance .generic.rte span {
  font-size: 1rem;
  line-height: 1.125;
}
.advance .generic .rte p, .advance .generic.rte p {
  line-height: 1.3;
}
.advance .generic .rte p:first-of-type, .advance .generic.rte p:first-of-type {
  padding-top: 0;
}
.advance .generic .rte ul, .advance .generic.rte ul {
  padding-left: 0.5rem;
}
.advance .generic .alt-background :last-child {
  margin-bottom: 0;
}