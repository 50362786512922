html,
body {
	font-family: $font-family-sans-serif;
	color: $color-gray-darkest;
}

h1,
.heading-1 {
	@include text(beta);
	color: $color-primary-dark-blue;
	font-weight: $font-weight-bold;
	margin-bottom: 1.5rem;

	&.heading-w-border {
		padding-bottom: 0.5rem;
		border-bottom: 0.25rem solid $color-illumination-yellow !important;
		border-image: linear-gradient(to right, $color-illumination-yellow 0, $color-illumination-yellow 4.375rem, transparent 4.375rem, transparent 100%) 1;
	}
}

h2,
.heading-2 {
	@include text(beta);
	color: $color-primary-dark-blue;
	font-weight: $font-weight-bold;
	margin-bottom: 0.25rem;
}

h3,
.heading-3 {
	@include text(gamma);
	color: $color-primary-dark-blue;
	font-weight: $font-weight-semi-bold;
	margin-bottom: 0.25rem;
}

h4,
.heading-4 {
	@include text(delta);
	color: $color-primary-dark-blue;
	font-weight: $font-weight-bold;
	margin-bottom: 0.25rem;
}

h5,
.heading-5 {
	@include text(epsilon);
	color: $color-primary-dark-blue;
	font-weight: $font-weight-bold;
	margin-bottom: 0.25rem;
}

h6,
.heading-6 {
	@include text(epsilon);
	color: $color-primary-dark-blue;
	font-weight: $font-weight-semi-bold;
	margin-bottom: 0.25rem;
}

@include media-breakpoint-up(lg) {
	h1,
	.heading-1 {
		@include text(omega);
		margin-bottom: 2rem;

		&.heading-w-border {
			padding-bottom: 0.5rem;
			border-bottom: 0.25rem solid $color-illumination-yellow !important;
			border-image: linear-gradient(to right, $color-illumination-yellow 0, $color-illumination-yellow 4.375rem, transparent 4.375rem, transparent 100%) 1;
		}
	}

	h2,
	.heading-2 {
		@include text(alpha);
		margin-bottom: 0.375rem;
	}

	h3,
	.heading-3 {
		@include text(beta);
		margin-bottom: 0.375rem;
	}

	h4,
	.heading-4 {
		@include text(gamma);
		margin-bottom: 0.375rem;
	}

	h5,
	.heading-5 {
		@include text(delta);
		margin-bottom: 0.375rem;
	}

	h6,
	.heading-6 {
		@include text(epsilon);
		margin-bottom: 0.375rem;
	}
}

/* TODO: Add rte class to generic RTE containers in cshtml views */
.rte {
	h1,
	.heading-1 {
		color: $color-primary-dark-blue;
		padding-top: 2rem;

		&:first-child {
			margin-bottom: 0.375rem;
			padding-top: 0rem;
		}
	}

	h2,
	.heading-2 {
		color: $color-primary-dark-blue;
		padding-top: 1.5rem;

		&:first-child {
			padding-top: 0rem;
		}
	}

	h3,
	.heading-3 {
		color: $color-primary-dark-blue;
		padding-top: 1.25rem;

		&:first-child {
			padding-top: 0rem;
		}
	}

	h4,
	.heading-4 {
		color: $color-primary-dark-blue;
		padding-top: 1.125rem;

		&:first-child {
			padding-top: 0rem;
		}
	}

	h5,
	.heading-5 {
		color: $color-primary-dark-blue;
		padding-top: 1rem;
	}

	h6,
	.heading-6 {
		color: $color-primary-dark-blue;
		padding-top: 0.875rem;
	}
}

p,
.body-copy {
	@include text(delta, gamma);
	font-weight: $font-weight-normal;

	&.body-copy-secondary {
		@include text(epsilon, delta);
	}
}

.notation {
	@include text(zeta, epsilon);
	font-weight: $font-weight-normal;
	font-style: italic;
}

ol {
	li {
		@include text(delta);
	}
}

a {
	font-weight: $font-weight-semi-bold;
	color: $color-secondary-light-blue;

	&:focus,
	&:hover {
		color: $color-secondary-light-blue;
	}
}

/*
 * Default ul without any classes to not step on toes of Bootstrap's numerous custom ul classes.
 * .bulleted-list class to apply elsewhere as needed
 */
ul:not([class]),
ul.two-columns,
ul.three-columns,
ul.four-columns,
.bulleted-list {
	@include text(epsilon);
	color: $color-gray-darkest;
	list-style: none;
	margin-bottom: 2rem;
	padding-left: 1.5rem;

	> li {
		@include text(epsilon, delta);
		margin-left: 1rem;

		&:before {
			color: $color-gray-darkest;
			content: '\2022';
			display: inline-block;
			margin-left: -1rem;
			width: 1rem;
		}

		&:not(:last-of-type) {
			margin-bottom: 0.375rem;
		}

		p {
			@include text(epsilon, delta);
		}
	}

	ol,
	ul {
		margin-bottom: 0.25rem;
		margin-top: 0.25rem;
	}
}

ol:not([class]),
ol.two-columns,
ol.three-columns,
ol.four-columns {
	@include text(epsilon);
	> li {
		@include text(epsilon);

		&:not(:last-of-type) {
			margin-bottom: 0.25rem;
		}

		p {
			@include text(epsilon, delta);
		}
	}

	ol,
	ul {
		margin-bottom: 0.25rem;
		margin-top: 0.25rem;
	}
}

ol,
ul {
	@include media-breakpoint-up(md) {
		&.two-columns,
		&.three-columns,
		&.four-columns {
			columns: 2;
			column-gap: 1rem;
		}
	}

	@include media-breakpoint-up(lg) {
		&.three-columns,
		&.four-columns {
			columns: 3;
			column-gap: 1rem;
		}
	}

	@include media-breakpoint-up(xl) {
		&.four-columns {
			columns: 4;
			column-gap: 1rem;
		}
	}
}
