.location-result {
	.doctor-location {
		padding: 1rem 0;
	}

	.card-footer {
		.appointment-type-label {
			border-bottom: 1px solid $color-neutral;
			margin-bottom: 1rem;
			padding-bottom: 1rem;
		}

		&.has-urgent-care-check-in {
			.appointment-heading {
				@include text(epsilon);
				margin-bottom: 1.75rem;
			}

			.wait-time,
			.patients-in-line {
				@include text(eta);
				color: $color-quick-links;
				font-weight: $font-weight-bold;
			}

			.wait-time {
				border-bottom: 1px solid $color-neutral;
				margin-bottom: 0.5rem;
				padding-bottom: 0.5rem;
			}

			.patients-in-line {
				margin-bottom: 1.75rem;
			}

			.btn-primary {
				width: 170px;
			}
		}

		.location-address {
			display: none;
		}
	}

	@include media-breakpoint-up(md) {
		.card-header {
			.doctor-location {
				display: none;
			}
		}

		.card-footer {
			.location-address {
				display: block;
			}

			.address {
				margin-bottom: 0.25rem;
			}
		}

		.view-mode-map & {
			.card-header {
				.doctor-location {
					display: flex;
					flex-direction: column;
				}

				.phone-number {
					padding-top: 0.375rem;
					order: 1;
				}
			}
		}
	}
}
