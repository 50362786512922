// strip top level padding from direct child components (which should all be divs) in two-column layouts
.two-column,
.three-column {
	[class^='col'] > div:not(.alt-background) {
		//optional reset here... since it would never hit the max-width when inside specialties inner
		// max-width: none;
		padding-left: 0;
		padding-right: 0;
	}
}

noscript {
	display: block;
	padding: 2rem;
	text-align: center;
	width: 100%;
}
