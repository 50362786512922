.content-grid {
	@include make-container();
	@include make-container-max-width();
	background-color: $color-white;
	margin-bottom: 2rem;

	.col {
		flex-basis: 100%;
		margin-bottom: 1.25rem;
	}

	@include media-breakpoint-up(md) {
		.row {
			.col {
				&:nth-child(-n + 2):not(:nth-last-child(3)) {
					flex-basis: 0;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.row {
			.col {
				&:nth-child(-n + 3):not(:nth-last-child(2)) {
					flex-basis: 0;
				}
			}
		}
	}
}
