.phcs-page {
	.subtext {
		color: $color-black;
	}

	.lighter-text {
		color: $color-healthplan-gray;
	}

	ol {
		padding-left: 1.25rem;
	}

	a[target='_blank'] {
		padding-right: 1.75rem;
		position: relative;
		display: inline-block;

		&:after {
			background-image: url(/assets/images/external-black.png);
			background-size: cover;
			background-position: 50% 50%;
			bottom: -3px;
			display: inline-block;
			content: '';
			height: 16px;
			position: relative;
			width: 28px;
			margin-right: -28px;
		}
	}

	.phcs-img {
		display: block;
		margin-left: -1rem;
		width: 200px;
	}
}
