.related-areas {
	@include make-container();
	@include make-container-max-width();
	margin-bottom: 2rem;

	.related-areas-inner {
		background-color: $color-precision-gray;
		margin-bottom: 1.25rem;
		padding: 2.5rem 2rem;
		border-radius: 5px;
	}

	.title {
		@include text(gamma);
		font-weight: $font-weight-bold;
		color: $color-white;
		margin-bottom: 1rem;
	}

	.btn {
		// border-radius: 0;
		margin-bottom: 1rem;
		padding: 0.75rem 2rem;
		width: 100%;

		&:hover,
		&:focus {
			color: $color-illumination-yellow;
			background-color: $color-precision-gray;
			//border-color: $color-secondary-light-blue;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-up(md) {
		.related-areas-inner {
			align-items: center;
			display: flex;
			justify-content: space-between;
			padding: 1rem 1.5rem;
		}

		.title {
			margin: 0;
			padding-right: 2rem;
		}

		.area-buttons {
			display: flex;
			justify-content: space-around;
		}

		.btn {
			align-items: center;
			display: flex;
			flex: 1 1 0;
			justify-content: center;
			margin-bottom: 0;
			margin-right: 1rem;
			width: auto;

			&:last-child {
				margin-right: 0;
			}
		}

		.two-column & {
			.related-areas-inner {
				display: block;
				padding: 2.5rem 2rem;
			}

			.title {
				margin-bottom: 1rem;
				padding-right: 0;
			}

			.area-buttons {
				display: block;
			}

			.btn {
				margin-bottom: 1rem;
				margin-right: 0;
				padding: 0.75rem;
				width: 100%;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.area-buttons {
			flex-basis: 80%;
		}

		.btn {
			margin-right: 2rem;
		}

		.two-column & {
			.related-areas-inner {
				display: flex;
				padding: 1rem 1.5rem;
			}

			.title {
				@include text(delta);
				margin-bottom: 0;
				padding-right: 1.5rem;
			}

			.area-buttons {
				display: flex;
			}

			.btn {
				margin-bottom: 0;
				margin-right: 1rem;
				width: auto;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}
