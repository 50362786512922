@import 'search-page/filter-list';
@import 'search-page/results';
@import 'search-page/doctor-result';
@import 'search-page/location-map';
@import 'search-page/location-result';

.search-page {
	min-height: calc(100vh - 90px);
	padding-top: 1.5em;

	&:not(.find-a-location) {
		@include make-container();
		@include make-container-max-width();
	}

	&.find-a-location {
		.search-title {
			@include make-container();
			@include make-container-max-width();

			&:after {
				background-color: $color-primary-yellow;
				display: block;
				position: relative;
				content: '';
				height: 4px;
				margin: 1rem auto;
				width: 4.375rem;
			}
		}

		.search-page-wrapper-outer {
			@include make-container();
			@include make-container-max-width();

			&.view-mode-map {
				.results-list {
					margin-left: -15px;
					padding-top: 0;
					width: calc(100% + 30px);

					.no-results-message {
						box-shadow: 0 10px 24px rgba($color-black, 0.12);
						left: 50%;
						margin-bottom: 0;
						margin-top: 0;
						max-width: 675px;
						position: absolute;
						top: 50%;
						transform: translate(-50%, -50%);
						width: calc(100% + 15px);
						z-index: 2;

						+ .location-map {
							pointer-events: none;
						}

						.card-body {
							padding: 0.75rem 1rem;
						}

						h2 {
							margin-bottom: 1rem;
						}

						p {
							@include text(zeta);
						}

						ul {
							li {
								@include text(zeta);
							}
						}
					}
				}

				.loading-overlay {
					align-items: center;
					background-color: rgba($color-white, 0.5);
					padding-top: 0.75rem;
				}
			}
		}

		.results-controls {
			// > * {
			// 	margin-top: 1rem;
			// 	width: 50%;
			// }

			// .results-view-mode {
			// 	order: 1;
			// }

			// .results-view-mode,
			// .results-control-dropdown {
			// 	text-align: right;
			// }

			// .results-control-dropdown {
			// 	select {
			// 		text-align-last: right;
			// 	}
			// }
		}

		.results-view-mode {
			display: block;
			width: 20%;
		}
	}

	.search-title {
		@include text(gamma);
		margin-bottom: 1.25rem;
		text-align: center;
	}

	.divider {
		margin: 1rem auto;
	}

	.seo-content {
		background-color: $color-neutral-lightest;
		margin-bottom: 1.5rem;
		margin-top: 2rem;
		padding: 1.25rem 1.5rem;

		p {
			@include text(zeta);

			&:last-of-type {
				margin-bottom: 0;
			}

			a {
				font-weight: $font-weight-bold;
			}
		}
	}

	.seo-title {
		@include text(gamma);
		color: $color-gray-darkest;
		font-weight: $font-weight-bold;
		margin-bottom: 1rem;
	}

	.search-page-wrapper {
		@include make-row();
	}

	.search-page-rail {
		@include make-col-ready();
	}

	.search-page-results {
		@include make-col-ready();
	}

	@media screen and (max-width: 768px) and (orientation: landscape) {
		&.find-a-location {
			.search-page-wrapper-outer {
				&.view-mode-map {
					.results-list-container {
						min-height: calc(100vh - 70px);
					}
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		&.find-a-location {
			.results-view-mode {
				width: auto;
				order: 1;
			}

			.search-page-wrapper-outer {
				&.view-mode-map {
					.results-list-container {
						min-height: 320px;
					}

					.results-list {
						.no-results-message {
							width: 100%;

							.card-body {
								padding: 2rem;
							}

							h2 {
								margin-bottom: 1.25rem;
							}

							p {
								@include text(delta);
							}

							ul {
								li {
									@include text(delta);
								}
							}
						}
					}
				}

				.results-controls {
					// > * {
					// 	margin-top: 0;
					// 	width: auto;
					// }

					// .results-count {
					// 	flex-grow: 0;
					// }

					// .mobile-filters-toggle {
					// 	order: 1;
					// }
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		min-height: calc(100vh - 141px);
		padding-top: 2em;

		.search-title {
			@include text(delta);
			text-align: left;
		}

		.divider {
			margin: 1rem auto 1rem 0;
		}

		.seo-content {
			margin-top: 0;

			p {
				@include text(epsilon);
			}
		}

		.mobile-filters-toggle {
			display: none;
		}

		.search-page-rail {
			@include make-col(4);
		}

		.search-page-results {
			@include make-col(8);
		}

		&.find-a-location {
			.search-title {
				text-align: left;

				&::after {
					margin: 1rem auto 1rem 0;
				}
			}

			.divider {
				margin: 1rem auto 1rem 15px;
			}

			.search-page-wrapper-outer {
				&.view-mode-map {
					margin-left: 0;

					.search-page-results {
						margin-top: -60px;
					}

					.seo-content,
					.results-controls,
					.results-list-container {
						width: calc(100vw - calc(50% + 45px));
					}

					.results-list {
						margin-left: 0;
						padding-top: 1rem;
						width: calc(100% + 15px);

						.no-results-message {
							margin: 0 0.5rem;

							.card-body {
								padding: 2.5rem;
							}
						}
					}

					.loading-overlay {
						left: 0;
						padding-top: 1rem;
						width: calc(100% + 15px);

						&:after {
							margin-left: -1rem;
							margin-top: -1rem;
						}
					}
				}
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.search-title {
			&.view-mode-map {
				margin-left: 0;
			}
		}
	}
}
