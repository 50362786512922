.generic {
	@include make-container();
	@include make-container-max-width();
	margin-bottom: 2rem;

	.alt-background {
		background-color: $color-neutral-lightest;
		padding: 1.5rem;
		border-radius: 5px;

		.rte {
			p:first-of-type {
				padding-top: 0;
			}
		}
	}

	.rte {
		p:first-of-type {
			padding-top: 0; //1rem
		}

	}

	p, .alt-background {
		a {
			font-weight: $font-weight-bold;
		}
	}

	li > a {
		font-weight: $font-weight-bold;
	}

	td:first-child {
		a {
			font-weight: inherit;
		}
	}

	li > a {
		font-weight: $font-weight-bold;
	}

	td:first-child {
		a {
			font-weight: inherit;
		}
	}

	.btn {
		// margin: 0rem 0.5rem 0.5rem;
		margin: 0.5rem 0;
	}
}
