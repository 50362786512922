.urgent-care-check-in {
	background-color: $color-neutral-lighter;
	padding: 2rem;
	margin-bottom: 2rem;

	.appointment-heading {
		@include text(delta);
		color: $color-primary-dark-blue;
		font-weight: $font-weight-bold;
		margin-bottom: 1.75rem;
	}

	.wait-time,
	.people-in-line {
		@include text(zeta);
		color: $color-quick-links;
		font-weight: $font-weight-bold;
	}

	.wait-time {
		border-bottom: 1px solid $color-neutral;
		margin-bottom: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.people-in-line {
		margin-bottom: 1.75rem;
	}

	@include media-breakpoint-up(md) {
		float: right;
		margin-left: 2rem;
		width: 388px;
	}
}
