html[dir='rtl'] {
	text-align: right;

	//UNORDERED LIST RTL TREATMENT
	ul:not([class]),
	ul.two-columns,
	ul.three-columns,
	ul.four-columns,
	.bulleted-list {
		padding-left: 0;
		padding-right: 1.5rem;

		> li {
			margin-left: 0;
			margin-right: 1rem;

			&:before {
				margin-left: 0;
				margin-right: -1rem;
			}
		}
	}

	//GLOBAL NAV RTL TREATMENT
	.global-navigation {
		.image-cta {
			picture {
				left: 0;
			}

			.text-overlay svg {
				transform: rotate(180deg);
			}

			&:hover,
			&:focus {
				.text-overlay {
					.svg-container {
						transform: translate(-4px);
					}
				}
			}
		}

		.quick-links-header {
			.heading-6 {
				direction: ltr;
			}
		}

		.quick-links-note {
			direction: ltr;
		}

		.hamburger-nav {
			.global-search-trigger {
				text-align: right;

				.icon-search {
					right: auto;
					left: 1rem;
				}
			}

			.primary-nav .nav-links li.has-sub-nav > a .icon-arrow-right {
				right: 0.5rem;
			}
		}
	}

	//GLOBAL SEARCH TYPEAHEAD RTL TREATMENT
	.global-search-typeahead {
		.search-form {
			.submit-search {
				right: auto;
				left: 2rem;
			}
		}

		.search-input-container {
			margin-right: -15px;
			margin-left: auto;
		}

		.search-overlay-close {
			right: auto;
			left: 1rem;
		}

		.search-dropdown {
			text-align: right;

			ul li {
				text-align: right;
			}

			.search-doctors {
				li {
					text-align: right;
				}

				.profile-image {
					margin-left: 1.5rem;
					margin-right: 0;
				}
			}

			.search-locations {
				li {
					text-align: right;
				}

				svg {
					margin-left: 1.5rem;
					margin-right: 0;
				}
			}
		}

		.view-all-link {
			direction: ltr;
		}
	}

	//ALERT RTL TREATMENT
	.message {
		.alert {
			.icon-message {
				margin-left: 2rem;
				margin-right: 0;
			}
		}
	}

	//LEFT NAV RTL TREATMENT
	.leftnav {
		.accordion-link {
			text-align: right;
		}

		.accordion-item-label {
			text-align: right;
		}
	}

	//RTE RTL TREATMENT
	.rte {
		text-align: right;
	}

	//HERO PRIMARY RTL TREATMENT
	.hero-primary {
		.search-dropdown {
			text-align: right;
		}
	}

	//HERO ARTICLE RTL TREATMENT
	.hero-article {
		.divider-hero {
			margin: 0.75rem 0 0.5rem auto;
		}
	}

	//TOOL RTL TREATMENT
	.tool {
		.tool-content {
			text-align: right;
		}

		.select-custom {
			.icon-arrow-down {
				left: 0.75rem;
				right: auto;
			}
		}

		.form-group {
			text-align: right;
		}

		.btn-minified-submit {
			svg {
				transform: rotate(180deg);
			}
		}
	}

	//PAGE HEADER RTL TREATMENT
	.page-header {
		.breadcrumb-item > a > .icon {
			transform: rotate(180deg);
		}

		.language-dropdown {
			left: 0;
			right: auto;

			.dropdown-menu {
				left: 0;
				right: auto;
			}

			+ .heading-1 {
				text-align: right;
			}
		}

		.divider {
			margin-left: auto;
			margin-right: 0;
		}
	}

	//SOCIAL SHARE RTL TREATMENT
	.social-share {
		a,
		button {
			margin-left: 0;
			margin-right: 0.75rem;

			&:first-of-type {
				margin-right: 0;
			}
		}
	}

	//ONE UP RTL TREATMENT
	.one-up {
		.divider {
			margin: 1.75rem 0 1.75rem auto;
		}
	}

	//TWO UP RTL TREATMENT
	.two-up {
		.divider {
			margin: 1rem 0 1rem auto;
		}
	}

	//THREE UP RTL TREATMENT
	.three-up {
		.three-up-content {
			text-align: right;
		}

		.divider-syndicated {
			margin-right: 0;
			margin-left: auto;
		}

		p {
			span {
				padding-right: 0;
				padding-left: 0.5rem;

				&:after {
					padding-right: 0.5rem;
					padding-left: 0;
				}
			}
		}
	}

	// FOOTER RTL TREATMENT
	.footer {
		.marketing {
			p {
				direction: ltr;
			}
		}

		.social-icons {
			a {
				margin-left: 0;
				margin-right: 0.75rem;

				&:first-of-type {
					margin-right: 0;
				}
			}
		}

		.link-lists,
		.link-lists-constant {
			text-align: right;

			ol {
				padding-right: 0;
				li ol {
					padding-right: 0;
				}
			}
		}

		.policy-terms {
			ol {
				padding-right: 0;
			}
		}

		.copyright {
			direction: ltr;
		}
	}

	@include media-breakpoint-up(sm) {
		.related-list-container {
			.related-list.doctor .related-list-item .related-DoL {
				margin-right: calc(64px - 20px);
				margin-left: 0;

				.card-header {
					padding: 1.5rem 0.5rem 1.5rem 4rem;
				}

				.doctor-details {
					text-align: right;
				}

				.profile-image {
					margin: auto -64px auto 1rem;
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		//HERO PRIMARY RTL TREATMENT
		.hero-primary {
			.hero-content-container {
				justify-content: flex-end;

				&:not(.center):not(.right) {
					.global-search-typeahead .search-dropdown {
						left: calc(50% + 4rem);
					}
				}

				&.center {
					justify-content: center;

					.global-search-typeahead .search-dropdown {
						left: 50%;
					}
				}

				&.right {
					justify-content: flex-start;
				}
			}
		}

		//HERO SECONDARY RTL TREATMENT
		.hero-secondary {
			.hero-content-container {
				&.right {
					justify-content: flex-start;
				}
			}
		}

		//HERO ARTICLE RTL TREATMENT
		.hero-article {
			.divider-hero {
				margin: 1.25rem 0 0.5rem auto;
			}
		}

		//TOOL RTL TREATMENT
		.tool {
			&.minified {
				.form-group {
					padding-left: 2rem;
					padding-right: 0;
				}

				.btn-minified-submit {
					right: auto;
					left: 0;
				}
			}
		}

		//PAGE HEADER RTL TREATMENT
		.page-header {
			.social {
				float: left;
				padding-left: 0;
				padding-right: 3rem;
			}

			.language-dropdown {
				+ .heading-1 {
					padding-right: 0;
					padding-left: 100px;
				}
			}
		}

		//ONE UP RTL TREATMENT
		.one-up {
			.one-up-inner {
				padding-left: 0;
				padding-right: 1.5rem;
			}

			.one-up-img {
				margin-left: auto;
				margin-right: -2.5rem;
			}

			&.right {
				.one-up-inner {
					padding-left: 1.5rem;
					padding-right: 0;
				}

				.one-up-img {
					margin-left: -2.5rem;
					margin-right: auto;
				}
			}
		}

		//TWO UP RTL TREATMENT
		.two-up {
			.two-up-content-container {
				&:first-child {
					margin-right: 15px;
					margin-left: 1rem;
				}

				&:not(:first-child) {
					margin-right: 1rem;
					margin-left: 0;
				}
			}

			&.center {
				.two-up-inner {
					padding-left: 1rem;
					padding-right: 1rem;
				}

				.two-up-content-container:first-child {
					margin-right: 15px;
				}

				.two-up-content-container:not(:first-child) {
					margin-left: 15px;
				}
			}

			&.right {
				.two-up-content-container:first-child {
					margin-right: 0;
				}

				.two-up-content-container:not(:first-child) {
					margin-left: 15px;
				}
			}
		}

		//DOCTOR RESULT RTE TREATMENT
		.doctor-result {
			.card-header {
				text-align: right;
			}
			.doctor-location {
				text-align: right;
			}
		}
		.view-mode-list {
			.doctor-result {
				margin-left: 0;
				margin-right: $profile-card-offset;

				.card-header {
					text-align: right;
				}

				.profile-image {
					margin-left: auto;
					margin-right: $profile-image-offset;
				}
			}
		}
		.search-page.find-a-location .search-page-wrapper-outer.view-mode-map {
			margin-right: 0;
		}

		// FOOTER RTL TREATMENT
		.footer {
			.footer-small {
				.policy-terms {
					margin-left: 0;
					margin-right: 0.5rem;
					text-align: left;
				}

				.copyright {
					margin-left: 0.5rem;
					margin-right: 0;
					text-align: right;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		//GLOBAL NAV RTL TREATMENT
		.global-navigation {
			&.navbar-fixed {
				.main-nav {
					.navbar-brand {
						text-align: right;
					}
				}
			}

			.main-nav {
				.navbar-brand {
					margin-left: 3rem;
					margin-right: 0;
				}

				.main-nav-links {
					> li > a .icon-arrow-down {
						margin-right: 0.75rem;
						margin-left: 0;
					}

					.sub-nav {
						.heading-6 {
							text-align: right;
						}

						ul {
							li {
								text-align: right;
							}
						}

						.sub-nav-primary {
							padding-right: 0;
							padding-left: 3rem;
							border-right: none;
							border-left: 1px solid $color-gray-light;
						}

						.sub-nav-secondary {
							padding: 0 3rem 0 1rem;
						}

						.quick-links {
							.quick-links-header .heading-6 {
								margin-left: 0.75rem;
								margin-right: 0;
							}

							.btn-icon {
								&:first-of-type {
									margin-right: 0;
								}

								&:not(:first-of-type) {
									margin-right: 1rem;
								}
							}
						}
					}
				}
			}

			.utility-nav {
				.utility-nav-container {
					left: 15px;
					right: auto;
				}

				.global-search-trigger {
					text-align: right;

					.icon-search {
						right: auto;
						left: 1rem;
					}
				}
			}

			.utility-nav-links {
				li:not(:last-of-type) {
					padding: 0 0 0 1rem;
					margin: 0 0 0 1rem;
					border-right: none;
					border-left: 1px solid $color-primary-blue;
				}
			}
		}

		//GLOBAL SEARCH TYPEAHEAD RTL TREATMENT
		.global-search-typeahead {
			.search-form {
				.submit-search {
					left: 1rem;
				}
			}

			.search-input-container {
				margin: auto;
			}

			.search-dropdown {
				.dropdown-container {
					.dropdown-primary {
						padding: 0 0 0 2rem;
						border-right: 0;
						border-left: 1px solid $color-neutral-darkest;
					}

					.dropdown-secondary {
						padding: 0 2rem 0 0;
						border-left: 0;
						border-right: 1px solid $color-neutral-darkest;
					}
				}
			}
		}

		//ONE UP RTL TREATMENT
		.one-up {
			.one-up-inner {
				padding-right: 4rem;
			}

			.one-up-content {
				padding: 3rem 0 3rem 3rem;
			}

			.one-up-img {
				margin-right: -5rem;
			}

			&.right {
				.one-up-inner {
					padding-left: 4rem;
				}

				.one-up-content {
					padding: 3rem 3rem 3rem 0;
				}

				.one-up-img {
					margin-left: -5rem;
					margin-right: auto;
				}
			}
		}

		//TWO UP RTL TREATMENT
		.two-up {
			.two-up-content-container {
				&:first-child {
					margin-left: 1.5rem;
				}

				&:not(:first-child) {
					margin-right: 1.5rem;
					margin-left: 0;
				}
			}

			&.center {
				.two-up-inner {
					padding-left: 1.5rem;
					padding-right: 1.5rem;
				}
			}
		}

		//RELATED LIST RTL TREATMENT
		.related-list-container {
			.section-title {
				&:after {
					right: 0;
					left: auto;
				}
			}
		}

		// FOOTER RTL TREATMENT
		.footer {
			.marketing {
				float: right;
				margin-left: 1rem;
				margin-right: 0;
				text-align: right;
			}
			.link-lists {
				float: right;
				margin-left: 0;
				margin-right: 2rem;
			}

			.link-lists-constant {
				float: left;
				margin-left: 0;
				margin-right: 1rem;
			}

			.social-icons {
				text-align: right;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		//ONE UP RTL TREATMENT
		.one-up {
			.one-up-content {
				padding-left: 5rem;
			}

			&.right {
				.one-up-content {
					padding-right: 5rem;
				}
			}
		}
	}
}
