.rating-stars {
	display: flex;
	justify-content: space-between;
	width: 130px;

	.star {
		display: inline-block;
		position: relative;
		height: 18px;
		width: 18px;

		svg {
			fill: $color-neutral-light;
			height: 18px;
			width: 18px;
			position: absolute;
			top: 0;
			left: 0;
		}

		.star-fill {
			position: absolute;
			top: 0;
			left: 0;
			overflow: hidden;
			width: 0;
			height: 18px;

			svg {
				fill: $color-primary-yellow;
			}
		}
	}

	@for $i from 1 through 5 {
		.star:nth-child(#{$i}) {
			.star-fill {
				$percent: calc((var(--rating) - (#{$i} - 1)) * 100%);
				max-width: 100%;
				width: $percent;
			}
		}
	}
}
