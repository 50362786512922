.profile-ratings {
	@include make-container();
	@include make-container-max-width();

	.section-title {
		margin-bottom: 0;

		&:after{
			background-color: $color-primary-yellow;
		}
	}

	.read-more {
		.read-more-body {
			max-height: 350px;
		}

		.read-more-link {
			position: absolute;

			&.is-expanded {
				font-size: 1rem;
				line-height: 1.125;
				bottom: 0;
				display: inline-block;
				font-weight: 700;
				left: 0;
				position: relative;
				padding: 2rem 0;
				transform: none;
				text-align: center;
			}
		}
	}

	.button-container {
		margin-bottom: 4rem;
		margin-top: -2.5rem;
		background-color: #f5f5f6;
		height: 5rem;
		border-radius: 5px;

		.read-more-link {
			position: relative;

			&.is-expanded {
				font-size: 1rem;
				line-height: 1.125;
				display: inline-block;
				font-weight: 700;
			}
		}
	}

	.ratings-grid {
		background-color: $color-neutral-lighter;
		display: flex;
		padding: 1rem 2rem 2rem;
		margin-top: 2rem;
		flex-direction: column;
		border-radius: 5px;
	}

	.overall-rating {
		align-items: center;
		display: flex;
		flex-direction: column;
		margin-top: .5rem;
		margin-bottom: 1.75rem;

		.rating-number {
			color: $color-primary-dark-blue;
			font-size: 80px;
			margin-bottom: 0.5rem;
		}

		.rating-count {
			@include text(zeta);
			color: $color-neutral-dark;
			margin-top: 0.5rem;
		}
	}

	.reviews-list {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			&:before {
				display: none;
			}

			&:not(:last-of-type) {
				margin-bottom: 1.625rem;
			}

			.review-rating {
				@include text(gamma);
				align-items: center;
				color: $color-primary-dark-blue;
				display: flex;
				font-weight: $font-weight-bold;

				.rating-number {
					flex-basis: 2.5rem;
					min-width: 2.5rem;
				}

				.rating-stars {
					margin-left: 0.75rem;
				}
			}

			.review-text {
				@include text(epsilon);
				color: $color-primary-blue;
				margin-top: 0.5rem;

				&.rating-only {
					color: $color-neutral;
				}

				.review-title {
					font-weight: $font-weight-semi-bold;

					+ .review-comment {
						margin-top: 0.5rem;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		.read-more:not(.is-expanded) {
			.read-more-body {
				max-height: 300px;
			}
		}

		.ratings-grid {
			display: flex;
			flex-direction: row;
		}

		.overall-rating {
			flex-basis: 180px;
			min-width: 180px;
			margin-bottom: 2rem;
			margin-top: 1rem;
			margin-right: 40px;
		}

		.reviews-list {
			li {
				align-items: flex-start;
				display: flex;

				.review-text {
					color: $color-primary-blue;
					margin-left: 3.125rem;
					margin-top: 0.25rem;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.overall-rating {
			flex-basis: 240px;
			min-width: 240px;
			margin-right: 80px;
		}
	}
}
