.example-module {
	color: $color-white;

	h4 {
		margin-bottom: 1rem;

		&:after {
			@include text(zeta);
			content: 'XS Breakpoint - <#{map-get($grid-breakpoints, sm)}';
			display: block;
			margin-top: 2px;
		}
	}

	.jumbotron {
		background-color: $color-primary-blue;
	}

	@include media-breakpoint-up(sm) {
		h4 {
			&:after {
				content: 'SM Breakpoint - ≥#{map-get($grid-breakpoints, sm)}';
			}
		}

		.jumbotron {
			background-color: $color-secondary-teal;
		}
	}

	@include media-breakpoint-up(md) {
		h4 {
			&:after {
				content: 'MD Breakpoint - ≥#{map-get($grid-breakpoints, md)}';
			}
		}

		.jumbotron {
			background-color: $color-secondary-blue;
		}
	}

	@include media-breakpoint-up(lg) {
		h4 {
			&:after {
				content: 'LG Breakpoint - ≥#{map-get($grid-breakpoints, lg)}';
			}
		}

		.jumbotron {
			background-color: $color-primary-dark-blue;
		}
	}

	@include media-breakpoint-up(xl) {
		h4 {
			&:after {
				content: 'XL Breakpoint -  ≥#{map-get($grid-breakpoints, xl)}';
			}
		}

		.jumbotron {
			background-color: $color-secondary-dark-teal;
		}
	}
}
