.healthplan-theme {
	.smaller-text {
		@include text(zeta);
	}
	.link-highlight {
		background-color: $color-healthplan-subtle-green;
		color: $color-primary-blue;
		text-decoration: underline;

		&:hover,
		&:focus {
			background-color: rgba($color-healthplan-subtle-green, 0.5);
			color: $color-primary-blue-gray;
		}
	}
	.callout-card {
		background-color: $color-neutral-lightest;
		background-clip: padding-box;
		border: 0.5px solid rgba($color-healthplan-gray, 0.38);
		box-shadow: 0px 3px 6px rgba($color-black, 0.15);
		color: $color-healthplan-blue;
		margin-left: auto;
		max-width: 280px;
		padding: 3rem 1.5rem !important;

		.heading-4 {
			margin-bottom: 1.5rem;
		}

		p {
			@include text(epsilon, delta);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.hero-primary {
		margin-bottom: 1rem;
	}

	.hero-secondary {
		margin-bottom: 1rem;
	}

	.insurance-card {
		display: flex;
		flex-direction: column;
		margin-bottom: 2rem;
		padding: 2rem 1.75rem;
		border-radius: 5px;
		background-color: $color-neutral-lighter;

		.insurance-columns {
			.column {
				&:not(:first-of-type) {
					margin-top: 3rem;
				}
			}
		}

		.heading-4 {
			margin-bottom: 0.75rem;
		}

		.logo-img {
			height: 36px;

			img {
				height: 100%;
			}
		}

		.img-wrapper {
			max-width: 400px;
		}

		.insurance-card-img {
			margin-bottom: 1.5rem;
			margin-left: -4%;
			width: 108%;
		}

		p {
			color: $color-healthplan-gray;
			margin-bottom: 1.25rem;
			margin-top: 0.75rem;
		}

		a {
			@include text(zeta, eta);
			font-weight: $font-weight-semi-bold;
			margin-top: auto;

			&.ext-img {
				margin-top: 3rem;
			}
		}

		a[target='_blank'] {
			position: relative;
			display: inline-block;

			&:after {
				background-image: url(/assets/images/external-black.png);
				background-size: cover;
				background-position: 50% 50%;
				bottom: -3px;
				display: inline-block;
				content: '';
				height: 16px;
				position: relative;
				width: 28px;
				margin-right: -28px;
			}
		}
	}


		.available-providers {
			display: block;
			padding-top: 15px;
			padding-left: 15px;
			padding-right: 15px;

			.heading-5 {
				margin-bottom: 0.825rem;
				padding-top: 1.5rem;
			}
	}

	.locations-and-appointments {
		.provider-list {
			.provider-item {
				padding: 1.5rem 0.5rem;
			}
		}
	}


	@include media-breakpoint-up(md) {
		.two-column p {
			margin-bottom: 1.5rem;
		}

		.hero-primary {
			margin-bottom: 1.5rem;
		}

		.hero-secondary {
			margin-bottom: 2rem;
		}

		.insurance-card {
			.insurance-columns {
				display: flex;
				height: 100%;
				justify-content: space-between;

				.column {
					display: flex;
					flex: 0 1 calc(50% - 1.75rem - 1px - calc(30px / 2));
					flex-direction: column;

					&:not(:first-of-type) {
						margin-top: 0;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
	.search-dropdown {
		width: 65%;

		.dropdown-container {
			@include make-row();
			background-color: $color-gray-darkest;
			flex-direction: row;
			margin: 0;
			padding: 1.75rem 1.75rem 1.25rem;

			.dropdown-primary {
				@include make-col(12);
				border-right: none;
				order: 1;
				padding: 0 2rem 0 0;
				}
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.hero-primary {
			&.dark-bg {
				.hero-content-container {
					.hero-content {
						height: 60%;
					}
				}
			}
		}
	}
}
