.news-list {
	@include make-container();
	@include make-container-max-width();

	.news-item {
		background-color: $color-neutral-lightest;
		display: flex;
		flex-direction: column;
		height: auto;
		margin-bottom: 2rem;
		min-height: 325px;
		position: relative;
	}

	.news-item-content {
		padding: 2rem;
		width: 100%;
	}

	.news-img {
		height: 265px;
		width: 100%;

		img,
		source {
			height: 100%;
			object-fit: cover;
			object-position: center 40%;
			font-family: 'object-fit: cover; object-position: center 40%;';
			width: 100%;
		}
	}

	.type-and-location {
		display: flex;
		margin-bottom: 0.75rem;
	}

	.news-type,
	.news-location {
		@include text(zeta);
		color: $color-neutral-darkest;
		font-weight: $font-weight-semi-bold;
		margin: 0;
		position: relative;

		&:not(:first-of-type) {
			margin-left: 0.625rem;
			padding-left: 0.625rem;

			&:before {
				content: '|';
				display: inline-block;
				left: 0;
				padding-right: 0;
				position: absolute;
				transform: translate(-50%, 0px);
			}
		}
	}

	.news-title {
		@include text(delta);
		color: $color-primary-dark-blue;
		font-weight: $font-weight-bold;
		margin-bottom: 1rem;
	}

	.news-description {
		@include text(epsilon);
		color: $color-primary-dark-blue;
		font-weight: $font-weight-normal;
		margin: 0;
	}

	.read-more-link {
		color: $color-primary-light-blue;
	}

	.view-all-link {
		@include text(epsilon);
		color: $color-primary-light-blue;
		display: inline-block;
		font-weight: $font-weight-bold;
		margin-top: 1.75rem;
		padding: 0;
	}

	@include media-breakpoint-up(md) {
		.news-listings {
			display: flex;
			flex-flow: wrap;
			justify-content: space-between;
		}

		.news-item {
			flex: 0 1 48.5%;
			margin-bottom: 4rem;
			max-width: 48.5%;
			min-height: 485px;
		}
	}

	@include media-breakpoint-up(lg) {
		.news-item {
			flex: 0 1 48.5%;
			max-width: 48.5%;
		}
	}
}
