$default-icon-color: $color-precision-gray;

.icon {
	// display: inline-block;
	height: 0.75rem;
	width: 1rem;
	stroke: $default-icon-color;
	stroke-linecap: round;
	stroke-width: 2px;

	use {
		path {
			all: inherit;
		}
	}

	&.icon-search {
		// TODO: remove height/width if possible after getting clean icons
		height: 1.15rem;
		width: 1.15rem;

		// TODO: remove margin-top if possible after getting clean icons
		margin-top: -6px;
	}

	&.icon-search-circle,
	&.icon-arrow-right-circle {
		// TODO: remove height/width if possible after getting clean icons
		height: 1.6875rem;
		width: 1.6875rem;
	}

	&.icon-callout-asterisk,
	&.icon-callout-bowl,
	&.icon-callout-doctor,
	&.icon-callout-location,
	&.icon-callout-services {
		height: 4.5rem;
		width: 4.5rem;
	}


	&.icon-callout-asterisk {
		fill: #c22032;
		stroke: transparent;
	}

	&.icon-callout-bowl {
		fill: #00aa8f;
		stroke: transparent;
	}

	&.icon-callout-doctor {
		stroke: #0776ab;
	}

	&.icon-arrow-left-thin,
	&.icon-menu-open,
	&.icon-menu-close {
		stroke: #5a5a5a;
	}

	// List Fill (vs stroke) icons here
	&.icon-alert,
	&.icon-arrow-down,
	&.icon-arrow-left,
	&.icon-arrow-right,
	&.icon-arrow-right-circle,
	&.icon-arrow-up,
	&.icon-arrow-long-down,
	&.icon-arrow-long-left,
	&.icon-arrow-long-right,
	&.icon-arrow-long-up,
	&.icon-building,
	&.icon-calendar,
	&.icon-camera,
	&.icon-clock,
	&.icon-close,
	&.icon-cta-arrow,
	&.icon-doctor,
	&.icon-follow-facebook,
	&.icon-follow-instagram,
	&.icon-follow-linkedin,
	&.icon-follow-twitter,
	&.icon-follow-youtube,
	&.icon-form-calendar,
	&.icon-globe,
	&.icon-home,
	&.icon-map-cluser,
	&.icon-map-marker,
	&.icon-map-pin,
	&.icon-message,
	&.icon-phone,
	&.icon-plus-circle,
	&.icon-profile,
	&.icon-search-circle,
	&.icon-share-chat-filled,
	&.icon-share-print-filled,
	&.icon-star,
	&.icon-video,
	&.icon-view-list,
	&.icon-view-tile {
		stroke-width: 0;
		fill: $default-icon-color;
	}


	&.icon-doctor {
		stroke: transparent;
	}

	// Fill and Stroke
	&.icon-phone,
	&.icon-share-facebook,
	&.icon-share-facebook-filled,
	&.icon-share-instagram,
	&.icon-share-linkedin,
	&.icon-share-print,
	&.icon-share-twitter,
	&.icon-share-twitter-filled,
	&.icon-share-youtube,
	&.icon-map-cluser,
	&.icon-map-marker {
		fill: $default-icon-color;
		height: 2rem;
		width: 2rem;
	}
}
