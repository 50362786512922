.social-share {
	text-align: center;

	a,
	button {
		background: transparent;
		border: 0;
		padding: 0;

		&:hover {
			text-decoration: none;

			.icon {
				fill: $color-sky-blue;
				stroke: $color-sky-blue;
			}
		}

		+ a,
		+ button {
			margin-left: 0.75rem;
		}
	}

	.icon {
		fill: $color-overcast-blue;
		height: 2rem;
		pointer-events: none;
		stroke: $color-overcast-blue;
		width: 2rem;
	}
}
