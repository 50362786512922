.insurance-networks {
	@include make-container();
	@include make-container-max-width();

	[class*='col-'] {
		> .heading-4 {
			margin-bottom: 1.25rem;
		}
	}

	.insurance-card {
		padding: 1.75rem 3.5rem;

		.insurance-card-img {
			margin-bottom: 0;
			margin-left: 0;
			width: 100%;

			&.magnify-bottom {
				margin-bottom: -15%;
			}

			&.magnify-left {
				width: 111%;
				margin-bottom: -10%;
				margin-left: -11%;
			}

			&.magnify-left-alt {
				width: 110%;
				margin-bottom: -10%;
				margin-left: -10%;
			}
		}

		.link-forward {
			margin-top: 1.75rem;
		}
	}
}
