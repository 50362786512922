.appointments-modal {
	.modal-dialog {
		margin: 1rem 0.75rem;
		max-width: none;
	}

	.modal-content {
		border: 0;
	}

	.modal-body {
		padding: 0;

		&:only-child {
			padding: 0;
		}
	}

	.modal-close-btn {
		background: transparent;
		border: 0;
		position: absolute;
		right: 0.25rem;
		top: 0.25rem;

		svg {
			height: 1.25rem;
			width: 1.25rem;
		}
	}

	.appointments {
		.appointments-inner {
			padding: 2.5rem 1rem 2rem;
		}

		.appointment-selection {
			margin-left: -0.5rem;
			margin-right: -0.5rem;
			width: calc(100% + 1rem);

			.no-appointments-message {
				top: 40%;
			}
		}

		.day-list {
			justify-content: space-around;
		}

		// .date-range-selection {
		// 	width: 100%;
		// }
	}
}
