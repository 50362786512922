.blog-list {
	@include make-container();
	@include make-container-max-width();

	.blog-list-header {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin: 2rem 0 1.5rem;
	}

	.blog-list-title {
		@include text(alpha);
		color: $color-primary-dark-blue;
		font-weight: $font-weight-bold;
		margin: 0;

		&:after {
			background-color: $color-primary-yellow;
			bottom: -10px;
			content: "";
			display: block;
			height: 4px;
			left: 0;
			position: relative;
			width: 4rem;
		}
	}

	.view-all-link {
		@include text(epsilon);
		font-weight: $font-weight-semi-bold;

		&:hover,
		&:focus {
			color: $color-primary-light-blue;
		}

		.icon {
			top: -1px;
			position: relative;
		}
	}

	.blog-item {
		height: auto;
		margin-bottom: 2rem;
		min-height: 325px;
		position: relative;

		&.subscription {
			align-items: center;
			display: flex;

			.blog-item-content {
				background-color: $color-white;
				position: relative;
				padding: 0;
			}

			.blog-title {
				@include text(beta);
				font-weight: $font-weight-bold;
			}

			.blog-description {
				@include text(epsilon);
				display: block;
				font-weight: $font-weight-normal;
				margin: 1rem 0 0;
				padding-bottom: 0;

				p {
					margin-bottom: 0;
				}
			}

			.subscribe-form {
				padding: 0;

				form {
					padding-top: 1.5rem;
				}
			}
		}
	}

	.blog-item-content {
		bottom: 0;
		padding: 1.5rem 0 1rem;
		width: 100%;
	}

	.blog-img {
		width: 100%;

		img,
		source {
			height: 100%;
			object-fit: cover;
			object-position: 50% 50%;
			font-family: 'object-fit: cover; object-position: 50% 50%;';
			width: 100%;
			max-height: 400px;
		}
	}

	.blog-category {
		@include text(zeta);
		background-color: rgba($color-neutral-lightest, 0.95);
		color: $color-primary-light-blue;
		font-weight: $font-weight-normal;
		margin: 0;
		padding: 0.5rem 0.75rem 0;
		position: absolute;
		top: 0;
		transform: translateY(-100%);
	}

	.blog-title {
		@include text(epsilon);
		color: $color-primary-dark-blue;
		font-weight: $font-weight-semi-bold;
		margin-bottom: 1rem;
	}

	.divider-blog {
		border-bottom: 4px solid $color-primary-yellow;
		margin: 0.75rem auto 0.5rem 0;
		width: 4rem;
		border-top: none;
	}

	.read-more-link {
		@include text(eta);
		padding: 0;
		color: $color-secondary-dark-teal;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
	}

	@include media-breakpoint-up(md) {
		.blog-list-header {
			margin: 1rem 0 1.5rem;
		}

		.blog-list-title {
			@include text(gamma);
		}

		.blog-listings {
			display: flex;
			flex-flow: wrap;

			justify-content: space-between;
		}

		.blog-item {
			flex: 0 1 48.5%;
			margin-bottom: 2rem;
			max-width: 48.5%;
			min-height: 425px;
			display: flex;
			flex-flow: column;
			justify-content: flex-start;

			&.subscription {
				.blog-title {
					@include text(beta);
					width: 65%;
				}

				.blog-description {
					width: 65%;
				}
			}
		}

		.blog-item-content {
			padding: 1.5rem 0 1.25rem;
		}

		.blog-category {
			margin-left: -1rem;
			padding: 0.5rem 1rem 0;
		}

		.blog-title {
			@include text(delta);
		}
	}

	@include media-breakpoint-up(lg) {
		.blog-listings {
			// Holds space for row with two items
			&:after {
				content: ' ';
				flex: 0 1 31.5%;
				max-width: 31.5%;
			}
		}

		.blog-item {
			flex: 0 1 31.5%;
			max-width: 31.5%;

			.blog-description {
				@include text(zeta);
				display: block;
				font-weight: $font-weight-normal;
				margin: 0;
				padding-bottom: 1rem;
			}

			&.half {
				flex: 0 1 48.5%;
				max-width: 48.5%;
			}

			&.subscription {
				.blog-title,
				.blog-description {
					width: 75%;
				}
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.blog-item {
			&.subscription {
				.blog-title,
				.blog-description {
					width: 65%;
				}
			}
		}
	}
}
