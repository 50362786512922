.tool {
	@include make-container();
	@include make-container-max-width();
	@extend .clearfix;
	background-color: $color-white;
	position: relative;

	&.minified {
		background-color: transparent;

		.tool-inner {
			margin-bottom: 1rem;
			padding-top: 0;
		}

		.form-group {
			// margin-bottom: 0;
			// padding-right: 2rem;

			// > input,
			// > label {
			// 	display: none;
			// 	padding: 1rem 0.75rem 0.5rem 0.75rem;
			// }
		}

		.select-custom {
			display: none;
		}

		.card:not(.contact) .btn-primary {
			display: none;
		}

		.contact {
			display: block;
		}
	}

	.tool-inner {
		@include make-container-max-width();
		padding-top: 0;
		text-align: center;
	}

	.search-dropdown {
		display: none;
	}

	.heading-3 {
		@include text(zeta, epsilon);
		font-weight: $font-weight-bold;
		margin-bottom: 0.75rem;
		text-transform: capitalize;
	}

	.form-group {
		margin-bottom: 0;

		&.search-focus {
			.search-dropdown {
				display: block;
			}
		}
	}

	.form-group,
	.form-control {
		@include text(epsilon, zeta);
		color: $color-gray-darkest;
	}

	.form-group,
	.form-label-group {
		position: relative;
	}

	.form-group > input,
	.form-label-group > input {
		background-color: $color-white;
		border-radius: 5px;
		padding: 1rem 1rem 1rem 2.5rem;
	}

	.form-group > label,
	.form-label-group > label {
		background-color: transparent;
		border: none;
		display: block;
		font-weight: $font-weight-semi-bold;
		width: 100%;
		margin-bottom: 0;
		padding-left: 1rem;
		margin-bottom: 0.5rem;
		pointer-events: none;
		cursor: text;
		transition: all 0.1s ease-in-out;
	}

	.form-group input:not(.placeholder-shown) ~ label,
	.form-label-group input:not(.placeholder-shown) ~ label {
		@include text(eta);
		padding-top: 0;
		padding-bottom: 0.25rem;
	}

	.select-custom {
		overflow: hidden;
		position: relative;
		margin-bottom: 0.5rem;
		width: 100%;

		svg {
			pointer-events: none;
		}
	}

	.select-custom select {
		position: relative;
		appearance: none;
		background-color: transparent;
		background-image: none;
		box-shadow: none;
		display: block;
	}

	.select-custom-inner::-ms-expand {
		display: none;
	}

	.select-custom-inner {
		border: none;
		border-radius: 0;
		overflow: hidden;
		position: relative;
		width: 100%;
		appearance: none;
	}

	.icon-arrow-down {
		position: absolute;
		right: 0.75rem;
		bottom: 1rem;
		display: block;
		padding: 0;
	}

	.icon-btn {
		background-color: transparent;
		border: 0;
		position: absolute;
		top: 2.75rem;
		left: 1rem;
		padding: 0;

		svg {
			height: 1rem;
			width: 1rem;
		}
	}

	.card-deck {
		background-color: $color-neutral-lighter;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-left: 0;
		margin-right: 0;
		padding: 1.5rem;
	}

	.tool-content {
		padding: 0;
		margin-bottom: 0;
		text-align: left;
	}

	.tool-content-container {
		background-color: transparent;
		border-top: none;
		border-right: none;
		border-bottom: none;
		border-left: none;
		flex: 0 0 auto;
		height: auto;
		max-width: 100%;
		padding: 0;
		margin: 0 0 1.5rem 0;

		&:last-child {
			margin-bottom: 0;
		}

		&.contact {
			.tool-content {
				padding: 0;
				display: block;
				.btn {
					width: 100%;
					padding: 0.75rem;

					&.btn-tertiary {
						.icon {
							fill: $color-white;
							height: 1.25rem;
							width: 1.25rem;
						}

						&:hover,
						&:focus {
							.icon {
								fill: $color-gray-darkest;
							}
						}
					}

				}

				.btn + .btn {
					margin-top: 1.25rem;
				}
			}
		}
	}

	@include media-breakpoint-up(sm) {
		.card-deck {
			margin-top: 0;
		}

		.tool-content-container {
			background-color: transparent;
			flex: 0 0 auto;
			height: auto;
			max-width: 100%;
			padding: 0;

			&.contact {
				.tool-content {
					display: flex;
					justify-content: space-between;

					.btn {
						width: calc(50% - 0.5rem);
					}

					.btn + .btn {
						margin-top: auto;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		background-color: $color-neutral-lightest;

		.hero-primary + &.minified {
			margin-top: -5rem;

			.tool-inner {
				padding-top: 0;
			}
		}

		.hero-secondary + &.minified .tool-content-container {
			&:first-child:nth-last-child(4),
			&:first-child:nth-last-child(4) ~ .tool-content-container {
				margin-top: -1rem;
				margin-bottom: 1rem;
				padding: 0;
			}
		}

		&:not(.minified) {
			.card-deck {
				margin-top: -7rem;
			}
		}

		&.minified {
			.heading-3 {
				margin-bottom: 0.5rem;
			}

			.form-group {
				> input,
				> label {
					display: block;
				}
			}
		}

		.tool-inner {
			@include make-container-max-width();
			padding-top: 1rem;

			.two-column & {
				margin-bottom: 1rem;
			}
		}

		.card-deck {
			flex-flow: row wrap;
			justify-content: space-between;
		}

		.tool-content-container {
			justify-content: space-between;

			&:first-child:nth-last-child(3) {
				flex: 0 0 calc((100% / 2) - 0.5rem);
				max-width: calc((100% / 2) - 0.5rem);
			}

			&:first-child:nth-last-child(1) ~ .contact,
			&:first-child:nth-last-child(2) ~ .contact {
				flex: 0 0 calc((100% / 2) - 0.5rem);
				max-width: calc((100% / 2) - 0.5rem);
			}

			&:first-child:nth-last-child(2) ~ .contact {
				margin-bottom: 0;
			}

			&:not(:only-child) {
				flex: 0 0 calc((100% / 2) - 0.5rem);
				max-width: calc((100% / 2) - 0.5rem);
				margin-bottom: 0;
			}

			&.contact:not(:only-child) {
				padding-top: 1.5rem;
			}

			&:only-child,
			&.contact {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		.two-column &.minified .tool-content-container {
			margin: 0 0 1.5rem 0;
		}

		.btn-minified-submit {
			bottom: 0.375rem;
			width: auto;
		}

		.col-md-4 &.minified {
			background-color: transparent;

			.tool-inner {
				margin-bottom: 1rem;

				.card-deck {
					.tool-content-container {
						&:before {
							display: none;
						}
					}
				}
			}

			.select-custom {
				display: none;
			}

			.btn-primary {
				display: none;
			}

			.btn-minified-submit {
				display: block;
			}

			.card-deck {
				margin-top: 0;
			}

			.contact {
				display: block;
			}

			.card-deck {
				flex-direction: column;
				justify-content: center;
				display: flex;
			}

			.tool-content {
				background-color: $color-white;
				padding: 1.25rem 1rem;
				text-align: left;
			}

			.tool-content-container {
				background-color: transparent;
				flex: 0 0 auto !important;
				height: auto;
				max-width: 100% !important;
				padding: 0 !important;
				margin-bottom: 0;
			}

			input,
			label {
				padding: 1rem 0.5rem 0.5rem;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.card-deck {
			justify-content: space-between;
		}

		&.minified {
			.tool-inner {
				margin-top: -1rem;
			}
		}

		.two-column &.minified .tool-content-container {
			justify-content: space-between;

			&:first-child:nth-last-child(3) {
				flex: 0 0 calc((100% / 2) - 0.5rem);
				max-width: calc((100% / 2) - 0.5rem);
			}

			&:first-child:nth-last-child(1) ~ .contact,
			&:first-child:nth-last-child(2) ~ .contact {
				flex: 0 0 calc((100% / 2) - 0.5rem);
				max-width: calc((100% / 2) - 0.5rem);
			}

			&:first-child:nth-last-child(2) ~ .contact {
				margin-bottom: 0;
			}

			&:not(:only-child) {
				flex: 0 0 calc((100% / 2) - 0.5rem);
				max-width: calc((100% / 2) - 0.5rem);
				margin-bottom: 0;
			}

			&.contact:not(:only-child) {
				padding-top: 1.5rem;
			}

			&:only-child,
			&.contact {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		.hero-secondary + &.minified .tool-content-container {
			&:first-child:nth-last-child(4),
			&:first-child:nth-last-child(4) ~ .tool-content-container {
				margin-top: 0rem;
				margin-bottom: 1rem;
				padding: 0;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		&:not(.minified) {
			.card-deck {
				margin-top: -10rem;
			}
		}

		.two-column &.minified .tool-inner {
			.tool-content-container {
				justify-content: space-between;

				&:first-child:nth-last-child(3) {
					flex: 0 0 calc((100% / 2) - 0.5rem);
					max-width: calc((100% / 2) - 0.5rem);
				}

				&:first-child:nth-last-child(1) ~ .contact,
				&:first-child:nth-last-child(2) ~ .contact {
					flex: 0 0 calc((100% / 2) - 0.5rem);
					max-width: calc((100% / 2) - 0.5rem);
				}

				&:first-child:nth-last-child(2) ~ .contact {
					margin-bottom: 0;
				}

				&:not(:only-child) {
					flex: 0 0 calc((100% / 2) - 0.5rem);
					max-width: calc((100% / 2) - 0.5rem);
					margin-bottom: 0;
				}

				&.contact:not(:only-child) {
					padding-top: 1.5rem;
				}

				&:only-child,
				&.contact {
					flex: 0 0 100%;
					max-width: 100%;
					margin-bottom: 0;
				}
			}
		}

		.tool-content-container {
			margin-bottom: 0;

			&:first-child:nth-last-child(3),
			&:first-child:nth-last-child(3) ~ :not(.contact) {
				flex: 0 0 calc((100% / 12) * 3.5 - 1rem);
				padding-right: 0;
			}

			&.contact,
			&:first-child:nth-last-child(2) ~ .contact,
			&:first-child:nth-last-child(3) ~ .contact {
				flex: 0 0 calc((100% / 12) * 5 - 1rem);
				max-width: calc((100% / 12) * 5 - 1rem);
				padding-top: 1.5rem;
    			margin-bottom: 0;
				padding-right: 0;
			}

			&:not(:only-child) {
				flex: 1 0 calc((100% / 2) - 0.5rem);
				max-width: calc((100% / 12) * 7);
				padding-right: 1rem;
			}

			&:not(:only-child) ~ :not(.contact) {
				flex: 0 0 calc((100% / 2) - 0.5rem);
				max-width: calc((100% / 2) - 0.5rem);
				padding-right: 0;
			}

			&.contact:only-child {
				padding-top: 0;
			}

			&:only-child {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}
}
