.location-map {
	height: calc(100vh - 270px);
	min-height: 345px;
	overflow: hidden;
	position: relative;

	.mapboxgl-marker {
		position: absolute;
	}

	.mapboxgl-map {
		overflow: hidden;
	}

	.mapboxgl-popup {
		box-shadow: 0 10px 24px rgba($color-black, 0.12);
		max-width: calc(100% - 30px) !important;
		margin-top: -75px;

		&:before {
			display: block;
			content: ' ';
			width: 0;
			height: 0;
			border-left: 30px solid transparent;
			border-right: 30px solid transparent;
			border-top: 30px solid $color-neutral-lighter;
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translate(-50%, -12px);
		}

		&:after {
			display: block;
			content: ' ';
			width: 0;
			height: 0;
			border-left: 19px solid transparent;
			border-right: 19px solid transparent;
			border-top: 19px solid $color-neutral-lighter;
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translate(-50%, -3px);
		}
	}

	.mapboxgl-popup-content {
		position: relative;
	}

	.doctor-result {
		.profile-image {
			display: none;
		}

		.doctor-details {
			padding: 1rem;
		}
	}

	.mapboxgl-popup-close-button {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
	}

	.mapboxgl-canvas-container {
		position: absolute;
		height: 100%;
		width: 100%;
		overflow: hidden;
	}

	.mapboxgl-canvas {
		position: absolute;

		&:focus {
			outline: 0;
		}
	}

	.spider-leg-container {
		width: 1px;
		height: 1px;
		overflow: display;
		will-change: transform;

		.spider-leg-pin {
			position: relative;
			z-index: 1;

			&.active {
				.icon {
					fill: $color-secondary-light-blue;
				}
			}

			.icon {
				border-radius: 50%;
				box-shadow: 0 8px 18px rgba($color-black, 0.24);
				transform: translate(-50%, -50%);
				fill: $color-gray-darkest;

				&:hover {
					cursor: pointer;
					fill: $color-secondary-light-blue;
				}
			}
		}

		.spider-leg-line {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 2px;
			background: linear-gradient(
				to top,
				$color-secondary-light-blue calc(100% - 0.9rem),
				transparent 0.9rem
			);
			pointer-events: none;
			transform-origin: bottom;
			z-index: 0;
			height: 0;
		}

		/* Animations specific styles */

		&.animate {
			transition: margin 0.15s linear;

			.spider-leg-line {
				transition: all 0.15s linear;
				transition-delay: inherit;
			}

			&.initial,
			&.exit {
				.spider-leg-line {
					height: 0 !important;
				}
			}
		}

		&.initial,
		&.exit {
			margin-left: 0 !important;
			margin-top: 0 !important;
			height: 0;
		}
	}

	@media screen and (max-width: 768px) and (orientation: landscape) {
		min-height: 240px;
		height: calc(100vh - 70px);
	}

	@include media-breakpoint-up(md) {
		.mapboxgl-popup {
			max-width: 550px !important;
		}

		.doctor-result {
			.profile-image {
				display: flex;
				border-radius: 50%;
				margin: calc(175px / -2) auto 1rem;
			}

			.doctor-details,
			.doctor-location {
				text-align: center;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		height: calc(100vh - 224px);
		min-height: 480px;
	}

	@include media-breakpoint-up(xl) {
		.mapboxgl-popup {
			max-width: 650px !important;
		}
	}
}
