.nav-accordion {
	background-color: $color-white;
	position: relative;

	&.flush {
		.accordion-item {
			> .accordion-item-title > .accordion-item-toggle {
				.icon {
					display: none;
				}
			}
		}
	}

	.accordion-item {
		border-bottom: 1px solid $color-precision-gray-tint;
		padding: 0;

		// &:first-of-type {
		// 	border-top: 1px solid $color-neutral-light;
		// }

		&[aria-expanded='true'] {
			> .accordion-item-title {
				.accordion-item-toggle {
					color: $color-primary-dark-blue;
					font-weight: $font-weight-semi-bold;

					.icon-arrow-down {
						transform: rotate(180deg) translateY(50%);
					}
				}

				.accordion-item-tags {
					opacity: 0;
					pointer-events: none;
					position: absolute;
					top: 100%;
					width: 100%;
				}
			}

			> .accordion-item-body {
				display: block;
				height: auto;
			}

			&.closing {
				> .accordion-item-title {
					.accordion-item-toggle {
						color: $color-neutral-dark;
						font-weight: $font-weight-light;

						&:hover,
						&:focus {
							color: $color-primary-light-blue;
						}

						.icon-arrow-down {
							transform: translateY(-50%);
						}

						.label-secondary {
							opacity: 1;
						}
					}

					.accordion-item-tags {
						opacity: 1;
						transition: opacity 0.4s ease-in-out;
					}
				}

				> .accordion-item-body {
					overflow: hidden;
					transition: height 0.4s ease-in-out;
				}
			}

			&.opening {
				> .accordion-item-body {
					overflow: hidden;
					transition: height 0.4s ease-in-out;
				}
			}
		}

		.accordion-item-title {
			position: relative;

			.accordion-item-toggle {
				@include text(epsilon);
				background: none;
				border: none;
				color: $color-neutral-dark;
				display: flex;
				font-weight: $font-weight-light;
				margin: 0;
				padding: 1rem 2.5rem 1rem 1rem;
				position: relative;
				text-align: left;
				width: 100%;

				&:hover,
				&:focus {
					color: $color-overcast-blue;
					outline: 0;

					.icon-arrow-down {
						fill: $color-overcast-blue;
					}
				}

				.accordion-item-label {
					white-space: nowrap;
				}

				.label-secondary {
					color: $color-secondary-light-blue;
					font-weight: $font-weight-light;
					padding-left: 0.5rem;
					overflow: hidden;
					text-overflow: ellipsis;
					transition: opacity 0.4s ease-in-out;
					white-space: nowrap;
				}

				.icon-arrow-down {
					fill: $color-primary-light-blue;
					position: absolute;
					right: 1rem;
					top: 50%;
					transform: translateY(-50%);
				}
			}

			.accordion-item-tags {
				background-color: $color-white;
				transition: opacity 0.2s ease-in-out;
				z-index: 1;

				.tag-list {
					padding: 0 1rem 0.5rem;

					&:last-of-type {
						padding-bottom: 1rem;
					}

					.tag:first-of-type {
						padding-top: 0;
					}
				}
			}
		}

		.accordion-item-body {
			display: none;
			height: 0;

			.accordion-item-body-inner {
				padding: 0 1rem 1rem;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		&.flush {
			.accordion-item {
				> .accordion-item-title > .accordion-item-toggle,
				> .accordion-item-body > .accordion-item-body-inner {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}

		.accordion-item .accordion-item-body .accordion-item-body-inner > .rbt {
			margin-left: -1rem;
			width: calc(100% + 2rem);
		}
	}
}
