.microsite-theme {
	.hero-secondary {
		margin-bottom: 2.5rem;
		max-width: none;
		padding: 0;

		.hero-title {
			color: $color-black;
		}

		@include media-breakpoint-up(lg) {
			.hero-img {
				text-align: center;

				img,
				source {
					max-height: 600px;
					max-width: 1920px;
				}
			}

			.hero-content-container {
				left: 0;
				right: 0;
			}
		}
	}
}
