.page-header {
	@include make-container();
	@include make-container-max-width();

	.page-header-inner {
		background-color: $color-white;
		margin-bottom: 1rem;
		padding-top: 1rem;
		position: relative;
	}

	.breadcrumb {
		@include text(eta, eta);
		background-color: $color-white;
		color: $color-secondary-light-blue;
		font-weight: $font-weight-bold;
		margin-bottom: 0.625rem;
		padding-bottom: 0;
		padding-left: 0;
	}

	.breadcrumb-item {
		@include text(eta, eta);
		color: $color-secondary-light-blue;
		font-weight: $font-weight-bold;
		padding-left: 0;

		&::before {
			display: none;
		}

		&:not(:last-child) {
			display: none;
		}

		> a {
			color: $color-secondary-light-blue;

			> .icon {
				fill: #3579ad;
				margin-top: -0.15rem;
				padding-right: 0.5rem;
				width: 1.5rem;
			}
		}
	}

	.divider {
		border-top: 0;
		margin-left: auto;
		margin-top: 0.75rem;
		margin-right: auto;
	}

	.heading-1 {
		@include text(alpha, beta);
		color: $color-primary-dark-blue;
		font-weight: $font-weight-bold;
		text-align: center;
		margin-bottom: 0.5rem;
	}

	.social {
		display: none;
		float: right;
		padding-top: 0.5rem;
		padding-left: 3rem;
	}

	.language-dropdown {
		position: absolute;
		right: 0;
		top: 26px;
	}

	@include media-breakpoint-up(md) {
		.breadcrumb {
			@include text(zeta, zeta);
		}

		.breadcrumb-item {
			@include text(zeta, zeta);
			color: $color-secondary-light-blue;
			font-weight: $font-weight-semi-bold;
			padding-left: 0.625rem;

			&::before {
				color: $color-neutral;
				display: inline-block;
			}

			&:not(:last-child) {
				display: block;
			}

			&:first-child {
				padding-left: 0.125rem;
			}

			> a {
				> .icon {
					display: none;
				}
			}

			+ .breadcrumb-item {
				&::before {
					padding-right: 0.625rem;
				}
			}
		}

		.divider {
			margin-left: 0;
		}

		.heading-1 {
			@include text(alpha, beta);
			text-align: left;
			margin-bottom: 0.5rem;
		}

		.social {
			display: block;
		}

		.language-dropdown {
			top: 64px;

			+ .heading-1 {
				padding-right: 100px;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.page-header-inner {
			background-color: $color-white;
			margin-bottom: 1rem;
			padding-top: 1.5rem;
		}

		.breadcrumb {
			margin-bottom: 1.25rem;
		}

		.heading-1 {
			@include text(omega);
			margin-bottom: 0.5rem;
		}

		.divider {
			border-top: 0;
			margin-left: 0;
			margin-top: 1rem;
			margin-right: auto;
		}

		.language-dropdown {
			top: 81px;
		}
	}
}
