.pull-quote {
	@include make-container-max-width();
	background-color: $color-precision-gray;
	border: none;
	// border-radius: 0;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
	margin-bottom: 2rem;
	padding: 3rem 1rem 1.5rem;

	&.small,
	&.medium {
		flex: 0 0 75%;
		width: 75%;
		margin-left: auto;
		margin-right: auto;
	}
	&.large {
		flex: 0 0 100%;
		width: 100%;
	}

	.card-header {
		background-color: transparent;
		border-bottom: none;
		color: $color-white;
		padding-top: 0;
		padding-bottom: 0.5rem;
		text-align: center;
	}

	.profile-image {
		background-color: $color-primary-dark-blue;
		border-color: $color-illumination-yellow;
		font-size: 69px;
		font-weight: $font-weight-semi-bold;
		height: 4rem;
		// margin-top: -2rem;
		margin-top: 0rem;
		margin-right: auto;
		margin-bottom: 1rem;
		// margin-left: auto;
		margin-left: 1.5rem;
		width: 4rem;
		min-width: 4rem;

		span {
			top: -0.6875rem;
			position: relative;
			color: $color-illumination-yellow;
		}
	}

	.card-body {
		background-color: transparent;
		border-bottom: none;
		padding-top: 0;
		padding-right: 3rem;
		padding-bottom: 0.5rem;
		padding-left: 3rem;

		p {
			@include text(epsilon, delta);
			color: $color-white;
			font-weight: $font-weight-semi-bold;
			
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.card-footer {
		background-color: transparent;
		border-top: none;
		//color: $color-primary-dark-blue;
		color: $color-white; 
		padding-bottom: 1rem;
		padding-top: 1rem;
		padding-left: 3rem;

		.quote-name {
			@include text(zeta);
			display: inline-block;
			margin-bottom: 0.5rem;
			text-transform: capitalize;
		}

		.designation {
			text-transform: uppercase;
		}

		.title {
			@include text(eta);
			display: block;
			margin-bottom: 0.25rem;
			font-style: italic;
		}
	}

	@include media-breakpoint-up(md) {
		padding-top: 2rem;
		padding-bottom: 1rem;

		.two-column & {
			&.medium {
				.card-body {
					padding-left: 10rem;
					padding-right: 3rem;
				}
			}
		}

		.card-header {
			border-bottom: none;
			padding-bottom: 0.5rem;
			position: absolute;
			top: 1.325rem;
		}

		.card-footer {
			padding-left: 10rem;
		}

		&.left {
			float: left;
			margin-right: 2rem;
		}

		&.center {
			margin-left: auto;
			margin-right: auto;
		}

		&.right {
			float: right;
			margin-left: 2rem;
		}

		&.small {
			flex: 0 0 33.33333%;
			width: 33.33333%;

			.card-header {
				border-bottom: none;
				padding-bottom: 0.5rem;
				position: relative;
				top: 0;
			}

			.card-body {
				padding-left: 3rem;
				padding-right: 3rem;
			}

			.card-footer {
				padding-left: 3rem;
			}
		}

		&.medium {
			flex: 0 0 66.66666%;
			width: 66.66666%;

			.card-body {
				padding-left: 10rem;
				padding-right: 3rem;
			}
		}

		&.large {
			flex: 0 0 100%;
			width: 100%;

			.card-body {
				padding-left: 10rem;
				padding-right: 3rem;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.two-column & {
			&.large {
				.card-body {
					padding-left: 10rem;
					padding-right: 5rem;
				}
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.two-column & {
			&.large {
				.card-body {
					padding-left: 10rem;
					padding-right: 10rem;
				}
			}
		}
	}
}
