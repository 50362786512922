.accordion-container {
	@include make-container();
	@include make-container-max-width();

	.heading-4 {
		margin-bottom: 1rem;
	}

	.accordion {
		> .card {
			border: 0;
			margin-bottom: 1.5rem;

			.card-header {
				background-color: transparent; 
				border-bottom: 0;
				padding: 0;

				&.active {
					background: linear-gradient(to bottom, transparent 50%, #f2f9ff 50%)
				}
			}
		}
	}

	.btn-link {
		@include text(epsilon);
		color: $color-gray-darkest;
		font-weight: $font-weight-semi-bold;
		padding: 0.875rem 3.5rem 0.875rem 1.5rem;
		position: relative;
		text-align: left;
		width: 100%;
		box-shadow: none;
		border-radius: 4rem;


		&.collapsed {
			background-color: $color-neutral-lighter;

			.icon-arrow-down {
				transform: translateY(-50%);
			}
		}

		&[aria-expanded='true'] {
			background-color: $color-gray-secondary;
		}

		.icon-arrow-down {
			fill: $color-primary-dark-blue;
			position: absolute;
			top: 50%;
			transform: rotate(180deg) translateY(50%);
			right: 1.5rem;
		}
	}

	.card-body {
		@include text(epsilon);
		// border: solid 1px $color-gray-light;
		padding: 2rem 2.5rem 2rem;
		background-color: $color-neutral-lightest;
		// margin-top: -2rem;
		border-radius: 5px;
		// font-weight: $font-weight-semi-bold;

		p,
		li {
			@include text(epsilon);
		}

		p {
			a {
				font-weight: $font-weight-bold;
			}
		}

		:last-child:not(.btn) {
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}

	.two-column & {
		.accordion {
			.card-body {
				padding: 2rem 2.5rem 2rem;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.btn-link {
			&:hover {
				color: $color-primary-light-blue;

				.icon-arrow-down {
					fill: $color-primary-light-blue;
				}
			}
		}
	}
}
