.microsite-theme {
	.audio-player {
		@include make-container();
		@include make-container-max-width();
		margin-bottom: 1.25rem;

		.audio-title {
			@include text(delta, 32);
			color: $color-black;
			margin-bottom: 0;
		}

		.audio-description {
			@include text(delta, 30);
		}

		audio {
			width: 100%;
			max-width: 100%;
		}

		audio::-webkit-media-controls-play-button {
			transform: scale(1.5, 1.5);
			left: -2px;
		}

		@include media-breakpoint-up(md) {
			display: flex;

			.audio-text {
				flex: 0 0 calc(50% + 0.5rem);
				max-width: calc(50% + 0.5rem);
				padding-right: 1rem;
			}

			.audio-element {
				display: flex;
				flex: 0 0 calc(50% - 0.5rem);
				justify-content: flex-end;
				max-width: calc(50% - 0.5rem);
				padding-top: 1.75rem;
			}

			audio {
				max-width: 300px;
			}
		}

		@include media-breakpoint-up(lg) {
			.audio-text {
				flex: 0 0 60%;
				max-width: 60%;
			}

			.audio-element {
				display: flex;
				flex: 0 0 40%;
				justify-content: flex-end;
				max-width: 40%;
				padding-top: 1.75rem;
			}
		}
	}
}
