.featured-list-container {
	@include make-container();
	@include make-container-max-width();
	background-color: $color-neutral-lightest;
	margin-bottom: 2rem;

	.two-column & {
		margin-left: -15px;
		width: calc(100% + 30px);
	}

	.section-title {
		@include text(epsilon);
		font-weight: $font-weight-extra-bold;
		color: $color-gray-darkest;
		margin-bottom: 1.25rem;
		padding-top: 2rem;
	}

	.view-all-top-left {
		@include text(zeta);
		color: $color-gray-darkest;
		font-weight: $font-weight-normal;
	}

	.view-all-top-left {
		display: none;
		float: right;
	}

	.btn-container {
		padding-bottom: 2rem;
		text-align: center;
		width: 100%;
	}

	.view-all-bottom {
		display: inline-block;
	}

	.featured-list {
		color: $color-white;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		list-style: none;
		padding: 0;
	}

	.featured-list-item {
		flex: 100%;
		max-width: 100%;
		flex-direction: column;
		padding-top: 1rem;
		padding-bottom: 0;
		margin-bottom: 1rem;
	}

	.featured-DoL {
		border: none;
		border-radius: 0;
		display: flex;
		flex-direction: column;

		.card-header {
			background-color: transparent;
			border-bottom: none;
			padding-top: 0;
			padding-bottom: 0.5rem;
			text-align: left;

			display: flex;
    		align-items: center;

			width: 100%;
			flex-flow: row;
			gap: 1rem;
		}

		.profile-image {
			height: 130px;
			min-width: 130px;
			width: 130px;
		}

		.doctor-name,
		.location-name {
			@include text(epsilon);
			color: $color-gray-darkest;
			display: block;
			font-weight: $font-weight-semi-bold;
			margin-bottom: 0.5rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.specialty,
		.address {
			@include text(eta, zeta);
			color: $color-gray-darkest;
			display: block;
			font-weight: $font-weight-normal;
			margin-bottom: 0.25rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	@include media-breakpoint-up(sm) {
		.featured-list-item {
			flex: 50%;
			max-width: 50%;
		}
	}

	@include media-breakpoint-up(md) {
		background-color: transparent;

		.two-column & {
			margin-left: 0;
			width: 100%;

			.featured-list {
				flex-direction: row;

				.featured-list-item {
					flex: 50%;
					max-width: 50%;
					padding-left: 0;
					padding-right: 0;
					width: 50%;

					&:last-child {
						padding-bottom: 0;
					}
				}
			}
		}

		.featured-list-inner {
			background-color: $color-neutral-lightest;
			border-radius: 5px;
		}

		.section-title {
			text-align: center;

			&::after {
				left: calc(50% - 29px);
			}
		}

		.featured-list {
			&.two-col-layout {
				flex-direction: row;

				.featured-list-item {
					flex: 50%;
					max-width: 50%;
					padding-left: 0;
					padding-right: 0;
					width: 50%;

					&:last-child {
						padding-bottom: 0;
					}
				}
			}

			.featured-list-item {
				flex: 33%;
				max-width: 33%;
				flex-direction: row;
			}
		}

		.two-col-layout & {
			height: auto;
			justify-items: flex-start;
			flex-direction: row;
			margin-left: $profile-card-offset;
			margin-top: 0;

			.card-header,
			.card-footer {
				padding-top: 2rem;
				padding-bottom: 1.5rem;
			}

			.card-header {
				align-items: center;
				display: flex;
				flex-direction: row;
				padding-left: 0;
				text-align: left;

				.appointment-type-label {
					display: none;
				}
			}

			.profile-image {
				margin-top: 0;
				margin-left: $profile-image-offset;
				margin-right: 1rem;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.section-title {
			display: inline-block;
			padding-left: 1.25rem;
			margin-bottom: 1rem;
			text-align: left;

			&::after {
				left: 1.325rem;
			}
		}

		.view-all-top-left {
			display: block;
			float: right;
			margin-top: 1.25rem;
		}

		.view-all-bottom {
			display: none;
		}

		.featured-DoL {
			margin-top: 0;
			.card-header {
				align-items: center;
				display: flex;
				flex-direction: row;
				text-align: left;
			}
			.profile-image {
				margin: 0 1rem 1rem 0;
			}

			.doctor-name,
			.location-name {
				@include text(epsilon, delta);
			}

			.specialty,
			.address {
				@include text(zeta, epsilon);
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.two-column & {
			.featured-list {
				.featured-list-item {
					flex: 33%;
					max-width: 33%;
					flex-direction: row;
					padding-bottom: 1rem;
				}
			}
		}

		.featured-list {
			&.two-col-layout {
				.featured-list-item {
					flex: 33%;
					max-width: 33%;
					flex-direction: row;
					padding-bottom: 1rem;
				}
			}

			.featured-list-item {
				flex: 0 0 calc(100 / 3);
				max-width: calc(100 / 3);

				&:nth-of-type(odd) {
				}

				&:nth-of-type(even) {
				}
			}
		}
	}
}
