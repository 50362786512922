.advance {
	.advance-page-header.has-image {
		position: relative;
		border-bottom: none;
		.page-header-inner {
			h1 {
				padding-top: 28px;
			}
		}
		.hero-img {
			margin: 30px -15px -24px;
			display: block;
			img {
				max-height: 48.6vw;
				width: 100%;
				object-fit: cover;
				object-position: top right;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.advance-page-header.has-image {
			.page-header-inner {
				min-height: 325px;
				max-height: 500px;
				z-index: 2;
				position: relative;
				h1 {
					padding-top: 65px;
					max-width: 45%;
				}
			}
			.hero-img {
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				left: 0;
				display: flex;
				justify-content: flex-end;
				margin: 0;
				z-index: 0;
				img {
					width: 75%;
					height: 100%;
					max-height: unset;
				}
			}
			.page-header--extend {
				z-index: 1;
				&::after {
					background: linear-gradient(
						90deg,
						rgba(0, 54, 74, 1) 60%,
						rgba(0, 54, 74, 0.95) 85%,
						rgba(0, 54, 74, 0.85)
					);
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.advance-page-header.has-image {
			.page-header-inner {
				min-height: 375px;
				max-height: 550px;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.advance-page-header.has-image {
			.page-header-inner {
				min-height: 425px;
				max-height: 600px;
			}
		}
	}

	@media (min-width: 1400px) {
		.advance-page-header.has-image {
			.page-header-inner {
				min-height: 475px;
				max-height: 650px;
			}
		}
	}
}
