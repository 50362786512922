.social-share-healthplan {
	display: none;
	float: right;

	.social-label {
		@include text(theta);
		color: $color-healthplan-blue;
		font-weight: $font-weight-bold;
		letter-spacing: 0.6px;
		margin-right: 0.5rem;
		text-transform: uppercase;
	}

	button {
		display: flex;
		background: transparent;
		border: 0;
		padding: 0;

		&:hover {
			.icon {
				fill: $color-primary-dark-blue;
				stroke: $color-primary-dark-blue;
			}
		}

		+ button {
			margin-left: 0.25rem;
		}
	}

	.icon {
		fill: $color-healthplan-blue;
		height: 1.5rem;
		pointer-events: none;
		stroke: $color-healthplan-blue;
		width: 1.5rem;
	}

	@include media-breakpoint-up(lg) {
		align-items: center;
		display: inline-flex;
		padding-top: 0.5rem;
	}
}
