.advance main {
	.advance--form {
		padding-top: $padding-advance-section-mobile;
		padding-bottom: $padding-advance-section-mobile;
	}
	.marketo-form {
		padding: 8px 0 0;
		margin: 0;
		max-width: 846px;
		.form-description p {
			line-height: 1.5;
		}
		.thank-you-message {
			background: $color-advance-dark-teal;
			.thank-you-bubble {
				&:after {
					border-top-color: $color-advance-dark-teal;
				}
			}
		}
		.mktoForm {
			padding: 27px 0 14px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			& > .mktoFormRow {
				flex-basis: 100%; //calc(50% - 15px);
				margin: 0 0 20px !important;
				&--hidden {
					flex-basis: 100%;
					height: 0;
					overflow: hidden;
					margin: 0 !important;
				}
			}
			.mktoButtonRow {
				flex-basis: 100%;
				button {
					@include button-variant(
						$color-advance-light-teal,
						$color-advance-light-teal,
						$color-advance-dark-teal,
						$color-advance-dark-teal
					);
					min-width: 122px;
					min-height: 48px;
				}
			}
			.mktoInstruction,
			.mktoErrorMsg {
				font-size: 14px;
			}
			label {
				margin-bottom: 11px;
			}
			input,
			textarea {
				&:not(:placeholder-shown) {
					background: $color-advance-white;
				}
				&:focus {
					border-color: $color-advance-light-teal;
				}
			}
			textarea {
				height: 151px;
			}
			select {
				background-image: url("../images/media-library/advance/carat-circle.png");
				background-repeat: no-repeat;
				background-position: calc(100% - 12px) center;
				background-size: 32px 32px;
				&:focus {
					border-color: $color-advance-light-teal;
				}
			}
			.mktoRadioList,
			.mktoCheckboxList {
				margin: 5px 0 10px;
				label {
					font-weight: 400;
					margin: 0;
					padding: 0;
				}
				& + .mktoCheckboxList,
				& + .mktoRadioList {
					margin-top: 15px;
				}
			}
		}
	}
	@include media-breakpoint-up(md) {
		.advance--form {
			padding-top: $padding-advance-section-desktop;
			padding-bottom: $padding-advance-section-desktop;
		}
		.marketo-form {
			padding: 0;
			.mktoForm {
				padding: 27px 0 7px;
				& > .mktoFormRow {
					flex-basis: calc(50% - 15px);
					&--full {
						flex-basis: 100%;
						max-width: 100%;
					}
				}
				.mktoRadioList,
				.mktoCheckboxList {
					label {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
