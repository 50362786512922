.filtered-content {
	margin-top: 2rem;

	&.has-query,
	&.has-category,
	&.has-letter {
		.selected-filters {
			display: block;
		}

		.filters {
			min-height: 0;
		}
	}

	&.has-query {
		.btn-search-clear {
			display: inline-block;
		}

		.btn-search-submit {
			display: none;
		}

		.selected-filters .selected-filter-query {
			display: block;
		}
	}

	&.has-category {
		.selected-filters .selected-filter-category {
			display: block;
		}
	}

	&.has-letter {
		.selected-filters .selected-filter-letter {
			display: block;
		}
	}

	.two-column & {
		margin: 0;

		.content {
			padding: 0;
		}
	}

	.btn-search-clear {
		display: none;
		top: 45%;
	}

	svg {
		pointer-events: none;
	}

	.mobile-filters-toggle {
		width: 80%;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	.filter-list {
		.mobile-filter-header {
			margin-bottom: 1rem;

			.filter-heading {
				width: auto;
			}

			.mobile-close-btn .icon {
				fill: $color-gray-darkest;
			}
		}
	}

	.filtered-content-heading {
		@include text(gamma);
		display: none;
		color: $color-primary-dark-blue;
		margin-bottom: 2rem;
		padding-left: 15px;
	}

	.filter-by-search {
		margin-bottom: 1rem;

		.label {
			padding-left: 1rem;
		}

		.services-typeahead {
			margin-left: 15px;
			margin-right: 15px;
			position: relative;

			&.search-focus {
				.search-dropdown {
					display: block;
				}
			}
		}

		.services-typeahead-input {
			padding: 0.5rem 2.25rem 0.5rem 1.5rem;
			text-align: left;
			outline: none;
			width: 100%;
		}

		.search-dropdown {
			display: none;
		}

		.dropdown-menu {
			overflow: hidden;
		}
	}

	.filters {
		margin-bottom: 2rem;
		min-height: 38px;
		text-align: center;

		.filters-inner {
			background-color: $color-neutral-lightest;
			padding: 1rem;
			text-align: center;
		}

		.label {
			@include text(epsilon);
			font-weight: $font-weight-semi-bold;
			color: $color-primary-dark-blue;
			margin-bottom: 1rem;
			text-align: center;
		}

		.filter-by-category {
			margin-bottom: 2rem;

			.btn {
				@include text(zeta);
				border-radius: 5px;
				padding: 0.375rem 0;
				width: 100%;

				&.active,
				&.active:focus {
					background-color: $color-neutral-dark;
					border-color: $color-neutral-dark;
					color: $color-white;
					box-shadow: none;
				}

				&:not(.active):focus {
					background-color: $color-gray-darkest;
					color: $color-white;
					box-shadow: none;
				}

				.childrens-theme & {
					@include button-variant(
						$color-childrens-primary,
						$color-childrens-primary,
						$color-childrens-secondary,
						$color-childrens-secondary
					);
				}
			}
		}

		.filter-by-letter {
			.letter-list {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				list-style: none;
				padding-left: 0;
			}

			li {
				width: calc(100% / 6);
			}

			button {
				background-color: transparent;
				border: 0;
				color: $color-primary-dark-blue;
				font-weight: $font-weight-bold;
				outline: 0;
				text-decoration: underline;
				text-transform: uppercase;
				padding: 0.5rem 0;
				width: 100%;

				&:disabled {
					background-color: transparent;
					color: $color-neutral;
					text-decoration: none;
				}

				&.active {
					background-color: $color-secondary-yellow;
					border-radius: 6rem;
					color: $color-gray-darkest;
					text-decoration: none;

					.childrens-theme & {
						background-color: $color-childrens-secondary;
					}
				}
			}
		}

		.view-all {
			@include text(zeta);
			background-color: transparent;
			border: 0;
			color: $color-primary-light-blue;
			font-weight: $font-weight-bold;
			outline: 0;
		}
	}

	.selected-filters {
		display: none;
		margin-left: 15px;
		margin-right: 15px;
		border-bottom: 1px solid $color-gray-light;
		padding-bottom: 2rem;

		.selected-filters-label {
			color: $color-neutral-dark;
			font-style: italic;
			padding-bottom: 0.375rem;
		}

		.selected-filter {
			@include text(epsilon);
			color: $color-primary-light-blue;
			display: none;
			font-weight: $font-weight-semi-bold;
			padding-bottom: 0.375rem;
		}

		.selected-filter-category,
		.selected-filter-letter {
			span {
				text-transform: capitalize;
			}
		}

		.remove-filter-btn {
			background-color: transparent;
			border: none;
		}

		.icon-close {
			fill: $color-gray-light;
			height: 0.75rem;
			width: 0.75rem;
		}
	}

	.content {
		@include make-container();
		margin-bottom: 2rem;

		.description {
			@include text(delta);
			font-weight: $font-weight-semi-bold;
			margin-bottom: 1rem;
		}

		.filterable-item-list {
			list-style: none;
			padding: 0;
			margin-bottom: 1.5rem;

			.filterable-item {
				padding: 0.5rem 0;
				display: inline-block;
				width: 100%;

				&.is-hidden {
					display: none;
				}
			}
		}
	}

	.letter-group {
		&.is-hidden {
			display: none;
		}
	}

	.letter-group-heading {
		@include text(delta);
		font-weight: $font-weight-bold;
		margin-bottom: 0.5rem;
		width: 100%;
	}

	@include media-breakpoint-up(md) {
		@include make-container();
		@include make-container-max-width();

		.mobile-filters-toggle {
			display: none;
		}

		.filtered-content-row {
			@include make-row();
		}

		/* override main filter list to switch at md instead of lg */
		.filter-list {
			background-color: transparent;
			opacity: 1;
			pointer-events: auto;
			position: initial;
			visibility: visible;

			.mobile-filter-header,
			.mobile-filter-show-btn {
				display: none;
			}
		}

		.filtered-content-heading {
			padding-left: 0;
		}

		.filter-by-search {
			margin-bottom: 2rem;

			.label {
				padding-left: 1.5rem;
				text-align: left;
			}

			.services-typeahead {
				margin-left: 0;
				margin-right: 0;
			}

			.services-typeahead-input {
				background-color: $color-neutral-lightest;
			}
		}

		.filters {
			@include make-col-ready();
			@include make-col(4);
			margin-bottom: 4rem;

			.filters-inner {
				padding: 1.5rem;
			}

			.label {
				@include text(delta);
				font-weight: $font-weight-bold;
				text-align: left;
			}

			.filter-by-letter {
				button:not([disabled]) {
					&:hover {
						background-color: $color-white;
						text-decoration: none;
						border-radius: 6rem;
					}

					&.active {
						text-decoration: none;

						.childrens-theme & {
							background-color: $color-childrens-secondary;
						}
					}
				}
			}
		}

		.selected-filters {
			display: none !important;
		}

		.content {
			@include make-col-ready();
			@include make-col(8);
			margin-bottom: 4rem;

			.filterable-item-list {
				columns: 2;
			}

			&:only-child {
				@include make-col(12);
			}
		}

		.letter-group-heading {
			@include text(beta);
		}
	}

	@include media-breakpoint-up(xl) {
		.filters {
			@include make-col(3);
		}

		.content {
			@include make-col(9);

			.filterable-item-list {
				column-count: 3;
			}
		}
	}
}
