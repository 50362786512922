.microsite-theme {
	.btn {
		padding-left: 2rem;
		padding-right: 2rem;
		font-weight: $font-weight-semi-bold;
		text-decoration: none;

		&.btn-primary,
		&:not([class*='btn-']) {
			@include button-variant(
				$color-microsite-yellow,
				$color-microsite-yellow,
				lighten($color-microsite-yellow, 8),
				lighten($color-microsite-yellow, 8)
			);
			color: $color-microsite-gray;

			&:hover {
				color: $color-black;
				background-color: $color-white;
    			border-color: $color-primary-yellow;
			}
		}

		&.btn-secondary {
			@include button-variant(
				$color-microsite-gray-lightest,
				$color-microsite-gray-lightest,
				$color-gray-lightest,
				$color-gray-lightest
			);
			color: $color-microsite-gray;

			&:hover {
				color: $color-black;
				background-color: $color-white;
    			border-color: $color-microsite-gray-lightest;
			}
		}

		&[target='_blank'] {
			&:after {
				background-image: url(/assets/images/external-black.png);
				background-size: cover;
				background-position: 50% 50%;
				bottom: -3px;
				display: inline-block;
				content: '';
				height: 16px;
				position: relative;
				width: 28px;
				margin-right: -8px;
			}
		}
	}

	.section-title {
		color: $color-black;
		font-size: 45px;
		font-weight: $font-weight-light;
		line-height: 1;
		padding-bottom: 0;
		margin-bottom: 1.5rem;
		text-align: left;

		&:after {
			display: none;
		}

		@include media-breakpoint-up(md) {
			font-size: 55px;
		}
	}

	@include media-breakpoint-up(md) {
		.two-column,
		.three-column {
			.row {
				margin-right: -0.5rem;
				margin-left: -0.5rem;
			}

			[class^='col-'] {
				padding-left: 0.5rem;
				padding-right: 0.5rem;
			}
		}
	}
}
