.footer {
	background: linear-gradient(to right, $color-gray-darkest 0%, $color-gray-darkest 50%, $color-blue-gray 50%, $color-blue-gray 100%);
	clear: both;

	.footer-inner {
		@include make-container();
		@include make-container-max-width();
		padding: 0;
		display: flex;
		flex-direction: column;
	}

	.marketing {
		@include make-container();
		text-align: center;
		background-color: $color-gray-darkest;
		padding: 3.5rem 15px 3rem;

		.btn {
			&.btn-primary,
			&:not([class*='btn-']) {
				@include button-variant(
					$color-primary-yellow,
					$color-primary-yellow,
					$color-gray-darkest,
					$color-primary-yellow
				);
			}
			border-radius: 6rem;
			display: block;
			padding: 0.75rem 3.375rem;
		}

		.logo-container {
			display: inline-block;
		}

		.profile-image {
			background-color: transparent;
			border: none;
			box-shadow: none;
			height: 230px;
			width: 230px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 2.25rem;

			img {
				border-radius: 50%;
				height: 100%;
				width: 100%;
			}
		}

		.tagline {
			@include text(epsilon);
			margin-bottom: 2rem;
		}

		.form-container {
			margin-bottom: 2rem;
		}

		.marketo-form {
			background-color: transparent;
			padding: 0;
			margin-bottom: 0;

			label {
				display: none;
			}

			input {
				background-color: $color-neutral-lightest;
				border: 1px solid $color-neutral-lightest;
				border-radius: 0;
				height: 3.125rem;
				padding: 0.75rem 1rem;
			}

			.mktoFormRow {
				margin-bottom: 1rem !important;
				margin-right: 0 !important;
				max-width: none;

				&.hidden {
					margin-bottom: 0 !important;
				}
			}

			.mktoButton {
				padding: 0.4375rem 3.375rem;
				font-size: 1rem;
				line-height: 1.375;
				border-radius: 0.3125rem;
				margin-bottom: 0 !important;
				width: 100%;
			}

			.thank-you-message {
				background-color: transparent;
				padding: 0;

				.thank-you-bubble {
					display: none;
				}
			}
		}
	}

	.social-icons {
		text-align: center;
		margin-bottom: 2rem;

		a {
			background: transparent;
			border: 0;
			padding: 0;

			&:hover {
				text-decoration: none;

				.icon {
					fill: $color-secondary-yellow;
					stroke: $color-secondary-yellow;
				}
			}

			+ a {
				margin-left: 0.75rem;
			}
		}

		.icon {
			fill: $color-primary-yellow;
			height: 2rem;
			pointer-events: none;
			stroke: $color-primary-yellow;
			width: 2rem;
		}
	}

	img {
		height: 73px;
		margin-bottom: 3rem;
		width: 234px;
	}

	p {
		@include text(gamma);
		color: $color-white;
		margin-bottom: 1.5rem;
	}

	.social-share {
		margin-bottom: 2rem;
	}

	.icon {
		fill: $color-secondary-light-teal;
		stroke: $color-secondary-light-teal;
	}

	.form-group,
	.form-control {
		@include text(zeta, zeta);
		color: $color-neutral-dark;
	}

	.form-group,
	.form-label-group {
		position: relative;
	}

	.form-group > input,
	.form-group > label,
	.form-label-group > input,
	.form-label-group > label {
		background-color: $color-neutral-lightest;
		border: 1px solid $color-neutral-lightest;
		border-radius: 0;
		height: 3.125rem;
		padding: 0.75rem 1rem;
	}

	.form-group > input,
	.form-label-group > input {
		padding-bottom: calc(0.75rem - 1px);
	}

	.form-group > label,
	.form-label-group > label {
		position: absolute;
		top: 1px;
		left: 0;
		border: none;
		display: block;
		width: 100%;
		margin-bottom: 0; /* Override default `<label>` margin */
		color: $color-neutral-dark;
		pointer-events: none;
		cursor: text; /* Match the input under the label */
		transition: all 0.1s ease-in-out;
	}

	.form-group input::placeholder,
	.form-label-group input::placeholder,
	.form-group input::-ms-input-placeholder,
	.form-label-group input::-ms-input-placeholder {
		color: transparent;
	}

	.links {
		@include make-container();
		background-color: $color-blue-gray;
		text-align: center;
		padding: 2.5rem 15px 1rem;
	}

	.link-lists,
	.link-lists-constant {
		@include text(zeta, epsilon);
		ol {
			padding-left: 0;
			list-style: none;
			li {
				padding-top: 1.5rem;
				&:before {
					display: none;
				}
				a {
					@include text(zeta); /*eta, zeta*/
					color: $color-primary-yellow;
					font-weight: $font-weight-bold;
				}
				ol {
					display: none;
					padding-left: 0;
					li {
						padding-top: 0.25rem;

						&:first-child {
							padding-top: 1.25rem;
						}

						a {
							@include text(zeta); /*eta, zeta*/
							color: $color-white;
							display: block;
							font-weight: $font-weight-normal;
						}
					}
				}
			}
		}
	}

	.link-lists-constant {
		margin-bottom: 4rem;
		ol {
			li {
				&:first-child {
					padding-top: 0.75rem;
				}
			}
		}
	}

	&-small {
		display: block;
	}

	.copyright,
	.policy-terms {
		display: block;
		p {
			@include text(theta);
			color: $color-white;
		}

		ol {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			list-style: none;
			padding-left: 0;
			li {
				&:first-child::before {
					display: none;
				}
				&::before {
					color: $color-white;
					content: '|';
					padding-left: 0.25rem;
					padding-right: 0.25rem;
				}
				a {
					@include text(theta);
					color: $color-white;
					font-weight: $font-weight-normal;
				}
			}
		}
	}

	@include media-breakpoint-up(sm) {
	}

	@include media-breakpoint-up(md) {
		.marketing {
			margin-left: auto;
			margin-right: auto;
		}
		.link-lists,
		.link-lists-constant {
			ol {
				display: flex;
				flex-flow: row wrap;
				li {
					flex-basis: calc(50% - 1.5rem);
					width: calc(50% - 1.5rem);
					&:nth-child(odd),
					&:nth-child(even) {
						padding-left: 0.5rem;
						padding-right: 1rem;
					}
					ol {
						display: block;
						flex-basis: auto;
						width: auto;
						li {
							display: block;
							flex-basis: auto;
							padding-left: 0;
							padding-right: 0;
							width: auto;
							&:nth-child(odd),
							&:nth-child(even) {
								padding-left: 0;
								padding-right: 0;
							}
						}
					}
				}
			}
		}

		.link-lists-constant {
			margin-bottom: 4rem;
			ol {
				li {
					&:first-child {
						padding-top: 1.5rem;
					}
				}
			}
		}

		&-small {
			display: flex;
			flex-flow: row-reverse wrap;
			float: right;
			flex-basis: calc(100% - 2.5rem);
			margin-left: 2.5rem;
			width: calc(100% - 2.5rem);

			.copyright {
				flex-basis: calc(65% - 0.5rem);
				width: calc(65% - 0.5rem);
				margin-right: 0.5rem;
				text-align: left;
				p {
					padding-top: 0.325rem;
				}
			}

			.policy-terms {
				flex-basis: calc(35% - 0.5rem);
				width: calc(35% - 0.5rem);
				margin-left: 0.5rem;
				text-align: right;
				ol {
					display: inline-flex;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.footer-inner {
			flex-direction: row;
		}

		.marketing {
			flex-basis: calc(30% - 1rem);
			float: left;
			height: auto;
			width: calc(30% - 1rem);
			margin-left: 0;
			margin-right: 1rem;
			padding: 4.25rem 15px 1rem;

			.btn {
				display: inline-block;
			}

			.marketo-form {
				.mktoButton {
					width: auto;
				}
				.thank-you-message {
					align-items: flex-start;
				}
			}
		}

		.links {
			float: right;
			display: flex;
			flex-basis: calc(70% - 1rem);
			width: calc(70% - 1rem);
			flex-flow: row wrap;
			text-align: left;
			padding: 3.5rem 15px 1rem;
		}

		.link-lists {
			flex-basis: calc(70% - 2rem);
			float: left;
			height: auto;
			margin-left: 2rem;
			margin-bottom: 4rem;
			margin-right: 0;
			width: calc(70% - 2rem);
		}

		.link-lists-constant {
			flex-basis: calc(30% - 1rem);
			float: right;
			height: auto;
			margin-left: 1rem;
			margin-right: 0;
			width: calc(30% - 1rem);
			ol {
				li {
					flex-basis: auto;
					height: auto;
					width: 100%;
					&:nth-child(odd),
					&:nth-child(even) {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
	}
}
