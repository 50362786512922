.hero-primary {
	color: $color-primary-blue-gray;
	margin-bottom: 0;

	&.is-takeover {
		+ .tool .card-deck {
			margin-top: 0;
		}

		.btn {
			display: inline-block;
			margin-left: 1rem;
		}

		.hero-outer .form-group {
			@include make-container();
			@include make-container-max-width();
			display: block;
		}

		.hero-content .form-group {
			display: none;
		}
	}

	&:not(.is-takeover) {
		.hero-outer .form-group {
			display: none;
		}

		.hero-content .form-group {
			display: block;
			padding-top: 1rem;
		}
	}

	.btn {
		display: none;
	}

	.hero-title {
		@include text(igraeca,ix);
		font-weight: $font-weight-semi-bold;
		padding: 0 0.5rem;
		margin-bottom: 0.5rem;
		margin-top: 0;
		color: $color-secondary-blue;

		&.title-light {
		}
	}

	h3.hero-title {
		margin-bottom: 1rem;
	}

	.hero-outer {
		background-color: $color-neutral-lighter;
		border-radius: 0;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
		position: relative;
	}

	.hero-content-container {
		@include make-container();
		@include make-container-max-width();
		background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 40%,rgba(255,255,255,0.5) 85%,rgba(255,255,255,0) 100%);
		padding-top: 2rem;
		position: relative;
		z-index: 1;

		.global-search-typeahead {
			.search-dropdown {
				background-color: $color-gray-darkest;
				border-radius: 5px;
				top: calc(100% + 1.5rem);
			}

			.primary-view-all {
				border-radius: 0 0 5px 5px;
			}
		}
	}

	.hero-img {
		display: block;
		position: relative;
		text-align: center;
		height: 25rem;
	}

	.hero-img img,
	.hero-img source {
		object-fit: none;
		object-position: 40% 45%;
		height: 35rem;
		width: 100%;
		border-radius: 0;
	}

	&.dark-bg {
		.hero-content-container {
			background: linear-gradient(to top, rgb(44, 55, 63, 1) 0%, rgba(44, 55, 63, 1) 40%, rgba(44, 55, 63, 0.75) 85%, rgba(44, 55, 63, 0) 100%);
			position: relative;
			z-index: 1;

			.hero-content {
				color: $color-white;
			}

			.hero-title {
				color: $color-white;
			}
		}
	}

	.hero-content {
		flex: auto;
		max-width: 100%;
		display: block;
		padding: 1.5rem 0 1rem;
	}

	.form-control {
		@include text(zeta, eta);
		background-color: $color-white;
		border: none;
		color: $color-neutral-dark;
		font-weight: $font-weight-semi-bold;
		padding-left: 0;
		padding-right: 0;

		&:focus {
			background-color: $color-white;
		}
	}

	.hero-outer > {
		.form-group {
			display: block;
			margin-bottom: 0;
			padding: 0 1rem;

			.icon {
				fill: $color-secondary-light-blue;
			}

			label {
				background-color: transparent;
				box-shadow: none;
				margin-bottom: 0;
			}

			.form-control {
				background-color: transparent;
				color: $color-primary-dark-blue;
				display: block;
				&::placeholder {
					color: $color-primary-dark-blue;
				}
			}

			.search-form {
				border-color: $color-secondary-blue;
			}
		}
	}

	.search-form {
		display: flex;
	}

	.form-group {
		padding-top: 1rem;
	}

	label {
		background-color: $color-white;
		border: 1px solid $color-gray-light;
		border-radius: 6rem;
		display: block;
		padding: 1rem 2rem;
		position: relative;
	}

	.search-typeahead-input {
		@include text(epsilon);
		color: $color-gray-darkest;
		padding: 0;

		&::placeholder {
			color: $color-gray-darkest;
		}
	}

	.icon {
		height: 1.25rem;
		fill: $color-neutral;
		display: flex;
		align-self: center;
		right: 0;
		width: 1.5rem;
		z-index: 1;
	}

	.secondary-nav {
		// background-color: #f8f8f8;

		.nav-links {
			list-style: none;
			padding: 1rem 0;

			li {
				font-weight: $font-weight-bold;

				a {
					// border-bottom: 0;
					color: $color-primary-dark-blue;
					text-decoration: none;

					&.btn {
						display: flex;
						justify-content: center;
					}

					&:hover {
						text-decoration: none;
					}

					svg {
						fill: $color-gray-darkest;
						top: -0.125rem;
						position: relative;
					}
				}
			}
		}
	}

	.tertiary-nav {
		.contact-phone {
			@include text(delta);
			color: $color-neutral-darkest;
			display: block;
			outline: 0;
			padding: 2rem 2rem 1rem;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}

		.nav-section-heading {
			@include text(zeta);
			color: $color-secondary-dark-teal;
			font-weight: $font-weight-normal;
			padding: 1rem 2rem 0;
		}

		.nav-links {
			list-style: none;
			column-count: 2;
			column-gap: 0.375rem;
			padding: 0;

			li {
				font-weight: $font-weight-bold;

				&.nurse-help-line-md,
				&.schedule-online-md {
					display: none;

					span {
						font-weight: $font-weight-normal;
					}
				}

				&.nurse-help-line-xs {
					display: block;
				}

				a {
					// border-bottom: 0;
					color: $color-white;
					padding: 0.75rem .5rem;
					text-decoration: none;

					&.btn {
						display: flex;
						justify-content: center;
					}

					&:hover,
					&:focus {
						color: $color-gray-darkest;
						text-decoration: none;

						svg {
							fill: $color-gray-darkest;
						}
					}

					svg {
						fill: $color-white;
						top: -0.125rem;
						position: relative;
						height: 1rem;
						width: 1rem;
						margin: 0 -8px 0 12px;
					}
				}
			}
		}

		.image-links {
			padding: 1rem 2rem 1.75rem;
		}
	}

	@include media-breakpoint-up(sm) {
		.hero-img {
			&.right {
				object-position: 90% 40%;
			}
		}

		.hero-img img,
		.hero-img source {
			object-position: 10% 40%;
		}

		.form-group {
			padding-top: 1rem;
		}

		.form-control {
			@include text(epsilon, eta);
		}
	}

	@include media-breakpoint-up(md) {
		&.is-takeover .btn {
			margin-left: 0;
		}

		.hero-title {
			@include text(igraeca,ix);
			padding: 0;
		}

		.hero-img {
			&.right {
				object-position: 70% 40%;
			}
		}

		.hero-img img,
		.hero-img source {
			object-position: 30% 40%;

			&.right {
				object-position: 70% 40%;
			}
		}

		.secondary-nav .nav-links li {
			&.schedule-online-xs {
				display: none;
			}
		}

		&.dark-bg {
			.hero-content-container {
				background: linear-gradient(to right, rgb(44, 55, 63, 0.8) 0%, rgba(44, 55, 63, 0.6) 40%, rgba(44, 55, 63, 0) 85%, rgba(44, 55, 63, 0) 100%);
				position: absolute;
				z-index: 1;

				.hero-content {
					color: $color-white;
				}

				.hero-title {
					color: $color-white;
				}

				&.right {
					background: linear-gradient(to left, rgb(44, 55, 63, 0.8) 0%, rgba(44, 55, 63, 0.6) 40%, rgba(44, 55, 63, 0) 85%, rgba(44, 55, 63, 0) 100%);
				}
			}

			.hero-img img,
			.hero-img source {
				object-position: 30% 40%;
			}
		}

		.hero-content-container {
			background: transparent;
			display: flex;
			height: 100%;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;

			&.center {
				justify-content: center;
				text-align: center;

				.global-search-typeahead {
					.search-grid {
						left: 50%;
					}
					.search-dropdown {
						left: 0;
					}
				}
			}

			&.right {
				justify-content: flex-end;
				text-align: right;

				.global-search-typeahead {
					.search-grid {
						position: absolute;
						left: 100%;
					}
					.search-dropdown {
						left: 3rem;
						transform: translateX(-100%);
						background-color: $color-gray-darkest;
					}
				}

				.secondary-nav {
					margin-left: auto;
				}

				.form-group {
					margin-left: auto;
				}

				.tertiary-nav {
					.nav-links {
						li {
							&.nurse-help-line-md {
								border: 5px solid transparent;
								display: inline-flex;
								margin-bottom: 1rem;

								a {
									margin-left: 0.25rem;
									margin-right: 0;
									order: 2;
								}

								svg {
									margin: 0 4px;
									top: 0;
								}

								&:first-child {
									order: 2;
								}

								&:last-child {
									order: 1;
								}
							}

							&.nurse-help-line-xs {
								display: none;
							}

							&.schedule-online-md {
								display: block;
							}

							&:last-child {
								display: none;
							}
						}
					}
				}
			}

			&:not(.center):not(.right) {
				.global-search-typeahead {
					.search-dropdown {
						left: -3rem;
						transform: none;
						width: calc(100vw - 100px);
					}
				}
			}
		}

		.hero-img {
			height: 35rem;
		}

		.hero-content {
			flex: 0 0 55%;
			max-width: 55%;
			padding-bottom: 0;
			align-self: center;
		}

		.tertiary-nav {
			padding-right: 0.5rem;
			right: 0;
			bottom: 0;
			position: absolute;

			.nav-links {
				columns: auto 2;
				column-gap: 1rem;

				li {
					&.nurse-help-line-md {
						display: inline-flex;
						margin-bottom: 1rem;

						a {
							margin-right: 0.5rem;
						}

						svg {
							margin: 0 4px;
							top: 0;
						}
					}

					&.nurse-help-line-xs {
						display: none;
					}

					&.schedule-online-md {
						a {
							color: $color-gray-darkest;
						}

						svg {
							fill: $color-gray-darkest;
						}
					}

					&:last-child {
						display: none;
					}
				}
			}
		}

		.form-group {
			padding-top: 2rem;
		}

		.hero-outer > {
			.form-group {
				label {
					padding-left: 0;
					width: 40%;
				}
				.search-dropdown {
					left: 0;
					transform: none;
					width: calc(100vw - 40px);
				}
			}
		}

		.global-search-typeahead {
			.search-grid {
				@include make-container();
				@include make-container-max-width();
				position: relative;
				margin: 0;
				width: 100vw;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.hero-title {
			@include text(igraeca,ix);
		}

		.form-control {
			@include text(delta);
		}

		.hero-content-container {
			&:not(.center):not(.right) {
				.global-search-typeahead {
					.search-dropdown {
						width: 65%;
					}
				}
			}

			.global-search-typeahead {

				.dropdown-primary {
					border-radius: 5px 0 0;
				}

				.dropdown-secondary {
					background-color: $color-blue-gray !important;
					border-radius: 0 5px 0 0;
				}
			}
		}

		.hero-outer > {
			.form-group {
				.search-dropdown {
					width: 65%;
				}
			}
		}

		.hero-content {
			flex: 0 0 50%;
			max-width: 50%;
		}

		.hero-content .form-group {
			max-width: 450px;
		}

		.secondary-nav {
			max-width: 300px;

			.nav-links {
				list-style: none;
				padding: 1rem 0;

				li {
					font-weight: $font-weight-bold;

					a {
						&.btn {
							padding-left: 2rem;
							padding-right: 2rem;
						}
					}
				}
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.form-control {
			@include text(gamma);
		}

		.hero-outer {
			background: linear-gradient(to left, rgba(245, 245, 246, 1) 0%, rgba(245, 245, 246, 1) 50%, rgba(44, 55, 63, 1) 50%, rgba(44, 55, 63, 1) 100%);

			&.right {
				background: linear-gradient(to right, rgba(245, 245, 246, 1) 0%, rgba(245, 245, 246, 1) 50%, rgba(44, 55, 63, 1) 50%, rgba(44, 55, 63, 1) 100%);
			}
		}

		.hero-img {
			height: 40rem;
			order: 2;
		}

		.hero-img img,
		.hero-img source {
			object-fit: cover;
			object-position: 50% 25%;
			height: inherit;
			max-width: 1920px;
		}

		&.dark-bg {
			.hero-img img,
			.hero-img source {
				object-position: 10% 25%;
			}

			.hero-content-container {
				background: linear-gradient(to left, rgba(44, 55, 63, 0) 10%, rgba(44, 55, 63, 0) 40%, rgba(44, 55, 63, 0.5) 60%, rgb(44, 55, 63, 0.8) 80%, rgb(44, 55, 63, 1) 100%);
				min-width: 1200px;
				max-width: 1920px !important;

				.hero-content {
					height: 75%;
					padding-right: 1rem;
					width: 50%;
					max-width: 660px;
					bottom: 0;
					left: auto;
					right: 50%;
					position: absolute;
				}

				&.right {
					background: linear-gradient(to right, rgba(44, 55, 63, 0) 10%, rgba(44, 55, 63, 0) 40%, rgba(44, 55, 63, 0.5) 60%, rgb(44, 55, 63, 0.8) 80%, rgb(44, 55, 63, 1) 100%);
					min-width: 1200px;
					max-width: 1920px !important;

					.hero-content {
						left: 50%;
						right: auto;
					}

					.tertiary-nav {
						padding-right: 0.5rem;
						right: 0;
						bottom: 0;
						position: absolute;
					}
				}
			}
		}
	}
}
