.profile-insurance {
	@include make-container();
	@include make-container-max-width();

	.insurance-list {
		list-style: none;
		margin: 0;
		padding: 0;

		background-color: $color-neutral-lighter;
        border-radius: 5px;
        padding: 2.5rem;

		li {
			display: inline-block;
			margin: 0;
			padding-bottom: 0.375rem;
			width: 100%;
		}
	}

	.notation {
		@include text(zeta);
		margin-bottom: 0.75rem;
	}

	.read-more {
		border-radius: 5px;
	}

	.button-container {
		margin-bottom: 4rem;
		margin-top: -2.5rem;
		background-color: #f5f5f6;
		height: 5rem;
		border-radius: 5px;

		.read-more-link {
			position: relative;

			&.is-expanded {
				font-size: 1rem;
				line-height: 1.125;
				display: inline-block;
				font-weight: 700;
			}
		}
	}


	.section-title {
		&:after{
			background-color: $color-primary-yellow;
		}
	}

	@include media-breakpoint-up(sm) {
		.insurance-list {
			columns: 2;
			column-gap: $grid-gutter-width;

			li {
				padding-bottom: 0.75rem;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.read-more {
			.read-more-body {
				max-height: none;
			}

			.button-container {
				display: none;
			}
		}

		.insurance-list {
			columns: 3;
		}

		.toggle-expand {
			display: none;
		}
	}
	@include media-breakpoint-up(xl) {
		.insurance-list {
			columns: 4;
		}
	}
}
