.global-navigation {
	.healthplan-theme & {
		&.navbar-fixed {
			height: 117px;

			&.no-transition {
				.main-nav {
					.navbar-brand {
						transition: none;
					}

					.main-nav-links {
						transition: none;
					}

					.fixed-search-btn {
						animation: none;
					}
				}
			}

			.hamburger-nav {
				height: calc(100% - 60px);
				top: 60px;

				.primary-nav {
					.nav-links {
						.sub-nav-header {
							button {
								color: $color-moments-black;
							}
						}
					}
				}

				.secondary-nav {
					.nav-links {
						li {
							a {
								color: $color-moments-black;
							}
						}
					}
				}

				.icon-arrow-right,
				.icon-arrow-left {
					fill: $color-moments-black;
				}

				.image-links {
					.image-cta {
						.text-overlay {
							background-color: $color-moments-black;

							.icon-arrow-right {
								fill: $color-moments-black;
							}
						}
					}
				}
			}

			.main-nav {
				height: 60px;

				.navbar-brand {
					height: 47px;
					margin: 0.5rem 0;
					width: 175px;
				}

				.main-nav-links {
					padding-right: 50px;
					transition: padding 0.1s ease-in-out;

					> li {
						> a {
							padding-bottom: 1rem;
						}
					}
				}

				.fixed-search-btn {
					background-color: transparent;
					position: absolute;
					right: 15px;
					top: 0;

					.icon-search {
						stroke: $color-black;
						stroke-width: 1px;
					}
				}
			}

			.main-nav-links {
				padding-right: 0;
			}
		}

		&.hamburger-nav-open {
			.icon-menu-close-alt {
				display: block;
			}

			.icon-menu-open-alt {
				display: none;
			}
		}

		.icon-menu-close-alt {
			display: none;
		}

		.global-search-typeahead {
			background-color: $color-healthplan-blue;
		}

		.hamburger-nav {
			height: calc(100% - 72px);
			top: 72px;

			.nav-links {
				li {
					font-size: 1rem;
				}
			}

			.secondary-nav {
				li {
					// font-size: 0.875rem;
					a {
						// color: $color-secondary-light-blue;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}

		.main-nav {
			height: auto;
		}

		.navbar-brand {
			height: 47px;
			margin: 0.75rem 0;
			padding: 1px 0;
		}

		.main-nav-links {
			padding: 0;
			justify-content: space-around;

			> li > a {
				@include text(zeta);
				padding-bottom: 1rem;
			}

			.nav-item-secondary {
				a {
					color: $color-black;
				}
			}
		}

		// .utility-nav {
		// 	background-color: $color-neutral-lightest;
		// }
	}

	@include media-breakpoint-up(md) {
		.healthplan-theme & {
			.navbar-brand {
				height: 63px;
			}

			.hamburger-nav {
				height: calc(100% - 87px);
				top: 87px;
			}

			.utility-nav-container {
				position: relative;
				justify-content: flex-end;
				background-color: $color-white;

				.utility-nav-links {
					padding: 0 1rem;

					li {
						border-right-color: $color-white;

						&:first-of-type {
							padding-left: 1rem;
						}

						a {
							@include text(eta, theta);
							color: $color-gray-darkest;
							display: inline-block;
							font-weight: $font-weight-semi-bold;
							padding: 0.5rem 1rem;
						}
					}
				}

				.global-search-trigger {
					@include text(eta, theta);
					height: auto;
					padding: 0.5rem 1.5rem;

					.icon {
						stroke: $color-gray-darkest;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		.healthplan-theme & {
			.main-nav {
			.main-nav-links {
				> li {
					> a {
						&.active {
							border-bottom-color: $color-primary-yellow;
						}
					}

					a[target='_blank'] {
						padding-right: 1.5rem;
						position: relative;

						&:after {
							background-image: url(/assets/images/external-black.png);
							background-size: cover;
							bottom: 10px;
							content: '';
							height: 28px;
							position: absolute;
							width: 28px;
							right: -2px;
						}
					}
				}

				> li.has-sub-nav {
					> .sub-nav {
						display: none;
					}

					&.sub-nav-active {
						&.sub-nav-opening {
							> .sub-nav {
								animation: sub-nav-slide-down 0.4s;
								animation-timing-function: ease-in-out;
								z-index: -1;

								@keyframes sub-nav-slide-down {
									from {
										transform: translate(
											-50%,
											calc(-100% - 8px)
										);
									}
									to {
										transform: translate(-50%, 0);
									}
								}

								.sub-nav-container {
									box-shadow: none;
								}
							}
						}

						&.sub-nav-closing {
							> a {
								.icon-arrow-down {
									transform: rotate(0deg);
								}
							}

							> .sub-nav {
								animation: sub-nav-slide-up 0.4s;
								animation-timing-function: ease-in-out;
								transform: translate(-50%, calc(-100% - 8px));
								z-index: -1;

								@keyframes sub-nav-slide-up {
									from {
										transform: translate(-50%, 0);
									}
									to {
										transform: translate(
											-50%,
											calc(-100% - 24px)
										);
									}
								}

								.sub-nav-container {
									box-shadow: none;
								}
							}
						}

						> a {
							.icon-arrow-down {
								transform: rotate(180deg);
							}
						}

						> .sub-nav {
							background: $color-gray-darkest;
							border: 1px solid $color-gray-darkest;
							border-top: 0;
							box-shadow: 0 10px 24px rgba($color-black, 0.12);
							display: block;
							padding: 0;
							position: absolute;
							top: 100%;
							transform: translateX(-50%);
							left: 50%;
							width: 32%;

							.sub-nav {
								margin-left: 2rem;
							}
						}

						.nav-links {
							list-style: none;
							margin: 0;
							padding: 0;

							li {
								padding: 6px 0;
								text-align: left;
							}
						}
					}
				}

				.sub-nav {
					text-align: left;

					ul {
						list-style-type: none;
						padding: 0;

						li {
							@include text(zeta);
							margin-bottom: 0.75rem;
							text-align: left;

							a {
								@include text(zeta);

								&:focus {
									outline: 0;
									text-decoration: underline;
								}
							}
						}
					}

					.heading-6 {
						margin-bottom: 0.75rem;
					}

					.sub-nav-container {
						@include make-row();
						background-color: $color-gray-darkest;
						box-shadow: inset 0 4px 6px rgba($color-black, 0.06);
						margin: 0;
						padding: 1.75rem 1.75rem 1.25rem;
					}

					.sub-nav-primary {
						@include make-col(8);
						border-right: none;
						padding-right: 0rem;
						flex: 100%;
						max-width: 100%;

						&.no-border {
							border-right: none;
							padding-right: 0;
						}
					}

					.sub-nav-secondary {
						@include make-col(4);
						padding: 0 1rem 0 3rem;
					}

					.featured-items {
						margin-bottom: 2rem;

						&:first-child {
							margin-bottom: 2.5rem;
						}
					}

					.featured-links {
						columns: 1;
						column-gap: 1rem;
						line-height: 0;

						> li {
							> a {
								&.active {
									border-bottom-color: $color-primary-yellow;
								}
							}

							a[target='_blank'] {
								padding-right: 1.5rem;
								position: relative;

								&:after {
									background-image: url(/assets/images/external-white.png);
									background-size: cover;
									bottom: -5px;
									content: '';
									height: 28px;
									position: absolute;
									width: 28px;
								}
							}
						}
					}

					.quick-links {
						.quick-links-header {
							align-items: center;
							display: flex;
							margin-bottom: 0.75rem;

							.heading-6 {
								margin-bottom: 0;
								margin-right: 0.75rem;
							}

							a {
								@include text(eta);

								&:focus {
									outline: 0;
									text-decoration: underline;
								}
							}
						}

						.quick-links-container {
							display: flex;
							margin-bottom: 1rem;
						}

						.btn-icon {
							background-color: $color-white;
							border-color: $color-white;
							color: $color-primary-blue-gray;
							margin-left: 1.5rem;
							padding-left: 1.75rem;
							padding-right: 1rem;
							position: relative;

							&:not(:last-of-type) {
								margin-right: 1rem;
							}

							&:hover {
								background-color: $color-neutral-lighter;
								border-color: $color-neutral-lighter;
							}

							&:focus {
								box-shadow: 0 0 0 0.2rem
									rgba($color-neutral-light, 0.5);
							}

							picture,
							svg {
								align-items: center;
								display: flex;
								height: 2rem;
								justify-content: center;
								left: 0;
								position: absolute;
								top: 50%;
								transform: translate(-50%, -50%);
								width: 3rem;

								img {
									width: 100%;
								}
							}
						}

						.quick-links-note {
							@include text(theta);
							color: $color-neutral-darkest;
							font-style: italic;
							margin-bottom: 0;
						}
					}

					.section-list {
						li {
							margin-bottom: 0.5rem;
						}

						a {
							@include text(zeta);
							color: $color-white;
							background-color: $color-primary-blue-gray;
							display: block;
							font-weight: $font-weight-semi-bold;
							padding: 1rem;
							width: 100%;
						}
					}

					.sub-nav-categories {
						display: flex;

						.sub-nav-category {
							flex: 1;
							margin-right: 3rem;
						}
					}

					.browse-all-link {
						@include text(zeta);
						align-items: center;
						display: flex;
						font-weight: $font-weight-semi-bold;
						height: 50px;
						justify-content: center;
						text-align: center;
						width: 100%;

						&:focus {
							outline: 0;
							text-decoration: underline;
						}
					}
				}
			}
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.healthplan-theme & {
			.main-nav-links {
				padding: 0 10px 0 50px;
				justify-content: space-between;

				> li > a {
					@include text(epsilon);
				}
			}

			.utility-nav-container {
				.utility-nav-links {
					li {
						a {
							@include text(eta, eta);
						}
					}
				}

				.global-search-trigger {
					@include text(zeta, eta);
				}
			}
		}
	}
}
