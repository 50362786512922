.advance {
	.generic {
		.rte,
		&.rte {
			p,
			li,
			span {
				@include text(zeta);
			}

			p {
				line-height: 1.3;

				&:first-of-type {
					padding-top: 0;
				}
			}

			ul {
				padding-left: 0.5rem;
			}
		}

		.alt-background {
			:last-child {
				margin-bottom: 0;
			}
		}
	}
}
