.microsite-theme {
	.global-navigation {
		&.navbar-fixed {
			.main-nav {
				box-shadow: 0 4px 6px rgba($color-black, 0.06);
			}
		}

		.main-nav {
			box-shadow: none;
			border-bottom: none;
		}

		.btn[target='_blank']::after {
			background-image: none;
			display: none;
		}

		@include media-breakpoint-up(lg) {
			&.navbar-fixed {
				.main-nav {
					&:before {
						box-shadow: 0 4px 6px rgba($color-black, 0.06);
					}
				}
			}

			.main-nav {
				&:before {
					box-shadow: none;
					border-bottom: none;
				}
			}
		}
	}
}
