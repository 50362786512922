$padding-advance-section-desktop: 63px;
$padding-advance-section-mobile: 36px;
$padding-advance-mobile: 20px;
$advance-content-width: 884px; //844+40

.advance {
	/* Elements */
	img {
		display: block;
		max-width: 100%;
	}
	a {
		color: $color-advance-blue;
		transition: color 0.2s;
		&:hover {
			color: $color-advance-dark-teal;
		}
	}

	/* BUTTON */
	.btn {
		padding: 9px 18px 7px;
		font-size: 14px;
		line-height: initial;
		font-weight: bold;
		border-radius: 8px;
		cursor: pointer;
		transition: all 0.3s;
		&:hover {
			text-decoration: none;
		}

		/* button size */
		&.btn-lg {
			padding: 10px 20px;
			font-size: 16px;
			line-height: 26px;
		}
		&.btn-md {
			margin-bottom: -6px;
			font-size: 14px;
			line-height: 26px;
			padding: 10px 20px;
		}
		&.btn-sm {
			padding: 4px 18px 4px;
			font-size: 14px;
			line-height: 26px;
		}

		/* button color */
		&.btn-primary {
			@include button-variant(
				$color-advance-yellow,
				$color-advance-yellow,
				$color-advance-dark-teal,
				$color-advance-dark-teal
			);
			&:hover {
				color: $color-white;
			}
		}

		&.btn-secondary {
			@include button-variant(
				$color-advance-light-teal,
				$color-advance-light-teal,
				$color-advance-dark-teal,
				$color-advance-dark-teal
			);
		}

		&.btn-tertiary {
			@include button-variant(
				$color-advance-off-white,
				$color-advance-off-white,
				$color-advance-light-grey,
				$color-advance-light-grey
			);
			color: $color-advance-dark-teal;
		}

		&.btn-link {
			@include button-variant(
				$color-advance-off-white,
				$color-advance-off-white,
				$color-advance-dark-teal,
				$color-advance-dark-teal
			);
			border: 1px $color-advance-light-grey solid;
			color: $color-advance-dark-teal;
			&:hover {
				background: $color-advance-dark-teal;
				color: $color-white;
			}
			svg {
				margin: -3px -9px -2px 7px;
				height: 28px;
				width: 28px;
				fill: $color-advance-light-teal;
				color: $color-advance-dark-teal;
			}
			&.btn-sm svg {
				margin: -2px -7px 0 5px;
				height: 22px;
				width: 22px;
			}
		}
	}
	.advance--bk--dark-teal {
		.btn-primary,
		.btn-secondary {
			&:hover {
				background: $color-advance-off-white;
				color: $color-advance-dark-teal;
			}
		}
		.btn-tertiary {
			background: $color-advance-white;
			&:hover {
				background: $color-advance-off-white;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.btn {
			padding: 10px 20px;
			font-size: 16px;
			line-height: 26px;
		}
	}

	/* SVGs */
	main {
		svg {
			fill: $color-advance-dark-teal;
			stroke-width: 0;
			path,
			circle {
				stroke-width: 0;
			}
			&.icon-white {
				fill: $color-advance-white;
				color: $color-advance-dark-teal;
			}
		}
	}

	/* SCROLLBAR */
	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}
	::-webkit-scrollbar-track {
		background-color: $color-advance-light-grey;
	}
	::-webkit-scrollbar-thumb {
		background-color: $color-advance-mid-blue;
		background-clip: padding-box;
		border: 15px solid rgba(0, 0, 0, 0);
	}

	/** SPACINGS **/
	.advance--bk--dark-teal + .advance--bk--dark-teal,
	.advance--bk--white + .advance--bk--white {
		padding-top: 0 !important;
	}

	/** BACKGROUNDS **/
	/** blue **/
	.advance--bk--dark-teal {
		background-color: $color-advance-dark-teal;
		color: $color-advance-white;
	}
	.advance--bk--dark-teal h1,
	.advance--bk--dark-teal p,
	.advance--bk--dark-teal span {
		color: $color-advance-white;
	}
	/** gray **/
	.advance--bk--gray {
		background-color: $color-advance-off-white;
	}
	/** teal **/
	.advance--bk--teal {
		background-color: $color-advance-light-teal;
	}
	/** yellow **/
	.advance--bk--yellow {
		background-color: $color-advance-yellow;
	}

	/** gray **/
	.advance--txt--gray {
		color: $color-advance-off-white !important;
	}
	/** teal **/
	.advance--txt--teal {
		color: $color-advance-light-teal;
	}
	.advance--txt--dark-teal {
		color: $color-advance-dark-teal;
	}
	.advance--txt--mid-bluegrey {
		color: $color-advance-mid-bluegrey;
	}
	/** yellow **/
	.advance--txt--yellow {
		color: $color-advance-yellow;
	}

	/* extend color left */
	.extend-left::after {
		position: absolute;
		width: 50vw;
		top: 0;
		content: '';
		height: 100%;
		right: 100%;
		background: $color-advance-dark-teal;
	}

	/* breadcrumbs */
	.breadcrumb {
		background: transparent;
		padding: 8px 0 10px 0;
		border-radius: 0;
		.breadcrumb-item {
			display: block;
			padding-left: 5px;
			font-size: 16px;
			&::before {
				display: inline-block;
				content: '/';
				padding: 0 5px 0 1px;
				color: $color-advance-green;
			}
			&:first-child {
				padding-left: 0;
				&::before {
					display: none;
				}
			}
			.icon {
				display: none;
			}
		}
		a {
			color: $color-white;
		}
	}
}
