.video {
	padding: 56.25% 0 0 0;
	position: relative;

	// 16:9
	&.standard {
		padding: 56.25% 0 0 0;
		position: relative;
	}

	// 2.35:1
	&.anamorphic {
		padding: 42% 0 0 0;
		position: relative;
	}

	// 4:3
	&.academy {
		padding: 75% 0 0 0;
		position: relative;
	}

	// 1:1
	&.square {
		padding: 100% 0 0 0;
		position: relative;
	}

	// 2:1
	&.widescreen {
		padding: 50% 0 0 0;
		position: relative;
	}

	&.loaded {
		padding: 0;

		.wistia_embed {
			position: relative;
		}
	}

	.wistia_embed {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		> .wistis_swatch {
			height: 100%;
			left: 0;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			top: 0;
			transform: opacity 200ms;
			width: 100%;
			> img {
				filter: blur(5px);
				height: 100%;
				object-fit: contain;
				font-family: 'object-fit: contain;';
				width: 100%;
			}
		}

		> div {
			border-radius: 5px !important;
		}

		.w-bpb-wrapper {
			//border: 5px solid $color-white !important;
			border-radius: 50% !important;
			height: 80px !important;
			overflow: hidden;
			width: 80px !important;
			margin-top: 0 !important;
			margin-left: 0 !important;
			top: calc(50% - 40px) !important;
			left: calc(50% - 40px) !important;

			button {
				height: 100% !important;
				width: 100% !important;

				div {
					border-radius: 50% !important;
				}

				div,
				svg {
					width: 100% !important;
					height: 100% !important;
					background-color: $color-primary-yellow;
					border-radius: 7rem;
				}
			}
		}
	}

	@include media-breakpoint-up(sm) {
		.wistia_embed {
			.w-bpb-wrapper {
				//border-width: 8px !important;
				height: 112px !important;
				width: 112px !important;
				top: calc(50% - 56px) !important;
				left: calc(50% - 56px) !important;
			}
		}
	}
}
