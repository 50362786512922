.bi-search-results {
	display: flex;
	flex-direction: column;
	margin-top: 0.5rem;
	margin-left: auto !important;
	margin-right: auto !important;

	.bi-search-filters {
		@include make-container();
		@include make-container-max-width();

		/* Adjust margins for search container according to Figma specs. See https://mhhsmarketing.atlassian.net/browse/SIT-2083*/
		padding-right: 0px !important;
		padding-left: 0px !important;

		.filter-btn {
			span {
				@include text(eta);
				font-weight: $font-weight-semi-bold;
				display: inline-flex;
				align-items: center;
			}

			.icon {
				flex-shrink: 0;
				margin-left: 0.25rem;
				width: 1.25rem;
				height: 1.25rem;
			}
		}

		.filters-container {
			margin-bottom: 1rem;
			padding-top: 1.5rem;
		}

		.filters-form {
			@include make-container();

			.filter {
				margin-bottom: 1.5rem;

				.filter-label {
					@include text(eta);
					font-weight: $font-weight-semi-bold;
				}

				.filter-field {
					.two-fields {
						display: flex;
						flex-direction: row;
						justify-content: space-between;

						input, select {
							flex: 0 0 calc(50% - 5px);
						}
					}
					input {
						@include text(zeta);
						color: $color-neutral-darkest;
						font-weight: $font-weight-semi-bold;
					}
					select {
						@include text(eta);
						padding: 0.5rem 1rem;
						border: 1px solid $color-gray-light;
						border-radius: 6px;
						width: 100%;
					}
				}
			}
		}
	}

	// Removing margins to adust for vertical alignment. See https://mhhsmarketing.atlassian.net/browse/SIT-2083 
	.bi-result-card {
	// 	margin-left: 15px;
	// 	margin-right: 15px;
	}


	.card-header {
	}

	.profile-image {
		display: block;
		margin-bottom: 1rem;

		&[href] {
			&:hover,
			&:focus {
				box-shadow: 0 8px 24px 0 rgba($color-black, 0.24);
				text-decoration: none;

				+ .doctor-details {
					.doctor-name {
						color: $color-secondary-light-blue;
						text-decoration: underline;
					}
				}
			}
		}

		&.has-hours {
			display: flex;
			flex-direction: column;

			.location-image {
				flex: 1;
				height: 100%;
				overflow: hidden;
				position: relative;

				img {
					height: 100%;
					object-fit: cover;
					object-position: 50% 50%;
					font-family: 'object-fit: cover; object-position: 50% 50%;';
					width: 100%;
				}

				svg {
					background-color: $color-neutral;
					position: absolute;
					height: auto;
					left: 50%;
					min-height: 100%;
					top: 56%;
					transform: translate(-50%, -50%);
					width: 100%;
				}
			}

			.open-status {
				@include text(zeta);
				background-color: $color-white;
				bottom: 0;
				color: $color-neutral-dark;
				font-weight: $font-weight-bold;
				flex: 0 0 34px;
				padding: 3px 1.125rem 0;
				text-align: center;
				width: 100%;

				&.open {
					color: $color-open;
				}
			}
		}
	}

	.doctor-details {
		width: 100%;
		padding-right: 1rem;
		text-align: left;
	}

	.more-locations {
		padding-top: 1rem;
	}

	.doctor-name {
		@include text(delta);
		color: $color-primary-light-blue;
		display: inline-block;
		font-weight: $font-weight-bold;
		margin-bottom: 0.5rem;

		&[href] {
			&:hover,
			&:focus {
				color: $color-secondary-light-blue;
			}
		}
	}

	.doctor-ratings {
		font-weight: $font-weight-semi-bold;
		align-items: center;
		display: flex;
		justify-content: flex-start;
		margin-bottom: 0.5rem;

		.rating-stars {
			margin-right: 0.75rem;
			width: auto;
		}

		.rating-count {
			@include text(zeta);
			color: $color-neutral-dark;
		}
	}

	.specialty {
		@include text(eta);
		font-weight: $font-weight-semi-bold;
		display: block;
		margin-bottom: 0.25rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.doctor-location {
		text-align: left;
		padding: 2rem 0 1rem;

		.appointment-type-label {
			padding: 0.125rem 0;
		}
	}

	.phone-number,
	.appointment-type-label,
	.location-name,
	.address,
	.appointment-time,
	.modal-open-btn {
		@include text(eta);
	}

	.distance-and-location,
	.appointment-request-phone {
		@include text(zeta);
		font-weight: $font-weight-semi-bold;
	}

	.distance-and-location {
		.in-miles::after {
			content: "|";
			padding-right: .5rem;
			padding-left: .5rem;
		}

		.get-directions::before {
			content: none;
		}
	}

	.appointment-heading,
	.appointment-date {
		@include text(theta);
	}

	.location-name {
		color: $color-primary-blue;
		display: block;
		margin-bottom: 0.5rem;

		&:only-child {
			margin-bottom: 0;
		}
	}

	.distance {
		color: $color-secondary-light-blue;
		font-weight: $font-weight-semi-bold;
		padding-right: 0.5rem;
	}

	.appointment-type-label {
		color: $color-primary-dark-blue;
	}

	.card-footer {
		background-color: $color-gray-lightest;
		border-top: none;
		padding: 1rem 1.5rem;

		.location-address {
			@include text(zeta);
			border-bottom: none;
			margin-bottom: 0;

			&:empty {
				display: none;
			}
		}

		.address {
		}

		.appointment-type-label {
			display: none;
		}

		.appointment-button-container {
			display: flex;
			justify-content: center;
			margin-bottom: 0.5rem;
		}

		.appointment-heading {
			color: $color-primary-dark-blue;
			font-weight: $font-weight-bold;
			margin-bottom: 0.75rem;
		}

		.appointment-date {
			color: $color-primary-blue;
			font-weight: $font-weight-bold;
			margin-bottom: 0.5rem;
		}

		.appointment-time {
			margin-right: 1rem;
		}

		.appointment-request-container {
			//padding: 0 1.5rem;
		}

		.appointment-request-btn {
			color: $color-white;
			padding: 7px;
			width: 100%;

			+ .appointment-request-phone {
				padding-top: 1rem;
			}
		}

		.appointment-request-phone {
			justify-content: flex-start;

			svg {
				flex: 0 0 2rem;
				height: 2rem;
				margin-right: 1rem;
				width: 2rem;
			}

			a {
				color: $color-primary-blue;
				display: block;
			}
		}

		.modal-open-btn {
			background: none;
			border: none;
			color: $color-primary-light-blue;
			font-weight: $font-weight-normal;
			padding: 0 0.625rem 0 0;
			position: relative;

			&:after {
				border-bottom: 6px solid transparent;
				border-left: 6px solid $color-quick-links;
				border-top: 6px solid transparent;
				clear: both;
				content: '';
				display: inline-block;
				height: 0;
				left: 100%;
				position: absolute;
				top: 1px;
				width: 0;
			}

			&:hover,
			&:focus {
				background: none;
				border: none;
				color: $color-primary-light-blue;
				outline: 0;
				text-decoration: underline;
			}
		}

		.legend {
			padding-top: 1rem;
		}
	}

	.view-mode-map & {
		height: auto;
		justify-items: flex-start;
		flex-direction: row;
		margin-left: 0;
		margin-top: 0;

		.card-header {
			align-items: center;
			border-radius: 0;
			display: flex;
			flex-direction: row;
			overflow: hidden;
			padding: 0;
			text-align: left;
			width: auto;

			.appointment-type-label {
				display: none;
			}
		}

		.profile-image {
			margin: 0 1rem 0 0;
			border-radius: 0;
			border: none;
			min-width: 175px;

			&.has-hours {
				.location-image {
					svg {
						top: 50%;
					}
				}
			}
		}

		.doctor-details {
			padding: 1rem 0;
		}

		.doctor-location {
			padding: 1rem 0 0;
		}

		.card-footer {
			display: none;
			width: 0;
		}
	}

	.loc-provider-specialty {
		@include text(zeta);
		display: block;
		background-color: $color-neutral-lighter;
		border-top: none;

		.loc-provider-specialty-content {
			align-items: center;
			justify-content: center;
			text-align: center; //left
		}
		.loc-provider-specialty-list {
			@include text(zeta);
			align-items: center;
			color: $color-primary-blue;
			display: inline-block;
			justify-content: center;
			padding: 0 1rem;
			list-style: none; //or disc?

			a {
				display: block;
			}
		}
	}

	.card-header {
		&.compromise {
			text-align: center;

			>.affiliation {
				display: none;
			}

			>.doctor-details>.affiliation {
				display: block;

				.affiliation-logo {
					float: none;
					margin-left: auto;
					margin-right: auto;
				}
			}

			.profile-image {
				margin-left: auto;
				margin-right: auto;
			}

			.doctor-ratings {
				justify-content: center;
			}
		}
	}

	@include media-breakpoint-up(md) {
		height: calc(100% - #{$profile-card-offset});
		justify-content: space-between;

		.bi-search-filters {
			.filters-form {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;

				.filter {
					margin-bottom: 1.5rem;
					flex: 0 0 calc(50% - 10px);
				}

				&.hidden {
					display: none;
				}
			}
		}

		.bi-result-card {
			margin-left: calc(130px / 2 + 0.5rem);
		}

		.card-header,
		.card-footer {
			display: flex;
			flex-direction: row;
		}

		.card-header {
			align-items: center;

			&.compromise {
				text-align: center;

				>.affiliation {
					display: block;
				}

				.doctor-details {
					text-align: left;
				}

				>.doctor-details>.affiliation {
					display: none;
				}

				.profile-image {
					margin-right: 1rem;
				}

				.doctor-ratings {
					justify-content: flex-start;
				}
			}
		}

		.profile-image {
			margin-right: 1rem;
		}

		.card-footer {
			.location-address,
			.appointment-request-container {
				// flex: 0 0 50%;
				// width: 50%;
			}
		}

		.view-mode-list & {
			height: auto;
			justify-items: flex-start;
			flex-direction: row;
			margin-left: $profile-card-offset;
			margin-top: 0;

			.card-header,
			.card-footer {
				padding-top: 1.5rem;
				padding-bottom: 1.5rem;
				width: 50%;
			}

			.card-header {
				align-items: center;
				display: flex;
				flex-direction: row;
				padding-left: 0;
				text-align: left;

				.appointment-type-label {
					display: none;
				}
			}

			.doctor-ratings {
				justify-content: flex-start;
			}

			.profile-image {
				margin-top: 0;
				margin-left: $profile-image-offset;
				margin-right: 1rem;
				margin-bottom: 0;
			}

			.doctor-location {
				padding-bottom: 0;
			}

			.card-footer {
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 50%;

				.appointment-type-label {
					@include text(zeta);
					display: block;

					&:not(:last-of-type) {
						padding-bottom: 0.25rem;
					}
				}

				.location-address {
					&:empty {
						display: block;
						padding-bottom: 0;
					}
				}

				.appointment-request-container {
					padding: 0;
				}
			}
		}

		.view-mode-map & {
			.profile-image {
				display: flex;
				flex: 0 0 135px;
				min-height: 155px;
				min-width: 175px;
				position: relative;

				.open-status {
					align-items: center;
					display: flex;
					flex: 0 0 47px;
					justify-content: center;
					padding-top: 0;
				}
			}

			.doctor-details {
				padding: 1rem 1rem 1rem 0;

				.doctor-name {
					@include text(epsilon);
				}
			}
		}

		.specialty {
			@include text(zeta);
		}

		.affiliation {
			border-left: 1px solid $color-neutral-dark;
			padding-left: 1rem;
			order: 3;
		}

		.distance-and-location,
		.phone-number,
		.appointment-type-label,
		.location-name,
		.address,
		.appointment-time,
		.modal-open-btn {
			@include text(zeta);
		}

		.appointment-heading,
		.appointment-date {
			@include text(eta);
		}

		.card-footer {
			flex-wrap: wrap;
			align-content: flex-start;

			.appointment-time {
				margin-right: 1.4rem;
			}

			.appointment-request-phone {
				padding: 0;
			}

			.location-address {
				margin-bottom: 0;
			}

			.modal-open-btn {
				&:after {
					top: 4px;
				}
			}

			.legend {
				display: none;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.bi-search-filters {
			.filter-btn {
				display: none;
			}

			.filters-form {
				justify-content: flex-start;

				.filter {
					flex: 0 0 calc(33.3333% - 20px);
					margin-right: 30px;

					&:nth-child(3n) {
						margin-right: 0;
						// Add margin-left: auto to achieve vertical alignment in the flexbox alignment. See https://mhhsmarketing.atlassian.net/browse/SIT-2083. It may be worth looking at doing a last-of-type here. This way we can be assured that last element will flex to the end of the container instead of targeting a specific element
						margin-left: auto;
					}
				}
			}
		}

		.bi-result-card {
			flex-direction: row;

			.card-header {
				flex: 0 0 55%;
				max-width: 55%;
			}

			.card-footer {
				flex: 0 0 45%;
				max-width: 45%;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.bi-search-filters {
			.filters-form {
				.filter {
					flex: 0 0 calc(33.33% - 30px);
					margin-right: 40px;
				}
			}
		}

		.card-footer {
			.loc-provider-specialty-content {
				padding: 0 1.25rem;
			}
		}

		.view-mode-map & {
			.profile-image {
				flex: 0 0 175px;
				margin-right: 2rem;
				min-height: 205px;
				min-width: 232px;
			}

			.doctor-details {
				padding-right: 2rem;

				.doctor-name {
					@include text(epsilon);
				}
			}
		}
	}
}
