.advance {
	.advance--article-grid + .advance--article-grid {
		margin-top: -2px;
	}
	.advance--article-grid,
	.advance--article-grid--scroll {
		padding-top: $padding-advance-section-mobile;
		padding-bottom: $padding-advance-section-mobile;
		.heading {
			padding-bottom: 20px;
			padding-top: 15px;
		}
		.article-grid--contain {
			margin-bottom: -15px;
			.advance--article-card--date {
				display: none;
			}
		}
		.btn--container {
			text-align: center;
			padding: 0px;
			margin: 33px 0 3px;
		}
		&.advance--bk--gray {
			.container {
				&::before,
				&::after {
					background: $color-advance-off-white;
				}
			}
			.heading {
				padding-bottom: 22px;
				padding-top: 8px;
				margin-top: -12px;
			}
			.advance--article-card {
				.btn {
					@include button-variant(
						$color-advance-white,
						$color-advance-white,
						$color-advance-dark-teal,
						$color-advance-dark-teal
					);
					border: 1px solid $color-advance-light-grey;
					&:hover {
						color: $color-white;
					}
				}
			}
			.btn--container {
				margin: 7px 0 1px;
			}
		}
	}
	.advance--article-grid--scroll {
		.container {
			position: relative;
			&::before,
			&::after {
				background: $color-advance-white;
				width: 20px;
				left: 0;
				bottom: 0;
				content: "";
				position: absolute;
				z-index: 9;
				height: 100px;
			}
			&::after {
				left: unset;
				right: 0;
			}
		}
		.article-grid--contain {
			overflow-x: auto;
			overflow-y: hidden;
			padding: 0 20px 20px;
			margin: 0 -20px;
		}
		.row {
			flex-wrap: nowrap;
		}
	}
	@include media-breakpoint-up(md) {
		.advance--article-grid + .advance--article-grid {
			margin-top: -16px;
		}
		.advance--article-grid,
		.advance--article-grid--scroll {
			padding-top: $padding-advance-section-desktop;
			padding-bottom: $padding-advance-section-desktop;
			.heading {
				padding-bottom: 16px;
				padding-top: 15px;
			}
			.article-grid--contain {
				margin: 12px 0 4px;
				padding-bottom: 28px;
				.row {
					row-gap: 30px;
				}
			}
			.btn--container {
				text-align: center;
				padding: 42px 0 0px;
				margin: -3px 0 -10px;
			}

			&.advance--bk--gray {
				.heading {
					padding-bottom: 24px;
					padding-top: 5px;
					margin-top: -12px;
				}
				.btn--container {
					text-align: center;
					margin-bottom: -15px;
				}
			}
		}
		.advance--article-grid {
			.article-grid--contain {
				position: relative;
				margin: 12px 0 4px;
				&::after {
					border-bottom: 1px $color-advance-light-grey solid;
					content: "";
					position: absolute;
					top: 100%;
					left: 0px;
					right: 0px;
				}
				& > .row {
					margin: 0 -12px;
					& > div {
						padding-right: 12px;
						padding-left: 12px;
					}
				}
			}
			.advance--article-card {
				&::before {
					display: none;
				}
			}
		}
		.advance--article-grid--scroll {
			.article-grid--contain {
				margin: 12px -20px 6px;
			}
			.container {
				&::before,
				&::after {
					top: 0;
					height: unset;
				}
			}
		}
	}
}
