.insurance-cards-container {
	@include media-breakpoint-up(md) {
		display: flex;
		flex-flow: wrap;
		justify-content: flex-start;

		.insurance-card {
			flex: 0 1 calc(49% - calc(30px / 2));
			margin-right: 1rem;

			&.double-wide,
			&.full-width {
				flex: 0 1 100%;
				margin-right: 1rem;
			}
		}

		.col-lg-8 & {
			.insurance-card {
				flex: 0 1 calc(49% - calc(30px / 2));
				margin-right: 1rem;
			}
		}

		.col-lg-4 & {
			.insurance-card {
				flex: 0 1 calc(49% - calc(30px / 2));
				margin-right: 1rem;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.btn {
			padding: 0.75rem 2.375rem;
		}

		.insurance-card {
			flex: 0 1 calc(25% - calc(30px * 3 / 4));
			margin-right: 1rem;

			&.double-wide {
				flex: 0 1 calc(49% - calc(30px / 2));
				margin-right: 1rem;
			}
		}

		.col-lg-4 & {
			.insurance-card {
				flex: 0 1 100%;
			}
		}
	}
}
