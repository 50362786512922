.bmi-calculator {
	@include make-container();
	@include make-container-max-width();
	margin-bottom: 2rem;
	background-color: $color-neutral-lightest;
	padding-bottom: 1.5rem;
	padding-top: 1.5rem;

	.title {
		margin-bottom: 1.25rem;
	}

	label {
		@include text(zeta);
		font-weight: $font-weight-semi-bold;
	}

	input[type='text'],
	select {
		@extend .form-control;
		@include text(epsilon);
		background-color: $color-white;
		border: 1px solid $color-white;
		border-radius: 0;
		color: $color-primary-blue;
		width: 100% !important;

		&:focus {
			background-color: $color-white;
			border-color: $color-secondary-light-blue;
			box-shadow: none;
		}
	}

	select {
		appearance: none;
		background-image: none;

		&::-ms-expand {
			display: none;
		}
	}

	.height {
		display: flex;
		margin-bottom: 1rem;

		.height-item {
			flex: 1;
			margin-right: 1rem;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	.weight {
		margin-bottom: 1.5rem;
	}

	.conditions-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 1rem;

		.checkbox-wrapper {
			margin-bottom: 1rem;
			flex: 0 0 calc(50% - 1rem);
			max-width: calc(50% - 1rem);
		}
	}

	.error {
		display: none;

		&.show {
			@include text(epsilon);
			color: $color-red;
			display: block;
			margin-top: 1rem;
		}
	}

	.bmi-results-container {
		display: none;

		&.show {
			background-color: rgba($color-white, 0.8);
			display: block;
			margin-top: 2rem;
			padding: 2rem;
			position: relative;
		}
	}

	.calculated-bmi {
		font-weight: $font-weight-semi-bold;
	}

	.health-rating {
		> div {
			display: none;

			&.show {
				display: block;
			}
		}
	}

	.weight-loss {
		display: none;

		&.show {
			display: block;
		}
	}

	.results-close-btn {
		@include text(epsilon, eta);
		background: none;
		border: none;
		color: $color-primary-light-blue;
		font-weight: $font-weight-extra-bold;
		right: 1rem;
		padding: 0;
		position: absolute;
		top: 1rem;

		.icon {
			fill: $color-primary-light-blue;
		}

		&:hover,
		&:focus {
			outline: 0;

			.icon {
				fill: $color-secondary-light-blue;
			}
		}
	}

	@include media-breakpoint-up(md) {
		background-color: transparent;
		padding-bottom: 0;
		padding-top: 0;

		.bmi-calculator-inner {
			background-color: $color-neutral-lightest;
			padding: 2rem;
		}

		.conditions-wrapper {
			// Holds space for row with two items
			&:after {
				content: ' ';
				flex: 0 0 calc(100% / 3 - 1rem);
				max-width: calc(100% / 3 - 1rem);
			}

			.checkbox-wrapper {
				flex: 0 0 calc(100% / 3 - 1rem);
				max-width: calc(100% / 3 - 1rem);

				.two-column & {
					flex: 0 0 calc(50% - 1rem);
					max-width: calc(50% - 1rem);
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.conditions-wrapper {
			// Holds space for row with two items
			&:before {
				content: ' ';
				flex: 0 0 calc(25% - 1rem);
				max-width: calc(25% - 1rem);
				order: 1;
			}

			&:after {
				flex: 0 0 calc(25% - 1rem);
				max-width: calc(25% - 1rem);
			}

			.two-column & {
				&:before {
					display: none;
				}

				&:after {
					flex: 0 0 calc(100% / 3 - 1rem);
					max-width: calc(100% / 3 - 1rem);
				}
			}

			.checkbox-wrapper {
				flex: 0 0 calc(25% - 1rem);
				max-width: calc(25% - 1rem);

				.two-column & {
					flex: 0 0 calc(100% / 3 - 1rem);
					max-width: calc(100% / 3 - 1rem);
				}
			}
		}
	}
}
