.advance {
	.advance--featured-article {
		padding-top: $padding-advance-section-mobile;
		padding-bottom: $padding-advance-section-mobile;
		.featured-article--info--container {
			display: flex;
			align-items: center;
		}
		.featured-article--info {
			padding-top: 2px;
			padding-bottom: 34px;
			.featured-article--contenttype {
				padding-bottom: 18px;
				color: $color-advance-yellow;
			}
			h1 {
				margin: 0 0 10px;
			}
			.featured-article--desc {
				padding-bottom: 18px;
				p {
					color: $color-advance-light-grey;
					font-weight: 400;
					line-height: initial;
				}
			}
			.featured-article--button {
				padding-top: 2px;
				.btn {
					margin-top: 5px;
				}
			}
		}

		.featured-article--image {
			border: 10px $color-advance-teal solid;
			padding: 15px 0 0 15px;
			margin: 0 -30px 20px -20px;
			position: relative;
			a {
				display: block;
				margin: 0 0 -32px;
				overflow: hidden;
				padding-top: 56.25%;
				position: relative;
			}
			img {
				display: block;
				height: 100%;
				left: 0;
				object-fit: cover;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.advance--featured-article {
			padding-top: $padding-advance-section-desktop;
			padding-bottom: $padding-advance-section-desktop;
			.featured-article--info {
				padding-top: 0;
				padding-bottom: 28px;
				.featured-article--contenttype {
					padding-bottom: 20px;
				}
				h1 {
					margin: 0 0 6px;
				}
				.featured-article--desc {
					max-width: 520px;
					padding-bottom: 2px;
					p {
						font-size: 16px;
						line-height: 26px;
						font-weight: 500;
					}
				}
			}
			.featured-article--image {
				margin: -40px 25px 14px -14px;
				border-width: 14px;
				padding: 21px 0 0 23px;
				a {
					margin: 0 -39px -49px 0;
				}
			}
		}
	}
}
