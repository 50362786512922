.event-detail {
	margin-bottom: 2rem;

	.event-card {
		border-width: 0.5rem;
		border-radius: 5px;
    	background-color: $color-neutral-lighter;

		a {
			font-weight: $font-weight-semi-bold;
		}
	}

	.card-header,
	.card-body,
	.card-footer {
		background-color: transparent;
		border: 0;
	}

	.card-header {
		height: 175px;
		padding: 0;
		position: relative;
	}

	.card-body {
		.body-half {
			margin-bottom: 1.375rem;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.mapboxgl-popup {
		display: none;
	}

	.event-location-map {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		.mapbox-map {
			height: 100%;
			overflow: hidden;
			position: relative;
			width: 100%;
		}

		.mapboxgl-canvas-container {
			height: 100%;
			overflow: hidden;
			position: absolute;
			width: 100%;
		}

		.mapboxgl-marker {
			display: flex;
			justify-content: center;
			width: 32px;
		}
	}

	.event-info {
		@include text(zeta, epsilon);
		margin-bottom: 1.375rem;

		p {
			@include text(zeta, epsilon);
		}

		.btn {
			padding: 0.75rem 1.5rem;

			&.disabled {
				background-color: $color-gray-light;
				border-color: $color-gray-light;
				color: $color-gray-darkest;
				opacity: 1;
				width: auto;
			}
		}

		.location-name {
			color: $color-primary-dark-blue;
		}

		.distance-and-directions {
			display: none;
		}

		.phone-number {
			color: $color-secondary-light-blue;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.event-heading {
		@include text(epsilon);
		color: $color-primary-dark-blue;
	}

	.date,
	.time {
		color: $color-gray-darkest;
	}

	.location-address {
		margin-bottom: 1rem;
	}

	.distance-and-directions {
		color: $color-secondary-light-blue;
		margin-bottom: 2rem;
		display: flex;
		flex-flow: row;
		justify-content: space-around;
		align-items: center;

		a {
			display: inline-block;
		}

		.distance {
			color: $color-secondary-light-blue;
			font-weight: $font-weight-bold;
			padding-right: 0.5rem;
		}
	}

	@include media-breakpoint-up(md) {
		@include make-container();
		@include make-container-max-width();

		.event-card {
			border-width: 0.75rem;
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-start;
			min-height: 325px;
		}

		.card-header {
			flex-basis: 45%;
			height: auto;
			max-width: 45%;
		}

		.card-body {
			display: flex;
			flex-basis: 55%;
			max-width: 55%;
			padding: 2.5rem 2.25rem;

			.body-half {
				flex: 1 1 0;
				margin: 0 1rem;

				&:first-of-type {
					margin-left: 0;
				}

				&:last-of-type {
					margin-bottom: 0;
					margin-right: 0;
				}
			}
		}

		.distance-and-directions {
			display: none;
		}

		.mapboxgl-popup {
			display: block;
			top: 50px;

			.mapboxgl-popup-tip {
				border: none;
			}
		}

		.location-popup {
			padding: 2rem 2.5rem;

			.distance-and-directions {
				display: block;
			}

			.location-type {
				@include text(eta);
			}

			.event-heading {
				@include text(epsilon);
				margin-bottom: 0;
			}

			.location-type-name {
				// border-bottom: 1px solid $color-neutral;
				padding-bottom: 0.75rem;
				margin-bottom: 0.75rem;
				text-align: center;
			}

			.location-distance-parking {
				@include text(zeta);
				display: flex;
				justify-content: center;

				a {
					color: $color-secondary-light-blue;

					&:focus-visible {
						outline: none;
					}
				}

				> :not(:last-child) {
					margin-right: 1.5rem;
					position: relative;

					&:after {
						background-color: $color-neutral-dark;
						content: '';
						height: 80%;
						position: absolute;
						right: -0.75rem;
						top: 50%;
						transform: translateY(-50%);
						width: 1px;
					}
				}
			}
		}

		.event-location-map {
			.mapboxgl-marker {
				&:hover,
				&.active {
					svg {
						cursor: pointer;
						fill: $color-secondary-light-blue;
					}
				}
			}
		}

		.event-info {
			@include text(epsilon, delta);
			margin-bottom: 1.5rem;

			p {
				@include text(epsilon, delta);
			}

			.distance-and-directions {
				display: block;
				margin-bottom: 0;
			}
		}

		.event-heading {
			@include text(delta);
		}
	}
}
