.microsite-theme {
	.thank-you-message {
		background-color: transparent;

		.thank-you-bubble {
			background-color: $color-precision-gray;
			border-color: $color-precision-gray;
			color: $color-white;

			&:before {
				border-top-color: $color-precision-gray;
			}

			&:after {
				border-top-color: $color-precision-gray;
			}
		}

		.thank-you-content {
			color: $color-microsite-gray;

			.heading-3,
			.heading-4,
			.heading-5,
			h3,
			h4,
			h5 {
				color: $color-microsite-gray;
			}

			p {
				a {
					color: $color-primary-blue;
				}
			}
		}
	}
}
