.advance {
	.advance--directory-grid {
		padding-top: $padding-advance-section-mobile;
		padding-bottom: $padding-advance-section-mobile;
		margin-top: -4px;
		.row {
			row-gap: 0px;
			padding-bottom: 20px;

			.col-12 {
				margin-bottom: 1rem;
			}

			.card {
				height: 100%;
				padding: 39px 40px 18px;
				position: relative;
				border-width: 10px;
				margin: 0;
				.row {
					gap: 19px;
					padding-top: 20px;
				}
				a {
					display: block;
					padding-bottom: 15px;
					position: relative;
					font-weight: 600;
					font-size: 16px;
					&::before {
						content: '';
						background: $color-advance-light-teal;
						height: 4px;
						width: 39px;
						position: absolute;
						bottom: 0;
						left: 0;
					}
					&:hover {
						color: $color-advance-dark-teal;
						text-decoration: none;
					}
				}
				&.view-all {
					padding: 30px;
					border-bottom-width: 10px;
					height: 100px;
					a {
						font-size: 20px;
						font-weight: 700;
						display: flex;
						justify-content: center;
						align-items: center;
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						padding: 0;
						color: $color-advance-dark-teal;
						&::before {
							display: none;
						}
					}
					&:hover {
						border-color: $color-advance-light-teal;
						a {
							color: $color-advance-dark-teal;
							background: $color-advance-off-white;
						}
					}
				}
			}
		}
	}
	@include media-breakpoint-up(md) {
		.advance--directory-grid {
			padding-top: $padding-advance-section-desktop;
			padding-bottom: $padding-advance-section-desktop;
			margin-top: 0px;
			.container > .row {
				row-gap: 30px;
				column-gap: 20px;
				margin: 0;
				& > div {
					padding: 0;
					flex: 0 0 calc(50% - 13.33px);
					max-width: calc(50% - 13.33px);
				}
				.card {
					border-width: 14px;
					padding: 37px 36px 28px;
					margin: 0;
					.row {
						padding-top: 25px;
					}
					&.view-all {
						height: 100%;
						a {
							font-size: 24px;
						}
					}
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.advance--directory-grid {
			.container > .row {
				row-gap: 38px;
				column-gap: 20px;
				& > div {
					padding: 0;
					flex: 0 0 calc(33.33% - 13.33px);
					max-width: calc(33.33% - 13.33px);
				}
			}
		}
	}
}
