.advance {
	.advance--article-card {
		padding-left: 20px;
		padding-right: 20px;
		margin: 0 -20px 50px;
		position: relative;
		&::after {
			content: "";
			position: absolute;
			background: $color-advance-light-grey;
			top: 0;
			bottom: 0;
			left: 0;
			width: 6px;
		}
		&::before {
			content: "";
			position: absolute;
			background: $color-advance-light-grey;
			top: 100%;
			left: 15px;
			right: 15px;
			height: 1px;
			margin-top: 9px;
		}

		.advance--article-card--content {
			// min-height: 410px;
			.advance--article-card--image {
				width: 100%;
				position: relative;
				img {
					max-height: 46.9vw;
					width: 100%;
					display: block;
					object-fit: cover;
					flex: 0 0 auto;
				}
			}
			.advance--article-card--info {
				padding: 14px 0 20px 0;
				.advance--article-card--date {
					padding-bottom: 18px;
					color: $color-advance-mid-bluegrey;
				}
				.advance--article-card--title {
					padding-bottom: 2px;
				}
				.advance--article-card--desc {
					p {
						margin: 0 0 6px;
					}
				}
				.advance--article-card--btn {
					padding-top: 10px;
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		.advance--article-card {
			padding-left: 29px;
			padding-right: 0;
			margin: 0 0 2px;
			min-height: 412px;
			&::after {
				width: 6px;
			}
			&::before {
				margin-top: 20px;
				left: 0;
				right: 0;
			}
			.advance--article-card--content {
				.advance--article-card--image {
					img {
						max-height: 14.1vw;
					}
				}
				.advance--article-card--info {
					padding: 20px 0 25px 0;
					.advance--article-card--title {
						padding-bottom: 1px;
						* {
							font-size: 18px;
							line-height: 28px;
						}
					}
					.advance--article-card--btn {
						padding-top: 13px;
					}
				}
			}
		}
	}
}
