.hero-secondary {
	@include make-container();
	@include make-container-max-width();
	color: $color-primary-blue;
	margin-bottom: 1rem;

	&.has-border .hero-border {
		border: 0.5rem solid $color-neutral-light;
		padding: 1.185rem 1rem;
	}

	&.has-picture .hero-border {
		border: none;
		padding: 0;
	}

	&:not(.has-picture) .hero-content {
		flex: 0 0 100%;
		max-width: 100%;
		padding: 0;
	}

	&:not(.has-border):not(.has-picture) {
		.hero-content-container {
			padding-left: 0;
			padding-right: 0;
			position: relative;
		}

		.hero-content {
			text-align: left;
			margin-top: 1rem;
		}

		.hero-title {
			display: none;
		}

		.btn {
			align-self: flex-start;
		}
	}

	&.has-picture .hero-img {
		display: block;
		background-color: $color-gray-darkest;;
	}

	&.has-logo {
		&.logo-wide {
			.specialty-logo {
				width: 150px;
			}
		}

		.specialty-logo {
			display: block;
			width: 100px;
			margin: 0 auto 1rem;

			&.has-border {
				padding-bottom: 0.5rem;
				border-bottom: 0.25rem solid $color-illumination-yellow;
			}

			.logo {
				width: 100%;
			}

			.logo-white {
				display: none;
			}
		}

		&:not(.has-picture),
		&:not(.has-border):not(.has-picture) {
			.hero-title {
				display: none;
			}

			.hero-description {
				text-align: left;
				width: calc(100% - 100px);
			}
		}

		&.dark-bg {
			&.has-logo {
				.specialty-logo {
					.logo-full-color {
						display: none;
					}

					.logo-white {
						display: block;
					}
				}
			}

			.hero-title {
				color: $color-neutral-lighter;
			}

			.hero-description {
				color: $color-neutral-lightest;
			}
		}
	}

	.hero-outer {
		background-color: $color-gray-darkest;
		border-radius: 0;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
		position: relative;
	}

	.hero-img {
		display: none;
		position: relative;
	}

	.hero-img img,
	.hero-img source {
		object-fit: cover;
		object-position: 50% 50%;
		font-family: 'object-fit: cover; object-position: 50% 50%;';
		width: 100%;
		border-radius: 0;
		height: 25rem;
		clip-path: circle(700px at 50% -19rem);
	}

	.hero-img {
		&.no-clip {

			img, source {
				clip-path: none;
			}
		}
	}

	.hero-content-container {
		@include make-container();
		@include make-container-max-width();
		position: relative;
		background-color: $color-gray-darkest;
	}

	.hero-content {
		flex: auto;
		max-width: 100%;
		display: block;
		padding: 1rem 0 1rem;
		text-align: center;
	}

	.hero-title {
		@include text(beta);
		font-weight: $font-weight-bold;
		color: $color-white;
		display: block;
		margin-bottom: 0.5rem;
		margin-top: 0;

		&.has-border {
			padding-bottom: 0.5rem;
			border-bottom: 0.25rem solid $color-illumination-yellow;
		}

		&:nth-last-child(2) {
			margin-bottom: 1rem;
		}

		&.title-light {
			font-weight: $font-weight-light;
			margin-bottom: 0.5rem;
		}
	}

	.hero-description {
		@include text(zeta);
		color: $color-white;
		padding-top: 0.5rem;
		margin-bottom: 2rem;
	}

	.button-group {
		display: flex;
		flex: 1 0 100%;
		flex-flow: column;
	}

	.btn {
		display: block;

		&:not(:only-of-type) {
			margin: 0 0.25rem 0.625rem;
		}

		&:hover,
		&:focus {
			color: $color-primary-yellow;
			background-color: $color-gray-darkest;
			text-decoration: none;
		}
	}

	@include media-breakpoint-up(sm) {
		.hero-img img,
		.hero-img source {
			height: 30rem;
			clip-path: circle(800px at 50% -21rem);
		}

		.hero-content-container {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		&.has-logo {
			&.logo-wide {
				.specialty-logo {
					width: 170px;
				}
			}

			.specialty-logo {
				width: 120px;
			}
		}

		.button-group {
			flex: 1 0 50%;
			flex-flow: row;
			justify-content: center;
		}

		.btn {
			display: inline-block;

			&:not(:only-of-type) {
				margin: 0 0.25rem 0.625rem;
				width: 50%;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.hero-img img,
		.hero-img source {
			height: auto;
			clip-path: none;
		}

		.hero-content {
			z-index: 1;
		}

		&:not(.has-picture) {
			.hero-content-container {
				position: relative;
			}

			.hero-content {
				flex: 0 0 100%;
				max-width: 100%;
				padding: 0;
			}
		}

		&:not(.has-border):not(.has-picture) {
			.hero-content-container {
				padding-left: 0;
				padding-right: 0;
			}

			.hero-content {
				text-align: left;
				margin-top: 1rem;
			}

			.hero-title {
				display: none;
			}

			.btn {
				align-self: flex-start;
			}
		}

		.hero-content {
			padding: 0;
		}

		.button-group {
			flex: 1 0 100%;
			flex-flow: wrap;
			justify-content: flex-start;
		}

		.btn {
			@include text(zeta);
			padding: 0.5rem 0.75rem;

			&:not(:only-of-type) {
				margin-left: 0;
				margin-right: 0.5rem;
				width: auto;
			}
		}

		&.has-logo {
			&.logo-wide {
				.specialty-logo {
					width: 220px;
				}
			}

			.specialty-logo {
				display: block;
				float: right;
				margin-bottom: 1rem;
				width: 170px;
				position: relative;
				top: 0;
				right: 0;

				.logo {
					width: 100%;
				}
			}

			.hero-content-container {
				justify-content: flex-end;
			}

			.hero-title {
				clear: both;
			}

			&:not(.has-picture),
			&:not(.has-border):not(.has-picture) {
				.hero-title {
					display: none;
				}
				.hero-description {
					width: calc(100% - 200px);
				}
			}
		}

		.hero-content-container {
			display: flex;
			justify-content: flex-start;
			bottom: 0;
			height: 100%;
			padding-left: 1rem;
			padding-right: 1rem;
			margin-bottom: 0;
			position: absolute;
			clip-path: circle(66% at center left);

			&.center {
				justify-content: center;
				text-align: center;

				.hero-content {
					text-align: center;
				}

				.hero-description {
					text-align: center;
				}

				.btn {
					align-self: center;
				}
			}

			&.right {
				justify-content: flex-end;
				text-align: right;
				clip-path: circle(66% at center right);

				.hero-content {
					text-align: right;
				}

				.hero-description {
					text-align: right;
				}

				.button-group {
					justify-content: flex-end;
				}

				.btn {
					align-self: flex-end;

					&:not(:only-of-type) {
						margin-right: 0;
						margin-left: 0.5rem;
					}
				}
			}
		}

		.hero-content {
			flex: 0 0 48%;
			max-width: 48%;
			flex-direction: column;
			align-self: center;
			text-align: left;
		}

		.hero-title {
			@include text(gamma);
			font-weight: 600;
			padding: 0;
			margin-bottom: 0.75rem;

			&.title-light {
				margin-bottom: 0.5rem;
			}
		}

		.hero-description {
			@include text(delta);
		}

		.btn {
			align-self: flex-start;
		}

		.two-column & {
			&.has-border .hero-border {
				border: 0.5rem solid $color-neutral-light;
				padding: 1.185rem 1rem;
			}

			&.has-picture .hero-border {
				border: none;
				padding: 0;
			}

			&:not(.has-picture) .hero-content {
				flex: 0 0 100%;
				max-width: 100%;
				padding: 0;
			}

			&:not(.has-border):not(.has-picture) {
				.hero-content-container {
					padding-left: 0;
					padding-right: 0;
					position: relative;
				}

				.hero-content {
					text-align: left;
					margin-top: 1rem;
				}

				.hero-title {
					display: none;
				}

				.btn {
					align-self: flex-start;
				}
			}

			&.has-picture .hero-img {
				display: block;
			}

			&.has-logo {
				&.dark-bg {
					.specialty-logo {
						.logo-full-color {
							display: block;
						}

						.logo-white {
							display: none;
						}
					}
				}

				&.logo-wide {
					.specialty-logo {
						width: 170px;
					}
				}

				.specialty-logo {
					float: none;
					width: 120px;
					margin: 0 auto 1rem;
				}

				&:not(.has-picture),
				&:not(.has-border):not(.has-picture) {
					.hero-title {
						display: none;
					}
					.hero-description {
						width: calc(100% - 100px);
					}
				}
			}

			.hero-outer {
				background-color: $color-gray-darkest;
				border-radius: 0;
				margin-bottom: 0;
				padding-top: 0;
				padding-bottom: 0;
				position: relative;
			}

			.hero-img {
				display: none;
				position: relative;
			}

			.hero-img img,
			.hero-img source {
				object-fit: cover;
				object-position: 50% 50%;
				font-family: 'object-fit: cover; object-position: 50% 50%;';
				width: 100%;
				height: 25rem;
				clip-path: circle(700px at 50% -19rem);
			}

			.hero-content-container {
				@include make-container();
				@include make-container-max-width();
				position: relative;
				clip-path: none;

				&.center,
				&.right {
					justify-content: center;
					text-align: center;

					.hero-content {
						text-align: center;
					}

					.hero-description {
						text-align: center;
					}

					.button-group {
						justify-content: center;
					}

					.btn {
						align-self: center;
					}
				}
			}

			.hero-content {
				flex: auto;
				max-width: 100%;
				display: block;
				padding: 1rem 0 1rem;
				text-align: center;
			}

			.hero-title {
				@include text(gamma);
				color: $color-white;
				margin-bottom: 0.75rem;
				margin-top: 0;

				&:nth-last-child(2) {
					margin-bottom: 1rem;
				}

				&.title-light {
					font-weight: $font-weight-light;
					margin-bottom: 0.5rem;
				}
			}

			.button-group {
				flex: 1 0 50%;
				flex-flow: row;
				justify-content: center;
			}

			.btn {
				display: inline-block;

				&:not(:only-of-type) {
					margin: 0 0.25rem 0.625rem;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		&.dark-bg {
			.hero-title {
				color: $color-neutral-lighter;
			}
		}

		&:not(.has-picture) {
			.hero-content-container {
				position: relative;
			}

			.hero-content {
				flex: 0 0 100%;
				max-width: 100%;
				padding: 0;
			}
		}

		&:not(.has-border):not(.has-picture) {
			.hero-content-container {
				padding-left: 0;
				padding-right: 0;
			}

			.hero-content {
				text-align: left;
				margin-top: 1rem;
			}

			.hero-title {
				display: none;
			}

			.btn {
				align-self: flex-start;
			}
		}

		.two-column & {
			&.dark-bg {
				&.has-logo {
					.specialty-logo {
						.logo-full-color {
							display: none;
						}

						.logo-white {
							display: block;
						}
					}
				}

				.hero-description {
					color: $color-neutral-lightest;
				}
			}

			&.has-logo {
				&.logo-wide {
					.specialty-logo {
						width: 200px;
					}
				}

				.specialty-logo {
					display: block;
					float: right;
					margin-bottom: 1rem;
					width: 150px;
					position: relative;
					top: 0;
					right: 0;

					.logo {
						width: 100%;
					}
				}

				.hero-content-container {
					justify-content: flex-end;
				}

				.hero-title {
					clear: both;
				}

				&:not(.has-picture),
				&:not(.has-border):not(.has-picture) {
					.hero-title {
						display: none;
					}
					.hero-description {
						width: calc(100% - 200px);
					}
				}
			}

			.hero-img img,
			.hero-img source {
				height: auto;
				clip-path: none;
			}

			.hero-content {
				flex: 0 0 50%;
				max-width: 50%;
			}

			.hero-title {
				@include text(beta);
				padding: 0;

				&.has-border {
					border-bottom: 0.25rem solid #ffcd55;
				}
			}

			.hero-description {
				@include text(epsilon);
				margin-bottom: 1rem;
				display: block;
			}

			.button-group {
				justify-content: flex-start;
			}

			.btn {
				@include text(zeta);
				padding: 0.4375rem 0.5rem;

				&:not(:only-of-type) {
					margin-left: 0;
					margin-right: 0.5rem;
				}
			}

			.hero-content-container {
				display: flex;
				justify-content: flex-start;
				background-position: -35vw 50%;
				padding-left: 1rem;
				padding-right: 1rem;
				position: absolute;
				clip-path: circle(66% at center left);

				&.center {
					justify-content: center;
					text-align: center;

					.hero-content {
						text-align: center;
					}

					.hero-description {
						text-align: center;
					}

					.btn {
						align-self: center;
					}
				}

				&.right {
					justify-content: flex-end;
					text-align: right;
					clip-path: circle(66% at center right);

					.hero-content {
						text-align: right;
					}

					.hero-description {
						text-align: right;
					}

					.button-group {
						justify-content: flex-end;
					}

					.btn {
						align-self: flex-end;

						&:not(:only-of-type) {
							margin-right: 0;
							margin-left: 0.5rem;
						}
					}
				}
			}

			.hero-content {
				flex: 0 0 50%;
				max-width: 50%;
				flex-direction: column;
				align-self: center;
				text-align: left;
				padding: 0 1rem;
			}
		}

		.hero-content-container {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		.hero-content {
			padding: 0;
		}

		.btn {
			&:not(:only-of-type) {
				margin-left: 0;
				margin-right: 0.5rem;
			}
		}

		&.has-logo {
			&.logo-wide {
				.specialty-logo {
					width: 235px;
				}
			}

			.specialty-logo {
				display: block;
				float: right;
				margin-bottom: 1rem;
				width: 185px;
				position: relative;
				top: 0;
				right: 0;

				.logo {
					width: 100%;
				}
			}

			.hero-content-container {
				justify-content: flex-end;
			}

			.hero-title {
				clear: both;
			}

			&:not(.has-picture),
			&:not(.has-border):not(.has-picture) {
				.hero-title {
					display: none;
				}
				.hero-description {
					width: calc(100% - 200px);
				}
			}
		}

		.hero-content-container {
			justify-content: flex-start;
			padding-left: 1.5rem;
			padding-right: 1.5rem;
			clip-path: circle(63% at center left);

			&.center {
				justify-content: center;
				text-align: center;

				.hero-content {
					text-align: center;
				}

				.hero-description {
					text-align: center;
				}

				.btn {
					align-self: center;
				}
			}

			&.right {
				justify-content: flex-end;
				clip-path: circle(63% at center right);

				.hero-content {
					text-align: right;
				}

				.hero-description {
					text-align: right;
				}

				.btn {
					align-self: flex-end;

					&:not(:only-of-type) {
						margin-right: 0;
						margin-left: 0.5rem;
					}
				}
			}
		}

		.hero-content {
			flex: 0 0 45%;
			max-width: 45%;
			flex-direction: column;
			align-self: center;
			text-align: left;
		}

		.hero-title {
			@include text(alpha);
			padding: 0;
		}

		.btn {
			align-self: flex-start;
		}
	}

	@include media-breakpoint-up(xl) {
		&.has-logo {
			&.logo-wide {
				.specialty-logo {
					width: 250px;
				}
			}

			.specialty-logo {
				width: 200px;
			}
		}

		.two-column & {
			.hero-content-container {
				clip-path: circle(63% at center left);

				&.right {
					clip-path: circle(63% at center right);
				}
			}

			.hero-content {
				flex: 0 0 45%;
				max-width: 45%;
			}
			.hero-title {
				@include text(alpha);
			}
		}

		.hero-content-container {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}

		.hero-title {
			@include text(ix);
			padding: 0;
		}

		.hero-content {
			padding: 0;
		}

		.btn {
			@include text(zeta);
			padding: 0.4375rem 3rem;
		}
	}
}
