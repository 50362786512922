.profile-meet-the-provider {
	@include make-container();
	@include make-container-max-width();

	.section-title {
		&:after {
			background-color: $color-primary-yellow;
		}
	}

	.read-more {
		.read-more-body {
			max-height: 350px;
			border-radius: 5px;
		}
	}

	.button-container {
		margin-bottom: 4rem;
		margin-top: -2.5rem;
		background-color: #f5f5f6;
		height: 5rem;
		border-radius: 5px;

		.read-more-link {
			position: relative;

			&.is-expanded {
				font-size: 1rem;
				line-height: 1.125;
				display: inline-block;
				font-weight: 700;
			}
		}
	}

	.card-subtitle {
		margin-bottom: 0.5rem;
	}

	.section-subtitle {
		color: $color-gray-darkest;
	}

	.provider-snippets {
		@include make-row();
		background-color: $color-neutral-lighter;
		padding: 3rem;
	}

	.provider-snippet {
		margin-bottom: 3rem;
		padding-right: 3rem;

		&:last-child {
			margin-bottom: 0;
		}

		p {
			@include text(epsilon);
		}

		li {
			@include text(epsilon, delta);
		}

		.read-more {
			margin-bottom: 2rem;
		}
	}

	@include media-breakpoint-up(sm) {
		.provider-snippets {
			@include make-row();
			background-color: $color-neutral-lighter;
			padding: 3rem;
		}

		.provider-snippet {
			@include make-col-ready();
			@include make-col(6);
			margin-bottom: 2rem;

			.read-more {
				margin-bottom: 3rem;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.read-more {
			.read-more-body {
				max-height: none;
			}

			.button-container {
				display: none;
			}
		}

		.provider-snippet {
			@include make-col(4);
		}

		.toggle-expand {
			display: none;
		}
	}
}
