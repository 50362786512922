.microsite-theme {
	.page-header {
		.breadcrumb {
			padding-top: 0;
			margin-bottom: 1rem;
		}

		.breadcrumb-item {
			a {
				@include text(eta, zeta);
				color: $color-black;
				font-weight: $font-weight-normal;

				> .icon {
					fill: $color-black;
					width: 1.25rem;
				}
			}
		}

		.heading-1 {
			font-size: 50px;
			font-weight: $font-weight-normal;
			color: $color-black;
		}

		.language-dropdown,
		.divider {
			display: none;
		}

		@include media-breakpoint-up(md) {
			.page-header-inner {
				padding-top: 1.25rem;
			}

			.breadcrumb-item {
				+ .breadcrumb-item {
					padding-left: 0.25rem;

					&::before {
						@include text(eta, zeta);
						content: '>';
						color: $color-black;
						font-weight: $font-weight-normal;
						padding-right: 0.25rem;
					}
				}
			}

			.social {
				align-items: center;
				display: flex;
				position: absolute;
				right: 0;
				bottom: 0.625rem;

				&:before {
					@include text(eta, zeta);
					color: $color-black;
					content: 'Share';
					display: block;
					font-weight: $font-weight-semi-bold;
					margin-right: 1.25rem;
				}

				.social-share {
					.icon {
						fill: $color-black;
						height: 30px;
						stroke: $color-black;
						width: 30px;
					}

					a,
					button {
						+ a,
						+ button {
							margin-left: 1rem;
						}
					}
				}
			}

			.heading-1 {
				padding-right: 250px;
			}
		}
	}
}
