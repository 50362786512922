.related-quick-links {
	float: none;

	.quick-links-inner {
		background-color: $color-neutral-lightest;
		flex-basis: auto;
		margin-bottom: 2rem;
		max-width: 100%;
		padding: 1.25rem;
		border-radius: 5px;
	}

	.quick-links-content {
		margin-bottom: 0;
		padding: 0;
	}

	.section-title {
		margin-bottom: 1.25rem;
		padding-bottom: 0;
		text-align: center;
		&::after {
			display: none;
		}
	}

	.offering-list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		@include text(zeta);
		background-color: $color-gray-darkest;
		display: inline-block;
		font-weight: $font-weight-normal;
		margin-bottom: 1rem;
		padding: 0.575rem 0.25rem;
		text-align: center;
		width: 100%;
		border-radius: 5px;
		a {
			color: $color-white;
		}
	}

	@include media-breakpoint-up(sm) {
		width: 50%;
		float: right;

		.quick-links-inner {
			margin-left: 2rem;
		}
	}

	@include media-breakpoint-up(md) {
		width: 33.333333%;
		float: right;

		.two-column & {
			width: 50%;
		}

		.section-title {
			padding-bottom: 0;
			&::after {
				display: none;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		width: 25%;
		float: right;

		.two-column & {
			width: 33.333333%;
		}
	}

	@include media-breakpoint-up(xl) {
		width: 20%;
		float: right;

		.two-column & {
			width: 30%;
		}
	}
}
