.related-nurse-practitioners {
	@include make-container();
	@include make-container-max-width();
	margin-bottom: 2rem;

	.related-areas-inner {
		background-color: $color-primary-dark-blue;
		margin-bottom: 1.25rem;
		padding: 2.5rem 2.5rem 2.5rem 3.125rem;
	}

	.title {
		@include text(epsilon);
		font-weight: $font-weight-bold;
		color: $color-primary-yellow;
		margin-bottom: 2rem;
	}

	.description {
		color: $color-primary-yellow;
		margin-top: 0.825rem;
		padding-top: 1.5rem;
		position: relative;

		&:before {
			background-color: $color-primary-yellow;
			content: '';
			display: block;
			position: absolute;
			top: 0;
			height: 4px;
			width: 58px;
		}
	}

	.profile-image {
		height: 80px;
		min-width: 80px;
		width: 80px;
	}

	.profile-details {
		margin-top: 0.5rem;
		margin-bottom: 1.5rem;
	}

	.doctor-name {
		@include text(delta);
		color: $color-white;
		font-weight: $font-weight-bold;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	.specialty {
		@include text(zeta);
		color: $color-primary-yellow;
		font-weight: $font-weight-bold;
		margin-top: 0.25rem;
	}

	@include media-breakpoint-up(md) {
		.related-areas-inner {
			align-items: center;
			display: flex;
			justify-content: space-between;
		}

		.title {
			margin: 0;
			padding-right: 2rem;
		}

		.description {
			max-width: 256px;
		}

		.area-profiles {
			display: flex;
			justify-content: space-around;
		}

		.profile {
			align-items: center;
			display: flex;
			flex: 1 1 0;
			justify-content: center;
			margin-bottom: 0;
			margin-right: 1rem;
			width: auto;

			&:last-child {
				margin-right: 0;
			}
		}

		.profile-details {
			margin-top: 0;
			margin-bottom: 0;
			margin-left: 1.5rem;
		}
	}

	@include media-breakpoint-up(lg) {
		.area-profiles {
			flex-basis: 80%;
		}

		.profile {
			margin-right: 2rem;
		}
	}
}
