.microsite-theme {
	.footer {
		background-color: $color-microsite-dark-blue;

		p {
			color: $color-white;
		}

		.marketing p {
			@include text(gamma);
		}

		.btn {
			padding-left: 3.375rem;
			padding-right: 3.375rem;
			font-weight: $font-weight-semi-bold;
		}

		.link-lists,
		.link-lists-constant {
			ol {
				li {
					ol {
						li {
							a {
								color: $color-white;
							}
						}
					}
				}
			}
		}
	}
}
