.story-article {
	@include make-container();
	@include make-container-max-width();
	font-family: $moments-serif;
	padding: 3rem 0;

	h2,
	h3,
	h4,
	h5,
	h6 {
		@include text(delta, gamma);
		color: $color-moments-gray;
		margin-bottom: 1rem;
		margin-top: 2rem;
	}

	.story-container {
		padding: 0 15px;
	}

	.story-title {
		color: $color-black;
		font-family: $moments-serif;
		font-size: 50px;
		font-weight: $font-weight-normal;
		line-height: 1;
		margin-bottom: 1.5rem;
		margin-top: 0;
		padding-right: 2rem;
	}

	.story-details {
		@include text(delta);
		margin-bottom: 3rem;

		.author-name {
			display: block;
			text-transform: uppercase;
		}
	}

	.story-share {
		margin-bottom: 2rem;

		.share-label {
			@include text(zeta);
			color: $color-black;
			font-family: $moments-sans-serif;
			margin-bottom: 0.75rem;
		}

		.share-buttons {
			display: flex;

			button {
				background: transparent;
				border: none;
				margin-right: 1rem;
				padding: 0;

				.icon {
					fill: $color-black;
					height: 36px;
					transition: fill 0.15s ease-in-out;
					width: 36px;
				}

				&:hover,
				&:focus {
					.icon {
						fill: $color-microsite-yellow;
					}
				}
			}
		}
	}

	.story-content {
		@include text(delta, gamma);
		color: $color-moments-gray;

		p {
			@include text(delta, gamma);
		}
	}

	.story-quote {
		margin: 4rem 0;
		text-align: center;
		width: 100%;

		.quote {
			@include text(omega);
			color: $color-black;
			margin: 1rem 0;
		}

		.by {
			@include text(delta);
			color: $color-moments-gray;
			display: block;
			margin-top: 1rem;
		}

		img {
			max-height: 50vh;
			max-width: 100%;
		}

		.img-left {
			margin-top: -3rem;
		}

		.img-right {
			margin-bottom: -3rem;
		}
	}

	.story-gallery {
		margin: 4.5rem 0;
	}

	.gallery {
		padding: 0;
	}

	@include media-breakpoint-up(md) {
		.story-quote {
			img {
				max-height: 25vh;
			}

			.img-left {
				text-align: left;
			}

			.img-right {
				text-align: right;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		padding: 60px 0;

		.story-container {
			@include make-row();
			padding: 0;
			position: relative;
		}

		.story-title {
			@include make-col-ready();
			@include make-col(10);
			font-size: 60px;
			margin: 0 auto 2rem;
		}

		.story-details {
			@include make-col-ready();
			@include make-col(8);
			@include text(zeta, delta);
			display: flex;
			margin: 0 auto 2rem;

			.author-name {
				display: inline;
			}

			div {
				position: relative;

				&:not(:last-of-type) {
					margin-right: 0.75rem;
					padding-right: 0.75rem;

					&:after {
						bottom: 0;
						content: '|';
						display: block;
						position: absolute;
						right: 0;
						transform: translateX(50%);
					}
				}
			}
		}

		.story-share {
			@include make-col-ready();
			@include make-col(1);
			padding: 0;
			position: absolute;
			right: 0;
			text-align: left;
			top: 30px;

			.share-label {
				margin-bottom: 1.5rem;
			}

			.share-buttons {
				align-items: flex-start;
				flex-direction: column;

				button {
					margin-bottom: 1.25rem;
					margin-right: 0;
				}
			}
		}

		.story-content {
			@include make-col-ready();
			@include make-col(8);
			margin: auto;
		}

		.story-quote {
			margin: 1rem 0;
			padding: 7.5rem 15px 6.5rem;
			position: relative;

			.quote {
				@include make-col-ready();
				@include make-col(8);
				font-size: 60px;
				margin: auto;
			}

			.img-container {
				@include make-col-ready();
				@include make-col(2);
				padding: 0;
				z-index: -1;
			}

			img {
				max-height: none;
			}

			.img-left {
				bottom: 50%;
				left: 0;
				margin-top: 0;
				position: absolute;
			}

			.img-right {
				margin-bottom: 0;
				position: absolute;
				right: 0;
				top: 50%;
			}

			&.img-left-lower {
				.img-left {
					bottom: auto;
					top: 50%;
				}

				.img-right {
					bottom: 50%;
					top: auto;
				}
			}
		}

		.story-gallery {
			@include make-col-ready();
			@include make-col(8);
			margin: 5rem auto 4rem;
		}
	}

	@include media-breakpoint-up(xl) {
		.story-quote {
			padding: 7.5rem 0 6.5rem;
		}
	}
}
