.story-list {
	background-color: $color-moments-black;
	padding-bottom: 1.5rem;
	padding-top: 15px;

	&.expanded {
		.story-card {
			display: block;
		}

		.view-more-btn {
			display: none;
		}
	}

	.story-list-container {
		@include make-container();
		@include make-container-max-width();
	}

	.story-listings {
		margin: 0;
		padding: 0;
	}

	.story-card {
		display: none;
		margin: 0;
		margin-bottom: 1.25rem;

		&:before {
			display: none;
		}

		&:not(:last-of-type) {
			margin-bottom: 1.25rem;
		}
	}

	.view-more-btn {
		align-items: center;
		background-color: $color-microsite-yellow;
		border-color: $color-microsite-yellow;
		border-radius: 0;
		color: $color-white;
		display: flex;
		font-family: $moments-serif;
		font-size: 26px;
		font-weight: 400;
		height: 110px;
		justify-content: center;
		margin-bottom: 1.25rem;
		text-decoration: none;
		width: 100%;

		&:hover,
		&:focus {
			background-color: $color-black;
			border-color: $color-black;
		}
	}

	@include media-breakpoint-up(md) {
		.story-listings {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.story-card {
			flex: 0 1 calc(50% - 0.625rem);
			max-width: calc(50% - 0.625rem);
		}

		.view-more-btn {
			font-size: 30px;
		}
	}

	@include media-breakpoint-up(lg) {
		padding-top: 0;

		.story-listings {
			margin-top: -110px;

			&:after {
				content: ' ';
				flex: 0 1 calc(33.3333% - (40px / 3));
				max-width: calc(33.3333% - (40px / 3));
			}
		}

		.story-card {
			flex: 0 1 calc(33.3333% - (40px / 3));
			max-width: calc(33.3333% - (40px / 3));
		}
	}

	@include media-breakpoint-up(xl) {
		.story-listings {
			&:before {
				content: ' ';
				flex: 0 1 calc(25% - (60px / 4));
				max-width: calc(25% - (60px / 4));
				order: 1;
			}
			&:after {
				content: ' ';
				flex: 0 1 calc(25% - (60px / 4));
				max-width: calc(25% - (60px / 4));
			}
		}

		.story-card {
			flex: 0 1 calc(25% - (60px / 4));
			max-width: calc(25% - (60px / 4));
		}
	}
}
