.location-profile-detail {
	margin-bottom: 3rem;

	.contact-link {
		background-color: $color-illumination-yellow;
		color: $color-gray-darkest;
	}

	&.view-mode-map {
		.trigger-map {
			display: none;
		}

		.location-profile-images {
			opacity: 0;
			pointer-events: none;
		}

		.trigger-images {
			display: inline-block;
		}

		.location-profile-map {
			opacity: 1;
			pointer-events: auto;
		}
	}

	.page-header {

		.page-header-inner {
			padding-top: 10px;
    		padding-bottom: 0px;
    		margin-top: 0px;
		}

		.language-dropdown {
			position: absolute;
			right: 0;
			top: 20px;
		}
	}
	.trigger-images {
		display: none;
	}

	.location-profile-map {
		height: 100%;
		left: 0;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		width: 100%;

		.mapbox-map {
			height: 100%;
			overflow: hidden;
			position: relative;
			width: 100%;
		}

		.mapboxgl-canvas-container {
			height: 100%;
			overflow: hidden;
			position: absolute;
			width: 100%;
		}

		.mapboxgl-marker {
			display: flex;
			justify-content: center;
		}
	}

	.view-mode-select {
		background-color: $color-white;
		border-radius: 2rem;
		padding: 0.25rem 0.75rem;
		position: absolute;
		top: 1rem;
		right: 1rem;
		z-index: 1;
	}

	.view-mode-toggle {
		@include text(eta);
		color: $color-gray-darkest;
		font-weight: $font-weight-bold;

		.icon {
			fill: $color-gray-darkest;
			padding-bottom: 2px;
			padding-right: 3px;
		}

		&:focus,
		&:hover,
		&:active {
			color: $color-primary-dark-blue;
			outline: 0;
			text-decoration: none;

			.icon {
				fill: $color-primary-dark-blue;
			}
		}
	}

	.profile-detail-navigation {
		display: none;
	}

	.profile-detail-card {
		border-width: 0.5rem 0;
	}

	.card-header,
	.card-footer {
		background-color: transparent;
		border: 0;
	}

	.card-body {
		background-color: $color-neutral-lighter;
	}

	.card-header {
		height: 100%;
		padding: 0;
		position: relative;
	}

	.open-or-closed {
		@include text(eta);
		color: $color-neutral-dark;
		font-weight: $font-weight-bold;

		&:not(:only-child) {
			&:after {
				border-right: solid 1px $color-gray-darkest;
				content: '';
				display: inline-block;
				margin-bottom: -3px;
				margin-right: 0.25rem;
				padding-left: 0.75rem;
				height: 17px;
			}
		}

		&.now-open {
			color: $color-gray-darkest;
		}
	}

	.btn-link {
		background-color: transparent;
		border: 0;
	}

	.location-type {
		@include text(eta);
		color: $color-primary-dark-blue;
	}

	.profile-name {
		@include text(delta);
		color: $color-primary-dark-blue;
		display: inline-block;
		font-weight: $font-weight-bold;
		margin-bottom: 0.25rem;
	}

	.profile-specialties {
		margin-bottom: 1.5rem;
	}

	.specialty {
		@include text(eta);
		color: $color-neutral-darkest;
		display: block;
		margin: 0;

		+ .specialty {
			padding-top: 0.25rem;
		}
	}

	.distance-and-directions,
	.location-address,
	.phone-number {
		@include text(zeta);
		padding-bottom: 0.25rem;
	}

	.distance-and-directions,
	.phone-number a,
	.email a,
	.in-facility a {
		color: $color-secondary-light-blue;
	}

	.distance {
		font-weight: $font-weight-bold;

		&:after {
			content: "|";
			display: inline-block;
			transform: translate(50%);
			padding-left: .3rem;
			color: $color-gray-darkest;
		}

		+ a {
			padding-left: .5rem;
		}
	}

	.phone-number a {
		padding-right: .3rem;

		+ a {
			padding-left: .3rem;
		}
	}

	.phone-number,
	.email {
		@include text(eta);
		font-weight: $font-weight-semi-bold;
	}

	.hours-list {
		font-weight: $font-weight-semi-bold;
		color: $color-gray-darkest;
		display: inline-block;
		list-style: none;
		margin: 0.75rem 0;
		padding: 0.625rem 0;
		width: 255px;

		li {
			display: flex;
			padding: 0.125rem 0;
			position: relative;

			span {
				@include text(zeta);
				text-align: center;
				width: 65%;

				&:first-child {
					text-align: left;
					width: 19%;
				}

				&:last-child {
					text-align: right;
					width: 16%;

					 &.open-24-hours {
						color: $color-secondary-dark-teal;
						text-align: left;
						width: 100%;
					}
				}
			}
		}

		.icon-24-hours {
			height: 1.75rem;
			width: 2rem;
			stroke: $color-secondary-dark-teal;
			stroke-linecap: round;
			stroke-width: 1rem;
			fill: $color-secondary-dark-teal;
		}

	}

	.in-facility {
		@include text(eta);
		display: block;

		a {
			color: $color-primary-dark-blue;
			font-weight: $font-weight-semi-bold;
		}
	}

	.contact-link {
		@include text(zeta);
		display: block;
		font-weight: $font-weight-bold;
		margin: 1rem 0 0;
		max-width: 255px;
		padding: 0.375rem 2rem;
		text-align: center;
	}

	.affiliation-logo {
		max-width: 100px;
		position: absolute;
		bottom: 1rem;
		right: 0.5rem;
	}

	.section-title {
		&:after {
			background-color: $color-primary-yellow;
		}
	}

	@include media-breakpoint-up(md) {
		@include make-container();
		@include make-container-max-width();
		padding-top: 1.75rem;

		.breadcrumb {
			display: none;
		}

		.profile-detail-navigation {
			align-items: center;
			display: flex;
			justify-content: space-between;
			margin-bottom: 1.375rem;
		}

		.page-header {
			padding: 0px 0px;

			.page-header-inner {
				padding: 10px 0px;
				margin-top: 0px;
			}

			.language-dropdown {
				top: -6px !important;
			}
		}

		.back-to-results {
			@include text(zeta);
			display: inline-block;
			font-weight: $font-weight-semi-bold;

			svg {
				fill: $color-secondary-light-blue;
				margin-right: 0.75rem;
			}
		}

		.profile-detail-card {
			border-width: 0.75rem;
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-start;
		}

		.card-header {
			display: flex;
			height: auto;
			max-width: 650px;
			width: 100%;
		}

		.location-profile-images {
			display: flex;
			overflow: hidden;

			.img-fluid {
				font-family: 'object-fit: cover; object-position: center;';
				height: 100%;
				object-fit: cover;
				object-position: center;
				width: 100%;
			}
		}

		.open-or-closed {
			@include text(epsilon);

			&:after {
				border-right-width: 2px;
				margin-right: 0.5rem;
				padding-left: 1rem;
			}
		}

		.view-mode-toggle {
			@include text(epsilon);
		}

		.card-body {
			padding-bottom: 4.5rem;
			position: relative;
			border-radius:5px 0 0 5px;
		}

		.location-type,
		.specialty {
			@include text(zeta);
		}

		.hours-list {
			display: inline-block;
		}

		.in-facility {
			margin-top: 1rem;
		}

		.affiliation-logo {
			bottom: 2rem;
			right: 1.5rem;
		}
	}

	@include media-breakpoint-up(lg) {
		.card-header {
			max-width: 740px;
		}

		.page-header {
			padding: 0px 0px;

			.page-header-inner {
				padding: 10px 0px;
				margin-top: 0px;
			}

			.language-dropdown {
				top: -6px !important;
			}
		}

		.breadcrumb {
			display: none;
		}

		.hours-list {
			display: inline-block;
		}
	}

	@include media-breakpoint-up(xl) {
		.distance-and-directions,
		.location-address,
		.phone-number,
		.email,
		.hours-list li,
		.in-facility {
			@include text(epsilon);
		}

		.page-header {
			padding: 0px 0px;

			.page-header-inner {
				padding: 10px 0px;
				margin-top: 0px;
			}

			.language-dropdown {
				top: -6px !important;
			}
		}

		.breadcrumb {
			display: none;
		}

		.card-body {
			padding-bottom: 1.5rem;
		}
	}
}
