.healthplan-modal {
	&.fade {
		.modal-dialog {
			transform: translateY(-15px);
		}
	}

	&.show {
		.modal-dialog {
			transform: none;
		}
	}

	.modal-scroll-container {
		max-height: 100vh;
		overflow: auto;
		padding: 15px 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
	}

	.modal-container {
		align-items: flex-start;
		display: flex;
	}

	.modal-dialog {
		@include make-container();
		@include make-container-max-width();
		align-items: flex-start;
		display: flex;
		margin-top: 0;
		margin-bottom: 0;
	}

	.modal-content {
		box-shadow: 0 3px 6px rgba($color-black, 0.16);
		padding: 0;
		border-radius: 10px;
		border: none;
		margin: auto;
	}

	.modal-body {
		padding: 2rem 1.5rem 1rem;

		&:only-child {
			padding: 3rem 1.5rem;
		}
	}

	.modal-footer {
		background-color: rgba($color-healthplan-blue, 0.06);
		border: none;
		display: block;
		padding: 1.5rem;
	}

	.heading-2 {
		@include text(alpha);
		color: $color-healthplan-blue;
		font-weight: $font-weight-bold;
		margin-bottom: 3rem;

		+ p {
			margin-top: -2rem;
			margin-bottom: 1.5rem;
		}
	}

	.link-forward {
		@include text(epsilon);
		font-weight: $font-weight-semi-bold;

		&:hover,
		&:focus {
			color: $color-secondary-light-blue;
			outline: 0;
			text-decoration: underline;
		}
	}

	.help-text {
		@include text(zeta);
		color: $color-healthplan-gray;
		margin: 0.5rem 0 0;
	}

	.button-container {
		.btn {
			@include text(delta);
			border-radius: 25px;
			min-height: 62px;
			padding: 1rem;
			width: 100%;

			&.btn-primary {
				@include button-variant(
					$color-healthplan-blue,
					$color-healthplan-blue,
					$color-primary-dark-blue,
					$color-primary-dark-blue
				);
			}

			&.btn-secondary {
				@include button-variant(
					$color-neutral-light,
					$color-neutral-light,
					$color-neutral,
					$color-neutral
				);
				color: $color-primary-dark-blue;
			}

			&:not(:first-child) {
				margin-top: 1rem;
			}
		}
	}

	.list-container {
		&:not(:last-of-type) {
			margin-bottom: 2rem;
		}

		.heading-4 {
			margin-bottom: 0.75rem;
		}

		ul {
			padding: 0;
			margin: 0;

			li {
				&:before {
					display: none;
				}

				&:not(:last-of-type) {
					margin-bottom: 0.625rem;
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		&.fade {
			.modal-dialog {
				transform: translateY(-3rem);
			}
		}

		&.show {
			.modal-dialog {
				transform: none;
			}
		}

		.modal-scroll-container {
			padding: 3rem 0;
		}

		.modal-content {
			@include make-col(10);
		}

		.modal-body {
			padding: 4rem 4rem 1rem;

			&:only-child {
				padding: 5.5rem 4rem;
			}
		}

		.modal-footer {
			padding: 1.5rem 4rem;
		}

		.heading-2 {
			text-align: center;

			+ p {
				margin-bottom: 2.5rem;
				text-align: center;
			}
		}

		.button-container {
			display: flex;

			.btn {
				flex: 1 1 0;

				&:not(:first-child) {
					margin-left: 1rem;
					margin-top: 0;
				}
			}
		}

		.multi-list-container {
			display: flex;

			.list-container {
				flex: 1 1 0;

				&:not(:last-of-type) {
					margin-bottom: 0;
					margin-right: 1rem;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.modal-content {
			@include make-col(8);
		}

		.multi-list-container {
			.list-container {
				&:not(:last-of-type) {
					margin-right: 2rem;
				}
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.modal-content {
			@include make-col(7);
		}

		.multi-list-container {
			.list-container {
				&:not(:last-of-type) {
					margin-right: 2.5rem;
				}
			}
		}
	}
}
