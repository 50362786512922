.article-list-paginated {
	@include make-container();
	@include make-container-max-width();

	.article-list {
		padding-left: 0;
		padding-right: 0;
	}

	.article-listing {
		display: none;

		&.active {
			display: block;
		}

		&:last-of-type {
			margin-bottom: 2.5rem;
		}
	}

	.pagination-controls {
		.pagination-counts {
			justify-content: center;
		}
	}
}
