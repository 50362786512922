.page-header-healthplan {
	background-color: rgba($color-healthplan-blue, 0.06);
	margin-bottom: 2rem;

	&.white-bg {
		background-color: $color-white;
	}

	+ .message,
	+ div + .message {
		.alert {
			margin-bottom: 2rem;
			margin-top: -2rem;
		}
	}

	+ .find-a-doctor,
	+ .find-a-location {
		margin-top: -2rem;
	}

	.page-header-inner {
		@include make-container();
		@include make-container-max-width();
		padding-top: 2rem;
		padding-bottom: 0.25rem;
	}

	.page-header-top {
		display: flex;
		justify-content: space-between;
		position: relative;
		flex-flow: column wrap;

		.social {
			&:nth-child(1) {
				order: 2;
			}
		}

		.heading-1 {
			&:nth-child(2) {
				order: 1;
			}
		}
	}

	.breadcrumb {
		background-color: transparent;
		margin: 0 0 1rem;
		padding: 0;

		.breadcrumb-item {
			@include text(epsilon);
			color: $color-healthplan-blue;
			font-weight: $font-weight-normal;
			display: none;
			padding-left: 0;

			+ .breadcrumb-item:before {
				display: none;
			}

			&:last-of-type {
				display: block;

				.link-backward {
					&:before {
						display: none;
					}
				}
			}
		}

		.icon-arrow-left {
			height: 1rem;
			position: relative;
    		top: -2px;
			fill: $color-healthplan-blue;
		}

		.icon-home {
			fill: $color-black;

			&:hover,
			&:focus {
				fill: $color-healthplan-blue;
			}
		}
	}

	.btn-enroll-now {
		color: $color-gray-darkest;
		background-color: $color-primary-yellow;
		border-color: $color-primary-yellow;
		float: none;
		margin-left: 0;
		margin-bottom: 0.75rem;
		padding: 0.4375rem 2.375rem;
	}

	.heading-1 {
		margin-bottom: 0.625rem;

		&:last-child {
			padding-bottom: 0.5rem;
		}
	}

	.page-header-bottom {
		.subtitle {
			margin-bottom: 0;
			padding-bottom: 0.625rem;
			padding-right: 4rem;
		}

		.link-forward {
			@include text(delta);
			background-color: rgba($color-healthplan-blue, 0.06);
			display: block;
			margin-left: -15px;
			padding: 1rem 4rem 1rem 15px;
			width: calc(100% + 30px);
			font-weight: $font-weight-bold;
		}
	}

	.customer-service {
		padding-top: 0.5rem;

		p {
			@include text(zeta);
			margin-bottom: 1rem;

			a {
				font-weight: $font-weight-bold;
			}
		}
	}

	@include media-breakpoint-up(sm) {
		.page-header-top {
			align-items: center;
			display: flex;
			justify-content: space-between;
			position: relative;
			flex-flow: row wrap;

			nav {
				flex: 0 0 50%;
			}

			.social {
				&:nth-child(1) {
					order: 2;
				}
			}

			.heading-1 {
				&:nth-child(2) {
					flex: 0 0 60%;
					order: 1;
				}
				&:nth-child(3) {
					flex: 0 0 100%;
				}
			}
		}

		.btn-enroll-now {
			float: right;
			margin-left: 1.5rem;
		}

		.customer-service {
			padding-top: 0.5rem;

			p {
				@include text(zeta);
				margin-bottom: 0.3rem;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		margin-bottom: 3rem;

		+ .message,
		+ div + .message {
			.alert {
				margin-bottom: 3rem;
				margin-top: -3rem;
			}
		}

		+ .find-a-doctor,
		+ .find-a-location {
			margin-top: -3rem;
		}

		+ .find-a-location
			.search-page-wrapper-outer.view-mode-map
			.search-page-results {
			margin-top: 0;
		}

		.page-header-inner {
			padding-bottom: 1.5rem;
			padding-top: 2rem;
		}

		.breadcrumb {
			align-items: center;
			display: flex;
			margin-bottom: 0.75rem;

			.breadcrumb-item {
				@include text(zeta, iota);
				display: block;
				font-weight: $font-weight-semi-bold;
				position: relative;

				+ .breadcrumb-item {
					margin-left: 0.375rem;
					padding-left: 0.675rem;

					&:before {
						color: $color-healthplan-blue;
						content: '/';
						display: inline-block;
						height: 95%;
						left: 0;
						padding-right: 0;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						width: 1px;
					}
				}

				.link-backward {
					&:before {
						display: none;
					}
				}

				.icon-arrow-left {
					display: none;
				}

				.icon {
					height: 0.875rem;
					width: 0.875rem;

					&.icon-home {
							position: relative;
							top: -1px;
							height: 0.775rem;
					}
				}
			}
		}

		.btn-enroll-now {
			padding: 0.4375rem 3.375rem;
		}

		.social {
			/* display: block;
			margin-left: auto;
			margin-bottom: 0.75rem; */

			&:only-child {
				/* margin-bottom: 0;
				position: absolute;
				top: 0.5rem;
				right: 0; */
			}
		}

		.heading-1 {
			margin-bottom: 1.25rem;

			&:last-child {
				margin-bottom: 0.5rem;
				padding-bottom: 0;
			}
		}

		.page-header-bottom {
			align-items: flex-end;
			display: flex;
			justify-content: space-between;

			.subtitle {
				padding-bottom: 0;

				&:not(:only-child) {
					padding-bottom: 0.75rem;
				}
			}

			.subtitle-container {
				.link-forward {
					display: inline-block;
				}
			}

			.link-forward {
				@include text(zeta);
				background-color: transparent;
				display: block;
				margin-bottom: 3px;
				margin-left: 0;
				padding: 0 1.125rem 0 0;
				width: auto;
			}
		}
	}
}
