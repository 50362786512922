.microsite-theme {

	body {
		font-family: $resolution-sans-serif;
	}
	
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $color-black;
	}

	p,
	.body-copy {
		@include text(delta, 30);
		color: $color-microsite-gray;
	}

	.list-heading {
		@include text(delta, 30);
		color: $color-microsite-gray;
		font-weight: $font-weight-bold;
		margin-bottom: 0;
	}

	.list-unstyled {
		li {
			@include text(delta, 30);
			color: $color-microsite-gray;
		}
	}

	.rte,
	.article-content {
		a:not(.btn) {
			color: $color-microsite-gray;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.generic,
	.rte {
		h2 {
			color: $color-black;
			font-size: 45px;
			font-weight: $font-weight-light;
			line-height: 1;
			margin-bottom: 0;
		}

		p {
			@include text(delta, 30);
			color: $color-microsite-gray;

			&:first-of-type {
				padding-top: 0;
			}
		}

		@include media-breakpoint-up(md) {
			h2 {
				font-size: 55px;
			}
		}
	}
}
