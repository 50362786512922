.cta-image-card {
	@include make-container();
	@include make-container-max-width();
	padding-left: 0;
	padding-top: 2rem;
	padding-right: 0;

	.two-column-sidebar & {
		display: block;
	}

	.two-column-main & {
		display: none;
	}

	.card {
		margin-bottom: 2rem;
	}

	.cta-card-img {
		// padding: 0 1.75rem;

		img,
		source {
			border-radius: 5px 5px 0 0;
			object-fit: cover;
			object-position: 50% 50%;
			font-family: 'object-fit: cover; object-position: 50% 50%;';
			width: 100%;
		}
	}

	.section-title {
		text-align: center;
		margin-bottom: 1.25rem;

		&:after {
			background-color: $color-illumination-yellow;
		}
	}

	.card-body {
		text-align: center;
		border: 0.5rem solid $color-gray-light;
		border-top: 0;
	}

	.divider {
		margin: 1rem auto;
	}

	.card-text {
		@include text(epsilon,delta);
	}

	.btn {
		padding: 0.4375rem 2.25rem;
		width: 100%;
	}

	@include media-breakpoint-up(sm) {
		.btn {
			width: auto;
		}
	}

	@include media-breakpoint-up(md) {
		.two-column-sidebar & {
			display: block;
		}

		.two-column-main & {
			display: none;
		}

		.card {
			margin-bottom: 2rem;
		}

		.section-title {
			text-align: center;

			&:after {
				left: calc(50% - 29px);
			}
		}

		.card-body {
			text-align: center;
			order: 2;
		}

		.cta-card-img {
			order: 1;
			// margin-top: -1rem;
			// margin-right: -1rem;
			margin-bottom: auto;
			// margin-left: -1rem;
			padding: 0;
		}

		.btn {
			width: 100%;
		}
	}
}
