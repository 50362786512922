.advance {
	header {
		z-index: 999999;
	}
	.advance--brand-bar,
	.advance--brand-bar--spacer {
		height: 60px;
		position: relative;
		z-index: 99999;
		img {
			max-height: 37px;
		}
	}
	.advance--brand-bar {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		border-top: 1px solid $color-advance-mid-grey;
		img {
			margin: 0 auto;
			width: auto;
		}
		&.fixed {
			top: 0px;
			position: fixed;
			box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.08);
		}
	}
	@include media-breakpoint-up(lg) {
		.advance--brand-bar,
		.advance--brand-bar--spacer {
			height: 70px;
			padding: 15px 0;
			img {
				max-height: 40px;
			}
		}
		.home {
			.advance--brand-bar,
			.advance--brand-bar--spacer {
				height: 90px;
				img {
					max-height: 52px;
				}
			}
		}
		.advance--brand-bar {
			&.fixed {
				top: 70px;
			}
		}
	}
}
