.bi-omnibar {
	@extend .clearfix;
	@include make-container();
	padding-top: 1rem;
	padding-bottom: 4rem;

	.bi-omnibar-inner {
		@include make-container();
		@include make-container-max-width();
		background-color: $color-white;
		border-radius: 1rem;
		/* Tashika felt that bottom padding in the figma specs were too high. Moved bottom padding to 1.5rem (24px) */
		/* See https://mhhsmarketing.atlassian.net/browse/SIT-2047 */
		padding: 1rem 0 1.5rem;
		position: relative;
		/* We need to override blockit's margin:left and right to correctly vertically align. */
		/* See SIT-2083 https://mhhsmarketing.atlassian.net/browse/SIT-2083*/   
		margin-left: auto !important;
		margin-right: auto !important;

		&.minified {
			.filter-group:not(:first-of-type),
			button[type='submit'] {
				display: none;
			}

			.bi-omnibar-search-typeahead {
				.search-dropdown {
					margin-top: -1rem;
				}
			}
		}
	}

	.heading-2 {
		@include text(delta);
		color: $color-gray-darkest;
		display: block;
		margin-bottom: 1rem;
		padding-bottom: 0.725rem;
		width: 100%;
		text-align: left;

		&:after {
			background-color: $color-primary-yellow;
			top: 10px;
			bottom: 0;
			content: '';
			display: block;
			height: 4px;
			position: relative;
			width: 58px;
		}
	}


	.button-group {
		display: flex;
		gap: 16px 16px;
		flex-wrap: wrap;

		a {
		font-size: 1rem;
		font-family: 'proxima-nova';
		line-height: 1.125rem;
		background: none;
		color: $color-primary-blue;
		font-weight: $font-weight-semi-bold;
		justify-content: flex-start;
		outline: 0;
		position: relative;
		min-width: 29%;
		height: 1rem;
		flex-basis: 100%;
		align-items: center;
		display: flex;

		span {
			max-width: 85%; //110px;
			position: relative;
			display: block;
			width: 85%; //110px;
			margin-left: auto;
			margin-right: auto;
			padding-right: 4px;
		}

		}

		.icon-arrow-right {
			fill: $color-primary-blue;
			pointer-events: none;
			top: calc(50% - 7px);
			height: 1rem;
			width: 1.25rem;
			padding-left: .75rem;
		}
	}


	form {
		position: relative;

		.search-form {
			border-bottom: 1px solid rgba(55, 55, 55, 0.5);
			display: flex;
		}
	}

	.filter-group {
		margin-bottom: 1.5rem;
		position: relative;
	}

	label {
		@include text(epsilon);
		color: $color-gray-darkest;
		font-weight: $font-weight-semi-bold;
		text-align: left;
	}

	.icon {
		// height: 1.5rem;
		fill: $color-neutral;
		display: flex;
		align-self: center;
		// width: 1.5rem;
		z-index: 1;
		right: 2rem;
	}

	input[type='text'] {
		// background-color: $color-white;
	}

	.input-icon-container {
		//box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
	}

	.form-group > input,
	.form-group > label,
	.form-group > select,
	.form-label-group > input,
	.form-label-group > label {
		background-color: transparent;
		border: 1px solid $color-gray-darkest;
		border-radius: 2rem;
		padding: 1rem 2.75rem 1rem 1.75rem;
	}

	.form-group > label,
	.form-label-group > label {
		position: absolute;
		top: -10px;
		left: 0px;
		border: none;
		display: block;
		width: 100%;
		margin-bottom: 0;
		color: $color-neutral-dark;
		pointer-events: none;
		cursor: text;
		transition: all 0.1s ease-in-out;
	}

	.form-group input::placeholder,
	.form-label-group input::placeholder {
		color: transparent;
	}

	.form-group input:not(:placeholder-shown) ~ label,
	.form-label-group input:not(:placeholder-shown) ~ label {
		@include text(eta);
		display: none;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
	}

	select {
		background-color: $color-white !important;
		border-radius: 0;
		border: none;
		box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
		padding-right: 2.5rem;
		position: relative;
		appearance: none;
		background-color: transparent;
		background-image: none;
		box-shadow: none;
		display: block;

		&::-ms-expand {
			display: none;
		}
	}

	.clear-btn {
		margin: 1.5rem 1rem 0.5rem;
		padding: 0;
	}

	.bi-omnibar-search-typeahead {
		position: relative;

		&.search-focus {
			.search-dropdown {
				display: block;
			}
		}

		.search-typeahead-input {
			&::-ms-clear {
				display: none;
			}
		}

		.search-dropdown {
			background-color: $color-white;
			border: 1px solid $color-gray-light;
			box-shadow: 0 10px 24px rgba($color-black, 0.12);
			display: none;
			margin-top: -3.5rem;
			position: absolute;
			top: 100%;
			left: 50%;
			text-align: left;
			transform: translateX(-50%);
			width: calc(100vw - 60px);
			max-width: 1240px;
			z-index: 6;

			ul {
				list-style-type: none;
				padding: 0;
				margin: 0;

				li {
					@include text(zeta);
					text-align: left;

					&:before {
						display: none;
					}

					&:not(:last-of-type) {
						margin-bottom: 0.5rem;
					}

					a {
						@include text(zeta);
						color: $color-neutral-dark;

						&:focus,
						&.active {
							outline: 0;
							text-decoration: underline;
						}
					}
				}
			}

			.heading-5 {
				margin-bottom: 0.625rem;
			}

			.view-all-link {
				@include text(zeta);
				color: $color-secondary-light-blue;
				display: inline-block;
				font-weight: $font-weight-semi-bold;
				margin-top: 1.5rem;
				margin-bottom: 0;

				&:focus,
				&.active {
					outline: 0;
					text-decoration: underline;
				}

				&.looking-for {
					color: $color-gray-light;
					font-style: italic;
					margin-top: 0;
				}
			}

			.primary-view-all {
				@include text(zeta);
				align-items: center;
				background-color: $color-neutral-lightest;
				color: $color-secondary-light-blue;
				display: flex;
				font-weight: $font-weight-semi-bold;
				height: 50px;
				justify-content: center;
				text-align: center;
				width: 100%;

				&:focus,
				&.active {
					outline: 0;
					text-decoration: underline;
				}
			}

			.category-list {
				&:not(:first-of-type) {
					margin-top: 1.5rem;
				}
			}

			.doctor-name {
				@include text(epsilon);
				color: $color-primary-light-blue;
				font-weight: $font-weight-semi-bold;
			}

			.location-name {
				@include text(epsilon);
				color: $color-primary-blue;
				font-weight: $font-weight-semi-bold;
			}

			.dropdown-container {
				@include make-row();
				flex-direction: column;
				margin: 0;

				.dropdown-primary {
					order: 1;
					padding: 1.75rem 1.75rem 1.25rem;
				}

				.dropdown-secondary {
					order: 2;
					padding: 0.75rem 1.75rem 1.25rem;

					.heading-5,
					ul {
						//display: none;
					}

					.view-all-link {
						display: none;

						&.mobile-find-link {
							align-items: center;
							border-bottom: 1px solid $color-gray-light;
							color: $color-gray-darkest;
							display: flex;
							justify-content: center;
							margin-top: 0;
							text-align: center;
							width: 100%;
							height: 100%;
							padding: 0.75rem 0.5rem;
						}
					}

					.category-list {
						align-items: center;
						display: block;
						justify-content: center;
						margin-top: 0;

						&:not(:first-of-type) {
							margin-top: 1.5rem;

							.mobile-find-link {
								border-left: 1px solid $color-gray-light;
							}
						}
					}
				}
			}

			.cta-container {
				.current-network {
					display: none;
				}
			}

			.search-doctors {
				.profile-image {
					height: 62px;
					min-width: 62px;
					width: 62px;
					flex: 0 0 62px;
					margin-right: 1.5rem;
					border: none;
					box-shadow: none;
				}

				li {
					align-items: center;
					display: flex;
				}

				svg {
					height: 100%;
					width: 100%;
				}
			}

			.search-locations {
				li {
					align-items: center;
					display: flex;
				}

				svg {
					flex: 0 0 25px;
					height: 25px;
					margin-right: 1.5rem;
					fill: $color-primary-dark-blue;
					width: 25px;
				}
			}

			.emergency-disclaimer {
				font-size: 1rem;
				 line-height: 1.2;
				 font-weight: 400;
				 font-style: italic;
				 color: red;
				 margin-bottom: 1.25rem;
			}
		}
	}

	@include media-breakpoint-down(md) {
		.emergency-disclaimer {
			padding-top: 1rem;
			padding-right: 1.25rem;
			padding-left: 1.25rem;
			margin-bottom: 0rem !important;
		}
	}

	@include media-breakpoint-up(sm) {
		.form-group input:not(:placeholder-shown) ~ label,
		.form-label-group input:not(:placeholder-shown) ~ label {
			display: block;
		}

		.form-group > label,
		.form-label-group > label {
			top: 0px;
		}
	}


	@include media-breakpoint-up(md) {
		@include make-container-max-width();

		&.with-page-hero {
			background-color: $white;

			.bi-omnibar-inner {
				margin-top: 0;
			}
		}

		.heading-2 {
			@include text(gamma);
		}

		.bi-omnibar-search-typeahead {

			.search-dropdown {
				left: 0;
				margin-top: 0.5rem;
				transform: none;
				width: calc(100vw - 80px);
			}
		}

		form {
			display: flex;
			flex-wrap: wrap;

			.filter-group {
				flex: 2	2 0;
				margin-bottom: 0;
				margin-right: 1rem;

				&:last-of-type {
					flex: 1 1 0;
					margin-right: 0;
				}
			}

			button[type='submit'] {
				flex-basis: 100%;
				height: 3rem;
				margin-top: 1rem;
			}
		}

		.clear-btn {
			margin: 0.5rem 1rem 0.5rem;
			position: absolute;
			top: 0;
			right: 2px;
		}

		.button-group {
			display: flex;
			gap: 16px 16px;
			flex-wrap: wrap;

			a {
			font-size: 1rem;
			line-height: 1.125rem;
			padding: .5rem .5rem;
			min-width: 29%;
			height: 1.25rem;
			flex-basis: calc(33.3% - 16px);
			align-items: center;
			display: flex;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.bi-omnibar-search-typeahead {
			.search-dropdown {

				.dropdown-container {
					@include make-row();
					flex-direction: row;
					margin: 0;
					padding: 1.75rem 1.75rem 1.25rem;

					.dropdown-primary {
						@include make-col(5);
						border-right: 1px solid $color-neutral-darkest;
						order: 1;
						padding: 0 2rem 0 0;
					}

					.dropdown-secondary {
						@include make-col(7);
						display: block;
						order: 2;
						//padding-left: 2rem;
						padding: 0 0 0 2rem;

						.heading-5,
						ul {
							display: block;
						}
						.view-all-link {
							display: inline-block;

							&.mobile-find-link {
								display: none;
							}
						}

						.category-list {
							display: block;

							&:not(:first-of-type) {
								margin-top: 1.5rem;

								.view-all-link {
									border-left: none;
								}
							}
						}
					}
				}

				.cta-container {
					@include make-row();
					background-color: $color-neutral-lightest;
					margin: 0;
					padding: 0 1.75rem;

					.primary-view-all {
						@include make-col(5);
						justify-content: flex-start;
					}

					.current-network {
						@include make-col(7);
						@include text(eta);
						color: $color-neutral-dark;
						display: flex;
						align-items: center;
						padding-left: 2rem;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.heading-2 {
			@include text(beta);
		}

		.bi-omnibar-search-typeahead {

			.search-dropdown {
				left: 0;
				transform: none;
				width: calc(100vw - 100px);
			}
		}

		form {
			.filter-group {
				&:last-of-type {
					margin-right: 1rem;
				}
			}

			button[type='submit'] {
				flex-basis: auto;
				margin-top: 0;
			}
		}
	}
}
