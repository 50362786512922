.advance {
	.advance-page-header {
		background: $color-advance-dark-teal;
		position: relative;
		max-height: 800px;
		padding-top: 16px;
		padding-bottom: 20px;
		border-bottom: 14px $color-advance-teal solid;

		.page-header-inner {
			@include make-container();
			background: transparent;
			position: relative;
			z-index: 1;

			h1 {
				color: $color-white;
				padding: 26px 0 3px;
				margin: 0;
			}

			.description {
				color: $color-white;
			}

			.icon {
				fill: $color-advance-white;
				stroke: $color-advance-white;
			}

			.language-dropdown {
				.dropdown-toggle {
					color: $color-advance-white;
				}
			}

			.social,
			.language-dropdown {
				display: none;
			}
			
			.description {
				color: $color-white;
			}
		}

		.divider {
			display: none;
		}
	}

	@include media-breakpoint-up(md) {
		.advance-page-header {
			background: $color-advance-teal;
			border-bottom: none;
			padding-top: 18px;

			.page-header-inner {
				@include make-container-max-width();

				h1 {
					padding: 20px 0 50px;
				}

				.description {
					max-width: 40%;
				}

				.language-dropdown {
					right: 15px;
				}
			}
			.page-header--extend {
				content: '';
				overflow: hidden;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				&::after {
					background: $color-advance-dark-teal;
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					width: 49.5vw;
					content: '';
					transform: rotate(12deg);
					height: 900px;
					transform-origin: top right;
				}
			}
		}
	}
}
