.marketo-form {
	@include make-container();
	@include make-container-max-width();
	margin-bottom: 3rem;

	.generic &,
	.two-column & {
		padding: 0;
	}

	&.success {
		.mktoForm {
			display: none !important;
		}
		.thank-you-message {
			display: flex;
		}
	}

	&.wide {
		.mktoForm {
			display: block;

			> .mktoFormRow {
				margin-bottom: 0;

				> .mktoFormCol {
					margin-bottom: 0 !important;
				}
			}
		}

		&.success {
			.mktoForm {
				display: none;
			}
			.thank-you-message {
				display: flex;
			}
		}
	}

	.thank-you-message {
		display: none;

		p {
			text-align: center;
		}
	}

	.form-description {
		@include text(epsilon, delta);
		margin-bottom: 1.5rem;

		:last-child {
			margin-bottom: 0;
		}

		p {
			@include text(epsilon, delta);

			a {
				font-weight: $font-weight-bold;
			}
		}
	}

	.mktoForm {
		font-family: $font-family-sans-serif;

		> .mktoFormRow {
			margin-bottom: 1.5rem !important;

			> .mktoFormCol {
				margin-bottom: 0 !important;
			}
		}

		.mktoFormRow {
			margin-bottom: 1.5rem;
			max-width: 400px;

			&.hidden {
				margin-bottom: 0 !important;
			}
		}

		.mktoAsterix {
			float: left;
			padding-right: 0.25rem;
		}

		.mktoFieldWrap:not(.mktoRequiredField) {
			.mktoAsterix {
				display: none;
			}
		}

		label,
		.mktoLabel {
			@include text(zeta);
			color: $color-primary-dark-blue;
			font-family: $font-family-sans-serif;
			width: 100% !important;
		}

		legend {
			display: none;
		}

		.mktoEmailField,
		.mktoTelField,
		.mktoTextField {
			@extend .form-control;
			@include text(epsilon);
			background-color: $color-neutral-lightest;
			border: 1px solid $color-neutral-lightest;
			border-radius: 0.3125rem;
			color: $color-precision-gray;
			width: 100% !important;

			&:not(:placeholder-shown) {
				background-color: $color-neutral-lightest;
				border-color: $color-neutral-lightest;
			}

			&:focus {
				background-color: $color-precision-gray-ish-tint;
				border-color: $color-secondary-light-blue;
				box-shadow: none;
			}

			&:hover {
				border-color: $color-sky-blue;
			}
		}

		select,
		textarea {
			@extend .form-control;
			@include text(epsilon);
			background-color: $color-neutral-lightest;
			border: 1px solid $color-neutral-lightest;
			border-radius: 0.3125rem;
			color: $color-precision-gray;
			width: 100% !important;

			&:focus {
				background-color: $color-precision-gray-ish-tint;
				border-color: $color-secondary-light-blue;
				box-shadow: none;
			}

			&:hover {
				border-color: $color-sky-blue;
			}
		}

		select {
			height: 2.625rem;
		}

		.mktoCheckboxList {
			@extend .checkbox-wrapper;

			label {
				@include text(zeta);
				display: block;
			}
		}

		.mktoRadioList {
			@extend .radio-wrapper;

			label {
				@include text(zeta);
			}
		}

		fieldset {
			.mktoFieldWrap {
				label {
					@include text(zeta);
				}

				h4 {
					@include text(zeta);
				}
			}

			.mktoCheckboxList {
				flex-direction: column-reverse;

				label {
					padding: 0 0 0 1.75rem;

					&::after {
						top: auto !important;
					}
				}
			}
		}

		.mktoErrorMsg {
			@include text(eta);
			color: $color-red;
			padding-top: 0.5rem;

			footer & {
				color: $color-white;
			}
		}

		.mktoButton {
			@extend .btn;
			@extend .btn-primary;
		}

		.g-recaptcha {
			visibility: visible;
		}
	}

	&.lp-mktoform {
		background-color: $color-neutral-lightest;
		border-radius: 5px;
		padding: 2.5rem 3rem !important;

		.mktoEmailField,
		.mktoTelField,
		.mktoTextField,
		select,
		textarea {
			background-color: $color-white;
			border: 1px solid $color-neutral-lightest;

			&:focus {
				background-color: $color-white;
				border-color: $color-neutral-light;
				box-shadow: none;
			}

			&:not(:placeholder-shown) {
				background-color: $color-white;
				border-color: $color-neutral-lightest;
			}
		}
	}

	.g-recaptcha {
		visibility: hidden;

		div {
			width: 168px !important;

			div {
				border-left: 5px solid transparent;
				border-top: 5px solid transparent;
				border-radius: 5px;
				transition: background-color 400ms ease-out;
				background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/250687/spiffygif_86x86.gif');
				background-repeat: no-repeat;
				background-position: center center;
			}
		}
	}

	.g-recaptcha.g-recaptcha {
		margin-top: 20px;
		margin-left: 110px;
	}

	.g-recaptcha.mktoInvalid > div > div {
		background-color: #f63c00;
		transition: background-color 400ms ease-in;
	}

	@include media-breakpoint-up(sm) {
		&.wide {
			.mktoForm {
				display: flex;
				flex-flow: row;
				flex-wrap: wrap;
				justify-content: space-between;

				> .mktoFormRow {
					flex-basis: calc(50% - 0.5rem);
					margin-bottom: 0.5rem;
					max-width: none;
					padding: 0;
				}
			}
		}

		.hero-secondary + .two-column .landing-page & {
			margin-top: 0;
		}

		&.lp-mktoform {
			padding: 2.5rem 3rem !important;
		}
	}

	@include media-breakpoint-up(md) {
		&.wide {
			.mktoForm {
				> .mktoFormRow {
					flex-basis: calc(50% - 15px);
				}
			}
		}

		&.lp-mktoform {
			padding: 2rem 2.5rem !important;
		}

		&.left,
		&.center,
		&.right {
			max-width: 400px;
		}

		.hero-secondary + .two-column .landing-page & {
			.two-column-main & {
				&.left,
				&.center,
				&.right {
					max-width: auto;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.hero-secondary + .two-column .landing-page & {
			margin-top: -12rem;
			margin-left: 1rem;
			margin-right: 1rem;
		}

		&.left {
			float: left;
			margin-left: 0;
			margin-right: 1.5rem;
		}

		&.center {
			margin-left: auto;
			margin-right: auto;
			max-width: 80%;

			.mktoForm {
				display: grid;
				justify-content: center;
			}
		}

		&.right {
			float: right;
			margin-left: 1.5rem;
			margin-right: 0;
		}

		.two-column-main & {
			&.left,
			&.right {
				/* max-width: 350px; */
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.hero-secondary + .two-column .landing-page & {
			margin-left: 3rem;
			margin-right: 3rem;
		}

		&.lp-mktoform {
			padding: 2.5rem 3rem !important;
		}

		&.full-width {
			.mktoForm {
				.mktoFormRow,
				.mktoButtonRow {
					max-width: none;
				}
			}
		}
	}
}
