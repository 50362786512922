.share-story-modal {
	background-color: rgba($color-black, 0.5);
	display: none;
	height: 100%;
	left: 0;
	opacity: 0;
	outline: 0;
	position: fixed;
	top: 0;
	transition: opacity 0.3s ease-in-out;
	width: 100%;
	z-index: 10;

	&.visible {
		display: block;

		.modal-dialog {
			transform: translateY(-15px);
		}
	}

	&.open {
		opacity: 1;

		.modal-dialog {
			transform: none;
		}
	}

	.modal-scroll-container {
		height: 100%;
		max-height: 100%;
		overflow: auto;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
	}

	.modal-container {
		@include make-container();
		align-items: flex-start;
		display: flex;
		height: 100%;
		justify-content: center;
		overflow: auto;
		padding: 0;
	}

	.modal-dialog {
		@include make-container();
		@include make-container-max-width();
		align-items: flex-start;
		display: flex;
		margin: 0 auto;
		max-width: none;
		padding: 0;
		transition: transform 0.3s ease-out;
	}

	.modal-close-btn {
		background: transparent;
		border: none;
		outline: 0;
		position: absolute;
		right: 15px;
		top: 15px;

		.icon {
			height: 30px;
			stroke: $color-healthplan-gray;
			width: 30px;
		}

		&:hover,
		&:focus {
			.icon {
				stroke: $color-microsite-yellow;
			}
		}
	}

	.modal-content {
		border: none;
		border-radius: 0;
		margin: auto;
		min-height: 100vh;
	}

	.modal-body {
		background-color: #eee;
		padding: 3.5rem 1rem 1rem;
	}

	.modal-title {
		color: $color-black;
		font-family: $moments-serif;
		font-size: 50px;
		font-weight: $font-weight-normal;
		line-height: 1;
		margin-bottom: 1.5rem;
	}

	.modal-description {
		@include text(zeta, 22);
		color: $color-moments-gray;
		font-family: $moments-serif;
		font-weight: 400;
	}

	form {
		padding: 0;

		label {
			@include text(eta);
			color: $color-moments-gray;
			font-family: $moments-sans-serif;
			padding-left: 2px;
		}

		.form-group {
			margin-bottom: 0;
		}

		input[type='text'],
		input[type='email'],
		input[type='tel'],
		select,
		textarea {
			@extend .form-control;
			@include text(epsilon);
			background-color: $color-white;
			border: 1px solid $color-white;
			border-bottom-color: $color-gray-light;
			border-radius: 0;
			color: $color-microsite-gray;
			margin-bottom: 1rem;

			&:focus {
				background-color: $color-white;
				border-color: $color-gray-light;
				box-shadow: none;
			}

			&::placeholder {
				color: $color-microsite-gray-light;
			}
		}

		.btn {
			@include button-variant(
				$color-microsite-yellow, // initial background
				$color-microsite-yellow, // initial border
				$color-white, // hover background
				$color-microsite-yellow // hover border
			);
			color: $color-precision-gray;
			margin: 0;

			&:hover {
				color: $color-precision-gray;
			}
		}
	}

	.thank-you-message {
		background-color: #eee;

		.thank-you-bubble {
			background-color: $color-moments-black;
			border-color: $color-moments-black;
			border-radius: 8px;
			color: $color-microsite-yellow;
			font-family: $moments-serif;
			font-size: 50px;
			font-weight: $font-weight-normal;

			&:before {
				border-top: 13px solid $color-moments-black;
			}

			&:after {
				border-top: 9px solid $color-moments-black;
			}
		}

		.thank-you-content {
			p {
				@include text(zeta, 22);
				color: $color-black;
				font-family: $moments-serif;
				font-weight: 400;
			}
		}
	}

	@include media-breakpoint-up(md) {
		&.visible {
			.modal-dialog {
				transform: translateY(-3rem);
			}
		}

		&.open {
			.modal-dialog {
				transform: none;
			}
		}

		.modal-scroll-container {
			height: auto;
			height: auto;
		}

		.modal-container {
			padding: 3rem 0;
		}

		.modal-content {
			@include make-col(10);
			min-height: 0;
		}

		.modal-body {
			padding: 4rem 4rem 1rem;
		}

		.modal-close-btn {
			right: 30px;
			top: 30px;
		}

		.modal-description {
			@include text(epsilon, 28);
		}

		.thank-you-message {
			.thank-you-content {
				p {
					@include text(epsilon, 28);
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		&.visible {
			align-items: center;
			display: flex;
		}

		.modal-container {
			height: auto;
			max-height: 100vh;
		}

		.modal-body {
			padding: 70px 110px 1rem;
		}
	}
}
