.related-content {
	background-color: $color-neutral-lightest;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1rem;
	max-width: 524px;

	.content {
		display: block;
		padding: 1.75rem 1.25rem 2rem;
		width: 100%;
	}

	.description,
	.buttons {
		flex-basis: 100%;
		flex-flow: row;
		max-width: 100%;
		padding-right: 15px;
		padding-left: 15px;
	}

	.buttons {
		a {
			display: block;
			margin-bottom: 0.25rem;
			width: 100%;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.btn {
			margin-bottom: 1rem;
		}
	}

	.title {
		@include text(epsilon);
		color: $color-primary-blue;
		font-weight: $font-weight-bold;
		margin-bottom: 1rem;
	}

	p {
		@include text(epsilon, delta);
	}

	.image-area {
		position: relative;
	}

	.image {
		object-fit: cover;
		object-position: 50% 50%;
		font-family: 'object-fit: cover; object-position: 50% 50%;';
		height: 100%;
		width: 100%;
	}

	@include media-breakpoint-up(sm) {
		.content {
			display: flex;
			padding: 1.75rem 1.25rem 2rem;
			width: 100%;
		}

		.description,
		.buttons {
			flex-basis: 50%;
			max-width: 50%;
			flex-flow: column;
		}
	}

	@include media-breakpoint-up(md) {
		&.left {
			margin-left: 0;
		}

		&.right {
			margin-right: 0;
		}

		.two-column & {
			&.left {
				margin-left: auto;
			}

			&.right {
				margin-right: auto;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.two-column & {
			&.left {
				margin-left: 0;
			}

			&.right {
				margin-right: 0;
			}
		}
	}
}
