.audio-player {
	@include make-container();
	@include make-container-max-width();
	margin-bottom: 2rem;

	audio {
		width: 100%;
	}

	.generic &,
	.two-column & {
		padding: 0;
	}
}
