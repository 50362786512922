.broadcast {
	.media-item {
		width: 100%;
		padding: 0;
		margin-bottom: 0.75rem;
		padding-top: 0.5rem;

		.heading-4 {
			display: none;
		}

		.figure-text {
			display: none;
		}
	}

	@include media-breakpoint-up(md) {
		.media-item {
			width: 100%;
			margin-bottom: 1.5rem;
		}
	}

	@include media-breakpoint-up(lg) {
		.media-item {
			width: 50%;
			padding-top: 1rem;
		}
	}
}