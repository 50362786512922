.advance {
	.generic .alt-background {
		background-color: $color-advance-off-white;
	}

	.advance--article-content {
		padding-top: $padding-advance-section-mobile;
		padding-bottom: $padding-advance-section-mobile;

		.generic {
			max-width: calc(#{$advance-content-width} - 40px);
			padding: 22px 0px;
			margin: 0 auto;
			.rte p:first-of-type {
				padding-top: 0;
			}
		}

		.generic *:last-child {
			margin-bottom: 0;
		}

		figure,
		.media-item {
			margin: 24px auto 16px;
			img {
				margin: 0;
				float: none;
			}
			&:not(.has-background) .video {
				margin: 0 -20px;
			}
			.figure-text {
				padding: 8px 0;
			}
			figcaption,
			.figure-caption,
			.figure-credits {
				font-size: 14px;
				line-height: initial;
				text-align: left;
				padding: 0;
				& + figcaption {
					padding-top: 4px;
				}
			}
			& > figcaption,
			& > .figure-caption {
				padding: 8px 0;
			}
			&.has-background {
				.figure-text {
					padding: 8px 10px;
				}
			}
		}
		p {
			margin-top: 1.7rem;
			margin-bottom: 1.7rem;
			& + h2,
			& + h3,
			& + h4 {
				margin-top: 1.7rem;
				& + ul {
					margin-top: 1rem;
				}
			}
		}
	}

	.gallery {
		.carousel-control-prev,
		.carousel-control-next,
		.carousel-indicators li {
			background-color: $color-advance-light-teal;
		}

		.icon-arrow-left,
		.icon-arrow-right {
			fill: $color-advance-dark-teal;
		}
	}

	.pull-quote,
	.advance--article-content .pull-quote {
		margin: 28px auto 30px;
		background: $color-white;
		border-bottom: 6px $color-advance-light-grey solid;
		padding: 80px 0 25px;
		float: none !important;
		.card-header {
			position: absolute;
			left: 0;
			top: 0;
			padding: 0;
			.profile-image {
				position: relative;
				background: transparent;
				border: none;
				color: $color-advance-dark-teal;
				box-shadow: none;
				overflow: visible;
				margin: 0;
				padding: 0;
				span {
					display: none;
				}
				&::after {
					content: url("../images/icons/advance-quote.svg");
					position: absolute;
					top: 14px;
					left: 16px;
					content: "";
					display: block;
					height: 33px;
					width: 40px;
					background-size: 40px 33px;
					background-image: url("../images/icons/advance-quote.svg");
					background-repeat: no-repeat;
				}
			}
			&::before {
				content: "";
				width: 0px;
				height: 0px;
				border-top: 76px solid $color-advance-yellow;
				border-right: 79px solid transparent;
				top: 0px;
				left: 0px;
				position: absolute;
			}
		}
		.card-body,
		.card-footer {
			padding: 0;
			text-align: left;
			p {
				text-align: left !important;
				line-height: 28px;
				color: $color-advance-dark-bluegrey;
			}
		}
		.card-footer {
			padding-top: 9px;
			font-family: $advance-serif;
			p {
				color: $color-advance-mid-blue;
				margin: 0;
			}
			.quote-name {
				font-weight: 700;
				font-family: $advance-serif;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.advance--article-content {
			padding-top: $padding-advance-section-desktop;
			padding-bottom: $padding-advance-section-desktop;
			.generic {
				padding: 40px 0px;
			}
			figure,
			.media-item {
				margin-bottom: 1.7rem;
				&.right,
				&.left {
					margin: 0 auto 16px;
					&.right {
						margin-left: 40px;
					}
					&.left {
						margin-right: 40px;
					}
				}
				&:not(.has-background) .video {
					margin: 0;
				}
				&:not(.right):not(.left):not(.media-right):not(.media-left):not(.image-right):not(.image-left) {
					width: 100%;
				}
				img {
					width: auto;
				}
			}
		}
		.pull-quote,
		.advance--article-content .pull-quote {
			margin: 2.5rem auto 3rem;
			padding: 70px 30px 30px;
			.card-header {
				.profile-image {
					padding: 0 20px;
					font-size: 100px;
					&::after {
						width: 54px; //only one size is alright, IE scales uniformly to fit it
						height: 44px;
						background-size: 54px 44px;
						top: 18px;
						left: 22px;
					}
				}
				&::before {
					border-top: 102px solid $color-advance-yellow;
					border-right: 106px solid transparent;
				}
			}
			.card-body,
			.card-footer {
				padding-right: 79px;
				padding-left: 79px;
			}
		}
	}
}
