.blog-categories {
	@include make-container();
	@include make-container-max-width();
	margin-bottom: 2rem;

	.accordion-container {
		padding: 0;

		.accordion {
			.card-header {
				background-color: transparent;
				border-radius: 0;

				&.active {
					background: linear-gradient(to bottom, transparent 50%, #f2f9ff 50%)
				}

				button {
					color: $color-white;
				}
			}

			.button-header {
				margin-bottom: 0;
			}

			.btn-link {
				@include text(zeta);
				padding: 0.875rem 1rem;
				text-decoration: none;
				background-color: $color-gray-darkest;

				.icon {
					fill: $color-white;
					right: 1rem;
				}
			}
		}
	}

	.category-header {
		display: none;
	}

	.category-list {
		background-color: $color-neutral-lightest;
		list-style-type: none;
		margin: 0;
		padding: 1rem 1rem 1.5rem;

		li {
			text-align: center;
			font-weight: $font-weight-semi-bold;

			&:before {
				display: none;
			}

			&:not(:last-of-type) {
				margin-bottom: 0.5rem;
			}

			a {
				&.active {
					font-weight: $font-weight-extra-bold;
				} 

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	.category-link {
		@include text(zeta);
		color: $color-gray-darkest;
	}

	@include media-breakpoint-up(lg) {
		.hero-article + &,
		.blog-list + & {
			// margin-top: -2rem;
			margin-top: -1rem;
		}

		.accordion-container {
			.accordion {
				.button-header {
					display: none;
				}
	
				.collapse {
					display: block;
				}
	
				.card {
					background-color: $color-gray-darkest;
					border-radius: 5px;
				}
	
				.card-header {
					background-color: $color-gray-darkest;

					&.active {
						background: $color-gray-darkest;
					}
				}
			}
		}

		.category-header {
			@include text(epsilon);
			color: $color-white;
			display: block;
			font-weight: $font-weight-semi-bold;
			margin-bottom: 0.25rem;
			// padding: 1.25rem 1.5rem 0;
			padding: 1.25rem 2.5rem 0;
		}

		.category-list {
			display: flex;
			list-style-type: none;
			margin: 0;
			// padding: 0 1.5rem 1.25rem;
			padding: .5rem 2.5rem 1.25rem;
			background-color: $color-gray-darkest;

			li {
				position: relative;
				margin-bottom: 0;

				&:before {
					display: none;
				}

				.category-link {
					position: relative;
					color: $color-white;
					font-weight: $font-weight-normal;
				}

				&:not(:last-of-type) {
					margin-right: 1rem;
					margin-bottom: 0;

						&:after {
							background-color: $color-primary-yellow;

							content: '';
							display: block;
							height: 75%;
							pointer-events: none;
							position: absolute;
							right: -1rem;
							top: 50%;
							transform: translate(50%, -50%);
							width: 1px;
						}
				}

				&:not(:first-of-type) {
					margin-left: 1rem;
				}

				a {
					&.active {
						font-weight: $font-weight-extra-bold;
						border-bottom: 2px solid $color-primary-yellow;
						padding-bottom: 2px;
					} 
	
					&:hover {
						text-decoration: none;
						border-bottom: 2px solid $color-primary-yellow;
						padding-bottom: 2px;
					}
				}
			}
		}

		.category-link {
			@include text(zeta);
		}
	}
}
